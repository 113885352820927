import { Profile } from './../../../../shared/models/profile';
import { Brand } from 'app/shared/models/brand';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomerListView} from '../../../../shared/models/customer-list-view';
import { BrandChangeService } from 'app/core/services/brand-change';
import { UpdateService } from 'app/core/services/update.service';

@Component({
  selector: 'app-brand-menu',
  templateUrl: './brand-menu.component.html',
  styleUrls: ['./brand-menu.component.scss']
})
export class BrandMenuComponent implements OnInit {
  @Input() customerListView: CustomerListView;
  @Input() profiles: CustomerListView[];
  @Input() numberOfProfiles: number;
  @Output() refreshProfiles = new EventEmitter<Profile>();

  shownSection: string;

  constructor(private brandChange: BrandChangeService) { }

  ngOnInit() {
    this.shownSection = 'overview';
    this.loadBrand();
  }

  loadBrand() {
    this.brandChange.currentBrand.subscribe(
      brand => {
        this.brandChange = this.brandChange;
    });
  }

  refreshProfilesEvent(event) {
    this.refreshProfiles.emit(event);
  }
}
