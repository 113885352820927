import { Dictionary } from 'app/shared/models/dictionary';
import { Market } from 'app/shared/models/market';

export const Markets: Market[] = [
  new Market('DK', 'Denmark'),
  new Market('NO', 'Norway'),
  new Market('SE', 'Sweden'),
  new Market('AF', 'Afghanistan'),
  new Market('AX', 'Aland Islands '),
  new Market('AL', 'Albania'),
  new Market('DZ', 'Algeria'),
  new Market('AS', 'American Samoa'),
  new Market('AD', 'Andorra'),
  new Market('AO', 'Angola'),
  new Market('AI', 'Anguilla'),
  new Market('AQ', 'Antarctica'),
  new Market('AG', 'Antigua and Barbuda'),
  new Market('AR', 'Argentina'),
  new Market('AM', 'Armenia'),
  new Market('AW', 'Aruba'),
  new Market('AU', 'Australia'),
  new Market('AT', 'Austria'),
  new Market('AZ', 'Azerbaijan'),
  new Market('BS', 'Bahamas'),
  new Market('BH', 'Bahrain'),
  new Market('BD', 'Bangladesh'),
  new Market('BB', 'Barbados'),
  new Market('BY', 'Belarus'),
  new Market('BE', 'Belgium'),
  new Market('BZ', 'Belize'),
  new Market('BJ', 'Benin'),
  new Market('BM', 'Bermuda'),
  new Market('BT', 'Bhutan'),
  new Market('BO', 'Bolivia'),
  new Market('BA', 'Bosnia and Herzegovina'),
  new Market('BW', 'Botswana'),
  new Market('BV', 'Bouvet Island'),
  new Market('BR', 'Brazil'),
  new Market('IO', 'British Indian Ocean Territory'),
  new Market('BN', 'Brunei Darussalam'),
  new Market('BG', 'Bulgaria'),
  new Market('BF', 'Burkina Faso'),
  new Market('BI', 'Burundi'),
  new Market('KH', 'Cambodia'),
  new Market('CM', 'Cameroon'),
  new Market('CA', 'Canada'),
  new Market('CV', 'Cape Verde'),
  new Market('KY', 'Cayman Islands'),
  new Market('CF', 'Central African Republic'),
  new Market('TD', 'Chad'),
  new Market('CL', 'Chile'),
  new Market('CN', 'China'),
  new Market('CX', 'Christmas Island'),
  new Market('CC', 'Cocos Islands'),
  new Market('CO', 'Colombia'),
  new Market('KM', 'Comoros'),
  new Market('CG', 'Congo'),
  new Market('CD', 'Democratic Republic of Congo'),
  new Market('CK', 'Cook Islands'),
  new Market('CR', 'Costa Rica'),
  new Market('HR', 'Croatia'),
  new Market('CU', 'Cuba'),
  new Market('CY', 'Cyprus'),
  new Market('CZ', 'Czech Republic'),
  new Market('CI', "Côte d'Ivoire"),
  new Market('KP', "Democratic People's Republic of Korea"),
  new Market('DK', 'Denmark'),
  new Market('DJ', 'Djibouti'),
  new Market('DM', 'Dominica'),
  new Market('DO', 'Dominican Republic'),
  new Market('TP', 'East Timor'),
  new Market('EC', 'Ecuador'),
  new Market('EG', 'Egypt'),
  new Market('SV', 'El Salvador'),
  new Market('GQ', 'Equatorial Guinea '),
  new Market('ER', 'Eritrea'),
  new Market('EE', 'Estonia'),
  new Market('ET', 'Ethiopia'),
  new Market('EU', 'European Union'),
  new Market('FK', 'Falkland Islands'),
  new Market('FO', 'Faroe Islands'),
  new Market('FM', 'Federated States of Micronesia'),
  new Market('FJ', 'Fiji'),
  new Market('FI', 'Finland'),
  new Market('FR', 'France'),
  new Market('GF', 'French Guiana'),
  new Market('PF', 'French Polynesia'),
  new Market('TF', 'French Southern Territories'),
  new Market('GA', 'Gabon'),
  new Market('GM', 'Gambia'),
  new Market('GE', 'Georgia'),
  new Market('DE', 'Germany'),
  new Market('GH', 'Ghana'),
  new Market('GI', 'Gibraltar'),
  new Market('GR', 'Greece'),
  new Market('GL', 'Greenland'),
  new Market('GD', 'Grenada'),
  new Market('GP', 'Guadeloup'),
  new Market('GU', 'Guam'),
  new Market('GT', 'Guatemala'),
  new Market('GG', 'Guernsey'),
  new Market('GN', 'Guinea'),
  new Market('GW', 'Guinea-Bissau'),
  new Market('GY', 'Guyana'),
  new Market('HT', 'Haiti'),
  new Market('HM', 'Heard Island and McDonald Islands'),
  new Market('VA', 'Holy See'),
  new Market('HN', 'Honduras'),
  new Market('HK', 'Hong Kong'),
  new Market('HU', 'Hungary'),
  new Market('IS', 'Iceland'),
  new Market('IN', 'India'),
  new Market('ID', 'Indonesia'),
  new Market('IQ', 'Iraq'),
  new Market('IE', 'Ireland'),
  new Market('IR', 'Islamic Republic of Iran'),
  new Market('IM', 'Isle of Man'),
  new Market('IL', 'Israel'),
  new Market('IT', 'Italy'),
  new Market('JM', 'Jamaica'),
  new Market('JP', 'Japan'),
  new Market('JE', 'Jersey'),
  new Market('JO', 'Jordan'),
  new Market('KZ', 'Kazakhstan'),
  new Market('KE', 'Kenya'),
  new Market('KI', 'Kiribati'),
  new Market('XK', 'Kosovo'),
  new Market('KW', 'Kuwait'),
  new Market('KG', 'Kyrgyzstan'),
  new Market('LA', "Lao People's Democratic Republic"),
  new Market('LV', 'Latvia'),
  new Market('LB', 'Lebanon'),
  new Market('LS', 'Lesotho'),
  new Market('LR', 'Liberia'),
  new Market('LY', 'Libyan Arab Jamahiriya'),
  new Market('LI', 'Liechtenstein'),
  new Market('LT', 'Lithuania'),
  new Market('LU', 'Luxembourg'),
  new Market('MO', 'Macau'),
  new Market('MG', 'Madagascar'),
  new Market('MW', 'Malawi'),
  new Market('MY', 'Malaysia'),
  new Market('MV', 'Maldives'),
  new Market('ML', 'Mali'),
  new Market('MT', 'Malta'),
  new Market('MH', 'Marshall Islands'),
  new Market('MQ', 'Martinique'),
  new Market('MR', 'Mauritania'),
  new Market('MU', 'Mauritius'),
  new Market('YT', 'Mayotte'),
  new Market('MX', 'Mexico'),
  new Market('MC', 'Monaco'),
  new Market('MN', 'Mongolia'),
  new Market('ME', 'Montenegro'),
  new Market('MS', 'Montserrat'),
  new Market('MA', 'Morocco'),
  new Market('MZ', 'Mozambique'),
  new Market('MM', 'Myanmar'),
  new Market('NA', 'Namibia'),
  new Market('NR', 'Nauru'),
  new Market('NP', 'Nepal'),
  new Market('NL', 'Netherlands'),
  new Market('AN', 'Netherlands Antilles'),
  new Market('NT', 'Neutral Zone'),
  new Market('NC', 'New Caledonia'),
  new Market('NZ', 'New Zealand'),
  new Market('NI', 'Nicaragua'),
  new Market('NE', 'Niger'),
  new Market('NG', 'Nigeria'),
  new Market('NU', 'Niue'),
  new Market('NF', 'Norfolk Island'),
  new Market('MP', 'Northern Mariana Islands'),
  new Market('NO', 'Norway'),
  new Market('OM', 'Oman'),
  new Market('PK', 'Pakistan'),
  new Market('PW', 'Palau'),
  new Market('PS', 'Palestinian Territory, Occupied'),
  new Market('PA', 'Panama'),
  new Market('PG', 'Papua New Guinea'),
  new Market('PY', 'Paraguay'),
  new Market('PE', 'Peru'),
  new Market('PH', 'Philippines'),
  new Market('PN', 'Pitcairn'),
  new Market('PL', 'Poland'),
  new Market('PT', 'Portugal'),
  new Market('PR', 'Puerto Rico'),
  new Market('QA', 'Qatar'),
  new Market('KR', 'Republic of Korea'),
  new Market('MD', 'Republic of Moldova'),
  new Market('RE', 'Reunion'),
  new Market('RO', 'Romania'),
  new Market('RU', 'Russian Federation'),
  new Market('RW', 'Rwanda'),
  new Market('SH', 'Saint Helena'),
  new Market('KN', 'Saint Kitts and Nevis'),
  new Market('LC', 'Saint Lucia'),
  new Market('MF', 'Saint Martin'),
  new Market('VC', 'Saint Vincent & the Grenadines'),
  new Market('WS', 'Samoa'),
  new Market('SM', 'San Marino'),
  new Market('ST', 'Sao Tome and Principe'),
  new Market('SA', 'Saudi Arabia'),
  new Market('SN', 'Senegal'),
  new Market('RS', 'Serbia'),
  new Market('SC', 'Seychelles'),
  new Market('SL', 'Sierra Leone'),
  new Market('SG', 'Singapore'),
  new Market('SX', 'Sint Maarten'),
  new Market('SK', 'Slovakia'),
  new Market('SI', 'Slovenia'),
  new Market('SB', 'Solomon Islands'),
  new Market('SO', 'Somalia'),
  new Market('ZA', 'South Africa'),
  new Market('GS', 'South Georgia and The South Sandwich Islands'),
  new Market('SS', 'South Sudan'),
  new Market('ES', 'Spain'),
  new Market('LK', 'Sri Lanka'),
  new Market('PM', 'St. Pierre and Miquelon'),
  new Market('SD', 'Sudan'),
  new Market('SR', 'Suriname'),
  new Market('SJ', 'Svalbard and Jan Mayen'),
  new Market('SZ', 'Eswatini'),
  new Market('SE', 'Sweden'),
  new Market('CH', 'Switzerland'),
  new Market('SY', 'Syrian Arab Republic'),
  new Market('TW', 'Taiwan'),
  new Market('TJ', 'Tajikistan'),
  new Market('TH', 'Thailand'),
  new Market('MK', 'North Macedonia'),
  new Market('TL', 'Timor-Leste'),
  new Market('TG', 'Togo'),
  new Market('TK', 'Tokelau'),
  new Market('TO', 'Tonga'),
  new Market('TT', 'Trinidad and Tobago'),
  new Market('TN', 'Tunisia'),
  new Market('TR', 'Turkey'),
  new Market('TM', 'Turkmenistan'),
  new Market('TC', 'Turks and Caicos Islands'),
  new Market('TV', 'Tuvalu'),
  new Market('UM', 'US Minor Outlying Islands'),
  new Market('UG', 'Uganda'),
  new Market('UA', 'Ukraine'),
  new Market('AE', 'United Arab Emirates'),
  new Market('GB', 'United Kingdom'),
  new Market('TZ', 'United Republic of Tanzania'),
  new Market('US', 'USA'),
  new Market('UY', 'Uruguay'),
  new Market('UZ', 'Uzbekistan'),
  new Market('VU', 'Vanuatu'),
  new Market('VE', 'Venezuela'),
  new Market('VN', 'Viet Nam'),
  new Market('VG', 'Virgin Islands (British)'),
  new Market('VI', 'Virgin Islands (U.S.A.)'),
  new Market('WF', 'Wallis and Futuna'),
  new Market('EH', 'Western Sahara'),
  new Market('YE', 'Yemen'),
  new Market('ZM', 'Zambia'),
  new Market('ZW', 'Zimbabwe'),
];

export const BusinessArea: Dictionary[] = [
  new Dictionary('TRAVELCARE', 'Travel'),
  new Dictionary('MOBILITY', 'Mobility'),
  new Dictionary('HEALTHCARE', 'Health'),
];
