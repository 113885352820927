<div class="profile-products" *ngIf="filteredProductsList">
  <div class="products-toolbar-container clearfix">
    <div class="products-toolbar-label float-start">Customer products ({{ numberOfProducts }})</div>
    <select class="products-toolbar-filter float-start" [ngModel]="statusFilterValue" (change)="filterChanged($event.target.value)">
      <option *ngFor="let item of productStatusList" [value]="item">{{ item }}</option>
    </select>
    <div ngbDropdown class="products-toolbar-button-container float-end">
      <button class="primary-button" (click)="showAddActionMenu()" ngbDropdownToggle>Add new product</button>
      <div ngbDropdownMenu>
        <button ngbDropdownItem *ngFor="let area of businessAreas" (click)="addNew(area.name)">{{ area.name }}</button>
      </div>
    </div>
    <input class="float-end" type="text" [(ngModel)]="searchText" placeholder="Search..." (ngModelChange)="searchTable($event)" />
    <div class="products-toolbar-button-newline" *permission='PermissionEnum.CUSTOMER_COVERAGES_LIMITS_RELATION_CREATE'>
      <div *ngIf="!copyCoveragesAndLimitsInProgress" class="products-toolbar-button-container float-end">
        <button class="primary-button" (click)="beginCopyCoveragesAndLimits()">Copy coverages &amp; limits</button>
      </div>
      <div *ngIf="copyCoveragesAndLimitsInProgress">
        <div class="products-toolbar-button-container float-end">
          <button *ngIf="copyCoveragesAndLimitsInProgress" class="primary-button"
                  [ngClass]="!anySelectedCopyToProduct() && 'button-disabled'" [disabled]="!anySelectedCopyToProduct()"
                  (click)="executeCopyCoveragesAndLimits()">Confirm copy</button>
        </div>
        <div class="products-toolbar-button-container float-end">
          <button class="primary-button" (click)="cancelCopyCoveragesAndLimits()">Cancel copy</button>
        </div>
      </div>
    </div>
  </div>
  <table class="products-table">
    <thead>
      <tr (clickOutside)="closeFilteringSortingMenu()">
        <td (click)="showFilteringSortingMenu(productsFilteringSortingMenuType.name)" class="filter-column">
          <div [ngStyle]="!showFilterMenu[productsFilteringSortingMenuType.name] && { display: 'none' }">
            <app-products-filtering-sorting-menu
              (modelChanged)="finishedAndFilteringSorting()"
              [productsFilteringSortingMenuType]="productsFilteringSortingMenuType.name"
              (clearAllFilters)="clearAllFiltering()"
              [(productsList)]="filteredProductsList"
            ></app-products-filtering-sorting-menu>
          </div>
          Name
        </td>
        <td class="filter-column-disabled" disabled="isProfileAMember()">
          Insurance / Card level
        </td>
        <td class="filter-column-disabled">
          Product version
        </td>
        <td (click)="showFilteringSortingMenu(productsFilteringSortingMenuType.sosProductId)" class="filter-column">
          <div [ngStyle]="!showFilterMenu[productsFilteringSortingMenuType.sosProductId] && { display: 'none' }">
            <app-products-filtering-sorting-menu
              (modelChanged)="finishedAndFilteringSorting()"
              [productsFilteringSortingMenuType]="productsFilteringSortingMenuType.sosProductId"
              (clearAllFilters)="clearAllFiltering()"
              [(productsList)]="filteredProductsList"
            ></app-products-filtering-sorting-menu>
          </div>
          SOS ID
        </td>
        <td (click)="showFilteringSortingMenu(productsFilteringSortingMenuType.customerProductId)" class="filter-column">
          <div [ngStyle]="!showFilterMenu[productsFilteringSortingMenuType.customerProductId] && { display: 'none' }">
            <app-products-filtering-sorting-menu
              (modelChanged)="finishedAndFilteringSorting()"
              [productsFilteringSortingMenuType]="productsFilteringSortingMenuType.customerProductId"
              (clearAllFilters)="clearAllFiltering()"
              [(productsList)]="filteredProductsList"
            ></app-products-filtering-sorting-menu>
          </div>
          Customer ID
        </td>
        <td (click)="showFilteringSortingMenu(productsFilteringSortingMenuType.policyLookupMethodType)" class="filter-column">
          <div [ngStyle]="!showFilterMenu[productsFilteringSortingMenuType.policyLookupMethodType] && { display: 'none' }">
            <app-products-filtering-sorting-menu
              (modelChanged)="finishedAndFilteringSorting()"
              [productsFilteringSortingMenuType]="productsFilteringSortingMenuType.policyLookupMethodType"
              (clearAllFilters)="clearAllFiltering()"
              [(productsList)]="filteredProductsList"
            ></app-products-filtering-sorting-menu>
          </div>
          Policy Lookup Method
        </td>
        <td (click)="showFilteringSortingMenu(productsFilteringSortingMenuType.policyLookupMethodCode)" class="filter-column">
          <div [ngStyle]="!showFilterMenu[productsFilteringSortingMenuType.policyLookupMethodCode] && { display: 'none' }">
            <app-products-filtering-sorting-menu
              (modelChanged)="finishedAndFilteringSorting()"
              [productsFilteringSortingMenuType]="productsFilteringSortingMenuType.policyLookupMethodCode"
              (clearAllFilters)="clearAllFiltering()"
              [(productsList)]="filteredProductsList"
            ></app-products-filtering-sorting-menu>
          </div>
          Policy Lookup Code
        </td>
        <td (click)="showFilteringSortingMenu(productsFilteringSortingMenuType.debtor)" class="filter-column">
          <div [ngStyle]="!showFilterMenu[productsFilteringSortingMenuType.debtor] && { display: 'none' }">
            <app-products-filtering-sorting-menu
              (modelChanged)="finishedAndFilteringSorting()"
              [productsFilteringSortingMenuType]="productsFilteringSortingMenuType.debtor"
              (clearAllFilters)="clearAllFiltering()"
              [(productsList)]="filteredProductsList"
            ></app-products-filtering-sorting-menu>
          </div>
          Debtor
        </td>
        <td (click)="showFilteringSortingMenu(productsFilteringSortingMenuType.validFrom)" class="filter-column">
          <div [ngStyle]="!showFilterMenu[productsFilteringSortingMenuType.validFrom] && { display: 'none' }">
            <app-products-filtering-sorting-menu
              (modelChanged)="finishedAndFilteringSorting()"
              [productsFilteringSortingMenuType]="productsFilteringSortingMenuType.validFrom"
              (clearAllFilters)="clearAllFiltering()"
              [(productsList)]="filteredProductsList"
            ></app-products-filtering-sorting-menu>
          </div>
          Valid from
        </td>
        <td (click)="showFilteringSortingMenu(productsFilteringSortingMenuType.validTo)" class="filter-column">
          <div [ngStyle]="!showFilterMenu[productsFilteringSortingMenuType.validTo] && { display: 'none' }">
            <app-products-filtering-sorting-menu
              (modelChanged)="finishedAndFilteringSorting()"
              [productsFilteringSortingMenuType]="productsFilteringSortingMenuType.validTo"
              (clearAllFilters)="clearAllFiltering()"
              [(productsList)]="filteredProductsList"
            ></app-products-filtering-sorting-menu>
          </div>
          Valid to
        </td>
        <td (click)="showFilteringSortingMenu(productsFilteringSortingMenuType.deactivationDate)" class="filter-column">
          <div [ngStyle]="!showFilterMenu[productsFilteringSortingMenuType.deactivationDate] && { display: 'none' }">
            <app-products-filtering-sorting-menu
              (modelChanged)="finishedAndFilteringSorting()"
              [productsFilteringSortingMenuType]="productsFilteringSortingMenuType.deactivationDate"
              (clearAllFilters)="clearAllFiltering()"
              [(productsList)]="filteredProductsList"
            ></app-products-filtering-sorting-menu>
          </div>
          Deactivation
        </td>
        <td class="filter-column-disabled"></td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let product of searchResultProductsList" [ngClass]="{ 'inherited': product.productProfileIsMember() }">
        <td (click)="edit(product.id)">
          <div *ngIf="!product.productProfileIsMember() && !product.groupProfileOnly" class="first-column-container">
            <img *ngIf="!product.parentId" src="assets/icons/star-blue.svg" />
            {{ product.name }}
          </div>
          <div *ngIf="!product.productProfileIsMember() && product.groupProfileOnly" class="first-column-container" triggers="hover" [ngbPopover]="'This product, incl. child products if any, is available on the group profile only'">
            <img *ngIf="!product.parentId" src="assets/icons/star-blue.svg" />
            {{ product.name }}
          </div>
          <div *ngIf="product.productProfileIsMember()" class="first-column-container" triggers="hover" [ngbPopover]="'This product is added on the customer group profile and cannot be edited here'">
            <img *ngIf="!product.parentId" src="assets/icons/star-blue.svg" />
            {{ product.name }}
          </div>
        </td>
        <td>{{ product.sosInsuranceLevel }}</td>
        <td>{{ product.version }}</td>
        <td>{{ product.sosProductId }}</td>
        <td>{{ product.customerProductIds.join(', ') }}</td>
        <td>{{product.policyLookupMethodType | PolicyLookupMethodPipe }}</td>
        <td>{{product.policyLookupMethodCode}}</td>
        <td>{{ product.debtorOverruled ? "Other" : "Main" }}</td>
        <td>{{ product.validFrom | date: 'dd MMM yyyy' }}</td>
        <td>{{ product.validTo | date: 'dd MMM yyyy' }}</td>
        <td>{{ product.deactivationDate | date: 'dd MMM yyyy' }}</td>
        <td *ngIf="!copyCoveragesAndLimitsInProgress">
          <div class="last-column-container" (mouseenter)="clickActionMenu(product.id)" (mouseleave)="hideActionMenu()">
            <button *ngIf="!product.productProfileIsMember()" class="action-button">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </button>
            <div
              class="action-menu"
              [ngClass]="{ 'action-menu-disabled': !product.sosInsuranceLevel }"
              *ngIf="showActionMenu && actionMenuIndex === product.id"
            >
              <div class="action-menu-item" *ngIf="!!product.sosInsuranceLevel" (click)="addNewLevel(product)">
                Add new Insurance / Card level
              </div>
              <div class="action-menu-item" *ngIf="!product.sosInsuranceLevel">
                Add new Insurance / Card level
              </div>
            </div>
          </div>
        </td>
        <td *ngIf="copyCoveragesAndLimitsInProgress">
          <div class="last-column-container">
            <img *ngIf="!product.parentId && coveragesAndLimitsExist(product) && !copyFromProductSelected" src="assets/icons/copy.svg" (click)="selectCopyFromProduct(product)" />
            <img *ngIf="!product.parentId && copyFromProductSelected && copyFromProduct===product" src="assets/icons/copy-inverted.svg" (click)="selectCopyFromProduct(product)" />
            <div *ngIf="!product.productProfileIsMember() && !product.parentId && copyFromProductSelected && !coveragesAndLimitsExist(product) && copyFromProduct!==product" class="copy-to-checkbox">
              <app-checkbox [id]="product.id.toString()" [(value)]="product.copyCoverages">
              </app-checkbox>
            </div>
            <div *ngIf="copyFromProductSelected && copyFromProduct!==product && (product.productProfileIsMember() || product.parentId || coveragesAndLimitsExist(product))" class="hover-enabled-box"
                 triggers="hover" placement="left" popoverClass="custom-popover" openDelay="400" [ngbPopover]="'It is only possible to copy coverages & limits to a customer product to which no coverages & limits have yet been added.'">
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<app-profile-products-add
  #profileProductsAddComponent
  [profileId]="profileId"
  (refreshProduct)="refreshProducts($event)"
  [products]="productsList"
>
</app-profile-products-add>
