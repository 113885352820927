import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'YesNo'
})
export class YesNoPipe implements PipeTransform {
  transform(value: boolean): any {
    if (value === undefined) {
      return '';
    }
    if (value) {
      return 'Yes';
    } else {
      return 'No';
    }
  }
}
