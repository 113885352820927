import { CountryAdapter } from 'app/shared/models/country';
import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class ProfileCountryListDeleteResult {
  deleted: boolean;
  namesOfProductsUsingGivenCountryList: Array<string> = [];


  public constructor(init?: Partial<ProfileCountryListDeleteResult>) {
    Object.assign(this, init);
  }

}
@Injectable({
  providedIn: 'root'
})
export class ProfileCountryListDeleteResultAdapter implements Adapter<ProfileCountryListDeleteResult> {
  constructor() {}
  adapt(item: any): ProfileCountryListDeleteResult {
    return new ProfileCountryListDeleteResult({
      deleted: item.deleted,
      namesOfProductsUsingGivenCountryList: item.namesOfProductsUsingGivenCountryList
    });
  }
}


