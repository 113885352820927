import { Brand } from 'app/shared/models/brand';
import { Component, OnInit, Input } from '@angular/core';
import { SosServices } from 'app/shared/models/sos-services';

@Component({
  selector: 'app-sos-services-overview',
  templateUrl: './sos-services-overview.component.html',
  styleUrls: ['./sos-services-overview.component.scss']
})
export class SosServicesOverviewComponent implements OnInit {
  @Input() sosServices: SosServices;

  constructor() {}

  ngOnInit() {}
}
