<div class="rule-engine-table-container">
  <div class="customer-profile-container" (mouseenter)="hideTooltip()">
    <div class="customer-profile-dropdown">
      <div class="d-flex flex-row">
        <div class="p-2">
          <app-drop-down-list
            [name]="'Customer Profile'"
            [items]="customerProfiles"
            bindLabel="profileName"
            [(selectedItem)]="selectedProfile">
          </app-drop-down-list>
        </div>
        <div class="p-2">
          <app-drop-down
            class="dropdown-rule-mapping"
            [items]="ruleTypesDictionary"
            [name]="'Type'"
            *ngIf="ruleTypesDictionary"
            (selectedItemIdChange)="ruleTypeChanges($event)">
          </app-drop-down></div>
      </div>
      <button class="new-rule-button primary-button" (click)="addNewRuleMapping()">New Business Rule</button>
    </div>
  </div>
  <div class="rule-table-container" (mouseenter)="hideTooltip()">
    <table>
      <thead>
        <th class="rule-table-header">
          Type
        </th>
        <th class="rule-table-header">
          Name
        </th>
        <th class="rule-table-header">
          Event
        </th>
        <th class="rule-table-header">
          Rule
        </th>
        <th class="rule-table-header">
          Action
        </th>
        <th class="rule-table-header" (mouseenter)="hideTooltip()">
          Status
        </th>
      </thead>
      <tbody>
        <tr *ngFor="let tableRow of tableRows; let index = index" (click)="editRuleMapping(tableRow)">
          <td>{{tableRow.ruleType}}</td>
          <td>{{tableRow.ruleMappingName}}</td>
          <td>{{tableRow.ruleEventName}}</td>
          <td>{{tableRow.ruleName}}</td>
          <td>{{tableRow.ruleActionName}}</td>
          <td (mouseenter)="hideTooltip()" class="status">{{tableRow.active ? "Active" : "Inactive"}}</td>
          <td class="overrides" *ngIf="getAllOverridesRuleMappings(tableRow) !== null"
            (mouseenter)="showTooltip(tableRow, index)">
            <img src="/assets/icons/Warning.svg" alt="Warning symbol that signifies that rule is overridden by another rule">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="tooltip-text" *ngIf="hoveredTableRow" [ngStyle]="tooltipBottom">
    <div [innerHTML]="getAllOverridesRuleMappings(hoveredTableRow)"></div>
    <div class="triangle"></div>
  </div>
  <ng-template #RuleMappingModal>
    <app-rule-mapping-modal [ruleEvents]="ruleEvents" [ruleActions]="ruleActions" [rules]="rules" [ruleTypes]="ruleTypes"
                            [ruleActionInputs]="actionInputs" (refreshTable)="reloadMappingsAndActionInputs()"
                            [selectedRuleMapping]="selectedRuleMapping">
    </app-rule-mapping-modal>
  </ng-template>
</div>
