import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomerProductRelation } from 'app/shared/models/customers/product/policy/customer-product-relation';
import { ProductsPolicyRelationSortingTypeEnum } from './product-policy-relation-sorting-type-enum';
import { CustomerProductRelationFilterService } from './customer-product-relation.filter.service';
import {HelperService} from '../../../../../core/services/helper.service';

@Component({
  selector: 'app-product-policy-relations-sorting-menu',
  templateUrl: './product-policy-relations-sorting-menu.component.html',
  styleUrls: ['./product-policy-relations-sorting-menu.component.scss']
})
export class ProductPolicyRelationsSortingMenuComponent implements OnInit {
  @Input() customerProductRelationsList: CustomerProductRelation[];
  @Input() originalCustomerProductRelationsList: CustomerProductRelation[];
  @Input() productsPolicyRelationSortingTypeEnum: ProductsPolicyRelationSortingTypeEnum;

  @Output() modelChanged = new EventEmitter();
  @Output() customerProductRelationsListChange = new EventEmitter();
  @Output() clearAllFilters = new EventEmitter();

  sortByAscending: boolean;
  items: CheckBoxModel[];
  sortingKey: string;


  constructor(private helperService: HelperService,
              private customerProductRelationFilterService: CustomerProductRelationFilterService ) { }

  ngOnInit() {
      this.populateItems();
  }


  public uncheckItems() {
    if (this.items) {
      this.items.forEach(a => (a.check = false));
    }
  }

  updatePopulateItems(CustomerProductRelations: CustomerProductRelation[]) {
    this.customerProductRelationsList = CustomerProductRelations;
    this.populateItems();
  }

  populateItems() {
    this.items = [];
    switch (this.productsPolicyRelationSortingTypeEnum) {
      case ProductsPolicyRelationSortingTypeEnum.companyPolicyHolder:
        this.customerProductRelationsList.forEach(a => {
          if (!this.items.find(f => f.id === a.companyPolicyHolder)) {
              this.items.push(new CheckBoxModel(a.companyPolicyHolder, a.companyPolicyHolder, false));
          }
        });
        this.sortingKey = 'companyPolicyHolder';
        break;

        case ProductsPolicyRelationSortingTypeEnum.bank:
        this.customerProductRelationsList.forEach(a => {
          if (!this.items.find(f => f.id === a.bank)) {
              this.items.push(new CheckBoxModel(a.bank, a.bank, false));
          }
        });
        this.sortingKey = 'bank';
        break;

        case ProductsPolicyRelationSortingTypeEnum.BIN:
        this.customerProductRelationsList.forEach(a => {
          if (!this.items.find(f => f.id === a.bin.toString())) {
              this.items.push(new CheckBoxModel(a.bin, a.bin, false));
          }
        });
        this.sortingKey = 'bin';
        break;

        case ProductsPolicyRelationSortingTypeEnum.customerRelationLabel:
        this.customerProductRelationsList.forEach(a => {
          if(a.customerRelationLabel) {
              if (!this.items.find(f => f.id === a.customerRelationLabel.name)) {
                this.items.push(new CheckBoxModel(a.customerRelationLabel.name, a.customerRelationLabel.name, false));
            }
          }
        });
        this.sortingKey = 'customerRelationLabel';
        break;
    }
  }

  get doesUserCheckAnything() {
    return this.items.find(a => a.check) != null;
  }

  sortAscending() {
    this.sortByAscending = true;
    this.helperService.sortArray(
      this.customerProductRelationsList,
      this.sortingKey,
      this.sortByAscending
    );
    this.modelChanged.emit();
  }

  sortDescending() {
    this.sortByAscending = false;
    this.helperService.sortArray(
      this.customerProductRelationsList,
      this.sortingKey,
      this.sortByAscending
    );
    this.modelChanged.emit();
  }

  applyButtonClick() {
    if (!this.doesUserCheckAnything) {
      return;
    }
    this.clearAllFilters.emit();
    const selectedIds = [];
    this.items.filter(a => a.check).forEach(a => selectedIds.push(a.id));
    this.customerProductRelationFilterService.removeStep(this.productsPolicyRelationSortingTypeEnum);
    this.customerProductRelationFilterService.addStep(
      this.productsPolicyRelationSortingTypeEnum,
      selectedIds
    );
      this.customerProductRelationsList = this.customerProductRelationFilterService.filter(
        this.customerProductRelationsList
    );

    this.customerProductRelationsListChange.emit(this.customerProductRelationsList);
    this.modelChanged.emit();
  }

  clearAllButtonClick() {
    this.populateItems();
    this.items.forEach(a => (a.check = false));
    this.customerProductRelationFilterService.removeStep(this.productsPolicyRelationSortingTypeEnum);
    this.clearAllFilters.emit();
    this.customerProductRelationsList = this.customerProductRelationFilterService.filter(
      this.customerProductRelationsList
    );

    this.customerProductRelationsListChange.emit(this.customerProductRelationsList);
  }

  modalWindowClick(event: any) {
    event.stopPropagation();
  }
}

export class CheckBoxModel {
  name: string;
  id: string;
  check: boolean;

  constructor(id: string, name: string, check: boolean) {
    this.check = check;
    this.id = id;
    this.name = name;
  }
}
