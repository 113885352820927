import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommitmentType } from '@onepoint/shared/models/commitment-type';
import { MasterListCommitmentTypeModel } from '@onepoint/shared/models/masterlist-commitment-type-model';
import { MasterListService } from 'app/core/services/masterlist.service';
import { OnepointModalService } from 'app/core/services/onepoint.modal.service';
import { PermissionService } from 'app/core/services/permission.service';
import { SosServicesService } from 'app/core/services/sos-services.service';
import { Dictionary } from 'app/shared/models/dictionary';
import { DictionaryWithType } from 'app/shared/models/dictionary-with-type';
import { MasterListServiceTypeModel } from 'app/shared/models/masterlist-service-type-model';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import { log } from 'console';

@Component({
  selector: 'app-masterlist-service-type-modal',
  templateUrl: './masterlist-service-type-modal.component.html',
  styleUrls: ['./masterlist-service-type-modal.component.scss'],
})
export class MasterlistServiceTypeModalComponent implements OnInit {
  @Input()
  masterListType: MasterListServiceTypeModel;

  @Output()
  refreshTable = new EventEmitter();

  serviceTypes: DictionaryWithType[];
  commitmentTypes: any[] = [{ id: null, name: ""}];
  minDeactivationDate: Date;
  selectedDefaultCommitmentTypeId: string;
  selectedVatValue: string;
  error: string;
  canUpdate: boolean;
  isModelChanged: boolean;
  selectedCommitmentTypes = [];
  allSelectableCommitmentType = [];
  allCommitmentTypes: MasterListCommitmentTypeModel[];
  defaultCommitmentChoises = [];

  constructor(
    private masterListService: MasterListService,
    private onepointModalService: OnepointModalService,
    private sosServicesService: SosServicesService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.minDeactivationDate = new Date(Date.now() - 86400000);
    this.permissionService.permissionsReadyNotification.subscribe(
      result => (this.canUpdate = this.permissionService.checkUserPermission(PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE))
    );
    if (this.masterListType.activateOn > this.minDeactivationDate) {
      this.minDeactivationDate = this.masterListType.activateOn;
    }
    this.commitmentTypes = this.commitementTypesAsDictionary(this.masterListType.commitmentTypes);
    this.defaultCommitmentChoises = [{id: null, name: ""}].concat(this.commitmentTypes);
    this.selectedDefaultCommitmentTypeId = this.masterListType.defaultCommitmentType?.businessKey;
    this.masterListService.getAllCommitmentTypes().subscribe(result => {
      this.allCommitmentTypes = result;
      this.allSelectableCommitmentType = this.commitementTypesAsDictionary(result);
    });
  }

  closeModal() {
    this.onepointModalService.dismissAll();
  }

  get isSaveButtonEnabled(): boolean {
    return (
      this.isModelChanged &&
      this.masterListType &&
      !!this.masterListType.activateOn &&
      !!this.masterListType.serviceTypeCode &&
      !!this.masterListType.serviceTypeName
    );
  }

  save() {
    this.masterListService.updateServiceType(this.masterListType).subscribe(
      response => {
        this.masterListType = response;
        this.refreshTable.emit();
        this.closeModal();
      },
      err => (this.error = err.error ? err.error.message : err.message)
    );
  }

  activateOnChanged(item: MasterListServiceTypeModel) {
    this.modelChanged();
    item.activateOn.setHours(12, 0);
  }

  deactivateOnChanged(item: MasterListServiceTypeModel) {
    this.modelChanged();
    item.deactivateOn.setHours(12, 0);
  }

  selectedDefaultCommitmentTypeChanged($event) {
    
   this.masterListType.defaultCommitmentType = this.masterListType.commitmentTypes.find(commitmentType => {return commitmentType.businessKey === $event});
   this.modelChanged();
  }

  selectedCommitmentTypesChanged(): void {
    this.masterListType.commitmentTypes = [];
    this.commitmentTypes.forEach(dictionary =>
      this.masterListType.commitmentTypes.push(this.allCommitmentTypes.find(type => type.businessKey === dictionary.id))
    );
    this.modelChanged();

  }
  modelChanged(): void {
    this.isModelChanged = true;
  }

  get isReadonly(): boolean {
    return !this.canUpdate;
  }

  get isNew(): boolean {
    return this.masterListType.id == null;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  private commitementTypesAsDictionary(commitmentTypes: MasterListCommitmentTypeModel[]){
    const commitAsDict =[];
    if (commitmentTypes) {
      commitAsDict.push(...commitmentTypes.map(commitmentType => { return { id: commitmentType.businessKey, name:  commitmentType.name } } ));
    }
    return commitAsDict;
  }
  
}
