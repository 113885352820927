export enum IncidentEventMenuType {
    BUSINESS_KEY = 'businessKey',
    NAME = 'name',
    UI_SORT_INDEX = 'uiSortIndex',
    DESCRIPTION = 'description',
    ACTIVATE_ON = 'activateOn',
    DEACTIVATION_ON = 'deactivateOn',
    DATE_OF_EVENT = 'dateOfEvent',
    EVENT_LOCATION = 'eventLocation',
    CATEGORY = 'category',
  }