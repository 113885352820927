<div class="page-container">
    <div class="page-header">
      <div class="p-3" >
        <div class="titel">Customer reporting labels</div>
        <div class="float-start w-25">
          <app-drop-down
            [disabled]="this.customerRelationLabelsToUpdate.length !== 0"
            [items]="dropDownStatusListForSearch"
            [(selectedItemId)]="statusTypeSearch"
            (selectedItemIdChange)="statusDropDownChange()"
            [name]="''">
          </app-drop-down>
        </div>
        <div class="float-end">
          <button class="primary-button" [disabled]="this.customerRelationLabelsToUpdate.length !== 0" (click)="addNewLabel()" [ngClass]="this.customerRelationLabelsToUpdate.length !== 0 && 'button-disabled'">Add new label</button>
        </div>
        <input class="float-end" [disabled]="this.customerRelationLabelsToUpdate.length !== 0" type="text" [(ngModel)]="searchText" placeholder="Search..." (ngModelChange)="searchTable()" />
      </div>
    </div>
    <div class="page-content-container">
        <table class="primary-table sos-services-table">
            <thead>
              <tr>
                <td class="tdWidth40" sortColumn [sortKey]="'name'" [data]="customerRelationLabel" >
                  Name
                </td>
                <td class="tdWidth40" sortColumn [sortKey]="'remarks'" [data]="customerRelationLabel">
                  Remarks
                </td>
                <td class="tdWidth20" sortColumn [sortKey]="'status'" [data]="customerRelationLabel" >
                  Status
                </td>
              </tr>
            </thead>
            <tbody class="theBody">
              <ng-container *ngFor="let customerRelationLabel of customerRelationLabel; index as i">
                <tr>
                  <td class="tdWidth40">
                    <app-input [(value)]="customerRelationLabel.name"
                         (valueChange)="checkIfTheDataCanBeSaved(customerRelationLabel)"></app-input>
                  </td>
                  <td class="tdWidth40">
                    <app-input [(value)]="customerRelationLabel.remarks"
                    (valueChange)="checkIfTheDataCanBeSaved(customerRelationLabel)"
                    ></app-input>
                  </td>
                  <td class="tdWidth20">
                    <app-drop-down
                      [items]="dropDownStatusList"
                      [(selectedItemId)]="customerRelationLabel.status"
                      (selectedItemIdChange)="checkIfTheDataCanBeSaved(customerRelationLabel)"
                      [name]="''">
                      </app-drop-down>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
    </div>
    <div class="index-foot">
      <div class="save-changes-button my-auto">
        <button class="primary-button" (click)="saveLabels()"
                [ngClass]="this.customerRelationLabelsToUpdate.length === 0 && 'button-disabled'" [disabled]="this.customerRelationLabelsToUpdate.length === 0">Save changes
        </button>
      </div>
    </div>
</div>


<app-add-customer-profile-label #addCustomerProfileLabelComponent [existingCustomerRelationLabels]="originalCustomerRelationLabels"  (addCustomerRelationLabel)="addCustomerProfileLabel($event)" ></app-add-customer-profile-label>
