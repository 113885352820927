import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-masterlist-menu',
  templateUrl: './masterlist-menu.component.html',
  styleUrls: ['./masterlist-menu.component.scss']
})
export class MasterListMenuComponent implements OnInit {
  shownSection: string;

  constructor() { }

  ngOnInit() {
    this.shownSection = 'overview';
  }
}
