import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MasterListService } from '@onepoint/core/services/masterlist.service';
import { MasterListApplicationType } from '@onepoint/shared/models/masterlist-applicationtype';
import { finalize, map } from 'rxjs/operators';
import { Dictionary } from '@onepoint/shared/models/dictionary';
import { ApplicationService } from '@onepoint/core/services/application.service';
import { ProfileApplication } from '@onepoint/shared/models/profile-application';
import { CustomerProfileApplicationsOverviewMenuType } from './customer-profile-applications-overview-sorting-menu/customer-profile-applications-overview-menu-type';
import { CustomerProfile } from '@onepoint/shared/models/customer-profile';
import { HelperService } from '@onepoint/core/services/helper.service';

@Component({
  selector: 'app-customer-profile-applications-overview',
  templateUrl: './customer-profile-applications-overview.component.html',
  styleUrls: ['./customer-profile-applications-overview.component.scss']
})
export class CustomerProfileApplicationsOverviewComponent implements OnInit {
  applicationTypes: Dictionary[];
  profileApplications: ProfileApplication[];
  filteredProfileApplications: ProfileApplication[];
  customerProfiles: CustomerProfile[];
  selectedApplicationType: string;
  showMenu = new Map<CustomerProfileApplicationsOverviewMenuType, boolean>();
  formChanged = false;
  checkboxShowActive = false;
  checkboxAllIncluded = false;
  checkboxEditAllValidFrom = false;
  checkboxEditAllValidTo = false;
  checkboxAllLegacy = false;
  saveInProgress = false;
  loadInProgress = false;

  constructor(private masterListService: MasterListService,
              private applicationService: ApplicationService,
              private helperService: HelperService,
              private router: Router) { }

  ngOnInit() {
    this.loadMasterListApplicationTypes();
  }

  saveChanges(): void {
    this.saveInProgress = true;
    const changedApplications = this.filteredProfileApplications.filter(item => item.changed);
    this.applicationService.updateApplication(changedApplications).pipe(
        finalize(() => this.saveInProgress = false)
      )
      .subscribe(() => {
        this.formChanged = false;
        this.profileApplications.forEach(item => item.changed = false);
        this.loadProfileApplications(this.selectedApplicationType);
      });
  }

  get isSaveButtonEnabled(): boolean {
    return this.formChanged;
  }

  applicationSelected(): void {
    this.checkboxAllIncluded = false;
    this.checkboxEditAllValidFrom = false;
    this.checkboxEditAllValidTo = false;
    this.checkboxAllLegacy = false;
    this.formChanged = false;
    this.loadProfileApplications(this.selectedApplicationType);
  }

  checkBoxShowActiveChange(event) {
    this.filterProfileApplications();
  }

  checkBoxIncludedChange(profileApplication: ProfileApplication): void {
    this.checkboxAllIncluded = this.isAllSelected(CustomerProfileApplicationsOverviewMenuType.INCLUDED);
    this.formChanged = true;
    profileApplication.changed = true;
  }

  checkBoxAllIncludedChange(event): void {
    this.profileApplications.forEach(item => {
      item.included = event.target.checked
      item.changed = true;
    });
    this.formChanged = true;
  }

  checkBoxLegacyChange(profileApplication: ProfileApplication): void {
    this.checkboxAllLegacy = this.isAllSelected(CustomerProfileApplicationsOverviewMenuType.STILL_LEGACY);
    this.formChanged = true;
    profileApplication.changed = true;
  }

  checkBoxAllLegacyChange(event): void {
    this.profileApplications.forEach(item => {
      item.stillLegacy = event.target.checked;
      item.changed = true;
    });
    this.formChanged = true;
  }

  checkBoxEditAllValidFromChange(event): void {
    this.checkboxEditAllValidFrom = event.target.checked;
  }

  checkBoxEditAllValidToChange(event): void {
    this.checkboxEditAllValidTo = event.target.checked;
  }

  validDateFromChange(profileApplication: ProfileApplication): void {
    this.formChanged = true;
    profileApplication.changed = true;

    if ( profileApplication.validFrom ) {
      profileApplication.validFrom.setHours(12, 0);
    }

    if (this.checkboxEditAllValidFrom) {
      this.filteredProfileApplications.forEach(item => {
        item.validFrom = profileApplication.validFrom;
        item.changed = true;
      });
    }
  }

  validDateToChange(profileApplication: ProfileApplication): void {
    this.formChanged = true;
    profileApplication.changed = true;

    if ( profileApplication.validTo ) {
      profileApplication.validTo.setHours(12, 0);
    }

    if (this.checkboxEditAllValidTo) {
      this.filteredProfileApplications.forEach(item => {
        item.validTo = profileApplication.validTo;
        item.changed = true;
      });
    }
  }
  
  goToProfile(profileId: string): void {
    if ( profileId ) {
      this.router.navigate(['/customers/profile/' + profileId]);
    }
  }  

  goToCommonCustomer(commonCustomerId: string): void {
    if ( commonCustomerId ) {
      this.router.navigate(['/customers/common-customer/' + commonCustomerId]);
    }
  }  

  goToBrand(brandId: string): void {
    if ( brandId ) {
      this.router.navigate(['/customers/brand/' + brandId]);
    }
  }  

  showFilteringSortingMenu(filteringSortingMenuType: CustomerProfileApplicationsOverviewMenuType) {
    this.showMenu.forEach((value, key) => {
      if (key !== filteringSortingMenuType) {
        this.showMenu[key] = false;
      }
    });

    this.showMenu[filteringSortingMenuType] = !this.showMenu[filteringSortingMenuType];
  }

  get CustomerProfileApplicationsOverviewMenuType() {
    return CustomerProfileApplicationsOverviewMenuType;
  }

  private loadMasterListApplicationTypes(): void {
    this.masterListService.getApplicationTypes().pipe(
      map((items: MasterListApplicationType[]) => items.map(item => new Dictionary(item.type, item.name))
    ))
    .subscribe(
      result => this.applicationTypes = result
    )
  }

  private loadProfileApplications(applicationType: string): void {
    this.loadInProgress = true;
    this.profileApplications = [];
    this.applicationService.getApplicationsByApplicationType(applicationType).pipe(
        finalize(() => this.loadInProgress = false)
      ).subscribe(
        result => {
          this.profileApplications = result;
          this.checkboxAllIncluded = this.isAllSelected(CustomerProfileApplicationsOverviewMenuType.INCLUDED);
          this.checkboxAllLegacy = this.isAllSelected(CustomerProfileApplicationsOverviewMenuType.STILL_LEGACY);
          this.filterProfileApplications();
          this.sortProfileApplications();
      })
  }

  private isAllSelected(property: string): boolean {
    return !this.profileApplications.find(item => !item[property]);
  }

  private filterProfileApplications(): void {
    if ( this.checkboxShowActive ) {
      this.filteredProfileApplications = this.profileApplications.filter(profile => profile.included);
    }
    else {
      this.filteredProfileApplications = this.profileApplications;
    }
  }

  private sortProfileApplications(): void {
    this.helperService.sortArray(
      this.filteredProfileApplications,
      CustomerProfileApplicationsOverviewMenuType.PROFILE_NAME,
      true
    );
  }
}
