import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-market-summary',
  templateUrl: './market-summary.component.html',
  styleUrls: ['./market-summary.component.scss']
})
export class MarketSummaryComponent {

  @Input() set markets(markets: string[]) {
    if (markets) {
      this.firstMarkets = markets.slice(0, 5);
      this.secondMarkets = markets.slice(5, 10);
    }
  }

  firstMarkets: string[] = [];
  secondMarkets: string[] = [];
}
