import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import * as moment from 'moment';

export class AppliedProfile {
  id: string;
  profileId: string;
  priceAgreementId: string;
  customerSlaAgreementId: string;
  debtorId: string;
  preDepartureDebtorId: string;
  commencement: Date;
  termination: Date;
  modifiedBy: string;
  modifiedOn: moment.Moment;
  billingCurrencyTheSame:  boolean = false;

  public static overlapsAny(profiles: AppliedProfile[]): boolean {
    let overlap = false;
    profiles.forEach(p => {
      profiles.forEach(comp => (overlap = overlap || comp.overlaps(p)));
    });
    return overlap;
  }

  public constructor(init?: Partial<AppliedProfile>) {
    Object.assign(this, init);
  }

  public isValid(): boolean {
    return (
      (this.profileId &&
        this.priceAgreementId &&
        this.debtorId &&
        Boolean(this.commencement) &&
        (this.termination == null || this.commencement <= this.termination)) ||
      this.isEmpty()
    );
  }

  public isEmpty(): boolean {
    return !this.profileId && !this.debtorId && !this.priceAgreementId && !this.commencement;
  }

  public overlaps(profile: AppliedProfile): boolean {
    const profileCommenceBetween =
      this.commencement <= profile.commencement && (this.termination == null || this.termination >= profile.commencement);
    const thisCommenceBetween =
      profile.commencement <= this.commencement && (profile.termination == null || profile.termination >= this.commencement);
    return this.profileId+"" === profile.profileId+"" && this.id !== profile.id && (profileCommenceBetween || thisCommenceBetween);
  }
}

@Injectable({
  providedIn: 'root',
})
export class AppliedProfileAdapter implements Adapter<AppliedProfile> {
  adapt(item: any): AppliedProfile {
    const appliedProfile =  new AppliedProfile({
      id: item.id,
      profileId: item.profileId,
      priceAgreementId: item.priceAgreementId,
      customerSlaAgreementId: item.customerSlaAgreementId,
      debtorId: item.debtorId,
      commencement: item.commencement ? new Date(item.commencement) : null,
      termination: item.termination ? new Date(item.termination) : null,
      modifiedBy: item.modifiedBy,
      modifiedOn: item.modifiedOn ? moment.utc(item.modifiedOn) : null,
      preDepartureDebtorId: item.preDepartureDebtorId
    });
    if (appliedProfile.commencement) {
      appliedProfile.commencement.setHours(0, 0, 0, 0);
    }
    if (appliedProfile.termination) {
      appliedProfile.termination.setHours(0, 0, 0, 0);
    }
    return appliedProfile;
  }

  adaptForServiceCall(appliedProfile: AppliedProfile) {
    return {
      id: appliedProfile.id,
      profileId: appliedProfile.profileId,
      priceAgreementId: appliedProfile.priceAgreementId,
      customerSlaAgreementId: appliedProfile.customerSlaAgreementId,
      debtorId: appliedProfile.debtorId,
      commencement: appliedProfile.commencement == null ? null : moment(appliedProfile.commencement).format('YYYY-MM-DD'),
      termination: appliedProfile.termination == null ? null : moment(appliedProfile.termination).format('YYYY-MM-DD'),
      preDepartureDebtorId: appliedProfile.preDepartureDebtorId,
    };
  }
}
