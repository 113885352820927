import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Product } from '../../../../shared/models/product';
import { ProductService } from '../../../../core/services/product.service';
import { InsuranceTypeEnum } from '../../../../shared/enums/insurance-type-enum';
import { CoveragePeriodTypeEnum } from '../../../../shared/enums/coverage-period-type-enum';
import { CoveragePeriodUnitEnum } from '../../../../shared/enums/coverage-period-unit-enum';
import { BusinessAreaEnum } from 'app/shared/models/enums';
import { OnepointModalService, Size } from '../../../../core/services/onepoint.modal.service';

@Component({
  selector: 'app-profile-products-add',
  templateUrl: './profile-products-add.component.html',
  styleUrls: ['./profile-products-add.component.scss']
})

export class ProfileProductsAddComponent {
  @Input() profileId: string;
  @Input() products: Product[];
  @Input() newVersion: false;
  @Output() refreshProduct = new EventEmitter<Product>();
  @ViewChild('content', { static: true })
  private template: TemplateRef<any>;
  private windowTitle: string;
  product: Product;
  uniqueViolations: Product[] = [];
  mandatoryMet: boolean;

  constructor(private modalService: OnepointModalService,
              private productService: ProductService
  ) {
  }

  public openCreateNew(businessArea: BusinessAreaEnum) {
    this.createNewProduct(businessArea);
    this.modalService.open(this.template, Size.NORMAL);
    this.windowTitle = 'Add new Product';
  }

  public openCreateChild(parent: Product) {
    this.createNewChild(parent);
    this.modalService.open(this.template, Size.NORMAL);
    this.windowTitle = 'Add new Insurance/Card level';
  }

  public openCreateNewVersion(product: Product) {
    this.createNewVersion(product);
    this.modalService.open(this.template, Size.NORMAL);
    this.windowTitle = 'Add new Product Version';
  }

  public open(product: Product) {
    this.product = product;
    this.modalService.open(this.template, Size.NORMAL);
    this.windowTitle = 'Edit product';
  }

  saveProduct() {
    if (!this.product.id && this.product.parentId) {
      this.productService.createChildProduct(this.product.parentId, this.product).subscribe(result => {
        this.refreshProductAndCloseModal(result);
      }, error => console.log(error));
    }
    else if (!this.product.id) {
      this.productService.createNewProduct(this.profileId, this.product).subscribe(result => {
        this.refreshProductAndCloseModal(result);
      }, error => console.log(error));
    } else {
      this.productService.updateProduct(this.product).subscribe(result => {
        this.refreshProductAndCloseModal(result);
      }, error => console.log(error));
    }
  }

  refreshProductAndCloseModal(result: Product) {
    this.product = result;
    this.modalService.dismissAll();
    this.refreshProduct.emit(this.product);
  }

  close() {
    this.modalService.dismissAll();
  }

  isValid(): boolean {
    this.mandatoryMet = this.product.mandatoryMet();
    return this.mandatoryMet && this.uniqueViolations.length < 1;
  }

  getViolations(): Product[] {
    this.uniqueViolations = this.product.getUniqueViolations(this.products);
    return this.uniqueViolations;
  }

  private createNewProduct(businessArea: BusinessAreaEnum) {
    this.product = new Product();
    this.product.businessArea = businessArea;
    this.product.insuranceType = InsuranceTypeEnum.regular;
    this.product.coveragePeriodType = CoveragePeriodTypeEnum.annual;
    this.product.coveragePeriodUnit = CoveragePeriodUnitEnum.DAYS;
    this.product.profileId = Number(this.profileId);
    this.product.groupProfileOnly = false;
  }

  private createNewChild(parent: Product) {
    this.product = new Product({
      parentId: parent.id,
      name: parent.name,
      insuranceType: parent.insuranceType,
      termsSelectedBy: parent.termsSelectedBy,
      sosProductId: parent.sosProductId,
      customerProductIds: [...parent.customerProductIds],
      version: parent.version,
      businessArea: parent.businessArea,
      coveragePeriodType: parent.coveragePeriodType,
      coveragePeriodUnit: parent.coveragePeriodUnit,
      coveragePeriodValue: parent.coveragePeriodValue,
      validFrom: parent.validFrom,
      validTo: parent.validTo,
      deactivationDate: parent.deactivationDate,
      profileId: parent.profileId,
      groupProfileOnly: false
    });
  }

  private createNewVersion(product: Product) {
    this.product = new Product({
      name: product.name,
      insuranceLevels: [...product.insuranceLevels],
      termsSelectedBy: product.termsSelectedBy,
      businessArea: product.businessArea,
      insuranceType: product.insuranceType,
      coveragePeriodType: product.coveragePeriodType,
      coveragePeriodUnit: product.coveragePeriodUnit,
      profileId: product.profileId,
      groupProfileOnly: false
    });
    this.profileId = product.profileId.toString();
    this.productService.getProfileProducts(product.profileId.toString())
      .subscribe(result => this.products = result, error => console.error(error));
  }
}
