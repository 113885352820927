import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-caseflow-page',
  templateUrl: './digital-caseflow-page.component.html',
  styleUrls: ['./digital-caseflow-page.component.scss']
})
export class DigitalCaseflowPageComponent implements OnInit {

  shownSection: string;

  ngOnInit() {
    this.shownSection = 'flows';
  }
}
