import { FilteringService } from './sos-services-filtering-service';
import { HelperService } from './../../../../core/services/helper.service';
import { DictionaryService } from './../../../../core/services/dictionary.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ApplicationRef,
} from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { SosServices } from 'app/shared/models/sos-services';
import { SosServicesMenuType } from './sos-services-menu-type';
import { SosServicesService } from 'app/core/services/sos-services.service';

@Component({
  selector: 'app-sos-services-menu',
  templateUrl: './sos-services-menu.component.html',
  styleUrls: ['./sos-services-menu.component.scss'],
})
export class SosServicesMenuComponent implements OnInit {
  @Input() sosServicesListView: SosServices[];
  @Input() filteringSortingMenuType: SosServicesMenuType;
  @Output() modelChanged = new EventEmitter();
  @Output() sosServicesListViewChange = new EventEmitter();
  @Output() clearAllFilters = new EventEmitter();
  sortByAscending: boolean;
  dateFilter: boolean;
  showFilter: boolean;
  sortingKey: string;
  items: CheckBoxModel[];
  fromDate: any;
  toDate: any;
  constructor(
    private sosServicesService: SosServicesService,
    private dictionaryService: DictionaryService,
    private helperService: HelperService,
    private filteringService: FilteringService,

    private applicationRef: ApplicationRef
  ) {}

  ngOnInit() {
    this.populateItems();
  }

  public uncheckItems() {
    if (this.items) {
      this.items.forEach(a => (a.check = false));
    }
  }

  sortAscending() {
    this.sortByAscending = true;
    this.helperService.sortArray(
      this.sosServicesListView,
      this.sortingKey,
      this.sortByAscending
    );
    this.modelChanged.emit();
  }

  sortDescending() {
    this.sortByAscending = false;
    this.helperService.sortArray(
      this.sosServicesListView,
      this.sortingKey,
      this.sortByAscending
    );
    this.modelChanged.emit();
  }

  populateItems() {
    this.items = [];
    switch (this.filteringSortingMenuType) {
      case SosServicesMenuType.name:
        this.sortingKey = 'name';
        this.showFilter = false;
        break;
      case SosServicesMenuType.sosServiceId:
        this.sortingKey = 'sosServiceId';
        this.showFilter = false;
        break;
      case SosServicesMenuType.category:
        this.sosServicesService.getSosServicesCategoryList().subscribe(
          result =>
            result.map(item =>
              this.items.push(new CheckBoxModel(item.id, item.name, false))
            ),
          error => console.error(error)
        );
        this.sortingKey = 'sosServicesCategory';
        this.showFilter = true;
        break;
      case SosServicesMenuType.feeType:
        this.sosServicesService.getFeeTypeList().subscribe(
          result =>
            result.map(item =>
              this.items.push(new CheckBoxModel(item.id, item.name, false))
            ),
          error => console.error(error)
        );
        this.sortingKey = 'feeType';
        this.showFilter = true;
        break;
      case SosServicesMenuType.feePriority:
        this.sortingKey = 'feePriority';
        this.showFilter = false;
        break;
      case SosServicesMenuType.vat:
        this.sortingKey = 'vat';
        this.showFilter = false;
        break;
      case SosServicesMenuType.includedInStandard:
        this.sortingKey = 'includedInStandard';
        this.showFilter = false;
        break;
      case SosServicesMenuType.targetPrice:
        this.sortingKey = 'targetPrice';
        this.showFilter = false;
        break;
      case SosServicesMenuType.validStart:
        this.dateFilter = true;
        let year = new Date().getFullYear();
        let month = new Date().getMonth() + 1;
        let day = new Date().getDate();
        this.fromDate = new NgbDate(year, month, day);
        this.toDate = new NgbDate(year + 1, month, day);
        this.sortingKey = 'validStart';
        this.showFilter = true;
        break;
      case SosServicesMenuType.validEnd:
        {
          this.dateFilter = true;
          let year = new Date().getFullYear();
          let month = new Date().getMonth() + 1;
          let day = new Date().getDate();
          this.fromDate = new NgbDate(year, month, day);
          this.toDate = new NgbDate(year + 1, month, day);
          this.sortingKey = 'validEnd';
          this.showFilter = true;
        }
        break;
    }
  }

  get hasUserCheckedAnything() {
    return !!this.items.find(a => a.check);
  }

  applyButtonClick() {
    if (!this.hasUserCheckedAnything) {
      return;
    }
    this.clearAllFilters.emit();
    let selectedIds = [];
    this.items.filter(a => a.check).forEach(a => selectedIds.push(a.id));
    if (selectedIds.length > 0) {
      this.filteringService.removeStep(this.filteringSortingMenuType);
      this.filteringService.addStep(
        this.filteringSortingMenuType,
        selectedIds,
        this.fromDate
          ? new Date(
              this.fromDate.year,
              this.fromDate.month - 1,
              this.fromDate.day
            )
          : new Date(),
        this.toDate
          ? new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day)
          : new Date()
      );
    } else {
      this.clearAllButtonClick();
    }
    this.sosServicesListView = this.filteringService.filter(
      this.sosServicesListView
    );
    this.sosServicesService.changedSosServiceCount(this.sosServicesListView);
    this.sosServicesListViewChange.emit(this.sosServicesListView);
    this.modelChanged.emit();
  }

  clearAllButtonClick() {
    if (!this.hasUserCheckedAnything) {
      return;
    }
    this.items.map(a => (a.check = false));
    this.filteringService.removeStep(this.filteringSortingMenuType);
    this.clearAllFilters.emit();
    this.sosServicesListView = this.filteringService.filter(
      this.sosServicesListView
    );
    this.sosServicesService.changedSosServiceCount(this.sosServicesListView);
    this.sosServicesListViewChange.emit(this.sosServicesListView);
  }
  modalWindowClick(event: any) {
    event.stopPropagation();
  }

  isLast() {
    return (
      this.filteringSortingMenuType &&
      this.filteringSortingMenuType === SosServicesMenuType.targetPrice
    );
  }
}

export class CheckBoxModel {
  name: string;
  id: string;
  check: boolean;

  constructor(id: string, name: string, check: boolean) {
    this.check = check;
    this.id = id;
    this.name = name;
  }
}
