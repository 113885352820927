import { BusinessAreaEnum } from '../../../models/enums';
import { Component, Input } from '@angular/core';
import { BusinessArea } from 'app/shared/models/customers/business-area';

@Component({
  selector: 'app-business-area-summary',
  templateUrl: './business-area-summary.component.html',
  styleUrls: ['./business-area-summary.component.scss'],
})
export class BusinessAreaSummaryComponent {
  @Input() set areas(values: BusinessArea[]) {
    if (values) {
      this.items = values.map(value => new BusinessAreaItem(value));
    }
  }

  items: BusinessAreaItem[] = [];
}

class BusinessAreaItem extends BusinessArea {
  iconSrc: string;

  constructor(area: BusinessArea) {
    super(area);
    this.iconSrc = this.getIconSrc(area);
  }

  private getIconSrc(area: BusinessArea): string {
    if (area.type === BusinessAreaEnum.TRAVELCARE) {
      if (area.active) {
        return 'assets/icons/travel-big-enabled.png';
      } else {
        return 'assets/icons/travel-big-disabled.png';
      }
    } else if (area.type === BusinessAreaEnum.MOBILITY) {
      if (area.active) {
        return 'assets/icons/roadside-big-enabled.png';
      } else {
        return 'assets/icons/roadside-big-disabled.png';
      }
    } else if (area.type === BusinessAreaEnum.HEALTHCARE) {
      if (area.active) {
        return 'assets/icons/health-big-enabled.png';
      } else {
        return 'assets/icons/health-big-disabled.png';
      }
    }
  }
}
