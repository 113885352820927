<div class="menu">
  <ul class="nav justify-content-center">
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'overview'}" (click)="shownSection = 'overview'">
        <div>Overview</div>
      </div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'relations'}" (click)="shownSection = 'relations'">
        <div>Customer reporting</div>
      </div>
    </li>
  </ul>
</div>
<app-customer-profile-relation *ngIf="shownSection === 'relations'"></app-customer-profile-relation>
<app-customer-profile-relations-overview *ngIf="shownSection === 'overview'"></app-customer-profile-relations-overview>