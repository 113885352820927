import { Component, Input, ViewChild } from '@angular/core';
import { Coverage } from '../../../../../shared/models/coverage';
import { CoverageService } from '../../../../../core/services/coverage-service';
import { CoverageLimitsModalComponent } from './coverage-limits-modal/coverage-limits-modal.component';
import {
  CoverageTypeEnum,
  LimitFromTypeEnum,
  LimitPersonTypeEnum,
  LimitQuantityTypeEnum,
  LimitTypeEnum
} from '../../../../../shared/models/enums';
import { ConfirmModalComponent } from '../../../../../shared/components/modal/confirm-modal/confirm-modal.component';
import { LimitPeriodUnitEnum } from '../../../../../shared/enums/coverage-period-unit-enum';
import { Product } from '../../../../../shared/models/product';
import { PermissionEnum } from '@onepoint/shared/models/permission.enum';
import { PermissionService } from '@onepoint/core/services/permission.service';

@Component({
  selector: 'app-coverages-limits-list',
  templateUrl: './coverages-limits-list.component.html',
  styleUrls: ['./coverages-limits-list.component.scss']
})
export class CoveragesLimitsListComponent {
  @ViewChild('coverageLimitsModal')
  coverageLimitsModal: CoverageLimitsModalComponent;

  @ViewChild('deleteConfirmModal')
  deleteConfirmModal: ConfirmModalComponent;

  coverage: Coverage;
  selectedLimit: number;
  deleteID: number;
  coverageTypes = new Map<string, string>([
    [CoverageTypeEnum.BASIC, 'Basic Coverage'],
    [CoverageTypeEnum.SUPPLEMENT, 'Supplement']
  ]);

  @Input() set product(value: Product) {
    this._product = value;
    this.getCoverages();
  }
  get product(): Product {
    return this._product;
  }
  _product: Product;
  coverages: Coverage[];
  listOfCoveragesNames: string[] = [];

  constructor(private coverageService: CoverageService, private permissionService: PermissionService) { }

  getCoverages() {
    this.coverageService.getCoveragesForProduct(this.product.id).subscribe(
      result => {
        this.coverages = result;
        this.updateListOfCoverageNames();
      },
      error => console.error(error)
    );
  }

  editCoverage(coverage: Coverage) {
    const indexToBeEdited = this.coverages.findIndex(x => x.coverageTermsRef === coverage.coverageTermsRef);
    const reducedListOfCoveragesNames = JSON.parse(JSON.stringify(this.listOfCoveragesNames));
    reducedListOfCoveragesNames.splice(indexToBeEdited, 1);
    this.coverageLimitsModal.open(coverage, reducedListOfCoveragesNames);
  }

  updateListOfCoverageNames() {
    this.listOfCoveragesNames = [];
    this.coverages.forEach((coverage) => {
      this.listOfCoveragesNames.push(coverage.coverageName);
    });
  }

  deleteCoverage() {
    this.coverageService.deleteCoverage(this.deleteID).subscribe(
      ignore => {
        const deleteIndexTest = this.coverages.findIndex(x => x.id === this.deleteID);
        this.coverages.splice(deleteIndexTest, 1);
        this.listOfCoveragesNames.splice(deleteIndexTest, 1);
      },
      error => console.error(error)
    );
  }

  addNewCoverage() {
    this.coverageLimitsModal.createNewCoverageAndOpen(this._product.id, this.listOfCoveragesNames);
  }

  setSelect(value: number) {
    this.selectedLimit = value;
  }

  getSelected() {
    return this.selectedLimit;
  }

  coverageLimitPeriodTypes(value: LimitPeriodUnitEnum) {
    return CoverageService.coverageLimitPeriodTypes.get(value);
  }

  coverageLimitFromTypes(value: LimitFromTypeEnum) {
    return CoverageService.coverageLimitFromTypes.get(value);
  }

  coverageLimitPersonTypes(value: LimitPersonTypeEnum) {
    return CoverageService.coverageLimitPersonTypes.get(value);
  }

  coverageLimitTreatmentsTypes(value: LimitQuantityTypeEnum) {
    return CoverageService.coverageLimitTreatmentsTypes.get(value);
  }

  closeLimits() {
    this.selectedLimit = undefined;
  }

  get getLimitTypeEnum() {
    return LimitTypeEnum;
  }

  refreshCoverages(returnCoverage: Coverage) {
    let addedCoverage = true;
    this.coverages.forEach((coverage, index) => {
      if (coverage.id === returnCoverage.id) {
        this.coverages[index] = returnCoverage;
        addedCoverage = false;
      }
    });
    if (addedCoverage) {
      this.coverages.push(returnCoverage);
    }
    this.resortCoverages();
    this.updateListOfCoverageNames();
  }

  private resortCoverages() {
    this.coverages.sort((a, b) => {
      return a.coverageSortOrder < b.coverageSortOrder ? -1 : 1;
    });
  }

  showDeleteModal(coverageID: number, coverageName: string) {
    this.deleteID = coverageID;
    this.deleteConfirmModal.open('Delete coverage?',
      'You are about to delete coverage & limits for ' + coverageName + '. Do you wish to proceed?');
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  editable() {
    return this.permissionService.checkUserPermission(PermissionEnum.CUSTOMER_COVERAGES_LIMITS_RELATION_UPDATE) ||
      this.permissionService.checkUserPermission(PermissionEnum.CUSTOMER_COVERAGES_LIMITS_RELATION_DELETE);
  }
}
