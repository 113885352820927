import { Profile, ProfileAdapter } from './../../../shared/models/profile';
import { BrandService } from './../../../core/services/brand.service';
import { CustomerListView } from './../../../shared/models/customer-list-view';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UpdateService } from 'app/core/services/update.service';

@Component({
  selector: 'app-brand-page',
  templateUrl: './brand-page.component.html',
  styleUrls: ['./brand-page.component.scss']
})
export class BrandPageComponent implements OnInit, OnDestroy {
  private sub: any;
  customerListView: CustomerListView;
  profiles: CustomerListView[];
  numberOfProfiles: number;
  constructor(
    private route: ActivatedRoute,
    private brandService: BrandService,
    private profileAdapter: ProfileAdapter,
    private updateService: UpdateService
  ) {}

  ngOnInit() {
    this.loadBrand();
    this.updateService.updateBrand.subscribe( value => {
      if (value === true) {
          this.loadBrand();
      }
    });
  }

  loadBrand() {
    this.sub = this.route.params.subscribe(params => {
      this.brandService
        .getBrandListView(params.id)
        .subscribe(
          result => {
            this.customerListView = result;
            this.profiles = result.children
              .map(i => {
                return this.profileAdapter.adaptToCommonCustomerListView(i);
              })
              .sort((a, b) => a.name.localeCompare(b.name, 'da-DK'));
            this.numberOfProfiles = this.profiles.length;
          },
          error => console.log(error)
        );
    });}

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  refreshBrandsEvent(profile: Profile) {
    this.numberOfProfiles++;
    this.profiles.push(this.profileAdapter.adaptToCommonCustomerListView(profile));
  }
}
