<ng-template #content let-modal>
  <div class="modal-container" *ngIf="customerProductRelation">
    <div class="policy-relations-modal-header">
      <div class="policy-relations-title" *ngIf="!existingRelation">Add relation</div>
      <div class="policy-relations-title" *ngIf="existingRelation">Edit relation</div>
      <div class="policy-relations-close" (click)="close();"> <img src="assets/icons/Close.svg" alt="Close button"></div>
    </div>
    <div class="policy-relations-modal-body">
      <div class="input-title-name">Type
        <span class="required">*</span>
      </div>
      <div class="d-flex p-2">
        <div class="custom-control custom-radio me-4" >
          <input type="radio" (change)="clearFieldsInForm()" [(ngModel)]="customerProductRelation.customerProductRelationType" [value]="CustomerProductRelationTypeEnum.BUSINESS_TRAVEL" name="productRelationType"
            [checked]="customerProductRelation.customerProductRelationType == CustomerProductRelationTypeEnum.BUSINESS_TRAVEL"/>
          <label class="input-name">Business travel</label>
        </div>
        <div class="custom-control custom-radio me-4">
          <input type="radio" (change)="clearFieldsInForm()" [(ngModel)]="customerProductRelation.customerProductRelationType" [value]="CustomerProductRelationTypeEnum.EXPATRIATE_INSURANCE" name="productRelationType"
            [checked]="customerProductRelation.customerProductRelationType == CustomerProductRelationTypeEnum.EXPATRIATE_INSURANCE"/>
          <label class="input-name">Expatriate</label>
        </div>

        <div class="custom-control custom-radio me-4">
          <input type="radio" (change)="clearFieldsInForm()" [(ngModel)]="customerProductRelation.customerProductRelationType" [value]="CustomerProductRelationTypeEnum.CREDIT_CARD" name="productRelationType"
            [checked]="customerProductRelation.customerProductRelationType == CustomerProductRelationTypeEnum.CREDIT_CARD"/>
          <label class="input-name">Credit card</label>
        </div>
        <div class="custom-control custom-radio me-4">
          <input type="radio" (change)="clearFieldsInForm()" [(ngModel)]="customerProductRelation.customerProductRelationType" [value]="CustomerProductRelationTypeEnum.GROUP_INSURANCE" name="productRelationType"
            [checked]="customerProductRelation.customerProductRelationType == CustomerProductRelationTypeEnum.GROUP_INSURANCE"/>
          <label class="input-name">Group insurance</label>
        </div>
      </div>
      <div class="d-flex">
        <div class="pe-2 borders-right">
          <div class="input-title-name">Company policy holder
            <span *ngIf=" customerProductRelation.customerProductRelationType === CustomerProductRelationTypeEnum.BUSINESS_TRAVEL ||
                          customerProductRelation.customerProductRelationType === CustomerProductRelationTypeEnum.EXPATRIATE_INSURANCE ||
                          customerProductRelation.customerProductRelationType === CustomerProductRelationTypeEnum.GROUP_INSURANCE"  class="required">*</span></div>
          <div class="policy-relations-input">
            <app-input [disabled]=" customerProductRelation.customerProductRelationType !== CustomerProductRelationTypeEnum.BUSINESS_TRAVEL &&
                                    customerProductRelation.customerProductRelationType !== CustomerProductRelationTypeEnum.EXPATRIATE_INSURANCE &&
                                    customerProductRelation.customerProductRelationType !== CustomerProductRelationTypeEnum.GROUP_INSURANCE"
                                    [(value)]="customerProductRelation.companyPolicyHolder">
            </app-input>
          </div>

          <div class="input-title-name">Policy no.
            <span *ngIf=" customerProductRelation.customerProductRelationType === CustomerProductRelationTypeEnum.BUSINESS_TRAVEL ||
                          customerProductRelation.customerProductRelationType === CustomerProductRelationTypeEnum.EXPATRIATE_INSURANCE ||
                          customerProductRelation.customerProductRelationType === CustomerProductRelationTypeEnum.GROUP_INSURANCE"  class="required">*</span>
          </div>
          <div class="policy-relations-input">
            <app-input [disabled]="!customerProductRelation.customerProductRelationType" [(value)]="customerProductRelation.policyNo"></app-input>
          </div>

          <div class="input-title-name">Bank
            <span *ngIf="customerProductRelation.customerProductRelationType === CustomerProductRelationTypeEnum.CREDIT_CARD"  class="required">*</span>
          </div>
          <div class="policy-relations-input">
            <app-input [disabled]="customerProductRelation.customerProductRelationType !== CustomerProductRelationTypeEnum.CREDIT_CARD"  [(value)]="customerProductRelation.bank"></app-input>
          </div>

          <div class="input-title-name">BIN </div>
          <div class="policy-relations-input">
            <app-input [disabled]="customerProductRelation.customerProductRelationType !== CustomerProductRelationTypeEnum.CREDIT_CARD" type="number" [(value)]="customerProductRelation.bin"></app-input>
          </div>
        </div>

        <div class="ps-2 borders-left">
          <div class="input-title-name">Split date
            <span class="required">*</span>
          </div>
          <div class="policy-relations-input">
            <div class="dropdown-big">
              <select [disabled]="!customerProductRelation.customerProductRelationType" class="input-component coverage-period-value-container-unit" [(ngModel)]="customerProductRelation.splitDateType">
                <option *ngFor="let item of splitDatesTypesDictionary" [value]="item.id">{{item.name}}</option>
              </select>
            </div>
          </div>
          <div class="input-title-name">Valid from
            <span class="required">*</span>
          </div>
          <div class="policy-relations-input">
            <app-input-calendar
              [disabled]="!customerProductRelation.customerProductRelationType"
              [withImage]="true"
              [minDate]="minDate"
              [maxDate]="maxDate"
              [selectMode]="'single'"
              [(dateTimeRange)]="customerProductRelation.validFrom">
            </app-input-calendar>
          </div>
          <div class="input-title-name">Valid to</div>
          <div class="policy-relations-input">
            <app-input-calendar
              [disabled]="!customerProductRelation.customerProductRelationType || !customerProductRelation.validFrom"
              [withImage]="true"
              [required]="false"
              [minDate]="customerProductRelation.validFrom"
              [maxDate]="maxDate"
              [selectMode]="'single'"
              [(dateTimeRange)]="customerProductRelation.validTo">
            </app-input-calendar>
          </div>
          <div class="input-title-name">Remarks</div>
          <div class="policy-relations-input">
            <textarea
              name="remarks"
              rows="4"
              [disabled]="!customerProductRelation.customerProductRelationType"
              [(ngModel)]="customerProductRelation.remarks"
              [maxLength]="250">
            </textarea>
          </div>
        </div>
      </div>
      <div class="pr-2">
        <div class="input-title-name">Customer reporting label</div>
        <div>
          <div class="dropdown-big">
            <select [disabled]="!customerProductRelation.customerProductRelationType" class="input-component coverage-period-value-container-unit" (change)="changeRelationLabel()" [(ngModel)]="selectedCustomerRelationLabel">
              <option *ngFor="let item of dropDownCustomerRelationLabelsList" [value]="item.id">{{item.name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="policy-relations-modal-footer">
        <button [disabled]="formNotFilledOut() || existingWithNoChanges()" class="primary-button" (click)="saveProductRelation()">
          <span *ngIf="!customerProductRelation.id">Save</span>
          <span *ngIf="customerProductRelation.id">Save changes</span>
        </button>
        <div class="float-end me-2 w-75 errormessage" *ngIf="!checkForSameValuesInNewCustomerProductRelation() && customerProductRelation.customerProductRelationType === CustomerProductRelationTypeEnum.CREDIT_CARD && !existingWithNoChanges()">A relation with this bank and/or BIN no. already exists for this period or with an open end date. A new relation cannot be added until an end date has been set on the existing relation or data in bank or BIN no. is changed in the new relation.</div>
      </div>
    </div>
  </div>
</ng-template>
