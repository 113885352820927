import {Component, TemplateRef, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  @Input() confirmBtnLabel = 'Ok';
  @Input() cancelBtnLabel = 'Cancel';
  @ViewChild('content', { static: true }) template: TemplateRef<any>;
  @Output() confirmed = new EventEmitter<any>();
  @Output() cancelled = new EventEmitter<any>();
  @Input() messageTemplate: TemplateRef<any>;

  title: string;
  message: string;
  object: any;

  constructor(
    private modalService: NgbModal
  ) {
  }

  cancel() {
    this.modalService.dismissAll();
    this.cancelled.emit(this.object);
  }

  confirm() {
    this.modalService.dismissAll();
    this.confirmed.emit(this.object);
  }

  public open(title = '', message = '', object = {}) {
    this.title = title;
    this.message = message;
    this.object = object;
    this.modalService.open(this.template, { windowClass : 'modal-container' });
  }

}
