import { IndexService } from './../../core/services/index.service';
import { Index } from './../../shared/models/index';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AddNewIndexComponent } from './add-new-index/add-new-index.component';
import { SettingsService } from 'app/core/services/settings.service';

@Component({
  selector: 'app-indexes-page',
  templateUrl: './indexes-page.component.html',
  styleUrls: ['./indexes-page.component.scss'],
})
export class IndexesPageComponent implements OnInit {
  dropDownYearList: { id: string; name: string }[];
  @ViewChild('addNewIndexComponent')
  addNewIndexComponent: AddNewIndexComponent;
  presentDate: Date = new Date();
  selectedYear = Number(this.presentDate.getFullYear()).toString();
  indexes: Index[];
  originalIndexes: Index[];
  namevalue: string;
  theChangesCanBeSaved = false;
  nameUpdateList: { oldName: string; newName: string }[];
  theSorting: string[];
  buttonType = 'run';
  timeStampOfLastIndexJob: Date;
  warningCaseOfFailingIndexJob = false;
  baseURL: string;
  constructor(
    private indexService: IndexService,
    private settingService: SettingsService
  ) {
    this.baseURL = this.settingService.getBffEndpoint();
  }
  ngOnInit() {
    this.dropDownYearList = [];
    const firstIndexYear = 2015;
    for (
      let i = firstIndexYear;
      i <= Number(this.presentDate.getFullYear()) + 5;
      i++
    ) {
      this.dropDownYearList.push({ id: i.toString(), name: i.toString() });
    }
    this.getYear();
  }

  percentUpdate(value: string, i: number) {
    if (value !== null || value !== '') {
      const lastCharacter = value.substring(value.length - 1);
      const stringWithDot = value.toString().replace(',', '.');
      const stringWithComma = value.toString().replace('.', ',');
      if (lastCharacter === '%') {
        this.indexes[i].indexValue = stringWithComma;
        this.indexes[i].indexNumberForSorting = Number(
          stringWithDot.slice(0, -1)
        );
      } else {
        this.indexes[i].indexValue = stringWithComma + '%';
        this.indexes[i].indexNumberForSorting = Number(stringWithDot);
      }
    }
    const regexp = new RegExp(
      '^[-/+]{0,2}[0-9]{1,3}[.,]{0,2}[0-9]{0,3}[%]{0,1}$'
    );
    const regexp2 = new RegExp('^[-/+]{0,2}[0-9]{3}[0-9]{1,3}[%]{0,1}$');
    if (
      !regexp.test(this.indexes[i].indexValue) ||
      regexp2.test(this.indexes[i].indexValue)
    ) {
      this.indexes[i].indexValue = '';
    }
    this.checkIfTheDataCanBeSaved(null, null);
  }

  checkIfTheDataCanBeSaved(nameValue: string, i: string) {
    this.theChangesCanBeSaved = true;
    this.buttonType = 'saveAndRun';
    this.indexes.forEach(theIndex => {
      if (
        theIndex.name === null ||
        theIndex.name === '' ||
        (theIndex.id !== null &&
          (theIndex.indexValue === null || theIndex.indexValue === ''))
      ) {
        this.theChangesCanBeSaved = false;
      }
      if (
        i !== null &&
        theIndex.nameTableId !== i &&
        theIndex.name === nameValue
      ) {
        this.theChangesCanBeSaved = false;
      }
    });
    this.originalIndexes.forEach(theIndex => {
      if (theIndex.name === nameValue) {
        this.theChangesCanBeSaved = false;
      }
    });
  }

  yearDropDownChange() {
    this.theSorting = [];
    this.indexes.forEach(index => {
      this.theSorting.push(index.nameTableId);
    });
    this.getYear();
  }

  getYear() {
    this.indexService.getIndexesOnYear(this.selectedYear).subscribe(result => {
      this.timeStampOfLastIndexJob = null;
      this.indexes = result;
      this.originalIndexes = JSON.parse(JSON.stringify(result));
      this.indexes.forEach(index => {
        if (index.indexValue !== null) {
          const stringWithDot = index.indexValue.toString().replace(',', '.');
          index.indexNumberForSorting = Number(stringWithDot.slice(0, -1));
        } else {
          index.indexNumberForSorting = -1000;
        }
        if (index.lastRunIndexJobDate !== null) {
          this.timeStampOfLastIndexJob = index.lastRunIndexJobDate;
        }
      });
      if (this.theSorting) {
        this.resortTheList(result, this.theSorting);
      }
    });
  }

  saveIndexes() {
    this.buttonType = 'runingJob';
    const indexToSend: Index[] = new Array();
    this.theSorting = [];
    this.indexes.forEach(index => {
      this.theSorting.push(index.nameTableId);
    });
    this.originalIndexes.forEach(index => {
      const theIndexToPush = this.indexes.filter(
        value =>
          value.nameTableId === index.nameTableId &&
          (value.indexValue !== index.indexValue || value.name !== index.name)
      )[0];
      if (theIndexToPush !== undefined) {
        indexToSend.push(theIndexToPush);
      }
    });
    this.indexService.updateIndexes(indexToSend, this.selectedYear).subscribe(
      result => {
        this.warningCaseOfFailingIndexJob = false;
        this.timeStampOfLastIndexJob = null;
        this.indexes = result;
        this.originalIndexes = JSON.parse(JSON.stringify(result));
        this.theChangesCanBeSaved = false;
        this.indexes.forEach(index => {
          if (index.indexValue !== null) {
            const stringWithDot = index.indexValue.toString().replace(',', '.');
            index.indexNumberForSorting = Number(stringWithDot.slice(0, -1));
          } else {
            index.indexNumberForSorting = -1000;
          }
          if (index.lastRunIndexJobDate !== null) {
            this.timeStampOfLastIndexJob = index.lastRunIndexJobDate;
          }
        });
        this.resortTheList(result, this.theSorting);
        this.buttonType = 'run';
      },
      error => {
        this.buttonType = 'run';
        this.warningCaseOfFailingIndexJob = true;
      }
    );
  }

  resortTheList(indexexToSorting: Index[], sortingOrder: string[]) {
    this.indexes = [];
    sortingOrder.forEach(i => {
      this.indexes.push(
        indexexToSorting.filter(value => value.nameTableId === i)[0]
      );
    });
  }

  refreshIndexEvent(newIndexList: Index[]) {
    this.indexes = newIndexList;
    this.originalIndexes = JSON.parse(JSON.stringify(newIndexList));
  }

  addNewIndex() {
    this.addNewIndexComponent.open();
  }
}
