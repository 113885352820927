<div class="page-container">
  <div class="page-header">
    <div class="my-auto counts-container">
      <div class="count-container">
        <span>Commitment types</span> <span>{{ masterListFiltered?.length }}</span>
      </div>
    </div>
    <div class="status-container my-auto">
      <app-drop-down [(selectedItemId)]="selectedStatus" [items]="statusTypes" (selectedItemIdChange)="selectedStatusTypeChanged($event)">
      </app-drop-down>
    </div>

    <div class="button-container my-auto">
      <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_CREATE">
        <button class="primary-button" (click)="addNew()">
          Add Commitment Type
        </button>
      </div>
    </div>
  </div>
  <div class="primary-table page-content-container">
    <table class="master-lists-table">
      <thead>
        <tr>
          <td (click)="showFilteringSortingMenu(CommitmentTypeMenuType.BUSINESS_KEY)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Business key</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(CommitmentTypeMenuType.BUSINESS_KEY) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CommitmentTypeMenuType.BUSINESS_KEY"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CommitmentTypeMenuType.NAME)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Name</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(CommitmentTypeMenuType.NAME) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CommitmentTypeMenuType.NAME"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CommitmentTypeMenuType.UI_SORT_INDEX)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Sort in UI</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(CommitmentTypeMenuType.UI_SORT_INDEX) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CommitmentTypeMenuType.UI_SORT_INDEX"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CommitmentTypeMenuType.SUPPLIER_INVOICE)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Supplier Invoice</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(CommitmentTypeMenuType.SUPPLIER_INVOICE) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CommitmentTypeMenuType.SUPPLIER_INVOICE"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>

          <td (click)="showFilteringSortingMenu(CommitmentTypeMenuType.REFUND)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Refund</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(CommitmentTypeMenuType.REFUND) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CommitmentTypeMenuType.REFUND"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CommitmentTypeMenuType.HANDLING_AREA_COST_CONTROL)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Handling Area Cost Control</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(CommitmentTypeMenuType.HANDLING_AREA_COST_CONTROL) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CommitmentTypeMenuType.REFUND"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CommitmentTypeMenuType.HANDLING_AREA_CLAIMS)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Handling Area Claims</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(CommitmentTypeMenuType.HANDLING_AREA_CLAIMS) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CommitmentTypeMenuType.HANDLING_AREA_CLAIMS"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>

          <td (click)="showFilteringSortingMenu(CommitmentTypeMenuType.EXCLUDE_FROM_EUS)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Exclude from EUS</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(CommitmentTypeMenuType.EXCLUDE_FROM_EUS) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CommitmentTypeMenuType.EXCLUDE_FROM_EUS"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CommitmentTypeMenuType.GOP)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>GOP</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(CommitmentTypeMenuType.GOP) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CommitmentTypeMenuType.GOP"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CommitmentTypeMenuType.SOS_CLAIMS)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>SOS Claims</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(CommitmentTypeMenuType.SOS_CLAIMS) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CommitmentTypeMenuType.SOS_CLAIMS"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CommitmentTypeMenuType.RECOVERY)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Recovery</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(CommitmentTypeMenuType.RECOVERY) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CommitmentTypeMenuType.RECOVERY"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CommitmentTypeMenuType.ACTIVATE_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Activation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(CommitmentTypeMenuType.ACTIVATE_ON) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CommitmentTypeMenuType.ACTIVATE_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CommitmentTypeMenuType.DEACTIVATION_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Deactivation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(CommitmentTypeMenuType.DEACTIVATION_ON) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CommitmentTypeMenuType.DEACTIVATION_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of masterListFiltered" (click)="edit(item)">
          <td>
            <div class="first-column-container">
              <div class="first-column-content">
                {{ item.businessKey }}
              </div>
            </div>
          </td>
          <td>
            {{ item.name }}
          </td>
          <td>
            {{ item.uiSort }}
          </td>
          <td class="align-center">
            <input type="checkbox" [checked]="item.supplierInvoice" disabled="true" />
          </td>
          <td class="align-center">
            <input type="checkbox" [checked]="item.refund " disabled="true" />
          </td>
          <td class="align-center">
            <input type="checkbox" [checked]="item.handlingAreaCostControl " disabled="true" />
          </td>
          <td class="align-center">
            <input type="checkbox" [checked]="item.handlingAreaClaims " disabled="true" />
          </td>
          <td class="align-center">
            <input type="checkbox" [checked]="item.excludeFromEUS" disabled="true" />
          </td>
          <td class="align-center">
            <input type="checkbox" [checked]="item.gop" disabled="true" />
          </td>
          <td class="align-center">
            <input type="checkbox" [checked]="item.sosClaims" disabled="true" />
          </td>
          <td class="align-center">
            <input type="checkbox" [checked]="item.recovery" disabled="true" />
          </td>
          <td>
            {{ item.activateOn | date: 'dd MMM yyyy' }}
          </td>
          <td>
            {{ item.deactivateOn | date: 'dd MMM yyyy' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-template #MasterListModal>
    <app-masterlist-commitment-type-modal [masterListType]="masterListType" (refreshTable)="reload()"> </app-masterlist-commitment-type-modal>
  </ng-template>
</div>
