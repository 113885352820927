<div class="page-container">
  <div class="page-header">
    <div class="my-auto counts-container">
      <div class="count-container">
        <span>Case Types</span> <span>{{ masterListFiltered?.length }}</span>
      </div>
    </div>
    <div class="status-container my-auto">
      <app-drop-down [(selectedItemId)]="selectedStatus" [items]="statusTypes" (selectedItemIdChange)="selectedStatusTypeChanged($event)">
      </app-drop-down>
    </div>
  </div>
  <div class="primary-table page-content-container">
    <table class="master-lists-table">
      <thead>
        <tr>
          <td (click)="showFilteringSortingMenu(CaseTypeMenuType.CASE_TYPE_CODE)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Business key</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[CaseTypeMenuType.CASE_TYPE_CODE] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CaseTypeMenuType.CASE_TYPE_CODE"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CaseTypeMenuType.CASE_TYPE_NAME)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Case Type name</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[CaseTypeMenuType.CASE_TYPE_NAME] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CaseTypeMenuType.CASE_TYPE_NAME"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CaseTypeMenuType.CASE_TYPE_ENUM_VALUE)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Case Type Enum value</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[CaseTypeMenuType.CASE_TYPE_ENUM_VALUE] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CaseTypeMenuType.CASE_TYPE_ENUM_VALUE"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CaseTypeMenuType.ORDER)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Priority</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[CaseTypeMenuType.ORDER] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CaseTypeMenuType.ORDER"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CaseTypeMenuType.UI_SORT_INDEX)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Sort in UI</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[CaseTypeMenuType.UI_SORT_INDEX] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CaseTypeMenuType.UI_SORT_INDEX"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CaseTypeMenuType.DESCRIPTION)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Description</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[CaseTypeMenuType.DESCRIPTION] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CaseTypeMenuType.DESCRIPTION"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CaseTypeMenuType.CASE_TYPE_RESET_DISABLED)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Resume case type evaluation disabled</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[CaseTypeMenuType.CASE_TYPE_RESET_DISABLED] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CaseTypeMenuType.CASE_TYPE_RESET_DISABLED"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>          
          <td (click)="showFilteringSortingMenu(CaseTypeMenuType.ACTIVATE_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Activation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[CaseTypeMenuType.ACTIVATE_ON] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CaseTypeMenuType.ACTIVATE_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CaseTypeMenuType.DEACTIVATION_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Deactivation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[CaseTypeMenuType.DEACTIVATION_ON] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CaseTypeMenuType.DEACTIVATION_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of masterListFiltered" (click)="edit(item)">
          <td>
            <div class="first-column-container">
              <div class="first-column-content">
                {{ item.caseTypeCode }}
              </div>
            </div>
          </td>
          <td>
            {{ item.caseTypeName }}
          </td>
          <td>
            {{ item.caseTypeEnumValue }}
          </td>
          <td>
            {{ item.order }}
          </td>
          <td>
            {{ item.uiSortIndex }}
          </td>
          <td>
            {{ item.description }}
          </td>
          <td class="align-center">
            <input type="checkbox" [checked]="item.caseTypeResetDisabled" disabled="true" />
          </td>          
          <td>
            {{ item.activateOn | date: 'dd MMM yyyy' }}
          </td>
          <td>
            {{ item.deactivateOn | date: 'dd MMM yyyy' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-template #MasterListModal>
    <app-masterlist-case-type-modal [masterListType]="masterListType" (refreshTable)="reload()"> </app-masterlist-case-type-modal>
  </ng-template>
</div>
