<div class="title">
  Business areas
</div>
<div class="row">
  <div class="col-4 area" *ngFor="let area of items">
    <img [src]="area.iconSrc"/>
    <div [ngClass]="{ active: area.active, inactive: !area.active}">
      {{area.displayName}}
    </div>
    <div [ngClass]="{ active: area.active, inactive: !area.active}">
      {{ area.segment?.name }}
    </div>
  </div>
</div>