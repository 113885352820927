import { Brand } from './../../../../../shared/models/brand';
import { CommonCustomerNewBrandComponent } from './common-customer-new-brand/common-customer-new-brand.component';
import { Router } from '@angular/router';
import { CustomerListView } from 'app/shared/models/customer-list-view';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { RoutesName } from 'app/app-routes-name';
import { SettingsService } from 'app/core/services/settings.service';

@Component({
  selector: 'app-common-customer-brands',
  templateUrl: './common-customer-brands.component.html',
  styleUrls: ['./common-customer-brands.component.scss']
})
export class CommonCustomerBrandsComponent implements OnInit {
  @ViewChild('commonCustomerNewBrandComponent')
  commonCustomerNewBrandComponent: CommonCustomerNewBrandComponent;
  @Input() commonCustomer: CustomerListView;
  @Input() numberOfBrands: number;
  @Input() brands: CustomerListView[];
  @Output() refreshBrands = new EventEmitter<Brand>();
  baseURL: string;
  constructor(
    private router: Router,
    private settingService: SettingsService) {
      this.baseURL = this.settingService.getBffEndpoint();
    }

  ngOnInit() {}

  brandClick(brand: CustomerListView) {
    this.router.navigate([RoutesName.customers + '/' + RoutesName.brand, brand.id]);
  }

  addNewBrand() {
    this.commonCustomerNewBrandComponent.open();
  }

  refreshBrandsEvent(brand: Brand) {
    this.refreshBrands.emit(brand);
  }
}
