import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';
import { SettingsService } from 'app/core/services/settings.service';
import { BusinessAreaEnum } from './enums';

export class Kam {
  userId: string;
  firstName: string;
  surname: string;
  email: string;
  phoneNumber: string;
  imageUrl: string;
  areas: BusinessAreaEnum[];

  public constructor(init?: Partial<Kam>) {
    Object.assign(this, init);
  }

  public getFullName() {
    return this.firstName + ' ' + this.surname;
  }
}

@Injectable({
  providedIn: 'root',
})
export class KamAdapter implements Adapter<Kam> {
  constructor(private settingService: SettingsService) {}

  adapt(item: any): Kam {
    return new Kam({
      userId: item.userId,
      firstName: item.firstName,
      surname: item.surname,
      email: item.userWorkEmail,
      phoneNumber: item.userWorkPhone,
      imageUrl: this.getImageUrl(item.userId),
      areas: item.areas,
    });
  }

  private getImageUrl(kamId: string) {
    return this.settingService.getBffEndpoint() + 'api/users/' + kamId + '/user-images-by-user-id';
  }
}
