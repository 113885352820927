<div class="add-new-brand-container justify-content-between">
  <span class="brands-value">Brands ({{numberOfBrands}})</span>
  <button class="primary-button my-auto" (click)="addNewBrand()">Add new Brand</button>
</div>

<div class="brand-list-item" *ngFor="let brand of brands" (click)="brandClick(brand)">
  <div class="icon-name-markets">
    <div class="icon"> <img [src]="this.baseURL+'api'+brand.iconBlob"></div>


    <div class="name">
      <span class="brand-name">{{brand.name}}</span>
      <span class="brand-brand">Brand</span>
    </div>
    <div class="markets">
      {{brand.market}}
      <div class="title">Markets</div>
    </div>
    <div class="areas-kam">

      <div class="areas">
        <img *ngIf="brand.isTravel" src="assets/icons/travel-enabled.png">
        <img *ngIf="!brand.isTravel" src="assets/icons/travel-disabled.png">
        <img *ngIf="brand.isRoadside" src="assets/icons/roadside-enabled.png">
        <img *ngIf="!brand.isRoadside" src="assets/icons/roadside-disabled.png">
        <img *ngIf="brand.isHealth" src="assets/icons/health-enabled.png">
        <img *ngIf="!brand.isHealth" src="assets/icons/health-disabled.png"></div>

      <div class="kam">
        {{brand.keyAccountManagerFirstName}} {{brand.keyAccountManagerSurname}}
        <div class="title">KAM</div>
      </div>

    </div>
    <div class="profiles"> {{brand.numberOfProfiles}}
      <div class="title">Profiles</div>
    </div>
  </div>

</div>

<app-common-customer-new-brand #commonCustomerNewBrandComponent [commonCustomerListView]="commonCustomer" (refreshBrands)="refreshBrandsEvent($event)">
</app-common-customer-new-brand>
