import { Component, OnInit, Input } from '@angular/core';
import { Kam } from 'app/shared/models/kam';

@Component({
  selector: 'app-kam-info-view',
  templateUrl: './kam-info-view.component.html',
  styleUrls: ['./kam-info-view.component.scss']
})
export class KamInfoViewComponent implements OnInit {

  @Input() kam: Kam;

  constructor() { }

  ngOnInit() {
  }

  get areas() {
    return this.kam.areas;
  }

  get imageUrl() {
    return this.kam.imageUrl;
  }

}
