import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';
import {SlaAgreement} from "./sla-agreement";
import {SlaRule, SlaRuleAdapter} from "./sla-rule";
import {SlaCategory, SlaCategoryAdapter} from "@onepoint/shared/models/sla-category";
import {SlaEvent, SlaEventAdapter} from "@onepoint/shared/models/sla-event";

export class Sla {
  id: number;
  deadlineTime: number;
  requirement: number;
  name: string;
  standard: boolean;
  category: SlaCategory;
  startEvents: SlaEvent[];
  endEvents: SlaEvent[];
  rules: SlaRule[];

    public constructor(init?: Partial<Sla>) {
        Object.assign(this, init);
      }
}


@Injectable({
    providedIn: 'root'
  })
  export class SlaAdapter implements Adapter<Sla> {
    adapt(item: any): Sla {
      const slaRuleAdapter = new SlaRuleAdapter();
      const slaCategoryAdapter = new SlaCategoryAdapter();
      const slaEventAdapter = new SlaEventAdapter();
      return new Sla({
        id: item.id,
        deadlineTime: item.deadlineTime,
        requirement: item.requirement,
        name: item.name,
        category: slaCategoryAdapter.adapt(item.category),
        standard: item.standard,
        startEvents: item.startEvents?.map(slaEventAdapter.adapt),
        endEvents: item.endEvents?.map(slaEventAdapter.adapt),
        rules: item.rules?.map(slaRuleAdapter.adapt),
      });
    }
  }
