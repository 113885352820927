import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from './settings.service';
import {CommonCustomer} from '@onepoint/shared/models/common-customer';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SlaCalculatedEvent, SlaCalculatedEventAdapter} from "@onepoint/shared/models/sla-calculated-event";

const slaRuleUrl = 'sla/calculated-event';

@Injectable({
  providedIn: 'root',
})
export class SlaCalculatedEventService extends BaseService {
  constructor(
    private settingsService: SettingsService,
    private http: HttpClient,
    private slaCalculatedEventAdapter: SlaCalculatedEventAdapter
  ) {
    super(settingsService);
  }

  public getSlaCalculatedEvents(): Observable<SlaCalculatedEvent[]> {
    return this.http
      .get(this.baseURL + slaRuleUrl, {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.slaCalculatedEventAdapter.adapt(item))));
  }

  public getSlaCalculatedEvent(slaCalculatedEventId: number): Observable<SlaCalculatedEvent> {
    return this.http
      .get(this.baseURL + slaRuleUrl +'/'+ slaCalculatedEventId, {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.slaCalculatedEventAdapter.adapt(item)));
  }

  public saveSlaCalculatedEvent(slaCalculatedEvent: SlaCalculatedEvent): Observable<SlaCalculatedEvent> {
    return this.http
      .put<CommonCustomer>(this.baseURL + slaRuleUrl, slaCalculatedEvent, {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.slaCalculatedEventAdapter.adapt(item)));
  }

  public getSlaCalculatedEventTypes(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + slaRuleUrl + "/calculatedEventTypes", {
      headers: this.jsonHeaders,
    });
  }

}
