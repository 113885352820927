import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MasterListService } from 'app/core/services/masterlist.service';
import { OnepointModalService, Size } from 'app/core/services/onepoint.modal.service';
import { MasterListServiceItemModel } from 'app/shared/models/masterlist-service-item-model';
import * as _ from 'lodash';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import { Dictionary } from 'app/shared/models/dictionary';
import { SosServicesService } from 'app/core/services/sos-services.service';
import {MasterListServiceTypeModel} from "../../../shared/models/masterlist-service-type-model";
import { ReasonForCancellationMenuType } from './reason-for-cancellation-menu/masterlist-reason-for-cancellation-menu-type';
import { MasterListReasonForCancellationModel } from '@onepoint/shared/models/masterlist-reason-for-cancellation-model';
import { MasterlistLanguages } from '../masterlist-languages';

@Component({
  selector: 'app-masterlist-reason-for-cancellation',
  templateUrl: './masterlist-reason-for-cancellation.component.html',
  styleUrls: ['./masterlist-reason-for-cancellation.component.scss'],
})
export class MasterListReasonForCancellationComponent implements OnInit {
  @ViewChild('MasterListModal')
  private masterListModalTemplate: TemplateRef<any>;

  masterList: MasterListReasonForCancellationModel[];
  masterListFiltered: MasterListReasonForCancellationModel[];
  masterListItem: MasterListReasonForCancellationModel;
  showMenu = new Map<ReasonForCancellationMenuType, boolean>();
  serviceTypesMap = new Map<string, string>();
  statusTypes: Dictionary[] = [
    { id: 'all', name: 'All' },
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
  ];
  selectedStatus = 'all';

  languages = MasterlistLanguages;

  constructor(
    private masterListService: MasterListService,
    private onepointModalService: OnepointModalService,
    private sosServicesService: SosServicesService
  ) {}

  ngOnInit(): void {
    this.sosServicesService.getSosServicesTypeList().subscribe(response => {
      response.forEach(item => this.serviceTypesMap.set(item.type, item.name));
    });
 
    this.loadMasterList();
  }

  showFilteringSortingMenu(filteringSortingMenuType: ReasonForCancellationMenuType) {
    this.showMenu.forEach((value, key) => {
      if (key !== filteringSortingMenuType) {
        this.showMenu.set(key, false);
      }
    });

    this.showMenu.set(filteringSortingMenuType, !this.showMenu.get(filteringSortingMenuType));
  }

  isShowMenu(filteringSortingMenuType: ReasonForCancellationMenuType) {
    return this.showMenu.get(filteringSortingMenuType);
  }

  addNew(): void {
    this.masterListItem = new MasterListReasonForCancellationModel();
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.LARGE, 'reason-for-cancellation-modal-container');
  }

  edit(item: MasterListServiceItemModel): void {
    this.masterListItem = _.clone(item);
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.LARGE, 'reason-for-cancellation-modal-container');
  }

  selectedStatusTypeChanged(status: string): void {
    this.masterListFiltered = this.filterByStatus(status);
  }

  reload(): void {
    this.loadMasterList();
  }

  hasDuplicateTranslation(item: MasterListReasonForCancellationModel, lang: string) {    
    if (lang !== MasterlistLanguages.EN && !!item.translationsAvailable[lang]) {
      return item.translationsAvailable[lang] === item.translationsAvailable[MasterlistLanguages.EN];
    }
    return false;
  }

  translationSort(lang: string) {
    return (a: MasterListReasonForCancellationModel, b: MasterListReasonForCancellationModel) => {
      const av = !!a.translationsAvailable[lang];
      const bv = !!b.translationsAvailable[lang];
      return +av - +bv;
    };
  }

  keepOrder = (a, b) => {
    return a;
  }

  get ReasonForCancellationMenuType() {
    return ReasonForCancellationMenuType;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  private loadMasterList() {
    this.masterListService.getReasonForCancellationList().subscribe(response => {
      this.masterList = response;
      this.masterListFiltered = this.filterByStatus(this.selectedStatus);
    });
  }

  private filterByStatus(status: string): MasterListReasonForCancellationModel[] {
    if (status === 'all') {
      return this.masterList;
    }
    return this.masterList.filter(item => (status === 'active' ? item.active : !item.active));
  }
}
