import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomerListView } from 'app/shared/models/customer-list-view';
import { Product } from 'app/shared/models/product';
import { ProfileChangeService } from 'app/core/services/profile-change';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit {
  @Input() profile: CustomerListView;
  @Input() products: CustomerListView[];
  @Input() numberOfProducts: number;
  @Output() refreshProducts = new EventEmitter<Product>();

  shownSection: string;

  constructor(private profileChange: ProfileChangeService) { }

  ngOnInit() {
    this.shownSection = 'overview';
    this.profileChange.currentProfile.subscribe(
      profile => {
        this.profileChange = this.profileChange;
    });
  }

  refreshProductsEvent(event) {
    this.refreshProducts.emit(event);
  }
}
