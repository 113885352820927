import { Adapter } from '../../interfaces/adapter';
import { Injectable } from '@angular/core';
import { TemplateTypes } from './template.types.enum';
import { NgOptionTemplateDirective } from '@ng-select/ng-select/lib/ng-templates.directive';


export class DocumentTemplate {
  id: string;
  name: string;
  fileName: string;
  path: string;
  templateType: TemplateTypes;
  documentType: string;
  receiverType: string;
  serviceType: string;
  languageCode: string;
  tags: string[];
  subject: string;


  public constructor(init?: Partial<DocumentTemplate>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DocumentTemplateAdapter implements Adapter<DocumentTemplate> {
  constructor() { }
  adapt(item: any): DocumentTemplate {
    const brand = new DocumentTemplate({
      id: item.id,
      name: item.name,
      fileName: item.fileName,
      path: item.path,
      templateType: TemplateTypes[item.type] as TemplateTypes,
      documentType: item.documentType,
      serviceType: item.serviceType,
      languageCode: item.languageCode,
      tags: item.tags,
      receiverType: item.receiverType,
      subject: item.subject
    });
    return brand;
  }
}
