<ng-template #content let-modal>
    <div class="onepoint-modal-container">
        <div class="onepoint-modal-header">
          <div class="onepoint-modal-header-title">
              Add new label<br/>
              <div class="subTitle">Label settings</div>
            </div>
          <div class="onepoint-modal-header-close" (click)="close();"> <img src="assets/icons/Close.svg"></div>
        </div>
        <div class="onepoint-modal-body p-3">
            <div class="input-title-name">
                Label name
                <span class="required">*</span>
            </div>
            <app-input  [(value)]="customerRelationLabel.name" (valueChange)="checkIfItCanBeSaved()"></app-input>
            <div class="input-title-name">
                Remarks
            </div>
            <app-input [(value)]="customerRelationLabel.remarks"></app-input>
          <div class="onepoint-modal-footer">
            <button
              class="primary-button save-btn"
              (click)="save()"
              [ngClass]="!canItBeSaved && 'button-disabled'"
              [disabled]="!canItBeSaved"
            >
              Add
            </button>
          </div>
        </div>
    </div>
</ng-template>
