import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import {ProfileApplication, ProfileApplicationAdapter} from '../../shared/models/profile-application';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService extends BaseService {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private profileApplicationAdapter: ProfileApplicationAdapter
  ) {
    super(settingsService);
  }

  public getApplicationsByProfile(applicationType: string, profileId: string): Observable<ProfileApplication[]> {
    return this.http.
      get(this.baseURL + `application/${applicationType}/${profileId}`, {headers : this.jsonHeaders})
      .pipe(map((data: any[]) => data.map(item => this.profileApplicationAdapter.adapt(item))));
  }

  public getApplicationsByApplicationType(applicationType: string): Observable<ProfileApplication[]> {
    return this.http.
      get(this.baseURL + `application/application-type/${applicationType}`, {headers : this.jsonHeaders})
      .pipe(map((data: any[]) => data.map(item => this.profileApplicationAdapter.adapt(item))));
  }

  public updateApplication(profileApplications: ProfileApplication[]): Observable<ProfileApplication[]> {
    return this.http.post<ProfileApplication[]>(this.baseURL + 'application/applications', profileApplications, {
      headers: this.jsonHeaders,
    });
  }
}
