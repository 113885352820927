<div class="input">
  <span class="input-name">{{ name }}</span
  ><span class="required" *ngIf="required">*</span><br />
  <select class="money-currency" [(ngModel)]="selectedCurrency" (change)="onChangeValue()" [disabled]="disabled" >
    <option value="DKK" default>DKK</option>
  </select>
  <input
    class="money-amount"
    currencyMask
    [maxLength]="maxTextLength"
    [(ngModel)]="value"
    (ngModelChange)="onChangeValue()"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2 }"
  />
</div>
