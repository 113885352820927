import { MasterListServiceTypeModel } from "./masterlist-service-type-model";

export class MasterListCommitmentTypeModel {
  id: string;
  businessKey: string;
  uiSort: number;
  description: string;
  supplierInvoice: boolean;
  refund: boolean;
  name: string;
  handlingAreaCostControl: boolean;
  handlingAreaClaims: boolean;
  sosClaims: boolean;
  servicesCategory: string;
  excludeFromEUS: boolean;
  gop: boolean;
  recovery: boolean;
  activateOn: Date;
  deactivateOn: Date;
  active: boolean;
  serviceTypes: MasterListServiceTypeModel[];


  public constructor(init?: Partial<MasterListCommitmentTypeModel>) {
    Object.assign(this, init);
  }
}
