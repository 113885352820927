
<div class="page-container">
  <div class="page-header">
      <div class="my-auto counts-container">
        <div class="count-container">
          <span>Causes Items</span> <span>{{masterListFiltered?.length}}</span>
        </div>
      </div>
      <div class="status-container my-auto">
        <app-drop-down [(selectedItemId)]="selectedStatus"
                       [items]="statusTypes"
                       (selectedItemIdChange)="selectedStatusTypeChanged($event)">
        </app-drop-down>
      </div>
      <div class="button-container my-auto">
        <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_CREATE">
          <button class="primary-button" (click)="addNew()">
            Add Cause
          </button>
        </div>
      </div>
  </div>
  <div class="primary-table page-content-container">
    <table class="master-lists-table">
      <thead>
        <tr>
          <td (click)="showFilteringSortingMenu(CausesMenuType.LEVEL1_CODE)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Business key</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(CausesMenuType.LEVEL1_CODE) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CausesMenuType.LEVEL1_CODE"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CausesMenuType.LEVEL1)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Cause name</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(CausesMenuType.LEVEL1) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CausesMenuType.LEVEL1"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CausesMenuType.UI_SORT_INDEX)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Sort in UI</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(CausesMenuType.UI_SORT_INDEX) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CausesMenuType.UI_SORT_INDEX"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CausesMenuType.ACTIVATE_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Activation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(CausesMenuType.ACTIVATE_ON) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CausesMenuType.ACTIVATE_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CausesMenuType.DEACTIVATION_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Deactivation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(CausesMenuType.DEACTIVATION_ON) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="CausesMenuType.DEACTIVATION_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td>
            &nbsp;
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of masterListFiltered" (click)="showLevel2(item)">
          <td>
            <div class="first-column-container">
              <div class="first-column-content">
                {{ item.level1Code }}
              </div>
            </div>
          </td>
          <td>
            {{ item.level1 }}
          </td>
          <td>
            {{ item.uiSortIndex }}
          </td>
          <td>
            {{ item.activateOn | date: 'dd MMM yyyy'}}
          </td>
          <td>
            {{ item.deactivateOn | date: 'dd MMM yyyy'}}
          </td>
          <td (click)="edit(item);$event.stopPropagation()">
            <span>Edit</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-template #MasterListModal>
    <app-masterlist-causes-level1-modal [masterListItem]="masterListItem" (refreshTable)="reload()">
    </app-masterlist-causes-level1-modal>
  </ng-template>
</div>
