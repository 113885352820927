import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { CustomerListView } from 'app/shared/models/customer-list-view';

@Injectable({
  providedIn: 'root'
})
export class ProfileChangeService {
  private message = new BehaviorSubject(null);
  currentProfile = this.message.asObservable();

  changed(customerListView: CustomerListView) {
    this.message.next(customerListView);
  }
}
