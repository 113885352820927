export enum RoutesName {
  customers = 'customers',
  commonCustomers = 'common-customer',
  brand = 'brand',
  profile = 'profile',
  product = 'product',
  sosServices = 'sos-services',
  indexes = 'indexes',
  templates = 'templates',
  businessRules = 'business-rules',
  suppliers = 'suppliers',
  masterLists = 'master-lists',
  customerProfileRelations = 'customer-profile-relations',
  slaConfiguration = 'sla-configuration',
  erpData = 'erp-data',
  dcf = 'digital-caseflow',
  dcfCustomerConfig = 'customer-configuration',
  customerProfileApplications = 'customer-profile-applications',
  customerProfileCaseProcesses = 'customer-profile-case-processes'
}

export class RoutesNameUtils {
  private static defaultRoute: RoutesName = RoutesName.customers;

  static isMainRoute(name): boolean {
    return (
      name === RoutesName.customers ||
      name === RoutesName.sosServices ||
      name === RoutesName.indexes ||
      name === RoutesName.templates ||
      name === RoutesName.businessRules ||
      name === RoutesName.suppliers ||
      name === RoutesName.masterLists ||
      name === RoutesName.customerProfileRelations ||
      name === RoutesName.customerProfileApplications ||
      name === RoutesName.customerProfileCaseProcesses ||
      name === RoutesName.erpData ||
      name === RoutesName.dcf ||
      name === RoutesName.slaConfiguration
    );
  }

  static getMainRouteFromUrl(url: string): RoutesName {
    const routeName = RoutesNameUtils.getRouteTextFromUrl(url);
    if (routeName === '') {
      return this.defaultRoute;
    }
    if (RoutesNameUtils.isMainRoute(routeName)) {
      return routeName as RoutesName;
    }
    return undefined;
  }

  private static getRouteTextFromUrl(url: string): string {
    const menuStringEndIndex = url.substring(1).indexOf('/');
    if (menuStringEndIndex !== -1) {
      return url.substr(1, url.substring(1).indexOf('/'));
    } else {
      return url.substr(1);
    }
  }
}
