import { AppDateAdapter, APP_DATE_FORMATS } from './format-datepicker';
import {
  Component,
  OnInit,
  Input,
  Output,
  ViewEncapsulation,
  EventEmitter,
  OnChanges
} from '@angular/core';

import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';


export const DATE_FORMATS = {
  fullPickerInput: 'DD MMM YYYY',
  datePickerInput: 'DD MMM YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
  serverSideDateFormat: 'YYYY-MM-DD',
  serverSideInput: 'YYYY-MM-DD'
};

@Component({
  selector: 'app-input-calendar',
  templateUrl: './input-calendar.component.html',
  styleUrls: ['./input-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class InputCalendarComponent implements OnInit {
  private localDateMin: Date;
  private localDateMax: Date;

  @Input() name: string;
  @Input() withImage: boolean;
  @Input() dateTimeRange: any;
  @Input() selectMode: string;
  @Input() startingPlaceholder: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() isValid = true;
  @Input() isValidMessage = '';
  @Output() dateTimeRangeChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();
  @Input() required: boolean;
  @Input()
  set minDate(date: Date) {
    if (date) {
      const temp = new Date(date);
      temp.setDate(temp.getDate() + 1);
      this.localDateMin = temp;
    } else {
      this.localDateMin = null;
    }
  }
  get minDate(): Date { return this.localDateMin; }

  @Input()
  set maxDate(date: Date) {
    if (date) {
      const temp = new Date(date);
      temp.setDate(temp.getDate() - 1);
      this.localDateMax = temp;
    } else {
      this.localDateMax = null;
    }
  }
  get maxDate(): Date { return this.localDateMax; }

  value: Date;
  oldValue: Date;
  oldRange: any;
  pickerOpen: boolean;

  constructor() {}

  onChange() {
    this.dateTimeRangeChange.emit(this.dateTimeRange);
  }

  afterPickerOpen() {
    this.pickerOpen = true;
  }

  onSaveModel() {
    if (this.selectMode === 'range') {
      if (JSON.stringify(this.dateTimeRange) !== JSON.stringify(this.oldRange)) {
        this.saveModel.emit();
      }
    } else {
      if (this.oldValue !== this.dateTimeRange) {
        this.saveModel.emit();
      }
    }
  }

  onSaveState() {
    if (this.selectMode === 'range') {
      if (!this.pickerOpen) {
        this.oldRange = this.dateTimeRange;
      }
    } else {
      this.oldValue = this.dateTimeRange;
    }
  }

  ngOnInit() {
    this.onSaveState();
  }

  onInputCheckEmpty($event) {}
}
