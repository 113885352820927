import { CustomerListView } from './../../../shared/models/customer-list-view';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProfileService } from 'app/core/services/profile.service';
import { UpdateService } from 'app/core/services/update.service';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss'],
})
export class ProfilePageComponent implements OnInit, OnDestroy {
  private sub: any;
  customerListView: CustomerListView;
  profiles: CustomerListView[];
  numberOfProfiles: number;
  constructor(private route: ActivatedRoute, private profileService: ProfileService, private updateService: UpdateService) {}

  ngOnInit() {
    this.loadProfile();

    this.updateService.updateProfile.subscribe(value => {
      if (value === true) {
        this.loadProfile();
      }
    });
  }

  loadProfile() {
    this.sub = this.route.params.subscribe(params => {
      this.profileService.getProfileListView(params.id).subscribe(
        result => (this.customerListView = result),
        error => console.log(error)
      );
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
