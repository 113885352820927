import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Product } from 'app/shared/models/product';



@Injectable({
  providedIn: 'root'
})
export class ProductUpdateService {
  private productsList = new BehaviorSubject(null);
  currentProductsList = this.productsList.asObservable();

  changeMessage(newList: Product[]) {
    this.productsList.next(newList);
  }

}
