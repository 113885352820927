import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DictionaryWithType } from 'app/shared/models/dictionary-with-type';
import { SupplierProfile, SupplierProfileAdapter } from 'app/shared/models/supplier-profile';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class SupplierProfileService extends BaseService {
  updateSupplierProfileCount = new BehaviorSubject(undefined);
  private allSupplierProfiles = new BehaviorSubject(undefined);
  private supoTypeList = new BehaviorSubject(undefined);

  constructor(private http: HttpClient, private settingsService: SettingsService, private supplierProfileAdapter: SupplierProfileAdapter) {
    super(settingsService);
    this.getSupoTypeListNonCached().subscribe(result => this.supoTypeList.next(result));
  }

  public changedSupplierProfileCount(supplierProfiles: SupplierProfile[]) {
    this.updateSupplierProfileCount.next(supplierProfiles.length);
  }

  public getAll(): Observable<SupplierProfile[]> {
    return this.http
      .get(this.baseURL + 'supplier-profile')
      .pipe(map((data: any[]) => data.map(item => this.supplierProfileAdapter.adapt(item))));
  }

  public getAllSupplierProfiles(): Observable<SupplierProfile[]> {
    return this.allSupplierProfiles.asObservable();
  }

  public setAllSupplierProfiles(supplierProfiles: SupplierProfile[]) {
    this.allSupplierProfiles.next(supplierProfiles);
  }

  public get(id: string): Observable<SupplierProfile> {
    return this.http
      .get(this.baseURL + 'supplier-profile/' + id)
      .pipe(map(item => (item === null ? null : this.supplierProfileAdapter.adapt(item))));
  }

  public save(supplierProfile: SupplierProfile): Observable<any> {
    return this.http
      .post<SupplierProfile>(this.baseURL + 'supplier-profile', supplierProfile, {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.supplierProfileAdapter.adapt(item)));
  }

  public update(supplierProfile: SupplierProfile): Observable<any> {
    return this.http
      .put<SupplierProfile>(this.baseURL + 'supplier-profile/' + supplierProfile.id, supplierProfile, {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.supplierProfileAdapter.adapt(item)));
  }

  public getSupoTypeList(): Observable<DictionaryWithType[]> {
    return this.supoTypeList.asObservable();
  }
  public getSupoTypeListNonCached(): Observable<DictionaryWithType[]> {
    return this.http
      .get(this.baseURL + 'masterlist/supotypes')
      .pipe(map((data: any[]) => data.map(item => new DictionaryWithType(item.id, item.name, item.code))));
  }
}
