import { HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import {SettingsService} from './settings.service';

export class BaseService {
  apiURL = 'api/';
  controllerName: string;
  baseURL: string;
  jsonHeaders = new HttpHeaders({ 'Content-Type': 'application/JSON' });

  constructor(private settingService: SettingsService) {
    this.baseURL = this.settingService.getBffEndpoint() + this.apiURL;
  }
}
