<div class='service-type-modal-container'>
  <div class='closing-x' (click)='closeModal()'>
    <img src='/assets/icons/Close.svg' alt='Close' />
  </div>
  <div class='header'>
    <span *ngIf='isNew'>Create new Contact Role</span>
    <span *ngIf='!isNew'>Edit Contact Role</span>
  </div>
  <div class='row body-container' *ngIf='masterListType'>
    <div class='col-4'>
      <div class='input-name'>
        Activate on
        <span *ngIf='!isReadonly' class='required'>*</span>
      </div>
      <app-input-calendar
        [withImage]='true'
        [selectMode]="'single'"
        [disabled]='isReadonly'
        [(dateTimeRange)]='masterListType.activateOn'
        [maxDate]='masterListType.deactivateOn'
        (saveModel)='activateOnChanged(masterListType)'
      >
      </app-input-calendar>
      <div class='input-name'>Deactivate after</div>
      <app-input-calendar
        [withImage]='true'
        [selectMode]="'single'"
        [disabled]='isReadonly'
        [(dateTimeRange)]='masterListType.deactivateOn'
        [minDate]='masterListType.activateOn'
        (saveModel)='deactivateOnChanged(masterListType)'
      >
      </app-input-calendar>
      <div class='label' style='font-size: 14px;
      color: #787878;' [ngClass]="'input-name'">Based on case creation time
      </div>
    </div>
    <div class='col-8'>
      <div class='row'>
        <div class='col-6'>
          <div>
            <app-input
              [name]="'Business key'"
              [(value)]='masterListType.businessKey'
              (valueChange)='modelChanged()'
              [disabled]='isReadonly || !!masterListType.id'
              [required]='!isReadonly'
            ></app-input>
          </div>
          <div class='padding-top'>
            <app-input
              [name]="'Name'"
              [(value)]='masterListType.contactRoleName'
              (valueChange)='modelChanged()'
              [disabled]='isReadonly'
              [required]='!isReadonly'
            ></app-input>
          </div>
          <div class='padding-top'>
            <div class='field-title'>Description</div>
            <textarea [(ngModel)]='masterListType.description'
                      (ngModelChange)='modelChanged()'
                      [disabled]='isReadonly'></textarea>
          </div>
          <div class='padding-top'>
            <app-input
              [name]="'Sort in UI'"
              [(value)]='masterListType.uiSort'
              (valueChange)='modelChanged()'
              [disabled]='isReadonly'
            ></app-input>
          </div>
        </div>
        <div class='col-6'>
          <div class='padding-top'>
            <div class='field-title'>Auto import from search</div>
            <input
              type='checkbox'
              name='Auto import from search'
              [(ngModel)]='masterListType.autoImportFromSearch'
              class='sos-services-checkbox'
              [disabled]='isReadonly'
              (change)='modelChanged()' />
          </div>
          <div class='padding-top'>
            <div class='field-title'>Exclude from Medical assessment</div>
            <input
              type='checkbox'
              name='Exclude from Medical assessment'
              [(ngModel)]='masterListType.excludeFromMedicalAssessment'
              class='sos-services-checkbox'
              [disabled]='isReadonly'
              (change)='modelChanged()' />
          </div>
          <div class='padding-top'>
            <div class='field-title'>Exclude from Service order</div>
            <input
              type='checkbox'
              name='Exclude from Service order'
              [(ngModel)]='masterListType.excludeFromServiceOrder'
              class='sos-services-checkbox'
              [disabled]='isReadonly'
              (change)='modelChanged()' />
          </div>
          <div class='padding-top'>
            <div class='field-title'>GOP Primary</div>
            <input
              type='checkbox'
              name='GOP Primary'
              [(ngModel)]='masterListType.gopPrimary'
              class='sos-services-checkbox'
              [disabled]='isReadonly'
              (change)='modelChanged()' />
          </div>
          <div class='padding-top'>
            <div class='field-title'>GOP Secondary</div>
            <input
              type='checkbox'
              name='GOP Secondary'
              [(ngModel)]='masterListType.gopSecondary'
              class='sos-services-checkbox'
              [disabled]='isReadonly'
              (change)='modelChanged()' />
          </div>
        </div>
      </div>
    </div>
    <div class='row body-container' *ngIf='error'>
      <div class='col-12 error'>
        {{ error }}
      </div>
    </div>
  </div>

  <div class="masterlist-modal-footer d-flex justify-content-between">
    <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE" class="">
      <button class="primary-button save-button" [class.button-disabled]="!isSaveButtonEnabled" (click)="update()">Save</button>
    </div>
    <app-last-changed [masterListItem]="masterListType" class="float-right"> </app-last-changed>
  </div>

</div>
