<div class="common-content-container row">
  <div class="col-6 clearfix">
  <div class="common-content-container-title">Product lookup integration settings</div>
  <div class="common-content-container-body">
    <div class="input-name">
      Policy lookup integration method
      <span class="required">*</span>
    </div>
    <div class="lookup-method-type-dropdown">
      <app-drop-down [items]="types" [(selectedItemId)]="type" (selectedItemIdChange)="typeChanged()" [disabled]="!!product.groupProfileProductId"></app-drop-down>
    </div>
    <app-register-server-integration
        *ngIf="policyLookupMethod?.extension && [PolicyLookupMethodTypeEnum.REGISTER_SERVER, PolicyLookupMethodTypeEnum.OTHER_INTEGRATION, PolicyLookupMethodTypeEnum.NO_INTEGRATION].includes(policyLookupMethod.type)"
        [extension]="policyLookupMethod.extension" [policyLookupMethodType]='policyLookupMethod.type' [disabled]="!!product.groupProfileProductId || type===PolicyLookupMethodTypeEnum.NO_INTEGRATION"></app-register-server-integration>
    <div>
      <button class="primary-button common-content-container-save-btn" [disabled]="!isValid() || !!product.groupProfileProductId" (click)="save()">
        Save changes
      </button>
    </div>
  </div>

  </div>
  <div class="col-6">
    <div class="common-content-container-title contact-info-title">Contact &amp; Info</div>
    <div class="contact-info-content">
      <div class="input-name">Contact and info for policy verification</div>
      <div class="contact-info">{{ contactInfo }}</div>
    </div>
  </div>
</div>
