import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import { DropdownAdapter } from 'app/shared/models/dictionary';
import { CountryAdapter, Country } from 'app/shared/models/country';
import { Continent, ContinentAdapter } from 'app/shared/models/continent';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends BaseService {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private countryAdapter: CountryAdapter,
    private continentAdapter: ContinentAdapter,
    private dropdownAdapter: DropdownAdapter
  ) {
    super(settingsService);
  }
  public searchCountries(query: string): Observable<any[]> {
    return this.http
      .get<any[]>(this.baseURL + 'countries/search/' + query)
      .pipe(
        map((data: any[]) => data.map(item => this.countryAdapter.adapt(item)))
      );
  }

  public getAllCountries(): Observable<Country[]> {
    return this.http
      .get<any[]>(this.baseURL + 'countries')
      .pipe(
        map((data: any[]) =>
          data.map(item => this.countryAdapter.adapt(item))
        )
      );
  }

  public getAllCountriesByContinentCode(code: string): Observable<Country[]> {
    return this.http
      .get<any[]>(this.baseURL + 'countries/' + code)
      .pipe(
        map((data: any[]) =>
          data.map(item => this.countryAdapter.adapt(item))
        )
      );
  }

  public getCountryByAlpha2Code(alpha2Code: string): Observable<Country> {
    return this.http
      .get<any>(this.baseURL + 'countries/alpha2Code/' + alpha2Code)
      .pipe(
        map((data: any) => this.countryAdapter.adapt(data))
      );
  }

  public getAllContinents(): Observable<Continent[]> {
    return this.http
      .get<any[]>(this.baseURL + 'countries/continent-list')
      .pipe(
        map((data: any[]) =>
          data.map(item => this.continentAdapter.adapt(item))
        )
      );
  }
}
