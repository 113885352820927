<div class="container">
  <div class="itemContainer">
    <div class="item top">Description</div>
    <div class="item top">Type</div>
    <div class="item top">Value</div>
    <div class="item top">Unit</div>
  </div>
  <div class="itemContainer" *ngFor="let limit of limits">
    <div class="item">{{ limit.description }}</div>
    <div class="item">{{ coverageLimitTypes(limit.limitType) }}</div>
    <div class="item">{{ limit.limitMaxValue }}</div>
    <div class="item"> {{ coverageLimitPersonTypes(limit.limitUnit) }}
      <span *ngIf="limit.limitType === getLimitTypeEnum.AMOUNT">{{ limit.limitUnit }}</span>
      {{ coverageLimitPeriodTypes(limit.limitUnit) }}
      {{ coverageLimitTreatmentsTypes(limit.limitUnit) }}
      <span *ngIf="limit.limitType === getLimitTypeEnum.DURATION">(From {{ coverageLimitFromTypes(limit.limitValue) }})</span></div>
  </div>
</div>
