<div class="page-container">
    <div class="page-header">
      <div class="my-auto counts-container">
        <div class="count-container">
          <span>Events</span><span>{{ masterListFiltered?.length }}</span>
        </div>
      </div>
      <div class="status-container my-auto">
        <app-drop-down [(selectedItemId)]="selectedStatus" [items]="statusTypes" (selectedItemIdChange)="selectedStatusTypeChanged($event)">
        </app-drop-down>
      </div>
      <div class="button-container my-auto">
        <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_CREATE">
          <button class="primary-button" (click)="addNew()">
            Add event
          </button>
        </div>
      </div>
    </div>



    <div class="primary-table page-content-container">
        <table class="master-lists-table">
            <thead>

              <tr>
                <td (click)="showFilteringSortingMenu(IncidentEventMenuType.BUSINESS_KEY)" class="filter-column unsortable-column">
                  <div class="sortable">
                    <span>Business key</span>
                    <span class="icon"></span>
                  </div>
                  <div [ngStyle]="!showMenu[IncidentEventMenuType.BUSINESS_KEY] && { display: 'none' }">
                    <app-masterlist-sorting-menu
                      [sortingKey]="IncidentEventMenuType.BUSINESS_KEY"
                      [masterList]="masterListFiltered"
                    ></app-masterlist-sorting-menu>
                    </div>
                </td>

                <td (click)="showFilteringSortingMenu(IncidentEventMenuType.DATE_OF_EVENT)" class="filter-column unsortable-column">
                  <div class="sortable">
                    <span>Date of event</span>
                    <span class="icon"></span>
                  </div>
                  <div [ngStyle]="!showMenu[IncidentEventMenuType.DATE_OF_EVENT] && { display: 'none' }">
                    <app-masterlist-sorting-menu
                      [sortingKey]="IncidentEventMenuType.DATE_OF_EVENT"
                      [masterList]="masterListFiltered"
                    ></app-masterlist-sorting-menu>
                  </div>
                </td>
                <td (click)="showFilteringSortingMenu(IncidentEventMenuType.CATEGORY)" class="filter-column unsortable-column">
                  <div class="sortable">
                    <span>Category</span>
                    <span class="icon"></span>
                  </div>
                  <div [ngStyle]="!showMenu[IncidentEventMenuType.CATEGORY] && { display: 'none' }">
                    <app-masterlist-sorting-menu
                      [sortingKey]="IncidentEventMenuType.CATEGORY"
                      [masterList]="masterListFiltered"
                    ></app-masterlist-sorting-menu>
                  </div>
                </td>
                <td (click)="showFilteringSortingMenu(IncidentEventMenuType.NAME)" class="filter-column unsortable-column">
                  <div class="sortable">
                    <span>Event name</span>
                    <span class="icon"></span>
                  </div>
                  <div [ngStyle]="!showMenu[IncidentEventMenuType.NAME] && { display: 'none' }">
                    <app-masterlist-sorting-menu
                      [sortingKey]="IncidentEventMenuType.NAME"
                      [masterList]="masterListFiltered"
                    ></app-masterlist-sorting-menu>
                  </div>
                </td>
                <td (click)="showFilteringSortingMenu(IncidentEventMenuType.DESCRIPTION)" class="filter-column unsortable-column">
                  <div class="sortable">
                    <span>Description</span>
                    <span class="icon"></span>
                  </div>
                  <div [ngStyle]="!showMenu[IncidentEventMenuType.DESCRIPTION] && { display: 'none' }">
                    <app-masterlist-sorting-menu
                      [sortingKey]="IncidentEventMenuType.DESCRIPTION"
                      [masterList]="masterListFiltered"
                    ></app-masterlist-sorting-menu>
                  </div>
                </td>
                <td (click)="showFilteringSortingMenu(IncidentEventMenuType.ACTIVATE_ON)" class="filter-column unsortable-column">
                  <div class="sortable">
                    <span>Activation date</span>
                    <span class="icon"></span>
                  </div>
                  <div [ngStyle]="!showMenu[IncidentEventMenuType.ACTIVATE_ON] && { display: 'none' }">
                    <app-masterlist-sorting-menu
                      [sortingKey]="IncidentEventMenuType.ACTIVATE_ON"
                      [masterList]="masterListFiltered"
                    ></app-masterlist-sorting-menu>
                  </div>
                </td>
                <td (click)="showFilteringSortingMenu(IncidentEventMenuType.DEACTIVATION_ON)" class="filter-column unsortable-column">
                  <div class="sortable">
                    <span>Deactivation date</span>
                    <span class="icon"></span>
                  </div>
                  <div [ngStyle]="!showMenu[IncidentEventMenuType.DEACTIVATION_ON] && { display: 'none' }">
                    <app-masterlist-sorting-menu
                      [sortingKey]="IncidentEventMenuType.DEACTIVATION_ON"
                      [masterList]="masterListFiltered"
                    ></app-masterlist-sorting-menu>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of masterListFiltered" (click)="edit(item)">
                  <td>
                    <div class="first-column-container">
                      <div class="first-column-content">
                        {{ item.businessKey }}
                      </div>
                    </div>
                  </td>
                  <td>
                    {{ item.dateOfEvent | date: 'dd MMM yyyy' }}
                  </td>
                  <td>
                    {{ getEnumValue(item.category) }}
                  </td>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    {{ item.description }}
                  </td>
                  <td>
                    {{ item.activateOn | date: 'dd MMM yyyy' }}
                  </td>
                  <td>
                    {{ item.deactivateOn | date: 'dd MMM yyyy' }}
                  </td>
                </tr>
              </tbody>
        </table>
    </div>

    <ng-template #MasterListModal>
      <app-masterlist-incident-event-modal [masterListItem]="masterListType" (refreshTable)="reload()"> </app-masterlist-incident-event-modal>
    </ng-template>
</div>


