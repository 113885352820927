<div class="input-name">
  Integration type
  <span class="required">*</span>
</div>
<div>
  <div class="register-server-lookup-method-type-dropdown" *ngIf="data">
    <app-drop-down [items]="types" [(selectedItemId)]="type" [disabled]="disabled"></app-drop-down>
  </div>
</div>
<div class="lookup-code-input" *ngIf="data">
  <app-input [(value)]="data.code" required="true" name="Lookup code" [disabled]="isNoneTypeSelected() || disabled"></app-input>
</div>

