import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomerProductRelationOverview } from 'app/shared/models/customer-product-relation-overview';

@Injectable({
  providedIn: 'root'
})
export class CustomerProductRelationOverviewUpdateService {

  private customerProductRelationOverviewList = new BehaviorSubject(null);
  currentCustomerProductRelationOverviewList = this.customerProductRelationOverviewList.asObservable();

  changeMessage(newList: CustomerProductRelationOverview[]) {
    this.customerProductRelationOverviewList.next(newList);
  }
}
