import {Component, Input, OnInit} from '@angular/core';
import {Product} from 'app/shared/models/product';
import {ProductMenuSectionEnum} from './product-menu-section-enum';
import { Router } from '@angular/router';
import { RoutesName } from '../../../../app-routes-name';

@Component({
  selector: 'app-product-menu',
  templateUrl: './product-menu.component.html',
  styleUrls: ['./product-menu.component.scss']
})
export class ProductMenuComponent implements OnInit {
  @Input() product: Product;

  shownSection: ProductMenuSectionEnum;

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.shownSection = ProductMenuSectionEnum.overview;
  }

  get menus() {
    return ProductMenuSectionEnum;
  }

  productChange(product: Product) {
    this.product = product;
    this.router.navigate([RoutesName.customers + '/' + RoutesName.product, product.id.toString()]);
  }
}
