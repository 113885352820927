import { GenericDictionary } from '../../models/dictionary';
import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-generic-drop-down',
  templateUrl: './generic-drop-down.component.html',
  styleUrls: ['./generic-drop-down.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GenericDropDownComponent {
  @Input() name: string;
  @Input() standardDropdownIcon: boolean;
  @Input() items: GenericDictionary[];
  @Input() selectedItem = null;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Output() selectedItemChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();
  @Input() placeholder: string;
  @Input() allowBlank = false;
  oldSelectedItem: any;

  onChange() {
    this.selectedItemChange.emit(this.selectedItem);
  }

  onSaveModel() {
    if (this.oldSelectedItem !== this.selectedItem) {
      this.saveModel.emit();
    }
  }

  onSaveState() {
    this.oldSelectedItem = this.selectedItem;
  }
  trackBy(a) {
    return a.id;
  }

  searchByItemName(term: string, item: any) {
    term = term.toLowerCase();
    const label = item.teamName +  " - " + item.adTCode
    return label.toLowerCase().indexOf(term) > -1;
  }
}
