import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class ProfileCountryList {
  id: number;
  name: string;
  numberOfCountries: number;
  groupProfileCountryListId: number;

  public constructor(init?: Partial<ProfileCountryList>) {
    Object.assign(this, init);
  }

}
@Injectable({
  providedIn: 'root'
})
export class ProfileCountryListAdapter implements Adapter<ProfileCountryList> {
  constructor() {}
  adapt(item: any): ProfileCountryList {
    const result = new ProfileCountryList({
      id: item.id,
      name: item.name,
      numberOfCountries: item.numberOfCountries,
      groupProfileCountryListId: item.groupProfileCountryListId
    });
    return result;
  }
}
