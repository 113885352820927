import { CustomerListView } from 'app/shared/models/customer-list-view';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-brand-overview',
  templateUrl: './brand-overview.component.html',
  styleUrls: ['./brand-overview.component.scss']
})
export class BrandOverviewComponent implements OnInit {
  @Input() customerListView: CustomerListView;
  @Input() numberOfProfiles: number;
  @Input() profiles: CustomerListView[];
  constructor() {}

  ngOnInit() {}
}
