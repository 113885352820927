<div class="drop-down">
  <span class="name">{{name}}</span><span class="required" *ngIf="required">*</span>
  <ng-select 
    (blur)="onSaveModel()"
    (focus)="onSaveState()"
    [(ngModel)]="selectedItem"
    [items]="items"
    (change)="onChange()"
    
    [searchFn]="searchByItemName"
    [trackByFn]="trackBy"
    [disabled]="disabled" 
    required>
 
    <ng-template ng-label-tmp let-item="item" >
      <span> {{ item.teamName }} - {{item.adTCode}}</span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" >
      <span> {{ item.teamName }} - {{item.adTCode}}</span>
    </ng-template>
  </ng-select>
</div>
