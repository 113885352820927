import { MasterListOverviewModel } from 'app/shared/models/masterlist-overview-model';

export const MASTER_LISTS: MasterListOverviewModel[] = [
  { id: 'AD_TEAMS', name: 'SECCA AD teams and departments', status: 'Active', enabled: true },
  { id: 'CAUSE_LIST', name: 'Incident causes', status: 'Active', enabled: true },
  { id: 'SANCTION_COUNTRIES', name: 'Sanction countries', status: 'Active', enabled: true },
  { id: 'SERVICE_ITEM', name: 'Service items', status: 'Active', enabled: true },
  { id: 'SERVICE_TYPE', name: 'Service types', status: 'Active', enabled: true },
  { id: 'PURPOSE_OF_TRAVEL', name: 'Travel purposes', status: 'Active', enabled: false },
  { id: 'REDUCTIONS', name: 'Reductions', status: 'Active', enabled: true },
  { id: 'SLA_CATEGORY', name: 'SLA Category', status: 'Active', enabled: false },
  { id: 'CASE_TYPE', name: 'Case types', status: 'Active', enabled: true },
  { id: 'REFUND_TYPE', name: 'Refund types', status: 'Active', enabled: true },
  { id: 'DEDICATED_EMAILS', name: 'Dedicated Emails', status: 'Active', enabled: true },
  { id: 'COMMITMENT_TYPES', name: 'Commitment Types', status: 'Active', enabled: true },
  { id: 'INCIDENT_EVENT', name: 'Events', status: 'Active', enabled: true },
  { id: 'APPROVAL_LIMITS', name: 'Approval limits for refunds and supplier invoices', status: 'Active', enabled: true },
  { id: 'DIAGNOSES', name: 'Diagnoses', status: 'Active', enabled: true },
  { id: 'QUESTIONNAIRE', name: 'Questionnaire', status: 'Active', enabled: true },
  { id: 'REASON_FOR_CANCELLATION', name: 'Reason for Cancellation', status: 'Active', enabled: true },
  { id: 'CHANNEL_TYPE', name: 'Channel types', status: 'Active', enabled: true },
  { id: 'CONTACT_ROLE', name: 'Contact Roles', status: 'Active', enabled: true }
];

export const FLOWS: string[] = [
  "DK",
  "NO",
  "SE",
  "FI"
]
