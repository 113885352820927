import { MasterListServiceTypeModel } from './masterlist-service-type-model';

export class MasterListReasonForCancellationModel {
  id: string;
  reasonForCancellationCode: string;
  reasonForCancellationName: string;
  description: string;
  uiSortIndex: number;
  modalType: string;
  activateOn: Date;
  deactivateOn: Date;
  active: boolean;
  applications: string[];
  translationsAvailable: { [index: string]: string };

  public constructor(init?: Partial<MasterListReasonForCancellationModel>) {
    Object.assign(this, init);
  }
}
