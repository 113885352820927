import {Component, ViewChild} from '@angular/core';
import {DigitalCaseflowService} from "@onepoint/core/services/digital-caseflow.service";
import {CreateCustomerConfigComponent} from "../customer-configuration/create-customer-config/create-customer-config.component";
import {CustomerConfiguration} from "@onepoint/shared/models/digital-caseflow/customer-configuration";
import {Router} from "@angular/router";
import {RoutesName} from "../../../app-routes-name";
import {ProfileService} from "@onepoint/core/services/profile.service";
import {Dictionary} from "@onepoint/shared/models/dictionary";

@Component({
  selector: 'app-dcf-flows',
  templateUrl: './dcf-flows.component.html',
  styleUrls: ['./dcf-flows.component.scss']
})
export class DcfFlowsComponent{

  customerConfigurations: CustomerConfiguration[];
  customerProfiles: Dictionary[] = [];
  @ViewChild('createCustomerConfigComponent')
  createCustomerConfigComponent: CreateCustomerConfigComponent;
  constructor(private profileService: ProfileService, private digitalCaseflowService : DigitalCaseflowService,
  private router: Router) {
    digitalCaseflowService.getAllCustomerConfiguration().subscribe(data => {
      this.customerConfigurations = data;
    });
    this.profileService.getCustomerProfiles().subscribe(data => {
      this.customerProfiles = data.map(customerProfile => new Dictionary(""+customerProfile.profileId, customerProfile.profileName));
      this.customerProfiles.unshift(new Dictionary("0", "All customers"));
    });
  }

  getCustomerProfileName(profileId: number): string {
    let profile = this.customerProfiles.find(profile => profile.id === ""+profileId);
    return profile ? profile.name : 'No profile selected';
  }

  createFlow(){
    this.createCustomerConfigComponent.open();
  }

  navigate(customerConfiguration: CustomerConfiguration) {
    this.router.navigate([RoutesName.dcf +'/'+ RoutesName.dcfCustomerConfig+'/', customerConfiguration.id]);
  }
}
