import { Component, OnInit, ViewChild } from '@angular/core';
import { PermissionEnum } from '@onepoint/shared/models/permission.enum';
import { SupplierProfileService } from 'app/core/services/supplier-profile.service';
import { AutoUnsubscribe } from 'app/shared/decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { SuppliersTableComponent } from '../suppliers-table/suppliers-table.component';
import { NewSupplierProfileComponent } from './new-supplier-profile/new-supplier-profile.component';

@Component({
  selector: 'app-suppliers-all',
  templateUrl: './suppliers-all.component.html',
  styleUrls: ['./suppliers-all.component.scss'],
})
@AutoUnsubscribe
export class SuppliersAllComponent implements OnInit {
  @ViewChild('supplierTable') supplierTable: SuppliersTableComponent;
  @ViewChild('newSupplierProfile') newSupplierProfile: NewSupplierProfileComponent;
  total = '0';
  searchText = '';
  subscription: Subscription;

  constructor(private supplierProfileService: SupplierProfileService) {}

  ngOnInit(): void {
    this.subscription = this.supplierProfileService.updateSupplierProfileCount
      .asObservable()
      .subscribe(result => (this.total = !!result ? result : 0));
  }

  textValueChange() {
    this.supplierTable.searchTextValueChange(this.searchText);
  }

  addNew() {
    this.newSupplierProfile.open();
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
