import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';

export class Index {
  id: string;
  nameTableId: string;
  name: string;
  year: string;
  indexValue: string;
  indexNumberForSorting: number;
  lastRunIndexJobDate: Date;
  public constructor(init?: Partial<Index>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class IndexAdapter implements Adapter<Index> {
  adapt(item: any): Index {
    return new Index({
      id: item.id,
      nameTableId: item.nameTableId,
      name: item.name,
      year: item.year,
      indexValue: item.indexValue,
      indexNumberForSorting: item.indexNumberForSorting,
      lastRunIndexJobDate: item.lastRunIndexJobDate === null ? null : new Date(item.lastRunIndexJobDate)
    });
  }
}
