import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';

export class Continent {
    code: string;
    name: string;

    public constructor(init?: Partial<Continent>) {
        Object.assign(this, init);
      }
}


@Injectable({
    providedIn: 'root'
  })
  export class ContinentAdapter implements Adapter<Continent> {
    adapt(item: any): Continent {
      return new Continent({
        code: item.code,
        name: item.name,
      });
    }
  }
