<div class="dual-list">
  <div class="listbox">
    <div class="listbox-header justify-content-between">
      <span class="listbox-header-title my-auto">{{leftColumnLabel}}</span>
      <app-checkbox [value]="selectedAllLeft" class="listbox-header-select-all my-auto" label="Select all" (valueChange)="selectAllLeft($event)"></app-checkbox>
      <button type="button"
        class="add-button primary-button my-auto"
         (click)="add()"
          [disabled]="addButtonDisabled"
          [ngClass]="addButtonDisabled && 'button-disabled'">
        Add
        <img src="assets/icons/transfer-right-white.svg" alt="Move selected to right"/>
      </button>
    </div>
    <div class="listbox-picker">
      <ul [ngStyle]="{ 'max-height': height, 'min-height': height }">
        <li
          *ngFor="let item of leftFiltered"
          class="listbox-row"
          [ngClass]="{ selected: item.selected }"
        >
          <div class="item-label-container">
            <label [attr.for]="'select-' + item.id">{{ item.name }}</label>
          </div>
          <div class="item-select-container">
            <app-checkbox [id]="'select-' + item.id" [(value)]="item.selected"></app-checkbox>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="listbox">
    <div class="listbox-header justify-content-between">
      <span class="listbox-header-title my-auto">{{rightColumnLabel}}</span>
      <app-checkbox class="listbox-header-select-all my-auto" label="Select all" (valueChange)="selectAllRight($event)"></app-checkbox>
      <button type="button"
        class="remove-button primary-button my-auto"
        (click)="remove()"
        [disabled]="removeButtonDisabled"
        [ngClass]="removeButtonDisabled && 'button-disabled'">
        <img src="assets/icons/transfer-left-white.svg" alt="Move selected to left"/>
        Remove
      </button>
    </div>
    <div class="listbox-picker">
      <ul [ngStyle]="{ 'max-height': height, 'min-height': height }">
        <li
          *ngFor="let item of right"
          class="listbox-row"
          [ngClass]="{ selected: item.selected }"
        >
          <div class="item-label-container">
              <img src="assets/icons/success.svg" alt="Selected"/>
            <label [attr.for]="'select-' + item.id">{{ item.name }}</label>
          </div>
          <div class="item-select-container">
            <app-checkbox [id]="'select-' + item.id" [(value)]="item.selected"></app-checkbox>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
