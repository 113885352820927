import { MasterListAdTeamsModel } from "./masterlist-ad-teams-model";

export class MasterListDedicatedEmailModel {
  id: string;
  businessKey: string;
  sendTo: string;
  adtCode: string;
  teamName: string;
  adTeam: MasterListAdTeamsModel;
  alwaysDirectToTeam: boolean;
  teamBeforeProfile: boolean;
  activateOn: Date;
  deactivateOn: Date;
  active: boolean;

  public constructor(init?: Partial<MasterListDedicatedEmailModel>) {
    Object.assign(this, init);
  }
}
