import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MasterListService } from 'app/core/services/masterlist.service';
import { Dictionary } from 'app/shared/models/dictionary';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import * as _ from 'lodash';
import { OnepointModalService, Size } from 'app/core/services/onepoint.modal.service';
import { DiagnosesMenuType } from '@onepoint/master-lists/diagnoses/diagnoses-menu-type';
import { MasterlistDiagnosisModel } from '@onepoint/shared/models/masterlist-diagnosis-model';
import { MasterlistLanguages } from '@onepoint/master-lists/masterlist-languages';

@Component({
  selector: 'app-masterlist-icd10-codes',
  templateUrl: './masterlist-diagnoses.component.html',
  styleUrls: ['./masterlist-diagnoses.component.scss'],
})
export class MasterlistDiagnosesComponent implements OnInit {
  @ViewChild('MasterListModal')
  private masterListModalTemplate: TemplateRef<any>;

  masterList: MasterlistDiagnosisModel[];
  masterListFiltered: MasterlistDiagnosisModel[];
  masterListType: MasterlistDiagnosisModel;
  menuGroup = new Map<DiagnosesMenuType, boolean>();
  statusTypes: Dictionary[] = [
    { id: 'all', name: 'All' },
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
  ];
  selectedStatus = 'all';

  languages = MasterlistLanguages;

  get DiagnosisMenuType() {
    return DiagnosesMenuType;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  constructor(private masterListService: MasterListService, private onepointModalService: OnepointModalService) {}

  ngOnInit(): void {
    this.loadMasterList();
  }

  selectedStatusTypeChanged(status: string) {
    this.masterListFiltered = this.filterByStatus(status);
  }

  private loadMasterList() {
    this.masterListService.getDiagnoses().subscribe(result => {
      this.masterList = result;
      this.masterListFiltered = result;
    });
  }

  private filterByStatus(status: string): MasterlistDiagnosisModel[] {
    if (status === 'all') {
      return this.masterList;
    }

    return this.masterList.filter(item => (status === 'active' ? item.active : !item.active));
  }

  edit(item: MasterlistDiagnosisModel) {
    this.masterListType = _.clone(item);
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.LARGE, 'diagnosis-modal-container');
  }

  addNew(): void {
    this.masterListType = new MasterlistDiagnosisModel();
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.LARGE, 'diagnosis-modal-container');
  }

  translationSort(lang: string) {
    return (a: MasterlistDiagnosisModel, b: MasterlistDiagnosisModel) => {
      const av = !!a.translationsAvailable[lang];
      const bv = !!b.translationsAvailable[lang];
      return +av - +bv;
    };
  }

  keepOrder = (a, b) => {
    return a;
  }

  reload() {
    this.loadMasterList();
  }

  hasDuplicateTranslation(item: MasterlistDiagnosisModel, lang: string) {    
    if (lang !== MasterlistLanguages.EN && !!item.translationsAvailable[lang]) {
      return item.translationsAvailable[lang] === item.translationsAvailable[MasterlistLanguages.EN];
    }
    return false;
  }

}
