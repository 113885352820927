<div class="page-container">
  <div class="page-header">
    <div class="my-auto counts-container">
      <div class="count-container">
        <span>Contact Roles</span> <span>{{ masterListFiltered?.length }}</span>
      </div>
    </div>
    <div class="status-container my-auto">
      <app-drop-down [(selectedItemId)]="selectedStatus" [items]="statusTypes" (selectedItemIdChange)="selectedStatusTypeChanged($event)">
      </app-drop-down>
    </div>
    <div class="button-container my-auto">
      <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_CREATE">
        <button class="primary-button" (click)="addNew()">
          Add Contact Role
        </button>
      </div>
    </div>
  </div>
  <div class="primary-table page-content-container">
    <table class="master-lists-table">
      <thead>
      <tr>
        <td (click)="showFilteringSortingMenu(ContactRoleMenuType.BUSINESS_KEY)" class="filter-column unsortable-column">
          <div class="sortable">
            <span>Business key</span>
            <span class="icon"></span>
          </div>
          <div [ngStyle]="!isShowMenu(ContactRoleMenuType.BUSINESS_KEY) && { display: 'none' }">
            <app-masterlist-sorting-menu
              [sortingKey]="ContactRoleMenuType.BUSINESS_KEY"
              [masterList]="masterListFiltered"
            ></app-masterlist-sorting-menu>
          </div>
        </td>
        <td (click)="showFilteringSortingMenu(ContactRoleMenuType.CONTACT_ROLE_NAME)" class="filter-column unsortable-column">
          <div class="sortable">
            <span>Contact Role Name</span>
            <span class="icon"></span>
          </div>
          <div [ngStyle]="!isShowMenu(ContactRoleMenuType.CONTACT_ROLE_NAME) && { display: 'none' }">
            <app-masterlist-sorting-menu
              [sortingKey]="ContactRoleMenuType.CONTACT_ROLE_NAME"
              [masterList]="masterListFiltered"
            >
            </app-masterlist-sorting-menu>
          </div>
        </td>
        <td (click)="showFilteringSortingMenu(ContactRoleMenuType.DESCRIPTION)" class="filter-column unsortable-column">
          <div class="sortable">
            <span>Description</span>
            <span class="icon"></span>
          </div>
          <div [ngStyle]="!isShowMenu(ContactRoleMenuType.DESCRIPTION) && { display: 'none' }">
            <app-masterlist-sorting-menu
              [sortingKey]="ContactRoleMenuType.DESCRIPTION"
              [masterList]="masterListFiltered"
            >
            </app-masterlist-sorting-menu>
          </div>
        </td>

        <td (click)="showFilteringSortingMenu(ContactRoleMenuType.UI_SORT)" class="filter-column unsortable-column">
          <div class="sortable">
            <span>Sort in UI</span>
            <span class="icon"></span>
          </div>
          <div [ngStyle]="!isShowMenu(ContactRoleMenuType.UI_SORT) && { display: 'none' }">
            <app-masterlist-sorting-menu
              [sortingKey]="ContactRoleMenuType.UI_SORT"
              [masterList]="masterListFiltered"
            ></app-masterlist-sorting-menu>
          </div>
        </td>
        <td (click)="showFilteringSortingMenu(ContactRoleMenuType.AUTO_IMPORT_FROM_SEARCH)" class="filter-column unsortable-column">
          <div class="sortable">
            <span>Auto import from search</span>
            <span class="icon"></span>
          </div>
          <div [ngStyle]="!isShowMenu(ContactRoleMenuType.AUTO_IMPORT_FROM_SEARCH) && { display: 'none' }">
            <app-masterlist-sorting-menu
              [sortingKey]="ContactRoleMenuType.AUTO_IMPORT_FROM_SEARCH"
              [masterList]="masterListFiltered"
            ></app-masterlist-sorting-menu>
          </div>
        </td>
        <td (click)="showFilteringSortingMenu(ContactRoleMenuType.EXCLUDE_FROM_MEDICAL_ASSESSMENT)" class="filter-column unsortable-column">
          <div class="sortable">
            <span>Exclude from Medical assessment</span>
            <span class="icon"></span>
          </div>
          <div [ngStyle]="!isShowMenu(ContactRoleMenuType.EXCLUDE_FROM_MEDICAL_ASSESSMENT) && { display: 'none' }">
            <app-masterlist-sorting-menu
              [sortingKey]="ContactRoleMenuType.EXCLUDE_FROM_MEDICAL_ASSESSMENT"
              [masterList]="masterListFiltered"
            ></app-masterlist-sorting-menu>
          </div>
        </td>
        <td (click)="showFilteringSortingMenu(ContactRoleMenuType.EXCLUDE_FROM_SERVICE_ORDER)" class="filter-column unsortable-column">
          <div class="sortable">
            <span>Exclude from Service order</span>
            <span class="icon"></span>
          </div>
          <div [ngStyle]="!isShowMenu(ContactRoleMenuType.EXCLUDE_FROM_SERVICE_ORDER) && { display: 'none' }">
            <app-masterlist-sorting-menu
              [sortingKey]="ContactRoleMenuType.EXCLUDE_FROM_SERVICE_ORDER"
              [masterList]="masterListFiltered"
            ></app-masterlist-sorting-menu>
          </div>
        </td>

        <td (click)="showFilteringSortingMenu(ContactRoleMenuType.ACTIVATE_ON)" class="filter-column unsortable-column">
          <div class="sortable">
            <span>Activation date</span>
            <span class="icon"></span>
          </div>
          <div [ngStyle]="!isShowMenu(ContactRoleMenuType.ACTIVATE_ON) && { display: 'none' }">
            <app-masterlist-sorting-menu
              [sortingKey]="ContactRoleMenuType.ACTIVATE_ON"
              [masterList]="masterListFiltered"
            ></app-masterlist-sorting-menu>
          </div>
        </td>
        <td (click)="showFilteringSortingMenu(ContactRoleMenuType.DEACTIVATION_ON)" class="filter-column unsortable-column">
          <div class="sortable">
            <span>Deactivation date</span>
            <span class="icon"></span>
          </div>
          <div [ngStyle]="!isShowMenu(ContactRoleMenuType.DEACTIVATION_ON) && { display: 'none' }">
            <app-masterlist-sorting-menu
              [sortingKey]=" ContactRoleMenuType.DEACTIVATION_ON"
              [masterList]="masterListFiltered"
            ></app-masterlist-sorting-menu>
          </div>
        </td>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of masterListFiltered" (click)="edit(item)">
        <td>
          <div class="first-column-container">
            <div class="first-column-content">
              {{ item.businessKey }}
            </div>
          </div>
        </td>
        <td>
          {{ item.contactRoleName }}
        </td>
        <td>
          {{ item.description }}
        </td>
        <td>
          {{ item.uiSort }}
        </td>

        <td class="align-center">
          <input type="checkbox" [checked]="item.autoImportFromSearch" disabled="true" />
        </td>
        <td class="align-center">
          <input type="checkbox" [checked]="item.excludeFromMedicalAssessment" disabled="true" />
        </td>
        <td class="align-center">
          <input type="checkbox" [checked]="item.excludeFromServiceOrder" disabled="true" />
        </td>

        <td>
          {{ item.activateOn | date: 'dd MMM yyyy' }}
        </td>
        <td>
          {{ item.deactivateOn | date: 'dd MMM yyyy' }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <ng-template #MasterListModal>
    <app-constact-role-modal [newContactRole]='newContactRole' [masterListType]="masterListType" (refreshTable)="reload()"> </app-constact-role-modal>
  </ng-template>
</div>
