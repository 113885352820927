import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MasterListServiceItemModel } from 'app/shared/models/masterlist-service-item-model';
import { MasterListService } from 'app/core/services/masterlist.service';
import { OnepointModalService } from 'app/core/services/onepoint.modal.service';
import { SosServicesService } from 'app/core/services/sos-services.service';
import { Dictionary } from 'app/shared/models/dictionary';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import { PermissionService } from 'app/core/services/permission.service';
import { MasterListServiceTypeModel } from '../../../../shared/models/masterlist-service-type-model';

@Component({
  selector: 'app-masterlist-service-item-modal',
  templateUrl: './masterlist-service-item-modal.component.html',
  styleUrls: ['./masterlist-service-item-modal.component.scss'],
})
export class MasterListServiceItemModalComponent implements OnInit {
  @Input()
  masterListItem: MasterListServiceItemModel;

  @Output()
  refreshTable = new EventEmitter();

  vatValues: Dictionary[] = [
    { id: 'true', name: 'Yes' },
    { id: 'false', name: 'No' },
  ];
  serviceItemTypes: Dictionary[] = [
    { id: 'ONE_TO_ONE', name: '1 to 1' },
    { id: 'OTHER', name: 'Other' },
    { id: 'RECOVERY', name: 'Recovery' },

  ];

  serviceTypes: MasterListServiceTypeModel[];
  serviceTypesDictionary: Dictionary[];
  selectedServiceTypes: Dictionary[];

  selectedVatValue: string;
  error: string;
  canUpdate: boolean;
  isModelChanged: boolean;

  constructor(
    private masterListService: MasterListService,
    private onepointModalService: OnepointModalService,
    private sosServicesService: SosServicesService,
    private permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.selectedVatValue = !this.isNew ? '' + this.masterListItem.vat : null;
    this.permissionService.permissionsReadyNotification.subscribe(
      result => (this.canUpdate = this.permissionService.checkUserPermission(PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE))
    );
    this.selectedServiceTypes = [];
    this.masterListItem.serviceTypes?.forEach(type => this.selectedServiceTypes.push(new Dictionary(type.id, type.serviceTypeName)));
    this.sosServicesService.getServiceTypeModalList().subscribe(response => {
      this.serviceTypesDictionary = [];
      this.serviceTypes = response;
      this.serviceTypes.forEach(type => this.serviceTypesDictionary.push(new Dictionary(type.id, type.serviceTypeName)));
    });
  }

  closeModal() {
    this.onepointModalService.dismissAll();
  }

  get isSaveButtonEnabled(): boolean {
    return (
      this.isModelChanged &&
      this.masterListItem &&
      !!this.masterListItem.activateOn &&
      !!this.masterListItem.serviceItemCode &&
      !!this.masterListItem.serviceItemName &&
      !!(this.masterListItem.serviceTypes?.length > 0) &&
      this.masterListItem.vat != null
    );
  }

  save() {
    this.masterListItem.automatedApprovalLimitCurrency = this.automatedApprovalLimitCurrency;
    if (this.isNew) {
      this.masterListService.addServiceItem(this.masterListItem).subscribe(
        response => {
          this.masterListItem = response;
          this.refreshTable.emit();
          this.closeModal();
        },
        err => (this.error = err.error ? err.error.message : err.message)
      );
    } else {
      this.masterListService.updateServiceItem(this.masterListItem).subscribe(
        response => {
          this.masterListItem = response;
          this.refreshTable.emit();
          this.closeModal();
        },
        err => (this.error = err.error ? err.error.message : err.message)
      );
    }
  }

  selectedServiceTypeChanged(): void {
    this.modelChanged();
    this.masterListItem.serviceTypes = [];
    this.selectedServiceTypes.forEach(dictionary =>
      this.masterListItem.serviceTypes.push(this.serviceTypes.find(type => type.id === dictionary.id))
    );
  }

  selectedVatValueChanged(value: string): void {
    this.modelChanged();
    this.masterListItem.vat = value === 'true';
  }

  selectedTypeValueChanged(value: string): void {
    this.modelChanged();
    this.masterListItem.serviceItemType = value;
  }

  activateOnChanged(item: MasterListServiceItemModel) {
    this.modelChanged();
    item.activateOn.setHours(12, 0);
  }

  deactivateOnChanged(item: MasterListServiceItemModel) {
    this.modelChanged();
    item.deactivateOn.setHours(12, 0);
  }

  modelChanged(): void {
    this.isModelChanged = true;
  }

  get isReadonly(): boolean {
    return !this.canUpdate;
  }

  get isNew(): boolean {
    return this.masterListItem.id == null;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get automatedApprovalLimitCurrency() {
    return this.masterListItem.automatedApprovalLimitValue == null ? null :
      (this.masterListItem.automatedApprovalLimitCurrency || 'DKK');
  }
}
