import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {BrandAdapter} from '../../shared/models/brand';
import {ProfileAdapter} from '../../shared/models/profile';
import {SettingsService} from './settings.service';
import {Observable} from 'rxjs';
import {Debtor} from '../../shared/models/debtor';

@Injectable({
  providedIn: 'root'
})
export class DebtorService extends BaseService {
  constructor(
    private http: HttpClient,
    private brandAdapter: BrandAdapter,
    private profileAdapter: ProfileAdapter,
    private settingsService: SettingsService
  ) {
    super(settingsService);
  }

  public getDebtors(commonCustomerId: number): Observable<Debtor[]> {
    return this.http.get<Debtor[]>(
      this.baseURL + 'common-customers/' + commonCustomerId + '/debtors',
      { headers: this.jsonHeaders }
    );
  }

  public getAllDebtorsForProfile(profileId: number): Observable<Debtor[]> {
    return this.http.get<Debtor[]>(
      this.baseURL + 'common-customers/by-profile/' + profileId + '/debtors',
      { headers: this.jsonHeaders }
    );
  }

  public updateDebtor(commonCustomerId: number, debtor: Debtor): Observable<Debtor> {
    return this.http.put<Debtor>(
      this.baseURL + 'common-customers/' + commonCustomerId + '/debtors/' + debtor.id,
      JSON.stringify(debtor),
      { headers: this.jsonHeaders }
    );
  }

  public saveDebtor(commonCustomerId: number, debtor: Debtor): Observable<any> {
    return this.http.post<Debtor>(this.baseURL + 'common-customers/' + commonCustomerId + '/debtors', debtor, {
      headers: this.jsonHeaders
    });
  }
}
