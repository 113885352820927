import { BusinessAreaEnum } from '../../shared/models/enums';
import { Observable } from 'rxjs';
import { ContractAdapter, Contract } from '../../shared/models/contract';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContractService extends BaseService {
  constructor(
    private settingsService: SettingsService,
    private contractAdapter: ContractAdapter,
    private http: HttpClient
  ) {
    super(settingsService);
  }

  public updateContract(contract: Contract): Observable<Contract> {
    return this.http
      .put<Contract>(
        this.baseURL + 'contracts/' + contract.id,
        JSON.stringify(this.contractAdapter.adaptForServiceCall(contract)),
        { headers: this.jsonHeaders }
      )
      .pipe(map(data => this.contractAdapter.adapt(data)));
  }

  public getContractsForCommonCustomer(
    commonCustomerId: string
  ): Observable<Contract[]> {
    return this.http
      .get(this.baseURL + 'contracts/byCommonCustomerId/' + commonCustomerId)
      .pipe(
        map((data: any[]) => data.map(item => this.contractAdapter.adapt(item)))
      );
  }

  public createContract(contract: Contract): Observable<Contract> {
    return this.http
      .post<Contract>(this.baseURL + 'contracts', JSON.stringify(this.contractAdapter.adaptForServiceCall(contract)), {
        headers: this.jsonHeaders
      })
      .pipe(map(data => this.contractAdapter.adapt(data)));
  }

  public isAddingNewContractAllowed(
    contracts: Contract[],
    businessAreaEnum: BusinessAreaEnum
  ): boolean {
    return (
      contracts.filter(
        a =>
          a.businessArea === businessAreaEnum &&
          a.terminationDate == null
      ).length === 0
    );
  }
}
