<div class="common-content-container">
  <div class="common-content-container-title">Case processes settings</div>
  <div class="common-content-container-body">
    <div>
      <table class="primary-table profile-case-processes-list-table">
        <thead>
        <tr>
          <td class="type-column">Case process type</td>
          <td class="boolean-column">Included</td>
          <td class="date-column">Start date</td>
          <td class="date-column">End date</td>
        </tr>
        </thead>
        <tbody>
        <ng-template ngFor let-caseProcessProfile [ngForOf]="caseProcessProfiles">
          <tr>
            <td class="type-column">
              <div>{{getTypeName(caseProcessProfile.caseProcessType)}}</div>
            </td>
            <td class="boolean-column-center">
              <div class="checkbox-container">
                <input class="includedCheckbox"
                       type="checkbox"
                       (change)="checkBoxIncludedChange()"
                       [(ngModel)]="caseProcessProfile.included"/>
              </div>
            </td>
            <td class="date-column">
              <app-input-calendar
                [withImage]="true"
                [(dateTimeRange)]="caseProcessProfile.validFrom"
                [selectMode]="'single'"
                [maxDate]="caseProcessProfile.validTo"
                (saveModel)="dateChangeValidFrom(caseProcessProfile)">
              </app-input-calendar>
            </td>
            <td class="date-column">
              <app-input-calendar
                [withImage]="true"
                [(dateTimeRange)]="caseProcessProfile.validTo"
                [selectMode]="'single'"
                [minDate]="caseProcessProfile.validFrom"
                (saveModel)="dateChangeValidTo(caseProcessProfile)">
              </app-input-calendar>
            </td>
          </tr>
        </ng-template>
        </tbody>
      </table>
    </div>
    <div class="profile-case-processes-container-footer">
      <button class="primary-button save-button" (click)="saveChanges()" [ngClass]="{ 'button-disabled': !(hasChanged) }"
              [disabled]="!(hasChanged)">Save changes</button>
    </div>
  </div>
</div>
