import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasterListChannelTypeModel } from '@onepoint/shared/models/masterlist-channel-type-model';
import { MasterListService } from 'app/core/services/masterlist.service';
import { OnepointModalService } from 'app/core/services/onepoint.modal.service';
import { PermissionService } from 'app/core/services/permission.service';
import { Dictionary } from 'app/shared/models/dictionary';
import { PermissionEnum } from 'app/shared/models/permission.enum';

@Component({
  selector: 'app-masterlist-channel-type-modal',
  templateUrl: './masterlist-channel-type-modal.component.html',
  styleUrls: ['./masterlist-channel-type-modal.component.scss'],
})
export class MasterlistChannelTypeModalComponent implements OnInit {
  @Input()
  masterListType: MasterListChannelTypeModel;

  @Output()
  refreshTable = new EventEmitter();

  vatValues: Dictionary[] = [
    { id: 'true', name: 'Yes' },
    { id: 'false', name: 'No' },
  ];
  minDeactivationDate: Date;
  error: string;
  canUpdate: boolean;
  isModelChanged: boolean;

  constructor(
    private masterListService: MasterListService,
    private onepointModalService: OnepointModalService,
    private permissionService: PermissionService,
  ) {}

  ngOnInit(): void {
    this.minDeactivationDate = new Date(Date.now() - 86400000);
    this.permissionService.permissionsReadyNotification.subscribe(
      result => (this.canUpdate = this.permissionService.checkUserPermission(PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE))
    );
    if (this.masterListType.activateOn > this.minDeactivationDate) {
      this.minDeactivationDate = this.masterListType.activateOn;
    }
  }

  closeModal() {
    this.onepointModalService.dismissAll();
  }

  get isSaveButtonEnabled(): boolean {
    return (
      this.isModelChanged &&
      this.masterListType &&
      !!this.masterListType.activateOn &&
      !!this.masterListType.channelTypeCode &&
      !!this.masterListType.channelTypeName
    );
  }

  save() {
    if (this.isNew) {
      this.masterListService.addChannelType(this.masterListType).subscribe({
        next: (response) => {
          this.masterListType = response;
          this.refreshTable.emit();
          this.closeModal();
        },
        error: (err) => (this.error = err.error ? err.error.message : err.message)
      });
    }
    else {
      this.masterListService.updateChannelType(this.masterListType).subscribe({
        next: (response) => {
          this.masterListType = response;
          this.refreshTable.emit();
          this.closeModal();
        },
        error: (err) => (this.error = err.error ? err.error.message : err.message)
      });
    }
  }

  activateOnChanged(item: MasterListChannelTypeModel) {
    this.modelChanged();
    item.activateOn.setHours(12, 0);
  }
  
  deactivateOnChanged(item: MasterListChannelTypeModel) {
    this.modelChanged();
    item.deactivateOn.setHours(12, 0);
  }

  modelChanged(): void {
    this.isModelChanged = true;
  }

  get isReadonly(): boolean {
    return !this.canUpdate;
  }

  get isNew(): boolean {
    return this.masterListType.id == null;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
