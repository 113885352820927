import { Injectable, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {NgbModalOptions} from "@ng-bootstrap/ng-bootstrap/modal/modal-config";

@Injectable({
  providedIn: 'root'
})
export class OnepointModalService {
  constructor(
    private modalService: NgbModal
  ) {}

  open(template: TemplateRef<any>, size: Size) {
    return this.modalService.open(template, {
      size: size as any,
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
  }

  openWithCustomWindowClass(template: TemplateRef<any>, size: Size, windowClass: string) {
    return this.modalService.open(template, {
      size: size as any,
      centered: true,
      backdrop: 'static',
      keyboard: false,
      windowClass
    });
  }

  dismissAll() {
    this.modalService.dismissAll();
  }
}

export enum Size {
  LARGE = 'xl',
  NORMAL = 'lg',
  SMALL = 'sm'
}
