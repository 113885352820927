<div class="menu">
  <ul class="nav justify-content-center">
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === menus.overview}" (click)="shownSection = menus.overview">
        <div>Overview</div>
      </div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === menus.settings}" (click)="shownSection = menus.settings">
        <div>Settings</div>
      </div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === menus.terms}" (click)="shownSection = menus.terms">
        <div>Terms, coverages &amp; limits</div>
      </div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === menus.integrations}" (click)="shownSection = menus.integrations">
        <div>Policy lookup</div>
      </div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === menus.debtor}" (click)="shownSection = menus.debtor">
        <div>Debtor</div>
      </div>
    </li>
  </ul>
</div>
<app-product-overview *ngIf="product && shownSection === menus.overview" [product]="product"></app-product-overview>
<app-product-settings *ngIf="shownSection === menus.settings" [product]="product" (changed)="productChange($event)"></app-product-settings>
<app-product-terms *ngIf="shownSection === menus.terms" [selectedProduct]="product"
                   (productChange)="productChange($event)"></app-product-terms>
<app-product-lookup-integration *ngIf="shownSection === menus.integrations" [product]="product"></app-product-lookup-integration>
<app-product-policy-relations *ngIf="(shownSection === menus.integrations) && product" [product]="product" [productId]="product.id"></app-product-policy-relations>
<app-product-debtor *ngIf="shownSection === menus.debtor" [product]="product" (changed)="productChange($event)"></app-product-debtor>
