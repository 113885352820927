import { CustomerListView } from 'app/shared/models/customer-list-view';
import { DictionaryService } from '../../../../../core/services/dictionary.service';
import { Component, Input, OnInit } from '@angular/core';
import { Kam, KamAdapter } from 'app/shared/models/kam';
import { BusinessArea } from '../../../../../shared/models/customers/business-area';

@Component({
  selector: 'app-brand-summary',
  templateUrl: './brand-summary.component.html',
  styleUrls: ['./brand-summary.component.scss']
})
export class BrandSummaryComponent implements OnInit {
  @Input() numberOfProfiles: number;
  markets: string[];
  kam: Kam;
  areas: BusinessArea[];

  @Input() set customerListView(customerListView: CustomerListView) {
    if (customerListView !== undefined) {
      this._customerListView = customerListView;
      this.markets = this.dictionaryService.getMarketsName(this._customerListView.market);
      this.areas = this.customerListView.getBusinessAreas();
      this.kam = this.getKam(customerListView);
    }
  }
  get customerListView(): CustomerListView {
    return this._customerListView;
  }
  private _customerListView: CustomerListView;

  constructor(
    private dictionaryService: DictionaryService,
    private kamAdapter: KamAdapter
  ) {}

  ngOnInit() {}

  private getKam(brand: CustomerListView): Kam {
    if (brand.keyAccountManagerUserId) {
      return this.kamAdapter.adapt({
        userId: brand.keyAccountManagerUserId,
        firstName: brand.keyAccountManagerFirstName,
        surname: brand.keyAccountManagerSurname,
        userWorkEmail: brand.keyAccountManagerEmail,
        userWorkPhone: brand.keyAccountManagerPhone
      });
    }
  }
}
