import {Component, TemplateRef, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent {

  @Input() closeBtnLabel = 'Close';
  @ViewChild('content', { static: true }) template: TemplateRef<any>;
  @Output() closed = new EventEmitter<any>();

  title: string;
  message: string;

  constructor(
    private modalService: NgbModal
  ) {
  }

  close() {
    this.closed.emit();
    this.modalService.dismissAll();
  }

  public open(title = 'Warning', message = '') {
    this.title = title;
    this.message = message;
    this.modalService.open(this.template, { windowClass : 'modal-container' });
  }
}
