import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HelperService} from '@onepoint/core/services/helper.service';
import {CaseProcessProfile} from '@onepoint/shared/models/case-process-profile';

@Component({
  selector: 'app-customer-profile-case-processes-overview-sorting-menu',
  templateUrl: './customer-profile-case-processes-overview-sorting-menu.component.html',
  styleUrls: ['./customer-profile-case-processes-overview-sorting-menu.component.scss']
})
export class CustomerProfileCaseProcessesOverviewSortingMenuComponent implements OnInit {

  @Input() caseProcessProfiles: CaseProcessProfile[];
  @Input() sortingKey: string;
  @Output() modelChanged = new EventEmitter();
  sortByAscending: boolean;
  dateFilter: boolean;
  fromDate: any;
  toDate: any;
  constructor(private helperService: HelperService) {}

  ngOnInit(): void {
  }

  sortAscending() {
    this.sortByAscending = true;
    this.helperService.sortArray(
      this.caseProcessProfiles,
      this.sortingKey,
      this.sortByAscending
    );
    this.modelChanged.emit();
  }

  sortDescending() {
    this.sortByAscending = false;
    this.helperService.sortArray(
      this.caseProcessProfiles,
      this.sortingKey,
      this.sortByAscending
    );
    this.modelChanged.emit();
  }
}
