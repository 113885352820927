import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Component,
  Output,
  OnInit,
  Input,
  ViewEncapsulation,
  EventEmitter,
  forwardRef,
  OnChanges,
} from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-drop-down-multiselect',
  templateUrl: './drop-down-multiselect.component.html',
  styleUrls: ['./drop-down-multiselect.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DropDownMultiselectComponent implements OnInit {
  @Output() selectedItemsChange = new EventEmitter();
  @Input() placeholder: string;
  @Input() name: string;
  @Input() textField: string = 'name';
  @Input() required: boolean;
  @Input() dropdownItemList: any[];
  @Input() selectedItems: any[];
  @Input() disabled: boolean;
  @Input() singleValue: boolean;
  @Input() maxHeight = 350;
  @Input() subText = null;
  dropdownSettings: IDropdownSettings;

  constructor() {
    if (this.placeholder === undefined) {
      this.placeholder = '';
    }
  }
  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: this.textField,
      allowSearchFilter: true,
      showSelectedItemsAtTop: false,
      maxHeight: this.maxHeight,
      enableCheckAll: false,
      searchPlaceholderText: 'Search',
    }
  }

  onItemDeSelect(item) {
    this.selectedItemsChange.emit(this.selectedItems);
  }

  onItemSelect(item) {
    if (this.singleValue && this.selectedItems.length > 1) {
        this.selectedItems = [item];
    }
    this.selectedItemsChange.emit(this.selectedItems);
  }

  onChangeValue() {
    this.selectedItemsChange.emit(this.selectedItems);
  }
}
