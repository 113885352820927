<ng-template #content let-modal>
  <div class="onepoint-modal-container">
    <div class="onepoint-modal-header">
      <div class="onepoint-modal-header-title">{{ title }}</div>
      <div class="onepoint-modal-header-close">
        <img (click)="cancel()" src="assets/icons/Close.svg" alt="Close" />
      </div>
    </div>
    <div class="onepoint-modal-body">
      <div class="message">
        <ng-container *ngIf="messageTemplate; then messageTemplate else defaultTemplate"></ng-container>
      </div>
    </div>
    <div class="onepoint-modal-footer">
      <div class="float-end">
        <button class="primary-button float-end" (click)="cancel()">
          {{ cancelBtnLabel }}
        </button>
        <button class="primary-button" (click)="confirm()">
          {{ confirmBtnLabel }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #defaultTemplate>{{ message }}</ng-template>
