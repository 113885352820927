export class MasterlistDiagnosisModel {
  id: string;
  businessKey: string;
  name: string;
  icd10Code: string;
  icd10Name: string;
  uiSortIndex: number;
  activateOn: Date;
  deactivateOn: Date;
  translationsAvailable: { [index: string]: string };
  active: boolean;

  public constructor(init?: Partial<MasterlistDiagnosisModel>) {
    Object.assign(this, init);
  }
}
