<div class="rule-mapping-modal-container">
  <div class="closing-x" (click)="closeModal()">
    <img src="/assets/icons/Close.svg">
  </div>
  <div class="header">
    <span *ngIf="isItAdding">Create new Business Rule</span>
    <span *ngIf="!isItAdding">Edit Business Rule</span>
  </div>
  <div class="row body-container" *ngIf="selectedRuleMapping">
    <div class="col-3">
      <app-drop-down-list
        name="Type"
        [items]="ruleTypes"
        [(selectedItem)]="selectedRuleType">
      </app-drop-down-list>

      <div class="padding-top">
        <app-drop-down-list
          name="Status"
          [items]="ruleStatus"
          [(selectedItem)]="selectedRuleStatus">
        </app-drop-down-list>
      </div>
    </div>
    <div class="col-9">
      <div class="row">
        <div class="col-6 custom-padding narrow">
          <div>
            <app-input [name]="'Rule name'" [(value)]="selectedRuleMapping.name"></app-input>
          </div>
          <div class="padding-top">
            <app-drop-down-list
              name="Rule"
              [items]="rules"
              bindLabel="name"
              [(selectedItem)]="selectedRule">
            </app-drop-down-list>
          </div>
          <div class="padding-top">
            <div class="field-title">Rule description</div>
            <textarea disabled [ngModel]="ruleDescription"></textarea>
          </div>
        </div>
        <div class="col-6 custom-padding wide">
          <div>
            <app-drop-down-list
              name="Event"
              [items]="ruleEvents"
              bindLabel="name"
              [(selectedItem)]="selectedRuleEvent">
            </app-drop-down-list>
          </div>
          <div class="padding-top">
            <app-drop-down-list
              name="Action"
              [items]="ruleActions"
              bindLabel="name"
              [(selectedItem)]="selectedRuleAction">
            </app-drop-down-list>
          </div>
          <div class="padding-top">
            <div class="field-title">Action input</div>
            <textarea [(ngModel)]="actionInputJSON"></textarea>
          </div>
        </div>
        <div class="col-12 custom-padding padding-bottom">
             <app-input [name]="'Remarks'" [(value)]="selectedRuleMapping.remarks"></app-input>
        </div>
      </div>
    </div>
  </div>
  <div class="masterlist-modal-footer d-flex justify-content-between">
    <button class="primary-button save-button" [class.button-disabled]="!isSaveButtonEnabled"
    (click)="save()">Save</button>
    <app-last-changed [masterListItem]="selectedRuleMapping" class="last-changed float-end"> </app-last-changed>
  </div>
</div>
