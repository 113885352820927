import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MasterListService } from 'app/core/services/masterlist.service';
import { Dictionary, TeamAdtCodeDropdownAdapter } from 'app/shared/models/dictionary';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import * as _ from 'lodash';
import { OnepointModalService, Size } from 'app/core/services/onepoint.modal.service';
import { DedicatedEmailMenuType } from './dedicated-email-menu/masterlist-dedicated-email-menu-type';
import { MasterListDedicatedEmailModel } from '@onepoint/shared/models/masterlist-dedicated-email-model';

@Component({
  selector: 'app-masterlist-dedicated-email',
  templateUrl: './masterlist-dedicated-emails.component.html',
  styleUrls: ['./masterlist-dedicated-emails.component.scss'],
})
export class MasterlistDedicatedEmailsComponent implements OnInit {
  @ViewChild('MasterListModal')
  private masterListModalTemplate: TemplateRef<any>;

  masterList: MasterListDedicatedEmailModel[];
  masterListFiltered: MasterListDedicatedEmailModel[];
  masterListType: MasterListDedicatedEmailModel;
  showMenu = new Map<DedicatedEmailMenuType, boolean>();
  serviceTypesMap = new Map<string, string>();
  statusTypes: Dictionary[] = [
    { id: 'all', name: 'All' },
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
  ];
  selectedStatus = 'all';

  get DedicatedEmailMenuType() {
    return DedicatedEmailMenuType;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  constructor(
    private masterListService: MasterListService,
    private onepointModalService: OnepointModalService,
    private teamAdtCodeDropdownAdapter: TeamAdtCodeDropdownAdapter
  ) {}

  ngOnInit(): void {
    this.loadMasterList();
  }

  selectedStatusTypeChanged(status: string) {
    this.masterListFiltered = this.filterByStatus(status);
  }

  teamLabel(item: any): string {
    if (item) {
      return this.teamAdtCodeDropdownAdapter.label(item.teamName, item.adTCode);
    } else {
      return "";
    }
  }

  private loadMasterList() {
    this.masterListService.getAllDedicatedEmails().subscribe(result => {
      this.masterList = result;
      this.masterListFiltered = result;
    });
  }

  private filterByStatus(status: string): MasterListDedicatedEmailModel[] {
    if (status === 'all') {
      return this.masterList;
    }

    return this.masterList.filter(item => (status === 'active' ? item.active : !item.active));
  }

  showFilteringSortingMenu(filteringSortingMenuType: DedicatedEmailMenuType) {
    this.showMenu.forEach((value, key) => {
      if (key !== filteringSortingMenuType) {
        this.showMenu.set(key, false);
      }
    });

    this.showMenu.set(filteringSortingMenuType, !this.showMenu.get(filteringSortingMenuType));
  }

  isShowMenu(filteringSortingMenuType: DedicatedEmailMenuType) {
    return this.showMenu.get(filteringSortingMenuType);
  }

  edit(item: MasterListDedicatedEmailModel) {
    this.masterListType = _.clone(item);
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.LARGE, 'dedicated-email-modal-container');
  }
  
  addNew(): void {
    this.masterListType = new MasterListDedicatedEmailModel();
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.LARGE, 'dedicated-email-modal-container');
  }

  reload() {
    this.loadMasterList();
  }
}
