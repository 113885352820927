<div class="menu">
  <ul class="nav justify-content-center">
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'creditor'}" (click)="shownSection = 'creditor'">
        <div>Creditors</div>
      </div>
    </li>
  </ul>
</div>
<app-creditor-list *ngIf="shownSection === 'creditor'"></app-creditor-list>
