import { Brand } from 'app/shared/models/brand';
import { CustomerTypeEnum } from './../enums/customerType.enum';
import { Adapter } from './../interfaces/adapter';
import { Injectable } from '@angular/core';
import { CustomerListView } from './customer-list-view';
import { KamService } from 'app/core/services/kam.service';
import { Kam } from './kam';

export class CommonCustomer {
  id: string;
  name: string;
  groupSegment = { id: null, name: null };
  travelSegment = { id: null, name: null };
  roadsideSegment = { id: null, name: null };
  healthSegment = { id: null, name: null };
  sosOwner: boolean;
  subBusinessRelation = { id: null, name: null };
  brands: Brand[];
  isActive: boolean;
  keyAccountManagerUserId: string;
  keyAccountManagerFirstName: string;
  keyAccountManagerSurname: string;
  keyAccountManagerEmail: string;
  keyAccountManagerPhone: string;
  remarks: string;

  // redundant fields just to view
  market: string;
  isTravel: boolean;
  isRoadside: boolean;
  isHealth: boolean;
  nextNegotationDate: Date;
  active: boolean;
  deletable: boolean;

  public constructor(init?: Partial<CommonCustomer>) {
    Object.assign(this, init);
  }

  public updateKamInfo(kam: Kam) {
    this.keyAccountManagerUserId = kam.userId;
    this.keyAccountManagerFirstName = kam.firstName;
    this.keyAccountManagerSurname = kam.surname;
    this.keyAccountManagerEmail = kam.email;
    this.keyAccountManagerPhone = kam.phoneNumber;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CommonCustomerAdapter implements Adapter<CommonCustomer> {
  constructor(private kamService: KamService) {}

  adapt(item: any): CommonCustomer {
    const commonCustomer = new CommonCustomer({
      id: item.id,
      name: item.name,
      groupSegment: item.groupSegment,
      travelSegment: item.travelSegment,
      roadsideSegment: item.roadsideSegment,
      healthSegment: item.healthSegment,
      subBusinessRelation: item.subBusinessRelation,
      sosOwner: item.sosOwner,
      brands: item.brands,
      active: item.active,
      deletable: item.deletable,
      keyAccountManagerUserId: item.keyAccountManagerUserId,
      keyAccountManagerFirstName: item.keyAccountManagerFirstName,
      keyAccountManagerSurname: item.keyAccountManagerSurname,
      remarks: item.remarks,
    });
    return commonCustomer;
  }

  adaptToCommonCustomerListView(item: any): CustomerListView {
    const customerListView = new CustomerListView({
      id: item.id,
      name: item.name,
      type: CustomerTypeEnum.commonCustomer,
      market: item.markets,
      iconBlob: item.iconBlob,
      groupSegment: item.groupSegment,
      travelSegment: item.travelSegment,
      roadsideSegment: item.roadsideSegment,
      healthSegment: item.healthSegment,
      subBusinessRelation: item.subBusinessRelation,
      businessAreaCount:
        (item.isTravel ? 1 : 0) +
        (item.isRoadside ? 1 : 0) +
        (item.isHealth ? 1 : 0),
      isTravel: item.travel,
      isRoadside: item.roadside,
      isHealth: item.health,
      nextNegotationDate:
        !item.nextNegotationDate || item.nextNegotationDate === ''
          ? null
          : new Date(item.nextNegotationDate),
      sosOwner: item.sosOwner,
      integrationType: item.integrationType,
      segment: item.segment,
      children: item.brands,
      numberOfProfiles: item.numberOfProfiles ? item.numberOfProfiles : 0,
      active: item.active,
      deletable: item.deletable,
      keyAccountManagerUserId: item.keyAccountManagerUserId,
      keyAccountManagerFirstName: item.keyAccountManagerFirstName,
      keyAccountManagerSurname: item.keyAccountManagerSurname,
      keyAccountManagerEmail: item.keyAccountManagerEmail,
      keyAccountManagerPhone: item.keyAccountManagerPhone,
      remarks: item.remarks,
    });
    return customerListView;
  }
}
