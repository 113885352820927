import { Index } from '../../../shared/models/index';
import { UntypedFormControl } from '@angular/forms';
import { IndexService } from '../../../core/services/index.service';
import { Component, OnInit, EventEmitter, Output, Input, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { empty } from 'rxjs';
import {OnepointModalService, Size} from '../../../core/services/onepoint.modal.service';

@Component({
  selector: 'app-add-new-index',
  templateUrl: './add-new-index.component.html',
  styleUrls: ['./add-new-index.component.scss']
})
export class AddNewIndexComponent implements OnInit {
  @ViewChild('content')
  private template: TemplateRef<any>;
  theNewIndexName: string;
  @Input() indexList: Index[];
  @Input() year: string;
  @Output() refreshIndex = new EventEmitter<Index[]>();
  theIndexCanbeAdded: boolean;
  public controls: UntypedFormControl;

  constructor(
    private modalService: OnepointModalService,
    private indexService: IndexService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.theNewIndexName = '';
    this.checkIfTheNameIsUnique('');
  }

  public open() {
    this.modalService.open(this.template, Size.NORMAL);
  }

  close() {
    this.theNewIndexName = '';
    this.modalService.dismissAll();
  }

  checkIfTheNameIsUnique(value: string) {
    if (value !== '' && value !== null) {
      this.theIndexCanbeAdded = true;
      this.indexList.forEach(index => {
        if (this.theIndexCanbeAdded) { this.theIndexCanbeAdded = index.name !== value; }
      });
    } else {
      this.theIndexCanbeAdded = false;
    }
  }

  addIndexes() {
    this.indexService.addIndex(this.theNewIndexName).subscribe(result => {
      const theNewList: Index[] = new Array();
      result.year = this.year;
      theNewList.push(result);
      this.indexList.forEach(index => theNewList.push(index));
      this.refreshIndex.emit(theNewList);
      this.theNewIndexName = '';
    });
    this.modalService.dismissAll();
  }

}
