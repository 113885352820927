<div class="common-content-container">
  <div class="common-content-container-title">Applications settings</div>
  <div class="common-content-container-body">
    <div>
      <table class="primary-table profile-application-lists-table">
        <thead>
        <tr>
          <td class="type-column">Application</td>
          <td class="boolean-column">Included</td>
          <td class="date-column">Start date</td>
          <td class="date-column">End date</td>
          <td class="boolean-column">Legacy</td>
          <td class="type-column">Legacy codes</td>
        </tr>
        </thead>
        <tbody>
        <ng-template ngFor let-profileApplication [ngForOf]="profileApplications">
          <tr>
            <td class="type-column"><div>{{ type[profileApplication.type] }}</div></td>
            <td class="boolean-column-center">
              <div class="checkbox-container">
                <input class="applicationCheckbox"
                       type="checkbox"
                       (change)="(!profileApplication.included); checkBoxIncludedChange(profileApplication)"
                       [(ngModel)]="profileApplication.included"/>
              </div>
            </td>
            <td class="date-column">
              <app-input-calendar
                [withImage]="true"
                [(dateTimeRange)]="profileApplication.validFrom"
                [selectMode]="'single'"
                [maxDate]="profileApplication.validTo"
                (saveModel)="validDateChangeValidFrom(profileApplication)"
              >
              </app-input-calendar>
            </td>
            <td class="date-column">
              <app-input-calendar
                [withImage]="true"
                [(dateTimeRange)]="profileApplication.validTo"
                [selectMode]="'single'"
                [minDate]="profileApplication.validFrom"
                (saveModel)="validDateChangeValidTo(profileApplication)"
              >
              </app-input-calendar>
            </td>
            <td class="boolean-column-center">
              <div class="checkbox-container">
                <input class="applicationCheckbox"
                       type="checkbox"
                       (change)="(!profileApplication.stillLegacy); checkBoxLegacyChange(profileApplication)"
                       [(ngModel)]="profileApplication.stillLegacy"/>
              </div>
            </td>
            <td class="legacy-column"><div *ngIf="profileApplication.included">
                <app-drop-down *ngIf="this.editAsCaseEventsLegacyCode(profileApplication.type)"
                    [disabled]="!(profileApplication.stillLegacy)"
                    [items]="this.legacyCodes"
                    (change) = "checkBoxIncludedChange(profileApplication)"
                    [required]="profileApplication.stillLegacy"
                    [(selectedItemId)]="profileApplication.legacyCode"
                    [name]="''">
                </app-drop-down>
            </div></td>
          </tr>
        </ng-template>
      </table>
    </div>
    <div class="profile-application-container-footer">
      <button class="primary-button save-button" (click)="saveChanges()" [ngClass]="{ 'button-disabled': !(hasChanged && isValid()) }"
              [disabled]="!(hasChanged && isValid())" [title]="validationMessage()" >Save changes</button>
    </div>
  </div>
</div>

