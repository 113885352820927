import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SosServicesService } from '@onepoint/core/services/sos-services.service';
import { MasterListAdTeamsModel } from '@onepoint/shared/models/masterlist-ad-teams-model';
import { MasterListCommitmentTypeModel } from '@onepoint/shared/models/masterlist-commitment-type-model';
import { MasterListServiceTypeModel } from '@onepoint/shared/models/masterlist-service-type-model';
import { MasterListService } from 'app/core/services/masterlist.service';
import { OnepointModalService } from 'app/core/services/onepoint.modal.service';
import { PermissionService } from 'app/core/services/permission.service';
import { Dictionary } from 'app/shared/models/dictionary';
import { DictionaryWithType } from 'app/shared/models/dictionary-with-type';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import { resolve } from '@angular/compiler-cli';

@Component({
  selector: 'app-masterlist-commitment-type-modal',
  templateUrl: './masterlist-commitment-type-modal.component.html',
  styleUrls: ['./masterlist-commitment-type-modal.component.scss'],
})
export class MasterlistCommitmentTypeModalComponent implements OnInit {
  @Input()
  masterListType: MasterListCommitmentTypeModel;

  @Output()
  refreshTable = new EventEmitter();

  vatValues: Dictionary[] = [
    { id: 'true', name: 'Yes' },
    { id: 'false', name: 'No' },
  ];

  selectedServiceTypeId: string;
  selectedVatValue: string;
  error: string;
  canUpdate: boolean;
  isModelChanged: boolean;
  teams : MasterListAdTeamsModel[];
  serviceTypes: MasterListServiceTypeModel[];
  selectedServiceTypes: Dictionary[];
  sosServicesCategoryList: Dictionary[];
  serviceTypesDictionary: Dictionary[];

  constructor(
    private masterListService: MasterListService,
    private onepointModalService: OnepointModalService,
    private permissionService: PermissionService,
    private sosServicesService: SosServicesService,

  ) {}

  ngOnInit(): void {
    this.permissionService.permissionsReadyNotification.subscribe(
      result => (this.canUpdate = this.permissionService.checkUserPermission(PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE))
    );
    this.masterListService.getAdTeams().subscribe(teams => {
      this.teams = teams;
    });
    this.selectedServiceTypes = [];
    this.masterListType.serviceTypes?.forEach(type => this.selectedServiceTypes.push(new Dictionary(type.id, type.serviceTypeName)));
    this.sosServicesService.getSosServicesCategoryList().subscribe(response => {
      this.sosServicesCategoryList = response;
    });
    this.sosServicesService.getServiceTypeModalList().subscribe(response => {
      this.serviceTypesDictionary = [];
      this.serviceTypes = response;
      this.serviceTypes.forEach(type => this.serviceTypesDictionary.push(new Dictionary(type.id, type.serviceTypeName)));
    });
  }

  closeModal() {
    this.onepointModalService.dismissAll();
  }

  get isSaveButtonEnabled(): boolean {
    return (
      this.isModelChanged &&
      this.masterListType &&
      !!this.masterListType.activateOn &&
      !!this.masterListType.businessKey &&
      !!this.masterListType.name
    );
  }

  save() {
    if ( this.isNew ) {
      this.masterListService.addCommitmentType(this.masterListType).subscribe(
        response => {
          this.masterListType = response;
          this.refreshTable.emit();
          this.closeModal();
        },
        err => this.error = err.error ? err.error.message : err.message
      )
    } else {
      this.masterListService.updateCommitmentType(this.masterListType).subscribe(
        response => {
          this.masterListType = response;
          this.refreshTable.emit();
          this.closeModal();
        },
        err => (this.error = err.error ? err.error.message : err.message)
      );
    }
  }

  selectedServiceTypeChanged(): void {
    this.modelChanged();
    this.masterListType.serviceTypes = [];
    this.selectedServiceTypes.forEach(dictionary =>
      this.masterListType.serviceTypes.push(this.serviceTypes.find(type => type.id === dictionary.id))
    );
  }


  activateOnChanged(item: MasterListCommitmentTypeModel) {
    this.modelChanged();
    item.activateOn.setHours(12, 0);
  }

  deactivateOnChanged(item: MasterListCommitmentTypeModel) {
    this.modelChanged();
    item.deactivateOn.setHours(12, 0);
  }

  modelChanged(): void {
    this.isModelChanged = true;
  }

  get isReadonly(): boolean {
    return !this.canUpdate;
  }

  get isNew(): boolean {
    return this.masterListType.id == null;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
