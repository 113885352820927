<div class="common-content-container row" *ngIf="filteredCustomerProductRelationsList">
    <div class="col-12 clearfix">
        <div class="common-content-container-title">
            <div > Customer product relations for {{ product.name }} <span *ngIf="product.sosInsuranceLevel"> ({{ product.sosInsuranceLevel }})</span></div>
            <div class="float-start w-25" style="font-size: 14px;">
                <app-drop-down
                    [(selectedItemId)]="selectedFilterType"
                    [disabled]="false"
                    [items]="dropDownCustomerProductRelationFilterList"
                    (selectedItemIdChange)="filterTable()"
                    [name]="''">
                </app-drop-down>
            </div>
            <div class="float-end mb-3" *permission='PermissionEnum.CUSTOMER_COVERAGES_LIMITS_RELATION_CREATE'>
              <button *ngIf="!product.groupProfileProductId" class="primary-button" (click)="open()">Add new relation</button>
            </div>
            <input class="float-end" type="text" [(ngModel)]="searchText" placeholder="Search..." (ngModelChange)="searchTable($event)" />
        </div>

        <div class="common-content-container-body">
            <div class="mb-5">
                <table class="product-relation-table" >
                    <thead>
                        <tr (clickOutside)="closeFilteringSortingMenu()">
                            <td (click)="showFilteringSortingMenu(ProductsPolicyRelationSortingTypeEnum.companyPolicyHolder)" class="filter-column">
                                <div [ngStyle]="!showFilterMenu[ProductsPolicyRelationSortingTypeEnum.companyPolicyHolder] && { display: 'none' }">
                                    <app-product-policy-relations-sorting-menu
                                      (modelChanged)="finishedAndFilteringSorting()"
                                      [productsPolicyRelationSortingTypeEnum]="ProductsPolicyRelationSortingTypeEnum.companyPolicyHolder"
                                      (clearAllFilters)="clearAllFiltering()"
                                      [(customerProductRelationsList)]="filteredCustomerProductRelationsList"
                                    ></app-product-policy-relations-sorting-menu>
                                </div>
                                Company policy holder
                            </td>
                            <td sortColumn [sortKey]="'policyNo'" [data]="filteredCustomerProductRelationsList">Policy no.</td>
                            <td (click)="showFilteringSortingMenu(ProductsPolicyRelationSortingTypeEnum.bank)" class="filter-column">
                                <div [ngStyle]="!showFilterMenu[ProductsPolicyRelationSortingTypeEnum.bank] && { display: 'none' }">
                                    <app-product-policy-relations-sorting-menu
                                      (modelChanged)="finishedAndFilteringSorting()"
                                      [productsPolicyRelationSortingTypeEnum]="ProductsPolicyRelationSortingTypeEnum.bank"
                                      (clearAllFilters)="clearAllFiltering()"
                                      [(customerProductRelationsList)]="filteredCustomerProductRelationsList"
                                    ></app-product-policy-relations-sorting-menu>
                                </div>
                                Bank
                            </td>
                            <td (click)="showFilteringSortingMenu(ProductsPolicyRelationSortingTypeEnum.BIN)" class="filter-column">
                                <div [ngStyle]="!showFilterMenu[ProductsPolicyRelationSortingTypeEnum.BIN] && { display: 'none' }">
                                    <app-product-policy-relations-sorting-menu
                                      (modelChanged)="finishedAndFilteringSorting()"
                                      [productsPolicyRelationSortingTypeEnum]="ProductsPolicyRelationSortingTypeEnum.BIN"
                                      (clearAllFilters)="clearAllFiltering()"
                                      [(customerProductRelationsList)]="filteredCustomerProductRelationsList"
                                    ></app-product-policy-relations-sorting-menu>
                                </div>
                                BIN no.
                            </td>
                            <td sortColumn [sortKey]="'splitDateType'" [data]="filteredCustomerProductRelationsList">Split date based on</td>
                            <td (click)="showFilteringSortingMenu(ProductsPolicyRelationSortingTypeEnum.customerRelationLabel)" class="filter-column">
                                <div [ngStyle]="!showFilterMenu[ProductsPolicyRelationSortingTypeEnum.customerRelationLabel] && { display: 'none' }">
                                    <app-product-policy-relations-sorting-menu
                                      (modelChanged)="finishedAndFilteringSorting()"
                                      [productsPolicyRelationSortingTypeEnum]="ProductsPolicyRelationSortingTypeEnum.customerRelationLabel"
                                      (clearAllFilters)="clearAllFiltering()"
                                      [(customerProductRelationsList)]="filteredCustomerProductRelationsList"
                                    ></app-product-policy-relations-sorting-menu>
                                </div>
                                Customer reporting label
                            </td>
                            <td sortColumn [sortKey]="'validFrom'" [data]="filteredCustomerProductRelationsList">Valid from</td>
                            <td sortColumn [sortKey]="'validTo'" [data]="filteredCustomerProductRelationsList">Valid to</td>
                            <td *ngIf='editable()'></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let productRelation of searchResultcustomerProductRelationsList,  let idx = index" [ngClass]="{ 'inherited': !!product.groupProfileProductId }">
                            <td> {{ productRelation.companyPolicyHolder }}</td>
                            <td> {{ productRelation.policyNo }}
                                <img *ngIf="productRelation.remarks" src="assets/icons/Warning.svg" (click)="setSelect(idx)">
                                <div *ngIf="getSelected() === idx" (click)="closeProductRelation()" class="grayBackground"></div>
                                <div *ngIf="getSelected() === idx" class="remarkContainer">
                                    <div class="position-relative fixed-top p-2 w100 remarkTop">Policy lookup remarks</div>
                                    <div class="position-relative fixed-bottom p-2 w100 remarkBottom" >
                                        <textarea
                                            name="remarks"
                                            rows="4"
                                            [disabled]="true"
                                            class="textRemark"
                                            [(ngModel)]="productRelation.remarks"
                                            [maxLength]="250">
                                      </textarea>
                                    </div>
                                </div>
                            </td>
                            <td> {{ productRelation.bank }} </td>
                            <td> {{ productRelation.bin }} </td>
                            <td> {{ splitDateTypes.get(productRelation.splitDateType) }}</td>
                            <td> <ng-container *ngIf="productRelation.customerRelationLabel">{{ productRelation.customerRelationLabel.name }}</ng-container></td>
                            <td> {{ productRelation.validFrom | date: 'dd MMM yyyy' }} </td>
                            <td> {{ productRelation.validTo | date: 'dd MMM yyyy' }} </td>
                            <td style="width: 150px;" *ngIf='editable()'>
                                <div class="d-flex">
                                    <span *permission='PermissionEnum.CUSTOMER_COVERAGES_LIMITS_RELATION_UPDATE'>
                                      <button *ngIf="!product.groupProfileProductId" class="primary-button-outline my-auto" (click)="editCustomerProductRelation(productRelation.id)">Edit</button>
                                    </span>
                                    <span *permission='PermissionEnum.CUSTOMER_COVERAGES_LIMITS_RELATION_DELETE'>
                                      <button *ngIf="!product.groupProfileProductId" class="btn" (click)="showDeleteModal(productRelation.id, productRelation.companyPolicyHolder)"><img src="assets/icons/garbage-red.svg"
                                          alt="Delete product relation" /></button>
                                    </span>
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>
                <app-product-policy-relations-modal  [customerProductRelations]="customerProductRelationsList" (closeProductRelation)="closeProductRelation()" (addProductRelation)="addProductRelation($event)" [minDate]="product.validFrom" [maxDate]="product.validTo" #productPolicyRelationsModalComponent></app-product-policy-relations-modal>
              </div>
        </div>
    </div>
</div>
<app-confirm-modal #deleteConfirmModal (confirmed)="deleteProductRelation()" confirmBtnLabel="Yes" cancelBtnLabel="No"></app-confirm-modal>
