import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomerListView} from '../../../../shared/models/customer-list-view';
import {Brand} from '../../../../shared/models/brand';
import {CommonCustomerChangeService} from '../../../../core/services/common-customer-change';
import { PermissionEnum } from 'app/shared/models/permission.enum';

@Component({
  selector: 'app-common-customer-menu',
  templateUrl: './common-customer-menu.component.html',
  styleUrls: ['./common-customer-menu.component.scss']
})
export class CommonCustomerMenuComponent implements OnInit {
  @Input() commonCustomer: CustomerListView;
  @Input() brands: CustomerListView[];
  @Output() refreshBrands = new EventEmitter<Brand>();

  shownSection: string;

  constructor(private commonCustomerChange: CommonCustomerChangeService) { }

  ngOnInit() {
    this.shownSection = 'overview';
    this.commonCustomerChange.currentCommonCustomer.subscribe(
      commonCustomer => {
        this.commonCustomer = commonCustomer;
    });
  }

  refreshBrandsEvent(event) {
    this.refreshBrands.emit(event);
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
