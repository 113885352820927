import {Adapter} from '../interfaces/adapter';
import {Injectable} from '@angular/core';

export class IncidentCause {

  level1: string;
  level2: string;
  level3: string;

  public constructor(init?: Partial<IncidentCause>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})

export class IncidentCauseAdapter implements Adapter<IncidentCause> {

  public adapt(item: any): IncidentCause {

    return new IncidentCause({
      level1: item.level1,
      level2: item.level2,
      level3: item.level3,
    });
  }
}
