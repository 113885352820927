import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from './settings.service';
import {CommonCustomer} from '@onepoint/shared/models/common-customer';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CustomerSlaAgreement, CustomerSlaAgreementAdapter} from "@onepoint/shared/models/customer-sla-agreement";

@Injectable({
  providedIn: 'root',
})
export class CustomerSlaAgreementService extends BaseService {
  constructor(
    private settingsService: SettingsService,
    private http: HttpClient,
    private customerSlaAgreementAdapter: CustomerSlaAgreementAdapter
  ) {
    super(settingsService);
  }

  public getCustomerSlaAgreements(contractId: string): Observable<CustomerSlaAgreement[]> {
    return this.http
      .get(this.baseURL + 'contracts/' + contractId + '/customer-sla-agreements', {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.customerSlaAgreementAdapter.adapt(item))));
  }

  public getCustomerSlaAgreementPrototype(contractId: string): Observable<CustomerSlaAgreement> {
    return this.http
      .get(this.baseURL + 'contracts/' + contractId + '/customer-sla-agreement/prototype', {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.customerSlaAgreementAdapter.adapt(item)));
  }

  public saveCustomerSlaAgreement(customerSlaAgreement: CustomerSlaAgreement): Observable<CustomerSlaAgreement> {
    return this.http
      .put<CommonCustomer>(this.baseURL + 'contracts/customer-sla-agreement', customerSlaAgreement, {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.customerSlaAgreementAdapter.adapt(item)));
  }
}
