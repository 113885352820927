<div class="page-container">
  <div class="page-header">
    <div class="my-auto counts-container">
      <div class="count-container">
        <span>Dedicated Emails</span> <span>{{ masterListFiltered?.length }}</span>
      </div>
    </div>
    <div class="status-container my-auto">
      <app-drop-down [(selectedItemId)]="selectedStatus" [items]="statusTypes" (selectedItemIdChange)="selectedStatusTypeChanged($event)">
      </app-drop-down>
    </div>

    <div class="button-container my-auto">
      <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_CREATE">
        <button class="primary-button" (click)="addNew()">
          Add Dedicated Email
        </button>
      </div>
    </div>
  </div>
  <div class="primary-table page-content-container">
    <table class="master-lists-table">
      <thead>
        <tr>
          <td (click)="showFilteringSortingMenu(DedicatedEmailMenuType.BUSINESS_KEY)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Business key</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(DedicatedEmailMenuType.BUSINESS_KEY) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="DedicatedEmailMenuType.BUSINESS_KEY"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(DedicatedEmailMenuType.SEND_TO)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Email</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(DedicatedEmailMenuType.SEND_TO) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="DedicatedEmailMenuType.SEND_TO"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(DedicatedEmailMenuType.ADT_CODE)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Team / ID</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(DedicatedEmailMenuType.ADT_CODE) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="DedicatedEmailMenuType.ADT_CODE"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>

          <td (click)="showFilteringSortingMenu(DedicatedEmailMenuType.ALWAYS_DIRECT_TO_TEAM)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Always direct to team</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(DedicatedEmailMenuType.ALWAYS_DIRECT_TO_TEAM) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="DedicatedEmailMenuType.ALWAYS_DIRECT_TO_TEAM"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>

          <td (click)="showFilteringSortingMenu(DedicatedEmailMenuType.TEAM_BEOFRE_PROFILE)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Team before profile</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(DedicatedEmailMenuType.TEAM_BEOFRE_PROFILE) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="DedicatedEmailMenuType.TEAM_BEOFRE_PROFILE"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(DedicatedEmailMenuType.ACTIVATE_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Activation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(DedicatedEmailMenuType.ACTIVATE_ON) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="DedicatedEmailMenuType.ACTIVATE_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(DedicatedEmailMenuType.DEACTIVATION_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Deactivation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(DedicatedEmailMenuType.DEACTIVATION_ON) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="DedicatedEmailMenuType.DEACTIVATION_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of masterListFiltered" (click)="edit(item)">
          <td>
            <div class="first-column-container">
              <div class="first-column-content">
                {{ item.businessKey }}
              </div>
            </div>
          </td>
          <td>
            {{ item.sendTo }}
          </td>
          <td>
            {{ teamLabel(item.adTeam) }}
          </td>
          <td class="align-center">
            <input type="checkbox" [checked]="item.alwaysDirectToTeams" disabled="true" />

          </td>
          <td class="align-center">
            <input type="checkbox" [checked]="item.teamBeforeProfile " disabled="true" />
          </td>
          <td>
            {{ item.activateOn | date: 'dd MMM yyyy' }}
          </td>
          <td>
            {{ item.deactivateOn | date: 'dd MMM yyyy' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-template #MasterListModal>
    <app-masterlist-dedicated-email-modal [masterListType]="masterListType" (refreshTable)="reload()"> </app-masterlist-dedicated-email-modal>
  </ng-template>
</div>
