import { CustomerProductRelationTypeEnum } from '../../../../enums/policy/customer-product-relation-type.enum';
import { SplitDateTypeEnum } from '../../../../enums/policy/split-date.enum';
import {Injectable} from '@angular/core';
import { CustomerRelationLabel, CustomerRelationLabelAdapter } from 'app/shared/models/customer-relation-label';

export class CustomerProductRelation {
  id: number;
  productId: number;
  customerProductRelationType: CustomerProductRelationTypeEnum;
  companyPolicyHolder: string;
  policyNo: string;
  bank: string;
  bin: string;
  splitDateType: SplitDateTypeEnum;
  validFrom: Date;
  validTo: Date;
  remarks: string;
  customerRelationLabel: CustomerRelationLabel;
  profileId: number;


public constructor(init?: Partial<CustomerProductRelation>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomerProductRelationAdapter {
  adapt(item: any): CustomerProductRelation {
    return new CustomerProductRelation({
      id: item.id,
      productId: item.productId,
      customerProductRelationType: item.customerProductRelationType,
      companyPolicyHolder: item.companyPolicyHolder,
      policyNo: item.policyNo ,
      bank: item.bank,
      bin: item.bin,
      splitDateType: item.splitDateType,
      validFrom: item.validFrom,
      validTo: item.validTo,
      remarks: item.remarks,
      customerRelationLabel: !!item.customerRelationLabel ?
      new CustomerRelationLabelAdapter().adapt(item.customerRelationLabel) : new CustomerRelationLabel(),
      profileId: item.profileId,
    });
  }
}
