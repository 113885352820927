<div class="common-content-container clearfix">
  <div class="common-content-container-title">Product Settings</div>
  <div class="product-settings-notification" *ngIf="getViolations().length">
    The following product(s) violates the validation rules due to same Name, Customer Id and/or Level:
    <div class="violating" *ngFor="let violating of uniqueViolations">
      <div class="dot"></div>
      <div class="product-info">
        Name: {{violating.name}}; Version: {{violating.version}}
        <span *ngIf="!!violating.customerProductIds.length">; Customer Product Ids: {{violating.customerProductIds.join(', ')}}</span>
        <span *ngIf="!!violating.insuranceLevels.length">; Insurance Levels: {{violating.insuranceLevels.join(', ')}}</span>
      </div>
    </div>
  </div>
  <div class="common-content-container-body">
    <app-product-settings-form [product]="product"></app-product-settings-form>
  </div>
  <button class="float-end primary-button common-content-container-save-btn" [disabled]="!isValid()"
          (click)="saveProduct()">
    Save changes
  </button>
</div>
<div class="common-delete-container justify-content-between">
  <div class="text my-auto">
    <div class="black-text">Delete customer product</div>
    <div class="gray-text">
      <p>Check if this product or any child products related to this product have been selected in a case in SECCA via policy lookup or manually. If this is the case the product must NOT be deleted. There is <strong> NO validation </strong> in OnePoint checking this for you. </p>
      <p>NOTE: To delete a <strong>mother product</strong> you have to delete all related <strong>child products</strong> first. Once a product is deleted, all data for this product is lost <strong>permanently</strong> incl. coverages & limits, policy lookup settings, guide link and terms (terms = mother products only). Please consider deactivating the product instead of deleting it.</p>
    </div>
  </div>
  <button class="primary-button delete-button my-auto" [class.button-disabled]="!deleteEnabled || !!product.groupProfileProductId" (click)="showDeleteModal()">
    Delete
  </button>
</div>
<app-confirm-modal #deleteConfirmModal (confirmed)="deleteProduct()" confirmBtnLabel="Yes" cancelBtnLabel="No" [messageTemplate]="deleteConfirmMsg"></app-confirm-modal>

<ng-template #deleteConfirmMsg>
  <p>You are about to delete a customer product and all product data for this product will be lost <strong>permanently</strong>.</p>
  <p>Have you checked if this customer product has been selected in a case in SECCA? There is <strong> NO validation </strong> in OnePoint checking this for you.</p>
</ng-template>
