import { Continent } from './continent';

export class CommunicationCost {
  continentCode: string;
  phoneAmount: number;
  smsAmount: number;
  medicalAmount: number;

  public constructor(init?: Partial<CommunicationCost>) {
    Object.assign(this, init);
  }
}
