import {ActionInput} from '@onepoint/shared/models/action-input';
import {Rule} from 'app/shared/models/rule';
import {RuleEngineService} from '@onepoint/core/services/rule-engine.service';
import {OnepointModalService, Size} from '@onepoint/core/services/onepoint.modal.service';
import {RuleMapping} from '@onepoint/shared/models/rule-mapping';
import {RuleEvent} from '@onepoint/shared/models/rule-event';
import {RuleAction} from '@onepoint/shared/models/rule-action';
import {Dictionary} from '@onepoint/shared/models/dictionary';
import {ProfileService} from 'app/core/services/profile.service';
import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CustomerProfile} from "@onepoint/shared/models/customer-profile";

@Component({
  selector: 'app-rule-engine-table',
  templateUrl: './rule-engine-table.component.html',
  styleUrls: ['./rule-engine-table.component.scss'],
})
export class RuleEngineTableComponent implements OnInit {
  @ViewChild('RuleMappingModal')
  private template: TemplateRef<any>;

  private _selectedProfile?: CustomerProfile;
  selectedRuleType: string;
  customerProfiles: CustomerProfile[];
  ruleActions: RuleAction[];
  ruleEvents: RuleEvent[];
  ruleMappings: RuleMapping[];
  actionInputs: ActionInput[];
  rules: Rule[];
  ruleTypes: string[];
  ruleTypesDictionary: Dictionary[];
  tableRows: TableRow[];
  hoveredTableRow: TableRow;
  hoveredRowIndex: number;
  selectedRuleMapping: RuleMapping;

  constructor(
    private profileService: ProfileService,
    private onepointModalService: OnepointModalService,
    private ruleEngineService: RuleEngineService
  ) {}

  ngOnInit() {
    this.initializeData();
  }

  initializeData() {
    this.profileService.getAllCustomerProfiles().subscribe(
      result => {
        this.customerProfiles = result;
        this.customerProfiles.sort((lhs, rhs) => (lhs.profileName.localeCompare(rhs.profileName)));
        let allCustomerProfile = new CustomerProfile();
        allCustomerProfile.profileName = 'ALL';
        this.customerProfiles.unshift(allCustomerProfile);
        this.selectedProfile = allCustomerProfile;
        this.calculateTable();
      },
      error => console.log(error)
    );
    this.ruleEngineService.getAllRuleEvents().subscribe(
      result => {
        this.ruleEvents = result;
        this.calculateTable();
      },
      error => console.log(error)
    );
    this.ruleEngineService.getAllRuleActions().subscribe(
      result => {
        this.ruleActions = result;
        this.calculateTable();
      },
      error => console.log(error)
    );
    this.ruleEngineService.getAllRules().subscribe(
      result => {
        this.rules = result;
        this.calculateTable();
      },
      error => console.log(error)
    );
    this.ruleEngineService.getAllRuleTypes().subscribe(
      result => {
        this.ruleTypes = result;
        this.ruleTypesDictionary = result.map(a => new Dictionary(a, a));
        this.ruleTypesDictionary.unshift(new Dictionary('', 'ALL'));
        this.selectedRuleType = '';
        this.calculateTable();
      },
      error => console.log(error)
    );
    this.reloadMappingsAndActionInputs();
  }

  reloadMappingsAndActionInputs() {
    this.ruleMappings = [];
    this.actionInputs = [];
    this.ruleEngineService.getAllRuleMappings().subscribe(
      result => {
        this.ruleMappings = result;
        this.calculateTable();
      },
      error => console.log(error)
    );
    this.ruleEngineService.getAllRuleActionInputs().subscribe(
      result => {
        this.actionInputs = result;
      },
      error => console.log(error)
    );
  }

  private shouldIncludeRow(mapping: RuleMapping, selectedProfile: CustomerProfile, selectedRuleType: string): boolean {
    const includeAllProfiles = mapping.cpId === null && selectedProfile !== undefined && selectedProfile.profileName === 'ALL';
    const includeThisProfile = selectedProfile !== undefined && mapping.cpId === selectedProfile.profileId;
    const isMatchingRuleType = selectedRuleType === '' || selectedRuleType === mapping.type;
    return (includeAllProfiles || includeThisProfile) && isMatchingRuleType;
  }

  calculateTable() {
    if (
      this.ruleEvents != null &&
      this.rules != null &&
      this.ruleActions != null &&
      this.ruleMappings != null &&
      this.customerProfiles != null
    ) {
      this.tableRows = [];
      this.ruleMappings.forEach(ruleMapping => {
        if (this.shouldIncludeRow(ruleMapping, this.selectedProfile, this.selectedRuleType)) {
          let tableRow = new TableRow();
          tableRow.ruleType = ruleMapping.type;
          tableRow.ruleActionId = ruleMapping.ruleActionId;
          tableRow.ruleActionName = this.ruleActions.find(a => a.id === tableRow.ruleActionId).name;
          tableRow.ruleMappingId = ruleMapping.id;
          tableRow.active = ruleMapping.active;
          tableRow.ruleEventId = ruleMapping.ruleEventId;
          tableRow.ruleEventName = this.ruleEvents.find(a => a.id === tableRow.ruleEventId).name;
          tableRow.ruleId = ruleMapping.ruleId;
          tableRow.ruleName = this.rules.find(a => a.id === tableRow.ruleId).name;
          tableRow.customerProfileId = ruleMapping.cpId;
          tableRow.ruleMappingName = ruleMapping.name;
          this.tableRows.push(tableRow);
        }
      });
    }
  }

  getAllOverridesRuleMappings(tableRow: TableRow): string {
    let result: string = null;
    if (tableRow.customerProfileId === null) {
      let matchedRuleMappings = this.ruleMappings.filter(
        a =>
          a.ruleEventId === tableRow.ruleEventId &&
          a.cpId !== null &&
          a.ruleActionId === tableRow.ruleActionId &&
          a.ruleId === tableRow.ruleId &&
          this.customerProfiles.find(customerProfile => +customerProfile.profileId === a.cpId) != null
      );
      if (matchedRuleMappings.length > 0) {
        result = 'Event-rule-action combination overwritten by Customer Profiles:<br>';
        matchedRuleMappings.forEach(matchedRuleMapping => {
          result += '- ' + this.customerProfiles.find(a => +a.profileId === matchedRuleMapping.cpId).profileName + '<br>';
        });
      }
    } else {
      if (
        this.ruleMappings.filter(
          a =>
            a.ruleEventId === tableRow.ruleEventId &&
            a.cpId === null &&
            a.ruleActionId === tableRow.ruleActionId &&
            a.ruleId === tableRow.ruleId
        ).length > 0
      ) {
        result = 'Overrides "All"';
      }
    }
    return result;
  }

  showTooltip(tableRow: TableRow, index: number) {
    this.hoveredTableRow = tableRow;
    this.hoveredRowIndex = index;
  }

  hideTooltip() {
    this.hoveredTableRow = null;
  }

  public get selectedProfile(): CustomerProfile | undefined {
    return this._selectedProfile;
  }

  public set selectedProfile(newCustomerProfile: CustomerProfile | undefined) {
    this._selectedProfile = newCustomerProfile;
    this.calculateTable();
  }

  ruleTypeChanges(event: any) {
    this.selectedRuleType = event;
    this.calculateTable();
  }

  addNewRuleMapping() {
    this.selectedRuleMapping = new RuleMapping();
    this.selectedRuleMapping.cpId = this.selectedProfile.profileId === undefined ? null : +this.selectedProfile.profileId;
    this.onepointModalService.openWithCustomWindowClass(this.template, Size.LARGE, 'rule-mapping-modal-container');
  }

  editRuleMapping(tableRow: TableRow) {
    this.ruleEngineService.getRuleMapping(tableRow.ruleMappingId).subscribe(
      result => {
        this.selectedRuleMapping = result;
        this.onepointModalService.openWithCustomWindowClass(this.template, Size.LARGE, 'rule-mapping-modal-container');
      }
    );
  }

  get tooltipBottom() {
    const triangleHeight = 32;
    const tablePadding = 20;
    const rowHeight = 46;
    let number = tablePadding + (this.tableRows.length - this.hoveredRowIndex) * rowHeight + triangleHeight;
    return { bottom: number + 'px' };
  }
}

class TableRow {
  ruleMappingId: number;
  ruleMappingName: string;
  ruleEventId: number;
  ruleEventName: string;
  ruleId: number;
  ruleName: string;
  ruleActionId: number;
  ruleActionName: string;
  ruleType: string;
  active: boolean;
  customerProfileId: number;
}
