import { CustomerProfile, CustomerProfileAdapter } from './../../shared/models/customer-profile';
import { TeamRelation, TeamRelationAdapter } from './../../shared/models/team-relation';
import { Profile } from './../../shared/models/profile';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CustomerListView } from './../../shared/models/customer-list-view';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { ProfileAdapter } from 'app/shared/models/profile';
import { map } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import { AppliedProfile, AppliedProfileAdapter } from '../../shared/models/applied-profile';
import {ProfileApplication, ProfileApplicationAdapter} from '../../shared/models/profile-application';
import {CaseProcessProfile, CaseProcessProfileAdapter} from '@onepoint/shared/models/case-process-profile';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends BaseService {
  constructor(
    private profileAdapter: ProfileAdapter,
    private teamRelationAdapter: TeamRelationAdapter,
    private appliedProfileAdaptor: AppliedProfileAdapter,
    private http: HttpClient,
    private settingsService: SettingsService,
    private customerProfileAdapter: CustomerProfileAdapter,
    private profileApplicationAdapter: ProfileApplicationAdapter,
    private caseProcessProfileAdapter: CaseProcessProfileAdapter
  ) {
    super(settingsService);
  }

  public createTeamRelation(teamRelation: TeamRelation): Observable<TeamRelation> {
    return this.http.post<TeamRelation>(this.baseURL + 'profile/team-relation', teamRelation, {
      headers: this.jsonHeaders,
    });
  }

  public getProfileTeamRelations(id: string, businessArea: string): Observable<TeamRelation[]> {
    return this.http
      .get(this.baseURL + 'profile/team-relation/' + businessArea + '/' + id)
      .pipe(map((data: any[]) => data.map(item => this.teamRelationAdapter.adapt(item))));
  }

  public updateTeamRelations(teamRelations: TeamRelation[]): Observable<TeamRelation[]> {
    return this.http.post<TeamRelation[]>(this.baseURL + 'profile/team-relation/update', teamRelations, {
      headers: this.jsonHeaders,
    });
  }

  public getProfileListView(id: string): Observable<CustomerListView> {
    return this.http.get(this.baseURL + 'profile/' + id).pipe(map(item => this.profileAdapter.adaptToCommonCustomerListView(item)));
  }

  public getProfile(id: string): Observable<Profile> {
    return this.http.get(this.baseURL + 'profile/' + id).pipe(map(item => this.profileAdapter.adapt(item)));
  }

  public getProfileNamesByCode(code: string): Observable<string[]> {
    return this.http.get(this.baseURL + 'profile/names?code=' + code).pipe(map(items =>  items as string[]));
  }

  public getProfileNamesByGroupMemberIdForMapping(groupProfileId: string, groupMemberIdForMapping: string): Observable<string[]> {
    return this.http.get(this.baseURL + 'profile/names-by-group-member/' + groupProfileId + '?groupMemberIdForMapping=' + groupMemberIdForMapping).pipe(map(items =>  items as string[]));
  }

  public getCustomerProfiles(): Observable<CustomerProfile[]> {
    return this.http
      .get(this.baseURL + 'profile/customer-travel-profiles')
      .pipe(map((data: any[]) => data.map(item => this.customerProfileAdapter.adapt(item))));
  }

  public getAllCustomerProfiles(): Observable<CustomerProfile[]> {
    return this.http
      .get(this.baseURL + 'profile/all-active-customer-travel-profiles')
      .pipe(map((data: any[]) => data.map(item => this.customerProfileAdapter.adapt(item))));
  }

  public getProfileActiveContracts(id: string): Observable<AppliedProfile[]> {
    return this.http
      .get(this.baseURL + 'profile/' + id + '/contracts', { headers: this.jsonHeaders })
      .pipe(map((data: any[]) => data.map(item => this.appliedProfileAdaptor.adapt(item))));
  }

  public addService(profile: Profile, brandId: string): Observable<Profile> {
    return this.http.post<Profile>(this.baseURL + 'profile/on-brand/' + brandId, profile, {
      headers: this.jsonHeaders,
    });
  }

  public updateProfile(profile: Profile): Observable<Profile> {
    return this.http.put<Profile>(this.baseURL + 'profile/update', profile, {
      headers: this.jsonHeaders,
    });
  }

  public deleteProfile(id: string): Observable<any> {
    return this.http.delete(this.baseURL + 'profile/delete/' + id, {
      headers: this.jsonHeaders,
      responseType: 'text',
    });
  }

  public getApplicationsByProfile(id: string): Observable<ProfileApplication[]> {
    return this.http.
      get(this.baseURL + 'profile/' + id + '/applications', {headers : this.jsonHeaders})
      .pipe(map((data: any[]) => data.map(item => this.profileApplicationAdapter.adapt(item))));
  }

  public updateApplication(profileApplications: ProfileApplication[]): Observable<ProfileApplication[]> {
    return this.http.put<ProfileApplication[]>(this.baseURL + 'profile/applications', profileApplications, {
      headers: this.jsonHeaders,
    });
  }

  public getCaseProcessProfiles(id: number): Observable<CaseProcessProfile[]> {
    return this.http.
    get(this.baseURL + 'profile/' + id + '/case-processes', {headers : this.jsonHeaders})
      .pipe(map((data: any[]) => data.map(item => this.caseProcessProfileAdapter.adapt(item))));
  }

  public updateCaseProcessProfiles(profileId: number, caseProcessProfiles: CaseProcessProfile[]): Observable<CaseProcessProfile[]> {
    return this.http.put<CaseProcessProfile[]>(this.baseURL + 'profile/case-processes/' + profileId, caseProcessProfiles, {
      headers: this.jsonHeaders,
    });
  }

  public isGroupProfile(id: string): Observable<boolean> {
    return this.http.get<boolean>(this.baseURL + 'profile/' + id + '/is-group-profile');
  }
}
