import { MasterListServiceTypeModel } from './masterlist-service-type-model';

export class MasterListServiceItemModel {
  id: string;
  serviceItemCode: string;
  serviceItemName: string;
  serviceType: string;
  description: string;
  vat: boolean;
  uiSortIndex: number;
  activateOn: Date;
  deactivateOn: Date;
  active: boolean;
  serviceItemType: string;
  automatedApprovalLimitCurrency: string;
  automatedApprovalLimitValue: number;
  applications: string[];
  serviceTypes: MasterListServiceTypeModel[];
  recovery: boolean;

  public constructor(init?: Partial<MasterListServiceItemModel>) {
    Object.assign(this, init);
  }
}
