<div class="product-overview">
  <div class="row">
    <div class="col-3">
      <p class="header">{{product.name}} {{product.sosInsuranceLevel ? '(' + product.sosInsuranceLevel + ')' : ''}}</p>
      <div class="row">
        <div class="col-6">
          <div class="field-name">Insurance Type</div>
          <div class="field-value">{{ insuranceTypes.get(product.insuranceType) }}</div>
        </div>
        <div class="col-6">
          <div class="field-name">Version</div>
          <div class="field-value">{{product.version}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="field-name">SOS ID</div>
          <div class="field-value">{{product.sosProductId}}</div>
        </div>
        <div class="col-6">
          <div class="field-name">Customer IDs</div>
          <div class="field-value">{{product.customerProductIds.join(', ')}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="field-name">Business Area</div>
          <div class="field-value">{{product.businessArea | titlecase}}</div>
        </div>
      </div>
    </div>

    <div class="col-3">
      <div class="row">
        <div class="col-6">
          <div class="field-name">Person Group</div>
          <div class="field-value">{{ personGroups.get(product.personGroup) }}</div>
        </div>
        <div class="col-6">
          <div class="field-name">Geographical Coverage Area</div>
          <div class="field-value" *ngIf="geographicalCoverageAreas != null">
            <div class="market-list-item" *ngFor="let geographicalCoverageArea of product.coverageGeographicAreaIds">
              {{ geographicalCoverageAreas.get(geographicalCoverageArea) }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="field-name">Coverage Period Type</div>
          <div class="field-value">{{ coveragePeriodTypes.get(product.coveragePeriodType) }}</div>
        </div>
        <div class="col-6">
          <div class="field-name">Coverage Period</div>
          <div class="field-value">{{product.coveragePeriodValue}} {{ product.coveragePeriodUnit | titlecase }}</div>
        </div>
      </div>
      <div class="row" *ngIf="purposesOfTravel">
        <div class="col-6">
          <div class="field-name">Primary purpose of Travel</div>
          <div class="field-value">{{ purposesOfTravel[product.primaryPurposeOfTravelCode] }}</div>
        </div>
      </div>
    </div>

    <div class="col-3 d-flex justify-content-center">
      <div class="fields">
        <div class="field-name">Valid From</div>
        <div class="field-value">{{product.validFrom | date: 'dd. MMM yyyy'}}</div>

        <div class="field-name" [class.dim]="!product.validTo">Valid To</div>
        <div class="field-value">{{product.validTo | date: 'dd. MMM yyyy'}}</div>
        <div class="field-value dim" *ngIf="product && !product.validTo">-</div>

        <div class="field-name" [class.dim]="!product.deactivationDate">Deactivation Date</div>
        <div class="field-value">{{product.deactivationDate | date: 'dd. MMM yyyy'}}</div>
        <div class="field-value dim" *ngIf="product && !product.deactivationDate">-</div>
      </div>
    </div>

    <div class="col-3">
      <app-kam-info-view [kam]="kam"></app-kam-info-view>
    </div>
  </div>
</div>
