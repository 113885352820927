<div class="menu">
  <ul class="nav justify-content-center">
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{ active: shownSection === 'overview' }" (click)="shownSection = 'overview'">
        <div>Overview</div>
      </div>
    </li>
    <li class="nav-item" *permission='PermissionEnum.SUPPLIER_UPDATE'>
      <div class="nav-link" [ngClass]="{ active: shownSection === 'settings' }" (click)="shownSection = 'settings'">
        <div>Settings</div>
      </div>
    </li>
  </ul>
</div>
<app-supplier-overview *ngIf="shownSection === 'overview'" [allErpCreditors]="allErpCreditors" [(supplierProfile)]="supplierProfile"></app-supplier-overview>
<app-supplier-settings
  *ngIf="shownSection === 'settings'"
  [allErpCreditors]="allErpCreditors"
  [supplierProfileOriginal]="supplierProfile"
  (supplierProfileChanged)="onSupplierProfileUpdate($event)"
></app-supplier-settings>
