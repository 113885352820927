import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SosServices } from 'app/shared/models/sos-services';
import { SosServicesService } from 'app/core/services/sos-services.service';
import { SosServicesValidationService } from 'app/core/services/sos-services-validation.service';
import { RoutesName } from 'app/app-routes-name';
import { OnepointModalService, Size } from '../../../../core/services/onepoint.modal.service';

@Component({
  selector: 'app-new-sos-services',
  templateUrl: './new-sos-services.component.html',
  styleUrls: ['./new-sos-services.component.scss'],
})
export class NewSosServicesComponent implements OnInit {
  @ViewChild('content', { static: true }) template: TemplateRef<any>;
  sosServices: SosServices;
  isValid = false;

  constructor(
    private sosServicesService: SosServicesService,
    private sosServicesValidationService: SosServicesValidationService,
    private router: Router,
    private modalService: OnepointModalService
  ) {
    this.sosServices = new SosServices();
  }

  ngOnInit() {
    this.clear();
  }

  clear() {
    this.sosServices = new SosServices({
      name: null,
      sosServicesCategory: null,
      validStart: null,
      validEnd: null,
      feeType: null,
      feePriority: null,
      commitmentType: [],
      medicalServiceOptions: null,
      caseType: [],
      causesLevel1: [],
      sosServicesType: [],
      includedInStandard: true,
      multipleDateRange: false,
      ignoreServiceItems: false,
      ignoreAsSuggested: false,
      targetPrice: { amount: 0, currency: 'DKK', precision: 2 },
      targetPriceYear: '2020',
      sosServicesItem: [],
      refundSosServicesItem: [],
      internalNote: '',
      description: '',
      vatErpClient: [],
      extension: new SosServices(),
      extensionType: 'NONE',
    });
  }

  public open() {
    this.clear();
    this.modalService.openWithCustomWindowClass(this.template, Size.LARGE, 'modal-container');
  }

  close() {
    this.modalService.dismissAll();
  }

  handleChange() {
    this.isValid =
      this.sosServicesValidationService.isValid(this.sosServices) &&
      this.sosServicesValidationService.isSosServiceIdUnique(this.sosServices, SosServicesService.allSosServices);
  }

  isEmpty(obj) {
    if (obj && obj instanceof Date) {
      return false;
    }
    if (typeof obj == 'number') {
      return false;
    }
    return !obj || !Object.values(obj).some(x => x !== void 0);
  }

  addNewSosServices() {
    this.sosServicesService.save(this.sosServices).subscribe(
      result => {
        this.sosServices = result;
        this.close();
        this.router.navigate([RoutesName.sosServices + '/', this.sosServices.id]);
      },
      error => {
        console.error(error);
      }
    );
  }
}
