import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'PolicyLookupMethodPipe'
})
export class PolicyLookupMethodPipePipe implements PipeTransform {
  transform(value: string): string {
    if (value === undefined) {
      return '';
    }
    switch (value) {
      case 'REGISTER_SERVER':
        return 'Register Server';
        break;
      case 'OTHER_INTEGRATION':
        return 'Other Integration';
        break;
      case 'NO_INTEGRATION':
        return 'No Integration';
        break;
      default:
        return value;
        break;
    }
  }
}
