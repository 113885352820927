export enum CustomerRelationLabelStatusEnum {
    ACTIVE = 'ACTIVE',
    DEACTIVATED = 'DEACTIVATED'
}

export enum CustomerRelationLabelStatusForSearchEnum {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED'
}
