<div class="menu">
  <ul class="nav justify-content-center">
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'overview'}" (click)="shownSection = 'overview'">
        <div>Overview</div>
      </div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'settings'}" (click)="shownSection = 'settings'">
        <div>Settings</div>
      </div>
    </li>
    <li class="nav-item">
      <div *permission="PermissionEnum.CUSTOMER_PRICE_AGREEMENT_READ" class="nav-link" [ngClass]="{'active': shownSection === 'contracts'}" (click)="shownSection = 'contracts'">
        <div>Contracts</div>
      </div>
    </li>
    <li class="nav-item">
      <div *permission="PermissionEnum.CUSTOMER_PRICE_AGREEMENT_READ"  class="nav-link" [ngClass]="{'active': shownSection === 'debtors'}"  (click)="shownSection = 'debtors'">
        <div>Debtors</div>
      </div>
    </li>
  </ul>
</div>
<app-common-customer-overview *ngIf="shownSection === 'overview'" [commonCustomer]="commonCustomer" [brands]="brands"
  (refreshBrands)="refreshBrandsEvent($event)"></app-common-customer-overview>
<app-common-customer-settings *ngIf="shownSection === 'settings'" [commonCustomerOriginal]="commonCustomer">
</app-common-customer-settings>
<app-common-customer-contracts *ngIf="shownSection === 'contracts'" [commonCustomer]="commonCustomer">
</app-common-customer-contracts>
<app-common-customer-debtors *ngIf="shownSection === 'debtors'" [commonCustomerId]="commonCustomer.id">
</app-common-customer-debtors>
