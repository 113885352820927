<div class="service-item-modal-container">
  <div class="closing-x" (click)="closeModal()">
    <img src="/assets/icons/Close.svg">
  </div>
  <div class="header">
    <span *ngIf="isNew">Create new Team</span>
    <span *ngIf="!isNew">Edit Team</span>
  </div>
  <div class="row body-container" *ngIf="masterListItem">
    <div class="col-3">
      <div class="input-name">
        Activate on
        <span *ngIf="!isReadonly" class="required">*</span>
      </div>
      <app-input-calendar [withImage]="true" [selectMode]="'single'" [disabled]="isReadonly"
                          [(dateTimeRange)]="masterListItem.activateOn" [maxDate]="masterListItem.deactivateOn"
                          (saveModel)="activateOnChanged(masterListItem)">
      </app-input-calendar>
      <div class="input-name">
        Deactivate after
      </div>
      <app-input-calendar [withImage]="true" [selectMode]="'single'" [disabled]="isReadonly"
                          [(dateTimeRange)]="masterListItem.deactivateOn" [minDate]="masterListItem.activateOn"
                          (saveModel)="deactivateOnChanged(masterListItem)">
      </app-input-calendar>
      <div class="label" style="font-size: 14px;
      color: #787878;" [ngClass]="'input-name'">Based on case creation time</div>

    </div>
    <div class="col-9">
      <div class="row">
        <div class="col-6">
          <div>
            <app-input [name]="'Business key'" [(value)]="masterListItem.adTCode" (valueChange)="modelChanged()" [disabled]="isReadonly || masterListItem.id" [required]="!isReadonly"></app-input>
          </div>
          <div class="padding-top">
            <app-input [name]="'Team name'" [(value)]="masterListItem.teamName" (valueChange)="modelChanged()" [disabled]="isReadonly" [required]="!isReadonly"></app-input>
          </div>
          <div class="padding-top">
            <app-input [name]="'Team ID'" [(value)]="masterListItem.teamId" (valueChange)="modelChanged()" [disabled]="isReadonly" [required]="!isReadonly"></app-input>
          </div>
          <div class="padding-top">
            <app-input [name]="'Alt. team name'" [(value)]="masterListItem.teamNameAlt" (valueChange)="modelChanged()" [disabled]="isReadonly" [required]="!isReadonly"></app-input>
          </div>
          <div class="padding-top">
            <app-input [name]="'Sort in UI'" [(value)]="masterListItem.uiSortIndex" (valueChange)="modelChanged()" [disabled]="isReadonly"></app-input>
          </div>
        </div>
        <div class="col-6">
          <div>
            <app-drop-down [name]="'Team type'" [(selectedItemId)]="masterListItem.teamType"
              [items]="teamTypes" [required]="!isReadonly" [disabled]="isReadonly"
              (selectedItemIdChange)="selectedTeamTypeValueChanged($event)">
            </app-drop-down>
          </div>
          <div class="padding-top">
            <app-input [name]="'Mindkey ID'" [(value)]="masterListItem.subTeamIds" (valueChange)="modelChanged()" [disabled]="isReadonly"></app-input>
          </div>
          <div class="padding-top">
            <div>
              <app-drop-down [name]="'Department'" [(selectedItemId)]="masterListItem.adDepartmentsId"
                [items]="addepartments" [required]="!isReadonly" [disabled]="isReadonly"
                (selectedItemIdChange)="selectedDepartmentValueChanged($event)">
              </app-drop-down>
            </div>
          </div>
          <div class="padding-top">
            <app-input [name]="'Default language'" [(value)]="masterListItem.defaultLanguage" (valueChange)="modelChanged()" [disabled]="isReadonly"></app-input>
          </div>
          <div class="padding-top">
            <app-drop-down [name]="'Default channel type'" [(selectedItemId)]="masterListItem.defaultChannel"
              [items]="channelTypes" [required]="!isReadonly" [disabled]="isReadonly"
              (selectedItemIdChange)="selectedChannelTypeValueChanged($event)">
            </app-drop-down>
          </div>
          <div class="padding-top">
            <app-input [name]="'Cisco ID'" [(value)]="masterListItem.ciscoTeamId" (valueChange)="modelChanged()" [disabled]="isReadonly"></app-input>
          </div>
        </div>
      </div>
    </div>
    <div class="row body-container" *ngIf="error">
      <div class="col-12 error">
        {{error}}
      </div>
    </div>
  </div>
  <div class="masterlist-modal-footer d-flex justify-content-between">
    <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE" class="">
      <button class="primary-button save-button" [class.button-disabled]="!isSaveButtonEnabled" (click)="save()">Save</button>
    </div>
    <app-last-changed [masterListItem]="masterListItem" class="last-changed float-end"> </app-last-changed>
  </div>
</div>
