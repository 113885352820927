import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SupplierProfileService } from 'app/core/services/supplier-profile.service';
import { AutoUnsubscribe } from 'app/shared/decorators/auto-unsubscribe';
import { SupplierProfile } from 'app/shared/models/supplier-profile';
import { Subscription } from 'rxjs';
import {SimpleErpCreditor} from "@onepoint/shared/models/simple-erp-creditor";
import * as moment from 'moment-timezone';
import { EditSupplierProfileComponent } from '../suppliers-all/edit-supplier-profile/edit-supplier-profile.component';

@Component({
  selector: 'app-supplier-settings',
  templateUrl: './supplier-settings.component.html',
  styleUrls: ['./supplier-settings.component.scss'],
})
@AutoUnsubscribe
export class SupplierSettingsComponent implements OnInit {
  supplierProfile: SupplierProfile;
  canSave = false;
  @Input() allErpCreditors: SimpleErpCreditor[];
  @Input() supplierProfileOriginal: SupplierProfile;
  @Output() supplierProfileChanged = new EventEmitter();
  allSupplierProfiles: SupplierProfile[];
  subscription: Subscription;

  constructor(private supplierProfileService: SupplierProfileService) {}

  ngOnInit(): void {
    this.canSave = false;
    this.supplierProfile = new SupplierProfile(this.supplierProfileOriginal);
    this.subscription = this.supplierProfileService.getAllSupplierProfiles().subscribe(result => (this.allSupplierProfiles = result));
  }

  handleChange(editSupplierComponent: EditSupplierProfileComponent) {
    const isValid =
      this.supplierProfile.supplierProfileCode != null &&
      this.supplierProfile.supplierProfileCode.startsWith('SP') &&
      this.supplierProfile.supplierProfileName != null &&
      this.supplierProfile.startDate != null &&
      this.supplierProfile.primaryErpCreditor != null &&
      (this.supplierProfile.endDate == null || this.supplierProfile.endDate > this.supplierProfile.startDate) &&
      (this.allSupplierProfiles.findIndex(s => s.supplierProfileCode === this.supplierProfile.supplierProfileCode && s.id !== this.supplierProfile.id) < 0) &&
      editSupplierComponent.validate();
    this.canSave = JSON.stringify(this.supplierProfile) !== JSON.stringify(this.supplierProfileOriginal) && isValid;
  }

  onEdit() {
    this.supplierProfileService.update(this.supplierProfile).subscribe(result => {
      this.supplierProfileOriginal = result;
      this.supplierProfileChanged.emit(this.supplierProfileOriginal);
      this.ngOnInit();
    });
  }
}
