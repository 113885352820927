import {Injectable} from '@angular/core';
import {Adapter} from './../../interfaces/adapter';

export class ActivityInput {
  id: number;
  revision: number;
  length: number;
  mandatory: boolean;
  serviceOrderCreationType: string;
  stakeholderType: string;
  templateName: string;
  serviceType: string;
  commitmentType: string;
  handlingAreaType: string;
  handlingAreaStatus: string;
  sanctionCheckType: string;
  validationType: string;
  validationNegation: boolean;
  purposeOfTravel: string;
  countries: string[] = [];
  caseTypes: string[] = [];
  causeLevels: string[] = [];
  commitmentTypes: string[] = [];
  specificEmailAddresses: string[] = [];
  emailAddressDomains: string[] = [];
  taskAssignee: string;
  taskAssigneeType: string;
  taskFixedTeam: string;
  taskTitle: string;
  taskBodyText: string;
  taskDefaultChannel: string;
  purposesOfTravel: string[] = [];
  sanctionActionType: string;

  public constructor(init?: Partial<ActivityInput>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ActivityInputAdapter implements Adapter<ActivityInput> {
  adapt(item: any): ActivityInput {
    return new ActivityInput({
      id: item.id,
      revision: item.revision,
      length: item.length,
      mandatory: item.mandatory,
      serviceOrderCreationType: item.serviceOrderCreationType,
      stakeholderType: item.stakeholderType,
      templateName: item.templateName,
      serviceType: item.serviceType,
      commitmentType: item.commitmentType,
      purposeOfTravel: item.purposeOfTravel,
      handlingAreaType: item.handlingAreaType,
      handlingAreaStatus: item.handlingAreaStatus,
      sanctionCheckType: item.sanctionCheckType,
      validationType: item.validationType,
      countries: item.countries,
      caseTypes: item.caseTypes,
      causeLevels: item.causeLevels,
      commitmentTypes: item.commitmentTypes,
      validationNegation: item.validationNegation,
      specificEmailAddresses: item.specificEmailAddresses,
      emailAddressDomains: item.emailAddressDomains,
      taskAssigneeType: item.taskAssigneeType,
      taskFixedTeam: item.taskFixedTeam,
      taskTitle: item.taskTitle,
      taskBodyText: item.taskBodyText,
      taskDefaultChannel: item.taskDefaultChannel,
      purposesOfTravel: item.purposesOfTravel,
      sanctionActionType: item.sanctionActionType
    });
  }
}
