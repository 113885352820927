import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {SettingsService} from './settings.service';
import {ProfileCountryList, ProfileCountryListAdapter} from 'app/shared/models/profile-country-list';
import {ProfileCountryListDetail, ProfileCountryListDetailAdapter} from 'app/shared/models/profile-country-list-detail';
import {ProfileCountryListDeleteResultAdapter, ProfileCountryListDeleteResult} from 'app/shared/models/profile-country-list-delete-result';

@Injectable({
  providedIn: 'root'
})
export class ProfileCountryListsService extends BaseService {
  constructor(
    private countryListAdapter: ProfileCountryListAdapter,
    private countryListDetailAdapter: ProfileCountryListDetailAdapter,
    private profileCountryListDeleteResultAdapter: ProfileCountryListDeleteResultAdapter,
    private http: HttpClient,
    private settingsService: SettingsService
  ) {
    super(settingsService);
  }

  public getAll(profileId: number): Observable<ProfileCountryList[]> {
    return this.http
      .get(this.baseURL + 'profile/country-lists/' + profileId)
      .pipe(
        map((data: any[]) =>
          data.map(item =>
            this.countryListAdapter.adapt(item)
          )
        )
      );
  }

  public get(countryListId: number): Observable<ProfileCountryListDetail> {
    return this.http
      .get(this.baseURL + 'profile/country-list/' + countryListId)
      .pipe(
        map(data =>
          this.countryListDetailAdapter.adapt(data)
        )
      );
  }

  public delete(countryListId: number): Observable<ProfileCountryListDeleteResult> {
    return this.http
      .delete(this.baseURL + 'profile/country-list/' + countryListId)
      .pipe(
        map(data => this.profileCountryListDeleteResultAdapter.adapt(data))
      );
  }

  public save(item: ProfileCountryListDetail): Observable<ProfileCountryListDetail> {
    return this.sendPostRequest(this.baseURL + 'profile/country-list', item);
  }


  public update(item: ProfileCountryListDetail): Observable<ProfileCountryListDetail> {
    return this.sendPostRequest(this.baseURL + 'profile/country-list/update', item);
  }

  private sendPostRequest(url: string, item: ProfileCountryListDetail): Observable<ProfileCountryListDetail> {
    return this.http
      .post<ProfileCountryListDetail>(url, item, {
        headers: this.jsonHeaders
      })
      .pipe(
        map(data =>
          this.countryListDetailAdapter.adapt(data)
        )
      );
  }
}
