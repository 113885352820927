import * as moment from 'moment';
export class MasterListReductionModel {
  id: string;
  reductionCode: string;
  reductionName: string;
  usedInRefund: boolean;
  usedInSupplierInvoice: boolean;
  partnerOnly: boolean;
  shareOfCostControl: boolean;
  description: string;
  uiSortIndex: number;
  activateOn: Date;
  deactivateOn: Date;
  active: boolean;
  modifiedOn: Date;
  modifiedBy: string;

  public constructor(init?: Partial<MasterListReductionModel>) {
    Object.assign(this, init);
  }
}
