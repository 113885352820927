import { Component, Input } from '@angular/core';
import { SupplierProfile } from 'app/shared/models/supplier-profile';
import {SimpleErpCreditor} from "@onepoint/shared/models/simple-erp-creditor";

@Component({
  selector: 'app-supplier-overview',
  templateUrl: './supplier-overview.component.html',
  styleUrls: ['./supplier-overview.component.scss'],
})
export class SupplierOverviewComponent {
  @Input() supplierProfile: SupplierProfile;
  @Input() allErpCreditors: SimpleErpCreditor[];
}
