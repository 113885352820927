<div class="page-container">
  <div class="page-header">
    <div class="my-auto counts-container">
      <div class="count-container">
        <span>Approval limits</span> <span>{{ masterListFiltered?.length }}</span>
      </div>
    </div>
    <div class="limit-type-container my-auto">
      <app-drop-down [(selectedItemId)]="selectedLimitType" [items]="limitTypes" (selectedItemIdChange)="selectedLimitTypeChanged($event)">
      </app-drop-down>
    </div>
    <div class="status-container my-auto">
      <app-drop-down [(selectedItemId)]="selectedStatus" [items]="statusTypes" (selectedItemIdChange)="selectedStatusTypeChanged($event)">
      </app-drop-down>
    </div>
  </div>
  <div class="primary-table page-content-container">
    <table class="master-lists-table">
      <thead>
        <tr>
          <td (click)="showFilteringSortingMenu(ApprovalPermissionLimitsMenuType.PERMISSION)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Permission</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[ApprovalPermissionLimitsMenuType.PERMISSION] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ApprovalPermissionLimitsMenuType.PERMISSION"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ApprovalPermissionLimitsMenuType.LIMIT)" class="filter-column unsortable-column">
            <div class="sortable money-pos">
              <span>Limit</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[ApprovalPermissionLimitsMenuType.LIMIT] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ApprovalPermissionLimitsMenuType.LIMIT"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ApprovalPermissionLimitsMenuType.DESCRIPTION)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Description</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[ApprovalPermissionLimitsMenuType.DESCRIPTION] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ApprovalPermissionLimitsMenuType.DESCRIPTION"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ApprovalPermissionLimitsMenuType.ACTIVATE_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Activation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[ApprovalPermissionLimitsMenuType.ACTIVATE_ON] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ApprovalPermissionLimitsMenuType.ACTIVATE_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ApprovalPermissionLimitsMenuType.DEACTIVATION_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Deactivation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[ApprovalPermissionLimitsMenuType.DEACTIVATION_ON] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ApprovalPermissionLimitsMenuType.DEACTIVATION_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of masterListFiltered" (click)="edit(item)">
        <td>
          {{ item.permission }}
        </td>
        <td class='money-pos'>
          {{item.limitInDKK.toLocaleString()}} DKK
        </td>
        <td class='description'>
          {{ item.description }}
        </td>
        <td>
          {{ item.activateOn | date: 'dd MMM yyyy' }}
        </td>
        <td>
          {{ item.deactivateOn | date: 'dd MMM yyyy' }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <ng-template #MasterListModal>
    <app-approval-limit-modal [masterListItem]="masterListType" (refreshTable)="reload()"> </app-approval-limit-modal>
  </ng-template>
</div>
