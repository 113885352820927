import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Contract} from '@onepoint/shared/models/contract';
import {AppliedProfile} from '@onepoint/shared/models/applied-profile';
import {Dictionary} from '@onepoint/shared/models/dictionary';
import {DebtorService} from '@onepoint/core/services/debtor.service';
import {AppliedProfileService} from '@onepoint/core/services/applied-profile.service';
import {forkJoin, Observable} from 'rxjs';
import {CustomerListView} from '@onepoint/shared/models/customer-list-view';
import {OnepointModalService, Size} from '@onepoint/core/services/onepoint.modal.service';
import * as moment from 'moment-timezone';
import { Debtor } from '@onepoint/shared/models/debtor';

@Component({
  selector: 'app-contract-profiles',
  templateUrl: './contract-profiles.component.html',
  styleUrls: ['./contract-profiles.component.scss']
})
export class ContractProfilesComponent implements OnInit {
  @ViewChild('modal') private modal: TemplateRef<any>;
  @Input() contract: Contract = new Contract();
  @Input() commonCustomer = new CustomerListView();
  @Input() set priceAgreements(value: Dictionary[]) {
    this.priceAgreementOptions = [{id: '', name: ''}].concat(value);
  }
  @Input() set customerSlaAgreements(value: Dictionary[]) {
    this.customerSlaAgreementOptions = [{id: '', name: ''}].concat(value);
  }
  canSave = false;
  appliedProfiles: AppliedProfile[] = [];
  profiles: Dictionary[] = [];
  debtors: Dictionary[] = [];
  preDepartureDebtors: Dictionary[] = [];
  fullDebitorList: Debtor[];
  priceAgreementOptions: Dictionary[];
  customerSlaAgreementOptions: Dictionary[];
  private originalAppliedProfiles: Map<string, string> = new Map();
  numberOfAppliedProfiles = 0;
  lastUpdate : AppliedProfile;
  constructor(private modalService: OnepointModalService,
              private appliedProfileService: AppliedProfileService,
              private debtorService: DebtorService) { }

  ngOnInit() {
    if (this.commonCustomer.children != null) {
      this.profiles = [{id: '', name: ''}].concat(...this.commonCustomer.children.map(b => b.profiles));
    }
    this.debtorService.getDebtors(Number(this.commonCustomer.id)).subscribe(
      result => {
        this.fullDebitorList = result;
        result.forEach(value => value.name = value.number + ' ' + value.name);
        this.debtors = [{id: '', name: ''}];
        this.debtors.push(...result);
        },
      error => console.error(error)
    );
    this.loadAppliedProfiles();
  }

  loadAppliedProfiles() {
    this.appliedProfileService.getAppliedProfiles(this.contract.id).subscribe(
      result => {
        this.appliedProfiles = result;
        this.numberOfAppliedProfiles = result.length;
        this.originalAppliedProfiles.clear();
        this.lastUpdate = new AppliedProfile();
        this.appliedProfiles.forEach(
          (ap) => {
            this.originalAppliedProfiles.set(ap.id, JSON.stringify(ap))
            if (this.isLater(this.lastUpdate.modifiedOn, ap.modifiedOn)) {
              this.lastUpdate = ap;
            }
          }
        );
        if (result.length === 0) {
          this.appliedProfiles.push(new AppliedProfile());
        }
      },
      error => console.error(error)
    );
  }

  addAppliedProfile() {
    this.appliedProfiles.push(new AppliedProfile());
  }

  getSortDebitorList(debitorId: number): Dictionary[] {
    this.preDepartureDebtors = [];
    let selectedDebtor =  this.fullDebitorList.find(debitor => +debitor.id === +debitorId) as Debtor;
    this.preDepartureDebtors.push({id: '', name: ''});
    this.fullDebitorList.forEach( debitor => {
        if(debitor.billingCurrency.id === selectedDebtor.billingCurrency.id) {
          this.preDepartureDebtors.push(debitor);
        }
      }
    );
    return this.preDepartureDebtors;
  }

  recalculateCanSave() {
    let valid = true;
    this.appliedProfiles.forEach(
      ap => valid = valid && ap.isValid() && this.validContractTermination(ap.termination)
    );
    valid = valid && !AppliedProfile.overlapsAny(this.appliedProfiles);
    this.canSave = valid;
  }

  private validContractTermination(appliedProfileTermination: Date): boolean {
    return !this.contract.terminationDate || (!!appliedProfileTermination && appliedProfileTermination <= this.contract.terminationDate);
  }
  private isLater(current: moment.Moment , next: moment.Moment) {
    if (current) {
      return current.isBefore(next);
    } else {
      return !!next
    }
  }

  openApply() {
    this.canSave = false;
    this.loadAppliedProfiles();
    this.modalService.openWithCustomWindowClass(this.modal, Size.LARGE, 'contract-profile-modal');
  }

  close() {
    this.modalService.dismissAll();
  }

  saveApplyProfiles() {
    forkJoin(this.handleChangesAndDeletions().concat(this.handleNew())).subscribe(
      ignore => {
        this.close();
        this.loadAppliedProfiles();
      },
      error => console.error(error)
    );
  }

  handleChangesAndDeletions(): Observable<any>[] {
    const responses = [];
    this.originalAppliedProfiles.forEach(
      oap => {
        const original = JSON.parse(oap);
        const appliedProfile = this.appliedProfiles.find(ap => ap.id === original.id);
        if (appliedProfile == null || appliedProfile.isEmpty()) {
          responses.push(this.appliedProfileService.deleteAppliedProfile(this.contract.id, original.id));
        } else if (oap !== JSON.stringify(appliedProfile)) {
          responses.push(this.saveAppliedProfile(appliedProfile));
        }
      }
    );
    return responses;
  }

  handleNew(): Observable<any>[] {
    return this.appliedProfiles.filter(ap => ap.id == null && !ap.isEmpty())
      .map(ap => this.saveAppliedProfile(ap));
  }

  saveAppliedProfile(appliedProfile: AppliedProfile): Observable<any> {
    return this.appliedProfileService.saveAppliedProfile(this.contract.id, appliedProfile);
  }

  getMinimumCommencementDate() {
    return new Date(this.contract.commencementDate.getTime() - (24 * 60 * 60 * 1000));
  }
  getMaximumCommencementDate(profileTermination: Date) {
    let maxDate = null;
    if (profileTermination) {
      maxDate = new Date(profileTermination.getTime() + (24 * 60 * 60 * 1000));
    } else if (this.contract.terminationDate) {
      maxDate = new Date(this.contract.terminationDate.getTime() + (24 * 60 * 60 * 1000));
    }
    return maxDate;
  }

  getMinimumTerminationDate(profileCommencement: Date) {
    let minDate = new Date(this.contract.commencementDate.getTime() - (24 * 60 * 60 * 1000));
    if (profileCommencement) {
      minDate = new Date(profileCommencement.getTime() - (24 * 60 * 60 * 1000));
    }
    return minDate;
  }

  getMaximumTerminationDate() {
    let maxDate = null;
    if (this.contract.terminationDate) {
      maxDate = new Date(this.contract.terminationDate.getTime() + (24 * 60 * 60 * 1000));
    }
    return maxDate;
  }
}
