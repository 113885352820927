import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IndexAdapter, Index } from 'app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class IndexService extends BaseService {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private indexAdapter: IndexAdapter
  ) {
    super(settingsService);
  }

  public getIndexesOnYear(year: string): Observable<Index[]> {
    return this.http
      .get(this.baseURL + 'index/' + year)
      .pipe(
        map((data: any[]) => data.map(item => this.indexAdapter.adapt(item)))
      );
  }

  public addIndex(indexName: string): Observable<Index> {
    return this.http.post<Index>(this.baseURL + 'index/' + indexName, {
      headers: this.jsonHeaders
    });
  }

  public updateIndexes(indexes: Index[], year: string): Observable<Index[]> {
    return this.http.put<Index[]>(this.baseURL + 'index/' + year, indexes, {
      headers: this.jsonHeaders
    });
  }
}
