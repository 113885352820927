
<div class="page-container">
  <div class="page-header">
      <div class="my-auto counts-container">
        <div class="count-container">
          <span>Service Items</span> <span>{{masterListFiltered?.length}}</span>
        </div>
      </div>
      <div class="status-container my-auto">
        <app-drop-down [(selectedItemId)]="selectedStatus"
                       [items]="statusTypes"
                       (selectedItemIdChange)="selectedStatusTypeChanged($event)">
        </app-drop-down>
      </div>
      <div class="button-container my-auto">
        <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_CREATE">
          <button class="primary-button" (click)="addNew()">
            Add Service item
          </button>
        </div>
      </div>
  </div>
  <div class="primary-table page-content-container">
    <table class="master-lists-table">
      <thead>
        <tr>
          <td (click)="showFilteringSortingMenu(ServiceItemMenuType.SERVICE_ITEM_CODE)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Business key</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(ServiceItemMenuType.SERVICE_ITEM_CODE) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ServiceItemMenuType.SERVICE_ITEM_CODE"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ServiceItemMenuType.SERVICE_ITEM_NAME)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Service Item name</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(ServiceItemMenuType.SERVICE_ITEM_NAME) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ServiceItemMenuType.SERVICE_ITEM_NAME"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ServiceItemMenuType.UI_SORT_INDEX)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Sort in UI</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(ServiceItemMenuType.UI_SORT_INDEX) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ServiceItemMenuType.UI_SORT_INDEX"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ServiceItemMenuType.VAT)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>VAT(Yes/No)</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(ServiceItemMenuType.VAT) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ServiceItemMenuType.VAT"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ServiceItemMenuType.DESCRIPTION)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Description</span>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ServiceItemMenuType.SERVICE_TYPE)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Service type</span>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ServiceItemMenuType.RECOVERY)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Recovery</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(ServiceItemMenuType.RECOVERY) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ServiceItemMenuType.RECOVERY"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ServiceItemMenuType.ACTIVATE_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Activation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(ServiceItemMenuType.ACTIVATE_ON) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ServiceItemMenuType.ACTIVATE_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ServiceItemMenuType.DEACTIVATION_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Deactivation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(ServiceItemMenuType.DEACTIVATION_ON) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ServiceItemMenuType.DEACTIVATION_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of masterListFiltered" (click)="edit(item)">
          <td>
            <div class="first-column-container">
              <div class="first-column-content">
                {{ item.serviceItemCode }}
              </div>
            </div>
          </td>
          <td class="serviceItemNameWidth">
            {{ item.serviceItemName }}
          </td>
          <td>
            {{ item.uiSortIndex }}
          </td>
          <td>
            {{ item.vat ? 'Yes' : 'No'}}
          </td>
          <td>
            {{ item.description }}
          </td>
          <td>
            {{ getServiceTypesLabel(item.serviceTypes) }}
          </td>
          <td class="align-center">
            <input type="checkbox" [checked]="item.recovery" disabled="true" />
          </td>
          <td>
            {{ item.activateOn | date: 'dd MMM yyyy'}}
          </td>
          <td>
            {{ item.deactivateOn | date: 'dd MMM yyyy'}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-template #MasterListModal>
    <app-masterlist-service-item-modal [masterListItem]="masterListItem" (refreshTable)="reload()">
    </app-masterlist-service-item-modal>
  </ng-template>
</div>
