import {Injectable} from '@angular/core';
import {Adapter} from './../../interfaces/adapter';
import {Flow, FlowAdapter} from "@onepoint/shared/models/digital-caseflow/flow";

export class CustomerConfiguration {
  id: number;
  revision: number;
  customerProfileId: number;
  channel: string;
  enabled: boolean;
  flow: Flow;
  hiddenInSummary: boolean;

  public constructor(init?: Partial<CustomerConfiguration>) {
    Object.assign(this, init);
  }
}


@Injectable({
  providedIn: 'root'
})
export class CustomerConfigurationAdapter implements Adapter<CustomerConfiguration> {
  adapt(item: any): CustomerConfiguration {
    const flowAdapter = new FlowAdapter();
    return new CustomerConfiguration({
      id: item.id,
      revision: item.revision,
      customerProfileId: item.customerProfileId,
      channel: item.channel,
      enabled: item.enabled,
      flow: flowAdapter.adapt(item.flow),
      hiddenInSummary: item.hiddenInSummary
    });
  }
}
