<div class="service-type-modal-container">
  <div class="closing-x" (click)="closeModal()">
    <img src="/assets/icons/Close.svg" />
  </div>
  <div class="header">
    <span *ngIf="isNew">Create new Dedicated Email</span>
    <span *ngIf="!isNew">Edit Dedicated Email</span>
  </div>
  <div class="row body-container" *ngIf="masterListType">
    <div class="col-3">
      <div class="input-name">
        Activate on
        <span *ngIf="!isReadonly" class="required">*</span>
      </div>
      <app-input-calendar
        [withImage]="true"
        [selectMode]="'single'"
        [disabled]="isReadonly"
        [(dateTimeRange)]="masterListType.activateOn"
        [maxDate]="masterListType.deactivateOn"
        (saveModel)="activateOnChanged(masterListType)"
      >
      </app-input-calendar>
      <div class="input-name">Deactivate after</div>
      <app-input-calendar
        [withImage]="true"
        [selectMode]="'single'"
        [disabled]="isReadonly"
        [(dateTimeRange)]="masterListType.deactivateOn"
        [minDate]="minDeactivationDate"
        (saveModel)="deactivateOnChanged(masterListType)"
      >
      </app-input-calendar>
      <div class="label" style="font-size: 14px;
      color: #787878;" [ngClass]="'input-name'">Based on case creation time</div>
    </div>
    <div class="col-9">
      <div class="row">
        <div class="col-6">
          <div>
            <app-input
              [name]="'Business key'"
              [(value)]="masterListType.businessKey"
              (valueChange)="modelChanged()"
              [disabled]="isReadonly || masterListType.id"
              [required]="!isReadonly"
            ></app-input>
          </div>
          <div class="padding-top">
            <app-input
              [name]="'Email'"
              [(value)]="masterListType.sendTo"
              (valueChange)="modelChanged()"
              [disabled]="isReadonly"
              [required]="!isReadonly"
            ></app-input>
          </div>
          <div class="padding-top">
            <div class="field-title">Team / ID</div>
            <app-generic-drop-down [items]="teams" [(selectedItem)]="masterListType.adTeam" (selectedItemChange)="teamChanged($event)">
            </app-generic-drop-down>
          </div>
        </div>
        <div class="col-6">
          <div class="padding-top">
            <div class="field-title">Always Direct to Team</div>

            <input
              type="checkbox"
              name="alwaysDirectToTeam"
              [(ngModel)]="masterListType.alwaysDirectToTeams"
              class="sos-services-checkbox"
              [disabled]="readonly"
              (change)="modelChanged()"
            />
          </div>
          <div class="padding-top">
            <div class="field-title">Team before Profile</div>

            <input
              type="checkbox"
              name="teamBeforeProfile"
              [(ngModel)]="masterListType.teamBeforeProfile"
              class="sos-services-checkbox"
              [disabled]="readonly"
              (change)="modelChanged()"
            />
          </div>
          <div class="row body-container" *ngIf="error">
            <div class="col-12 error">
              {{ error }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="masterlist-modal-footer d-flex justify-content-between">
    <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE" class="">
      <button class="primary-button save-button" [class.button-disabled]="!isSaveButtonEnabled" (click)="save()">Save</button>
    </div>
    <app-last-changed [masterListItem]="masterListType" class="last-changed float-end"> </app-last-changed>
  </div>
</div>
