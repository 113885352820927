import { Injectable } from '@angular/core';

export class MasterListRefundTypeModel {
  id: string;
  businessKey: string;
  refundTypeName: string;
  description: string;
  uiSort: number;
  activateOn: Date;
  deactivateOn: Date;
  active: boolean;
  modifiedOn: Date;
  modifiedBy: string;

  public constructor(init?: Partial<MasterListRefundTypeModel>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class MasterListRefundTypeModeAdapter {
  adapt(item: any): MasterListRefundTypeModel {
    return new MasterListRefundTypeModel({
      id: item.id,
      businessKey: item.businessKey,
      refundTypeName: item.refundTypeName,
      description: item.description,
      uiSort: item.uiSort,
      activateOn: item.activateOn != null ? new Date(item.activateOn) : null,
      deactivateOn: item.deactivateOn != null ? new Date(item.deactivateOn) : null,
      modifiedOn: item.modifiedOn != null ? new Date(item.modifiedOn) : null,
      modifiedBy: item.modifiedBy
    });
  }
}
