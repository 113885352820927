<div class="page-container">
    <div class="applications-toolbar-container">
        <div class="applications-toolbar-label">Applications</div>
        <div class="applications-toolbar-elements">
          <div class="applications-toolbar-selector">
            <div class="applications-toolbar-dropdown">
              <app-drop-down
                  [(selectedItemId)]="selectedApplicationType"
                  [disabled]="false"
                  [items]="applicationTypes"
                  (selectedItemIdChange)="applicationSelected()">
              </app-drop-down>
            </div>
            <div class="applications-toolbar-spinner">
              <img *ngIf="loadInProgress" src="./assets/icons/waitForSearchresult.gif">
            </div>
            <div class="checkbox-container">
                <span class="applicationCheckbox"><input type="checkbox" (click)="$event.stopPropagation()" (change)="checkBoxShowActiveChange($event)" [(ngModel)]="checkboxShowActive"/></span>
                <span class="applicationLabel">Show included</span>
            </div>
          </div>
          <div>
            <button class="primary-button save-button" (click)="saveChanges()" [ngClass]="{ 'button-disabled': !isSaveButtonEnabled }" [disabled]="!isSaveButtonEnabled">
              <img *ngIf="saveInProgress" src="./assets/icons/waitForSearchresult.gif" alt="Save changes">
              <span *ngIf="!saveInProgress">Save changes</span>
            </button>            
          </div>
        </div>
    </div>
    <div *ngIf="selectedApplicationType">
      <div>
        <div>
          <table class="primary-table profile-application-lists-table">
            <thead>
            <tr>
              <td (click)="showFilteringSortingMenu(CustomerProfileApplicationsOverviewMenuType.PROFILE_CODE)" class="filter-column unsortable-column">
                <div class="sortable">
                  <span>Profile code</span>
                  <span class="icon"></span>
                </div>
                <div [ngStyle]="!showMenu[CustomerProfileApplicationsOverviewMenuType.PROFILE_CODE] && { display: 'none' }">
                  <app-customer-profile-applications-overview-menu
                    [sortingKey]="CustomerProfileApplicationsOverviewMenuType.PROFILE_CODE"
                    [profileApplications]="filteredProfileApplications"
                  ></app-customer-profile-applications-overview-menu>
                </div>
              </td>
              <td (click)="showFilteringSortingMenu(CustomerProfileApplicationsOverviewMenuType.COMMON_CUSTOMER)" class="filter-column unsortable-column">
                <div class="sortable">
                  <span>Common customer</span>
                  <span class="icon"></span>
                </div>
                <div [ngStyle]="!showMenu[CustomerProfileApplicationsOverviewMenuType.COMMON_CUSTOMER] && { display: 'none' }">
                  <app-customer-profile-applications-overview-menu
                    [sortingKey]="CustomerProfileApplicationsOverviewMenuType.COMMON_CUSTOMER"
                    [profileApplications]="filteredProfileApplications"
                  ></app-customer-profile-applications-overview-menu>
                </div>
              </td>
              <td (click)="showFilteringSortingMenu(CustomerProfileApplicationsOverviewMenuType.BRAND_NAME)" class="filter-column unsortable-column">
                <div class="sortable">
                  <span>Brand name</span>
                  <span class="icon"></span>
                </div>
                <div [ngStyle]="!showMenu[CustomerProfileApplicationsOverviewMenuType.BRAND_NAME] && { display: 'none' }">
                  <app-customer-profile-applications-overview-menu
                    [sortingKey]="CustomerProfileApplicationsOverviewMenuType.BRAND_NAME"
                    [profileApplications]="filteredProfileApplications"
                  ></app-customer-profile-applications-overview-menu>
                </div>
              </td>
              <td (click)="showFilteringSortingMenu(CustomerProfileApplicationsOverviewMenuType.PROFILE_NAME)" class="filter-column unsortable-column">
                <div class="sortable">
                  <span>Profile name</span>
                  <span class="icon"></span>
                </div>
                <div [ngStyle]="!showMenu[CustomerProfileApplicationsOverviewMenuType.PROFILE_NAME] && { display: 'none' }">
                  <app-customer-profile-applications-overview-menu
                    [sortingKey]="CustomerProfileApplicationsOverviewMenuType.PROFILE_NAME"
                    [profileApplications]="profileApplications"
                  ></app-customer-profile-applications-overview-menu>
                </div>
              </td>
              <td (click)="showFilteringSortingMenu(CustomerProfileApplicationsOverviewMenuType.INCLUDED)" class="filter-column unsortable-column">
                <div class="sortable">
                  <span>
                    <span class="checkbox-container">
                      <input class="applicationCheckbox" type="checkbox" (click)="$event.stopPropagation()" (change)="checkBoxAllIncludedChange($event)" [(ngModel)]="checkboxAllIncluded"/>                                
                    </span>
                    <span class="name-heading">Included</span>
                  </span>
                  <span class="icon"></span>
                </div>
                <div [ngStyle]="!showMenu[CustomerProfileApplicationsOverviewMenuType.INCLUDED] && { display: 'none' }">
                  <app-customer-profile-applications-overview-menu
                    [sortingKey]="CustomerProfileApplicationsOverviewMenuType.INCLUDED"
                    [profileApplications]="filteredProfileApplications"
                  ></app-customer-profile-applications-overview-menu>
                </div>
              </td>
              <td (click)="showFilteringSortingMenu(CustomerProfileApplicationsOverviewMenuType.VALID_FROM)" class="filter-column unsortable-column date-column">
                <div class="sortable">
                    <span class="name-heading">Start date</span>
                    <span class="checkbox-text-container">
                      <span class="checkbox-container">
                        <input class="applicationCheckbox" type="checkbox" (click)="$event.stopPropagation()" (change)="checkBoxEditAllValidFromChange($event)" [(ngModel)]="checkboxEditAllValidFrom"/>                                
                      </span>
                      <span class="name-heading">Multi edit</span>
                    </span>                    
                  <span class="icon"></span>
                </div>
                <div [ngStyle]="!showMenu[CustomerProfileApplicationsOverviewMenuType.VALID_FROM] && { display: 'none' }">
                  <app-customer-profile-applications-overview-menu
                    [sortingKey]="CustomerProfileApplicationsOverviewMenuType.VALID_FROM"
                    [profileApplications]="filteredProfileApplications"
                  ></app-customer-profile-applications-overview-menu>
                </div>
              </td>
              <td (click)="showFilteringSortingMenu(CustomerProfileApplicationsOverviewMenuType.VALID_TO)" class="filter-column unsortable-column date-column">
                <div class="sortable">
                  <span class="name-heading">End date</span>
                  <span class="checkbox-text-container">
                    <span class="checkbox-container">
                      <input class="applicationCheckbox" type="checkbox" (click)="$event.stopPropagation()" (change)="checkBoxEditAllValidToChange($event)" [(ngModel)]="checkboxEditAllValidTo"/>
                    </span>
                    <span class="name-heading">Multi edit</span>
                  </span>                    
                  <span class="icon"></span>
                </div>
                <div [ngStyle]="!showMenu[CustomerProfileApplicationsOverviewMenuType.VALID_TO] && { display: 'none' }">
                  <app-customer-profile-applications-overview-menu
                    [sortingKey]="CustomerProfileApplicationsOverviewMenuType.VALID_TO"
                    [profileApplications]="filteredProfileApplications"
                  ></app-customer-profile-applications-overview-menu>
                </div>
              </td>
              <td (click)="showFilteringSortingMenu(CustomerProfileApplicationsOverviewMenuType.STILL_LEGACY)" class="filter-column unsortable-column">
                <div class="sortable">
                  <span>
                    <span class="checkbox-container">
                      <input class="applicationCheckbox" type="checkbox" (click)="$event.stopPropagation()" (change)="checkBoxAllLegacyChange($event)" [(ngModel)]="checkboxAllLegacy"/>                                
                    </span>
                    <span class="name-heading">Legacy</span>
                  </span>
                  <span class="icon"></span>
                </div>
                <div [ngStyle]="!showMenu[CustomerProfileApplicationsOverviewMenuType.STILL_LEGACY] && { display: 'none' }">
                  <app-customer-profile-applications-overview-menu
                    [sortingKey]="CustomerProfileApplicationsOverviewMenuType.STILL_LEGACY"
                    [profileApplications]="filteredProfileApplications"
                  ></app-customer-profile-applications-overview-menu>
                </div>
              </td>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor="let profileApplication of filteredProfileApplications; index as idx">
                <td class="type-column" (click)="goToProfile(profileApplication.profileId)">{{profileApplication.code}}</td>
                <td class="link-column" (click)="goToCommonCustomer(profileApplication.commonCustomerId)">{{profileApplication.commonCustomerName}}</td>
                <td class="link-column" (click)="goToBrand(profileApplication.brandId)">{{profileApplication.brandName}}</td>
                <td class="link-column" (click)="goToProfile(profileApplication.profileId)">{{profileApplication.profileName}}</td>
                <td>
                  <div class="checkbox-container">
                    <input class="applicationCheckbox"
                           type="checkbox"
                           (change)="checkBoxIncludedChange(profileApplication)"
                           [(ngModel)]="profileApplication.included"/>
                  </div>
                </td>
                <td class="date-column">
                  <app-input-calendar
                    [withImage]="true"
                    [(dateTimeRange)]="profileApplication.validFrom"
                    [selectMode]="'single'"
                    [maxDate]="profileApplication.validTo"
                    (saveModel)="validDateFromChange(profileApplication)"
                    [disabled]="idx !== 0 && checkboxEditAllValidFrom">
                  </app-input-calendar>
                </td>
                <td class="date-column">
                  <app-input-calendar
                    [withImage]="true"
                    [(dateTimeRange)]="profileApplication.validTo"
                    [selectMode]="'single'"
                    [minDate]="profileApplication.validFrom"
                    (saveModel)="validDateToChange(profileApplication)"
                    [disabled]="idx !== 0 && checkboxEditAllValidTo">
                  </app-input-calendar>
                </td>
                <td>
                  <div class="checkbox-container">
                    <input class="applicationCheckbox"
                           type="checkbox"
                           (change)="checkBoxLegacyChange(profileApplication)"
                           [(ngModel)]="profileApplication.stillLegacy"/>
                  </div>
                </td>
              </tr>
          </table>
        </div>
        <br>
        <div class="profile-application-container-footer">
          <div>
            <button class="primary-button save-button" (click)="saveChanges()" [ngClass]="{ 'button-disabled': !isSaveButtonEnabled }" [disabled]="!isSaveButtonEnabled">
              <img *ngIf="saveInProgress" src="./assets/icons/waitForSearchresult.gif" alt="Save changes">
              <span *ngIf="!saveInProgress">Save changes</span>
            </button>
          </div>                    
        </div>
      </div>
    </div>
</div>