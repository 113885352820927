import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PriceAgreement} from '../../../../../shared/models/price-agreement';
import {ViewEditAgreementComponent} from './view-edit-agreement/view-edit-agreement.component';
import {Contract} from '../../../../../shared/models/contract';
import {PriceAgreementService} from '../../../../../core/services/price-agreement.service';
import { PermissionEnum } from '@onepoint/shared/models/permission.enum';

@Component({
  selector: 'app-price-agreements',
  templateUrl: './price-agreements.component.html',
  styleUrls: ['./price-agreements.component.scss']
})
export class PriceAgreementsComponent implements OnInit {
  @ViewChild('modal') modal: ViewEditAgreementComponent;
  @Input() contract: Contract;
  @Output() priceAgreementsChange = new EventEmitter<PriceAgreement[]>();
  commencementYear: number;
  priceAgreements: PriceAgreement[] = [];

  constructor(private priceAgreementService: PriceAgreementService) { }

  ngOnInit() {
    this.commencementYear = this.contract.commencementDate.getFullYear();
    this.loadPriceAgreements();
  }

  loadPriceAgreements() {
    this.priceAgreementService.getPriceAgreements(this.contract.id).subscribe(
      result => {
        this.priceAgreements = result.sort((a1, a2) => Number(a2.id) - Number(a1.id));
        this.priceAgreementsChange.emit(this.priceAgreements);
      },
      error => console.error(error)
    );
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
