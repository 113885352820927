<div class="header clearfix">
  <div class="title">SLA agreements ({{ customerSlaAgreements.length }})</div>
  <button class="primary-button new-agreement" (click)="modal.openNew()">Add new SLA agreement</button>
</div>
<div class="agreement" *ngFor="let agreement of customerSlaAgreements">
  <div class="agreement-name">{{ agreement.name }} <span class="suspended" *ngIf="!agreement.enabled">Disabled</span></div>
  <div class="agreement-buttons">
    <button class="primary-button open" (click)="openExisting(agreement.id)">Open</button>
  </div>
</div>

<app-view-edit-customer-sla-agreement #modal (reloadCustomerSlaAgreements)="loadCustomerSlaAgreements()" [contract]="contract">
</app-view-edit-customer-sla-agreement>
