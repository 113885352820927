import {Component, Input, OnInit} from '@angular/core';
import {ProfileService} from '@onepoint/core/services/profile.service';
import {CaseProcessProfile} from '@onepoint/shared/models/case-process-profile';
import {map} from 'rxjs/operators';
import {CaseProcess} from '@onepoint/shared/models/case-process';
import {Dictionary} from '@onepoint/shared/models/dictionary';
import {CaseProcessService} from '@onepoint/core/services/case-process.service';

@Component({
  selector: 'app-profile-case-processes',
  templateUrl: './profile-case-processes.component.html',
  styleUrls: ['./profile-case-processes.component.scss']
})
export class ProfileCaseProcessesComponent implements OnInit {
  caseProcessTypes: CaseProcess[];
  caseProcessProfiles: CaseProcessProfile[];
  oldCaseProcessProfilesJson: string;

  @Input() profileId: number;
  hasChanged = false;

  constructor(private profileService: ProfileService,
              private caseProcessService: CaseProcessService) {
  }

  ngOnInit(): void {
    this.caseProcessService.getCaseProcessTypes()
      .subscribe(
        result => this.caseProcessTypes = result
      );
    this.profileService.getCaseProcessProfiles(this.profileId).subscribe(
      result => {
        this.caseProcessProfiles = result;
        this.oldCaseProcessProfilesJson = JSON.stringify(this.caseProcessProfiles);
      }
    );
  }

  getTypeName(caseProcessType: string): string {
    return this.caseProcessTypes.find(value => caseProcessType === value.caseProcessType).caseProcessName;
  }

  dateChangeValidFrom(caseProcessProfile: CaseProcessProfile) {
    if (caseProcessProfile.validFrom) {
      caseProcessProfile.validFrom.setHours(12, 0, 0, 0);
    }
    this.hasChanged = JSON.stringify(this.caseProcessProfiles) !== this.oldCaseProcessProfilesJson;
  }

  dateChangeValidTo(caseProcessProfile: CaseProcessProfile) {
    if (caseProcessProfile.validTo != null) {
      caseProcessProfile.validTo.setHours(12, 0, 0, 0);
    }
    this.hasChanged = JSON.stringify(this.caseProcessProfiles) !== this.oldCaseProcessProfilesJson;
  }

  saveChanges() {
    this.profileService.updateCaseProcessProfiles(this.profileId, this.caseProcessProfiles).subscribe(result => {
      this.hasChanged = false;
      this.caseProcessProfiles = result;
      this.oldCaseProcessProfilesJson = JSON.stringify(this.caseProcessProfiles);
    });
  }

  checkBoxIncludedChange() {
    this.hasChanged = JSON.stringify(this.caseProcessProfiles) !== this.oldCaseProcessProfilesJson;
  }
}
