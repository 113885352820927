import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Product } from '../../../../../../shared/models/product';
import { ProductService } from '../../../../../../core/services/product.service';

@Component({
  selector: 'app-edit-guide',
  templateUrl: './edit-guide.component.html',
  styleUrls: ['./edit-guide.component.scss']
})
export class EditGuideComponent {
  @Input() product: Product;
  @Output() updated = new EventEmitter<void>();
  @Output() openGuidelineFile = new EventEmitter<string>();
  @ViewChild('content', { static: true }) template: TemplateRef<any>;
  filePath = '';
  name = '';
  ableToSave = false;
  validPath = false;
  uriPrefix = 'file://';
  extension = 'docx';

  constructor(private modalService: NgbModal, private productService: ProductService) {
  }

  open() {
    this.ableToSave = false;
    if (this.product != null) {
      this.name = this.product.guidelineName ? this.product.guidelineName : '';
      this.filePath = this.product.guidelineUri ? this.product.guidelineUri.replace(this.uriPrefix, '') : '';
    }
    this.validatePath();
    this.modalService.open(this.template, { windowClass: 'modal-container' });
  }

  evaluateAbleToSave() {
    this.ableToSave = !!this.name && this.isValidPath(this.filePath) &&
      (this.name !== this.product.guidelineName || this.buildUri(this.filePath) !== this.product.guidelineUri);
  }

  getNormalizedPath(path: string) {
    return path.replace(/\\/g, '/');
  }

  buildUri(path: string) {
    return this.uriPrefix + this.getNormalizedPath(path);
  }

  validatePath() {
    this.filePath = this.getNormalizedPath(this.filePath);
    this.validPath = this.isValidPath(this.filePath);
    this.evaluateAbleToSave();
  }

  private isValidPath(path: string): boolean {
    return path && path.endsWith('.' + this.extension);
  }

  save() {
    const product: Product = new Product();
    Object.assign(product, this.product);
    product.guidelineName = this.name;
    product.guidelineUri = this.buildUri(this.filePath);
    this.productService.updateProduct(product).subscribe(
      result => {
        this.close();
        this.updated.emit();
      },
      error => console.error(error)
    );
  }

  openFile() {
    const path = this.buildUri(this.filePath);
    this.openGuidelineFile.emit(path);
  }

  close() {
    this.modalService.dismissAll();
  }
}
