import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MasterListServiceItemModel } from 'app/shared/models/masterlist-service-item-model';
import { MasterListService } from 'app/core/services/masterlist.service';
import { OnepointModalService } from 'app/core/services/onepoint.modal.service';
import { Dictionary } from 'app/shared/models/dictionary';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import { PermissionService } from 'app/core/services/permission.service';
import { MasterListServiceTypeModel } from '../../../../shared/models/masterlist-service-type-model';
import { MasterListReasonForCancellationModel } from '@onepoint/shared/models/masterlist-reason-for-cancellation-model';
import { MasterlistLanguages } from '@onepoint/master-lists/masterlist-languages';

@Component({
  selector: 'app-masterlist-reason-for-cancellation-modal',
  templateUrl: './masterlist-reason-for-cancellation-modal.component.html',
  styleUrls: ['./masterlist-reason-for-cancellation-modal.component.scss'],
})
export class MasterListReasonForCancellationModalComponent implements OnInit {
  @Input()
  masterListItem: MasterListReasonForCancellationModel;

  @Output()
  refreshTable = new EventEmitter();

  modalTypes: Dictionary[] = [
    { id: 'RECOVERY_HEALTH', name: 'Recovery Health' },
  ];

  languages = MasterlistLanguages;

  serviceTypes: MasterListServiceTypeModel[];
  serviceTypesDictionary: Dictionary[];
  selectedServiceTypes: Dictionary[];

  selectedVatValue: string;
  error: string;
  canUpdate: boolean;
  isModelChanged: boolean;

  constructor(
    private masterListService: MasterListService,
    private onepointModalService: OnepointModalService,
    private permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.permissionService.permissionsReadyNotification.subscribe(
      result => (this.canUpdate = this.permissionService.checkUserPermission(PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE))
    );
  }

  closeModal() {
    this.onepointModalService.dismissAll();
  }

  get isSaveButtonEnabled(): boolean {
    return (
      this.isModelChanged &&
      this.masterListItem &&
      !!this.masterListItem.activateOn &&
      !!this.masterListItem.reasonForCancellationCode &&
      !!this.masterListItem.reasonForCancellationName
    );
  }

  save() {
    if (this.isNew) {
      this.masterListService.addReasonForCancellation(this.masterListItem).subscribe(
        response => {
          this.masterListItem = response;
          this.refreshTable.emit();
          this.closeModal();
        },
        err => (this.error = err.error ? err.error.message : err.message)
      );
    } else {
      this.masterListService.updateReasonForCancellation(this.masterListItem).subscribe(
        response => {
          this.masterListItem = response;
          this.refreshTable.emit();
          this.closeModal();
        },
        err => (this.error = err.error ? err.error.message : err.message)
      );
    }
  }

  selectedModalTypeValueChanged(value: string): void {
    this.modelChanged();
    this.masterListItem.modalType = value;
  }

  activateOnChanged(item: MasterListServiceItemModel) {
    this.modelChanged();
    item.activateOn.setHours(12, 0);
  }

  deactivateOnChanged(item: MasterListServiceItemModel) {
    this.modelChanged();
    item.deactivateOn.setHours(12, 0);
  }

  modelChanged(): void {
    this.isModelChanged = true;
  }

  keepOrder = (a, b) => {
    return a;
  }

  get isReadonly(): boolean {
    return !this.canUpdate;
  }

  get isNew(): boolean {
    return this.masterListItem.id == null;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
