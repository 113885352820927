import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MasterListService } from 'app/core/services/masterlist.service';
import { Dictionary } from 'app/shared/models/dictionary';
import { MasterListSanctionCountryModel } from 'app/shared/models/masterlist-sanction-country-model';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import * as _ from 'lodash';
import { OnepointModalService, Size } from 'app/core/services/onepoint.modal.service';
import { SanctionCountryMenuType } from './masterlist-sanction-countries-menu-type';

@Component({
  selector: 'app-masterlist-sanction-countries-type',
  templateUrl: './masterlist-sanction-countries.component.html',
  styleUrls: ['./masterlist-sanction-countries.component.scss'],
})
export class MasterlistSanctionCountriesComponent implements OnInit {
  @ViewChild('MasterListModal')
  private masterListModalTemplate: TemplateRef<any>;

  masterList: MasterListSanctionCountryModel[];
  masterListFiltered: MasterListSanctionCountryModel[];
  masterListType: MasterListSanctionCountryModel;
  showMenu = new Map<SanctionCountryMenuType, boolean>();
  serviceTypesMap = new Map<string, string>();
  statusTypes: Dictionary[] = [
    { id: 'all', name: 'All' },
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
  ];
  selectedStatus = 'all';

  
  get PermissionEnum() {
    return PermissionEnum;
  }
  
  get SanctionCountryMenuType() {
    return SanctionCountryMenuType;
  }

  constructor(private masterListService: MasterListService, private onepointModalService: OnepointModalService) {}

  ngOnInit(): void {
    this.loadMasterList();
  }

  addNew(): void {
    this.masterListType = new MasterListSanctionCountryModel();
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.LARGE, 'sanction-country-modal-container');
  }

  selectedStatusTypeChanged(status: string) {
    this.masterListFiltered = this.filterByStatus(status);
  }

  private loadMasterList() {
    this.masterListService.getSanctionCountryList().subscribe(result => {
      this.masterList = result;
      this.masterListFiltered = result;
    });
  }

  private filterByStatus(status: string): MasterListSanctionCountryModel[] {
    if (status === 'all') {
      return this.masterList;
    }

    return this.masterList.filter(item => (status === 'active' ? item.active : !item.active));
  }

  showFilteringSortingMenu(filteringSortingMenuType: SanctionCountryMenuType) {
    this.showMenu.forEach((value, key) => {
      if (key !== filteringSortingMenuType) {
        this.showMenu[key] = false;
      }
    });

    this.showMenu[filteringSortingMenuType] = !this.showMenu[filteringSortingMenuType];
  }

  edit(item: MasterListSanctionCountryModel) {
    this.masterListType = _.clone(item);
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.LARGE, 'reduction-modal-container');
  }

  reload() {
    this.loadMasterList();
  }
}
