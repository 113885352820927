import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


@Component({
  selector: 'app-reload-footer',
  templateUrl: './reload-footer.component.html',
  styleUrls: ['./reload-footer.component.scss']
})
export class ReloadFooterComponent implements OnInit {

  newVersion = false;
  private lastModified: string;
  private readonly CONFIG_URL = 'build.txt';
  private subscription;
  private count = 0;

  private headers = new HttpHeaders({
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache'
  });



  constructor(private http: HttpClient) { }

  ngOnInit(): void {

    this.subscription = interval(100 * 1000).pipe(
      mergeMap(() => this.http.get(this.CONFIG_URL, { responseType: 'text', headers: this.headers }))
    ).subscribe(data => {
      this.count++;
      this.checkforNewVersion(data);
    });
  }
  private checkforNewVersion(newValue: string) {
    if (!this.lastModified) {
      this.lastModified = newValue;
    } else{
      if (newValue && this.lastModified !== newValue) {
        this.subscription.unsubscribe();
        this.newVersion = true;
      }
    }
  }

  reloadAppClick() {
    window.location.reload();
  }
}
