import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import {ErpCreditor, ErpCreditorAdapter} from "@onepoint/shared/models/erp-creditor";

export class SupplierProfile {
  id: number;
  supplierProfileCode: string;
  supplierProfileName: string;
  providerId: number;
  creditorNumber: string;
  creditorName: string;
  creditorCurrency: string;
  primaryErpCreditor: ErpCreditor;
  secondaryErpCreditors: ErpCreditor[] = [];
  networkManagerId: string;
  networkManagerFirstname: string;
  networkManagerSurname: string;
  networkManagerEmail: string;
  networkManagerPhone: string;
  startDate: Date;
  endDate: Date;
  type: string;
  typeName: string;
  automatedApproval = false;
  approvalScore: number;
  approvalLimitFactor = 1;
  supplierProfilePartners: SupplierProfilePartner[];

  public constructor(init?: Partial<SupplierProfile>) {
    Object.assign(this, init);
  }
}

export class SupplierProfilePartner {
  id: number;
  startDate: Date;
  endDate: Date;
  supplierProfileId: number;
  supplierProfilePartnerId: number;

  public constructor(init?: Partial<SupplierProfilePartner>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SupplierProfilePartnerAdapter implements Adapter<SupplierProfilePartner> {
  adapt(item: any): SupplierProfilePartner {
    return new SupplierProfilePartner({
      id: item.id,
      startDate: new Date(item.startDate),
      endDate: item.endDate ? new Date(item.endDate) : null,
      supplierProfileId: item.supplierProfileId,
      supplierProfilePartnerId: item.supplierProfilePartnerId
    });
  }
}
@Injectable({
  providedIn: 'root',
})
export class SupplierProfileAdapter implements Adapter<SupplierProfile> {
  constructor(private erpCreditorAdapter: ErpCreditorAdapter,
              private supplierProfilePartnerAdapter: SupplierProfilePartnerAdapter) {
  }

  adapt(item: any): SupplierProfile {
    const endDate = item.endDate ? new Date(item.endDate) : null;
    return new SupplierProfile({
      id: item.id,
      supplierProfileCode: item.supplierProfileCode,
      supplierProfileName: item.supplierProfileName,
      providerId: item.providerId,
      creditorNumber: item.creditorNumber,
      creditorName: item.creditorName,
      creditorCurrency: item.creditorCurrency,
      primaryErpCreditor: item.primaryErpCreditor ? this.erpCreditorAdapter.adapt(item.primaryErpCreditor) : null,
      secondaryErpCreditors: item.secondaryErpCreditors.map(this.erpCreditorAdapter.adapt),
      networkManagerId: item.networkManagerId,
      networkManagerFirstname: item.networkManagerFirstname,
      networkManagerSurname: item.networkManagerSurname,
      networkManagerEmail: item.networkManagerEmail,
      networkManagerPhone: item.networkManagerPhone,
      startDate: new Date(item.startDate),
      endDate,
      type: item.type,
      automatedApproval: item.automatedApproval,
      approvalScore: item.approvalScore,
      approvalLimitFactor: item.approvalLimitFactor || 1,
      supplierProfilePartners: item.supplierProfilePartners?.map(item => this.supplierProfilePartnerAdapter.adapt(item))
    });
  }
}
