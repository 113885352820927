import {
  AdTeamsDictionary,
  Dictionary,
  DropdownAdapter,
  PurposeOfTravelDropdownAdapter,
  TeamAdtCodeDropdownAdapter,
  TeamDropdownAdapter
} from '../../shared/models/dictionary';
import {Injectable} from '@angular/core';
import {BusinessArea, Markets} from '../mocks/dictionary';
import {Observable} from 'rxjs';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from './settings.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService extends BaseService {
  constructor(
    private http: HttpClient,
    private dropdownAdapter: DropdownAdapter,
    private settingsService: SettingsService,
    private teamDropdownAdapter: TeamDropdownAdapter,
    private purposeOfTravelDropdownAdapter: PurposeOfTravelDropdownAdapter
  ) {
    super(settingsService);
  }

  getMarketsName(marketsId: string): string[] {
    if (marketsId === undefined || marketsId === null) {
      return [];
    }
    const result: string[] = [];
    const ids = marketsId.split(',');
    ids.forEach(id => {
      result.push(this.getMarketName(id.trim()));
    });
    return result;
  }

  getMarketName(marketId: string): string {
    let market = Markets.find(a => a.id === marketId);
    if (market) {
      return market.name;
    } else {
      return '';
    }
  }

  getSegments(): Observable<Dictionary[]> {
    return this.getDictionary('segments');
  }

  getSubBusinessRelations(): Observable<Dictionary[]> {
    return this.getDictionary('sub-business-relations');
  }

  getBusinessAreas(): Dictionary[] {
    return BusinessArea;
  }

  getErpClients(): Observable<Dictionary[]> {
    return this.getDictionary('erp-clients');
  }

  getBillingCurrencies(): Observable<Dictionary[]> {
    return this.getDictionary('billing-currencies');
  }

  getTeamsByDepartments(): Observable<Dictionary[]> {
    return this.http
    .get(this.baseURL + 'masterlist/adteams')
      .pipe(map((data: any[]) => data.map(item => new Dictionary(item.adTCode, item.teamName))));
  }

  getADDepartments(): Observable<Dictionary[]> {
    return this.http
      .get(this.baseURL + 'ad-departments')
      .pipe(map((data: any[]) => data.map(item => new Dictionary(item.id, item.departmentName))));
  }

  public getAllPurposeOfTravels(): Observable<Dictionary[]> {
    return this.http.get(this.baseURL + "masterlist/purpose-of-travel-list")
      .pipe(map((data: any[]) => data.map(item => this.purposeOfTravelDropdownAdapter.adapt(item))));
  }

  getChannelTypes(): Observable<Dictionary[]> {
    return this.http
      .get(this.baseURL + 'masterlist/channel-type-list')
      .pipe(map((data: any[]) => data.map(item => new Dictionary(item.channelTypeEnumValue, item.channelTypeName))));
  }

  getCoverageLimitType(): Dictionary[] {
    return [
      new Dictionary('AMOUNT', 'Amount'),
      new Dictionary('NUMBER_OF_PERSONS', 'Number of persons'),
      new Dictionary('DURATION', 'Duration'),
      new Dictionary('QUANTITY', 'Quantity'),
    ];
  }

  getPersonLimitType(): Dictionary[] {
    return [new Dictionary('PERSON', 'Person')];
  }

  getTreatmentsLimitType(): Dictionary[] {
    return [new Dictionary('TREATMENTS', 'Treatments')];
  }

  getCoverageType(): Dictionary[] {
    return [new Dictionary('BASIC', 'Basic coverage'), new Dictionary('SUPPLEMENT', 'Supplement')];
  }

  getLimitFromType(): Dictionary[] {
    return [new Dictionary('INCIDENT_DATE', 'Incident date'), new Dictionary('FIRST_MEDICAL_VISIT', 'First medcal visit')];
  }

  getPaymentTerms(): Observable<Dictionary[]> {
    return this.getDictionary('payment-terms');
  }

  getInvoiceFrequencies(): Observable<Dictionary[]> {
    return this.getDictionary('invoice-frequencies');
  }

  getSplitDatesTypes(): Dictionary[] {
    return [
      new Dictionary('INCIDENT_DATE', 'Incident date'),
      new Dictionary('REPORT_DATE', 'Report date'),
    ];
  }

  getCustomerRelationLabelStatus(): Dictionary[] {
    return [
      new Dictionary('ACTIVE', 'Active'),
      new Dictionary('DEACTIVATED', 'Deactivated'),
    ];
  }

  getCustomerRelationLabelStatusForSearch(): Dictionary[] {
    return [
      new Dictionary('ACTIVE', 'Active'),
      new Dictionary('DEACTIVATED', 'Deactivated'),
      new Dictionary('ALL', 'All'),
    ];
  }

  getCustomerProductRelationFilterTypesForSearch(): Dictionary[] {
    return [
      new Dictionary('ACTIVE_FUTURE', 'Active & future'),
      new Dictionary('PAST', 'Past'),
      new Dictionary('ALL', 'All')
    ];
  }

  private getDictionary(dictionaryItemPath: string): Observable<Dictionary[]> {
    return this.http
      .get(this.baseURL + dictionaryItemPath)
      .pipe(map((data: any[]) => data.map(item => new Dictionary(item.id, item.name))));
  }
}
