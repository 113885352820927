<div class="top-container">
  <div class="filter-combo">
    <app-drop-down [items]="filterOptions" [(selectedItemId)]="selectedFilter" (selectedItemIdChange)="filterContracts()"></app-drop-down>
  </div>
  <div class="choose-business-area-combo">
    <app-drop-down [items]="businessAreas" [placeholder]="'Choose business area'" [(selectedItemId)]="currentContract.businessArea">
    </app-drop-down>
  </div>
  <div class="add-new-contract" [ngClass]="{ 'tooltip-control': !isAddingNewContractAllowed }">
    <span class="tooltiptext"
      >Select a termination date for the existing contract for the chosen business area before adding a new one</span
    >
    <input
      type="button"
      class="primary-button add-new-contract-button"
      value="Add new contract"
      [ngClass]="{ 'add-new-contract-button-active': isAddingNewContractAllowed }"
      (click)="addNewContractButtonClick()"
    />
  </div>
</div>
<div class="content-container" *ngFor="let contract of filteredContracts">
  <div class="bussines-area">
    <img *ngIf="contract.businessArea === BusinessAreaEnum.TRAVELCARE" src="assets/icons/travel-enabled.png" />
    <img *ngIf="contract.businessArea === BusinessAreaEnum.MOBILITY" src="assets/icons/roadside-enabled.png" />
    <img *ngIf="contract.businessArea === BusinessAreaEnum.HEALTHCARE" src="assets/icons/health-enabled.png" />
  </div>
  <div class="contract-placeholder">
    <div class="contract-title">
      {{ contract.title }}
      <a target="_blank" [href]="contract.linkToDatabase"> <img src="assets/icons/New window.svg" class="new-window-image" /> </a>
      <button class="primary-button edit-contract-button" (click)="editNewContractButtonClick(contract)">Edit contract</button>
    </div>
    <div class="contract-content">
      <div class="contract-content-agreements">
        <app-price-agreements [contract]="contract" (priceAgreementsChange)="priceAgreements[contract.id] = $event"></app-price-agreements>
        <app-sla-agreements [contract]="contract" (customerSlaAgreementsChange)="customerSlaAgreements[contract.id] = $event"></app-sla-agreements>
      </div>
      <div class="contract-content-profiles">
        <app-contract-profiles [contract]="contract" [commonCustomer]="_commonCustomer" [priceAgreements]="priceAgreements[contract.id]" [customerSlaAgreements]="customerSlaAgreements[contract.id]">
        </app-contract-profiles>
      </div>
      <div class="contract-content-dates">
        <div class="date-value">{{ contract.commencementDate | date: 'dd.MMM yyyy' }}</div>
        <div class="date-description date-description-active">Commencement date</div>
        <div class="date-value">{{ contract.nextNegotiationDate | date: 'dd.MMM yyyy' }}{{ contract.nextNegotiationDate ? '' : '-' }}</div>
        <div class="date-description" [ngClass]="{ 'date-description-active': contract.nextNegotiationDate }">Next negotiation</div>
        <div class="date-value">{{ contract.terminationDate | date: 'dd.MMM yyyy' }}{{ contract.terminationDate ? '' : '-' }}</div>
        <div class="date-description" [ngClass]="{ 'date-description-active': contract.terminationDate }">Termination date</div>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-container">
    <div class="contract-modal-header">
      <div class="contract-title">{{ addingMode ? 'Add new contract' : 'Edit contract' }}</div>
      <div class="contract-close"><img (click)="close()" src="assets/icons/Close.svg" alt="Close" /></div>
    </div>
    <div class="contract-modal-body">
      <div class="contract-modal-body-header">
        Contract settings
      </div>
      <div class="contract-modal-body-container">
        <app-input [name]="'Contract title'" [required]="true" [(value)]="currentContract.title" [maxTextLength]="250"> </app-input>
        <app-input
          [name]="'Link to database'"
          [withLink]="true"
          [required]="true"
          [maxTextLength]="250"
          [(value)]="currentContract.linkToDatabase"
        >
        </app-input>
        <app-input-calendar
          [withImage]="true"
          [required]="true"
          [maxDate]="currentContract.nextNegotiationDate"
          [selectMode]="'single'"
          [name]="'Commencement date'"
          (dateTimeRangeChange)="checkCommencementDate($event)"
          [(dateTimeRange)]="currentContract.commencementDate"
        >
        </app-input-calendar>
        <app-input-calendar
          [withImage]="true"
          [selectMode]="'single'"
          (dateTimeRangeChange)="checkTerminationDate($event)"
          [minDate]="currentContract.commencementDate"
          [name]="'Termination date'"
          [dateTimeRange]="currentContract.terminationDate"
        >
        </app-input-calendar>
        <app-input-calendar
          [withImage]="true"
          [selectMode]="'single'"
          (dateTimeRangeChange)="checkNextNegotiationDate($event)"
          [minDate]="currentContract.commencementDate"
          [name]="'Next negotiation date'"
          [dateTimeRange]="currentContract.nextNegotiationDate"
        >
        </app-input-calendar>
      </div>
    </div>
    <div class="contract-modal-footer d-flex">
      <div class="contract-save">
        <button
          class="primary-button"
          (click)="saveContract()"
          [ngClass]="{ 'save-button-active': saveButtonEnabled, 'button-disabled': !saveButtonEnabled }"
          [disabled]="!saveButtonEnabled"
        >
          Save
        </button>
      </div>
      <div>
        <button *ngIf="!addingMode"
                class="primary-button"
                (click)="saveContractRunPriceIndex()"
                [ngClass]="{ 'save-button-active': saveButtonEnabled, 'button-disabled': !saveButtonEnabled }"
                [disabled]="!saveButtonEnabled"
        >
          Save & run price index
        </button>
      </div>
    </div>
  </div>
</ng-template>
