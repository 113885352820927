import {Injectable} from '@angular/core';
import {Adapter} from './../interfaces/adapter';
import {Sla, SlaAdapter} from "./sla";

export class SlaCategory {
  id: number;
  code: string;
  name: string;

  public constructor(init?: Partial<SlaCategory>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SlaCategoryAdapter implements Adapter<SlaCategory> {
  adapt(item: any): SlaCategory {
    return new SlaCategory({
      id: item.id,
      code: item.code,
      name: item.name
    });
  }
}
