import {Injectable} from "@angular/core";
import {Adapter} from "@onepoint/shared/interfaces/adapter";

export class SimpleErpCreditor {
  id: number;
  erpId: number;
  name: string;
  currency: string;
  displayName: string;

  public constructor(init?: Partial<SimpleErpCreditor>) {
    Object.assign(this, init);
  }

}

@Injectable({
  providedIn: 'root'
})
export class SimpleErpCreditorAdapter implements Adapter<SimpleErpCreditor> {
  adapt(item: any): SimpleErpCreditor {
    return new SimpleErpCreditor({
      id: item.id,
      erpId: item.erpId,
      name: item.name,
      currency: item.currency,
      displayName: item.erpId + " - " + item.name + " ("+ item.currency + ")"
    });
  }
}
