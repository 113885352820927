<ng-template #content let-modal>
  <div class="onepoint-modal-container">
    <div class="onepoint-modal-header">
      <div class="onepoint-modal-header-title">
        <div *ngIf="slaCalculatedEvent.id == null">
          Add new event<br/>
        </div>
        <div *ngIf="slaCalculatedEvent.id != null">
          Update event<br/>
        </div>
        <div class="subTitle">Default SLA settings</div>
      </div>
      <div class="onepoint-modal-header-close" (click)="close();"><img src="assets/icons/Close.svg"></div>
    </div>
    <div class="onepoint-modal-body p-3">
      <div class="input-title-name">
        Name
      </div>
      <app-input [(value)]="slaCalculatedEvent.name" (valueChange)="validate()"></app-input>

        <div class="input-title-name">
          Calculation Type
        </div>
        <app-drop-down class="dropdown" [items]="slaCalculatedEventTypeDictionary" [(selectedItemId)]="slaCalculatedEvent.calculatedEventType" (selectedItemIdChange)="validate()"></app-drop-down>

      <div class="onepoint-modal-footer">
        <button
          class="primary-button save-btn"
          (click)="save()"
          [ngClass]="!valid && 'button-disabled'"
          [disabled]="!valid"
        >
          <div *ngIf="slaCalculatedEvent.id == null">
            Add<br/>
          </div>
          <div *ngIf="slaCalculatedEvent.id != null">
            Save<br/>
          </div>
        </button>
      </div>
    </div>
  </div>
</ng-template>
