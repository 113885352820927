<div class="service-type-modal-container">
    <div class="closing-x" (click)="closeModal()">
      <img src="/assets/icons/Close.svg" />
    </div>
    <div class="header">
      <span *ngIf="isNew">Add event</span>
      <span *ngIf="!isNew">Edit event</span>
    </div>
    <div class="row body-container" *ngIf="masterListItem">
      <div class="col-3">
        <div class="input-name">
          Activate on
          <span *ngIf="!isReadonly" class="required">*</span>
        </div>
        <app-input-calendar
          [withImage]="true"
          [selectMode]="'single'"
          [disabled]="isReadonly"
          [(dateTimeRange)]="masterListItem.activateOn"
          [maxDate]="masterListItem.deactivateOn"
          (saveModel)="activateOnChanged(masterListItem)"
        >
        </app-input-calendar>
        <div class="input-name">Deactivate after</div>
        <app-input-calendar
          [withImage]="true"
          [selectMode]="'single'"
          [disabled]="isReadonly"
          [(dateTimeRange)]="masterListItem.deactivateOn"
          [minDate]="minDeactivationDate"
          (saveModel)="deactivateOnChanged(masterListItem)"
        >
        </app-input-calendar>
        <div class="label" class="based-on" [ngClass]="'input-name'">Based on case creation time</div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-6">
            <div>
              <app-input
                [name]="'Business key'"
                [(value)]="masterListItem.businessKey"
                (valueChange)="modelChanged()"
                [disabled]="isReadonly || masterListItem.id"
                [required]="!isReadonly"
              ></app-input>
            </div>
            <div class="padding-top">
              <app-input
                [name]="'Event name'"
                [maxTextLength]="100"
                [(value)]="masterListItem.name"
                (valueChange)="modelChanged()"
                [disabled]="isReadonly"
                [required]="!isReadonly"
              ></app-input>
            </div>
            <div class="padding-top">
              <div class="field-title">Description</div>
              <textarea [(ngModel)]="masterListItem.description" (ngModelChange)="modelChanged()" [disabled]="isReadonly"  maxlength="255"></textarea>
            </div>
            <div class="padding-top">
              <div class="field-title">Hide event in the incident event name list in SECCA</div>
              <input
                type="checkbox"
                [(ngModel)]="masterListItem.hiddenInEventDropDown"
                class="sos-services-checkbox"
                [disabled]="readonly"
                (change)="modelChanged()"
              />
            </div>
          </div>
          <div class="col-6 third-row" >
            <div>
              <div class="field-title">Date of event</div>
              <app-input-calendar
                [withImage]="true"
                [selectMode]="'single'"
                [disabled]="isReadonly"
                [(dateTimeRange)]="masterListItem.dateOfEvent"
                (saveModel)="dateOfEventOnChanged(masterListItem)"
              >
              </app-input-calendar>
            </div>

            <div class="padding-top">
              <app-drop-down
                [name]="'Category'"
                [items]="categories"
                [required]="!isReadonly"
                [disabled]="isReadonly"
                (selectedItemIdChange)="modelChanged()"
                [(selectedItemId)]="masterListItem.category"
                >
              </app-drop-down>
            </div>
          </div>
        </div>
      </div>
      <div class="row body-container" *ngIf="error">
        <div class="col-12 error">
          {{ error }}
        </div>
      </div>
    </div>
    <div class="masterlist-modal-footer d-flex justify-content-between">
      <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE" class="">
        <button class="primary-button save-button" [class.button-disabled]="!isSaveButtonEnabled" (click)="save()">Save</button>
      </div>
      <app-last-changed [masterListItem]="masterListItem" class="last-changed float-end"> </app-last-changed>
    </div>
  </div>
