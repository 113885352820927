import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-profile-case-processes',
  templateUrl: './customer-profile-case-processes.component.html',
  styleUrls: ['./customer-profile-case-processes.component.scss']
})
export class CustomerProfileCaseProcessesComponent implements OnInit {

  shownSection: string;

  constructor() { }

  ngOnInit(): void {
    this.shownSection = 'overview';
  }

}
