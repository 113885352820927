import {ProductsFilteringService} from './products-filtering.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Product} from '../../../../shared/models/product';
import {DictionaryService} from '../../../../core/services/dictionary.service';
import {HelperService} from '../../../../core/services/helper.service';
import {ProductsFilteringSortingMenuTypeEnum} from './products-filtering-sorting-menu-type-enum';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-products-filtering-sorting-menu',
  templateUrl: './products-filtering-sorting-menu.component.html',
  styleUrls: ['./products-filtering-sorting-menu.component.scss']
})
export class ProductsFilteringSortingMenuComponent implements OnInit {
  @Input() productsList: Product[];
  @Input() productsFilteringSortingMenuType: ProductsFilteringSortingMenuTypeEnum;
  @Output() modelChanged = new EventEmitter();
  @Output() productsListChange = new EventEmitter();
  @Output() clearAllFilters = new EventEmitter();
  sortByAscending: boolean;
  dateFilter: boolean;
  sortingKey: string;
  items: CheckBoxModel[];
  fromDate: any;
  toDate: any;

  constructor(
    private dictionaryService: DictionaryService,
    private helperService: HelperService,
    private filteringService: ProductsFilteringService) {
  }

  ngOnInit() {
    this.populateItems();
  }

  public uncheckItems() {
    if (this.items) {
      this.items.forEach(a => (a.check = false));
    }
  }

  sortAscending() {
    this.sortByAscending = true;
    this.helperService.sortArray(
      this.productsList,
      this.sortingKey,
      this.sortByAscending
    );
    this.modelChanged.emit();
  }

  sortDescending() {
    this.sortByAscending = false;
    this.helperService.sortArray(
      this.productsList,
      this.sortingKey,
      this.sortByAscending
    );
    this.modelChanged.emit();
  }

  populateItems() {
    this.items = [];
    switch (this.productsFilteringSortingMenuType) {
      case ProductsFilteringSortingMenuTypeEnum.name:
        this.productsList.forEach(a => {
          if (!this.items.find(f => f.id === a.name)) {
            this.items.push(new CheckBoxModel(a.name, a.name, false));
          }
        });
        this.sortingKey = 'name';
        break;
      case ProductsFilteringSortingMenuTypeEnum.insuranceLevel:
        this.productsList.forEach(a => {
          a.insuranceLevels.forEach(level => {
            if (!this.items.find(f => f.id === level)) {
              this.items.push(new CheckBoxModel(level, level, false));
            }
          });
        });
        this.sortingKey = 'insuranceLevel';
        break;
      case ProductsFilteringSortingMenuTypeEnum.sosProductId:
        this.productsList.forEach(a => {
          if (!this.items.find(f => f.id === a.sosProductId)) {
            this.items.push(new CheckBoxModel(a.sosProductId, a.sosProductId, false));
          }
        });
        this.sortingKey = 'sosProductId';
        break;
      case ProductsFilteringSortingMenuTypeEnum.customerProductId:
        this.productsList.forEach(a => {
          a.customerProductIds.forEach(productId => {
            if (!this.items.find(f => f.id === productId)) {
              this.items.push(new CheckBoxModel(productId, productId, false));
            }
          });
        });
        this.sortingKey = 'customerProductId';
        break;
      case ProductsFilteringSortingMenuTypeEnum.productVersion:
        this.productsList.forEach(a => {
          if (!this.items.find(f => f.id === a.version)) {
            this.items.push(new CheckBoxModel(a.version, a.version, false));
          }
        });
        this.sortingKey = 'version';
        break;
      case ProductsFilteringSortingMenuTypeEnum.businessArea:
        this.productsList.forEach(a => {
          if (!this.items.find(f => f.id === a.businessArea)) {
            this.items.push(new CheckBoxModel(a.businessArea, a.businessArea, false));
          }
        });
        this.sortingKey = 'businessArea';
        break;
      case ProductsFilteringSortingMenuTypeEnum.debtor:
        this.productsList.forEach(a => {
          const overruledText = a.debtorOverruled ? 'Other' : 'Main';
          if (!this.items.find(f => f.id === overruledText)) {
            this.items.push(new CheckBoxModel(overruledText, overruledText, false));
          }
        });
        this.sortingKey = 'debtorOverruled';
        break;
      case ProductsFilteringSortingMenuTypeEnum.validFrom:
        this.productsList.forEach(a => {
          if (!this.items.find(f => f.id === formatDate(a.validFrom, 'dd MMM yyyy', 'en-en'))) {
            this.items.push(new CheckBoxModel(formatDate(a.validFrom, 'dd MMM yyyy', 'en-en')
              , formatDate(a.validFrom, 'dd MMM yyyy', 'en-en'), false));
          }
        });
        this.sortingKey = 'validFrom';
        break;
      case ProductsFilteringSortingMenuTypeEnum.validTo:
        this.productsList.forEach(a => {
          if (!this.items.find(f => f.id === formatDate(a.validTo, 'dd MMM yyyy', 'en-en'))) {
            this.items.push(new CheckBoxModel(formatDate(a.validTo, 'dd MMM yyyy', 'en-en')
              , formatDate(a.validTo, 'dd MMM yyyy', 'en-en'), false));
          }
        });
        this.sortingKey = 'validTo';
        break;
      case ProductsFilteringSortingMenuTypeEnum.deactivationDate:
        this.productsList.forEach(a => {
          if (!this.items.find(f => f.id === formatDate(a.deactivationDate, 'dd MMM yyyy', 'en-en'))) {
            this.items.push(new CheckBoxModel(formatDate(a.deactivationDate, 'dd MMM yyyy', 'en-en')
              , formatDate(a.deactivationDate, 'dd MMM yyyy', 'en-en'), false));
          }
        });
        this.sortingKey = 'deactivationDate';
        break;
      case ProductsFilteringSortingMenuTypeEnum.policyLookupMethodType:
        this.productsList.forEach(a => {
          if (!this.items.find(f => f.id === a.policyLookupMethodType)) {
            this.items.push(new CheckBoxModel(a.policyLookupMethodType, a.policyLookupMethodType, false));
          }
        });
        this.sortingKey = 'policyLookupMethodType';
        break;
      case ProductsFilteringSortingMenuTypeEnum.policyLookupMethodCode:
        this.productsList.forEach(a => {
          if (!this.items.find(f => f.id === a.policyLookupMethodCode)) {
            this.items.push(new CheckBoxModel(a.policyLookupMethodCode, a.policyLookupMethodCode, false));
          }
        });
        this.sortingKey = 'policyLookupMethodCode';
        break;
    }
  }

  get doesUserCheckAnything() {
    return this.items.find(a => a.check) != null;
  }

  applyButtonClick() {
    if (!this.doesUserCheckAnything) {
      return;
    }
    this.clearAllFilters.emit();
    const selectedIds = [];
    this.items.filter(a => a.check).forEach(a => selectedIds.push(a.id));
    this.filteringService.removeStep(this.productsFilteringSortingMenuType);
    this.filteringService.addStep(
      this.productsFilteringSortingMenuType,
      selectedIds,
      this.fromDate
        ? new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day)
        : new Date(),
      this.toDate
        ? new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day)
        : new Date()
    );
    this.productsList = this.filteringService.filter(
      this.productsList
    );

    this.productsListChange.emit(this.productsList);
    this.modelChanged.emit();
  }

  clearAllButtonClick() {
    this.items.forEach(a => (a.check = false));
    this.filteringService.removeStep(this.productsFilteringSortingMenuType);
    this.clearAllFilters.emit();
    this.productsList = this.filteringService.filter(
      this.productsList
    );
    this.productsListChange.emit(this.productsList);
  }

  modalWindowClick(event: any) {
    event.stopPropagation();
  }
}

export class CheckBoxModel {
  name: string;
  id: string;
  check: boolean;

  constructor(id: string, name: string, check: boolean) {
    this.check = check;
    this.id = id;
    this.name = name;
  }
}
