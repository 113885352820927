import { Kam } from '../../models/kam';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KamService } from 'app/core/services/kam.service';

@Component({
  selector: 'app-kam-drop-down',
  templateUrl: './kam-drop-down.component.html',
  styleUrls: ['./kam-drop-down.component.scss'],
})
export class KamDropDownComponent implements OnInit {
  @Input() name: string;
  @Input() standardDropdownIcon: boolean;

  @Input() set selectedKamId(kamId: string) {
    if (kamId) {
      this._selectedKamId = kamId;
      if (this.kams) {
        this.setSelectedKam();
      }
    } else {
      this.selectedKam = new Kam();
    }
  }

  get selectedKamId(): string {
    return this._selectedKamId;
  }

  private _selectedKamId = '';
  @Input() selectedKamFirstName = '';
  @Input() selectedKamSurname = '';
  @Input() selectedKamEmail = '';
  @Input() selectedKamPhone = '';
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() adtCode: string = 'ADT21';
  @Output() selectedKamChange = new EventEmitter();
  @Output() selectedKamIdChange = new EventEmitter();
  @Output() selectedKamFirstNameChange = new EventEmitter();
  @Output() selectedKamSurnameChange = new EventEmitter();
  @Output() selectedKamEmailChange = new EventEmitter();
  @Output() selectedKamPhoneChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();
  @Input() placeholder: string;
  @Input() allowBlank = false;
  kams: Kam[];
  selectedKam: Kam;

  constructor(private kamService: KamService) {
    this.selectedKam = new Kam();
  }

  ngOnInit() {
    this.setKamList();
  }

  onChange() {
    this.selectedKamId = this.selectedKam.userId;
    this.selectedKamFirstName = this.selectedKam.firstName;
    this.selectedKamSurname = this.selectedKam.surname;
    this.selectedKamEmail = this.selectedKam.email;
    this.selectedKamPhone = this.selectedKam.phoneNumber;
    this.selectedKamIdChange.emit(this.selectedKamId);
    this.selectedKamFirstNameChange.emit(this.selectedKamFirstName);
    this.selectedKamSurnameChange.emit(this.selectedKamSurname);
    this.selectedKamEmailChange.emit(this.selectedKamEmail);
    this.selectedKamPhoneChange.emit(this.selectedKamPhone);
    this.selectedKamChange.emit(this.selectedKam);
  }

  setKamList() {
    this.kamService.getUserListByADTCode(this.adtCode).subscribe(result => {
      result.sort(this.compareKam);
      this.kams = result;
      this.setSelectedKam();
    });
  }

  compareKam(kam1: Kam, kam2: Kam): number {
    if (kam1.firstName === kam2.firstName) {
      return kam1.surname > kam2.surname ? 1 : -1;
    }
    return kam1.firstName > kam2.firstName ? 1 : -1;
  }

  setSelectedKam() {
    if (this.selectedKamId) {
      this.selectedKam = this.kams.find(x => x.userId.toString() === this.selectedKamId.toString());
    }
  }
}
