import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PermissionEnum } from '@onepoint/shared/models/permission.enum';
import { MasterListService } from '@onepoint/core/services/masterlist.service';
import { OnepointModalService, Size } from '@onepoint/core/services/onepoint.modal.service';
import { PermissionService } from '@onepoint/core/services/permission.service';
import { Dictionary } from '@onepoint/shared/models/dictionary';
import { ContactRoleMenuType } from '@onepoint/master-lists/contact-role/contact-role-menu-type';
import { MasterlistContactRoleModel } from '@onepoint/shared/models/masterlist-contact-role-model';
import * as _ from 'lodash';

@Component({
  selector: 'app-masterlist-contact-role',
  templateUrl: './masterlist-contact-role.component.html',
  styleUrls: ['./masterlist-contact-role.component.scss']
})
export class MasterlistContactRoleComponent implements OnInit {
  @ViewChild('MasterListModal')
  private masterListModalTemplate: TemplateRef<any>;

  masterList: MasterlistContactRoleModel[];
  masterListFiltered: MasterlistContactRoleModel[];
  masterListType: MasterlistContactRoleModel;
  showMenu: Map<ContactRoleMenuType, boolean> = new Map<ContactRoleMenuType, boolean>();

  statusTypes: Dictionary[] = [
    { id: 'all', name: 'All' },
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' }
  ];

  error: string;
  canUpdate: boolean;
  isModelChanged: boolean;
  selectedStatus: string = 'all';
  newContactRole: boolean = false;

  constructor(
    private masterListService: MasterListService,
    private onepointModalService: OnepointModalService,
    private permissionService: PermissionService
  ) {
  }

  ngOnInit(): void {
    this.loadMasterList();
    this.permissionService.permissionsReadyNotification.subscribe(
      ignore => (this.canUpdate = this.permissionService.checkUserPermission(PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE))
    );
  }

  private loadMasterList() {
    this.masterListService.getContactRoleList().subscribe(result => {
      this.masterList = result;
      this.masterListFiltered = result;
    });
  }

  selectedStatusTypeChanged(status: string) {
    this.masterListFiltered = this.filterByStatus(status);
  }

  filterByStatus(status: string): MasterlistContactRoleModel[] {
    if (status === 'all') {
      return this.masterList;
    }

    const now = Date.now();
    this.masterList.forEach(rt => {
        rt.activateOn = new Date(rt.activateOn);
        rt.deactivateOn = rt.deactivateOn ? new Date(rt.deactivateOn) : null;
        rt.active = rt.activateOn.getTime() < now && (rt.deactivateOn == null || rt.deactivateOn.getTime() > now)
      }
    );

    return this.masterList.filter(item => (status === 'active' ? item.active : !item.active));
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  addNew() {
    this.newContactRole = true;
    this.masterListType = new MasterlistContactRoleModel();
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.LARGE, 'contact-role-modal-container');

  }

  edit(item: MasterlistContactRoleModel) {
    this.newContactRole = false;
    this.masterListType = _.clone(item);
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.NORMAL, 'contact-role-modal-container');
  }

  reload() {
    this.loadMasterList();
  }

  showFilteringSortingMenu(filteringSortingMenuType: ContactRoleMenuType) {
    this.showMenu.forEach((value, key) => {
      if (key !== filteringSortingMenuType) {
        this.showMenu.set(key, false);
      }
    });
    this.showMenu.set(filteringSortingMenuType, !this.showMenu.get(filteringSortingMenuType));
  }

  get ContactRoleMenuType() {
    return ContactRoleMenuType;
  }

  isShowMenu(filteringSortingMenuType: ContactRoleMenuType) {
    return this.showMenu.get(filteringSortingMenuType);
  }
}
