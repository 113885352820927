<div class="page-container">
  <div class="page-header header">
    <button class="primary-button button" (click)="addNewSlaRule()">Add new SLA exception</button>
  </div>

  <div class="page-content-container">
    <table class="primary-table sos-services-table">
      <thead>
      <tr>
        <td sortColumn [sortKey]="'name'" [data]="slaRules">
          Name
        </td>
        <td sortColumn [sortKey]="'type'" [data]="slaRules">
          Type
        </td>
      </tr>
      </thead>
      <tbody class="theBody">
      <ng-container *ngFor="let slaRule of slaRules; index as i">
        <tr (click)="showExistingSlaRule(slaRule.id)">
          <td>
            {{ slaRule.name }}
          </td>
          <td>
            {{ slaRule.type }}
          </td>
      </ng-container>
      </tbody>
    </table>
  </div>
</div>

<app-add-sla-rule #modal (addSlaRule)="reloadSlaRules()"></app-add-sla-rule>
