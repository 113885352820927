import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {DictionaryService} from '@onepoint/core/services/dictionary.service';
import {Contract} from '@onepoint/shared/models/contract';
import {OnepointModalService, Size} from '@onepoint/core/services/onepoint.modal.service';
import {CustomerSlaAgreementService} from "@onepoint/core/services/customer-sla-agreement.service";
import {CustomerSlaAgreement} from "@onepoint/shared/models/customer-sla-agreement";

@Component({
  selector: 'app-view-edit-customer-sla-agreement',
  templateUrl: './view-edit-customer-sla-agreement.component.html',
  styleUrls: ['./view-edit-customer-sla-agreement.component.scss'],
})
export class ViewEditCustomerSlaAgreementComponent{
  @ViewChild('content', { static: false }) private template: TemplateRef<any>;
  @Output() reloadCustomerSlaAgreements = new EventEmitter();
  @Input() contract: Contract;
  customerSlaAgreement: CustomerSlaAgreement;
  saveEnabled: boolean = true;

  constructor(
    private modalService: OnepointModalService,
    private dictionaryService: DictionaryService,
    private customerSlaAgreementService: CustomerSlaAgreementService
  ) {}

  openNew() {
    this.customerSlaAgreementService.getCustomerSlaAgreementPrototype(this.contract.id).subscribe(
      result =>{
        this.customerSlaAgreement = result;
        this.open();
      }
    )
  }

  openExisting(customerSlaAgreement: CustomerSlaAgreement) {
    this.customerSlaAgreement = customerSlaAgreement;
    this.open();
  }

  open() {
    this.modalService.open(this.template, Size.LARGE);
    this.allowSubmit();
  }

  close() {
    this.reloadCustomerSlaAgreements.emit();
    this.modalService.dismissAll();
  }

  allowSubmit() {
    this.saveEnabled = false;
    if(this.customerSlaAgreement.name){
      this.saveEnabled = true;
    }
  }

  saveCustomerSlaAgreement() {
    this.customerSlaAgreementService.saveCustomerSlaAgreement(this.customerSlaAgreement).subscribe(
      result =>{
        this.close();
      }
    )
  }
}
