<div class="page-container">
    <div class="page-header header">
      <div class="filter-container">
        <div class="filter-element">
          <app-drop-down [(selectedItemId)]="selectedStatus"
                         [items]="statusTypes"
                         [name]="'Status'"
                         (selectedItemIdChange)="reload()">
          </app-drop-down>
        </div>
        <div class="filter-element">
          <app-input [name]="'Number'" [type]="'number'" [(value)]="this.erpIdSearch" (focusOut)="reload()"></app-input>
        </div>
        <div class="filter-element">
          <app-input [name]="'Name'" [(value)]="this.nameSearch" (focusOut)="reload()"></app-input>
        </div>
      </div>
      <div class="paging-container">
        <mat-paginator [length]="totalElements"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="[50, 100, 150]"
                       [hidePageSize]="true"
                       (page) = "pagingUpdate($event)"
                       aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
    <div class="page-content-container">
        <table class="primary-table sos-services-table">
            <thead>
              <tr>
                <td (click)="sort('erpId')">Creditor number</td>
                <td (click)="sort('name')">Creditor name</td>
                <td (click)="sort('currency')">Creditor currency</td>
                <td (click)="sort('iban')">IBAN</td>
                <td (click)="sort('swift')">SWIFT</td>
                <td (click)="sort('bankAccount')">Account</td>
                <td (click)="sort('paymentTermsDesc')">Terms</td>
                <td (click)="sort('alias')">Alias</td>
                <td (click)="sort('segmentDesc')">Segment</td>
                <td (click)="sort('erpLastUpdated')">Last Updated</td>
              </tr>
            </thead>
            <tbody class="theBody">
              <ng-container *ngFor="let creditor of creditors; index as i">
                <tr [class.disabled-creditor]="!creditor.erpActive">
                  <td>{{ creditor.erpId }}</td>
                  <td>{{ creditor.name }}</td>
                  <td>{{ creditor.currency }}</td>
                  <td>{{ creditor.iban }}</td>
                  <td>{{ creditor.swift }}</td>
                  <td>{{ creditor.bankAccount }}</td>
                  <td>{{ creditor.paymentTermsDesc }}</td>
                  <td>{{ creditor.alias }}</td>
                  <td>{{ creditor.segmentDesc }}</td>
                  <td>{{ creditor.erpLastUpdated | momentFormat: 'DD MMM YYYY HH:mm' : 'secca' }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
    </div>
</div>
