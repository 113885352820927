import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input,
} from "@angular/core";
import { SosServicesService } from "app/core/services/sos-services.service";
import { NewSosServicesComponent } from "./new-sos-services/new-sos-services.component";
import { SosServices } from "app/shared/models/sos-services";
import { SosServicesTableComponent } from "../sos-services-table/sos-services-table.component";

@Component({
  selector: "app-sos-services-summary",
  templateUrl: "./sos-services-summary.component.html",
  styleUrls: ["./sos-services-summary.component.scss"],
})
export class SosServicesSummaryComponent implements OnInit {
  @ViewChild("newSosServicesComponent")
  newSosServicesComponent: NewSosServicesComponent;
  @ViewChild("sosServicesTable")
  sosServicesTable: SosServicesTableComponent;
  @Input() sosServices: SosServices;

  total: number;
  searchText: string;

  constructor(private sosServicesService: SosServicesService) {}

  ngOnInit() {
    this.sosServicesService.updateSosServiceCount.subscribe(
      result => {
        this.total = result;
      }
    );
  }

  textValueChange() {
    this.sosServicesTable.searchTextValueChange(this.searchText);
  }

  addNew() {
    this.newSosServicesComponent.open();
  }
}
