import { ApplicationRef, Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { ProductStatusEnum } from 'app/shared/enums/product-status-enum';
import { CustomerProductRelationOverview } from 'app/shared/models/customer-product-relation-overview';
import { CustomerProfileRelationOverviewFilterSortEnum } from './customer-profile-relations-overview-sorting-menu/customer-profile-relations-overview-sorting-menu-enum';
import { PolicyLookupMethodService } from '../../../core/services/policy-lookup-method.service';
import * as moment from 'moment';
import { CustomerProductRelationFilterTypeEnum } from 'app/shared/enums/policy/customer-product-relation-filter.type.enum';
import { SplitDateTypeEnum } from 'app/shared/enums/policy/split-date.enum';
import { CustomerProductRelationTypeEnum } from 'app/shared/enums/policy/customer-product-relation-type.enum';
import {CustomerProductRelationOverviewUpdateService } from '../../../core/services/customer-product-relation-overview-update.service';
import {Subscription} from 'rxjs';
import { CustomerProfileRelationsOverviewSortingMenuComponent } from './customer-profile-relations-overview-sorting-menu/customer-profile-relations-overview-sorting-menu.component';
import { Dictionary } from 'app/shared/models/dictionary';
import { DictionaryService } from 'app/core/services/dictionary.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-customer-profile-relations-overview',
  templateUrl: './customer-profile-relations-overview.component.html',
  styleUrls: ['./customer-profile-relations-overview.component.scss']
})
export class CustomerProfileRelationsOverviewComponent implements OnInit {
  @ViewChildren(CustomerProfileRelationsOverviewSortingMenuComponent)
    filteringComponents: QueryList<CustomerProfileRelationsOverviewSortingMenuComponent>;

  customerProfileRelationOverviewFilterSortType: CustomerProfileRelationOverviewFilterSortEnum;
  statusFilterValue: ProductStatusEnum;
  searchText: string;
  selectedProductRelation: number;
  dropDownCustomerProductRelationFilterList: Dictionary[] = [];
  selectedFilterType: CustomerProductRelationFilterTypeEnum;
  showFilterMenu: boolean[] = [];
  subscription: Subscription;
  customerProductRelationOverviewList: CustomerProductRelationOverview[];
  filteredCustomerProductRelationOverviewList: CustomerProductRelationOverview[];
  searchResultCustomerProductRelationOverviewList: CustomerProductRelationOverview[];


  splitDateTypes = new Map<string, string>([
    [SplitDateTypeEnum.INCIDENT_DATE, 'Incident date'],
    [SplitDateTypeEnum.REPORT_DATE, 'Report date']
  ]);

  creditCardTypes = new Map<string, string>([
    [CustomerProductRelationTypeEnum.BUSINESS_TRAVEL, 'Business travel'],
    [CustomerProductRelationTypeEnum.CREDIT_CARD, 'Credit card'],
    [CustomerProductRelationTypeEnum.EXPATRIATE_INSURANCE, 'Expatriate insurance'],
    [CustomerProductRelationTypeEnum.GROUP_INSURANCE, 'Group']
  ]);

  constructor(private policyLookupMethodService: PolicyLookupMethodService,
              private dictionaryService: DictionaryService,
              private customerProductRelationOverviewUpdateService: CustomerProductRelationOverviewUpdateService,
              private applicationRef: ApplicationRef,
              private router: Router) { }



  ngOnInit() {
    this.dropDownCustomerProductRelationFilterList = this.dictionaryService.getCustomerProductRelationFilterTypesForSearch();
    this.selectedFilterType = CustomerProductRelationFilterTypeEnum.ACTIVE_FUTURE;
    this.getAllCustomerProductRelations();
  }

  getAllCustomerProductRelations() {
    this.subscription = this.policyLookupMethodService.getAllCustomerProductRelations()
    .subscribe( result => {
        this.customerProductRelationOverviewList = result;
        this.filterTable();
        this.customerProductRelationOverviewUpdateService.changeMessage(this.searchResultCustomerProductRelationOverviewList);
      });
  }

  get CustomerProfileRelationOverviewFilterSortType() {
    return CustomerProfileRelationOverviewFilterSortEnum;
  }

  filterTable() {
    this.filterActive();
    this.searchTable(this.searchText);
    this.customerProductRelationOverviewUpdateService.changeMessage(this.searchResultCustomerProductRelationOverviewList);
  }

  finishedAndFilteringSorting() {
    this.showFilteringSortingMenu(CustomerProfileRelationOverviewFilterSortEnum.empty);
    this.searchTable(this.searchText);
    this.customerProductRelationOverviewUpdateService.changeMessage(this.searchResultCustomerProductRelationOverviewList);
    this.filteringComponents.forEach(component => component.updatePopulateItems(this.searchResultCustomerProductRelationOverviewList));
  }

  showFilteringSortingMenu(filteringSortingType: CustomerProfileRelationOverviewFilterSortEnum) {
    for (let i = 0; i < this.showFilterMenu.length; i++) {
      if (i !== filteringSortingType.valueOf()) {
        this.showFilterMenu[i] = false;
      }
    }
    this.showFilterMenu[filteringSortingType] = !this.showFilterMenu[filteringSortingType];
  }

  setSelect(value: number) {
    this.selectedProductRelation = value;
  }

  getSelected() {
    return this.selectedProductRelation;
  }

  goToProduct(id) {
    this.router.navigate(['/customers/product/' + id]);
  }

  searchTable(searchText: string) {
    if (this.searchText != null && this.searchText.length > 0) {
      searchText = searchText.toLowerCase();
      this.searchResultCustomerProductRelationOverviewList = this.filteredCustomerProductRelationOverviewList.filter(customerProfileRelatio => {
        return customerProfileRelatio?.customerProductName?.toLowerCase().includes(searchText) ||
               customerProfileRelatio?.insuranceCardLevel?.toLowerCase().includes(searchText) ||
               customerProfileRelatio?.companyPolicyHolder?.toLowerCase().includes(searchText) ||
               customerProfileRelatio?.policyNo?.toLowerCase().includes(searchText) ||
               customerProfileRelatio?.customerProductVersion?.toLowerCase().includes(searchText) ||
               customerProfileRelatio?.bank?.toLowerCase().includes(searchText) ||
               customerProfileRelatio?.bin?.toLowerCase().includes(searchText) ||
               customerProfileRelatio?.customerProfileName?.toLowerCase().includes(searchText) ||
               customerProfileRelatio?.customerRelationLabel?.toLowerCase().includes(searchText);
      });
    } else {
      this.searchResultCustomerProductRelationOverviewList = this.filteredCustomerProductRelationOverviewList;
    }
  }

  filterActive() {
    let today = moment().format('YYYY-MM-DD');
    switch(this.selectedFilterType) {
      case CustomerProductRelationFilterTypeEnum.ACTIVE_FUTURE: {
        this.filteredCustomerProductRelationOverviewList = this.customerProductRelationOverviewList.filter(customerProductRelation => {
          return (moment(customerProductRelation.validTo).isSameOrAfter(today) || !customerProductRelation.validTo);
        });
        break;
      }

      case CustomerProductRelationFilterTypeEnum.PAST: {
        this.filteredCustomerProductRelationOverviewList = this.customerProductRelationOverviewList.filter(customerProductRelation => {
          return (moment(customerProductRelation.validTo).isBefore(today));
        });
        break;
      }

      case CustomerProductRelationFilterTypeEnum.ALL: {
        this.filteredCustomerProductRelationOverviewList = this.customerProductRelationOverviewList;
        break;
      }
    }
  }

  closeFilteringSortingMenu() {
    for (let i = 0; i < this.showFilterMenu.length; i++) {
      if (this.showFilterMenu[i]) {
        this.showFilterMenu[i] = false;
      }
    }
  }

  clearAllFiltering() {
    this.filterTable();
    this.applicationRef.tick();
    this.showFilteringSortingMenu(CustomerProfileRelationOverviewFilterSortEnum.empty);
  }

  clearButton() {
    this.selectedFilterType = CustomerProductRelationFilterTypeEnum.ACTIVE_FUTURE;
    this.searchText = '';
    this.filterTable();
    this.filteringComponents.forEach(component => component.clearPopulateItems(this.searchResultCustomerProductRelationOverviewList));
  }

  closeProductRelation() {
    this.selectedProductRelation = undefined;
  }
}
