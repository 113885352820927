<ng-template #content let-modal>
  <div class="modal-container">
    <div class="new-brand-modal-header">
      <div class="new-brand-title">Add new Brand</div>
      <div class="new-brand-close" (click)="close()">
        <img src="assets/icons/Close.svg" />
      </div>
    </div>
    <div class="new-brand-modal-body">
      <div class="brand-setup">
        Brand setup
      </div>
      <div class="input-title-name">Brand name<span class="star">*</span></div>
      <div class="brand-name-input">
        <app-input (valueChange)="brandNameChanges($event)" (value)="(brand.name)"></app-input>
      </div>
      <div class="input-title-name">Logo<span class="star">*</span></div>
      <app-upload-brand-icon [(iconBlob)]="iconBlob" [showInvalidIconMsg]="showInvalidIconMsg"></app-upload-brand-icon>
      <div class="input-title-name">Responsible KAM<span class="star">*</span></div>
      <div class="responsible-kam-container">
        <app-kam-drop-down
          [(selectedKamId)]="brand.keyAccountManagerUserId"
          [(selectedKamFirstName)]="brand.keyAccountManagerFirstName"
          [(selectedKamSurname)]="brand.keyAccountManagerSurname"
          (selectedKamIdChange)="formChanged()"
        >
        </app-kam-drop-down>
      </div>
      <div class="input-title-name">Internal remarks</div>
      <div class="brand-remarks-input">
        <textarea name="remarks" rows="4" [(ngModel)]="brand.remarks" (ngModelChange)="formChanged()" [maxLength]="1000"></textarea>
      </div>
    </div>
    <div class="new-brand-modal-footer">
      <button class="primary-button" (click)="addNewBrand()" [ngClass]="!isBrandValid && 'button-disabled'">
        Add
      </button>
    </div>
  </div>
</ng-template>
