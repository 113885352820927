import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SettingsService} from './settings.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  CustomerConfiguration,
  CustomerConfigurationAdapter
} from "@onepoint/shared/models/digital-caseflow/customer-configuration";
import {Activity, ActivityAdapter} from "@onepoint/shared/models/digital-caseflow/activity";
import {Flow, FlowAdapter} from "@onepoint/shared/models/digital-caseflow/flow";

const url = 'dcf';

@Injectable({
  providedIn: 'root',
})
export class DigitalCaseflowService extends BaseService {
  constructor(
    private settingsService: SettingsService,
    private http: HttpClient,
    private customerConfigurationAdapter: CustomerConfigurationAdapter,
    private activityAdapter: ActivityAdapter,
    private flowAdapter: FlowAdapter) {
    super(settingsService);
  }

  public getCustomerConfiguration(customerProfileId: number): Observable<CustomerConfiguration[]> {
    return this.http
      .get(this.baseURL + url + "/customer-profile/" + customerProfileId + "/config", {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.customerConfigurationAdapter.adapt(item))));
  }
  public getCustomerConfigurationById(configurationId: number): Observable<CustomerConfiguration> {
    return this.http
      .get(this.baseURL + url + "/customer-configuration/" + configurationId, {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.customerConfigurationAdapter.adapt(item)));
  }
  public updateCustomerConfiguration(configuration: CustomerConfiguration): Observable<CustomerConfiguration> {
    return this.http
      .put(this.baseURL + url + "/customer-configuration", configuration, {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.customerConfigurationAdapter.adapt(item)));
  }
  public getAllCustomerConfiguration(): Observable<CustomerConfiguration[]> {
    return this.http
      .get(this.baseURL + url + "/customer-configuration/all", {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.customerConfigurationAdapter.adapt(item))));
  }

  public getAllActivities(): Observable<Activity[]> {
    return this.http
      .get(this.baseURL + url + "/activity/all", {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.activityAdapter.adapt(item))));
  }
  public getAllValidations(): Observable<Activity[]> {
    return this.http
      .get(this.baseURL + url + "/validation/all", {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.activityAdapter.adapt(item))));
  }
  public getAllChannels(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + url + "/customer-configuration/channel/types", {headers: this.jsonHeaders});
  }

  public updateActivity(activity: Activity): Observable<Activity> {
    return this.http.put<Activity>(this.baseURL + url + '/activity', activity, { headers: this.jsonHeaders });
  }

  public getExectutionTypes(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + url + "/activity/execution-types", {headers: this.jsonHeaders});
  }
  public getStartingTypes(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + url + "/activity/starting-types", {headers: this.jsonHeaders});
  }
  public getActivityValidationTypes(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + url + "/activity/validation-types", {headers: this.jsonHeaders});
  }
  public getValidationTypes(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + url + "/validation/types", {headers: this.jsonHeaders});
  }
  public getActivityInputServiceOrderCreationTypes(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + url + "/activity/input/serviceOrderCreationTypes", {headers: this.jsonHeaders});
  }
  public getActivityInputStakeholderTypes(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + url + "/activity/input/stakeholderTypes", {headers: this.jsonHeaders});
  }
  public getActivityInputServiceTypes(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + url + "/activity/input/serviceOrderTypes", {headers: this.jsonHeaders});
  }
  public getActivityInputHandlingAreaTypes(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + url + "/activity/input/handlingAreaTypes", {headers: this.jsonHeaders});
  }
  public getActivityInputHandlingAreaStatus(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + url + "/activity/input/handlingAreaStatus", {headers: this.jsonHeaders});
  }
  public getActivityInputSanctionCheckTypes(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + url + "/activity/input/sanctionCheckTypes", {headers: this.jsonHeaders});
  }
  public getActivityInputSanctionActionTypes(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + url + "/activity/input/sanctionActionTypes", {headers: this.jsonHeaders});
  }
  public getTaskAssigneeTypes(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + url + "/activity/input/taskAssigneeTypes", {headers: this.jsonHeaders});
  }
  public isActivityDeletionPossible(id: number): Observable<boolean> {
    return this.http.get<boolean>(this.baseURL + url + "/activity/"+id+"/deleteStatus", {headers: this.jsonHeaders});
  }
  public deleteActivity(id: number): Observable<boolean> {
    return this.http.delete<any>(this.baseURL + url + "/activity/"+id, {headers: this.jsonHeaders});
  }
  public getRequiredActivityInput(activityType: string): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + url + "/activity/"+activityType+"/input/requirements", {headers: this.jsonHeaders});
  }
  public getRequiredValidationInput(validationType: string): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + url + "/validation/"+validationType+"/input/requirements", {headers: this.jsonHeaders});
  }
  public getValidationDescription(validationType: string): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + url + "/validation/"+validationType+"/description",{headers: this.jsonHeaders});
  }
  public isValidationDeletionPossible(id: number): Observable<boolean> {
    return this.http.get<boolean>(this.baseURL + url + "/validation/"+id+"/deleteStatus", {headers: this.jsonHeaders});
  }
  public updateValidation(activity: Activity): Observable<Activity> {
    return this.http.put<Activity>(this.baseURL + url + '/validation', activity, { headers: this.jsonHeaders });
  }
  public deleteValidation(id: number): Observable<boolean> {
    return this.http.delete<any>(this.baseURL + url + "/validation/"+id, {headers: this.jsonHeaders});
  }
  public getAllFlows(): Observable<Flow[]> {
    return this.http
      .get(this.baseURL + url + "/flow/all", {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.flowAdapter.adapt(item))));
  }

  public createCustomerConfiguration(name: string): Observable<CustomerConfiguration> {
    return this.http
      .put(this.baseURL + url + "/customer-configuration/create", name, {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.customerConfigurationAdapter.adapt(item)));
  }
}
