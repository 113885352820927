import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SupplierProfileService } from 'app/core/services/supplier-profile.service';
import { AutoUnsubscribe } from 'app/shared/decorators/auto-unsubscribe';
import { Dictionary } from 'app/shared/models/dictionary';
import { DictionaryWithType } from 'app/shared/models/dictionary-with-type';
import { SupplierProfile, SupplierProfilePartner } from 'app/shared/models/supplier-profile';
import { Subscription } from 'rxjs';
import {SimpleErpCreditor} from "@onepoint/shared/models/simple-erp-creditor";
import {ErpCreditor} from "@onepoint/shared/models/erp-creditor";
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
@Component({
  selector: 'app-edit-supplier-profile',
  templateUrl: './edit-supplier-profile.component.html',
  styleUrls: ['./edit-supplier-profile.component.scss'],
})
@AutoUnsubscribe
export class EditSupplierProfileComponent implements OnInit {
  private _supplierProfile: SupplierProfile;
  @Input() set supplierProfile(value: SupplierProfile) {
    this._supplierProfile = value;
    this.primaryErpCreditors = value?.primaryErpCreditor ? [value.primaryErpCreditor] : [];
    this.initSupplierProfilePartners();
  }
  get supplierProfile() {
    return this._supplierProfile;
  }

  @Input() readonly: boolean = false;
  @Input() allErpCreditors: SimpleErpCreditor[];
  @Output() supplierProfileChanged = new EventEmitter();

  supplierTypes: DictionaryWithType[];
  partners: Dictionary[];
  private _allSupplierProfiles: SupplierProfile[];
  supplierProfilePartners: SupplierProfilePartner[] = [];
  primaryErpCreditors: ErpCreditor[];
  supoTypeSubscription: Subscription;
  supplierProfileSubscription: Subscription;
  validationError: string;

  constructor(private supplierProfileService: SupplierProfileService) {}

  ngOnInit(): void {
    this.supoTypeSubscription = this.supplierProfileService.getSupoTypeList().subscribe(types => {
      this.supplierTypes = types;
      this.setPartnerList();
    });
    this.supplierProfileSubscription = this.supplierProfileService.getAllSupplierProfiles().subscribe(result => {
      if (result === undefined) {
        this.supplierProfileService
          .getAll()
          .subscribe(supplierProfiles => this.supplierProfileService.setAllSupplierProfiles(supplierProfiles));
      } else {
        this._allSupplierProfiles = result;
        this.setPartnerList();
      }
    });
  }

  setPartnerList() {
    if (this._allSupplierProfiles && this.supplierTypes) {
      const supplierTypeId = this.supplierTypes.find(st => st.type === 'PARTNER')?.id;
      this.partners = [];
      this._allSupplierProfiles
        .filter(p => p.type === supplierTypeId && p.id !== this.supplierProfile?.id)
        .forEach(p => this.partners.push(new Dictionary(p.id.toString(), p.supplierProfileName)));
      this.supplierProfileSubscription?.unsubscribe();
      this.supoTypeSubscription?.unsubscribe();
    }
  }

  deleteSupplierProfilePartner(index: number) {
    this.supplierProfilePartners.splice(index, 1);
    this.supplierProfilePartnerDataChanged();
  }

  dataChanged() {
    this.supplierProfileChanged.emit();
  }

  supplierProfilePartnerDataChanged() {
    const emptyEntry = this.supplierProfilePartners.find(partner => !partner.supplierProfilePartnerId || !partner.startDate);
    if ( !emptyEntry ) {
      this.supplierProfilePartners.push(new SupplierProfilePartner({supplierProfileId: this.supplierProfile.id}));
    }
    this.sortProfilePartners(this.supplierProfilePartners);
    this.supplierProfile.supplierProfilePartners = this.getSupplierProfilePartners();
    this.dataChanged();
  }

  primaryErpCreditorChanged(item: any) {
    if(item.length > 0){
      this.supplierProfile.primaryErpCreditor = item[0];
    }else{
      this.supplierProfile.primaryErpCreditor = null;
    }
    this.dataChanged();
  }

  isSupplierPartnerProfileEmpty(partner: SupplierProfilePartner) {
    return !partner.supplierProfilePartnerId && !partner.startDate && !partner.endDate;
  }

  validate(): boolean {
    this.validationError = undefined;
    return this.validateSupplierProfilePartners();
  }

  validateSupplierProfilePartners(): boolean {    
    let last;
    for (let partner of this.supplierProfile.supplierProfilePartners || []) {
      if (this.isSupplierPartnerProfileEmpty(partner)) {
        continue;
      }
      if ( !!partner.supplierProfilePartnerId && !partner.startDate  ) {
        this.validationError = 'Partner start date is mandatory.';
        return false;
      }
      if ( !!partner.startDate && !partner.supplierProfilePartnerId ) {
        this.validationError = 'Parter profile is mandatory.';
        return false;
      }
      if ( !!partner.startDate && !!partner.endDate && moment(partner.startDate).startOf('day').isAfter(moment(partner.endDate).startOf('day')) ) {
        this.validationError = 'Partner has a start date that is after an end date.';
        return false;
      }
      if (!!last) {
        if ( !last.endDate && !!partner.startDate ) {
          this.validationError = 'Partners have overlapping periods.';
          return false;
        }
        if ( moment(last.endDate).startOf('day').isSameOrAfter(moment(partner.startDate).startOf('day')) ) {
          this.validationError = 'Partners have overlapping periods.';
          return false;
        }
      }
      last = partner;
    }
    return true;
  }

  get partnersWithEmpty() {
    return !!this.partners && this.partners.length > 0 ? [{id: null, name: ''}, ...this.partners] : [];
  }

  private initSupplierProfilePartners() {
    this.supplierProfilePartners = this.supplierProfile?.supplierProfilePartners?.map(partner => _.clone(partner)) || [];
    if ( !this.readonly ) {
      this.supplierProfilePartners.push(new SupplierProfilePartner({supplierProfileId: this.supplierProfile.id}));
    }
    this.sortProfilePartners(this.supplierProfilePartners);
  }

  private getSupplierProfilePartners() {
    const [last] = this.supplierProfilePartners.slice(-1);
    const supplierProfiles = this.isSupplierPartnerProfileEmpty(last) ? this.supplierProfilePartners.slice(0,-1) : this.supplierProfilePartners.slice(0);
    return supplierProfiles;
  }

  private sortProfilePartners(supplierProfiles: SupplierProfilePartner[]) {
    supplierProfiles.sort((a,b) => {
      if (!!a.startDate && !!b.startDate) {
        return moment(a.startDate).startOf('day').unix() - moment(b.startDate).startOf('day').unix();
      }
      else {
        return !!a.startDate ? -1 : 1;
      }
    })
  }
}
