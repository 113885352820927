<div class="page-container">
  <div class="page-header justify-content-between no-gutters">
    <div class="col-4 my-auto counts-container">
      <div class="count-container">
        <span>Suppliers</span> <span>{{ total }}</span>
      </div>
    </div>
    <div class="search-container my-auto">
      <app-input withImage="true" [(value)]="searchText" (valueChange)="textValueChange()"></app-input>
    </div>
    <button class="primary-button my-auto" *permission='PermissionEnum.SUPPLIER_CREATE' (click)="addNew()">Add new supplier</button>
  </div>

  <div class="suppliers-container">
    <div class="suppliers-table">
      <app-suppliers-table #supplierTable></app-suppliers-table>
    </div>
  </div>
  <app-new-supplier-profile #newSupplierProfile></app-new-supplier-profile>
</div>
