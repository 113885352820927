import { Adapter } from '../interfaces/adapter';
import { Injectable } from '@angular/core';
import { CustomerRelationLabelStatusEnum } from '../enums/customer-relation-label-status.enum';

export class CustomerRelationLabel {
  id: number;
  name: string;
  remarks: string;
  status: CustomerRelationLabelStatusEnum;


  public constructor(init?: Partial<CustomerRelationLabel>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CustomerRelationLabelAdapter implements Adapter<CustomerRelationLabel> {
  adapt(item: any): CustomerRelationLabel {
    return new CustomerRelationLabel({
        id: item.id,
        name: item.name ? item.name : '',
        remarks: item.remarks ? item.remarks : '',
        status: item.status,
    });
  }
}
