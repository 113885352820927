import * as moment from 'moment';
import { EventCategory } from './enums';
export class MasterListIncidentEventModel {
  id: string;
  businessKey: string;
  name: string;
  usedInRefund: boolean;
  usedInSupplierInvoice: boolean;
  partnerOnly: boolean;
  shareOfCostControl: boolean;
  description: string;
  uiSortIndex: number;
  activateOn: Date;
  deactivateOn: Date;
  dateOfEvent: Date;
  category: string;
  active: boolean;
  modifiedOn: Date;
  modifiedBy: string;
  hiddenInEventDropDown: boolean;

  public constructor(init?: Partial<MasterListIncidentEventModel>) {
    Object.assign(this, init);
  }
}