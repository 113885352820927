import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Product } from '../../../../shared/models/product';
import { ProductService } from '../../../../core/services/product.service';
import { ProfileProductsAddComponent } from '../profile-products-add/profile-products-add.component';
import { PermissionEnum } from '@onepoint/shared/models/permission.enum';

@Component({
  selector: 'app-product-terms',
  templateUrl: './product-terms.component.html',
  styleUrls: ['./product-terms.component.scss']
})
export class ProductTermsComponent implements OnInit {
  product: Product;
  selectedVersionProductId: number;
  @Input() profileId: string;
  @Output() productChange = new EventEmitter<Product>();

  @ViewChild('profileProductsAddComponent') profileProductsAddComponent: ProfileProductsAddComponent;

  otherVersions: Product[];

  constructor(private productService: ProductService) {
  }

  @Input() set selectedProduct(p: Product) {
    this.product = p;
    this.selectedVersionProductId = this.isChildProduct(this.product) ? this.product.parentId : this.product.id;
  }

  ngOnInit() {
    this.productService.getProductVersions(this.selectedVersionProductId.toString())
      .subscribe(result => this.otherVersions = result, error => console.error(error));
  }

  addNewVersion() {
    this.productService.getProduct(this.product.id.toString()).subscribe(
      result => this.profileProductsAddComponent.openCreateNewVersion(result),
      error => console.error(error));
  }

  onChange(selected: number) {
    this.productService.getProduct(selected.toString())
      .subscribe(item => {
        this.productChange.emit(item);
      }, error => console.error(error));
  }

  onNewVersionSaved(product: Product) {
    this.otherVersions.push(product);
    this.productChange.emit(product);
  }

  isChildProduct(product: Product) {
    return product.parentId != null;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
