import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  updateProfile: BehaviorSubject<boolean> = new BehaviorSubject(false);
  updateBrand: BehaviorSubject<boolean> = new BehaviorSubject(false);

  updateBrandListWhenBrandIsDeleted: BehaviorSubject<boolean> = new BehaviorSubject(false);
  updateCommonCustomerListWhenCommonCustomerIsDeleted: BehaviorSubject<boolean> = new BehaviorSubject(false);

  changedProfile() {
    this.updateProfile.next(true);
  }
  changedBrand() {
    this.updateBrand.next(true);
  }
  deletedBrand() {
    this.updateBrandListWhenBrandIsDeleted.next(true);
  }
  deletedCommonCustomer() {
    this.updateBrandListWhenBrandIsDeleted.next(true);
  }
}
