<ng-template #content let-modal>
  <div class="modal-container">
    <div class="new-modal-header">
      <div class="new-title">Add new Supplier Profile</div>
      <div class="new-close"><img (click)="close()" src="assets/icons/Close.svg" alt="Close" /></div>
    </div>
    <div class="new-modal-body">
      <app-edit-supplier-profile #editSupplierComponent
        [(supplierProfile)]="supplierProfile"
        [allErpCreditors]="allErpCreditors"
        (supplierProfileChanged)="handleChange(editSupplierComponent)"
      ></app-edit-supplier-profile>
      <div class="container">
        <div class="row">
          <div class="col-sm">
            <div class="new-modal-footer">
              <button
                class="primary-button"
                (click)="addNewSupplierProfile()"
                [ngClass]="!isValid && 'button-disabled'"
                [disabled]="!isValid"
              >
                Add
              </button>
              <div *ngIf="!!editSupplierComponent?.validationError" class="validation-error">
                <span>{{editSupplierComponent.validationError}}</span>
              </div>
            
            </div>
          </div>
          <div class="col-sm"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
