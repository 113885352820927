<div class="edit-sos-services">
  <div class="sos-services-setup">SOS Services Settings</div>
  <div class="container-fluid" *ngIf="sosServices">
    <div class="row">
      <div class="col-sm">
        <div class="sos-services-input">
          <app-input name="SOS services name" [required]="!readonly" [(value)]="sosServices.name"
            (valueChange)="formChanged()" [disabled]="readonly"></app-input>
        </div>
        <div class="sos-services-input">
          <app-input name="SOS Service ID" [required]="!readonly" [(value)]="sosServices.sosServiceId"
            (valueChange)="formChanged()" [disabled]="readonly" [placeholder]="'Unique SOS Service ID'"></app-input>
        </div>
        <div class="sos-services-input">
          <app-drop-down name="Service category" [required]="!readonly" [items]="sosServicesCategoryList"
            [(selectedItemId)]="sosServices.sosServicesCategory" (selectedItemIdChange)="sosServicesCategoryChanged()"
            [disabled]="readonly">
          </app-drop-down>
        </div>
        <div class="sos-services-input">
          <app-input-calendar [required]="!readonly" [withImage]="true" [selectMode]="'single'"
            [maxDate]="sosServices.validEnd" [name]="'Start date'" [(dateTimeRange)]="sosServices.validStart"
            [disabled]="readonly" (dateTimeRangeChange)="formChanged()">
          </app-input-calendar>
        </div>
        <div class="sos-services-input">
          <app-input-calendar [required]="false" [withImage]="true" [selectMode]="'single'"
            [minDate]="sosServices.validStart" (dateTimeRangeChange)="formChanged()" [name]="'End date'"
            [(dateTimeRange)]="sosServices.validEnd" [disabled]="readonly">
          </app-input-calendar>
        </div>
        <div class="sos-services-input">
          <div class="input-title-name">Fee priority<span class="star" *ngIf="!readonly">*</span></div>
          <input type="number" min="0" max="9999" [(ngModel)]="sosServices.feePriority" class="sos-services-input-field"
            [disabled]="readonly" (change)="formChanged()" (keypress)="onlyNumericKeys($event)" />
        </div>
        <div class="sos-services-input">
          <div class="input-title-name">Included in standard SOS services</div>
          <input type="checkbox" name="tc" [(ngModel)]="sosServices.includedInStandard" class="sos-services-checkbox"
            [disabled]="readonly" (change)="formChanged()" />
        </div>
        <div class="sos-services-input">
          <div class="input-title-name">More than 24 hours duration mandatory in Treatment SO</div>
          <input type="checkbox" [(ngModel)]="sosServices.multipleDateRange" class="sos-services-checkbox"
            [disabled]="readonly" (change)="formChanged()" />
        </div>
        <div class="sos-services-input">
          <div class="input-title-name">Ignore service items when Cost Control handling area closed/not activated</div>
          <input type="checkbox" [(ngModel)]="sosServices.ignoreServiceItems" class="sos-services-checkbox"
            [disabled]="readonly" (change)="formChanged()" />
        </div>

        <div class="sos-services-input">
          <app-drop-down name="Service item override" [required]="!readonly"
                         [items]="serviceItemTypes"
                         [(selectedItemId)]="sosServices.serviceItemOverride"
                         (selectedItemIdChange)="formChanged()"
                         [disabled]="readonly"
          ></app-drop-down>
        </div>

        <div class="sos-services-input">
          <app-drop-down-multiselect name="VAT ERP Client" [required]="false" [dropdownItemList]="erpClientList"
            [(selectedItems)]="sosServices.vatErpClient"
            (selectedItemsChange)="multiselectChanged('vatErpClient', $event)" [disabled]="readonly">
          </app-drop-down-multiselect>
        </div>
        <div *ngIf="
            sosServices.sosServicesCategoryEnum !== SosServicesCategoryEnum.SHARE_OF_COST_CONTROL &&
            sosServices.sosServicesCategoryEnum !== SosServicesCategoryEnum.COMMUNICATION_COST &&
            sosServices.sosServicesCategoryEnum !== SosServicesCategoryEnum.BANK_FEE &&
            sosServices.sosServicesCategoryEnum !== SosServicesCategoryEnum.SHARE_OF_RECOVERY
          ">
          <div class="sos-services-input">
            <app-input-money [name]="getPriceFieldTitle(sosServices)" [required]="!readonly"
              [(selectedCurrency)]="sosServices.targetPrice.currency" [(value)]="sosServices.targetPrice.amount"
              [disabled]="readonly" (valueChange)="formChanged()"></app-input-money>
          </div>
          <div class="sos-services-input">
            <div class="input-title-name">{{ getPriceYearFieldTitle(sosServices) }}<span class="star"
                *ngIf="!readonly">*</span></div>
            <input type="number" min="2010" max="2200" [(ngModel)]="sosServices.targetPriceYear"
              class="sos-services-input-field" [disabled]="readonly" (change)="formChanged()"
              (keypress)="onlyNumericKeys($event)" />
          </div>
        </div>
        <div *ngIf="sosServices.sosServicesCategoryEnum == SosServicesCategoryEnum.BANK_FEE" class="bank-fee">
          <div class="row justify-content-between align-items-center bank-fee-label">
            <div class="col-8"><span class="input-name">Bank fees</span><span class="required"
                                                                                       *ngIf="!readonly">*</span></div>
            <div class="col-auto">
              <span class="input-name">Currency</span>
              <select class="money-currency" [disabled]="readonly" [(ngModel)]="sosServices.communicationCostCurrency"
                      (ngModelChange)="formChanged()">
                <option value="DKK" default>DKK</option>
              </select>
            </div>
          </div>
          <table>
            <thead>
            <tr>
              <td>Creditor</td>
              <td>Fee</td>
            </tr>
            </thead>
            <tbody>
            <ng-template ngFor let-bankfee [ngForOf]="sosServices.bankFees">
              <tr>
                <td>{{ bankfee.creditorId + " " + bankfee.creditorName  }}</td>
                <td>
                  <input class="money-amount" currencyMask
                         [(ngModel)]="bankfee.feeAmount" [disabled]="readonly"
                         [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2 }"
                         (ngModelChange)="formChanged()" />
                </td>
              </tr>
            </ng-template>
            </tbody>
          </table>
        </div>
        <div *ngIf="
            sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.ADDITIONAL_MEDICAL_SERVICES ||
            sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.ADDITIONAL_MEDICAL_SERVICES_ESCORT
          ">
          <div class="sos-services-input">
            <app-input name="Default Price Index" [required]="!readonly" [disabled]="true"
              [placeholder]="defaultPriceIndex"></app-input>
          </div>
        </div>
        <div *ngIf="
            sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.SHARE_OF_COST_CONTROL ||
            sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.SHARE_OF_RECOVERY
        ">
          <div class="sos-services-input">
            <div class="input-title-name">Target share %<span class="star" *ngIf="!readonly">*</span></div>
            <input currencyMask [(ngModel)]="sosServices.targetShare" class="sos-services-input-field"
              [disabled]="readonly" (ngModelChange)="formChanged()"
              [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2, max: 100 }" />
          </div>
          <div class="sos-services-input">
            <app-input-money name="Target max cap" [required]="!readonly"
              [(selectedCurrency)]="sosServices.targetMaxCap.currency" [(value)]="sosServices.targetMaxCap.amount"
              [disabled]="readonly" (valueChange)="formChanged()"></app-input-money>
          </div>
        </div>
        <div *ngIf="sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.COMMUNICATION_COST"
          class="communication-cost">
          <div class="row justify-content-between align-items-center communication-cost-label">
            <div class="col-8"><span class="input-name">Communication cost</span><span class="required"
                *ngIf="!readonly">*</span></div>
            <div class="col-auto">
              <span class="input-name">Currency</span>
              <select class="money-currency" [disabled]="readonly" [(ngModel)]="sosServices.communicationCostCurrency"
                (ngModelChange)="formChanged()">
                <option value="DKK" default>DKK</option>
              </select>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <td>Region</td>
                <td>{{ CommunicationCostTypeEnum.OUTGOING_PHONE }}</td>
                <td>{{ CommunicationCostTypeEnum.OUTGOING_SMS }}</td>
                <td>{{ CommunicationCostTypeEnum.MEDICAL_VOICE }}</td>
              </tr>
            </thead>
            <tbody>
              <ng-template ngFor let-continent [ngForOf]="continents">
                <tr>
                  <td>{{ continent.name }}</td>
                  <td>
                    <input class="money-amount" currencyMask
                      [(ngModel)]="getCommunicationCostForContinent(continent).phoneAmount" [disabled]="readonly"
                      [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2 }"
                      (ngModelChange)="formChanged()" />
                  </td>
                  <td>
                    <input class="money-amount" currencyMask
                      [(ngModel)]="getCommunicationCostForContinent(continent).smsAmount" [disabled]="readonly"
                      [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2 }"
                      (ngModelChange)="formChanged()" />
                  </td>
                  <td>
                    <input class="money-amount" currencyMask
                      [(ngModel)]="getCommunicationCostForContinent(continent).medicalAmount" [disabled]="readonly"
                      [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2 }"
                      (ngModelChange)="formChanged()" />
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
          <div class="communication-cost"><span class="input-title-name">Phone minutes</span></div>
          <div class="row">
            <div class="col-4">
              <div class="sos-services-input">
                <div class="input-title-name">Minimum<span class="star" *ngIf="!readonly">*</span></div>
                <input class="money-amount" currencyMask [(ngModel)]="sosServices.communicationCostMinimum"
                  [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2 }" [disabled]="readonly"
                  (ngModelChange)="formChanged()" />
              </div>
            </div>
            <div class="col-4">
              <div class="sos-services-input">
                <div class="input-title-name">Maximum<span class="star" *ngIf="!readonly">*</span></div>
                <input class="money-amount" currencyMask [(ngModel)]="sosServices.communicationCostMaximum"
                  [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2 }" [disabled]="readonly"
                  (ngModelChange)="formChanged()" />
              </div>
            </div>
            <div class="col-4">
              <div class="sos-services-input">
                <div class="input-title-name">No minutes in SECCA<span class="star" *ngIf="!readonly">*</span></div>
                <input class="money-amount" currencyMask [(ngModel)]="sosServices.communicationCostDefault"
                  [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2 }" [disabled]="readonly"
                  (ngModelChange)="formChanged()" />
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-sm">
        <div class="sos-services-input">
          <app-drop-down name="Fee type" [required]="!readonly"
            [items]="feeTypeList"
            [(selectedItemId)]="sosServices.feeType"
            (selectedItemIdChange)="formChanged()"
            [disabled]="
              readonly ||
              sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.SHARE_OF_COST_CONTROL ||
              sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.SHARE_OF_RECOVERY ||
              sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.COMMUNICATION_COST ||
              sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.ADDITIONAL_MEDICAL_SERVICES ||
              sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.ADDITIONAL_MEDICAL_SERVICES_ESCORT ||
              sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.BANK_FEE
            "
          ></app-drop-down>
        </div>
        <div class="sos-services-input">
          <app-drop-down-multiselect name="Case types" class="sos-services-input" [required]="!readonly"
            [dropdownItemList]="caseTypeList" [(selectedItems)]="sosServices.caseType"
            (selectedItemsChange)="multiselectChanged('caseType', $event)" [disabled]="readonly"
          ></app-drop-down-multiselect>
        </div>
        <div class="sos-services-input">
          <app-drop-down-multiselect name="Cause level 1" [required]="!readonly" [dropdownItemList]="causeLevel1List"
            [(selectedItems)]="sosServices.causesLevel1" (selectedItemsChange)="causeChanged('cause', $event)"
            [disabled]="readonly"></app-drop-down-multiselect>
        </div>
        <div class="sos-services-input">
          <app-drop-down-multiselect name="Cause level 2" [required]="!readonly" [dropdownItemList]="causeLevel2List"
            [(selectedItems)]="sosServices.causesLevel2" (selectedItemsChange)="cause2Changed('cause2', $event)"
            [disabled]="readonly"></app-drop-down-multiselect>
        </div>
        <div *ngIf="!isBankFee">
          <div class="sos-services-input">
            <app-drop-down-multiselect name="Commitment types" [required]="!readonly && !isBankFee"
                                       [dropdownItemList]="commitmentTypeList" [(selectedItems)]="selectedCommitmentTypes"
                                       (selectedItemsChange)="commitmentTypesChanged($event)" [disabled]="readonly || isBankFee"></app-drop-down-multiselect>
          </div>
        </div>
        <div *ngIf="!isBankFee && !isRecoveryFee">
          <div class="sos-services-input">
            <app-drop-down-multiselect name="Services" [required]="!readonly && !isBankFee && !isRecoveryFee" [dropdownItemList]="sosServicesTypeList"
                                       [(selectedItems)]="sosServices.sosServicesType"
                                       (selectedItemsChange)="multiselectChanged('sosServicesType', $event)" [disabled]="readonly || isBankFee || isRecoveryFee">
            </app-drop-down-multiselect>
          </div>
          <div *ngIf="sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.ADDITIONAL_MEDICAL_SERVICES_ESCORT"
            class="sos-services-input">
            <app-drop-down name="Medical service options" [required]="!readonly" [items]="medicalServiceOptions"
              [(selectedItemId)]="sosServices.medicalServiceOptions" (selectedItemIdChange)="formChanged()"
              [disabled]="readonly">
            </app-drop-down>
          </div>
          <div class="sos-services-input">
            <app-drop-down-multiselect name="Service items &ndash; supplier invoice" [required]="false" [dropdownItemList]="serviceItemList"
                                       [(selectedItems)]="sosServices.sosServicesItem"
                                       (selectedItemsChange)="multiselectServiceItemChanged($event)" [disabled]="readonly || isBankFee || isRecoveryFee">
            </app-drop-down-multiselect>
          </div>
          <div class="sos-services-input">
            <app-drop-down-multiselect name="Service items &ndash; refund" [required]="false" [dropdownItemList]="serviceItemList"
                                       [(selectedItems)]="sosServices.refundSosServicesItem"
                                       (selectedItemsChange)="multiselectServiceItemChanged($event, 'REFUND')" [disabled]="readonly || isBankFee">
            </app-drop-down-multiselect>
          </div>
          <div class="sos-services-input">
            <div class="input-title-name">Ignore as suggested</div>
            <input type="checkbox" [(ngModel)]="sosServices.ignoreAsSuggested" class="sos-services-checkbox"
                   [disabled]="readonly" (change)="formChanged()" />
          </div>
        </div>
        <div *ngIf="sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.CLAIMS_TRAVEL || sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.BANK_FEE"
             class="sos-services-input">
          <app-drop-down-multiselect
            name="Refund type"
            [required]="!readonly"
            [dropdownItemList]="refundTypeList"
            [(selectedItems)]="sosServices.refundTypes"
            (selectedItemsChange)="formChanged()"
            [disabled]="readonly"
          ></app-drop-down-multiselect>
        </div>
        <div *ngIf="sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.SHARE_OF_RECOVERY"
             class="sos-services-input">
          <app-drop-down-multiselect name="Service items &ndash; recovery" [required]="isRecoveryFee" [dropdownItemList]="serviceItemList"
                                     [(selectedItems)]="sosServices.recoverySosServicesItem"
                                     (selectedItemsChange)="multiselectServiceItemChanged($event, 'REFUND')" [disabled]="readonly">
          </app-drop-down-multiselect>
        </div>
        <div class="sos-services-input">
          <div class="input-title-name">Internal note</div>
          <textarea name="internal note" rows="2" [(ngModel)]="sosServices.internalNote"
            class="sos-services-input sos-services-input-field" [disabled]="readonly"
            (ngModelChange)="formChanged()" [maxLength]="250"></textarea>
        </div>
        <div class="sos-services-input">
          <div class="input-title-name">SOS service description (external)<span class="star" *ngIf="!readonly">*</span>
          </div>
          <textarea name="SOS service description (external)" rows="6" maxlength="2000"
            [(ngModel)]="sosServices.description" class="sos-services-input sos-services-input-field"
            [disabled]="readonly" (keyup)="formChanged()" (change)="formChanged()"></textarea>
        </div>
      </div>
      <div class="col-sm extension-column">
        <div class="extension-types">
          <div class="custom-control extension-radio" *ngFor="let types of ExtensionTypes">
            <input type="radio" [(ngModel)]="sosServices.extensionType" [disabled]="readonly"
                   [value]="types" name="sosService.extensionType" (change)="extensionTypeChanged()" />
            <label class="radio-label">{{types}}</label>
          </div>
        </div>
        <div class="sos-services-input">
          <app-drop-down name="Fee type" [required]="!readonly && sosServices.extensionType == 'OR'"
                         [items]="feeTypeList"
                         [(selectedItemId)]="sosServices.extension && sosServices.extension.feeType"
                         (selectedItemIdChange)="formChanged()"
                         [disabled]="
              readonly ||
              sosServices.extensionType != 'OR' ||
              sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.SHARE_OF_COST_CONTROL ||
              sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.COMMUNICATION_COST ||
              sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.ADDITIONAL_MEDICAL_SERVICES ||
              sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.ADDITIONAL_MEDICAL_SERVICES_ESCORT
            "
          ></app-drop-down>
        </div>
        <div class="sos-services-input">
          <app-drop-down-multiselect name="Case types" class="sos-services-input" [required]="!readonly && sosServices.extensionType == 'OR'"
                                     [dropdownItemList]="caseTypeList"
                                     [(selectedItems)]="sosServices.extension && sosServices.extension.caseType"
                                     (selectedItemsChange)="formChanged()"
                                     [disabled]="readonly || sosServices.extensionType != 'OR'"></app-drop-down-multiselect>
        </div>
        <div class="sos-services-input">
          <app-drop-down-multiselect name="Cause level 1" [required]="!readonly && sosServices.extensionType == 'OR'"
                                     [dropdownItemList]="causeLevel1List"
                                     [(selectedItems)]="sosServices.extension && sosServices.extension.causesLevel1"
                                     (selectedItemsChange)="causeChanged('cause', $event, true)"
                                     [disabled]="readonly || sosServices.extensionType != 'OR'"></app-drop-down-multiselect>
        </div>
        <div class="sos-services-input">
          <app-drop-down-multiselect name="Cause level 2" [required]="!readonly && sosServices.extensionType == 'OR'"
                                     [dropdownItemList]="causeLevel2ExtensionList"
                                     [(selectedItems)]="sosServices.extension && sosServices.extension.causesLevel2"
                                     (selectedItemsChange)="cause2Changed('cause2', $event, true)"
                                     [disabled]="readonly || sosServices.extensionType != 'OR'"></app-drop-down-multiselect>
        </div>

        <div class="sos-services-input">
          <app-drop-down-multiselect name="Commitment types" [required]="!readonly && sosServices.extensionType == 'OR'"
                                     [dropdownItemList]="commitmentTypeList"
                                     [(selectedItems)]="selectedExtensionCommitmentTypes"
                                     (selectedItemsChange)="commitmentTypesChanged($event, true)"
                                     [disabled]="readonly || sosServices.extensionType == 'NONE'"></app-drop-down-multiselect>
        </div>

        <div *ngIf="sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.ADDITIONAL_MEDICAL_SERVICES_ESCORT"
             class="sos-services-input">
          <app-drop-down name="Medical service options" [required]="!readonly && sosServices.extensionType != 'NONE'"
                         [items]="medicalServiceOptions"
                         [(selectedItemId)]="sosServices.extension && sosServices.extension.medicalServiceOptions"
                         (selectedItemIdChange)="formChanged()"
                         [disabled]="readonly || sosServices.extensionType == 'NONE'">
          </app-drop-down>
        </div>

        <div *ngIf="!isBankFee && !isRecoveryFee">
          <div class="sos-services-input">
            <app-drop-down-multiselect name="Services" [required]="!readonly && sosServices.extensionType == 'OR'"
                                       [dropdownItemList]="sosServicesTypeList"
                                       [(selectedItems)]="sosServices.extension && sosServices.extension.sosServicesType"
                                       (selectedItemsChange)="formChanged()"
                                       [disabled]="readonly || sosServices.extensionType == 'NONE'">
            </app-drop-down-multiselect>
          </div>
          <div class="sos-services-input">
            <app-drop-down-multiselect name="Service items &ndash; supplier invoice" [required]="false"
                                       [dropdownItemList]="serviceItemList"
                                       [(selectedItems)]="sosServices.extension && sosServices.extension.sosServicesItem"
                                       (selectedItemsChange)="multiselectServiceItemChanged($event, 'SUPPLIER_INVOICE', true)"
                                       [disabled]="readonly || sosServices.extensionType == 'NONE'">
            </app-drop-down-multiselect>
          </div>
        </div>
        <div
          *ngIf="sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.CLAIMS_TRAVEL || sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.BANK_FEE"
          class="sos-services-input">
          <app-drop-down-multiselect name="Service items &ndash; refund" [required]="false"
                                     [dropdownItemList]="serviceItemList"
                                     [(selectedItems)]="sosServices.extension && sosServices.extension.refundSosServicesItem"
                                     (selectedItemsChange)="multiselectServiceItemChanged($event, 'REFUND', true)"
                                     [disabled]="readonly || sosServices.extensionType == 'NONE'">
          </app-drop-down-multiselect>
        </div>
        <div *ngIf="isRecoveryFee"
             class="sos-services-input">
          <app-drop-down-multiselect name="Service items &ndash; recovery" [required]="isRecoveryFee"
                                     [dropdownItemList]="serviceItemList"
                                     [(selectedItems)]="sosServices.extension && sosServices.extension.recoverySosServicesItem"
                                     (selectedItemsChange)="multiselectServiceItemChanged($event, 'RECOVERY', true)"
                                     [disabled]="readonly || sosServices.extensionType == 'NONE'">
          </app-drop-down-multiselect>
        </div>

        <div class="sos-services-input">
          <div class="input-title-name">Ignore as suggested</div>
          <input type="checkbox" [(ngModel)]="sosServices.extension.ignoreAsSuggested" class="sos-services-checkbox"
                 [disabled]="readonly" (change)="formChanged()" />
        </div>
        <div *ngIf="sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.CLAIMS_TRAVEL"
             class="sos-services-input">
          <app-drop-down-multiselect name="Refund type" [required]="sosServices.extensionType == 'OR'"
                                     [dropdownItemList]="refundTypeList"
                                     [(selectedItems)]="sosServices.extension && sosServices.extension.refundTypes"
                                     (selectedItemsChange)="formChanged()"
                                     [disabled]="readonly || sosServices.extensionType != 'OR'"
          ></app-drop-down-multiselect>
        </div>
      </div>
    </div>
  </div>
</div>
