<div class="page-container">
  <div class="case-processes-toolbar-container">
    <div class="case-processes-toolbar-label">Case processes</div>
    <div class="case-processes-toolbar-elements">
      <div class="case-processes-toolbar-selector">
        <div class="case-processes-toolbar-dropdown">
          <app-drop-down
            [(selectedItemId)]="selectedCaseProcessType"
            [disabled]="false"
            [items]="caseProcessTypes"
            (selectedItemIdChange)="caseProcessTypeSelected()">
          </app-drop-down>
        </div>
        <div class="case-processes-toolbar-spinner">
          <img *ngIf="loadInProgress" src="./assets/icons/waitForSearchresult.gif" alt="Working...">
        </div>
        <div class="checkbox-container">
          <span class="case-process-checkbox"><input type="checkbox" (click)="$event.stopPropagation()" (change)="checkBoxShowActiveChange()" [(ngModel)]="checkboxShowActive"/></span>
          <span class="case-process-label">Show included</span>
        </div>
      </div>
      <div>
        <button class="primary-button save-button" (click)="saveChanges()" [ngClass]="{ 'button-disabled': !isSaveButtonEnabled }" [disabled]="!isSaveButtonEnabled">
          <img *ngIf="saveInProgress" src="./assets/icons/waitForSearchresult.gif" alt="Save changes">
          <span *ngIf="!saveInProgress">Save changes</span>
        </button>
      </div>
    </div>
    <div class="case-processes-description-label">Description</div>
    <div class="case-processes-description-textarea">
      <textarea rows="4" [(ngModel)]="caseProcessTypeDescription" [maxLength]="8000"></textarea>
    </div>
  </div>
  <div *ngIf="selectedCaseProcessType">
    <div>
      <table class="primary-table case-process-profiles-table">
        <thead>
        <tr>
          <td (click)="showFilteringSortingMenu(CustomerProfileCaseProcessesOverviewSortingMenuType.PROFILE_CODE)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Profile code</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[CustomerProfileCaseProcessesOverviewSortingMenuType.PROFILE_CODE] && { display: 'none' }">
              <app-customer-profile-case-processes-overview-sorting-menu
                [sortingKey]="CustomerProfileCaseProcessesOverviewSortingMenuType.PROFILE_CODE"
                [caseProcessProfiles]="filteredCaseProcessProfiles">
              </app-customer-profile-case-processes-overview-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CustomerProfileCaseProcessesOverviewSortingMenuType.COMMON_CUSTOMER)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Common customer</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[CustomerProfileCaseProcessesOverviewSortingMenuType.COMMON_CUSTOMER] && { display: 'none' }">
              <app-customer-profile-case-processes-overview-sorting-menu
                [sortingKey]="CustomerProfileCaseProcessesOverviewSortingMenuType.COMMON_CUSTOMER"
                [caseProcessProfiles]="filteredCaseProcessProfiles">
              </app-customer-profile-case-processes-overview-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CustomerProfileCaseProcessesOverviewSortingMenuType.BRAND_NAME)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Brand name</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[CustomerProfileCaseProcessesOverviewSortingMenuType.BRAND_NAME] && { display: 'none' }">
              <app-customer-profile-case-processes-overview-sorting-menu
                [sortingKey]="CustomerProfileCaseProcessesOverviewSortingMenuType.BRAND_NAME"
                [caseProcessProfiles]="filteredCaseProcessProfiles">
              </app-customer-profile-case-processes-overview-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CustomerProfileCaseProcessesOverviewSortingMenuType.PROFILE_NAME)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Profile name</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[CustomerProfileCaseProcessesOverviewSortingMenuType.PROFILE_NAME] && { display: 'none' }">
              <app-customer-profile-case-processes-overview-sorting-menu
                [sortingKey]="CustomerProfileCaseProcessesOverviewSortingMenuType.PROFILE_NAME"
                [caseProcessProfiles]="filteredCaseProcessProfiles">
              </app-customer-profile-case-processes-overview-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CustomerProfileCaseProcessesOverviewSortingMenuType.INCLUDED)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>
                    <span class="checkbox-container">
                      <input class="applicationCheckbox" type="checkbox" (click)="$event.stopPropagation()" (ngModelChange)="checkBoxAllIncludedChange($event)" [(ngModel)]="checkboxAllIncluded"/>
                    </span>
                    <span class="name-heading">Included</span>
              </span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[CustomerProfileCaseProcessesOverviewSortingMenuType.INCLUDED] && { display: 'none' }">
              <app-customer-profile-case-processes-overview-sorting-menu
                [sortingKey]="CustomerProfileCaseProcessesOverviewSortingMenuType.INCLUDED"
                [caseProcessProfiles]="filteredCaseProcessProfiles">
              </app-customer-profile-case-processes-overview-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CustomerProfileCaseProcessesOverviewSortingMenuType.VALID_FROM)" class="filter-column unsortable-column">
            <div class="sortable">
              <span class="name-heading">Start date</span>
              <span class="checkbox-text-container">
                      <span class="checkbox-container">
                        <input class="applicationCheckbox" type="checkbox" (click)="$event.stopPropagation()" (ngModelChange)="checkBoxEditAllValidFromChange($event)" [(ngModel)]="checkboxEditAllValidFrom"/>
                      </span>
                      <span class="name-heading">Multi edit</span>
                    </span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[CustomerProfileCaseProcessesOverviewSortingMenuType.VALID_FROM] && { display: 'none' }">
              <app-customer-profile-case-processes-overview-sorting-menu
                [sortingKey]="CustomerProfileCaseProcessesOverviewSortingMenuType.VALID_FROM"
                [caseProcessProfiles]="filteredCaseProcessProfiles">
              </app-customer-profile-case-processes-overview-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(CustomerProfileCaseProcessesOverviewSortingMenuType.VALID_TO)" class="filter-column unsortable-column">
            <div class="sortable">
              <span class="name-heading">End date</span>
              <span class="checkbox-text-container">
                    <span class="checkbox-container">
                      <input class="applicationCheckbox" type="checkbox" (click)="$event.stopPropagation()" (ngModelChange)="checkBoxEditAllValidToChange($event)" [(ngModel)]="checkboxEditAllValidTo"/>
                    </span>
                    <span class="name-heading">Multi edit</span>
                  </span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[CustomerProfileCaseProcessesOverviewSortingMenuType.VALID_TO] && { display: 'none' }">
              <app-customer-profile-case-processes-overview-sorting-menu
                [sortingKey]="CustomerProfileCaseProcessesOverviewSortingMenuType.VALID_TO"
                [caseProcessProfiles]="filteredCaseProcessProfiles">
              </app-customer-profile-case-processes-overview-sorting-menu>
            </div>
          </td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let caseProcessProfile of filteredCaseProcessProfiles; index as idx">
          <td class="type-column" (click)="gotoProfile(caseProcessProfile.profileId)">{{caseProcessProfile.profileCode}}</td>
          <td class="type-column" (click)="gotoCommonCustomer(caseProcessProfile.commonCustomerId)">{{caseProcessProfile.commonCustomerName}}</td>
          <td class="type-column" (click)="gotoBrand(caseProcessProfile.brandId)">{{caseProcessProfile.brandName}}</td>
          <td class="type-column" (click)="gotoProfile(caseProcessProfile.profileId)">{{caseProcessProfile.profileName}}</td>
          <td>
            <div class="checkbox-container">
              <input class="case-process-checkbox"
                     type="checkbox"
                     (change)="checkBoxIncludedChange(caseProcessProfile)"
                     [(ngModel)]="caseProcessProfile.included"/>
            </div>
          </td>
          <td class="date-column">
            <app-input-calendar
              [withImage]="true"
              [(dateTimeRange)]="caseProcessProfile.validFrom"
              [selectMode]="'single'"
              [maxDate]="caseProcessProfile.validTo"
              (saveModel)="dateChangeValidFrom(caseProcessProfile)"
              [disabled]="idx !== 0 && checkboxEditAllValidFrom">
            </app-input-calendar>
          </td>
          <td class="date-column">
            <app-input-calendar
              [withImage]="true"
              [(dateTimeRange)]="caseProcessProfile.validTo"
              [selectMode]="'single'"
              [minDate]="caseProcessProfile.validFrom"
              (saveModel)="dateChangeValidTo(caseProcessProfile)"
              [disabled]="idx !== 0 && checkboxEditAllValidTo">
            </app-input-calendar>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <br>
    <div class="case-processes-container-footer">
      <div>
        <button class="primary-button save-button" (click)="saveChanges()" [ngClass]="{ 'button-disabled': !isSaveButtonEnabled }" [disabled]="!isSaveButtonEnabled">
          <img *ngIf="saveInProgress" src="./assets/icons/waitForSearchresult.gif" alt="Save changes">
          <span *ngIf="!saveInProgress">Save changes</span>
        </button>
      </div>
    </div>
  </div>
</div>
