import { BusinessAreaEnum, CaseInformationEnum, InvoicingEnum } from 'app/shared/models/enums';
import { ProfileService } from 'app/core/services/profile.service';
import { Profile } from '../../../../../../../shared/models/profile';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CustomerListView } from 'app/shared/models/customer-list-view';
import { ActivatedRoute, Router } from '@angular/router';
import { Kam } from 'app/shared/models/kam';
import { Dictionary } from 'app/shared/models/dictionary';
import { UntypedFormControl } from '@angular/forms';
import { CustomersService } from 'app/core/services/customers.service';
import { AutoCompleteTypeEnum } from 'app/shared/components/drop-down-with-auto-complete-countries/auto-complete-type-enum';
import { RoutesName } from 'app/app-routes-name';
import { OnepointModalService, Size } from '../../../../../../../core/services/onepoint.modal.service';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-brand-new-profile',
  templateUrl: './brand-new-profile.component.html',
  styleUrls: ['./brand-new-profile.component.scss'],
})
export class BrandNewProfileComponent implements OnInit {
  @ViewChild('content')
  private template: TemplateRef<any>;
  @Input() customerListView: CustomerListView;
  @Output() refreshProfile = new EventEmitter<Profile>();
  showFavicon = false;
  kams: Kam[];
  profile: Profile;
  isProfileValid: boolean;
  isProfileCodeUnique: boolean;
  isGroupMemberIdForMappingValid: boolean;
  profileCodeViolations: string[];
  groupMemberIdForMappingViolations: string[];
  profileKAM: Dictionary[];
  marketList: Dictionary[];
  changedCode = new Subject<string>();
  changedGroupMemberIdForMapping = new Subject<string>();
  public controls: UntypedFormControl;
  private saveInProgress = false;
  constructor(
    private modalService: OnepointModalService,
    private customersService: CustomersService,
    private profileService: ProfileService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.profile = new Profile();
  }

  get AutoCompleteTypeEnum() {
    return AutoCompleteTypeEnum;
  }

  get BusinessAreaEnum() {
    return BusinessAreaEnum;
  }

  ngOnInit() {
    this.setupCodeChangeHandler();
  }

  public openAddNewProfile() {
    this.profile = new Profile();
    this.modalService.open(this.template, Size.LARGE);
    this.isProfileCodeUnique = false;
    this.isGroupMemberIdForMappingValid = true;
    this.isProfileValid = false;
  }

  public openAddNewMemberProfile(groupProfile: Profile) {
    this.profile = new Profile({
      groupProfileId: groupProfile.id,
      brandId: groupProfile.brandId,
      market: groupProfile.market,
      businessAreasTravel: groupProfile.businessAreasTravel,
      businessAreasHealth: groupProfile.businessAreasHealth,
      businessAreasRoadside: groupProfile.businessAreasRoadside,
      responsibleKamTravelUserId: groupProfile.responsibleKamTravelUserId,
      responsibleKamRoadsideUserId: groupProfile.responsibleKamRoadsideUserId,
      responsibleKamHealthUserId: groupProfile.responsibleKamHealthUserId,
      responsibleKamTravelFirstName: groupProfile.responsibleKamTravelFirstName,
      responsibleKamRoadsideFirstName: groupProfile.responsibleKamRoadsideFirstName,
      responsibleKamHealthFirstName: groupProfile.responsibleKamHealthFirstName,
      responsibleKamTravelSurname: groupProfile.responsibleKamTravelSurname,
      responsibleKamRoadsideSurname: groupProfile.responsibleKamRoadsideSurname,
      responsibleKamHealthSurname: groupProfile.responsibleKamHealthSurname,
      responsibleKamTravelEmail: groupProfile.responsibleKamTravelEmail,
      responsibleKamRoadsideEmail: groupProfile.responsibleKamRoadsideEmail,
      responsibleKamHealthEmail: groupProfile.responsibleKamHealthEmail,
      responsibleKamTravelPhone: groupProfile.responsibleKamTravelPhone,
      responsibleKamRoadsidePhone: groupProfile.responsibleKamRoadsidePhone,
      responsibleKamHealthPhone: groupProfile.responsibleKamHealthPhone,
      caseNumberPreFixTravel: groupProfile.caseNumberPreFixTravel,
      caseNumberPreFixRoadside: groupProfile.caseNumberPreFixRoadside,
      caseNumberPreFixHealth: groupProfile.caseNumberPreFixHealth,
      travelcareCaseInformation: groupProfile.travelcareCaseInformation,
      mobilityCaseInformation: groupProfile.mobilityCaseInformation,
      healthcareCaseInformation: groupProfile.healthcareCaseInformation,
      travelcareInvoicing: groupProfile.travelcareInvoicing,
      mobilityInvoicing: groupProfile.mobilityInvoicing,
      healthcareInvoicing: groupProfile.healthcareInvoicing
    });
    this.isProfileCodeUnique = false;
    this.isGroupMemberIdForMappingValid = true;
    this.isProfileValid = false;
    this.modalService.open(this.template, Size.LARGE);
  }

  save() {}

  close() {
    this.modalService.dismissAll();
  }

  showHideFavicon() {
    this.showFavicon = !this.showFavicon;
  }

  nameChanges(name: string) {
    this.profile.name = name;
    this.formChanged();
  }

  get CaseInformationEnumKeys() {
    return Object.keys(CaseInformationEnum);
  }

  get CaseInformationEnum() {
    return CaseInformationEnum;
  }

  get InvoicingEnumKeys() {
    return Object.keys(InvoicingEnum);
  }

  get InvoicingEnum() {
    return InvoicingEnum;
  }

  onCodeChange(code: string) {
    this.changedCode.next(code);
    this.profileCodeViolations = [];
  }

  onGroupMemberIdForMappingChange(memberId: string) {
    this.changedGroupMemberIdForMapping.next(memberId);
    this.groupMemberIdForMappingViolations = [];
  }

  private setupCodeChangeHandler() {
    this.changedCode.pipe(debounceTime(500)).subscribe(value => this.handleCodeChange(value));
    this.changedGroupMemberIdForMapping.pipe(debounceTime(500)).subscribe(value => this.handleGroupMemberIdForMappingChange(value));
  }

  private handleCodeChange(code: string) {
    this.profile.code = code;
    this.validateCode(this.profile.code);
  }

  private handleGroupMemberIdForMappingChange(memberId: string) {
    this.profile.groupMemberIdForMapping = memberId;
    this.validateGroupMemberIdForMapping(this.profile.groupMemberIdForMapping);
  }

  validateCode(code: string) {
    if (code) {
      this.profileService.getProfileNamesByCode(code).subscribe(value => {
        this.profileCodeViolations = value;
        this.isProfileCodeUnique = this.profileCodeViolations.length < 1;
        this.formChanged();
      });
    } else {
      this.isProfileCodeUnique = false;
      this.formChanged();
    }
  }

  private validateGroupMemberIdForMapping(memberId: string) {
    if (memberId) {
      this.profileService.getProfileNamesByGroupMemberIdForMapping(this.profile.groupProfileId, memberId).subscribe(value => {
        this.groupMemberIdForMappingViolations = value;
        this.isGroupMemberIdForMappingValid = this.groupMemberIdForMappingViolations.length < 1;
        this.formChanged();
      });
    } else {
      this.isGroupMemberIdForMappingValid = true;
      this.formChanged();
    }
  }

  prefixCheck(type: BusinessAreaEnum, input: string) {
    if (type === BusinessAreaEnum.TRAVELCARE) {
      this.profile.caseNumberPreFixTravel = input;
    }
    if (type === BusinessAreaEnum.MOBILITY) {
      this.profile.caseNumberPreFixRoadside = input;
    }
    if (type === BusinessAreaEnum.HEALTHCARE) {
      this.profile.caseNumberPreFixHealth = input;
    }
    this.formChanged();
  }

  prefixValidation(input: string) {
    const regexp = RegExp('^[A-Z]{4}$');
    return regexp.test(input);
  }

  checkBoxChange() {
    if (!this.profile.businessAreasTravel) {
      this.profile.caseNumberPreFixTravel = null;
      this.profile.responsibleKamTravelUserId = null;
      this.profile.responsibleKamTravelFirstName = null;
      this.profile.responsibleKamTravelSurname = null;
      this.profile.travelcareCaseInformation = null;
      this.profile.travelcareInvoicing = null;
    }
    if (!this.profile.businessAreasRoadside) {
      this.profile.caseNumberPreFixRoadside = null;
      this.profile.responsibleKamRoadsideUserId = null;
      this.profile.responsibleKamRoadsideFirstName = null;
      this.profile.responsibleKamRoadsideSurname = null;
      this.profile.mobilityCaseInformation = null;
      this.profile.mobilityInvoicing = null;
    }
    if (!this.profile.businessAreasHealth) {
      this.profile.caseNumberPreFixHealth = null;
      this.profile.responsibleKamHealthUserId = null;
      this.profile.responsibleKamHealthFirstName = null;
      this.profile.responsibleKamHealthSurname = null;
      this.profile.healthcareCaseInformation = null;
      this.profile.healthcareInvoicing = null;
    }
    this.formChanged();
  }

  formChanged() {
    if (
      this.profile.name &&
      this.profile.code &&
      this.profile.code.startsWith('CP') &&
      this.isProfileCodeUnique &&
      this.isGroupMemberIdForMappingValid &&
      this.profile.market &&
      this.profile.name !== '' &&
      (this.profile.businessAreasTravel || this.profile.businessAreasRoadside || this.profile.businessAreasHealth) &&
      this.isBusinessAreaChanged()
    ) {
      this.isProfileValid = true;
    } else {
      this.isProfileValid = false;
    }
  }

  private isBusinessAreaChanged() {
    return (
      ((this.profile.businessAreasTravel &&
        this.profile.responsibleKamTravelUserId &&
        this.prefixValidation(this.profile.caseNumberPreFixTravel) &&
        this.profile.travelcareCaseInformation &&
        this.profile.travelcareInvoicing) ||
        (!this.profile.businessAreasTravel &&
          !this.profile.responsibleKamTravelUserId &&
          !this.profile.caseNumberPreFixTravel &&
          !this.profile.travelcareCaseInformation &&
          !this.profile.travelcareInvoicing)) &&
      ((this.profile.businessAreasRoadside &&
        this.profile.responsibleKamRoadsideUserId &&
        this.prefixValidation(this.profile.caseNumberPreFixRoadside) &&
        this.profile.mobilityCaseInformation &&
        this.profile.mobilityInvoicing) ||
        (!this.profile.businessAreasRoadside &&
          !this.profile.responsibleKamRoadsideUserId &&
          !this.profile.caseNumberPreFixRoadside &&
          !this.profile.mobilityCaseInformation &&
          !this.profile.mobilityInvoicing)) &&
      ((this.profile.businessAreasHealth &&
        this.profile.responsibleKamHealthUserId &&
        this.prefixValidation(this.profile.caseNumberPreFixHealth) &&
        this.profile.healthcareCaseInformation &&
        this.profile.healthcareInvoicing) ||
        (!this.profile.businessAreasHealth &&
          !this.profile.responsibleKamHealthUserId &&
          !this.profile.caseNumberPreFixHealth &&
          !this.profile.healthcareCaseInformation &&
          !this.profile.healthcareInvoicing))
    );
  }

  addNewProfile() {
    if (this.saveInProgress) {
      return;
    }
    this.saveInProgress = true;
    this.profile.brandId = this.customerListView.id;
    this.profileService.addService(this.profile, this.customerListView.id).subscribe(
      result => {
        this.saveInProgress = false;
        this.modalService.dismissAll();
        this.profile = result;
        this.refreshProfile.emit(this.profile);
        this.router.navigate([RoutesName.customers + '/' + RoutesName.profile, this.profile.id]);
      },
      error => {
        this.saveInProgress = false;
        console.log(error);
      }
    );
  }

  hasGroupProfile(profile: Profile): boolean {
    return !!profile.groupProfileId;
  }
}
