import { Component, OnInit, TemplateRef, ViewChild, Output, EventEmitter, Input} from '@angular/core';
import { OnepointModalService, Size } from '../../../core/services/onepoint.modal.service';
import { CustomerRelationLabel } from '../../../shared/models/customer-relation-label';
import { CustomerRelationLabelStatusEnum } from '../../../shared/enums/customer-relation-label-status.enum';

@Component({
  selector: 'app-add-customer-profile-label',
  templateUrl: './add-customer-profile-label.component.html',
  styleUrls: ['./add-customer-profile-label.component.scss']
})
export class AddCustomerProfileLabelComponent implements OnInit {

  @Output() addCustomerRelationLabel = new EventEmitter<CustomerRelationLabel>();
  @Input() existingCustomerRelationLabels: CustomerRelationLabel[];
  @ViewChild('content')
  private template: TemplateRef<any>;
  customerRelationLabel: CustomerRelationLabel;
  canItBeSaved: boolean;

  constructor(private modalService: OnepointModalService) { }

  ngOnInit(): void { }

  open(){
    this.modalService.open(this.template, Size.NORMAL);
    this.addNewCustomerRelationLabel();
    this.canItBeSaved = false;
  }

  close() {

    this.modalService.dismissAll();
  }

  checkIfItCanBeSaved() {
    this.canItBeSaved = true;
    if((!!this.existingCustomerRelationLabels.find(label => label.name.toLowerCase() === this.customerRelationLabel.name.toLowerCase())) ||
        this.customerRelationLabel.name === '') {
      this.canItBeSaved = false;
    }
  }

  save() {
    this.addCustomerRelationLabel.emit(this.customerRelationLabel);
    this.modalService.dismissAll();
  }

  addNewCustomerRelationLabel(){
    this.customerRelationLabel = new CustomerRelationLabel();
    this.customerRelationLabel.status = CustomerRelationLabelStatusEnum.ACTIVE;
  }

}
