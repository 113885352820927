import { DictionaryService } from './../../../../../core/services/dictionary.service';
import { CustomerListView } from 'app/shared/models/customer-list-view';
import { Component, Input, OnInit } from '@angular/core';
import { Kam, KamAdapter } from 'app/shared/models/kam';
import { BusinessArea } from '../../../../../shared/models/customers/business-area';

@Component({
  selector: 'app-common-customer-summary',
  templateUrl: './common-customer-summary.component.html',
  styleUrls: ['./common-customer-summary.component.scss']
})
export class CommonCustomerSummaryComponent implements OnInit {
  @Input() numberOfBrands: number;
  markets: string[];
  numberOfProfiles: number;
  kam: Kam;
  areas: BusinessArea[];

  @Input() set commonCustomer(customerListView: CustomerListView) {
    if (customerListView != null) {
      this._commonCustomer = customerListView;
      this.markets = this.dictionaryService.getMarketsName(this._commonCustomer && this._commonCustomer.market);
      if (this._commonCustomer != null) {
        this.numberOfBrands =  this._commonCustomer.children.length;
        this.numberOfProfiles = this._commonCustomer.numberOfProfiles;
      }
      this.areas = this._commonCustomer.getBusinessAreas();
      this.kam = this.getKam(customerListView);
    }
  }

  get commonCustomer(): CustomerListView {
    return this._commonCustomer;
  }
  private _commonCustomer: CustomerListView;

  constructor(
    private dictionaryService: DictionaryService,
    private kamAdapter: KamAdapter
  ) {}

  ngOnInit() {}

  private getKam(commonCustomer: CustomerListView): Kam {
    if (commonCustomer.keyAccountManagerUserId) {
      return this.kamAdapter.adapt({
        userId: commonCustomer.keyAccountManagerUserId,
        firstName: commonCustomer.keyAccountManagerFirstName,
        surname: commonCustomer.keyAccountManagerSurname,
        userWorkEmail: commonCustomer.keyAccountManagerEmail,
        userWorkPhone: commonCustomer.keyAccountManagerPhone
      });
    }
  }
}
