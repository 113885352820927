import {Injectable} from '@angular/core';
import {Adapter} from './../interfaces/adapter';

export class SlaCalculatedEvent {
  id: number;
  name: string;
  calculatedEventType: string;

  public constructor(init?: Partial<SlaCalculatedEvent>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SlaCalculatedEventAdapter implements Adapter<SlaCalculatedEvent> {
  adapt(item: any): SlaCalculatedEvent {
    return new SlaCalculatedEvent({
      id: item.id,
      name: item.name,
      calculatedEventType: item.calculatedEventType
    });
  }
}
