import { animate, state, style, transition, trigger } from '@angular/animations';


export const resumeAnimation: any[] = [
trigger('accordianState', [
  state(
    'close',
    style({
      transform: 'rotateX(0deg)'
    })
  ),
  state(
    'open',
    style({
      transform: 'rotateX(180deg)'
    })
  ),
  transition('open <=> close', animate('300ms ease-in'))
])
]
