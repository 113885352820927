import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {

  @Input() id: string;
  @Input() label = '';
  @Input() value: boolean;
  @Input() disabled = false;
  @Output() valueChange = new EventEmitter<boolean>();
  
  clicked() {
    if ( this.disabled ) {
      return;
    }
    const val = !this.value;
    if ( val != this.value ) {
      this.value = val;
      this.valueChange.emit(this.value);
    }
  }
}
