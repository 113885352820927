<div class="common-customer-debtors-container">
  <div class="common-customer-debtors">
    <div class="debtors col-xl-6 col-lg-9">
      <span class="title">Debtors ({{ debtors.length }})</span>
      <div class="add-new-debtor-button-container">
        <button class="primary-button add-new-debtor-button" (click)="addNewDebtor()">Add new debtor</button>
      </div>
      <div class="debtor-list">
        <div class="debtor" *ngFor="let debtor of debtors">
          <span class="debtor-name">{{ debtor.number }} {{ debtor.name }}</span>
          <button class="primary-button" (click)="editDebtor(debtor)">Edit</button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-edit-debtor #modal [commonCustomerId]="commonCustomerId" (reloadDebtors)="reloadDebtors()"></app-edit-debtor>
