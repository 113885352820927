import { Injectable } from '@angular/core';
import { ApplicationSettings } from '@onepoint/shared/models/application.settings';
import { SettingsLoaderService } from './settings.loader.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private configuration: ApplicationSettings;

  constructor(private settingsLoaderService: SettingsLoaderService) {
  }

  getBffEndpoint(): string {
    return this.settingsLoaderService.configuration.bffEndpoint;
  }

  getKeyCloakUrl(): string {
    return this.settingsLoaderService.configuration.keyCloakUlr;
  }

  isProduction(): boolean {
    return this.settingsLoaderService.configuration.isProduction;
  }

  isTest(): boolean {
    return this.settingsLoaderService.configuration.isTest;
  }

  isSsoDisabled(): boolean {
    return this.settingsLoaderService.configuration.ssoDisabled;
  }
}
