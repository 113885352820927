<div class="input">
  <span [ngClass]="isValid?'input-calendar-name':'invalid-input-name'">{{name}}<span class="required"
      *ngIf="required">*</span></span>
  <img *ngIf="withImage" src="assets/icons/calendar.png" class="input-image-calendar"
    [ngStyle]="name && {'top': '28px'}" (click)="picker.open()">
  <div *ngIf="startingPlaceholder" class="input-starting-placeholder">{{startingPlaceholder}}</div>
  <input matInput [matDatepicker]="picker" (saveModel)="onSaveModel();" (blur)="onChange();onSaveModel();" (click)="picker.open()"
    title="{{this.isValidMessage}}"  spellcheck="false" (focus)="onSaveState()" [(ngModel)]="dateTimeRange"
    placeholder="{{placeholder}}"  class="input-component" (input)="onInputCheckEmpty($event)"
    (keydown.backspace)="onInputCheckEmpty($event)" [ngClass]="isValid?'input-component':'invalid-input-component'"
    [disabled]="!!disabled" (dateChange)="onChange();onSaveModel();"
    [min]="minDate" [max]="maxDate">
   <mat-datepicker #picker ></mat-datepicker>
</div>