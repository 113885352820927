<div class='chip-input-container form-control'>
  <span *ngFor='let chip of validatedChips; let i = index' class='chip'>
    <button type='button' class='btn-close ms-2 me-1' (click)='removeChip(i)'></button>
    <span [ngStyle]="{'color': chip.isValid ? 'white' : 'red'}">{{ chip.value }}</span>
  </span>
  <input
    type='text'
    [(ngModel)]='inputText'
    (keydown)='addChip($event)'
    (blur)='addChipOnBlur()'
    [placeholder]='placeholder'
    class='chip-input'
  >
</div>
