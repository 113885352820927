import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { SettingsService } from './settings.service';
import { Coverage, CoverageAdapter } from '../../shared/models/coverage';
import { LimitTypeEnum, LimitFromTypeEnum, LimitPersonTypeEnum, LimitQuantityTypeEnum } from '../../shared/models/enums';
import { LimitPeriodUnitEnum } from '../../shared/enums/coverage-period-unit-enum';

@Injectable({
  providedIn: 'root'
})
export class CoverageService extends BaseService {

  static coverageLimitTypes = new Map<string, string>([
    [LimitTypeEnum.AMOUNT, 'Amount'],
    [LimitTypeEnum.NUMBER_OF_PERSONS, 'Number of persons'],
    [LimitTypeEnum.DURATION, 'Duration'],
    [LimitTypeEnum.QUANTITY, 'Quantity'],
  ]);

  static coverageLimitFromTypes = new Map<string, string>([
    [LimitFromTypeEnum.INCIDENT_DATE, 'incident date'],
    [LimitFromTypeEnum.FIRST_MEDICAL_VISIT, 'first medical visit']
  ]);

  static coverageLimitPeriodTypes = new Map<string, string>([
    [LimitPeriodUnitEnum.DAYS, 'days'],
    [LimitPeriodUnitEnum.MONTHS, 'months'],
    [LimitPeriodUnitEnum.WEEKS, 'weeks']
  ]);

  static coverageLimitPersonTypes = new Map<string, string>([
    [LimitPersonTypeEnum.PERSON, 'person']
  ]);

  static coverageLimitTreatmentsTypes = new Map<string, string>([
    [LimitQuantityTypeEnum.TREATMENTS, 'treatments']
  ]);

  constructor(
    private http: HttpClient,
    private coverageAdapter: CoverageAdapter,
    private settingsService: SettingsService
  ) {
    super(settingsService);
  }

  public getCoveragesForProduct(productId: number): Observable<Coverage[]> {
    return this.http.get<Coverage[]>(
      this.baseURL + 'productcoverages/coverages/' + productId,
      { headers: this.jsonHeaders }
    ).pipe(
      map((data: any[]) =>
        data.map(item => this.coverageAdapter.adapt(item))
      )
    );
  }

  public updateCoverage(coverage: Coverage): Observable<Coverage> {
    return this.http.put<Coverage>(this.baseURL + 'productcoverages/updateCoverage',
      JSON.stringify(coverage), {
      headers: this.jsonHeaders
    });
  }

  public createCoverage(coverage: Coverage): Observable<Coverage> {
    return this.http.post<Coverage>(this.baseURL + 'productcoverages/createCoverage',
      JSON.stringify(coverage), {
      headers: this.jsonHeaders
    });
  }

  public deleteCoverage(coverageID: any): Observable<any> {
    return this.http.delete<any>(this.baseURL + 'productcoverages/delete/' + coverageID, {
      headers: this.jsonHeaders
    });
  }
}
