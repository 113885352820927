<div class="menu">
  <ul class="nav justify-content-center">
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'flows'}" (click)="shownSection = 'flows'">
        <div>Overview</div>
      </div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'activities'}" (click)="shownSection = 'activities'">
        <div>Activities</div>
      </div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'validations'}" (click)="shownSection = 'validations'">
        <div>Validations</div>
      </div>
    </li>
  </ul>
</div>
<app-dcf-flows *ngIf="shownSection === 'flows'"></app-dcf-flows>
<app-dcf-activities *ngIf="shownSection === 'activities'"></app-dcf-activities>
<app-dcf-validations *ngIf="shownSection === 'validations'"></app-dcf-validations>
