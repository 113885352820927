import { ActionInput } from '@onepoint/shared/models/action-input';
import { RuleEngineService } from '@onepoint/core/services/rule-engine.service';
import { RuleMapping } from '@onepoint/shared/models/rule-mapping';
import { RuleAction } from '@onepoint/shared/models/rule-action';
import { Rule } from 'app/shared/models/rule';
import { RuleEvent } from '@onepoint/shared/models/rule-event';
import { OnepointModalService } from '@onepoint/core/services/onepoint.modal.service';
import * as _ from 'lodash';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-rule-mapping-modal',
  templateUrl: './rule-mapping-modal.component.html',
  styleUrls: ['./rule-mapping-modal.component.scss'],
})
export class RuleMappingModalComponent implements OnInit {
  private _selectedRuleStatus?: string;
  private _selectedRule?: Rule;
  private _selectedRuleType?: string;
  private _selectedRuleEvent?: RuleEvent;
  private _selectedRuleAction?: RuleAction;
  private _rules: Rule[];
  private _ruleEvents: RuleEvent[];
  private _ruleActions: RuleAction[];
  private _selectedRuleMapping: RuleMapping;
  private originalRuleMapping: RuleMapping;

  @Input()
  public set ruleEvents(newRuleEvents: RuleEvent[]) {
    this._ruleEvents = newRuleEvents;
    this._ruleEvents.sort((lhs, rhs) => (lhs.name.localeCompare(rhs.name)));
  }

  public get ruleEvents(): RuleEvent[] {
    return this._ruleEvents;
  }

  @Input()
  public ruleTypes: string[];

  public get selectedRuleType(): string | undefined {
    return this._selectedRuleType;
  }

  public set selectedRuleType(newRuleType: string | undefined) {
    this._selectedRuleType = newRuleType;
    this.selectedRuleMapping.type = this._selectedRuleType;
  }

  public ruleStatus: string[];

  public get selectedRuleStatus(): string | undefined {
    return this._selectedRuleStatus;
  }

  public set selectedRuleStatus(newRuleStatus: string | undefined) {
    this._selectedRuleStatus = newRuleStatus;

    if (this._selectedRuleStatus != undefined) {
      this.selectedRuleMapping.active = this._selectedRuleStatus === 'Active';
    }
  }

  @Input()
  public set rules(newRules: Rule[]) {
    this._rules = newRules;
    this._rules.sort((lhs, rhs) => (lhs.name.localeCompare(rhs.name)));
    this.getRuleDescriptionAndActionInput();
  }

  public get rules(): Rule[] {
    return this._rules;
  }

  public get selectedRule(): Rule | undefined {
    return this._selectedRule;
  }

  public set selectedRule(newRule: Rule | undefined) {
    this._selectedRule = newRule;
    this.selectedRuleMapping.ruleId = this._selectedRule.id;
    this.ruleDescription = this._selectedRule.description;
  }

  @Input()
  public set selectedRuleEvent(newRuleEvent: RuleEvent | undefined) {
    this._selectedRuleEvent = newRuleEvent;
    this.selectedRuleMapping.ruleEventId = this._selectedRuleEvent.id;
  }

  public get selectedRuleEvent(): RuleEvent | undefined {
    return this._selectedRuleEvent;
  }

  @Input()
  public set ruleActions(newRuleActions: RuleAction[]) {
    this._ruleActions = newRuleActions;
    this._ruleActions.sort((lhs, rhs) => (lhs.name.localeCompare(rhs.name)));
  }

  public get ruleActions(): RuleAction[] {
    return this._ruleActions;
  }

  public set selectedRuleAction(newRuleAction: RuleAction | undefined) {
    // Action is reset
    if (newRuleAction === undefined) {
      this.actionInputJSON = undefined;
    } else {
      // No previous action set - set with action prototype
      if (this._selectedRuleAction === undefined) {
        this.actionInputJSON = this.parseJson(newRuleAction.prototype);
      } else {
        // Action previously set and is set to a new action - set with action prototype
        if (this._selectedRuleAction.id !== newRuleAction.id) {
          this.actionInputJSON = this.parseJson(newRuleAction.prototype);
        }
        // Action previously set and i set to the same action - do not update actionInput
      }
    }

    this._selectedRuleAction = newRuleAction;
    this.selectedRuleMapping.ruleActionId = this._selectedRuleAction.id;
  }

  public get selectedRuleAction(): RuleAction | undefined {
    return this._selectedRuleAction;
  }

  @Input()
  public get ruleActionInputs(): ActionInput[] {
    return this._ruleActionInputs;
  }
  public set ruleActionInputs(ruleActionInputs: ActionInput[]) {
    this._ruleActionInputs = ruleActionInputs;
    this.getRuleDescriptionAndActionInput();
  }
  private _ruleActionInputs: ActionInput[];

  @Input()
  public get selectedRuleMapping(): RuleMapping {
    return this._selectedRuleMapping;
  }

  public set selectedRuleMapping(selectedRuleMapping: RuleMapping) {
    this._selectedRuleMapping = selectedRuleMapping;
    this.originalRuleMapping = _.cloneDeep(selectedRuleMapping);
    this.getRuleDescriptionAndActionInput();
  }


  @Output() refreshTable = new EventEmitter();

  ruleDescription: string;
  actionInputJSON: string;
  originalActionInputJSON: string;

  constructor(private onepointModalService: OnepointModalService, private ruleEngineService: RuleEngineService) {}

  ngOnInit() {
    this.initializeData();
  }

  private getRuleDescriptionAndActionInput() {
    if (
      !this.isItAdding &&
      this.selectedRuleMapping != null &&
      this.rules != null &&
      this.ruleActionInputs != null &&
      this.actionInputJSON == null
    ) {
      let unparsed = this.ruleActionInputs.find(a => a.id === this.selectedRuleMapping.actionInputId).actionInputJson;
      this.actionInputJSON = this.parseJson(unparsed);
      this.ruleDescription = this.rules.find(a => a.id === this.selectedRuleMapping.ruleId).description;
      this.originalActionInputJSON = this.actionInputJSON;
    }
  }

  private isRuleMappingValid(mapping: RuleMapping): boolean {
    if (this.isItAdding) {
      return mapping.active != null && mapping.name != null && mapping.type != null &&
        mapping.ruleId != null && mapping.ruleEventId != null && mapping.ruleActionId != null;
    } else {
      // Rule types was added at a later point. Existing rules is allowed to not have the rule type set.
      return mapping.active != null && mapping.name != null &&
        mapping.ruleId != null && mapping.ruleEventId != null && mapping.ruleActionId != null;

    }
  }

  initializeData() {
    if (this.isItAdding) {
      this.ruleDescription = '';
      this.actionInputJSON = '';
    }

    this.ruleStatus = ['Active', 'Inactive'];

    if (this.isRuleMappingValid(this.selectedRuleMapping)) {
      this._selectedRuleType = this.ruleTypes.find(ruleType => ruleType === this.selectedRuleMapping.type);
      const statusText = this.selectedRuleMapping.active ? 'Active' : 'Inactive';
      this._selectedRuleStatus = this.ruleStatus.find(status => status === statusText);
      this._selectedRule = this.rules.find(rule => rule.id === this.selectedRuleMapping.ruleId);
      this._selectedRuleEvent = this.ruleEvents.find(ruleEvent => ruleEvent.id === this.selectedRuleMapping.ruleEventId);
      this._selectedRuleAction = this.ruleActions.find(ruleAction => ruleAction.id === this.selectedRuleMapping.ruleActionId);
    }

  }

  parseJson(jsonString : string){
    try {
      return JSON.stringify(JSON.parse(jsonString), null, 3);
    } catch (e) {
      return jsonString;
    }
  }

  closeModal() {
    this.selectedRuleMapping = this.originalRuleMapping;
    this.onepointModalService.dismissAll();
  }

  get isSaveButtonEnabled(): boolean {
    if (this.selectedRuleMapping == null) {
      return false;
    } else {
      return (
        this.selectedRuleMapping.type != null &&
        this.selectedRuleMapping.active != null &&
        this.selectedRuleMapping.ruleEventId != null &&
        this.selectedRuleMapping.ruleId != null &&
        this.selectedRuleMapping.ruleActionId != null &&
        (this.selectedRuleMapping.name != null && this.selectedRuleMapping.name !== '') &&
        (JSON.stringify(this.selectedRuleMapping) !== JSON.stringify(this.originalRuleMapping) ||
            this.actionInputJSON !== this.originalActionInputJSON)
      );
    }
  }

  save() {
    if (this.isItAdding) {
      this.selectedRuleMapping.actionInput = this.actionInputJSON;
      this.ruleEngineService.addRuleMapping(this.selectedRuleMapping).subscribe(
        () => {
          this.refreshTable.emit();
          this.onepointModalService.dismissAll();
        }
      );
    } else {
      this.ruleEngineService
        .updateActionInput(
          new ActionInput({
            id: this.selectedRuleMapping.actionInputId,
            actionInputJson: this.actionInputJSON,
          })
        )
        .subscribe(
          () => {
            this.ruleEngineService.updateRuleMapping(this.selectedRuleMapping).subscribe(
              () => {
                this.refreshTable.emit();
                this.onepointModalService.dismissAll();
              }
            );
          }
        );
    }
  }

  get isItAdding() {
    if (this.selectedRuleMapping == null) {
      return false;
    } else {
      return this.selectedRuleMapping.id == null;
    }
  }
}
