import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from './settings.service';
import {CommonCustomer} from '@onepoint/shared/models/common-customer';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Sla, SlaAdapter} from "@onepoint/shared/models/sla";
import {SlaEvent, SlaEventAdapter} from "@onepoint/shared/models/sla-event";

const slaUrl = 'sla';

@Injectable({
  providedIn: 'root',
})
export class SlaService extends BaseService {
  constructor(
    private settingsService: SettingsService,
    private http: HttpClient,
    private slaAdapter: SlaAdapter,
    private slaEventAdapter: SlaEventAdapter
  ) {
    super(settingsService);
  }

  public getSlas(): Observable<Sla[]> {
    return this.http
      .get(this.baseURL + slaUrl, {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.slaAdapter.adapt(item))));
  }

  public getSla(slaId: number): Observable<Sla> {
    return this.http
      .get(this.baseURL + slaUrl +'/'+ slaId, {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.slaAdapter.adapt(item)));
  }

  public saveCustomerSlaAgreement(sla: Sla): Observable<Sla> {
    return this.http
      .put<CommonCustomer>(this.baseURL + slaUrl, sla, {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.slaAdapter.adapt(item)));
  }

  public getSlaEvents(): Observable<SlaEvent[]> {
    return this.http
      .get(this.baseURL + slaUrl + "/events", {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.slaEventAdapter.adapt(item))));
  }
}
