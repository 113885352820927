import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dictionary, DropdownAdapter } from 'app/shared/models/dictionary';
import { SosServices } from 'app/shared/models/sos-services';
import { SosServicesService } from 'app/core/services/sos-services.service';
import { SosServicesItem } from 'app/shared/models/sos-services-item';
import { SosServicesCategoryEnum, FeeTypeEnum, CommunicationCostTypeEnum, PriceIndexEnum } from 'app/shared/models/enums';
import { CountryService } from 'app/core/services/country.service';
import { Continent } from 'app/shared/models/continent';
import { CommunicationCost } from 'app/shared/models/communication-cost';
import { CausesService } from 'app/core/services/causes.service';
import { DictionaryService } from 'app/core/services/dictionary.service';
import { MasterListService } from '@onepoint/core/services/masterlist.service';
import {BankFee} from "@onepoint/shared/models/bank-fee";

@Component({
  selector: 'app-edit-sos-services',
  templateUrl: './edit-sos-services.component.html',
  styleUrls: ['./edit-sos-services.component.scss'],
})
export class EditSosServicesComponent implements OnInit {
  _sosServices: SosServices;

  @Input() set sosServices(sosServices) {
    if (sosServices) {
      this._sosServices = sosServices;
      this.isBankFee = sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.BANK_FEE;
      this.isRecoveryFee = sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.SHARE_OF_RECOVERY;
      if (this.causeList) {
        this.recalculateCauseLevel2List();
      }
      this.processCommitmentTypeList();
    }
  }

  get sosServices() {
    return this._sosServices;
  }

  @Input() readonly: boolean;
  @Output() changes = new EventEmitter<SosServices>();
  isBankFee: boolean = false;
  isRecoveryFee: boolean = false;
  segments: Dictionary[];
  sosOwnerOptions: Dictionary[];
  sosServicesCategoryList: Dictionary[];
  medicalServiceOptions: Dictionary[];
  caseTypeList: Dictionary[];
  causeList: Map<string, Dictionary[]>;
  causeLevel1List: Dictionary[];
  causeLevel2List: Dictionary[] = [];
  causeLevel2ExtensionList: Dictionary[] = [];
  refundTypeList: Dictionary[];
  commitmentTypeList: Dictionary[];
  serviceItemList: Dictionary[];
  sosServicesTypeList: Dictionary[];
  feeTypeList: Dictionary[];
  continents: Continent[];
  bankFeePrototypes: BankFee[];
  defaultPriceIndex: string;
  selectedCommitmentTypes: Dictionary[] = [];
  selectedExtensionCommitmentTypes: Dictionary[] = [];
  erpClientList: Dictionary[];

  serviceItemTypes: Dictionary[] = [
    { id: 'SOS_FEE', name: 'SOS fee' },
    { id: 'SUPPLIER_INVOICE_1_TO_1', name: '1 to 1' },
    { id: 'SUPPLIER_INVOICE_OTHER', name: 'Other' },
    { id: 'RECOVERY_FEE', name: 'Recovery'},
  ];

  get SosServicesCategoryEnum() {
    return SosServicesCategoryEnum;
  }

  get CommunicationCostTypeEnum() {
    return CommunicationCostTypeEnum;
  }

  get ExtensionTypes() {
    return ['OR', 'NONE'];
  }

  constructor(
    private sosServicesService: SosServicesService,
    private countryService: CountryService,
    private causeService: CausesService,
    private dictionaryService: DictionaryService,
    private dropdownAdapter: DropdownAdapter,
    private masterListService: MasterListService
  ) {
    this.sosOwnerOptions = [
      { id: 'true', name: 'Yes' },
      { id: 'false', name: 'No' },
    ];

  }

  ngOnInit() {
    this.sosServicesService.getSosServicesCategoryList().subscribe(
      result => (this.sosServicesCategoryList = result),
      error => console.error(error)
    );
    this.sosServicesService.getCaseTypeList().subscribe(
      result => (this.caseTypeList = result),
      error => console.error(error)
    );
    this.processCommitmentTypeList();
    this.causeService.getCauses().subscribe(
      result => {
        this.causeLevel1List = result[0];
        this.causeList = result[1];
        if (this._sosServices) {
          this.recalculateCauseLevel2List();
          if (this._sosServices.extension) {
            this.recalculateCauseLevel2List(true);
          }
        }
      },
      error => console.error(error)
    );

    this.sosServicesService.getMedicalServiceOptionsList().subscribe(
      result => (this.medicalServiceOptions = result),
      error => console.error(error)
    );
    this.sosServicesService.getServiceItemList().subscribe(
      result => (this.serviceItemList = result),
      error => console.error(error)
    );
    this.sosServicesService.getSosServicesTypeList().subscribe(
      result => (this.sosServicesTypeList = result),
      error => console.error(error)
    );
    this.sosServicesService.getFeeTypeList().subscribe(
      result => (this.feeTypeList = result),
      error => console.error(error)
    );
    this.sosServicesService.getDefaultPriceIndex().subscribe(
      result => (this.defaultPriceIndex = result.defaultPriceIndex),
      error => console.error(error)
    );
    this.sosServicesService.getBankFeesPrototype().subscribe(
      result => (this.bankFeePrototypes = result),
      error => console.error(error)
    );
    this.countryService.getAllContinents().subscribe(
      result => {
        this.continents = result;
      },
      error => console.error(error)
    );
    this.dictionaryService.getErpClients().subscribe(
      result => (this.erpClientList = result),
      error => console.error(error)
    );
    this.masterListService.getRefundTypeList().subscribe(
      result => this.refundTypeList = result.map(
        rt => new Dictionary(rt.businessKey, rt.refundTypeName)
      )
    );
  }

  recalculateCauseLevel2List(extension = false) {
    const causesLevel2 = [new Dictionary('All', 'All')];
    const serviceObj = extension ? this.sosServices.extension : this.sosServices;
    serviceObj.causesLevel1?.map(cause => cause.id).forEach(l1id =>
      this.causeList.get(l1id).forEach(l2Cause => {
        if (causesLevel2.indexOf(l2Cause)) {
          causesLevel2.push(l2Cause);
        }
      })
    );
    if (serviceObj.causesLevel2) {
      this.removeOrphanedLevel2Causes(causesLevel2);
    }
    if (extension) {
      this.causeLevel2ExtensionList = causesLevel2;
    } else {
      this.causeLevel2List = causesLevel2;
    }
  }

  private processCommitmentTypeList() {
    this.sosServicesService.getCommitmentTypeList().subscribe(
      result => {
        this.commitmentTypeList = result;
        this.selectedCommitmentTypes = [];
        if (this.sosServices.commitmentType) {
          this.sosServices.commitmentType.forEach(
            bizzkey => this.selectedCommitmentTypes.push(this.commitmentTypeList.find(commitmenType => bizzkey === commitmenType.id))
          );
        }
        this.selectedExtensionCommitmentTypes = [];
        if (this.sosServices?.extension?.commitmentType) {
          this.sosServices.extension.commitmentType.forEach(
            bizKey => this.selectedExtensionCommitmentTypes.push(
              this.commitmentTypeList.find(commitmentType => bizKey === commitmentType.id)
            )
          );
        }
      },
      error => console.error(error)
    );

  }

  private removeOrphanedLevel2Causes(causesLevel2: Dictionary[]) {
    const availableLevel2Causes = causesLevel2.map(c => c.id);

    const newSelectedLevel2Causes = [];
    this.sosServices.causesLevel2
      .filter(cause => availableLevel2Causes.indexOf(cause.id) !== -1)
      .forEach(cause => newSelectedLevel2Causes.push(cause));
    // replace list because component does not see change in elements of array
    this.sosServices.causesLevel2 = newSelectedLevel2Causes;
  }

  getCommunicationCostForContinent(continent: Continent): CommunicationCost {
    let communicationCost = this.sosServices.communicationCosts.find(ccom => continent.code === ccom.continentCode);
    if (communicationCost === undefined) {
      communicationCost = new CommunicationCost({ continentCode: continent.code, phoneAmount: 0, smsAmount: 0, medicalAmount: 0 });
    }
    return communicationCost;
  }

  onlyNumericKeys(e) {
    if (!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode === 8)) {
      return false;
    }
  }

  sosServicesCategoryChanged() {
    this.isBankFee = false;
    this.isRecoveryFee = false;
    this.sosServices.communicationCostCurrency = null;
    this.sosServices.communicationCosts = [];
    this.sosServices.bankFees = [];
    this.sosServices.recoverySosServicesItem = [];
    const sosServicesCategoryEnum = SosServicesCategoryEnum[this.sosServices.sosServicesCategory];
    this.sosServices.sosServicesCategoryEnum = sosServicesCategoryEnum;
    if (sosServicesCategoryEnum === SosServicesCategoryEnum.SHARE_OF_COST_CONTROL || sosServicesCategoryEnum === SosServicesCategoryEnum.SHARE_OF_RECOVERY) {
      this.isRecoveryFee =  this.sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.SHARE_OF_RECOVERY
      this.sosServices.feeTypeEnum = FeeTypeEnum.SECONDARY;
      this.sosServices.feeType = this.feeTypeList.find(f => f.name === FeeTypeEnum.SECONDARY).id;
      this.sosServices.targetShare = 0;
      this.sosServices.targetMaxCap = {
        amount: 0,
        currency: 'DKK',
        precision: 2,
      };
      this.sosServices.targetPrice = null;
      this.sosServices.targetPriceYear = null;
    } else if (sosServicesCategoryEnum === SosServicesCategoryEnum.BANK_FEE) {
      this.isBankFee = true;
      this.sosServices.feeTypeEnum = FeeTypeEnum.SECONDARY;
      this.sosServices.feeType = this.feeTypeList.find(f => f.name === FeeTypeEnum.SECONDARY).id;
      this.sosServices.targetPrice = null;
      this.sosServices.targetPriceYear = null;
      this.sosServices.targetShare = null;
      this.sosServices.targetMaxCap = null;
      this.sosServices.communicationCostCurrency = 'DKK';
      this.sosServices.bankFees = this.bankFeePrototypes;

    } else if (sosServicesCategoryEnum === SosServicesCategoryEnum.COMMUNICATION_COST) {
      this.sosServices.feeTypeEnum = FeeTypeEnum.SECONDARY;
      this.sosServices.feeType = this.feeTypeList.find(f => f.name === FeeTypeEnum.SECONDARY).id;
      this.sosServices.targetPrice = null;
      this.sosServices.targetPriceYear = null;
      this.sosServices.targetShare = null;
      this.sosServices.targetMaxCap = null;
      this.sosServices.communicationCostCurrency = 'DKK';
      for (const continent of this.continents) {
        const commCost = new CommunicationCost({ continentCode: continent.code, phoneAmount: 0, smsAmount: 0, medicalAmount: 0 });
        this.sosServices.communicationCosts.push(commCost);
      }
    } else if (this.isAdditionalMedicalServices(this.sosServices)) {
      this.sosServices.feeTypeEnum = FeeTypeEnum.SECONDARY;
      this.sosServices.feeType = this.feeTypeList.find(f => f.name === FeeTypeEnum.SECONDARY).id;
      this.sosServices.priceIndexEnum = PriceIndexEnum.DANISH_SALARY_INDEX;
      this.sosServices.defaultPriceIndex = this.defaultPriceIndex;
    } else {
      if (!this.sosServices.targetPrice) {
        this.sosServices.targetPrice = {
          amount: 0,
          currency: 'DKK',
          precision: 2,
        };
      }
      if (!this.sosServices.targetPriceYear) {
        this.sosServices.targetPriceYear = '2020';
      }
      this.sosServices.targetShare = null;
      this.sosServices.targetMaxCap = null;
      this.sosServices.feeType = '';
    }
    this.formChanged();
  }

  formChanged() {
    this.changes.emit(this.sosServices);
  }

  multiselectServiceItemChanged(event: any, type: string = 'SUPPLIER_INVOICE', extension = false) {
    let newItems: SosServicesItem[];
    if (!this.containsAllServicesItem(this.sosServices.sosServicesItem)) {
      if (this.containsAllServicesItem(event)) {
        newItems = [].concat(event.filter(x => String(x.id) === '0'));
      } else {
        newItems = event;
      }
    } else if (this.containsAllServicesItem(event)) {
      if (event.length > 1) {
        newItems = [].concat(event.filter(x => String(x.id) !== '0'));
      } else {
        newItems = event;
      }
    } else {
      newItems = event;
    }
    if (extension) {
      switch (type) {
        case 'REFUND':
          this.sosServices.extension.refundSosServicesItem = newItems;
          break;
        case 'RECOVERY':
          this.sosServices.extension.recoverySosServicesItem = newItems;
          break;
        default:
          this.sosServices.extension.sosServicesItem = newItems;
      }
    } else {
      switch (type) {
        case 'REFUND':
          this.sosServices.refundSosServicesItem = newItems;
          break;
        case 'RECOVERY':
          this.sosServices.recoverySosServicesItem = newItems;
          break;
        default:
          this.sosServices.sosServicesItem = newItems;
      }
    }
    this.formChanged();
  }

  causeChanged(field: string, event: any, extension = false) {
    this.recalculateCauseLevel2List(extension);
    this.formChanged();
  }

  cause2Changed(field: string, event: any, extension = false) {
    const serviceObj = extension ? this.sosServices.extension : this.sosServices;
    serviceObj.allCausesLevel2 = serviceObj.causesLevel2.map(cause => cause.id).indexOf('All') > -1;
    if (serviceObj.allCausesLevel2) {
      serviceObj.causesLevel2 = [{id: 'All', name: 'All'}];
    }
    this.formChanged();
  }

  multiselectChanged(field: string, event: any) {
    this.sosServices[field] = event;
    this.formChanged();
  }

  commitmentTypesChanged(event: any, extension = false) {
    if (extension) {
      this.sosServices.extension.commitmentType = event.map(value => value.id);
    } else {
      this.sosServices.commitmentType = event.map(value => value.id);
    }
    this.formChanged();
  }

  containsAllServicesItem(sosServicesItem: SosServicesItem[]): boolean {
    return sosServicesItem.filter(x => String(x.id) === '0').length > 0;
  }

  getPriceFieldTitle(sosServices: SosServices) {
    if (this.isAdditionalMedicalServices(sosServices)) {
      return 'Price';
    } else {
      return 'Target price';
    }
  }

  getPriceYearFieldTitle(sosServices: SosServices) {
    if (this.isAdditionalMedicalServices(sosServices)) {
      return 'Price year';
    } else {
      return 'Target price year';
    }
  }

  isAdditionalMedicalServices(sosServices: SosServices) {
    return (
      sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.ADDITIONAL_MEDICAL_SERVICES ||
      sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.ADDITIONAL_MEDICAL_SERVICES_ESCORT
    );
  }

  extensionTypeChanged() {
    switch (this.sosServices.extensionType) {
      case 'NONE':
        this.sosServices.extension = new SosServices();
        break;
    }
    this.formChanged();
  }
}
