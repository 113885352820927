import {Injectable} from '@angular/core';
import {Adapter} from './../../interfaces/adapter';
import {Step, StepAdapter} from "@onepoint/shared/models/digital-caseflow/step";
import {ActivityAdapter} from "@onepoint/shared/models/digital-caseflow/activity";

export class Flow {
  id: number;
  revision: number;
  name: string;
  description: string;
  startTypes: string[];
  validationSteps: Step[];
  steps: Step[];

  public constructor(init?: Partial<Flow>) {
    Object.assign(this, init);
  }
}


@Injectable({
  providedIn: 'root'
})
export class FlowAdapter implements Adapter<Flow> {
  adapt(item: any): Flow {
    const stepAdapter = new StepAdapter();
    return new Flow({
      id: item.id,
      revision: item.revision,
      name: item.name,
      description: item.description,
      startTypes: item.startTypes,
      validationSteps: item.validationSteps?.map(stepAdapter.adapt),
      steps: item.steps?.map(stepAdapter.adapt)
    });
  }
}
