import {Observable} from 'rxjs';
import {BaseService} from './base.service';
import {Injectable} from '@angular/core';
import {SettingsService} from './settings.service';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {CreditorListPageDto, CreditorPageDtoAdapter} from "@onepoint/shared/models/creditor-list-page-dto";
import {ErpCreditorAdapter} from "@onepoint/shared/models/erp-creditor";
import {SimpleErpCreditor, SimpleErpCreditorAdapter} from "@onepoint/shared/models/simple-erp-creditor";
@Injectable({
  providedIn: 'root'
})
export class ErpDataService extends BaseService {
  constructor(
    private settingsService: SettingsService,
    private erpCreditorPageAdapter: CreditorPageDtoAdapter,
    private erpCreditorAdapter: ErpCreditorAdapter,
    private simpleErpCreditorAdapter: SimpleErpCreditorAdapter,
    private http: HttpClient
  ) {
    super(settingsService);
  }

  public getCreditorPage(request: ErpCreditorPageRequest): Observable<CreditorListPageDto> {
    return this.http
      .put(this.baseURL + 'erp-data/creditor/page', request)
      .pipe(map(data => this.erpCreditorPageAdapter.adapt(data)));
  }

  public getAllSimpleCreditors(): Observable<SimpleErpCreditor[]> {
    return this.http
      .get(this.baseURL + 'erp-data/creditor/simple/all')
      .pipe(map((data: any[]) => data.map(item => this.simpleErpCreditorAdapter.adapt(item))));
  }

}

export class ErpCreditorPageRequest {
  page: number;
  pageSize: number;
  erpId: number;
  name: string;
  status: string;
  sortBy: string;

  public constructor(page: number, pageSize: number, erpId: number, name: string, status: string, sortBy: string) {
    this.page = page;
    this.pageSize = pageSize;
    this.erpId = erpId;
    this.name = name;
    this.status = status;
    this.sortBy = sortBy;
  }
}
