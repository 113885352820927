<div class="coverages-limits justify-content-between">
  <div class="title my-auto">Coverages &amp; limits</div>
  <span *permission='PermissionEnum.CUSTOMER_COVERAGES_LIMITS_RELATION_CREATE'>
    <button *ngIf="!product.groupProfileProductId" class="primary-button my-auto" (click)="addNewCoverage()">Add new coverage &amp; limit</button>
  </span>

</div>
<div>
  <table class="primary-table">
    <thead>
      <tr>
        <td class="sort filter-column" sortColumn [sortKey]="'coverageSortOrder'" [data]="coverages">Sort order</td>
        <td class="id" sortColumn [sortKey]="'id'" [data]="coverages">Coverage Terms Ref.</td>
        <td sortColumn [sortKey]="'coverageName'" [data]="coverages">Coverage Name</td>
        <td class="limit">Limit</td>
        <td sortColumn [sortKey]="'coverageType'" [data]="coverages">Coverage Type</td>
        <td class="edit" *ngIf='editable()'></td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let coverage of coverages;  let i = index " [ngClass]="{ 'inherited': !!coverage.groupProfileCoverageId }">
        <td class="text-center">{{coverage.coverageSortOrder}}</td>
        <td class="text-center pl-0">{{coverage.coverageTermsRef}}</td>
        <td>{{coverage.coverageName}}{{coverage.coverageNameAlias ? ' (' + coverage.coverageNameAlias + ')' : ''}}</td>
        <td>
          <span *ngIf="coverage.limits.length > 0">
            {{ coverage.limits[0].limitMaxValue }}
            <span *ngIf="coverage.limits[0].limitType === getLimitTypeEnum.AMOUNT">
              {{ coverage.limits[0].limitUnit }}</span>
            <span *ngIf="coverage.limits[0].limitType === getLimitTypeEnum.DURATION">
              {{ coverageLimitPeriodTypes(coverage.limits[0].limitUnit) }} from
              {{ coverageLimitFromTypes(coverage.limits[0].limitValue) }}</span>
            <span *ngIf="coverage.limits[0].limitType === getLimitTypeEnum.NUMBER_OF_PERSONS">
              {{ coverageLimitPersonTypes(coverage.limits[0].limitUnit) }}
              {{ coverageLimitFromTypes(coverage.limits[0].limitValue) }}</span>
            <span *ngIf="coverage.limits[0].limitType === getLimitTypeEnum.QUANTITY">
              {{ coverageLimitTreatmentsTypes(coverage.limits[0].limitUnit) }}
              {{ coverageLimitFromTypes(coverage.limits[0].limitValue) }}</span>
            <img *ngIf="coverage.limits.length > 1" src="assets/icons/Warning.svg" (click)="setSelect(i)">
            <div *ngIf="getSelected() === i" (click)="closeLimits()" class="grayBackground"></div>
            <div *ngIf="getSelected() === i">
              <app-limits-field [limits]="coverage.limits"></app-limits-field>
            </div>
          </span>
        </td>
        <td>{{ coverageTypes.get(coverage.coverageType) }}</td>
        <td class="flex" *ngIf='editable()'>
          <div class="d-flex">
            <span *permission='PermissionEnum.CUSTOMER_COVERAGES_LIMITS_RELATION_UPDATE'>
              <button *ngIf="!coverage.groupProfileCoverageId" class="primary-button-outline my-auto" (click)="editCoverage(coverage)">Edit</button>
            </span>
            <span *permission='PermissionEnum.CUSTOMER_COVERAGES_LIMITS_RELATION_DELETE'>
              <button *ngIf="!coverage.groupProfileCoverageId" class="btn" (click)="showDeleteModal(coverage.id, coverage.coverageName)"><img src="assets/icons/garbage-red.svg"
                  alt="Delete coverage and limits" /></button>
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<app-confirm-modal #deleteConfirmModal (confirmed)="deleteCoverage()" confirmBtnLabel="Yes" cancelBtnLabel="No">
</app-confirm-modal>
<app-coverage-limits-modal #coverageLimitsModal (refreshCoverages)="refreshCoverages($event)"></app-coverage-limits-modal>
