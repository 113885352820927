
export class MasterListCausesLevel1Model {
  id: string;
  businessArea: string;
  level1Code: string;
  level1: string;
  uiSortIndex: number;
  activateOn: Date;
  deactivateOn: Date;
  active: boolean;
  level2Causes: MasterListCausesLevel2Model[];
  applications: string[];

  public constructor(init?: Partial<MasterListCausesLevel1Model>) {
    Object.assign(this, init);
  }
}

export class MasterListCausesLevel2Model {
  id: string;
  businessArea: string;
  level2Code: string;
  level2: string;
  uiSortIndex: number;
  activateOn: Date;
  deactivateOn: Date;
  active: boolean;
  level3Causes: MasterListCausesLevel3Model[];
  applications: string[];

  public constructor(init?: Partial<MasterListCausesLevel2Model>) {
    Object.assign(this, init);
  }
}

export class MasterListCausesLevel3Model {
  id: string;
  businessArea: string;
  level3Code: string;
  level3: string;
  uiSortIndex: number;
  activateOn: Date;
  deactivateOn: Date;
  active: boolean;
  applications: string[];

  public constructor(init?: Partial<MasterListCausesLevel3Model>) {
    Object.assign(this, init);
  }
}
