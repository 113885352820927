<div class="title">
  Markets
</div>
<div class="market-list">
  <ul class="market-list-column">
    <li class="market-list-item" *ngFor="let market of firstMarkets">
      {{market}}
    </li>
  </ul>
  <ul class="market-list-column">
    <li class="market-list-item" *ngFor="let market of secondMarkets">
        {{market}}
    </li>
  </ul>
</div>
