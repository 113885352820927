<div class="common-content-container">
  <div class="common-content-container-title">Profile settings</div>
  <div class="common-content-container-body">
    <div class="input-name">Profile name<span class="required">*</span></div>
    <div class="profile-name-input">
      <app-input (valueChange)="nameChanges($event)" [(value)]="profile.name"></app-input>
    </div>
    <div class="input-name">Profile Code<span class="required">*</span></div>
    <div class="profile-code-input">
      <app-input (valueChange)="onCodeChange($event)" [(value)]="profile.code" [maxTextLength]="30" [disabled]="profile.id"></app-input>
    </div>
    <div *ngIf="profile.code && !isCodeValid && profileCodeViolations.length > 0" class="notification">
      Profile code {{profile.code}} is already used by profile {{profileCodeViolations.join('')}}
    </div>
    <div *ngIf="!isGroupProfile(profile)" class="input-name">Group member ID for mapping</div>
    <div *ngIf="!isGroupProfile(profile)" class="profile-code-input">
      <app-input (valueChange)="onGroupMemberIdForMappingChange($event)" [(value)]="profile.groupMemberIdForMapping"></app-input>
    </div>
    <div *ngIf="profile.groupMemberIdForMapping && !isGroupMemberIdForMappingValid && groupMemberIdForMappingViolations.length > 0" class="notification">
      Group member ID for mapping {{profile.groupMemberIdForMapping}} is already used by profile {{groupMemberIdForMappingViolations.join('')}}
    </div>
    <div class="input-name">Market<span class="required">*</span></div>
    <div class="dropdown-market">
      <app-drop-down-with-auto-complete-countries
        [autoCompleteTypeEnum]="AutoCompleteTypeEnum.Countries"
        [withInput]="false"
        [countryName]="countryName"
        [(selectedItemId)]="profile.market"
        (selectedItemIdChange)="formChanged()"
        [showCode]="false"
        [disabled]="!isGroupProfile(profile)"
      >
      </app-drop-down-with-auto-complete-countries>
    </div>
    <div class="d-flex businessAreaRow">
      <div class="checkbox-container">
        <div class="input-name">Business Area<span class="required">*</span></div>
        <div class="businessAreaCheckboxWrapper">
          <input
            class="businessAreaCheckbox"
            type="checkbox"
            (change)="(!profile.businessAreasTravel); checkBoxChange()"
            [(ngModel)]="profile.businessAreasTravel"
            [disabled]="!isGroupProfile(profile)"
          />Travel
        </div>
      </div>
      <div class="kam-container">
        <div class="input-name">Responsible KAM<span class="required">*</span></div>
        <div class="dropdown">
          <app-kam-drop-down
            [(selectedKamId)]="profile.responsibleKamTravelUserId"
            [(selectedKamFirstName)]="profile.responsibleKamTravelFirstName"
            [(selectedKamSurname)]="profile.responsibleKamTravelSurname"
            [(selectedKamEmail)]="profile.responsibleKamTravelEmail"
            [(selectedKamPhone)]="profile.responsibleKamTravelPhone"
            (selectedKamIdChange)="formChanged()"
            [disabled]="!profile.businessAreasTravel"
          >
          </app-kam-drop-down>
        </div>
      </div>
      <div class="case-number-pre-fix-container">
        <div class="input-name">Case number prefix<span class="required">*</span></div>
        <div class="case-number-pre-fix">
          <app-input
            (valueChange)="prefixCheck(BusinessAreaEnum.TRAVELCARE, $event)"
            [(value)]="profile.caseNumberPreFixTravel"
            [disabled]="!profile.businessAreasTravel || !isGroupProfile(profile)"
          ></app-input>
        </div>
      </div>
      <div class="profile-integrationtype-container">
        <div class="input-name">Case Information<span class="required">*</span></div>
        <select
          class="input-component"
          [(ngModel)]="profile.travelcareCaseInformation"
          (ngModelChange)="formChanged()"
          [disabled]="!profile.businessAreasTravel"
        >
          <option *ngFor="let item of CaseInformationEnumKeys" [value]="item">{{ CaseInformationEnum[item] }}</option>
        </select>
      </div>
      <div class="profile-integrationtype-container">
        <div class="input-name">Invoicing<span class="required">*</span></div>
        <select
          class="input-component"
          [(ngModel)]="profile.travelcareInvoicing"
          (ngModelChange)="formChanged()"
          [disabled]="!profile.businessAreasTravel"
        >
          <option *ngFor="let item of InvoicingEnumKeys" [value]="item">{{ InvoicingEnum[item] }}</option>
        </select>
      </div>
    </div>
    <div class="d-flex businessAreaRow">
      <div class="checkbox-container">
        <div class="businessAreaCheckboxWrapper">
          <input
            class="businessAreaCheckbox"
            type="checkbox"
            (change)="(!profile.businessAreasRoadside); checkBoxChange()"
            [(ngModel)]="profile.businessAreasRoadside"
            [disabled]="!isGroupProfile(profile)"
          />Roadside
        </div>
      </div>
      <div class="kam-container">
        <div class="dropdown">
          <app-kam-drop-down
            [(selectedKamId)]="profile.responsibleKamRoadsideUserId"
            [(selectedKamFirstName)]="profile.responsibleKamRoadsideFirstName"
            [(selectedKamSurname)]="profile.responsibleKamRoadsideSurname"
            [(selectedKamEmail)]="profile.responsibleKamRoadsideEmail"
            [(selectedKamPhone)]="profile.responsibleKamRoadsidePhone"
            (selectedKamIdChange)="formChanged()"
            [disabled]="!profile.businessAreasRoadside"
          >
          </app-kam-drop-down>
        </div>
      </div>
      <div class="case-number-pre-fix-container">
        <div class="case-number-pre-fix">
          <app-input
            (valueChange)="prefixCheck(BusinessAreaEnum.MOBILITY, $event)"
            [(value)]="profile.caseNumberPreFixRoadside"
            [disabled]="!profile.businessAreasRoadside || !isGroupProfile(profile)"
          ></app-input>
        </div>
      </div>
      <div class="profile-integrationtype-container">
        <select
          class="input-component"
          [(ngModel)]="profile.mobilityCaseInformation"
          (ngModelChange)="formChanged()"
          [disabled]="!profile.businessAreasRoadside"
        >
          <option *ngFor="let item of CaseInformationEnumKeys" [value]="item">{{ CaseInformationEnum[item] }}</option>
        </select>
      </div>
      <div class="profile-integrationtype-container">
        <select
          class="input-component"
          [(ngModel)]="profile.mobilityInvoicing"
          (ngModelChange)="formChanged()"
          [disabled]="!profile.businessAreasRoadside"
        >
          <option *ngFor="let item of InvoicingEnumKeys" [value]="item">{{ InvoicingEnum[item] }}</option>
        </select>
      </div>
    </div>
    <div class="d-flex businessAreaRow">
      <div class="checkbox-container">
        <div class="businessAreaCheckboxWrapper">
          <input
            class="businessAreaCheckbox"
            type="checkbox"
            (change)="(!profile.businessAreasHealth); checkBoxChange()"
            [(ngModel)]="profile.businessAreasHealth"
            [disabled]="!isGroupProfile(profile)"
          />Health
        </div>
      </div>
      <div class="kam-container">
        <div class="dropdown">
          <app-kam-drop-down
            [(selectedKamId)]="profile.responsibleKamHealthUserId"
            [(selectedKamFirstName)]="profile.responsibleKamHealthFirstName"
            [(selectedKamSurname)]="profile.responsibleKamHealthSurname"
            [(selectedKamEmail)]="profile.responsibleKamHealthEmail"
            [(selectedKamPhone)]="profile.responsibleKamHealthPhone"
            (selectedKamIdChange)="formChanged()"
            [disabled]="!profile.businessAreasHealth"
          >
          </app-kam-drop-down>
        </div>
      </div>
      <div class="case-number-pre-fix-container">
        <div class="case-number-pre-fix">
          <app-input
            (valueChange)="prefixCheck(BusinessAreaEnum.HEALTHCARE, $event)"
            [(value)]="profile.caseNumberPreFixHealth"
            [disabled]="!profile.businessAreasHealth || !isGroupProfile(profile)"
          ></app-input>
        </div>
      </div>
      <div class="profile-integrationtype-container">
        <select
          class="input-component"
          [(ngModel)]="profile.healthcareCaseInformation"
          (ngModelChange)="formChanged()"
          [disabled]="!profile.businessAreasHealth"
        >
          <option *ngFor="let item of CaseInformationEnumKeys" [value]="item">{{ CaseInformationEnum[item] }}</option>
        </select>
      </div>
      <div class="profile-integrationtype-container">
        <select
          class="input-component"
          [(ngModel)]="profile.healthcareInvoicing"
          (ngModelChange)="formChanged()"
          [disabled]="!profile.businessAreasHealth"
        >
          <option *ngFor="let item of InvoicingEnumKeys" [value]="item">{{ InvoicingEnum[item] }}</option>
        </select>
      </div>
    </div>
    <div class="input-name">Internal remarks</div>
    <div class="profile-remarks-input">
      <textarea name="remarks" rows="4" [(ngModel)]="profile.remarks" (ngModelChange)="formChanged()" [maxLength]="1000"></textarea>
    </div>
    <div class="profile-change-container-footer">
      <button class="primary-button" (click)="addNewProfile()" [ngClass]="!isProfileValid && 'button-disabled'">Save changes</button>
    </div>
  </div>
</div>

<div class="common-delete-container justify-content-between">
  <div class="text my-auto">
    <div class="black-text">Delete Profile</div>
    <div class="gray-text" *ngIf="!isDeletable()">
      This profile cannot be deleted since it is applied to a contract or customer products have been added to the profile
    </div>
  </div>
  <button
    class="primary-button my-auto"
    [disabled]="!isDeletable()"
    [ngClass]="{ 'button-disabled': !isDeletable() }"
    (click)="deleteProfile()"
  >
    Delete profile
  </button>
</div>
