export class RuleMapping {
  id: number;
  cpId: number;
  active: boolean;
  ruleEventId: number;
  ruleActionId: number;
  ruleId: number;
  actionInputId: number;
  name: string;
  type: string;
  actionInput: string;
  remarks: string;

  public constructor(init?: Partial<RuleMapping>) {
    Object.assign(this, init);
  }
}
