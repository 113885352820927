<div *ngIf="customerListView" class="brand-summary-container">
  <div class="brand-summary-content">
    <div class="general-info-column">
      <div class="title">
        Group segment
      </div>
      <div class="value">
        {{customerListView.groupSegment.name}}
      </div>
      <div class="title">
        SOS owner
      </div>
      <div class="value"> {{customerListView.sosOwner | YesNo}}</div>
      <div class="title">
        Sub-business relation
      </div>
      <div> {{customerListView.subBusinessRelation.name}}</div>
    </div>
    <div class="markets-column">
      <app-market-summary [markets]="markets"></app-market-summary>
    </div>
    <div class="business-areas-column">
      <app-business-area-summary [areas]="areas"></app-business-area-summary>
    </div>
    <div class="profiles-column">
      <div class="profiles"> {{numberOfProfiles}}</div>
      <div class="title">Profiles</div>
    </div>
    <div class="KAM-column">
      <app-kam-info-view [kam]="kam"></app-kam-info-view>
    </div>
  </div>
</div>
