<div class="menu">
  <ul class="nav justify-content-center">
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'overview'}"  (click)="shownSection = 'overview'">
        <div>Overview</div>
      </div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'settings'}"  (click)="shownSection = 'settings'">
        <div>Settings</div>
      </div>
    </li>
  </ul>
</div>
<app-brand-overview *ngIf="shownSection === 'overview'" [customerListView]="customerListView" [profiles]='profiles'
                              (refreshBrands)="refreshProfilesEvent($event)" [numberOfProfiles]="numberOfProfiles"></app-brand-overview>
<app-brand-settings *ngIf="shownSection === 'settings'" [customerListViewOriginal]='customerListView'></app-brand-settings>
