import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import {ProfileApplication} from '../../shared/models/profile-application';
import {CaseProcessProfile, CaseProcessProfileAdapter} from '@onepoint/shared/models/case-process-profile';
import {CaseProcess, CaseProcessAdapter} from '@onepoint/shared/models/case-process';

@Injectable({
  providedIn: 'root',
})
export class CaseProcessService extends BaseService {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private caseProcessAdapter: CaseProcessAdapter,
    private caseProcessProfileAdapter: CaseProcessProfileAdapter
  ) {
    super(settingsService);
  }

  public getCaseProcessTypes(): Observable<CaseProcess[]> {
    return this.http.get(this.baseURL + 'case-processes/case-process-types', {headers : this.jsonHeaders})
      .pipe(map((data: any[]) => data.map(item => this.caseProcessAdapter.adapt(item))));
  }

  public getDescriptionForCaseProcessType(caseProcessType: string): Observable<string> {
    return this.http.get<string>(this.baseURL + 'case-processes/description/' + caseProcessType);
  }

  public updateCaseProcessDescription(caseProcessType: string, description: string): Observable<ProfileApplication[]> {
    return this.http.post<ProfileApplication[]>(this.baseURL + 'case-processes/description/' + caseProcessType, description, {
      headers: this.jsonHeaders,
    });
  }

  public getCaseProcessProfilesByCaseProcessType(caseProcessType: string): Observable<CaseProcessProfile[]> {
    return this.http.get(this.baseURL + `case-processes/case-process-type/${caseProcessType}`, {headers : this.jsonHeaders})
      .pipe(map((data: any[]) => data.map(item => this.caseProcessProfileAdapter.adapt(item))));
  }

  public updateCaseProcessProfiles(caseProcessProfiles: CaseProcessProfile[]): Observable<CaseProcessProfile[]> {
    return this.http.post<CaseProcessProfile[]>(this.baseURL + 'case-processes/update', caseProcessProfiles, {
      headers: this.jsonHeaders,
    });
  }
}
