import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MasterListService } from 'app/core/services/masterlist.service';
import { OnepointModalService, Size } from 'app/core/services/onepoint.modal.service';
import { MasterListServiceItemModel } from 'app/shared/models/masterlist-service-item-model';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import { Dictionary } from 'app/shared/models/dictionary';
import { MasterListCausesLevel1Model } from 'app/shared/models/masterlist-cause-model';
import { CausesLevel1MenuType } from '../causes-menu/masterlist-causes-menu-type';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
    selector: 'app-masterlist-causes-level1',
    templateUrl: './masterlist-causes-level1.component.html',
    styleUrls: ['./masterlist-causes-level1.component.scss'],
})
export class MasterListCausesLevel1Component implements OnInit {
  @ViewChild('MasterListModal')
  private masterListModalTemplate: TemplateRef<any>;

  masterList: MasterListCausesLevel1Model[];
  masterListFiltered: MasterListCausesLevel1Model[];
  masterListItem: MasterListCausesLevel1Model;
  showMenu = new Map<CausesLevel1MenuType, boolean>();
  statusTypes: Dictionary[] = [{id:'all', name:'All'}, {id:'active', name:'Active'}, {id:'inactive', name:'Inactive'}];
  selectedStatus = 'all';

  constructor(private masterListService: MasterListService,
              private onepointModalService: OnepointModalService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {    
  }

  ngOnInit(): void {
    this.loadMasterList();
  }
  
  showFilteringSortingMenu(filteringSortingMenuType: CausesLevel1MenuType) {
    this.showMenu.forEach((value, key) => {
      if (key !== filteringSortingMenuType) {
        this.showMenu.set(key, false);
      }
    });

    this.showMenu.set(filteringSortingMenuType, !this.showMenu.get(filteringSortingMenuType));
  }

  isShowMenu(filteringSortingMenuType: CausesLevel1MenuType) {
    return this.showMenu.get(filteringSortingMenuType);
  }

  addNew(): void {    
    this.masterListItem = new MasterListCausesLevel1Model({businessArea: 'Travel'});

    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.NORMAL, 'causes-modal-container');
  }

  edit(item: MasterListServiceItemModel): void {
    this.masterListItem = _.clone(item);
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.NORMAL, 'causes-modal-container');
  }

  showLevel2(level1Item: MasterListCausesLevel1Model) {
    this.router.navigate([level1Item.level1Code], {state: {level1: level1Item.level1}, relativeTo: this.activatedRoute});
  }

  selectedStatusTypeChanged(status: string): void {
    this.masterListFiltered = this.filterByStatus(status);
  }

  reload(): void {
    this.loadMasterList();
  }

  get CausesMenuType() {
    return CausesLevel1MenuType;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  private loadMasterList() {
    this.masterListService.getCausesLevel1().subscribe(
      response => {
        this.masterList = response
        this.masterListFiltered = this.filterByStatus(this.selectedStatus);
      }
    )
  }

  private filterByStatus(status: string): MasterListCausesLevel1Model[] {
    if ( status === 'all' ) {
      return this.masterList;
    }

    return this.masterList.filter(item => status === 'active' ? item.active : !item.active);
  }
}
