import { Injectable } from '@angular/core';
import { SosServicesMenuType } from './sos-services-menu-type';
import { SosServices } from 'app/shared/models/sos-services';

@Injectable({
  providedIn: 'root'
})
export class FilteringService {
  filteringStep: FilteringStep[] = [];

  public addStep(
    filteringSortingMenuType: SosServicesMenuType,
    selectedIds: string[],
    dateFrom: Date = null,
    dateTo: Date = null
  ) {
    this.filteringStep.push(
      new FilteringStep(filteringSortingMenuType, selectedIds, dateFrom, dateTo)
    );
  }

  public removeStep(filteringSortingMenuType: SosServicesMenuType) {
    let itemToDelete = this.filteringStep.find(
      a => a.filteringSortingMenuType === filteringSortingMenuType
    );
    if (itemToDelete) {
      this.filteringStep.splice(this.filteringStep.indexOf(itemToDelete));
    }
  }

  public filter(sosServicesListView: SosServices[]): SosServices[] {
    let tempSosServicesListView: Set<SosServices>;

    this.filteringStep.forEach(step => {
      tempSosServicesListView = new Set();
      switch (step.filteringSortingMenuType) {
        case SosServicesMenuType.name:
          step.selectedIds.forEach(element => {
            sosServicesListView
              .filter(a => a.name === element)
              .forEach(a => tempSosServicesListView.add(a));
          });
          break;
        case SosServicesMenuType.category:
          step.selectedIds.forEach(element => {
            sosServicesListView
              .filter(a => a.sosServicesCategory === element)
              .forEach(a => tempSosServicesListView.add(a));
          });
          break;
        case SosServicesMenuType.feeType:
          step.selectedIds.forEach(element => {
            sosServicesListView
              .filter(a => a.feeType === element || a?.extension.feeType === element)
              .forEach(a => tempSosServicesListView.add(a));
          });
          break;
        case SosServicesMenuType.validStart:
          sosServicesListView
            .filter(
              element =>
                step.dateFrom <= new Date(element.validStart) &&
                step.dateTo >= new Date(element.validStart)
            )
            .forEach(a => tempSosServicesListView.add(a));
            break;
        case SosServicesMenuType.validEnd:
          sosServicesListView
            .filter(
              element =>
                step.dateFrom <= new Date(element.validEnd) &&
                step.dateTo >= new Date(element.validEnd)
            )
            .forEach(a => tempSosServicesListView.add(a));
            break;
      }

      sosServicesListView = Array.from(tempSosServicesListView);
    });
    return sosServicesListView;
  }
}

export class FilteringStep {
  filteringSortingMenuType: SosServicesMenuType;
  selectedIds: string[];
  dateFrom: Date;
  dateTo: Date;
  constructor(
    filteringSortingMenuType: SosServicesMenuType,
    selectedIds: string[],
    dateFrom: Date,
    dateTo: Date
  ) {
    this.filteringSortingMenuType = filteringSortingMenuType;
    this.selectedIds = selectedIds;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
  }
}
