import { Component, OnInit, ViewChild } from '@angular/core';
import { Dictionary } from '../../../shared/models/dictionary';
import { CustomerRelationLabelStatusEnum, CustomerRelationLabelStatusForSearchEnum } from '../../../shared/enums/customer-relation-label-status.enum';
import { DictionaryService } from '../../../core/services/dictionary.service';
import { CustomerRelationLabel } from '../../../shared/models/customer-relation-label';
import { AddCustomerProfileLabelComponent  } from '../add-customer-profile-label/add-customer-profile-label.component';
import { PolicyLookupMethodService } from '../../../core/services/policy-lookup-method.service';


@Component({
  selector: 'app-customer-profile-relation',
  templateUrl: './customer-profile-relation.component.html',
  styleUrls: ['./customer-profile-relation.component.scss']
})
export class CustomerProfileRelationComponent implements OnInit {

  @ViewChild('addCustomerProfileLabelComponent')
  addCustomerProfileLabelComponent: AddCustomerProfileLabelComponent;
  customerRelationLabel: CustomerRelationLabel[] = [];
  originalCustomerRelationLabels: CustomerRelationLabel[] = [];
  customerRelationLabelsToUpdate: CustomerRelationLabel[] = [];
  selectStatus: string;
  dropDownStatusList: Dictionary[] = [];
  dropDownStatusListForSearch: Dictionary[] = [];
  relations: [];
  searchText: string;
  statusTypeSearch: CustomerRelationLabelStatusForSearchEnum = CustomerRelationLabelStatusForSearchEnum.ACTIVE;

  constructor( private dictionaryService: DictionaryService,
               private policyLookupMethodService: PolicyLookupMethodService ) {
    this.dropDownStatusList = this.dictionaryService.getCustomerRelationLabelStatus();
    this.dropDownStatusListForSearch = this.dictionaryService.getCustomerRelationLabelStatusForSearch();
  }

  ngOnInit() {
    this.policyLookupMethodService.getCustomerRelationLabels().subscribe(
      result => {
        this.customerRelationLabel = result;
        this.originalCustomerRelationLabels = JSON.parse(JSON.stringify(this.customerRelationLabel));
        this.statusDropDownChange();
      },
      error => console.error(error)
    );
  }

  sortByName() {
    this.customerRelationLabel.sort((a, b) => a.name.localeCompare(b.name));
  }

  statusDropDownChange() {
    if (this.statusTypeSearch !== CustomerRelationLabelStatusForSearchEnum.ALL) {
      this.customerRelationLabel = JSON.parse(JSON.stringify(this.originalCustomerRelationLabels.filter(customerRelationLabel => {
        return  (customerRelationLabel.status.includes(CustomerRelationLabelStatusEnum[this.statusTypeSearch]));
      })));
    } else {
      this.customerRelationLabel = JSON.parse(JSON.stringify(this.originalCustomerRelationLabels));
    }
    this.sortByName();
  }

  get CustomerRelationLabelStatusEnum() {
    return CustomerRelationLabelStatusEnum;
  }

  addNewLabel() {
    this.addCustomerProfileLabelComponent.open();
  }

  searchTable() {
    if (this.searchText != null && this.searchText.length > 0) {
      this.searchText = this.searchText.toLowerCase();
      this.customerRelationLabel = JSON.parse(JSON.stringify(this.originalCustomerRelationLabels.filter(customerRelationLabel => {
        return  (customerRelationLabel.name.toLowerCase().includes(this.searchText) || customerRelationLabel.remarks.toLowerCase().includes(this.searchText));
      })));
    } else {
      this.customerRelationLabel = JSON.parse(JSON.stringify(this.originalCustomerRelationLabels));
      this.statusDropDownChange();
    }
  }

  addCustomerProfileLabel(customerRelationLabel: CustomerRelationLabel) {
    this.policyLookupMethodService.createCustomerRelationLabel(customerRelationLabel).subscribe(
      result => {
        this.originalCustomerRelationLabels.push(result);
        this.searchTable();
      },
      error => console.error(error)
    );
  }

  checkIfTheDataCanBeSaved(){
    let nameExistId;
    this.customerRelationLabelsToUpdate = [];
    this.customerRelationLabel.forEach(customerRelationLabel => {
      const origLabel =  this.originalCustomerRelationLabels.find(label => label.id === customerRelationLabel.id);
      if (origLabel.name !== customerRelationLabel.name) {
        nameExistId = this.originalCustomerRelationLabels.find(label => (label.name.toLowerCase() === customerRelationLabel.name.toLowerCase()) && (label.id !== customerRelationLabel.id));
      }
      if (!nameExistId && customerRelationLabel.name !== ''){
        if (origLabel.name.toLowerCase() !== customerRelationLabel.name.toLowerCase() ||
            origLabel.remarks.toLowerCase() !== customerRelationLabel.remarks.toLowerCase() ||
            origLabel.status !== customerRelationLabel.status ) {
              this.customerRelationLabelsToUpdate.push(customerRelationLabel);
        }
      }
    });
  }

  saveLabels() {
    if (this.customerRelationLabelsToUpdate.length !== 0) {
      this.policyLookupMethodService.updateCustomerRelationLabels(this.customerRelationLabelsToUpdate).subscribe(
        result => {
          this.customerRelationLabel = result;
          this.originalCustomerRelationLabels = JSON.parse(JSON.stringify(this.customerRelationLabel));
          this.customerRelationLabelsToUpdate = [];
          this.statusDropDownChange();
        }
      );
    }
  }
}