<div class="page-container">
    <div class="page-header header">
        <app-drop-down class="dropdown" [name]="'Category'" [items]="slaCategoryDictionary" [(selectedItemId)]="currentFilter"
                       (selectedItemIdChange)="applyFilter($event)"></app-drop-down>
        <button class="primary-button button" (click)="addNewSla()">Add new SLA</button>
    </div>
    <div class="page-content-container">
        <table class="primary-table sos-services-table">
            <thead>
              <tr>
                <td sortColumn [sortKey]="'category'" [data]="slas" >
                  Category
                </td>
                <td sortColumn [sortKey]="'name'" [data]="slas" >
                  Name
                </td>
                <td sortColumn [sortKey]="'startEvent'" [data]="slas" >
                  Start event
                </td>
                <td sortColumn [sortKey]="'endEvent'" [data]="slas" >
                  End Event
                </td>
                <td sortColumn [sortKey]="'deadlineTime'" [data]="slas" >
                  Deadline
                </td>
                <td sortColumn [sortKey]="'requirement'" [data]="slas" >
                  Requirement
                </td>
                <td sortColumn [sortKey]="'standard'" [data]="slas" >
                  Standard
                </td>
              </tr>
            </thead>
            <tbody class="theBody">
              <ng-container *ngFor="let sla of filteredSlas; index as i">
                <tr (click)="showExistingSla(sla.id)">
                  <td>
                    {{ sla.category.name }}
                  </td>
                  <td>
                    {{ sla.name }}
                  </td>
                  <td>
                    {{ concatEventNames(sla.startEvents) }}
                  </td>
                  <td>
                    {{ concatEventNames(sla.endEvents) }}
                  </td>
                  <td>
                    {{ sla.deadlineTime }}
                  </td>
                  <td>
                    {{ sla.requirement }}
                  </td>
                  <td>
                    {{ sla.standard }}
                  </td>
              </ng-container>
            </tbody>
          </table>
    </div>
</div>


<app-add-sla #modal (addSla)="reload()"></app-add-sla>
