export enum ChannelTypeMenuType {
  CHANNEL_TYPE_CODE = 'channelTypeCode',
  CHANNEL_TYPE_NAME = 'channelTypeName',
  CHANNEL_TYPE_ENUM_VALUE = 'channelTypeEnumValue',
  UI_SORT_INDEX = 'uiSortIndex',
  DESCRIPTION = 'description',
  ACTIVATE_ON = 'activateOn',
  DEACTIVATION_ON = 'deactivateOn',
  ORDER = 'order',
}
