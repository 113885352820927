import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Debtor } from '../../../../../shared/models/debtor';
import { DictionaryService } from '../../../../../core/services/dictionary.service';
import { Dictionary } from '../../../../../shared/models/dictionary';
import { DebtorService } from '../../../../../core/services/debtor.service';
import { OnepointModalService, Size } from '../../../../../core/services/onepoint.modal.service';

@Component({
  selector: 'app-edit-debtor',
  templateUrl: './edit-debtor.component.html',
  styleUrls: ['./edit-debtor.component.scss'],
})
export class EditDebtorComponent implements OnInit {
  @ViewChild('content') private template: TemplateRef<any>;
  @Input() private commonCustomerId: number;
  @Output() reloadDebtors = new EventEmitter();
  debtor: Debtor;
  originalDebtor: Debtor;
  allowSubmit: boolean;
  erpClients: Dictionary[];
  billingCurrencies: Dictionary[];
  paymentTerms: Dictionary[];
  invoiceFrequencies: Dictionary[];
  errorMessage: string|null = null;

  constructor(
    private dictionaryService: DictionaryService,
    private debtorService: DebtorService,
    private modalService: OnepointModalService
  ) {}

  ngOnInit() {
    this.dictionaryService.getErpClients().subscribe(
      result => (this.erpClients = result),
      error => console.error(error)
    );
    this.dictionaryService.getBillingCurrencies().subscribe(
      result => (this.billingCurrencies = result),
      error => console.error(error)
    );
    this.dictionaryService.getPaymentTerms().subscribe(
      result => (this.paymentTerms = result),
      error => console.error(error)
    );
    this.dictionaryService.getInvoiceFrequencies().subscribe(
      result => (this.invoiceFrequencies = result),
      error => console.error(error)
    );
  }

  public openNew() {
    this.open(new Debtor(null, '', this.commonCustomerId));
  }

  public open(debtor: Debtor) {
    this.errorMessage = null;
    this.originalDebtor = debtor;
    this.debtor = JSON.parse(JSON.stringify(debtor));
    this.calculateAllowSubmit();
    this.modalService.open(this.template, Size.LARGE);
  }

  close() {
    this.modalService.dismissAll();
  }

  calculateAllowSubmit() {
    const valid =
      this.debtor.name &&
      this.debtor.number &&
      !isNaN(+this.debtor.number) &&
      this.debtor.erpClient.id != null &&
      this.debtor.billingCurrency.id != null &&
      this.debtor.paymentTerm.id != null &&
      this.debtor.invoiceFrequency.id != null &&
      this.debtor.sosServicesSeparateInvoice != null &&
      this.debtor.maxInvoicesPerDay &&
      Math.sign(this.debtor.maxInvoicesPerDay) === 1 &&
      this.debtor.claimsCostPartialInvoicingLimit &&
      Math.sign(this.debtor.claimsCostPartialInvoicingLimit) === 1;
    if (this.isNewDebtor()) {
      this.allowSubmit = valid;
    }
    this.allowSubmit = valid && JSON.stringify(this.debtor) !== JSON.stringify(this.originalDebtor);
  }

  isNewDebtor() {
    return this.debtor.id == null;
  }

  addNewDebtor() {
    this.errorMessage = null;
    this.debtorService.saveDebtor(this.commonCustomerId, this.debtor).subscribe(
      result => {
        this.debtor = result;
        this.close();
        this.reloadDebtors.emit();
      },
      error => {
        this.handleSubmitError(error);
      }
    );
  }

  private handleSubmitError(error: any) {
    if (error.status === 409 && error.error.code === 'ALREADY_EXISTS') {
      this.errorMessage = error.error.message;
    } else {
      console.error(error);
    }
  }

  updateDebtor() {
    this.errorMessage = null;
    this.debtorService.updateDebtor(this.commonCustomerId, this.debtor).subscribe(
      result => {
        this.debtor = result;
        this.close();
        this.reloadDebtors.emit();
      },
      error => {
        this.handleSubmitError(error);
      }
    );
  }
}
