import {ActivatedRoute} from '@angular/router';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Product} from '../../../shared/models/product';
import {ProductService} from '../../../core/services/product.service';
import {AutoUnsubscribe} from '../../../shared/decorators/auto-unsubscribe';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html'
})
@AutoUnsubscribe
export class ProductPageComponent implements OnInit {

  product: Product;

  private productSubscription: Subscription;

  constructor(private productService: ProductService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.loadProduct();
  }

  loadProduct() {
    this.productSubscription = this.route.params.subscribe(params => {
      this.productService
        .getProduct(params.id)
        .subscribe(
          result => (this.product = result),
          error => console.log(error)
        );
    });
  }
}
