import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {SlaRule} from "@onepoint/shared/models/sla-rule";
import {OnepointModalService, Size} from "@onepoint/core/services/onepoint.modal.service";
import {SlaRuleService} from "@onepoint/core/services/sla-rule.service";
import {Dictionary} from "@onepoint/shared/models/dictionary";

@Component({
  selector: 'app-add-sla-rule',
  templateUrl: './add-sla-rule.component.html',
  styleUrls: ['./add-sla-rule.component.scss']
})
export class AddSlaRuleComponent implements OnInit {

  @Output() addSlaRule = new EventEmitter();
  @ViewChild('content')
  private template: TemplateRef<any>;
  slaRule: SlaRule;
  valid: boolean;
  calculatedTypes: Dictionary[];
  ruleTypes: Dictionary[];
  caseTypes: Dictionary[];

  constructor(private modalService: OnepointModalService, private slaRuleService: SlaRuleService) {

  }

  ngOnInit(): void {
    this.slaRuleService.getSlaRuleCaseTypes().subscribe( result => {
      this.caseTypes = result.map(type => new Dictionary(type, type));
    });
    this.slaRuleService.getSlaCalculatedEventTypes().subscribe( result => {
      this.calculatedTypes = result.map(type => new Dictionary(type, type));
    });
    this.slaRuleService.getSlaRuleTypes().subscribe( result => {
      this.ruleTypes = result.map(type => new Dictionary(type, type));
    });
  }

  openNew() {
    this.slaRule = new SlaRule();
    this.modalService.open(this.template, Size.NORMAL);
  }

  openExisting(slaRule: SlaRule) {
    this.slaRuleService.getSlaRule(slaRule.id).subscribe(
      result => {
        this.slaRule = result;
        this.modalService.open(this.template, Size.NORMAL);
      }
    )
  }

  close() {
    this.modalService.dismissAll();
  }

  save() {
    this.slaRuleService.saveSlaRule(this.slaRule).subscribe(
      result => {
        this.addSlaRule.emit();
        this.modalService.dismissAll();
      }
    )
  }

  isValid() {
    this.valid = false;
    switch (this.slaRule.type) {
      case 'GRACE_PERIOD':
        this.valid = this.slaRule.gracePeriodStart !== undefined && this.slaRule.gracePeriodStart !== null && this.slaRule.gracePeriodEnd !== undefined && this.slaRule.gracePeriodEnd !== null;
        break;
      case 'GRACE_PERIOD_LOCAL':
        this.valid = this.slaRule.gracePeriodStart !== undefined && this.slaRule.gracePeriodStart !== null && this.slaRule.gracePeriodEnd !== undefined && this.slaRule.gracePeriodEnd !== null;
        break;
      case 'START_EVENT_CALCULATED_FROM_CASE':
        this.valid = this.slaRule.calculatedEventType !== null;
        break;
      case 'IS_CORRECT_CASE_TYPE':
        this.valid = this.slaRule.slaCaseType !== null;
        break;
      default:
        this.valid = this.slaRule.type !== null;
    }
    if (this.valid && (this.slaRule.name === null || this.slaRule.name === undefined || this.slaRule.name.length === 0)) {
      this.valid = false;
    }
  }


}
