import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelperService } from 'app/core/services/helper.service';
import { MasterlistContactRoleModel } from '@onepoint/shared/models/masterlist-contact-role-model';

@Component({
  selector: 'app-masterlist-sorting-menu',
  templateUrl: './masterlist-sorting-menu.component.html',
  styleUrls: ['./masterlist-sorting-menu.component.scss'],
})
export class MasterlistSortingMenuComponent {
  @Input() masterList: MasterlistContactRoleModel[];
  @Input() sortingKey: string;
  @Output() modelChanged = new EventEmitter();
  sortByAscending: boolean;
  dateFilter: boolean;
  showFilter: boolean;
  fromDate: any;
  toDate: any;
  constructor(private helperService: HelperService) { }

  sortAscending() {
    this.sortByAscending = true;
    this.helperService.sortArray(this.masterList, this.sortingKey, this.sortByAscending);
    this.modelChanged.emit();
  }

  sortDescending() {
    this.sortByAscending = false;
    this.helperService.sortArray(this.masterList, this.sortingKey, this.sortByAscending);
    this.modelChanged.emit();
  }
}
