import {Injectable} from '@angular/core';
import {Adapter} from '../interfaces/adapter';
import {ApplicationTypeEnum} from './enums';

export class ProfileApplication {
  id: number;
  type: ApplicationTypeEnum;
  code: string;
  profileId: number;
  profileName: string;
  brandName: string;
  brandId: number;
  commonCustomerName: string;
  commonCustomerId: number;
  market: string;
  included: boolean;
  stillLegacy: boolean;
  validFrom: Date;
  validTo: Date;
  legacyCode: string;
  changed = false;

  public constructor(init?: Partial<ProfileApplication>) {
    Object.assign(this, init);
  }

}

@Injectable({
  providedIn: 'root',
})
export class ProfileApplicationAdapter implements Adapter<ProfileApplication> {
  constructor() {}

  adapt(item: any): ProfileApplication {
    const profileApplication = new ProfileApplication({
      id: item.id,
      type: item.type,
      code: item.code,
      profileId: item.profileId,
      profileName: item.profileName,
      brandName: item.brandName,
      brandId: item.brandId,
      commonCustomerName: item.commonCustomerName,
      commonCustomerId: item.commonCustomerId,
      market: item.market,
      included: item.included,
      stillLegacy: item.stillLegacy,
      validFrom: item.validFrom ? new Date(item.validFrom) : null,
      validTo: item.validTo ? new Date(item.validTo) : null,
      legacyCode: item.legacyCode
    });
    return profileApplication;
  }
}
