<div class="diagnosis-modal-container">
  <div class="closing-x" (click)="closeModal()">
    <img src="/assets/icons/Close.svg" />
  </div>
  <div class="header">
    <span *ngIf="isNew">Create new question</span>
    <span *ngIf="!isNew">Edit question</span>
  </div>
  <div class="row body-container" *ngIf="masterListType">
    <div class="col-3">
      <div class="input-name">
        Activate on
        <span *ngIf="!isReadonly" class="required">*</span>
      </div>
      <app-input-calendar
        [withImage]="true"
        [selectMode]="'single'"
        [disabled]="isReadonly"
        [(dateTimeRange)]="masterListType.activateOn"
        [maxDate]="masterListType.deactivateOn"
        (saveModel)="activateOnChanged(masterListType)"
      >
      </app-input-calendar>
      <div class="input-name">Deactivate after</div>
      <app-input-calendar
        [withImage]="true"
        [selectMode]="'single'"
        [disabled]="isReadonly"
        [(dateTimeRange)]="masterListType.deactivateOn"
        [minDate]="minDeactivationDate"
        (saveModel)="deactivateOnChanged(masterListType)"
      >
      </app-input-calendar>
      <div class="label" style="font-size: 14px;
      color: #787878;" [ngClass]="'input-name'">Based on case creation time</div>
    </div>
    <div class="col-5">
      <div>
        <app-input
          [name]="'Business key'"
          [(value)]="masterListType.questionCode"
          (valueChange)="modelChanged()"
          [required]="!isReadonly"
          [disabled]="isReadonly || !!masterListType.id"
          [maxTextLength]="10"
        ></app-input>
      </div>
      <div class="padding-top">
        <app-drop-down [name]="'Flow'" [(selectedItemId)]="masterListType.flow" [items]="flowTypes"
                [required]="!isReadonly" [disabled]="isReadonly"
                (selectedItemIdChange)="selectedFlowValueChanged($event)">
              </app-drop-down>
      </div>
      <div class="padding-top">
        <div class="field-title">Question name<span class="required" *ngIf="!isReadonly">*</span></div>
        <textarea rows="4" [(ngModel)]="masterListType.questionName" (ngModelChange)="modelChanged()" [disabled]="isReadonly" maxlength="200"></textarea>
      </div>
      <div class="padding-top">
        <app-input
          [name]="'Sort in UI'"
          [(value)]="masterListType.uiSortIndex"
          (valueChange)="modelChanged()"
          [disabled]="isReadonly"
        ></app-input>
      </div>
    </div>
    <div class="col-4">
      <div *ngIf='masterListType.translationsAvailable'>
        <div *ngFor="let lang of languages | keyvalue: keepOrder; let i = index" [class.padding-top]="i >= 1">
          <div class='input'>
            <span class='input-name'>Translation - {{lang.key}}</span>
            <input class='input-component text-truncate' disabled [value]='masterListType.translationsAvailable[lang.value] || ""' [title]='masterListType.translationsAvailable[lang.value] || ""'>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row body-container" *ngIf="error">
    <div class="col-12 error">
      {{ error }}
    </div>
  </div>
  <div class="masterlist-modal-footer d-flex justify-content-between">
    <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE" class="">
      <button class="primary-button save-button" [class.button-disabled]="!isSaveButtonEnabled" (click)="save()">Save</button>
    </div>
    <app-last-changed [masterListItem]="masterListType" class="last-changed float-end"> </app-last-changed>
  </div>
</div>
