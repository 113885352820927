<div class="profile-country-lists-container">
  <div class="profile-country-lists-header justify-content-between">
    <span class="country-lists-number my-auto">Country lists ({{ numberOfCountryLists }})</span>
    <div class="add-new-country-list-container float-end">
      <button class="primary-button my-auto" (click)="addNewCountrylist()">
        Add new country list
      </button>
    </div>
  </div>
  <app-profile-country-lists-table [countryLists]="countryLists" (editOnClick)="showEditModal($event)" (deleteOnClick)="showDeleteModal($event)"></app-profile-country-lists-table>
  <app-profile-country-list #profileCountryList [profileId]="profileId"  (changed)="handleChange()"></app-profile-country-list>
  <app-warning-modal #cannotDeleteWarningModal></app-warning-modal>
  <app-confirm-modal #deleteConfirmModal (confirmed)="deleteList($event)" confirmBtnLabel="Confirm"></app-confirm-modal>
</div>
