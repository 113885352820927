import { Component, EventEmitter, Output, ViewChild, TemplateRef } from '@angular/core';
import { OnepointModalService, Size } from '@onepoint/core/services/onepoint.modal.service';
import { DigitalCaseflowService } from '@onepoint/core/services/digital-caseflow.service';
import { Activity } from '@onepoint/shared/models/digital-caseflow/activity';
import { Dictionary } from '@onepoint/shared/models/dictionary';
import { MasterListService } from '@onepoint/core/services/masterlist.service';
import { map } from 'rxjs/operators';
import { MasterListAdTeamsModel } from '@onepoint/shared/models/masterlist-ad-teams-model';

@Component({
  selector: 'app-edit-dcf-activity',
  templateUrl: './edit-dcf-activity.component.html',
  styleUrls: ['./edit-dcf-activity.component.scss']
})
export class EditDcfActivityComponent {
  @ViewChild('content')
  private template: TemplateRef<any>;
  @Output() updateCallback = new EventEmitter<any>();
  updateNeeded: boolean;
  deletePossible = false;
  allActivityTypes: Dictionary[];
  serviceOrderCreationTypes: Dictionary[];
  stakeholderTypes: Dictionary[];
  serviceTypes: Dictionary[];
  commitmentTypes: Dictionary[] = [];
  handlingAreaTypes: Dictionary[] = [];
  handlingAreaStatus: Dictionary[] = [];
  sanctionCheckTypes: Dictionary[] = [];
  taskAssigneeTypes: Dictionary[] = [];
  adTeams: Dictionary[] = [];
  channelTypes: Dictionary[] = [];
  private adTeamsLoading = false;
  purposesOfTravel: Dictionary[] = [];
  requiredInputTypes: string[];
  activity: Activity;

  constructor(
    private modalService: OnepointModalService,
    private digitalCaseflowService: DigitalCaseflowService,
    private masterListService: MasterListService) {
    this.requiredInputTypes = [];
    digitalCaseflowService.getExectutionTypes().subscribe(data => {
      this.allActivityTypes = data.map(activityType => new Dictionary(activityType, activityType));
    });

    digitalCaseflowService.getActivityInputStakeholderTypes().subscribe(data => {
      this.stakeholderTypes = data.map(stakeholderType => new Dictionary(stakeholderType, stakeholderType));
    });

    digitalCaseflowService.getActivityInputServiceOrderCreationTypes().subscribe(data => {
      this.serviceOrderCreationTypes = data.map(serviceOrderCreationType => new Dictionary(serviceOrderCreationType, serviceOrderCreationType));
    });

    digitalCaseflowService.getActivityInputServiceTypes().subscribe(data => {
      this.serviceTypes = data.map(serviceOrder => new Dictionary(serviceOrder, serviceOrder));
    });

    digitalCaseflowService.getActivityInputHandlingAreaTypes().subscribe(data => {
      this.handlingAreaTypes = data.map(handlingAreaType => new Dictionary(handlingAreaType, handlingAreaType));
    });

    digitalCaseflowService.getActivityInputHandlingAreaStatus().subscribe(data => {
      this.handlingAreaStatus = data.map(handlingAreaStatus => new Dictionary(handlingAreaStatus, handlingAreaStatus));
    });
    digitalCaseflowService.getActivityInputSanctionCheckTypes().subscribe(data => {
      this.sanctionCheckTypes = data.map(sanctionCheckType => new Dictionary(sanctionCheckType, sanctionCheckType));
    });
    digitalCaseflowService.getTaskAssigneeTypes().subscribe(data => {
      this.taskAssigneeTypes = data.map(taskAssigneeType => new Dictionary(taskAssigneeType, taskAssigneeType));
    });
    this.digitalCaseflowService.getAllChannels().subscribe(data => {
      this.channelTypes = data.map(channelType => new Dictionary(channelType, channelType));
    });
  }

  public open(activity: Activity) {
    this.activity = activity;
    if (activity.type) {
      this.reloadRequiredInput(activity);
    } else {
      this.requiredInputTypes = [];
    }
    if (activity.id) {
      this.reloadCommitmentTypes();
      this.digitalCaseflowService.isActivityDeletionPossible(activity.id).subscribe(data => {
        this.deletePossible = data;
      });
      this.ensureAdTeamsLoaded();
    }
    this.modalService.open(this.template, Size.NORMAL);
  }

  close() {
    this.updateCallback.emit();
    this.modalService.dismissAll();
  }

  save() {
    this.cleanInput();
    this.digitalCaseflowService.updateActivity(this.activity).subscribe(
      data => {
        this.updateCallback.emit();
        this.modalService.dismissAll();
      }
    );
  }

  delete() {
    if (this.deletePossible) {
      this.digitalCaseflowService.deleteActivity(this.activity.id).subscribe(
        data => {
          this.updateCallback.emit();
          this.modalService.dismissAll();
        }
      );
    }
  }

  taskAssigneeChanged() {
    this.ensureAdTeamsLoaded();
    this.validate();
  }

  validate() {
    this.updateNeeded = true;

    if (this.activity.name == null || this.activity.name.length === 0 || this.activity.type == null ||
      (this.requiredInputTypes.indexOf('LENGTH') >= 0 && this.activity.input.length == null) ||
      (this.requiredInputTypes.indexOf('STAKEHOLDER_TYPE') >= 0 && this.activity.input.stakeholderType == null) ||
      (this.requiredInputTypes.indexOf('TEMPLATE_NAME') >= 0 && this.activity.input.templateName == null) ||
      (this.requiredInputTypes.indexOf('SERVICE_TYPE') >= 0 && this.activity.input.serviceType == null) ||
      (this.requiredInputTypes.indexOf('COMMITMENT_TYPE') >= 0 && this.activity.input.commitmentType == null) ||
      (this.requiredInputTypes.indexOf('HANDLING_AREA_TYPE') >= 0 && this.activity.input.handlingAreaType == null) ||
      (this.requiredInputTypes.indexOf('HANDLING_AREA_STATUS') >= 0 && this.activity.input.handlingAreaStatus == null) ||
      (this.requiredInputTypes.indexOf('SANCTION_CHECK_EXPORT_TYPE') >= 0 && this.activity.input.sanctionCheckType == null) ||
      (this.requiredInputTypes.indexOf('SERVICE_ORDER_CREATION_TYPE') >= 0 && this.activity.input.serviceOrderCreationType == null) ||
      (this.requiredInputTypes.indexOf('TASK_ASSIGNEE') >= 0 &&
        (this.activity.input.taskAssigneeType == null ||
          this.activity.input.taskAssigneeType === 'FIXED_TEAM' && this.activity.input.taskFixedTeam == null)) ||
      (this.requiredInputTypes.indexOf('TASK_TITLE') >= 0 &&
        (this.activity.input.taskTitle == null || this.activity.input.taskTitle.length === 0)) ||
      (this.requiredInputTypes.indexOf('TASK_BODY_TEXT') >= 0 &&
        (this.activity.input.taskBodyText == null || this.activity.input.taskBodyText.length === 0)) ||
      (this.requiredInputTypes.indexOf('TASK_DEFAULT_CHANNEL') >= 0 && this.activity.input.taskDefaultChannel == null) ||
      (this.requiredInputTypes.indexOf('PURPOSE_OF_TRAVEL') >= 0 && this.activity.input.purposeOfTravel == null)
    ) {
      this.updateNeeded = false;
    }
  }

  shouldShow(type: string) {
    return this.requiredInputTypes && this.requiredInputTypes.indexOf(type) >= 0;
  }

  typeChanged() {
    this.reloadRequiredInput(this.activity);
  }

  serviceTypeChanged() {
    this.reloadCommitmentTypes();
    this.validate();
  }

  setIncluded(included: boolean) {
    this.activity.input.mandatory = included;
    this.validate();
  }

  private reloadCommitmentTypes() {
    if (!!this.activity.input.serviceType) {
      this.masterListService.getBusinessKeyValuesWithoutGopByServiceType(this.activity.input.serviceType).subscribe(data => {
        this.commitmentTypes = data;
      });
    }
  }

  private cleanInput() {
    if (!this.requiredInputTypes.includes('LENGTH')) {
      this.activity.input.length = null;
    }
    if (!this.requiredInputTypes.includes('STAKEHOLDER_TYPE')) {
      this.activity.input.stakeholderType = null;
    }
    if (!this.requiredInputTypes.includes('TEMPLATE_NAME')) {
      this.activity.input.templateName = null;
    }
    if (!this.requiredInputTypes.includes('SERVICE_TYPE')) {
      this.activity.input.serviceType = null;
    }
    if (!this.requiredInputTypes.includes('COMMITMENT_TYPE')) {
      this.activity.input.commitmentType = null;
    }
    if (!this.requiredInputTypes.includes('SERVICE_ORDER_CREATION_TYPE')) {
      this.activity.input.serviceOrderCreationType = null;
    }
    if (!this.requiredInputTypes.includes('HANDLING_AREA_TYPE')) {
      this.activity.input.handlingAreaType = null;
    }
    if (!this.requiredInputTypes.includes('HANDLING_AREA_STATUS')) {
      this.activity.input.handlingAreaStatus = null;
    }
    if (!this.requiredInputTypes.includes('SANCTION_CHECK_EXPORT_TYPE')){
      this.activity.input.sanctionCheckType = null;
    }
    if (!this.requiredInputTypes.includes('TASK_ASSIGNEE')){
      this.activity.input.taskAssigneeType = null;
      this.activity.input.taskFixedTeam = null;
    } else {
      if (this.activity.input.taskAssigneeType !== 'FIXED_TEAM') {
        this.activity.input.taskFixedTeam = null;
      }
    }
    if (!this.requiredInputTypes.includes('TASK_TITLE')){
      this.activity.input.taskTitle = null;
    }
    if (!this.requiredInputTypes.includes('TASK_BODY_TEXT')){
      this.activity.input.taskBodyText = null;
    }
    if (!this.requiredInputTypes.includes('PURPOSE_OF_TRAVEL')){
      this.activity.input.purposeOfTravel = null;
    }
  }

  private reloadRequiredInput(activity: Activity) {
    this.digitalCaseflowService.getRequiredActivityInput(activity.type).subscribe(data => {
      this.requiredInputTypes = data;
      this.validate();
    });
  }

  private ensureAdTeamsLoaded() {
    if (this.adTeamsLoading || this.adTeams.length > 0) {
      return;
    }
    if (this.activity.input.taskAssigneeType === 'FIXED_TEAM') {
      this.adTeamsLoading = true;
      this.masterListService.getAdTeams()
        .pipe(
          map((data: MasterListAdTeamsModel[]) => data.map(adTeam => new Dictionary(adTeam.adTCode, adTeam.teamName)))
        )
        .subscribe(data => {
          this.adTeams = data;
          this.adTeamsLoading = false;
      });
    }
  }
}
