<div class="page-container">
  <div class="page-header">
    <div class="my-auto counts-container">
      <div class="count-container">
        <span>Sanction Countries</span> <span>{{ masterListFiltered?.length }}</span>
      </div>
    </div>
    <div class="status-container my-auto">
      <app-drop-down [(selectedItemId)]="selectedStatus" [items]="statusTypes" (selectedItemIdChange)="selectedStatusTypeChanged($event)">
      </app-drop-down>
    </div>
    <div class="button-container my-auto">
      <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_CREATE">
        <button class="primary-button" (click)="addNew()">
          Add Sanction Country
        </button>
      </div>
    </div>

  </div>
  <div class="primary-table page-content-container">
    <table class="master-lists-table">
      <thead>
        <tr>
          <td (click)="showFilteringSortingMenu(SanctionCountryMenuType.SANCTION_COUNTRY_CODE)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Business key</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[SanctionCountryMenuType.SANCTION_COUNTRY_CODE] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="SanctionCountryMenuType.SANCTION_COUNTRY_CODE"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
              </div>
          </td>
          <td (click)="showFilteringSortingMenu(SanctionCountryMenuType.SANCTION_COUNTRY_NAME)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Country name</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[SanctionCountryMenuType.SANCTION_COUNTRY_NAME] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="SanctionCountryMenuType.SANCTION_COUNTRY_NAME"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(SanctionCountryMenuType.SANCTION_ALPHA_COUNTRY_CODE)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Alpha Country Code</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[SanctionCountryMenuType.SANCTION_ALPHA_COUNTRY_CODE] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="SanctionCountryMenuType.SANCTION_ALPHA_COUNTRY_CODE"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(SanctionCountryMenuType.LIMIT_SERVICE_TYPES)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Change case type to Sanction case - Limited</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[SanctionCountryMenuType.LIMIT_SERVICE_TYPES] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="SanctionCountryMenuType.LIMIT_SERVICE_TYPES"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(SanctionCountryMenuType.INFO_TASK)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Info task</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[SanctionCountryMenuType.INFO_TASK] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="SanctionCountryMenuType.INFO_TASK"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(SanctionCountryMenuType.SCREENING)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Screening</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[SanctionCountryMenuType.SCREENING] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="SanctionCountryMenuType.SCREENING"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(SanctionCountryMenuType.ACTIVATE_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Activation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[SanctionCountryMenuType.ACTIVATE_ON] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="SanctionCountryMenuType.ACTIVATE_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(SanctionCountryMenuType.DEACTIVATION_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Deactivation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[SanctionCountryMenuType.DEACTIVATION_ON] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="SanctionCountryMenuType.DEACTIVATION_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of masterListFiltered" (click)="edit(item)">
          <td>
            <div class="first-column-container">
              <div class="first-column-content">
                {{ item.code }}
              </div>
            </div>
          </td>
          <td>
            {{ item.name }}
          </td>
          <td>
            {{ item.alphaCountryCode }}
          </td>
          <td class="align-center">
            <input type="checkbox" [checked]="item.limitServiceTypes" disabled="true" />
          </td>
          <td class="align-center">
            <input type="checkbox" [checked]="item.infoTask" disabled="true" />
          </td>
          <td class="align-center">
            <input type="checkbox" [checked]="item.screening" disabled="true" />
          </td>
          <td>
            {{ item.activateOn | date: 'dd MMM yyyy' }}
          </td>
          <td>
            {{ item.deactivateOn | date: 'dd MMM yyyy' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-template #MasterListModal>
    <app-masterlist-sanction-country-modal [masterListItem]="masterListType" (refreshTable)="reload()"> </app-masterlist-sanction-country-modal>
  </ng-template>
</div>
