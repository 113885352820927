<div *ngIf="sosServicesCategoryEnum[categoryId] === sosServicesCategoryEnum.SHARE_OF_COST_CONTROL ||
            sosServicesCategoryEnum[categoryId] === sosServicesCategoryEnum.COMMUNICATION_COST ||
            sosServicesCategoryEnum[categoryId] === sosServicesCategoryEnum.BANK_FEE ||
            sosServicesCategoryEnum[categoryId] === sosServicesCategoryEnum.SHARE_OF_RECOVERY
">
  <div class="d-flex price-row" [class.service-not-included]="!priceAgreementService.included">
    <div class="price-col" *ngFor="let share of priceAgreementService.shares; index as idx">
      <input *ngIf="categoryIncluded && priceAgreementService.included" [tabIndex]="idx"
        currencyMask
        [options]="percentageOptions"
        [disabled]="sharesDisabled(idx)"
        [(ngModel)]="shares[idx]"
        (focusout)="updateShares($event, idx)"/>
    </div>
  </div>
</div>
<div *ngIf="sosServicesCategoryEnum[categoryId] !== sosServicesCategoryEnum.COMMUNICATION_COST && sosServicesCategoryEnum[categoryId] !== sosServicesCategoryEnum.BANK_FEE">
  <div class="d-flex price-row" [class.service-not-included]="!priceAgreementService.included">
      <div class="price-col" *ngFor="let price of priceAgreementService.prices; index as idx">
        <input *ngIf="categoryIncluded && priceAgreementService.included" [tabIndex]="idx"
               [name]="priceAgreementService.id + '-' + idx"
               currencyMask
               [options]="options"
               [disabled]="priceInputDisabled(idx)"
               [(ngModel)]="prices[idx]"
               (focusout)="updatePrices($event, idx)"/>
      </div>
  </div>
</div>
