import { UpdateService } from 'app/core/services/update.service';
import { BrandAdapter } from './../../../shared/models/brand';
import { CustomerListView } from './../../../shared/models/customer-list-view';
import { CommonCustomerService } from './../../../core/services/common-customer.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Brand } from 'app/shared/models/brand';
import {CommonCustomer} from '../../../shared/models/common-customer';

@Component({
  selector: 'app-common-customer-page',
  templateUrl: './common-customer-page.component.html',
  styleUrls: ['./common-customer-page.component.scss']
})
export class CommonCustomerPageComponent implements OnInit, OnDestroy {
  private sub: any;
  commonCustomer: CustomerListView;
  brands: CustomerListView[];
  numberOfBrands: number;
  numberOfProfiles: number;
  constructor(
    private route: ActivatedRoute,
    private commonCustomerService: CommonCustomerService,
    private brandAdapter: BrandAdapter,
    private updateService: UpdateService
  ) {
    this.brands = [];
  }

  ngOnInit() {
    this.loadCommonCustomer();
    this.updateService.updateBrandListWhenBrandIsDeleted.subscribe( value => {
      if (value === true) {
          this.loadCommonCustomer();
      }
    });
  }

  loadCommonCustomer() {
    this.sub = this.route.params.subscribe(params => {
      this.commonCustomerService
        .getCommonCustomerListView(params.id)
        .subscribe(
          result => {
            this.commonCustomer = result;
            this.numberOfBrands = result.children.length;
            this.brands = result.children.map(b => {
              return this.brandAdapter.adaptToCommonCustomerListView(b);
            })
            this.brands.sort((a, b) => a.market.localeCompare(b.market, 'da-DK') || a.name.localeCompare(b.name, 'da-DK'));
          },
          error => console.log(error)
        );
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  refreshBrandsEvent(brand: Brand) {
    this.numberOfBrands++;
    this.brands.push(this.brandAdapter.adaptToCommonCustomerListView(brand));
  }
}
