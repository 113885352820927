import { DineroObject } from 'dinero.js';
import { CaseType } from './case-type';
import { SosServicesType } from './sos-services-type';
import { SosServicesItem } from './sos-services-item';
import { FeeTypeEnum, SosServicesCategoryEnum, PriceIndexEnum, MedicalServiceOptionsEnum } from './enums';
import { CommunicationCost } from './communication-cost';
import { CausesLevel1 } from './CausesLevel1';
import { Dictionary } from './dictionary';
import { Cause } from '@onepoint/shared/models/cause';
import {BankFee} from "@onepoint/shared/models/bank-fee";

export class SosServices {
  id: string;
  sosServiceId: string;
  name: string;
  sosServicesCategory: string;
  sosServicesCategoryEnum: SosServicesCategoryEnum;
  validStart: Date;
  validEnd: Date;
  serviceItemOverride: string;
  feeType: string;
  feeTypeEnum: FeeTypeEnum;
  feePriority: number;
  commitmentType: string[];
  medicalServiceOptions: string;
  medicalServiceOptionsEnum: MedicalServiceOptionsEnum;
  caseType: CaseType[];
  causesLevel1: CausesLevel1[];
  causesLevel2: Cause[];
  allCausesLevel2: boolean;
  refundTypes: Dictionary[];
  sosServicesType: SosServicesType[];
  includedInStandard: boolean;
  ignoreServiceItems: boolean;
  ignoreAsSuggested: boolean;
  multipleDateRange: boolean;
  vatErpClient: Dictionary[];
  vat: string;
  targetPrice: DineroObject;
  targetPriceYear: string;
  targetShare: number;
  targetMaxCap: DineroObject;
  sosServicesItem: SosServicesItem[];
  refundSosServicesItem: SosServicesItem[];
  recoverySosServicesItem: SosServicesItem[];
  internalNote: string;
  description: string;
  visible: true;
  bankFees: BankFee[];
  communicationCosts: CommunicationCost[];
  communicationCostCurrency: string;
  communicationCostMinimum: number;
  communicationCostMaximum: number;
  communicationCostDefault: number;
  priceIndexEnum: PriceIndexEnum;
  defaultPriceIndex: string;
  extension: SosServices;
  extensionType: string;

  public constructor(init?: Partial<SosServices>) {
    Object.assign(this, init);
    this.communicationCosts = [];
    if (init && init.communicationCosts) {
      for (const originalCommCost of init.communicationCosts) {
        this.communicationCosts.push(new CommunicationCost(originalCommCost));
      }
    }
  }

  prepareForSend() {
    const causeLevels2 = [];
    this.causesLevel2.filter(cause => cause.id !== 'All').forEach(cause => causeLevels2.push(cause));
    this.causesLevel2 = causeLevels2;
    if (this.extensionType === 'NONE') {
      this.extension = null;
    } else {
      const extCauseLevel2 = [];
      this.extension.causesLevel2.filter(cause => cause.id !== 'All').forEach(cause => extCauseLevel2.push(cause));
      this.extension.causesLevel2 = extCauseLevel2;
    }
    return this;
  }
}
