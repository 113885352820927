import {Component, OnInit} from '@angular/core';
import { MasterListService } from 'app/core/services/masterlist.service';
import { PermissionService } from 'app/core/services/permission.service';
import { MasterListApplicationModel } from 'app/shared/models/masterlist-application-model';
import { MasterListApplicationType } from 'app/shared/models/masterlist-applicationtype';
import { MasterListOverviewModel } from 'app/shared/models/masterlist-overview-model';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import { finalize } from 'rxjs/operators';
import { MASTER_LISTS } from '../common/common';
import { MasterlistApplicationsMenuType } from './applications-menu/masterlist-applications-menu-type';

@Component({
  selector: 'app-masterlist-applications',
  templateUrl: './masterlist-applications.component.html',
  styleUrls: ['./masterlist-applications.component.scss']
})
export class MasterListApplicationsComponent implements OnInit {
  masterListApplication: MasterListApplicationModel[];
  masterListApplicationFiltered: MasterListApplicationModel[];
  showMenu = new Map<MasterlistApplicationsMenuType, boolean>();
  applicationTypes: MasterListApplicationType[];
  masterLists: MasterListOverviewModel[] = MASTER_LISTS;
  selectedApplications = {};
  selectedMasterlist = '';
  selectedStatus = 'all';
  allSelected = {};
  isApplicationsValid = false;
  canUpdate = false;
  saveInProgress = false;

  constructor(private masterListService: MasterListService,
              private permissionService: PermissionService) {
  }

  ngOnInit(): void {
    this.permissionService.permissionsReadyNotification.subscribe(
      result =>
        this.canUpdate = this.permissionService.checkUserPermission(PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE)
    )

    this.loadMasterListApplicationTypes();
  }
  
  updateApplications(): void {
    this.updateMasterListApplication();
  }

  showFilteringSortingMenu(filteringSortingMenuType: MasterlistApplicationsMenuType): void {
    this.showMenu.forEach((value,key) => {
      if ( key !== filteringSortingMenuType ) {
        this.showMenu[key] = false;
      }
    });

    this.showMenu[filteringSortingMenuType] = !this.showMenu[filteringSortingMenuType];
  }

  selectedStatusTypeChanged(status: string): void {
    this.masterListApplicationFiltered = this.filterByStatus(status);
  }

  selectedMasterlistChanged(selectedMasterlist: string): void {
    this.loadMasterListApplication();
  }

  checkboxValueChanged(applicationType: string, value: boolean) {
    this.allSelected[applicationType] = this.isAllSelected(applicationType);
    this.isApplicationsValid = true;
  }

  selectAllCheckboxValueChanged(applicationType: string, value: boolean): void {
    this.checkAll(applicationType, value);
    this.allSelected[applicationType] = this.isAllSelected(applicationType);
    this.isApplicationsValid = true;
  }

  get MasterlistApplicationsMenuType() {
    return MasterlistApplicationsMenuType;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get isReadonly(): boolean {
    return !this.canUpdate;
  }

  get isSaveButtonEnabled(): boolean {
    return this.isApplicationsValid &&
           this.canUpdate &&
           !this.saveInProgress;
  }

  private loadMasterListApplicationTypes(): void {
    this.masterListService.getApplicationTypes().subscribe(
      result => this.applicationTypes = result
    )
  }

  private loadMasterListApplication(): void {
    this.isApplicationsValid = false;
    this.masterListApplication = [];
    this.masterListApplicationFiltered = [];
    this.selectedApplications = {};
    this.allSelected = {};

    if ( this.selectedMasterlist ) {
      this.masterListService.getApplicationsByMasterlist(this.selectedMasterlist).subscribe(
        result => {
          this.masterListApplication = result
          this.masterListApplicationFiltered = this.filterByStatus(this.selectedStatus);
          this.selectedApplications = this.getSelectedApplications();
          this.updateAllSelected();      
      });
    }
  }

  private updateMasterListApplication(): void {
    this.saveInProgress = true;
    this.masterListApplicationFiltered.forEach(item => {
      if ( this.selectedApplications[item.id] ) {
        const arr = Array.from(new Map(Object.entries(this.selectedApplications[item.id])));
        item.applications = arr.filter(([key, value]) => {
                                   return value;
                                 })
                                 .map(([key, value]) => {
                                   return key;
                                 });
      }
    })

    this.masterListService.updateApplications(this.selectedMasterlist, this.masterListApplicationFiltered).pipe(
      finalize(() => {
        this.saveInProgress = false;
        this.isApplicationsValid = false;
      })
    )
    .subscribe();
  }

  private filterByStatus(status: string): MasterListApplicationModel[] {
    if ( status === 'all' ) {
      return this.masterListApplication;
    }
    
    return this.masterListApplication.filter(item => status === 'active' ? item.active : !item.active);
  }

  private getSelectedApplications() {
    let selectedApplications = {};

    this.masterListApplicationFiltered.forEach(item => {
      selectedApplications[item.id] = {};
      item.applications?.forEach(type => selectedApplications[item.id][type] = true);
    })

    return selectedApplications;
  }

  private checkAll(applicationType: string, selected: boolean): void {
    this.masterListApplicationFiltered.forEach(item => {
      this.selectedApplications[item.id][applicationType] = selected;
    })
  }

  private updateAllSelected() {
    this.applicationTypes.forEach(applicationType => {
      this.allSelected[applicationType.type] = this.isAllSelected(applicationType.type);
    })
  }

  private isAllSelected(applicationType: string) {
    let allSelected = true;
    this.masterListApplicationFiltered.forEach(item => {      
      if ( !this.selectedApplications[item.id][applicationType] ) {
        allSelected = false;
      }
    })

    return allSelected;
  }
}
