<div class="page-container" *ngIf="filteredCustomerProductRelationOverviewList" >
    <div class="customer-product-relation-toolbar-container clearfix">
        <div class="customer-product-relation-toolbar-label">Credit card and business insurance relations</div>
        <div class="customer-product-relation-toolbar-filter float-start w-25 ms-4" style="font-size: 14px;">
            <app-drop-down
                [(selectedItemId)]="selectedFilterType"
                [disabled]="false"
                [items]="dropDownCustomerProductRelationFilterList"
                (selectedItemIdChange)="filterTable()"
                [name]="''">
            </app-drop-down>
        </div>
        <input class="float-end" type="text" [(ngModel)]="searchText" placeholder="Search..." (ngModelChange)="searchTable($event)" />
        <button class="primary-button ms-3" (click)="clearButton()">
            Clear all filters
        </button>
    </div>
    <table class="primary-table common-customers-table">
        <thead>
          <tr (clickOutside)="closeFilteringSortingMenu()">
            <td (click)="showFilteringSortingMenu(CustomerProfileRelationOverviewFilterSortType.type)" class="filter-column">
                <div [ngStyle]="!showFilterMenu[CustomerProfileRelationOverviewFilterSortType.type] && { display: 'none' }">
                    <app-customer-profile-relations-overview-sorting-menu
                    (modelChanged)="finishedAndFilteringSorting()"
                    [customerProfileRelationOverviewFilterSortType]="CustomerProfileRelationOverviewFilterSortType.type"
                    (clearAllFilters)="clearAllFiltering()"
                    [(customerProductRelationOverviewList)]="filteredCustomerProductRelationOverviewList"
                    [originalCustomerProductRelationOverviewList]="customerProductRelationOverviewList"
                     ></app-customer-profile-relations-overview-sorting-menu>
                </div>
                Type
            </td>
            <td sortColumn [sortKey]="'customerProductName'" [data]="searchResultCustomerProductRelationOverviewList" class="filter-column">
                Customer product name (version)
            </td>
            <td sortColumn [sortKey]="'insuranceCardLevel'" [data]="searchResultCustomerProductRelationOverviewList" class="filter-column">
                Insurance / Card level
            </td>
            <td (click)="showFilteringSortingMenu(CustomerProfileRelationOverviewFilterSortType.companyPolicyHolder)" class="filter-column">
                <div [ngStyle]="!showFilterMenu[CustomerProfileRelationOverviewFilterSortType.companyPolicyHolder] && { display: 'none' }">
                    <app-customer-profile-relations-overview-sorting-menu
                    (modelChanged)="finishedAndFilteringSorting()"
                    [customerProfileRelationOverviewFilterSortType]="CustomerProfileRelationOverviewFilterSortType.companyPolicyHolder"
                    (clearAllFilters)="clearAllFiltering()"
                    [(customerProductRelationOverviewList)]="filteredCustomerProductRelationOverviewList"
                    [originalCustomerProductRelationOverviewList]="customerProductRelationOverviewList"
                     ></app-customer-profile-relations-overview-sorting-menu>
                </div>
                Company policy holder
            </td>
            <td sortColumn [sortKey]="'policyNo'" [data]="searchResultCustomerProductRelationOverviewList" class="filter-column">
                Policy no.
            </td>
            <td (click)="showFilteringSortingMenu(CustomerProfileRelationOverviewFilterSortType.bank)" class="filter-column">
                <div [ngStyle]="!showFilterMenu[CustomerProfileRelationOverviewFilterSortType.bank] && { display: 'none' }">
                    <app-customer-profile-relations-overview-sorting-menu
                    (modelChanged)="finishedAndFilteringSorting()"
                    [customerProfileRelationOverviewFilterSortType]="CustomerProfileRelationOverviewFilterSortType.bank"
                    (clearAllFilters)="clearAllFiltering()"
                    [(customerProductRelationOverviewList)]="filteredCustomerProductRelationOverviewList"
                    [originalCustomerProductRelationOverviewList]="customerProductRelationOverviewList"
                     ></app-customer-profile-relations-overview-sorting-menu>
                </div>
                Bank
            </td>
            <td (click)="showFilteringSortingMenu(CustomerProfileRelationOverviewFilterSortType.bin)" class="filter-column">
                <div [ngStyle]="!showFilterMenu[CustomerProfileRelationOverviewFilterSortType.bin] && { display: 'none' }">
                    <app-customer-profile-relations-overview-sorting-menu
                    (modelChanged)="finishedAndFilteringSorting()"
                    [customerProfileRelationOverviewFilterSortType]="CustomerProfileRelationOverviewFilterSortType.bin"
                    (clearAllFilters)="clearAllFiltering()"
                    [(customerProductRelationOverviewList)]="filteredCustomerProductRelationOverviewList"
                    [originalCustomerProductRelationOverviewList]="customerProductRelationOverviewList"
                     ></app-customer-profile-relations-overview-sorting-menu>
                </div>
                BIN no.
            </td>
            <td sortColumn [sortKey]="'splitDateType'" [data]="searchResultCustomerProductRelationOverviewList" class="filter-column">
                Split date based on
            </td>
            <td (click)="showFilteringSortingMenu(CustomerProfileRelationOverviewFilterSortType.customerProfile)" class="filter-column">
                <div [ngStyle]="!showFilterMenu[CustomerProfileRelationOverviewFilterSortType.customerProfile] && { display: 'none' }">
                    <app-customer-profile-relations-overview-sorting-menu
                    (modelChanged)="finishedAndFilteringSorting()"
                    [customerProfileRelationOverviewFilterSortType]="CustomerProfileRelationOverviewFilterSortType.customerProfile"
                    (clearAllFilters)="clearAllFiltering()"
                    [(customerProductRelationOverviewList)]="filteredCustomerProductRelationOverviewList"
                    [originalCustomerProductRelationOverviewList]="customerProductRelationOverviewList"
                     ></app-customer-profile-relations-overview-sorting-menu>
                </div>
                Customer profile
            </td>
            <td (click)="showFilteringSortingMenu(CustomerProfileRelationOverviewFilterSortType.customerReportingLabel)" class="filter-column">
                <div [ngStyle]="!showFilterMenu[CustomerProfileRelationOverviewFilterSortType.customerReportingLabel] && { display: 'none' }">
                    <app-customer-profile-relations-overview-sorting-menu
                    (modelChanged)="finishedAndFilteringSorting()"
                    [customerProfileRelationOverviewFilterSortType]="CustomerProfileRelationOverviewFilterSortType.customerReportingLabel"
                    (clearAllFilters)="clearAllFiltering()"
                    [(customerProductRelationOverviewList)]="filteredCustomerProductRelationOverviewList"
                    [originalCustomerProductRelationOverviewList]="customerProductRelationOverviewList"
                     ></app-customer-profile-relations-overview-sorting-menu>
                </div>
                Customer reporting label
            </td>
            <td sortColumn [sortKey]="'validFrom'" [data]="searchResultCustomerProductRelationOverviewList" class="filter-column">
                Valid from
            </td>
            <td sortColumn [sortKey]="'validTo'" [data]="searchResultCustomerProductRelationOverviewList" class="filter-column">
                Valid to
            </td>
          </tr>
        </thead>
        <tbody >
            <tr *ngFor="let customerProductRelation of searchResultCustomerProductRelationOverviewList, let idx = index">
                <td>
                    {{ creditCardTypes.get(customerProductRelation.type) }}
                </td>
                <td (click)="goToProduct(customerProductRelation.customerProductId)" class="customerProductrelationLink">
                    {{ customerProductRelation.customerProductName }} ({{ customerProductRelation.customerProductVersion }})
                </td>
                <td class="insurance">
                    {{ customerProductRelation.insuranceCardLevel }}
                </td>
                <td class="companyPolicyHolder">
                    {{ customerProductRelation.companyPolicyHolder }}
                </td>
                <td>
                    {{ customerProductRelation.policyNo }}
                    <img *ngIf="customerProductRelation.remarks" src="assets/icons/Warning.svg" (click)="setSelect(idx)">
                    <div *ngIf="getSelected() === idx" (click)="closeProductRelation()" class="grayBackground"></div>
                    <div *ngIf="getSelected() === idx" class="remarkContainer">
                        <div class="position-relative fixed-top p-2 w100 remarkTop">Policy lookup remarks</div>
                        <div class="position-relative fixed-bottom p-2 w100 remarkBottom" >
                            <textarea
                                name="remarks"
                                rows="4"
                                [disabled]="true"
                                class="textRemark"
                                [(ngModel)]="customerProductRelation.remarks"
                                [maxLength]="250">
                          </textarea>
                        </div>
                    </div>
                </td>
                <td>
                    {{ customerProductRelation.bank }}
                </td>
                <td class="bin">
                    {{ customerProductRelation.bin }}
                </td>
                <td class="splitDateType">
                    {{ splitDateTypes.get(customerProductRelation.splitDateType) }}
                </td>
                <td>
                    {{ customerProductRelation.customerProfileName }}
                </td>
                <td>
                    {{ customerProductRelation.customerRelationLabel }}
                </td>
                <td>
                    {{ customerProductRelation.validFrom | date: 'dd MMM yyyy' }}
                </td>
                <td>
                    {{ customerProductRelation.validTo | date: 'dd MMM yyyy' }}
                </td>
            </tr>
        </tbody>
    </table>
</div>