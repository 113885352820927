<div class="product-settings-form-container" *ngIf="product">
  <div class="copy-member-container"*ngIf="isGroupProfile && !product.parentId">
    <div class="input-name">
      Product available on
      <span class="required">*</span>
    </div>
    <div class="radio-controls-container">
      <div class="custom-control custom-radio me-3">
        <input type="radio" [(ngModel)]="product.groupProfileOnly" [value]="false" name="memberCopy"
               [disabled]="!!product.parentId || !!product.groupProfileProductId" [checked]="product.groupProfileOnly == false"/>
        <label class="input-name">Group and member profiles</label>
      </div>
      <div class="custom-control custom-radio me-3">
        <input type="radio" [(ngModel)]="product.groupProfileOnly" [value]="true" name="memberCopy"
               [disabled]="!!product.parentId || !!product.groupProfileProductId" [checked]="product.groupProfileOnly == true"/>
        <label class="input-name">Group profile only</label>
      </div>
    </div>
  </div>
  <div class="type-container">
    <div class="input-name">
      Insurance type
      <span class="required">*</span>
    </div>
    <div class="radio-controls-container">
      <div class="custom-control custom-radio me-3">
        <input type="radio" [(ngModel)]="product.insuranceType" [value]="InsuranceType.regular" name="insuranceType"
               [disabled]="!!product.parentId || !!product.groupProfileProductId" [checked]="product.insuranceType == InsuranceType.regular"/>
        <label class="input-name">Regular travel insurance</label>
      </div>
      <div class="custom-control custom-radio me-3">
        <input type="radio" [(ngModel)]="product.insuranceType" [value]="InsuranceType.card" name="insuranceType"
               [disabled]="!!product.parentId || !!product.groupProfileProductId" [checked]="product.insuranceType == InsuranceType.card"/>
        <label class="input-name">Credit card travel insurance</label>
      </div>
    </div>
  </div>
  <div class="form-container">
    <div class="left-column-container">
      <app-input class="app-input" [(value)]="product.name" required="true" name='Name'
                 [disabled]="!!product.parentId || !!product.groupProfileProductId || newVersion"></app-input>
      <div *ngFor="let productId of product.customerProductIds; trackBy: trackByFn; let idx = index" class="d-flex">
        <app-input class="app-input flex-grow-1" [(value)]="product.customerProductIds[idx]" [disabled]="!!product.groupProfileProductId" required="true" name="Customer ID for mapping"></app-input>
        <div *ngIf="idx!==0" class="delete-btn d-flex"><button (click)="deleteProductId(idx)" class="btn"><img
          src="assets/icons/garbage-red.svg" alt="Delete Product Id"></button> </div>
      </div>
      <div *ngIf="!product.groupProfileProductId" class="add-button" (click)="addNewProductId()">Add customer ID for mapping <img src="assets/icons/add-new.svg" alt="+"></div>
      <app-input class="app-input" [(value)]="product.sosInsuranceLevel"
                 [name]="product.insuranceType === InsuranceType.regular ? 'Insurance level for case' : 'Card level for case'"
                 [required]="product.parentId != null || (product.insuranceLevels && product.insuranceLevels.length > 0)"
                 [disabled]="!!product.groupProfileProductId || newVersion"></app-input>
      <div *ngFor="let productId of product.insuranceLevels; trackBy: trackByFn; let idx = index" class="d-flex">
        <app-input class="app-input flex-grow-1" [(value)]="product.insuranceLevels[idx]" [name]=levelInputLabel()
                   [required]="product.parentId != null" [disabled]="!!product.groupProfileProductId || newVersion"></app-input>
        <div *ngIf="!product.groupProfileProductId" class="delete-btn d-flex"><button (click)="deleteLevel(idx)" class="btn"><img
          src="assets/icons/garbage-red.svg" alt="Delete Level"></button> </div>
      </div>
      <div *ngIf="!product.groupProfileProductId" class="add-button" (click)="addNewLevel()">Add insurance level for mapping <img src="assets/icons/add-new.svg" alt="+"></div>
      <app-input class="app-input" [(value)]="product.version" name="Version" required="true"
                 [disabled]="!!product.parentId || !!product.groupProfileProductId"></app-input>
      <div class="input-name">
        Terms selected by
        <span class="required">*</span>
      </div>
      <select class="input-component" [(ngModel)]="product.termsSelectedBy" required="true"
              [disabled]="!!product.parentId || !!product.groupProfileProductId || newVersion">
        <option *ngFor="let item of SelectedByEnumKeys" [value]="item">{{SelectedByEnum[item]}}</option>
      </select>
      <app-input class="app-input" [(value)]="product.sosProductId" [disabled]="!!product.groupProfileProductId" required="true" name="SOS ID"></app-input>
      <div class="input">
            <span class="input-name">Percentage of expenses paid with card
              <span *ngIf="product.insuranceType == InsuranceType.card" class="required">*</span>
            </span>
        <input class="input-component" type="text" [pattern]="'(100%)|[0-9]{1,2}%'"
               [(ngModel)]="product.percentagePaidByCard"
               mask="000" suffix="%" [max]="100"
               [disabled]="!!product.groupProfileProductId || product.insuranceType == InsuranceType.regular">
      </div>
    </div>
    <div class="right-column-container">
      <div class="right-column-container-top">
        <span class="input-name">Person group
          <span class="required">*</span>
        </span>
        <select class="input-component" [(ngModel)]="product.personGroup" [disabled]="!!product.groupProfileProductId">
          <option *ngFor="let item of PersonGroupEnumKeys" [value]="item">{{PersonGroupEnum[item]}}</option>
        </select>
        <app-drop-down-multiselect (selectedItemsChange)="setCountryList($event)" [(selectedItems)]="selectedGeographicalCoverageAreas"
                                   [dropdownItemList]="this.geographicalCoverageAreaDictionary"
                                   [name]="'Geographical coverage area'"
                                   [disabled]="!!product.groupProfileProductId"
                                   required="true">
        </app-drop-down-multiselect>
        <div class="coverage-period-type-container">
          <div class="input-name">
            Coverage period type
            <span class="required">*</span>
          </div>
          <div class="coverage-period-value-container">
            <div class="custom-control custom-radio me-3">
              <input type="radio" [disabled]="!!product.parentId || !!product.groupProfileProductId" [(ngModel)]="product.coveragePeriodType"
                     [value]="CoveragePeriodType.annual" name="coveragePeriodType"/>
              <label class="input-name">Annual insurance</label>
            </div>
            <div class="custom-control custom-radio me-3">
              <input type="radio" [disabled]="!!product.parentId || !!product.groupProfileProductId" [(ngModel)]="product.coveragePeriodType"
                     [value]="CoveragePeriodType.single" name="coveragePeriodType"/>
              <label class="input-name">Single trip insurance</label>
            </div>
          </div>
        </div>
        <span class="input-name">Coverage period<span
          *ngIf="product.coveragePeriodType === CoveragePeriodType.annual"
          class="required">*</span></span>
        <div class="coverage-period-value-container">
          <select class="input-component coverage-period-value-container-unit"
                  [(ngModel)]="product.coveragePeriodUnit" [disabled]="!!product.groupProfileProductId">
            <option *ngFor="let item of CoveragePeriodUnits | keyvalue"
                    [value]="CoveragePeriodUnits[item.key]">{{item.key | titlecase}}</option>
          </select>
          <input class="input-component" type="text" [(ngModel)]="product.coveragePeriodValue" [disabled]="!!product.groupProfileProductId" mask="0*">
        </div>
        <app-drop-down-multiselect [name]="'Primary purpose of travel'"
                                   singleValue="true"
                                   required="true"
                                   [dropdownItemList]="primaryPurposeOfTravelDictionary"
                                   [(selectedItems)]="primaryTravelPurpose"
                                   (selectedItemsChange)="validToChanged()"
                                   [disabled]="!!product.groupProfileProductId">
        </app-drop-down-multiselect>
        <app-drop-down-multiselect [name]="'Secondary purpose of travel'"
                                   [disabled]="isPrimaryPurposeOfTravelEqualToAllPurpose() || !!product.groupProfileProductId"
                                   [dropdownItemList]="secondaryPurposeOfTravelDictionary"
                                   [(selectedItems)]="secondaryTravelPurpose"
                                   (selectedItemsChange)="validToChanged()">
        </app-drop-down-multiselect>
      </div>
      <div class="right-column-container-bottom">
        <div class="input-name">
          Valid from
          <span class="required">*</span>
        </div>
        <app-input-calendar [withImage]="true" [selectMode]="'single'" [disabled]="!!product.parentId || !!product.groupProfileProductId"
                            [(dateTimeRange)]="product.validFrom" [maxDate]="product.validTo">
        </app-input-calendar>
        <div class="spacer"></div>
        <span class="input-name">Valid to</span>
        <app-input-calendar [withImage]="true" [selectMode]="'single'"
                            [disabled]="!!product.parentId || !!product.groupProfileProductId || !product.validFrom"
                            [(dateTimeRange)]="product.validTo" [minDate]="product.validFrom"
                            (dateTimeRangeChange)="validToChanged()">
        </app-input-calendar>
        <div class="spacer"></div>
        <span class="input-name">Deactivation date</span>
        <app-input-calendar [withImage]="true" [selectMode]="'single'"
                            [disabled]="!!product.parentId || !!product.groupProfileProductId || !product.validTo"
                            [(dateTimeRange)]="product.deactivationDate">
        </app-input-calendar>
      </div>
    </div>
  </div>
</div>
