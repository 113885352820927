import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Contract} from '@onepoint/shared/models/contract';
import {CustomerSlaAgreement} from "@onepoint/shared/models/customer-sla-agreement";
import {CustomerSlaAgreementService} from "@onepoint/core/services/customer-sla-agreement.service";
import {ViewEditCustomerSlaAgreementComponent} from "./view-edit-customer-sla-agreement/view-edit-customer-sla-agreement.component";
import {PriceAgreement} from "@onepoint/shared/models/price-agreement";

@Component({
  selector: 'app-sla-agreements',
  templateUrl: './sla-agreements.component.html',
  styleUrls: ['./sla-agreements.component.scss']
})
export class SlaAgreementsComponent implements OnInit {
  @ViewChild('modal') modal: ViewEditCustomerSlaAgreementComponent;
  @Input() contract: Contract;
  @Output() customerSlaAgreementsChange = new EventEmitter<CustomerSlaAgreement[]>();
  customerSlaAgreements: CustomerSlaAgreement[] = [];

  constructor(private customerSlaAgreementService: CustomerSlaAgreementService) { }

  ngOnInit() {
    this.loadCustomerSlaAgreements();
  }

  loadCustomerSlaAgreements() {
    this.customerSlaAgreementService.getCustomerSlaAgreements(this.contract.id).subscribe(
      result => {
        this.customerSlaAgreements = result;
        this.customerSlaAgreementsChange.emit(this.customerSlaAgreements);
      },
      error => console.error(error)
    );
  }

  openExisting(customerSlaAgreementId: number){
    this.modal.openExisting(this.customerSlaAgreements.find(agreement => agreement.id === customerSlaAgreementId))
  }
}
