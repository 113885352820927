export enum TemplateFilteringSortingMenuType {
  documentType = 0,
  receiverType = 1,
  serviceType = 2,
  templateType = 3,
  tags = 4,
  displayName = 5,
  fileName = 6,
  language = 7,
  empty =  8,
  subject = 9
}
