import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import * as moment from 'moment';
import { DateSerializer } from './date-serializer';
export class MasterListUploadInfo {
  uploadedBy: string;
  lastUploaded: moment.Moment;

  public constructor(init?: Partial<MasterListUploadInfo>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class MasterListUploadInfoAdapter implements Adapter<MasterListUploadInfo> {
  public adapt(item: any): MasterListUploadInfo {
    return item != null ?
      new MasterListUploadInfo({
        uploadedBy: item.uploadedBy,
          lastUploaded: DateSerializer.deserialize(item.lastUploaded)
        }) : null;
    }
}
