<div class="reason-for-cancellation-modal-container">
  <div class="closing-x" (click)="closeModal()">
    <img src="/assets/icons/Close.svg">
  </div>
  <div class="header">
    <span *ngIf="isNew">Create new Reason for Cancellation</span>
    <span *ngIf="!isNew">Edit Reason for Cancellation</span>
  </div>
  <div class="row body-container" *ngIf="masterListItem">
    <div class="col-3">
      <div class="input-name">
        Activate on
        <span *ngIf="!isReadonly" class="required">*</span>
      </div>
      <app-input-calendar [withImage]="true" [selectMode]="'single'" [disabled]="isReadonly"
        [(dateTimeRange)]="masterListItem.activateOn" [maxDate]="masterListItem.deactivateOn"
        (saveModel)="activateOnChanged(masterListItem)">
      </app-input-calendar>
      <div class="input-name">
        Deactivate after
      </div>
      <app-input-calendar [withImage]="true" [selectMode]="'single'" [disabled]="isReadonly"
        [(dateTimeRange)]="masterListItem.deactivateOn" [minDate]="masterListItem.activateOn"
        (saveModel)="deactivateOnChanged(masterListItem)">
      </app-input-calendar>
      <div class="label" style="font-size: 14px;
      color: #787878;" [ngClass]="'input-name'">Based on case creation time</div>

    </div>
    <div class="col-9">
      <div class="row">
        <div class="col-6">
          <div>
            <app-input [name]="'Business key'" [(value)]="masterListItem.reasonForCancellationCode" (valueChange)="modelChanged()"
              [disabled]="isReadonly || masterListItem.id" [required]="!isReadonly"></app-input>
          </div>
          <div class="padding-top">
            <app-input [name]="'Name'" [(value)]="masterListItem.reasonForCancellationName" (valueChange)="modelChanged()"
              [disabled]="isReadonly" [required]="!isReadonly"></app-input>
          </div>
          <div class="padding-top">
            <div class="field-title">Description</div>
            <textarea [(ngModel)]="masterListItem.description" (ngModelChange)="modelChanged()"
              [disabled]="isReadonly"></textarea>
          </div>
          <div class="padding-top">
            <app-input [name]="'Sort in UI'" [(value)]="masterListItem.uiSortIndex" (valueChange)="modelChanged()"
              [disabled]="isReadonly"></app-input>
          </div>
        </div>
        <div class="col-6">
          <div class="padding-top">
            <div>
              <app-drop-down [name]="'Modal Type'" [(selectedItemId)]="masterListItem.modalType"
                [items]="modalTypes" [required]="!isReadonly" [disabled]="isReadonly"
                (selectedItemIdChange)="selectedModalTypeValueChanged($event)">
              </app-drop-down>
            </div>
          </div>
          <div class="padding-top" *ngIf='masterListItem.translationsAvailable'>
            <div *ngFor="let lang of languages | keyvalue: keepOrder; let i = index" [class.padding-top]="i >= 1">
              <div class='input'>
                <span class='input-name'>Translation - {{lang.key}}</span>
                <input class='input-component text-truncate' disabled [value]='masterListItem.translationsAvailable[lang.value] || ""' [title]='masterListItem.translationsAvailable[lang.value] || ""'>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  <div class="row body-container" *ngIf="error">
    <div class="col-12 error">
      {{error}}
    </div>
  </div>
</div>
<div class="masterlist-modal-footer d-flex justify-content-between">
  <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE" class="">
    <button class="primary-button save-button" [class.button-disabled]="!isSaveButtonEnabled" (click)="save()">Save</button>
  </div>
  <app-last-changed [masterListItem]="masterListItem" class="float-right"> </app-last-changed>
</div>
</div>
