<div class="row terms-container">
  <div class="col-12">
    <div class="version-box">
      <div class="input-name version-box-label">Version</div>
      <div class="d-flex">
        <select class="input-component version-box-dropdown clearfix" [disabled]="isChildProduct(product)" [(ngModel)]="selectedVersionProductId"
                (ngModelChange)="onChange($event)">
          <option *ngFor="let otherVersion of otherVersions" [value]="otherVersion.id">{{otherVersion.version}}</option>
        </select>
        <span *permission='PermissionEnum.CUSTOMER_COVERAGES_LIMITS_RELATION_CREATE'>
          <button *ngIf="!product.groupProfileProductId" class="primary-button" [class.button-disabled]="isChildProduct(product)" (click)="addNewVersion()">Add new version</button>
        </span>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="terms-box">
      <app-product-documents [productId]="product.id"></app-product-documents>
    </div>
  </div>
  <div class="col-8">
    <app-coverages-limits-list [product]="product"></app-coverages-limits-list>
  </div>
</div>
<app-profile-products-add #profileProductsAddComponent [profileId]="profileId" [newVersion]="true"
                          (refreshProduct)="onNewVersionSaved($event)">
</app-profile-products-add>
