import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasterListService } from '@onepoint/core/services/masterlist.service';
import { OnepointModalService } from '@onepoint/core/services/onepoint.modal.service';
import { SosServicesService } from '@onepoint/core/services/sos-services.service';
import { PermissionService } from '@onepoint/core/services/permission.service';
import { PermissionEnum } from '@onepoint/shared/models/permission.enum';
import { MasterlistContactRoleModel } from '@onepoint/shared/models/masterlist-contact-role-model';

@Component({
  selector: 'app-constact-role-modal',
  templateUrl: './constact-role-modal.component.html',
  styleUrls: ['./constact-role-modal.component.scss']
})
export class ConstactRoleModalComponent implements OnInit {
  constructor(
    private masterListService: MasterListService,
    private onepointModalService: OnepointModalService,
    private sosServicesService: SosServicesService,
    private permissionService: PermissionService
  ) {}

  @Input()
  masterListType: MasterlistContactRoleModel;

  @Input()
  newContactRole: boolean;

  @Output()
  refreshTable = new EventEmitter();

  error: string;
  canUpdate: boolean;
  isModelChanged: boolean;
  ngOnInit(): void {
    this.permissionService.permissionsReadyNotification.subscribe(
      ignore => (this.canUpdate = this.permissionService.checkUserPermission(PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE))
    );
  }

  update() {
    if (this.newContactRole) {
      this.create();
    } else {
      this.save();
    }
  }

  private save() {
    this.masterListService.updateContactRole(this.masterListType)
      .subscribe({
        next: (response: any) => {
          this.masterListType = response;
          this.refreshTable.emit();
          this.closeModal();
        },
        error: (err: any) => {
          this.error = err.error ? err.error.message : err.message;
        }
      });
  }

  private create() {
    this.masterListService.createContactRole(this.masterListType)
      .subscribe({
        next: (response: any) => {
          this.masterListType = response;
          this.refreshTable.emit();
          this.closeModal();
        },
        error: (err: any) => {
          this.error = err.error ? err.error.message : err.message;
        }
      });
  }

  get isSaveButtonEnabled(): boolean {
    return (
      this.isModelChanged &&
      this.masterListType &&
      !!this.masterListType.activateOn &&
      !!this.masterListType.businessKey &&
      !!this.masterListType.contactRoleName
    );
  }


  closeModal() {
    this.onepointModalService.dismissAll();
  }

  activateOnChanged(item: MasterlistContactRoleModel) {
    this.modelChanged();
    item.activateOn.setHours(12, 0);
  }

  deactivateOnChanged(item: MasterlistContactRoleModel) {
    this.modelChanged();
    item.deactivateOn.setHours(12, 0);
  }

  modelChanged(): void {
    this.isModelChanged = true;
  }

  get isReadonly(): boolean {
    return !this.canUpdate;
  }

  get isNew(): boolean {
    return this.masterListType?.id == null;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

}
