<ng-template #content let-modal>
  <div class="onepoint-modal-container">
    <div class="onepoint-modal-header">
      <div class="onepoint-modal-header-title">
        <div *ngIf="sla.id == null">
        Add new SLA<br/>
        </div>
        <div *ngIf="sla.id != null">
        Update SLA<br/>
        </div>
        <div class="subTitle">Default SLA settings</div>
      </div>
      <div class="onepoint-modal-header-close" (click)="close();"><img src="assets/icons/Close.svg"></div>
    </div>
    <div class="onepoint-modal-body p-3">
      <div class="top-space">
        <div class="input-title-name">
          Standard SLA
        </div>
        <app-input-switch [id]="'sla-enabled'"
                          [value]="sla.standard"
                          (valueChange)="standardChanged($event)">
        </app-input-switch>
      </div>
      <div class="top-space">
        <div class="input-title-name">
          Category
        </div>
        <app-drop-down class="dropdown" [items]="slaCategoryDictionary" [(selectedItemId)]="sla.category.id"
                       (selectedItemIdChange)="categoryChanged($event)"></app-drop-down>
      </div>
      <div class="top-space">
        <app-input [name]="'SLA name'" [(value)]="sla.name" (valueChange)="validate()"></app-input>
      </div>
      <div class="top-space">
        <app-drop-down-multiselect
          class="multi-select"
          [dropdownItemList]="slaEvents"
          [name]="'Start events'"
          [(selectedItems)]="sla.startEvents"
          (selectedItemsChange)="validate()"
        ></app-drop-down-multiselect>
      </div>
      <div class="top-space">
        <app-drop-down-multiselect
          class="top-space"
          [dropdownItemList]="slaEvents"
          [name]="'End events'"
          [(selectedItems)]="sla.endEvents"
          (selectedItemsChange)="validate()"
        ></app-drop-down-multiselect>
      </div>
      <div class="top-space">
        <app-input [name]="'Deadline time'" [type]="'number'" [(value)]="sla.deadlineTime"
                   (valueChange)="validate()"></app-input>
      </div>
      <div class="top-space">
        <app-input [name]="'Requirement'" [type]="'number'" [(value)]="sla.requirement"
                   (valueChange)="validate()"></app-input>
      </div>
      <div class="top-space">
        <app-drop-down-multiselect
          [name]="'Exceptions'"
          [dropdownItemList]="slaRules"
          [(selectedItems)]="sla.rules"
          (selectedItemsChange)="exceptionSelected()"
        ></app-drop-down-multiselect>
      </div>
      <div class="onepoint-modal-footer">
        <button
          class="primary-button save-btn"
          (click)="save()"
          [ngClass]="!valid && 'add-button-disabled'"
          [disabled]="!valid"
        >
          <div *ngIf="sla.id == null" [ngbPopover]="hover" [disablePopover]="valid" triggers="hover" placement="top">
            Add<br/>
          </div>
          <div *ngIf="sla.id != null" [ngbPopover]="hover" [disablePopover]="valid" triggers="hover" placement="top">
            Save<br/>
          </div>
        </button>
      </div>
      <ng-template #hover>
        <span>{{validationMessage}}</span>
      </ng-template>
    </div>
  </div>
</ng-template>
