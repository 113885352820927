import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { CommonCustomer } from '../../shared/models/common-customer';
import { Observable } from 'rxjs';
import { PriceAgreement, PriceAgreementAdapter } from '../../shared/models/price-agreement';
import { map } from 'rxjs/operators';
import { Dictionary, DropdownAdapter } from 'app/shared/models/dictionary';

@Injectable({
  providedIn: 'root',
})
export class PriceAgreementService extends BaseService {
  constructor(
    private settingsService: SettingsService,
    private http: HttpClient,
    private priceAgreementAdapter: PriceAgreementAdapter,
    private dropdownAdapter: DropdownAdapter
  ) {
    super(settingsService);
  }

  public getPriceAgreements(contractId: string): Observable<PriceAgreement[]> {
    return this.http
      .get(this.baseURL + 'contracts/' + contractId + '/price-agreements', {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.priceAgreementAdapter.adapt(item))));
  }

  public getPriceAgreement(contractId: string, id: string): Observable<PriceAgreement> {
    return this.http
      .get(this.baseURL + 'contracts/' + contractId + '/price-agreements/' + id, {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.priceAgreementAdapter.adapt(item)));
  }
  public getPriceAgreementWithNewServices(contractId: string, id: string): Observable<PriceAgreement> {
    return this.http
      .get(this.baseURL + 'contracts/' + contractId + '/price-agreements/' + id, {
        headers: this.jsonHeaders, params: { includeNewServices: 'true' }
      })
      .pipe(map(item => this.priceAgreementAdapter.adapt(item)));
  }

  public savePriceAgreement(contractId: string, priceAgreement: PriceAgreement): Observable<PriceAgreement> {
    priceAgreement.trim();
    return this.http
      .post<CommonCustomer>(this.baseURL + 'contracts/' + contractId + '/price-agreements', priceAgreement, {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.priceAgreementAdapter.adapt(item)));
  }

  public calculatePriceAgreement(contractId: string, priceAgreement: PriceAgreement): Observable<any> {
    return this.http.post<any>(this.baseURL + 'contracts/' + contractId + '/price-agreements/calculation', priceAgreement, {
      headers: this.jsonHeaders,
    });
  }

  public suspendPriceAgreement(contractId: string, id: string): Observable<PriceAgreement> {
    return this.http
      .post<any>(this.baseURL + 'contracts/' + contractId + '/price-agreements/suspend/' + id, {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.priceAgreementAdapter.adapt(item)));
  }

  public getIndexes(): Observable<Dictionary[]> {
    return this.http
      .get(this.baseURL + 'index', {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => new Dictionary(item.id, item.name))));
  }
}
