import { BrandService } from './../../../../core/services/brand.service';
import { Brand, BrandAdapter } from './../../../../shared/models/brand';
import { Component, Input, OnInit } from '@angular/core';
import { CustomerListView } from 'app/shared/models/customer-list-view';
import { Dictionary } from 'app/shared/models/dictionary';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { RoutesName } from 'app/app-routes-name';
import { UpdateService } from 'app/core/services/update.service';
import { BreadcrumbsMenuUpdateService } from 'app/shared/components/breadcrumbs-menu/breadcrumbs-menu-update-service';

@Component({
  selector: 'app-brand-settings',
  templateUrl: './brand-settings.component.html',
  styleUrls: ['./brand-settings.component.scss'],
})
export class BrandSettingsComponent implements OnInit {
  @Input() customerListViewOriginal: CustomerListView;
  brand: Brand = new Brand();
  oldBrand: Brand = new Brand();
  changesMade = false;
  isBrandValid: boolean;
  brandKAM: Dictionary[];
  public controls: UntypedFormControl;
  isDeletabel: boolean;
  showInvalidIconMsg = false;
  constructor(
    private brandService: BrandService,
    private updateService: UpdateService,
    private breadcrumbsMenuUpdateService: BreadcrumbsMenuUpdateService,
    private brandAdapter: BrandAdapter,
    private router: Router
  ) {}

  ngOnInit() {
    this.brand.id = this.customerListViewOriginal.id;
    this.brand.commonCustomerId = this.customerListViewOriginal.parentId;
    this.brand.keyAccountManagerUserId = this.customerListViewOriginal.keyAccountManagerUserId;
    this.brand.iconBlob = this.customerListViewOriginal.iconBlob;
    this.brand.name = this.customerListViewOriginal.name;
    this.brand.remarks = this.customerListViewOriginal.remarks;
    this.brand.deletable = this.customerListViewOriginal.deletable;

    this.oldBrand = this.brandAdapter.adapt(this.brand);

    if (this.customerListViewOriginal.children.length === 0) {
      this.isDeletabel = false;
    } else {
      this.isDeletabel = true;
    }
    this.formChanged();
  }

  get iconBlob() {
    return this.brand.iconBlob;
  }

  set iconBlob(iconBlob) {
    this.brand.iconBlob = iconBlob;
    this.showInvalidIconMsg = false;
    this.formChanged();
  }

  brandNameChanges(name: string) {
    this.brand.name = name;
    this.formChanged();
  }

  formChanged() {
    if (
      this.brand.name &&
      this.brand.keyAccountManagerUserId &&
      this.brand.iconBlob &&
      this.brand.name !== '' &&
      (this.brand.name !== this.oldBrand.name ||
        this.brand.iconBlob !== this.oldBrand.iconBlob ||
        this.brand.keyAccountManagerUserId !== this.oldBrand.keyAccountManagerUserId ||
        this.brand.remarks !== this.oldBrand.remarks)
    ) {
      this.isBrandValid = true;
    } else {
      this.isBrandValid = false;
    }
  }
  updateBrand() {
    this.brandService.updateBrand(this.brand).subscribe(result => {
      if ((result as any).errorCode === 'INVALID_ICON') {
        this.showInvalidIconMsg = true;
        return;
      }
      this.brand = result;
      this.oldBrand = this.brandAdapter.adapt(this.brand);
      this.updateService.changedBrand();
      this.breadcrumbsMenuUpdateService.updatedBrand(result);
      this.formChanged();
    });
  }
  deleteBrand() {
    const navId = this.customerListViewOriginal.parentId;
    this.brandService.deleteBrand(this.brand.id).subscribe(result => {
      this.updateService.deletedBrand();
      this.router.navigate([
        RoutesName.customers + '/' + RoutesName.commonCustomers,
        navId,
      ]);
    });
  }
}
