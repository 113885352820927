<ng-template #content let-modal>
  <div class="modal-container">
    <div class="price-agreement-modal-header">
      <div class="price-agreement-title" *ngIf="priceAgreement.id == null">Add New Price Agreement</div>
      <div class="price-agreement-title" *ngIf="priceAgreement.id != null">Price Agreement</div>
      <div class="price-agreement-close"><img (click)="close()" src="assets/icons/Close.svg" alt="Close" /></div>
    </div>

    <div class="price-agreement-modal-body">
      <div class="price-agreement-properties">
        <div class="row">
          <div class="input-property col-4">
            <div class="title">Title</div>
            <app-input
              [disabled]="priceAgreement.suspended"
              [(value)]="priceAgreement.name"
              (valueChange)="calculateAllowSubmit()"
            ></app-input>
          </div>
          <div class="input-property col-2">
            <div class="title">Currency</div>
            <app-drop-down
              [disabled]="priceAgreement.suspended"
              [items]="currencies"
              [(selectedItemId)]="priceAgreement.currency.id"
              (selectedItemIdChange)="calculateAllowSubmit()"
            ></app-drop-down>
          </div>
          <div class="input-property col-4">
            <div class="title">Index</div>
            <app-drop-down
              [disabled]="priceAgreement.suspended"
              [items]="indexes"
              [(selectedItemId)]="priceAgreement.indexId"
              (selectedItemIdChange)="calculateAllowSubmit()"
            ></app-drop-down>
          </div>
          <div *ngIf="isManualIndex()" class="input-property col-1">
            <button class="primary-button notitle" (click)="addNewYear()" [ngClass]="!addNewYearAllowed() && 'button-disabled'" [disabled]="!addNewYearAllowed()">Add new year</button>
          </div>
          <div *ngIf="!isManualIndex()" class="input-property col-2">
            <div class="title">First yearly regulation</div>
            <app-drop-down
              [disabled]="priceAgreement.suspended"
              [items]="firstRegulation"
              [(selectedItemId)]="priceAgreement.firstRegulation"
              (selectedItemIdChange)="calculateAllowSubmit()"
            ></app-drop-down>
          </div>
        </div>
      </div>
      <div class="services" *ngFor="let category of sosServicesCategories">
        <app-price-agreement-service-category
          [priceAgreementCategory]="priceAgreement.getCategory(category)"
          [categoryId]="category"
          [categoryName]="categories.get(category).name"
          [services]="categories.get(category).services"
          [editable]="priceAgreement.id == null"
          [firstPriceYear]="firstPriceYear"
          [manualIndex]="isManualIndex()"
          [currency]="
            !!priceAgreement.getCategory(category).currency ? priceAgreement.getCategory(category).currency : priceAgreement.currency.id
          "
          [showType]="showType"
          (change)="calculateAllowSubmit()"
          (dataChange)="calculateAllowSubmit()"
          [suspended]="priceAgreement.suspended"
          [numberOfYearsInService]="numberOfYearsInService"
          *ngIf="!!priceAgreement.getCategory(category)"
        >
        </app-price-agreement-service-category>
      </div>
    </div>

    <div class="price-agreement-modal-footer" *ngIf="!priceAgreement.suspended">
      <div class="price-agreement-title">
        <button
          class="primary-button"
          *ngIf="priceAgreement.id == null"
          (click)="savePriceAgreement()"
          [ngClass]="!allowSubmit && 'button-disabled'"
          [disabled]="!allowSubmit"
        >
          Add
        </button>
        <span *permission="PermissionEnum.CUSTOMER_PRICE_AGREEMENT_UPDATE">
          <button class="primary-button suspend" *ngIf="priceAgreement.id != null" (click)="openSuspendBox()">Suspend</button>
          <button
            class="reload-button primary-button"
            *ngIf="priceAgreement.id != null"
            [disabled]="!priceAgreement.servicesNotOnPriceAgreementExist"
            [ngClass]="!priceAgreement.servicesNotOnPriceAgreementExist && 'button-disabled'"
            (click)="reloadWithNewServices()"
          >Reload with new services</button>
          <button
            class="save-button primary-button"
            *ngIf="priceAgreement.id != null"
            (click)="savePriceAgreement()"
            [ngClass]="!allowSubmit && 'button-disabled'"
            [disabled]="!allowSubmit"
          >
            Save changes
          </button>
          </span>
        <div class="legend float-end"><span class="not-standard">*</span> Not standard SOS Service</div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #areYouSure let-modal data-backdrop="static">
  <div class="modal-container2">
    <div class="suspend-content">
      <div class="warning">
        Are you sure you want to suspend this price agreement? You cannot re-activate the price agreement if you suspend it.
      </div>
      <button (click)="closeSuspendBox()" class="primary-button float-end">No</button>
      <button (click)="suspendPriceAgreement()" class="primary-button suspend">Yes</button>
    </div>
  </div>
</ng-template>
