<ng-template #content let-modal>
  <div class="modal-container">
    <div class="new-modal-header">
      <div class="new-title">Add SOS services</div>
      <div class="new-close"><img (click)="close()" src="assets/icons/Close.svg" alt="Close"></div>
    </div>

    <div class="new-modal-body">
      <app-edit-sos-services [sosServices]="sosServices" (changes)="handleChange()"></app-edit-sos-services>
      <div class="container"> 
        <div class="row">
          <div class="col-sm">
            <div class="new-modal-footer">
              <button class="primary-button" (click)="addNewSosServices()"
                      [ngClass]="!isValid && 'button-disabled'" [disabled]="!isValid">Add
              </button>
            </div>
          </div>
          <div class="col-sm">
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
