import { BusinessAreaEnum } from 'app/shared/models/enums';
import { Injectable } from '@angular/core';
import { DocumentTemplate } from 'app/shared/models/templates/document.template';
import { TemplateFilteringSortingMenuType } from './template-filtering-sorting-menu-type';

@Injectable({
  providedIn: 'root',
})
export class TemplateFilteringService {
  filteringStep: TemplateFilteringStep[] = [];
  constructor() { }

  public addStep(filteringSortingMenuType: TemplateFilteringSortingMenuType, selectedIds: string[]) {
    this.filteringStep.push(new TemplateFilteringStep(filteringSortingMenuType, selectedIds));
  }

  public removeStep(filteringSortingMenuType: TemplateFilteringSortingMenuType) {
    let itemToDelete = this.filteringStep.find(a => a.filteringSortingMenuType === filteringSortingMenuType);
    if (itemToDelete) {
      this.filteringStep.splice(this.filteringStep.indexOf(itemToDelete));
    }
  }

  public filter(rows: DocumentTemplate[]): DocumentTemplate[] {
    let tempRows: Set<DocumentTemplate>;

    this.filteringStep.forEach(step => {
      tempRows = new Set();
      switch (step.filteringSortingMenuType) {
        case TemplateFilteringSortingMenuType.templateType:
          step.selectedIds.forEach(element => {
            rows.filter(a => a.templateType === element).forEach(a => tempRows.add(a));
          });
          break;
        case TemplateFilteringSortingMenuType.receiverType:
          step.selectedIds.forEach(element => {
            rows.filter(a => a.receiverType === element).forEach(a => tempRows.add(a));
          });
          break;
        case TemplateFilteringSortingMenuType.serviceType:
          step.selectedIds.forEach(element => {
            rows.filter(a => a.serviceType === element).forEach(a => tempRows.add(a));
          });
          break;
        case TemplateFilteringSortingMenuType.documentType:
          step.selectedIds.forEach(element => {
            rows.filter(a => a.documentType === element).forEach(a => tempRows.add(a));
          });
          break;
        case TemplateFilteringSortingMenuType.tags:
          step.selectedIds.forEach(element => {
            rows
              .filter(a => {
                if (element) {
                  return a.tags ? a.tags.includes(element) : false;
                } else {
                  return a.tags === null || a.tags.length === 0;
                }
              }
              )
              .forEach(a => tempRows.add(a));
          });
          break;
        case TemplateFilteringSortingMenuType.displayName:
          step.selectedIds.forEach(element => {
            rows.filter(a => a.name === element).forEach(a => tempRows.add(a));
          });
          break;
        case TemplateFilteringSortingMenuType.fileName:
          step.selectedIds.forEach(element => {
            rows.filter(a => a.fileName === element).forEach(a => tempRows.add(a));
          });
          break;
        case TemplateFilteringSortingMenuType.language:
          step.selectedIds.forEach(element => {
            rows.filter(a => a.languageCode === element).forEach(a => tempRows.add(a));
          });
          break;
        case TemplateFilteringSortingMenuType.subject:
          step.selectedIds.forEach(element => {
            rows.filter(a => a.subject === element).forEach(a => tempRows.add(a));
          });
          break;
      }

      rows = Array.from(tempRows);
    });
    return rows;
  }
}

export class TemplateFilteringStep {
  filteringSortingMenuType: TemplateFilteringSortingMenuType;
  selectedIds: string[];
  constructor(filteringSortingMenuType: TemplateFilteringSortingMenuType, selectedIds: string[]) {
    this.filteringSortingMenuType = filteringSortingMenuType;
    this.selectedIds = selectedIds;
  }
}
