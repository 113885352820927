import { ProfileAdapter } from './../../shared/models/profile';
import { BrandAdapter } from './../../shared/models/brand';
import { CustomerListView } from './../../shared/models/customer-list-view';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  CommonCustomerAdapter,
  CommonCustomer
} from './../../shared/models/common-customer';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class CommonCustomerService extends BaseService {
  constructor(
    private commonCustomerAdapter: CommonCustomerAdapter,
    private http: HttpClient,
    private brandAdapter: BrandAdapter,
    private profileAdapter: ProfileAdapter,
    private settingsService: SettingsService
  ) {
    super(settingsService);
  }

  public getCommonCustomerListView(id: string): Observable<CustomerListView> {
    return this.http
      .get(this.baseURL + 'common-customers/' + id)
      .pipe(
        map(item =>
          this.commonCustomerAdapter.adaptToCommonCustomerListView(item)
        )
      );
  }

  public updateCommonCustomer(commonCustomer: CommonCustomer): Observable<CommonCustomer> {
    return this.http.put<CommonCustomer>(
      this.baseURL + 'common-customers/' + commonCustomer.id,
      JSON.stringify(commonCustomer),
      { headers: this.jsonHeaders }
    );
  }

  public getCommonCustomer(id: string): Observable<CommonCustomer> {
    return this.http
      .get(this.baseURL + 'common-customers/' + id)
      .pipe(map(item => this.commonCustomerAdapter.adapt(item)));
  }

  public getProfiles(commonCustomerId: string): Observable<CustomerListView[]> {
    return this.http
      .get(this.baseURL + 'profile?commonCustomerId = ' + commonCustomerId)
      .pipe(
        map((data: any[]) =>
          data.map(item =>
            this.profileAdapter.adaptToCommonCustomerListView(item)
          )
        )
      );
  }

  public saveCommonCustomer(commonCustomer: CommonCustomer): Observable<any> {
    return this.http.post<CommonCustomer>(this.baseURL + 'common-customers', commonCustomer, {
      headers: this.jsonHeaders
    });
  }
  public deleteCommonCustomer(id: string): Observable<any> {
    return this.http.delete<any>(this.baseURL + 'common-customers/delete/' + id, {
      headers: this.jsonHeaders
    });
  }
}
