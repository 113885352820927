import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {
  PriceAgreement,
  PriceAgreementCategory,
  PriceAgreementCategoryService
} from '../../../../../../shared/models/price-agreement';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Dictionary} from '../../../../../../shared/models/dictionary';
import {DictionaryService} from '../../../../../../core/services/dictionary.service';
import {PriceAgreementService} from '../../../../../../core/services/price-agreement.service';
import {SosServicesService} from '../../../../../../core/services/sos-services.service';
import {SosServices} from '../../../../../../shared/models/sos-services';
import {Contract} from '../../../../../../shared/models/contract';
import {SosServicesCategoryEnum} from 'app/shared/models/enums';
import {OnepointModalService, Size} from '../../../../../../core/services/onepoint.modal.service';
import { PermissionEnum } from '@onepoint/shared/models/permission.enum';

@Component({
  selector: 'app-view-edit-price-agreement',
  templateUrl: './view-edit-agreement.component.html',
  styleUrls: ['./view-edit-agreement.component.scss'],
})
export class ViewEditAgreementComponent implements OnInit {
  @ViewChild('content', { static: false }) private template: TemplateRef<any>;
  @ViewChild('areYouSure', { static: false })
  private suspendTemplate: TemplateRef<any>;
  @Output() reloadPriceAgreements = new EventEmitter();
  @Input() firstPriceYear: number;
  @Input() contract: Contract;
  @Input() showType: string;
  priceAgreement: PriceAgreement;
  oldPriceAgreement: PriceAgreement;
  allowSubmit: boolean;
  allowSave: boolean;
  firstRegulation: Dictionary[];
  currencies: Dictionary[];
  indexes: Dictionary[];
  categories: Map<string, { name: string; services: SosServices[] }> = new Map();
  suspendBoxModalRef: NgbModalRef;

  sosServicesCategories: string[];
  numberOfYearsInService: number;

  constructor(
    private modalService: OnepointModalService,
    private dictionaryService: DictionaryService,
    private priceAgreementService: PriceAgreementService,
    private sosServiceService: SosServicesService
  ) {}

  ngOnInit() {
    const firstYear = 2014;
    const lastYear = new Date().getFullYear() + 6;
    const diffYear = lastYear - firstYear;
    this.firstRegulation = [];
    for (let i = 1; i < diffYear; i++) {
      this.firstRegulation.push({
        id: String(firstYear + i),
        name: String(firstYear + i),
      });
    }
    this.priceAgreementService.getIndexes().subscribe(
      result => (this.indexes = result),
      error => console.log(error)
    );

    this.dictionaryService.getBillingCurrencies().subscribe(
      result => (this.currencies = result),
      error => console.log(error)
    );

    this.initCategories();
  }

  openCopy(priceAgreementId: string) {
    this.priceAgreementService.getPriceAgreement(this.contract.id, priceAgreementId).subscribe(
      result => {
        this.priceAgreement = result;
        this.oldPriceAgreement = JSON.parse(JSON.stringify(this.priceAgreement));
        this.priceAgreement.id = null;
        this.priceAgreement.name = '';
        this.priceAgreement.suspended = false;
        this.showType = 'copy';
        this.priceAgreement.categories.forEach(c => {
          c.id = null;
          c.services.forEach(s => {
            s.id = null;
            if (this.priceAgreement.indexId !== 0) {
              s.prices.forEach(p => p = null);
            }
          });
        });
        this.open();
      },
      error => console.log(error)
    );
  }

  openNew() {
    this.prepareNewPriceAgreement();
    this.showType = 'new';
    this.open();
  }

  openExisting(priceAgreementId: string) {
    this.priceAgreementService.getPriceAgreement(this.contract.id, priceAgreementId).subscribe(
      result => {
        this.priceAgreement = result;
        this.oldPriceAgreement = JSON.parse(JSON.stringify(this.priceAgreement));
        this.showType = 'existing';
        this.priceAgreement.categories.forEach(c => {
          c.services.forEach(s => {});
        });
        this.open();
      },
      error => console.log(error)
    );
  }

  open() {
    this.modalService.open(this.template, Size.LARGE);
    this.calculateAllowSubmit();
  }

  close() {
    this.modalService.dismissAll();
  }

  openSuspendBox() {
    this.suspendBoxModalRef = this.modalService.open(this.suspendTemplate, Size.SMALL);
  }

  closeSuspendBox() {
    if (this.suspendBoxModalRef != null) {
      this.suspendBoxModalRef.close();
    }
  }

  savePriceAgreement() {
    this.priceAgreementService.savePriceAgreement(this.contract.id, this.priceAgreement).subscribe(
      result => {
        this.priceAgreement = result;
        this.reloadPriceAgreements.emit();
        this.close();
        if (result.indexId !== 0) {
          this.priceAgreementService.calculatePriceAgreement(this.contract.id, result).subscribe(() => {});
        }
      },
      error => console.log(error)
    );
  }

  suspendPriceAgreement() {
    this.priceAgreementService.suspendPriceAgreement(this.contract.id, this.priceAgreement.id).subscribe(
      result => {
        this.priceAgreement = result;
        this.reloadPriceAgreements.emit();
      },
      error => console.log(error)
    );
    this.close();
  }

  calculateAllowSubmit() {
    this.allowSubmit = Boolean(
      this.priceAgreement.currency.id != null &&
      this.priceAgreement.name &&
      this.priceAgreement.indexId != null &&
      this.priceAgreement.firstRegulation
    );

    if (this.showType === 'existing') {
      if (this.allowSubmit) {
        this.allowSubmit = Boolean(
          this.priceAgreement.currency.id !== this.oldPriceAgreement.currency.id ||
          this.priceAgreement.name !== this.oldPriceAgreement.name ||
          this.priceAgreement.indexId !== this.oldPriceAgreement.indexId ||
          this.priceAgreement.firstRegulation !== this.oldPriceAgreement.firstRegulation
        );
        this.priceAgreement.categories.forEach(category => {
          const oldcategory = this.oldPriceAgreement.categories.find(c => c.id === category.id);
          this.allowSubmit = this.allowSubmit || oldcategory.included !== category.included;
          if (category.included) {
            category.services.forEach(service => {
              const oldService = oldcategory.services.find(s => s.id === service.id);
              if (oldService.included !== service.included) {
                if (this.isManualIndex()) {
                  const maxYear = this.findMaxYear(category);
                  for (let year = this.contract.commencementDate.getFullYear(); year <= maxYear; year++) {
                    if (!service.years.includes(year)) {
                      service.years.push(year);
                      service.prices.push(null);
                      service.shares.push(null);
                    }
                  }
                } else {
                  if (service.years.length === 0) {
                    service.years.push(this.contract.commencementDate.getFullYear());
                    service.prices.push(null);
                    service.shares.push(null);
                  }
                }
              }
              this.allowSubmit = this.allowSubmit ||
                this.isServiceValidSave(service, oldService) ||
                oldService.included !== service.included;
              if (this.priceAgreement.indexId !== this.oldPriceAgreement.indexId) {
                this.resetCalculatedPrices(service);
              }
            });
          }
        });
      }
    }
  }

  resetCalculatedPrices(service: PriceAgreementCategoryService) {
    if (this.priceAgreement.indexId !== null && this.priceAgreement.indexId !== 0) {
      service.prices.forEach(price => price = null);
    }
  }

  isServiceValidSave(service: PriceAgreementCategoryService, oldService: PriceAgreementCategoryService): boolean {
    let valid = true;
    let identical = true;
    if (service.included) {
      identical = service.prices.every((value, index) => value === oldService.prices[index]) &&
                  service.shares.every((value, index) => value === oldService.shares[index]);
      valid = valid && (Boolean(service.prices[0]) || Boolean(service.shares[0]));
    }
    return valid && !identical;
  }

  addNewYearAllowed(): boolean {
    if (this.contract.terminationDate) {
      let maxYear = 0;
      this.priceAgreement.categories.forEach(category => {
        const categoryEnum = SosServicesCategoryEnum[category.categoryId];
        if (category.included && categoryEnum !== SosServicesCategoryEnum.ADDITIONAL_MEDICAL_SERVICES &&
          categoryEnum !== SosServicesCategoryEnum.ADDITIONAL_MEDICAL_SERVICES_ESCORT
        ) {
          maxYear = Math.max(maxYear, this.findMaxYear(category));
        }
      });
      return maxYear < this.contract.terminationDate.getFullYear();
    } else {
      return true;
    }
  }

  isManualIndex() {
    if (this.priceAgreement.indexId !== null) {
      return this.priceAgreement.indexId === 0;
    }
    return false;
  }

  initCategories() {
    const categories = new Map();
    const sosServicesCategories: string[] = [];
    this.sosServiceService.getAll().subscribe(
      result => {
        result.forEach(service => {
          if (this.servicePeriodRelevant(service)) {
            const categoryId = service.sosServicesCategory;
            if (categories.has(categoryId)) {
              const value = categories.get(categoryId);
              value.services.push(service);
              categories.set(categoryId, value);
            } else {
              categories.set(categoryId, {
                name: SosServicesCategoryEnum[service.sosServicesCategory],
                services: [service],
              });
              sosServicesCategories.push(categoryId);
            }
          }
        });
        this.categories = categories;

        const categoryOrder = Object.keys(SosServicesCategoryEnum);
        sosServicesCategories.sort((a, b) => categoryOrder.indexOf(a) - categoryOrder.indexOf(b));
        this.sosServicesCategories = sosServicesCategories;
      },
      error => {
        console.error(error);
      }
    );
  }

  servicePeriodRelevant(service: SosServices): boolean {
    return (
      new Date(service.validStart) <= this.contract.commencementDate &&
      (service.validEnd == null || new Date(service.validEnd) > this.contract.commencementDate)
    );
  }

  prepareNewPriceAgreement() {
    this.priceAgreement = new PriceAgreement();
    this.priceAgreement.contractId = this.contract.id;
    this.priceAgreement.categories = [];
    this.categories.forEach((category, categoryId) => {
      const services: PriceAgreementCategoryService[] = [];
      category.services.forEach(service => {
        services.push(
          new PriceAgreementCategoryService({
            serviceId: Number(service.id),
            included: service.includedInStandard,
            prices: [null],
            shares: [null],
            years: [this.contract.commencementDate.getFullYear()]
          })
        );
      });

      this.priceAgreement.categories.push(new PriceAgreementCategory({ categoryId, included: true, services }));
    });
  }

  addNewYear() {
    if (this.isManualIndex()) {
      this.priceAgreement.categories.forEach(category => {
        const categoryEnum = SosServicesCategoryEnum[category.categoryId];
        if (category.included &&
          categoryEnum !== SosServicesCategoryEnum.ADDITIONAL_MEDICAL_SERVICES &&
          categoryEnum !== SosServicesCategoryEnum.ADDITIONAL_MEDICAL_SERVICES_ESCORT
        ) {
          category.services.forEach(service => {
            if (service.years.length > 0) {
              const toYear = service.years[service.years.length - 1];
              service.years.push(toYear + 1);
              service.prices.push(null);
              service.shares.push(null);
            } else {
              service.years.push(this.contract.commencementDate.getFullYear() + 1);
              service.prices.push(null);
              service.shares.push(null);
            }
            this.numberOfYearsInService = service.years.length;
          });
        }
      });
    }
  }

  private findMaxYear(category: PriceAgreementCategory): number {
    let maxYear = 0;
    category.services.forEach(service => {
        if (service.years.length > 0) {
          maxYear = Math.max(maxYear, service.years[service.years.length - 1]);
        } else {
          maxYear = Math.max(maxYear, this.contract.commencementDate.getFullYear());
        }
      });
    return maxYear;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  reloadWithNewServices() {
    this.priceAgreementService.getPriceAgreementWithNewServices(this.contract.id, this.priceAgreement.id).subscribe(
      {
        next: (result: PriceAgreement) => {
          this.priceAgreement = result;
          this.oldPriceAgreement = JSON.parse(JSON.stringify(this.priceAgreement));
          this.priceAgreement.categories.forEach(c => {
            c.services.forEach(s => {
            });
          });
        },
        error: (error) => console.log(error)
      }
    );
  }
}
