<div class="drop-down-auto-complete">
  <div class="p-2 dropdown-cell" [ngStyle]="!withInput && {'width':'100%'}">
    <span class="name">{{name}}</span>
    <ng-select #select (blur)="onSaveModel()" [typeahead]="input" (focus)="onSaveState()" [ngClass]="showCode === true ? 'small' : 'custom'"
      [clearable]="false" [(ngModel)]="selectedItemId" placeholder="" (change)="onChange()" [items]="items | async" [disabled]="disabled"
      bindValue="code">
      <ng-template ng-label-tmp let-item="item" let-index="index">
        <div *ngIf="item.url !== undefined; else generateData">
          <div *ngIf="showCode; else showName">
            <img *ngIf="item.url" [src]="item.url">
            {{item.code}}
          </div>
          <ng-template #showName>
            <img *ngIf="item.url" [src]="item.url" class="country-image">
            <span class="country-name-shown">{{item.countryName}} </span>
          </ng-template>
        </div>
        <ng-template #generateData>
          <img *ngIf="item.url" [src]="item.url">
          <span *ngIf="showCode; else showName">
            {{item.code}}
          </span>
          <ng-template #showName>
            {{countryName}}
          </ng-template>
        </ng-template>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <div class="row">
          <div class="col-2">
            <img [src]="item.url" class="country-image">
          </div>
          <div class="col-10">
            <div class="country-name">{{item.countryName}} </div>
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
</div>
