import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelperService } from '@onepoint/core/services/helper.service';

@Component({
  selector: 'app-sort-column',
  templateUrl: './sort-column.component.html',
  styleUrls: ['./sort-column.component.scss']
})
export class SortColumnComponent implements OnInit {
  @Input() list: any[];
  @Input() sortingFunction: (a: any, b: any) => number;
  @Input() sortingKey: string;
  @Input() menuGroup: Map<string, boolean> = new Map<string, boolean>();
  @Output() modelChanged = new EventEmitter();

  constructor(private helperService: HelperService) { }

  ngOnInit(): void {
  }

  toggleMenu() {
    this.menuGroup?.forEach((value, key) => {
      if (key !== this.sortingKey) {
        this.menuGroup.set(key, false);
      }
    });

    this.menuGroup?.set(this.sortingKey, !this.menuGroup.get(this.sortingKey));
  }

  sort(ascending: boolean) {
    if (this.sortingFunction) {
      this.list.sort(ascending ? this.sortingFunction : (a, b) => this.sortingFunction(b, a));
    } else {
      this.helperService.sortArray(this.list, this.sortingKey, ascending);
    }
    this.toggleMenu();
  }

  get isShowMenu() {
    return this.menuGroup?.get(this.sortingKey);
  }
}
