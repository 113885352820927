import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasterListService } from '@onepoint/core/services/masterlist.service';
import { OnepointModalService } from '@onepoint/core/services/onepoint.modal.service';
import { Dictionary } from '@onepoint/shared/models/dictionary';
import { MasterListIncidentEventModel } from '@onepoint/shared/models/masterlist-incident-event-model';
import { PermissionService } from 'app/core/services/permission.service';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import { EventCategory } from 'app/shared/models/enums';
import {forIn} from 'lodash';
import { Key } from 'protractor';
import * as _ from 'lodash';



@Component({
  selector: 'app-masterlist-incident-event-modal',
  templateUrl: './masterlist-incident-event-modal.component.html',
  styleUrls: ['./masterlist-incident-event-modal.component.scss']
})
export class IncidentEventModalComponent implements OnInit  {

  @Input()
  masterListItem: MasterListIncidentEventModel;

  @Output()
  refreshTable = new EventEmitter();

  error: string;
  canUpdate: boolean;
  isModelChanged: boolean;
  minDeactivationDate: Date;
  categories: Dictionary[] = [];
  oldMasterListItem: MasterListIncidentEventModel;


  vatValues: Dictionary[] = [
    { id: 'true', name: 'Yes' },
    { id: 'false', name: 'No' },
  ];

  constructor(private onepointModalService: OnepointModalService,
              private permissionService: PermissionService,
              private masterListService: MasterListService,) { }

  ngOnInit(): void {
    forIn(EventCategory, (value, key) => this.categories.push(new  Dictionary(key, value)));
    this.minDeactivationDate = new Date(Date.now() - 86400000);
    this.permissionService.permissionsReadyNotification.subscribe(
      result => (this.canUpdate = this.permissionService.checkUserPermission(PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE))
    );
    if (this.masterListItem.activateOn > this.minDeactivationDate) {
      this.minDeactivationDate = this.masterListItem.activateOn;
    }
  }

  save() {
    if ( this.isNew ) {
      this.masterListService.addIncidentEvent(this.masterListItem).subscribe(
        response => {
          this.updateMasterlistItem(response);
        },
        err => this.error = err.error ? err.error.message : err.message
      );
    } else {

      this.masterListService.updateIncidentEvent(this.masterListItem).subscribe(
        response => {
          this.updateMasterlistItem(response);
        },
        err => (this.error = err.error ? err.error.message : err.message)
      );
    }
  }

  updateMasterlistItem(masterListIncidentEventModel: MasterListIncidentEventModel) {
    this.masterListItem = masterListIncidentEventModel;
    this.refreshTable.emit();
    this.closeModal();
  }

  closeModal() {
    this.oldMasterListItem = null;
    this.onepointModalService.dismissAll();
  }

  get isSaveButtonEnabled(): boolean {
    return (
            this.isModelChanged &&
            this.masterListItem &&
            !!this.masterListItem.activateOn &&
            !!this.masterListItem.businessKey &&
            !!this.masterListItem.name &&
            !!this.masterListItem.category
    );
  }

  ngOnChanges() {
    this.oldMasterListItem = _.cloneDeep(this.masterListItem);
  }

  activateOnChanged(item: MasterListIncidentEventModel) {
    this.modelChanged();
    item.activateOn.setHours(12, 0);
  }

  deactivateOnChanged(item: MasterListIncidentEventModel) {
    this.modelChanged();
        item.deactivateOn.setHours(12, 0);
  }

  dateOfEventOnChanged(item: MasterListIncidentEventModel) {
    this.modelChanged();
    item.dateOfEvent.setHours(12, 0);
  }

  modelChanged(): void {
    this.isModelChanged = !(JSON.stringify(this.masterListItem) === JSON.stringify(this.oldMasterListItem));
  }

  get isReadonly(): boolean {
    return !this.canUpdate;
  }

  get isNew(): boolean {
    return this.masterListItem.id == null;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
