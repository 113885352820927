import { TemplateFilteringService } from './template-filtering-service';

import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { DocumentTemplate } from 'app/shared/models/templates/document.template';
import { TemplateFilteringSortingMenuType } from './template-filtering-sorting-menu-type';
import { HelperService } from 'app/core/services/helper.service';

@Component({
  selector: 'app-template-filtering-sorting-menu',
  templateUrl: './template-filtering-sorting-menu.component.html',
  styleUrls: ['./template-filtering-sorting-menu.component.scss'],
})
export class TemplateFilteringSortingMenuComponent implements OnInit {
  @Input() documentTemplateList: DocumentTemplate[];
  @Input() filteringSortingMenuType: TemplateFilteringSortingMenuType;
  @Output() modelChanged = new EventEmitter();
  @Output() documentTemplateListChange = new EventEmitter();
  @Output() clearAllFilters = new EventEmitter();
  sortByAscending: boolean;
  dateFilter: boolean;
  sortingKey: string;
  items: CheckBoxModel[];
  constructor(
    private helperService: HelperService,
    private filteringService: TemplateFilteringService
  ) { }

  ngOnInit() {
    this.populateItems(this.documentTemplateList);
  }

  public uncheckItems() {
    if (this.items) {
      this.items.forEach(item => (item.check = false));
    }
  }

  sortAscending() {
    this.sortByAscending = true;
    this.helperService.sortArray(this.documentTemplateList, this.sortingKey, this.sortByAscending);
    this.modelChanged.emit();
  }

  sortDescending() {
    this.sortByAscending = false;
    this.helperService.sortArray(this.documentTemplateList, this.sortingKey, this.sortByAscending);
    this.modelChanged.emit();
  }

  private addFilterOption(option: string, items: CheckBoxModel[]) {
    if (!items.find(item => item.id === option)) {
      this.items.push(new CheckBoxModel(option, option, false));
    }
  }

  populateItems(templates: DocumentTemplate[]) {
    if (!templates) {
      return;
    }
    this.items = [];
    switch (this.filteringSortingMenuType) {
      case TemplateFilteringSortingMenuType.templateType:
        templates.forEach(template => this.addFilterOption(template.templateType, this.items));
        this.sortingKey = 'type';
        break;
      case TemplateFilteringSortingMenuType.receiverType:
        templates.forEach(template => this.addFilterOption(template.receiverType, this.items));
        this.sortingKey = 'receiverType';
        break;
      case TemplateFilteringSortingMenuType.serviceType:
        templates.forEach(template => this.addFilterOption(template.serviceType, this.items));
        this.sortingKey = 'serviceType';
        break;
      case TemplateFilteringSortingMenuType.documentType:
        templates.forEach(template => this.addFilterOption(template.documentType, this.items));
        this.sortingKey = 'documentType';
        break;
      case TemplateFilteringSortingMenuType.tags:
        templates.forEach(template => {
          if (template.tags) {
            template.tags.forEach(tag => this.addFilterOption(tag, this.items))
          } else {
            this.addFilterOption('', this.items);
          }
        });
        this.sortingKey = 'tags';
        break;
      case TemplateFilteringSortingMenuType.displayName:
        templates.forEach(template => this.addFilterOption(template.name, this.items));
        this.sortingKey = 'name';
        break;
      case TemplateFilteringSortingMenuType.fileName:
        templates.forEach(template => this.addFilterOption(template.fileName, this.items));
        this.sortingKey = 'fileName';
        break;
      case TemplateFilteringSortingMenuType.language:
        templates.forEach(template => this.addFilterOption(template.languageCode, this.items));
        this.sortingKey = 'languageCode';
        break;
      case TemplateFilteringSortingMenuType.subject:
        templates.forEach(template => this.addFilterOption(template.subject, this.items));
        this.sortingKey = 'subject';
        break;
    }
  }
  addSortOption(integrationType: string): void {
    if (!this.items.find(item => item.id === integrationType.trim())) {
      this.items.push(new CheckBoxModel(integrationType.trim(), integrationType.trim(), false));
    }
  }

  get isAnyItemChecked() {
    return this.items.find(item => item.check) != null;
  }

  applyButtonClick() {
    if (!this.isAnyItemChecked) {
      return;
    }
    this.clearAllFilters.emit();
    let selectedIds = [];
    this.items.filter(item => item.check).forEach(item => selectedIds.push(item.id));
    this.filteringService.removeStep(this.filteringSortingMenuType);
    this.filteringService.addStep(
      this.filteringSortingMenuType,
      selectedIds
    );
    this.documentTemplateList = this.filteringService.filter(this.documentTemplateList);
    this.documentTemplateListChange.emit(this.documentTemplateList);
    this.modelChanged.emit();
  }

  clearAllButtonClick() {
    if (!this.isAnyItemChecked) {
      return;
    }
    this.items.forEach(a => (a.check = false));
    this.filteringService.removeStep(this.filteringSortingMenuType);
    this.clearAllFilters.emit();
    this.documentTemplateList = this.filteringService.filter(this.documentTemplateList);
    this.documentTemplateListChange.emit(this.documentTemplateList);
  }
  modalWindowClick(event: any) {
    event.stopPropagation();
  }
}

export class CheckBoxModel {
  name: string;
  id: string;
  check: boolean;

  constructor(id: string, name: string, check: boolean) {
    this.check = check;
    this.id = id;
    this.name = name;
  }
}
