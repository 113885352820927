export enum SosServicesMenuType {
  sosServiceId = 0,
  name = 1,
  category = 2,
  validStart = 3,
  validEnd = 4,
  feeType = 5,
  feePriority = 6,
  includedInStandard = 7,
  vat = 8,
  targetPrice = 9,
  empty = 10,
}
