
<div class="page-container">
  <div class="page-header">
      <div class="my-auto counts-container">
        <div class="count-container">
          <span>Reason for cancellation</span> <span>{{masterListFiltered?.length}}</span>
        </div>
      </div>
      <div class="status-container my-auto">
        <app-drop-down [(selectedItemId)]="selectedStatus"
                       [items]="statusTypes"
                       (selectedItemIdChange)="selectedStatusTypeChanged($event)">
        </app-drop-down>
      </div>
      <div class="button-container my-auto">
        <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_CREATE">
          <button class="primary-button" (click)="addNew()">
            Add Reason for Cancellation
          </button>
        </div>
      </div>
  </div>
  <div class="primary-table page-content-container">
    <table class="master-lists-table">
      <thead>
        <tr>
          <td (click)="showFilteringSortingMenu(ReasonForCancellationMenuType.REASON_FOR_CANCELLATION_CODE)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Business key</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(ReasonForCancellationMenuType.REASON_FOR_CANCELLATION_CODE) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ReasonForCancellationMenuType.REASON_FOR_CANCELLATION_CODE"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ReasonForCancellationMenuType.REASON_FOR_CANCELLATION_NAME)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Reason for cancellation name</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(ReasonForCancellationMenuType.REASON_FOR_CANCELLATION_NAME) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ReasonForCancellationMenuType.REASON_FOR_CANCELLATION_NAME"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ReasonForCancellationMenuType.UI_SORT_INDEX)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Sort in UI</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(ReasonForCancellationMenuType.UI_SORT_INDEX) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ReasonForCancellationMenuType.UI_SORT_INDEX"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ReasonForCancellationMenuType.MODAL_TYPE)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Modal type</span>
            </div>
          </td>
          <td *ngFor="let lang of languages | keyvalue: keepOrder" class="language-header filter-column unsortable-column">
            <app-sort-column [list]="masterListFiltered" [sortingKey]="lang" [sortingFunction]="translationSort(lang.value)" [menuGroup]="menuGroup">{{lang.key}}</app-sort-column>
          </td>
          <td (click)="showFilteringSortingMenu(ReasonForCancellationMenuType.ACTIVATE_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Activation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(ReasonForCancellationMenuType.ACTIVATE_ON) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ReasonForCancellationMenuType.ACTIVATE_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ReasonForCancellationMenuType.DEACTIVATION_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Deactivation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(ReasonForCancellationMenuType.DEACTIVATION_ON) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ReasonForCancellationMenuType.DEACTIVATION_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of masterListFiltered" (click)="edit(item)">
          <td>
            <div class="first-column-container">
              <div class="first-column-content">
                {{ item.reasonForCancellationCode }}
              </div>
            </div>
          </td>
          <td class="reasonForCancellationNameWidth">
            {{ item.reasonForCancellationName }}
          </td>
          <td>
            {{ item.uiSortIndex }}
          </td>
          <td>
            {{ item.modalType }}
          </td>
          <td class="p-0" *ngFor="let lang of languages | keyvalue: keepOrder">
            <div class="language-item">
              <img *ngIf="hasDuplicateTranslation(item, lang.value)" class="warning" src="assets/icons/Warning.svg" container="body"
                  [ngbPopover]="'Same as English translation'" triggers="hover" placement="top">
              <img src="assets/icons/success.svg" alt="exists" *ngIf="!!item.translationsAvailable[lang.value]">
              <div class='ml-1 no-col-on' *ngIf="!item.translationsAvailable[lang.value]"></div>
            </div>
          </td>
          <td>
            {{ item.activateOn | date: 'dd MMM yyyy'}}
          </td>
          <td>
            {{ item.deactivateOn | date: 'dd MMM yyyy'}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-template #MasterListModal>
    <app-masterlist-reason-for-cancellation-modal [masterListItem]="masterListItem" (refreshTable)="reload()">
    </app-masterlist-reason-for-cancellation-modal>
  </ng-template>
</div>
