import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { PriceAgreementCategory, PriceAgreementCategoryService } from '../../../../../../../shared/models/price-agreement';
import { SosServices } from '../../../../../../../shared/models/sos-services';
import { SosServicesCategoryEnum } from 'app/shared/models/enums';

@Component({
  selector: 'app-price-agreement-service-category',
  templateUrl: './agreement-category.component.html',
  styleUrls: ['./agreement-category.component.scss'],
})
export class AgreementCategoryComponent {
  private static PRICE_CONTAINER_WIDTH = 950;
  @ViewChild('priceContainerDiv', {static: false}) priceContainerDiv: ElementRef | undefined;

  @Output() dataChange = new EventEmitter();
  @Input() priceAgreementCategory: PriceAgreementCategory;
  @Input() editable: boolean;
  @Input() categoryId;
  @Input() categoryName: string;
  @Input() services: SosServices[];
  @Input() firstPriceYear: number;
  @Input() manualIndex: boolean;
  @Input() showType: string;
  @Input() suspended: boolean;
  @Input() numberOfYearsInService: number; // Used to trigger ngOnChanges and get correct scrollWidth

  scrollLeft: number;

  options = { prefix: '', allowNegative: false, suffix: '' };
  SosServicesCategoryEnum: SosServicesCategoryEnum;
  @Input('currency') set currency(value: string) {
    if (value) {
      this.options.suffix = ' ' + value;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.scrollLeft = this.priceContainerDiv ? this.priceContainerDiv.nativeElement.scrollWidth : AgreementCategoryComponent.PRICE_CONTAINER_WIDTH;
  }

  categoryIncluded(): boolean {
    return this.priceAgreementCategory != null && this.priceAgreementCategory.included;
  }

  getService(id: string) {
    if (this.priceAgreementCategory.services == null) {
      this.priceAgreementCategory.services = [];
    }
    let service = this.priceAgreementCategory.services.find(s => String(s.serviceId) === String(id));
    if (service != null) {
      return service;
    }
    service = new PriceAgreementCategoryService();
    service.serviceId = Number(id);
    this.priceAgreementCategory.services.push(service);
    return service;
  }

  recalculateValid() {
    this.dataChange.emit();
  }

  getYears(): number[] {
    if (this.priceAgreementCategory.services) {
      let includedService = this.priceAgreementCategory.services.find(service => service.included);
      return includedService ? includedService.years : [];
    } else {
      return [];
    }
  }
}
