import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasterListReductionModel } from '@onepoint/shared/models/masterlist-reduction-model';
import { MasterListService } from 'app/core/services/masterlist.service';
import { OnepointModalService } from 'app/core/services/onepoint.modal.service';
import { PermissionService } from 'app/core/services/permission.service';
import { SosServicesService } from 'app/core/services/sos-services.service';
import { Dictionary } from 'app/shared/models/dictionary';
import { DictionaryWithType } from 'app/shared/models/dictionary-with-type';
import { MasterListServiceTypeModel } from 'app/shared/models/masterlist-service-type-model';
import { PermissionEnum } from 'app/shared/models/permission.enum';

@Component({
  selector: 'app-masterlist-reduction-modal',
  templateUrl: './masterlist-reduction-modal.component.html',
  styleUrls: ['./masterlist-reduction-modal.component.scss'],
})
export class MasterlistReductionModalComponent implements OnInit {
  @Input()
  masterListItem: MasterListReductionModel;

  @Output()
  refreshTable = new EventEmitter();

  serviceTypes: DictionaryWithType[];
  vatValues: Dictionary[] = [
    { id: 'true', name: 'Yes' },
    { id: 'false', name: 'No' },
  ];
  minDeactivationDate: Date;
  selectedServiceTypeId: string;
  selectedVatValue: string;
  error: string;
  canUpdate: boolean;
  isModelChanged: boolean;
  constructor(
    private masterListService: MasterListService,
    private onepointModalService: OnepointModalService,
    private sosServicesService: SosServicesService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.minDeactivationDate = new Date(Date.now() - 86400000);
    this.permissionService.permissionsReadyNotification.subscribe(
      result => (this.canUpdate = this.permissionService.checkUserPermission(PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE))
    );
    if (this.masterListItem.activateOn > this.minDeactivationDate) {
      this.minDeactivationDate = this.masterListItem.activateOn;
    }
  }

  closeModal() {
    this.onepointModalService.dismissAll();
  }

  get isSaveButtonEnabled(): boolean {
    return (
      this.isModelChanged &&
      this.masterListItem &&
      !!this.masterListItem.activateOn &&
      !!this.masterListItem.reductionCode &&
      !!this.masterListItem.reductionName
    );
  }

  save() {
    if ( this.isNew ) {
      this.masterListService.addReduction(this.masterListItem).subscribe(
        response => {
          this.masterListItem = response;
          this.refreshTable.emit();
          this.closeModal();
        },
        err => this.error = err.error ? err.error.message : err.message
      )
    } else {
  
    this.masterListService.updateReduction(this.masterListItem).subscribe(
      response => {
        this.masterListItem = response;
        this.refreshTable.emit();
        this.closeModal();
      },
      err => (this.error = err.error ? err.error.message : err.message)
    );
  }
  }

  activateOnChanged(item: MasterListServiceTypeModel) {
    this.modelChanged();
    item.activateOn.setHours(12, 0);
  }

  deactivateOnChanged(item: MasterListServiceTypeModel) {
    this.modelChanged();
    item.deactivateOn.setHours(12, 0);
  }

  modelChanged(): void {
    this.isModelChanged = true;
  }

  get isReadonly(): boolean {
    return !this.canUpdate;
  }

  get isNew(): boolean {
    return this.masterListItem.id == null;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
