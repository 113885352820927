import { SosServicesService } from 'app/core/services/sos-services.service';
import { Component, OnInit, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { SosServicesMenuType } from './sos-services-menu/sos-services-menu-type';
import { SosServices } from 'app/shared/models/sos-services';
import { Dictionary } from 'app/shared/models/dictionary';
import { HelperService } from 'app/core/services/helper.service';
import { SosServicesCategoryEnum } from 'app/shared/models/enums';

@Component({
  selector: 'app-sos-services-table',
  templateUrl: './sos-services-table.component.html',
  styleUrls: ['./sos-services-table.component.scss'],
})
export class SosServicesTableComponent implements OnInit {
  sosServices: SosServices[];
  filteredSosServicesListView: SosServices[];
  searchText = '';
  showMenu: boolean[] = new Array(SosServicesMenuType.empty);
  feeTypeList: Dictionary[];

  constructor(
    private sosServicesService: SosServicesService,
    private router: Router,
    private applicationRef: ApplicationRef,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.sosServicesService.getAll().subscribe(
      sosServices => {
        this.sosServices = this.initSort(sosServices);
        this.filteredSosServicesListView = sosServices;
        this.sosServicesService.changedSosServiceCount(sosServices);
      },
      error => console.log(error)
    );
    this.sosServicesService.getFeeTypeList().subscribe(
      result => (this.feeTypeList = result),
      error => console.error(error)
    );
  }

  initSort(sosServices: SosServices[]) {
    return this.helperService.sortArray(sosServices, 'name', true);
  }

  public searchTextValueChange(searchText: string) {
    searchText = searchText.toLowerCase();
    this.filteredSosServicesListView = this.sosServices.filter(item => item.name.toLowerCase().search(searchText) >= 0);
    this.sosServices.forEach(item => {
      if (
        item.sosServicesCategoryEnum.toLowerCase().search(searchText) >= 0 ||
        item.feeTypeEnum.toLowerCase().search(searchText) >= 0 ||
        String(item.feePriority).search(searchText) >= 0 ||
        String(item.targetPrice.amount).search(searchText) >= 0
      ) {
        if (this.filteredSosServicesListView.find(x => x.id === item.id) == null) {
          this.filteredSosServicesListView.push(item);
        }
      }
    });
    this.sosServicesService.changedSosServiceCount(this.filteredSosServicesListView);
  }

  get SosServicesMenuType() {
    return SosServicesMenuType;
  }

  get SosServicesCategoryEnum() {
    return SosServicesCategoryEnum;
  }

  filterTable() {
    this.filteredSosServicesListView = this.sosServices;
  }
  clearAllFiltering() {
    this.filterTable();
    this.applicationRef.tick();
    this.showFilteringSortingMenu(SosServicesMenuType.empty);
  }

  navigate(sosServices: SosServices) {
    this.router.navigate(['/sos-services/', sosServices.id]);
  }

  expandCollapse(sosServices: SosServices, event: Event) {
    event.stopPropagation();
    this.applicationRef.tick();
  }

  finishedAndFilteringSorting() {
    this.showFilteringSortingMenu(SosServicesMenuType.empty);
  }

  showFilteringSortingMenu(filteringSortingMenuType: SosServicesMenuType) {
    for (let i = 0; i < this.showMenu.length; i++) {
      if (i !== filteringSortingMenuType) {
        this.showMenu[i] = false;
      }
    }
    this.showMenu[filteringSortingMenuType] = !this.showMenu[filteringSortingMenuType];
  }
}
