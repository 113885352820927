export class DualListboxItem {
    id: number;
    name: string;
    object: any;
    selected = false;

    constructor(id: number, name: string, object: any) {
        this.id = id;
        this.name = name;
        this.object = object;
    }
}
