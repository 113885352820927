import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Component,
  Output,
  OnInit,
  Input,
  ViewEncapsulation,
  EventEmitter,
  forwardRef
} from '@angular/core';

@Component({
  selector: 'app-input-money',
  templateUrl: './input-money.component.html',
  styleUrls: ['./input-money.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputMoneyComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  @Input() placeholder: string;
  @Input() name: string;
  @Input() value: string;
  @Input() selectedCurrency: string = 'DKK';
  @Input() required: boolean;
  @Input() maxTextLength: number = 100000;

  @Input() disabled: boolean;

  constructor() {
    if (this.placeholder === undefined) {
      this.placeholder = '';
    }
  }
  ngOnInit() {}

  onChangeValue() {
    this.valueChange.emit(this.value);
  }
}
