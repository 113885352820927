import { CustomerListView } from 'app/shared/models/customer-list-view';
import { Kam, KamAdapter } from './../../../../shared/models/kam';
import { DictionaryService } from './../../../../core/services/dictionary.service';
import { Component, OnInit, Input } from '@angular/core';
import { BusinessArea } from 'app/shared/models/customers/business-area';

@Component({
  selector: 'app-profile-overview',
  templateUrl: './profile-overview.component.html',
  styleUrls: ['./profile-overview.component.scss']
})
export class ProfileOverviewComponent implements OnInit {
  kams: Kam[];
  areas: BusinessArea[];
  @Input() numberOfProfiles: number;
  @Input() products: CustomerListView[];
  @Input() set profile(customerListView: CustomerListView) {
    if (customerListView !== undefined) {
      this._profile = customerListView;
      if (this._profile.market) {
        this.markets = [];
        this.markets.push(this.dictionaryService.getMarketName(
        this._profile.market.toUpperCase()
      ));
      }
      this.areas = this._profile.getBusinessAreas();
      this.kams = this._profile.getProfileKams(this.kamAdapter);
    }
  }

  get profile(): CustomerListView {
    return this._profile;
  }
  private _profile: CustomerListView;
  markets: string[];

  constructor(
    private dictionaryService: DictionaryService,
    private kamAdapter: KamAdapter
  ) {}

  ngOnInit() {}
}
