export enum FilteringSortingMenuType {
  customer = 0,
  market = 1,
  kam = 2,
  segment = 3,
  subBusinessRelation = 4,
  integrationType = 5,
  businessArea = 6,
  nextNegotiation = 7,
  empty = 8
}
