<div
  *ngIf="
    SosServicesCategoryEnum[categoryId] === SosServicesCategoryEnum.SHARE_OF_COST_CONTROL ||
    SosServicesCategoryEnum[categoryId] === SosServicesCategoryEnum.COMMUNICATION_COST ||
    SosServicesCategoryEnum[categoryId] === SosServicesCategoryEnum.BANK_FEE ||
    SosServicesCategoryEnum[categoryId] === SosServicesCategoryEnum.SHARE_OF_RECOVERY
  "
>
  <div class="d-flex service-row" [class.service-not-included]="!priceAgreementService.included">
    <div class="service-name"  triggers="hover" openDelay="2000" [ngbPopover]="sosServices.name + ' - percentage'">
      {{ sosServices.name }}
      <span>- percentage</span>
      <span class="not-default" *ngIf="!sosServices.includedInStandard">*</span>
    </div>
    <div class="slider float-end" *ngIf="!suspended && categoryIncluded">
      <app-input-switch
        [id]="'service-' + sosServices.id"
        [value]="priceAgreementService.included"
        (valueChange)="priceAgreementService.included = $event.valueOf()"
      >
      </app-input-switch>
    </div>
  </div>
</div>
<div *ngIf="SosServicesCategoryEnum[categoryId] !== SosServicesCategoryEnum.COMMUNICATION_COST && SosServicesCategoryEnum[categoryId] !== SosServicesCategoryEnum.BANK_FEE">
  <div class="d-flex service-row" [class.service-not-included]="!priceAgreementService.included">
    <div class="service-name" triggers="hover" openDelay="2000" [ngbPopover]="sosServices.name">
      {{ sosServices.name }}
      <span *ngIf="
        SosServicesCategoryEnum[categoryId] === SosServicesCategoryEnum.SHARE_OF_COST_CONTROL ||
        SosServicesCategoryEnum[categoryId] === SosServicesCategoryEnum.SHARE_OF_RECOVERY
      ">- maximum fee</span>
      <span class="not-default" *ngIf="!sosServices.includedInStandard">*</span>
    </div>
    <div
      class=" slider-part slider"
      *ngIf="
        !suspended &&
        categoryIncluded &&
        SosServicesCategoryEnum[categoryId] !== SosServicesCategoryEnum.SHARE_OF_COST_CONTROL &&
        SosServicesCategoryEnum[categoryId] !== SosServicesCategoryEnum.SHARE_OF_RECOVERY
    ">
      <app-input-switch
        [id]="'service-' + sosServices.id"
        [value]="priceAgreementService.included"
        (valueChange)="setIncluded($event.valueOf())"
      >
      </app-input-switch>
    </div>
  </div>
</div>
