import { Injectable } from '@angular/core';
import { SupplierProfile } from 'app/shared/models/supplier-profile';
import { SupplierMenuType } from './supplier-menu-type';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class FilteringService {
  filteringStep: FilteringStep[] = [];
  constructor() {}

  addStep(supplierMenuType: SupplierMenuType, selectedIds: string[], dateFrom: Date, dateTo: Date) {
    this.filteringStep.push(new FilteringStep(supplierMenuType, selectedIds, dateFrom, dateTo));
  }

  removeStep(supplierMenuType: SupplierMenuType) {
    let itemToDelete = this.filteringStep.find(a => a.filteringSortingMenuType === supplierMenuType);
    if (itemToDelete) {
      this.filteringStep.splice(this.filteringStep.indexOf(itemToDelete));
    }
  }

  hasPartner(supplier: SupplierProfile, selectedSupplierId: string): boolean {
    let startOfToday = moment.utc().startOf('day').tz("Europe/Copenhagen").toDate();
    return supplier.supplierProfilePartners.filter(partner => partner.supplierProfilePartnerId.toString() === selectedSupplierId && partner.startDate <= startOfToday && (partner.endDate == null || partner.endDate >= startOfToday)).length > 0;
  }

  filter(supplierProfileListView: SupplierProfile[]): SupplierProfile[] {
    let tempSupplierProfileListView: Set<SupplierProfile>;

    this.filteringStep.forEach(step => {
      tempSupplierProfileListView = new Set();
      switch (step.filteringSortingMenuType) {
        case SupplierMenuType.supplierProfileCode:
          step.selectedIds.forEach(element => {
            supplierProfileListView.filter(a => a.supplierProfileCode === element).forEach(a => tempSupplierProfileListView.add(a));
          });
          break;
        case SupplierMenuType.supplierProfileName:
          step.selectedIds.forEach(element => {
            supplierProfileListView.filter(a => a.supplierProfileName === element).forEach(a => tempSupplierProfileListView.add(a));
          });
          break;
        case SupplierMenuType.providerId:
          step.selectedIds.forEach(element => {
            supplierProfileListView.filter(a => a.providerId === +element).forEach(a => tempSupplierProfileListView.add(a));
          });
          break;
        case SupplierMenuType.creditorNumber:
          step.selectedIds.forEach(element => {
            supplierProfileListView.filter(a => String(a.creditorNumber) === element).forEach(a => tempSupplierProfileListView.add(a));
          });
          break;
        case SupplierMenuType.creditorName:
          step.selectedIds.forEach(element => {
            supplierProfileListView.filter(a => a.creditorName === element).forEach(a => tempSupplierProfileListView.add(a));
          });
          break;
        case SupplierMenuType.creditorCurrency:
          step.selectedIds.forEach(element => {
            supplierProfileListView.filter(a => a.creditorCurrency === element).forEach(a => tempSupplierProfileListView.add(a));
          });
          break;
        case SupplierMenuType.networkManagerId:
          step.selectedIds.forEach(element => {
            supplierProfileListView.filter(a => a.networkManagerId === element).forEach(a => tempSupplierProfileListView.add(a));
          });
          break;
        case SupplierMenuType.startDate:
          supplierProfileListView
            .filter(a => step.dateFrom <= a.startDate && step.dateTo >= a.startDate)
            .forEach(a => tempSupplierProfileListView.add(a));
          break;
        case SupplierMenuType.endDate:
          supplierProfileListView
            .filter(a => step.dateFrom <= a.endDate && step.dateTo >= a.endDate)
            .forEach(a => tempSupplierProfileListView.add(a));
          break;
        case SupplierMenuType.type:
          step.selectedIds.forEach(element => {
            supplierProfileListView.filter(a => (+a.type) === (+element)).forEach(a => tempSupplierProfileListView.add(a));
          });
          break;
        case SupplierMenuType.partner:
          step.selectedIds.forEach(element => {
            supplierProfileListView
              .filter(a => this.hasPartner(a, element))
              .forEach(a => tempSupplierProfileListView.add(a));
          });
      }
      supplierProfileListView = Array.from(tempSupplierProfileListView);
    });
    return supplierProfileListView;
  }
}

export class FilteringStep {
  filteringSortingMenuType: SupplierMenuType;
  selectedIds: string[];
  dateFrom: Date;
  dateTo: Date;
  constructor(filteringSortingMenuType: SupplierMenuType, selectedIds: string[], dateFrom: Date, dateTo: Date) {
    this.filteringSortingMenuType = filteringSortingMenuType;
    this.selectedIds = selectedIds;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
  }
}
