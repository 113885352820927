import { HelperService } from '../../../../core/services/helper.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { ProfileApplication } from '@onepoint/shared/models/profile-application';

@Component({
  selector: 'app-customer-profile-applications-overview-menu',
  templateUrl: './customer-profile-applications-overview-menu.component.html',
  styleUrls: ['./customer-profile-applications-overview-menu.component.scss'],
})
export class CustomerProfileApplicationsOverviewMenuComponent implements OnInit {
  @Input() profileApplications: ProfileApplication[];
  @Input() sortingKey: string;
  @Output() modelChanged = new EventEmitter();
  sortByAscending: boolean;
  dateFilter: boolean;
  showFilter: boolean;
  fromDate: any;
  toDate: any;
  constructor(private helperService: HelperService) {}

  ngOnInit() {
  }

  sortAscending() {
    this.sortByAscending = true;
    this.helperService.sortArray(
      this.profileApplications,
      this.sortingKey,
      this.sortByAscending
    );
    this.modelChanged.emit();
  }

  sortDescending() {
    this.sortByAscending = false;
    this.helperService.sortArray(
      this.profileApplications,
      this.sortingKey,
      this.sortByAscending
    );
    this.modelChanged.emit();
  }  
}
