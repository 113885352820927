import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  constructor() {}

  compareValues(key, order = 'asc') {
    return function(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];

      let comparison = 0;
      if (varA < varB) {
        comparison = 1;
      } else if (varA > varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }
  newGuid() {
    {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
        c
      ) {
        const r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    }
  }

  sortArray(
    array: Array<any>,
    key: string,
    sortByAscending: boolean,
    sortUsingEmptyValues: boolean = false
  ): Array<any> {
    array.sort((a, b) => {
      const sortA = this.sortValue(a[key]);
      const sortB = this.sortValue(b[key]);
      if (sortByAscending) {
        if (sortUsingEmptyValues && (sortA === '' || sortB === '')) {
          return -2;
        }
        if (sortA < sortB) {
          return -1;
        }
        if (sortA > sortB) {
          return 1;
        }
      } else {
        if (sortUsingEmptyValues && (sortA === '' || sortB === '')) {
          return -2;
        }
        if (sortA > sortB) {
          return -1;
        }
        if (sortA < sortB) {
          return 1;
        }
      }
      return 0;
    });
    return array;
  }

  private sortValue(value: any) {
    let sortVal: any = '';
    if (typeof value === 'number') {
      sortVal = value;
    } else if (value) {
      sortVal = value.toString().toLowerCase();
      if (value.name) {
        sortVal = value.name.toString().toLowerCase();
      }
      if (Object.keys(value).indexOf('amount') > -1) {
        sortVal = value.amount;
      }
    }
    return sortVal;
  }

  public getEnumKeyByEnumValue(myEnum, enumValue): any {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
}
