import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from './settings.service';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {PolicyLookupMethodTypeEnum} from '../../shared/enums/policy/policy-lookup-method-type.enum';
import {PolicyLookupMethod, PolicyLookupMethodAdapter} from '../../shared/models/customers/product/policy/policy-lookup-method';
import { CustomerRelationLabel, CustomerRelationLabelAdapter } from '../../shared/models/customer-relation-label';
import { CustomerProductRelation, CustomerProductRelationAdapter } from 'app/shared/models/customers/product/policy/customer-product-relation';
import { CustomerProductRelationOverview, CustomerProductRelationOverviewAdapter } from 'app/shared/models/customer-product-relation-overview';

@Injectable({
  providedIn: 'root'
})
export class PolicyLookupMethodService extends BaseService {
  constructor(private http: HttpClient,
              private settingsService: SettingsService,
              private policyLookupMethodAdapter: PolicyLookupMethodAdapter,
              private customerRelationLabelAdapter: CustomerRelationLabelAdapter,
              private customerProductRelationAdapter: CustomerProductRelationAdapter,
              private customerProductRelationOverviewAdapter: CustomerProductRelationOverviewAdapter) {
    super(settingsService);
  }

  public save(item: PolicyLookupMethod): Observable<PolicyLookupMethod> {
    const data = {};
    Object.assign(data, item);
    (data as {
      type: string
    }).type = this.getPolicyLookupMethodTypeCode(item.type);
    return this.http.put(this.baseURL + 'products/policy-lookup-method', data, {headers: this.jsonHeaders})
      .pipe(map(result => this.policyLookupMethodAdapter.adapt(result)));
  }

  private getPolicyLookupMethodTypeCode(type: PolicyLookupMethodTypeEnum): string {
    for (const key in PolicyLookupMethodTypeEnum) {
      if (type === PolicyLookupMethodTypeEnum[key]) {
        return key;
      }
    }
    return null;
  }

  getPolicyLookupMethod(productId: number) {
    return this.http.get(this.baseURL + 'products/policy-lookup-method/' + productId)
      .pipe(map(result => this.policyLookupMethodAdapter.adapt(result)));
  }

  public getCustomerRelationLabels(): Observable<CustomerRelationLabel[]> {
    return this.http
      .get(this.baseURL + 'customer-relation-label')
      .pipe(map((data: any[]) => data.map(item => this.customerRelationLabelAdapter.adapt(item))));
  }

  public createCustomerRelationLabel(customerRelationLabel: CustomerRelationLabel): Observable<CustomerRelationLabel> {
    return this.http.post<CustomerRelationLabel>(this.baseURL + 'customer-relation-label/create-customer-relation-label', customerRelationLabel, {
      headers: this.jsonHeaders,
    }).pipe(map(item => this.customerRelationLabelAdapter.adapt(item)));
  }

  public updateCustomerRelationLabels(customerRelationLabel: CustomerRelationLabel[]): Observable<CustomerRelationLabel[]> {
    return this.http.put<CustomerRelationLabel[]>(this.baseURL + 'customer-relation-label/update-customer-relation-label', customerRelationLabel, {
      headers: this.jsonHeaders
    }).pipe(map((data: any[]) => data.map(item => this.customerRelationLabelAdapter.adapt(item))));
  }

  public getCustomerProductRelationsByProductId(productId: number): Observable<CustomerProductRelation[]> {
    return this.http
      .get(this.baseURL + 'customer-product-relation/product/' + productId)
      .pipe(map((data: any[]) => data.map(item => this.customerProductRelationAdapter.adapt(item))));
  }

  public createCustomerProductRelations(customerProductRelation: CustomerProductRelation): Observable<CustomerProductRelation> {
    return this.http.post<CustomerProductRelation>(this.baseURL + 'customer-product-relation/create-customer-product-relation',
      customerProductRelation, {
      headers: this.jsonHeaders
    }).pipe(map(item => this.customerProductRelationAdapter.adapt(item)));
  }

  public getCustomerProductRelationsById(id: number): Observable<CustomerProductRelation> {
    return this.http
      .get(this.baseURL + 'customer-product-relation/' + id)
      .pipe(map(item => this.customerProductRelationAdapter.adapt(item)));
  }

  public updateCustomerProductRelations(customerProductRelation: CustomerProductRelation): Observable<CustomerProductRelation> {
    return this.http.put<CustomerProductRelation>(this.baseURL + 'customer-product-relation/update-customer-product-relation',
      customerProductRelation, {
      headers: this.jsonHeaders
    }).pipe(map(item => this.customerProductRelationAdapter.adapt(item)));
  }

  public deleteCustomerProductRelations(customerProductRelation: CustomerProductRelation): Observable<any> {
    return this.http.delete<any>(this.baseURL + 'customer-product-relation/delete/' + customerProductRelation.id , {
      headers: this.jsonHeaders
    });
  }

  public getAllCustomerProductRelations(): Observable<CustomerProductRelationOverview[]> {
    return this.http
      .get(this.baseURL + 'customer-product-relation/customer-product-relation-overview')
      .pipe(map((data: any[]) => data.map(item => this.customerProductRelationOverviewAdapter.adapt(item))));
  }
}
