import { Component, EventEmitter, Output, ViewChild, TemplateRef } from '@angular/core';
import {OnepointModalService, Size} from "@onepoint/core/services/onepoint.modal.service";
import {CustomerConfiguration} from "@onepoint/shared/models/digital-caseflow/customer-configuration";
import {DigitalCaseflowService} from "@onepoint/core/services/digital-caseflow.service";

@Component({
  selector: 'app-create-customer-config',
  templateUrl: './create-customer-config.component.html',
  styleUrls: ['./create-customer-config.component.scss']
})
export class CreateCustomerConfigComponent {
  @ViewChild('content')
  private template: TemplateRef<any>;
  @Output() updateCallback = new EventEmitter<CustomerConfiguration>();
  updateNeeded: boolean;
  name: string;
  constructor(private modalService: OnepointModalService, private digitalCaseflowService: DigitalCaseflowService) { }

  public open() {
    this.modalService.open(this.template, Size.NORMAL);
  }

  close() {
    this.modalService.dismissAll();
  }

  save() {
    this.digitalCaseflowService.createCustomerConfiguration(this.name).subscribe(data => {
      this.updateCallback.emit(data);
      this.modalService.dismissAll();
    });
  }

  validate(){
    if(this.name !== null){
      this.updateNeeded = true;
    }
  }

}
