<div class="add-new-profile-container justify-content-between">
  <span class="profiles-value">Profiles ({{numberOfProfiles}})</span>
  <button class="primary-button my-auto" (click)="addNewProfile()">Add new Profile</button>
</div>

<div class="profile-list-item" *ngFor="let profile of profiles">
  <div class="icon-name-markets">
    <div class="icon"> <img [src]="this.baseURL+'api'+customerListView.iconBlob" (click)="profileClick(profile)"></div>

    <div class="name" (click)="profileClick(profile)">
      <span class="star"><img *ngIf="!profile.groupProfileId && groupMembersExist()" src="assets/icons/star-blue.svg"/></span>
      <span class="profile-name">{{profile.name}}</span>
      <span class="profile-profile">Profile</span>
    </div>
    <div class="markets" (click)="profileClick(profile)">
      {{profile.market}}
      <div class="title">Markets</div>
    </div>
    <div class="areas" (click)="profileClick(profile)">
      <img *ngIf="profile.isTravel" src="assets/icons/travel-enabled.png">
      <img *ngIf="!profile.isTravel" src="assets/icons/travel-disabled.png">
      <img *ngIf="profile.isRoadside" src="assets/icons/roadside-enabled.png">
      <img *ngIf="!profile.isRoadside" src="assets/icons/roadside-disabled.png">
      <img *ngIf="profile.isHealth" src="assets/icons/health-enabled.png">
      <img *ngIf="!profile.isHealth" src="assets/icons/health-disabled.png">
    </div>
    <div class="kam" (click)="profileClick(profile)">
      <span class="profile-kam-name" *ngIf="profile.isHealth && profile.responsibleKamHealthUserId">{{profile.responsibleKamHealthFirstName}} {{profile.responsibleKamHealthSurname}} (Health)</span>
      <span class="profile-kam-name" *ngIf="profile.isRoadside && profile.responsibleKamRoadsideUserId">{{profile.responsibleKamRoadsideFirstName}} {{profile.responsibleKamRoadsideSurname}} (Roadside)</span>
      <span class="profile-kam-name" *ngIf="profile.isTravel && profile.responsibleKamTravelUserId">{{profile.responsibleKamTravelFirstName}} {{profile.responsibleKamTravelSurname}} (Travel)</span>
      <div class="title">KAM</div>
    </div>
    <div class="add-child">
      <div *ngIf="!profile.groupProfileId" (mouseenter)="clickActionMenu(+profile.id)" (mouseleave)="hideActionMenu()">
        <button class="action-button">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </button>
        <div class="action-menu" *ngIf="showActionMenu && actionMenuIndex === profile.id">
          <div class="action-menu-item" (click)="addNewGroupMember(profile.id)">
            Add new group member
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-brand-new-profile #brandNewProfileComponent [customerListView]="customerListView" (refreshBrands)="refreshProfilesEvent($event)">
</app-brand-new-profile>
