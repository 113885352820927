export enum ServiceItemMenuType {
  SERVICE_ITEM_CODE = 'serviceItemCode',
  SERVICE_ITEM_NAME = 'serviceItemName',
  SERVICE_TYPE = 'serviceType',
  UI_SORT_INDEX = 'uiSortIndex',
  VAT = 'vat',
  DESCRIPTION = 'description',
  ACTIVATE_ON = 'activateOn',
  DEACTIVATION_ON = 'deactivateOn',
  RECOVERY = 'recovery'
}
