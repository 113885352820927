<ng-template #content let-modal>
  <div class="modal-container">
    <div class="new-common-customer-modal-header">
      <div class="new-common-customer-title">Add new Common Customer</div>
      <div class="new-common-customer-close"><img (click)="close()" src="assets/icons/Close.svg" alt="Close"></div>
    </div>

    <div class="new-common-customer-modal-body">
      <app-edit-common-customer [commonCustomer]="commonCustomer" (changes)="handleChange()"></app-edit-common-customer>
      <div class="new-common-customer-modal-footer">
        <button class="primary-button" (click)="addNewCommonCustomer()"
                [ngClass]="!isCommonCustomerValid && 'button-disabled'" [disabled]="!isCommonCustomerValid">Add
        </button>
      </div>
    </div>
  </div>
</ng-template>
