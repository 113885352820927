import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-chip-input',
  templateUrl: './chip-input.component.html',
  styleUrls: ['./chip-input.component.scss']
})
export class ChipInputComponent implements OnInit {
  @Input() chips: string[] = [];
  @Input() placeholder: string = '';
  @Input() allowDuplicates: boolean = false;
  @Input() validatorFn: (chip: string) => boolean = () => true;
  @Output() chipsChange = new EventEmitter<string[]>();

  inputText: string = '';
  validatedChips: { value: string, isValid: boolean }[] = [];

  ngOnInit(): void {
    this.validateChips();
    if (this.chips && this.chips.length > 0) {
      this.chipsChange.emit(this.chips);
    }
  }

  validateChips(): void {
    this.validatedChips = this.chips.map(chip => ({
      value: chip,
      isValid: this.validatorFn(chip)
    }));
  }

  addChip(event: KeyboardEvent): void {
    if (event.key !== 'Enter') {
      return;
    }
    this.addChipHelper();
  }

  addChipOnBlur(): void {
    this.addChipHelper();
  }

  addChipHelper(){
    if (this.inputText.trim() !== '') {
      const chipValue = this.inputText.trim();
      if (this.allowDuplicates || !this.chips.includes(chipValue)) {
        this.chips.push(chipValue);
        this.validateChips();
        this.chipsChange.emit(this.chips);
      }
      this.inputText = '';
    }
  }

  removeChip(index: number): void {
    this.chips.splice(index, 1);
    this.validateChips();
    this.chipsChange.emit(this.chips);
  }
}
