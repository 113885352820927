import {Component, OnInit, ViewChild} from '@angular/core';
import {SlaRule} from "@onepoint/shared/models/sla-rule";
import {AddSlaRuleComponent} from "./add-sla-rule/add-sla-rule.component";
import {SlaRuleService} from "@onepoint/core/services/sla-rule.service";

@Component({
  selector: 'app-sla-rule',
  templateUrl: './sla-rule.component.html',
  styleUrls: ['./sla-rule.component.scss']
})
export class SlaRuleComponent implements OnInit {
  @ViewChild('modal') modal: AddSlaRuleComponent;
  slaRules: SlaRule[];

  constructor( private slaRuleService : SlaRuleService ) {
  }

  ngOnInit() {
    this.slaRuleService.getSlaRules().subscribe(result =>{
      this.slaRules = result;
    });
  }

  reloadSlaRules(){
    this.slaRuleService.getSlaRules().subscribe(result =>{
      this.slaRules = result;
    });
  }

  addNewSlaRule(){
    this.modal.openNew();
  }

  showExistingSlaRule(ruleId:number){
    this.modal.openExisting(this.slaRules.find(rule => rule.id ===ruleId));
  }
}
