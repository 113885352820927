<div class="row kam-container" *ngIf="kam">
  <div class="col-4">
    <div class="kam-image" [ngStyle]="{ 'background-image': 'url(' + imageUrl + ')' }"></div>
  </div>
  <div class="col-8">
    <div class="title">
      KAM
    </div>
    <div class="content">{{ kam.getFullName() }}</div>
    <div class="content kam-email">{{ kam.email }}</div>
    <div class="content kam-phone-number">{{ kam.phoneNumber }}</div>
    <div *ngIf="areas" class="title">
      Business area
    </div>
    <div class="content" *ngFor="let area of areas">{{ area }}</div>
  </div>
</div>
