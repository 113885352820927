<div class="page-container">
  <div class="page-header">
    <div class="row">
      <div class="col-2">
        <div class="count-container">
          <span>Logo</span>
          <span>{{getNumberOfLogos()}}</span>
        </div>
        <div class="count-container">
          <span>Signature</span>
          <span>{{getNumberOfSignatures()}}</span>
        </div>
        <div class="count-container">
          <span>Email/Fax</span>
          <span>{{getNumberOfEmailsAndFaxes()}}</span>
        </div>
      </div>
      <div class="col-2">
        <div class="count-container">
          <span>SMS</span>
          <span>{{getNumberOfSms()}}</span>
        </div>
        <div class="count-container">
          <span>Document</span>
          <span>{{getNumberOfDocuments()}}</span>
        </div>
      </div>
      <div class="col-4"></div>
      <div class="col-4">
        <button class="primary-button refresh-button" [ngClass]="{'error-button': isTemplatesRefreshing === getTemplateRefreshingStateEnum().ERROR}" [disabled]="isTemplatesRefreshing === getTemplateRefreshingStateEnum().IN_PROGRESS" (click)="refresh()">
            <span *ngIf="isTemplatesRefreshing  === getTemplateRefreshingStateEnum().IN_PROGRESS" >Refreshing...</span>
            <span *ngIf="isTemplatesRefreshing  === getTemplateRefreshingStateEnum().DONE" >Refresh</span>
            <span *ngIf="isTemplatesRefreshing  === getTemplateRefreshingStateEnum().ERROR">Error in last refresh</span>
            <img *ngIf="isTemplatesRefreshing  === getTemplateRefreshingStateEnum().IN_PROGRESS" class="ms-2" src="./assets/icons/waitForSearchresult.gif" alt="Loading">
          </button>
      </div>
    </div>
  </div>
  <div class="page-content-container">
    <app-document-templates-table #templateTable></app-document-templates-table>
  </div>
</div>
