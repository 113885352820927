<div class="common-content-container">
  <div class="common-content-container-title">Brand Settings</div>
  <div class="common-content-container-body">
    <div class="input-name">Brand name<span class="required">*</span></div>
    <div class="brand-name-input">
      <app-input (valueChange)="brandNameChanges($event)" [(value)]="brand.name"></app-input>
    </div>
    <div class="input-name">Logo</div>
    <div>
      <app-upload-brand-icon
        [(iconBlob)]="iconBlob"
        uploadBtnLabel="Change"
        [showInvalidIconMsg]="showInvalidIconMsg"
      ></app-upload-brand-icon>
    </div>
    <div class="input-name">Responsible KAM<span class="required">*</span></div>
    <div class="responsible-kam-container">
      <app-kam-drop-down
        [(selectedKamId)]="brand.keyAccountManagerUserId"
        (selectedKamChange)="this.brand.updateKamInfo($event); formChanged()"
      >
      </app-kam-drop-down>
    </div>
    <div class="input-name">Internal remarks</div>
    <div class="brand-remarks-input">
      <textarea name="remarks" rows="4" [(ngModel)]="brand.remarks" (change)="formChanged()" [maxLength]="1000"></textarea>
    </div>
  </div>
  <div class="update-brand">
    <button class="primary-button" (click)="updateBrand()" [ngClass]="{ 'button-disabled': !isBrandValid }" [disabled]="!isBrandValid">
      Save changes
    </button>
  </div>
</div>

<div class="common-delete-container justify-content-between">
  <div class="text my-auto">
    <div class="black-text">
      Delete Brand
    </div>
    <div class="gray-text" *ngIf="!brand.deletable">
      This Brand can not deleted,because it contains one or more Profiles. To delete this Brand, please delete all Profiles within it first.
    </div>
  </div>
  <button class="primary-button delete-button my-auto" (click)="deleteBrand()" [ngClass]="!brand.deletable && 'button-disabled'">
    Delete Brand
  </button>
</div>
