import { PolicyLookupMethodExtension } from '../policy-lookup-method-extension';
import { RegisterServerLookupTypeEnum } from '../../../../../enums/policy/register-server-lookup-type.enum';
import { PolicyLookupMethodTypeEnum } from '../../../../../enums/policy/policy-lookup-method-type.enum';


export class RegisterServerPolicyLookupMethodExtension extends PolicyLookupMethodExtension {
  id: number;
  type: string;
  code: string;

  public constructor(init?: Partial<RegisterServerPolicyLookupMethodExtension>) {
    super();
    this.type = RegisterServerLookupTypeEnum.NONE;
    Object.assign(this, init);
  }

  public isValid(): boolean {
    return !!this.type && this.isCodeValid();
  }

  private isCodeValid() {
    return RegisterServerLookupTypeEnum.NONE === RegisterServerLookupTypeEnum[this.type] || !!this.code;
  }

  public clone() {
    const clone = new RegisterServerPolicyLookupMethodExtension();
    Object.assign(clone, this);
    return clone;
  }
}
