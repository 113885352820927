import {Component, Input, OnInit} from '@angular/core';
import {ProfileService} from '@onepoint/core/services/profile.service';
import {ProfileApplication} from '@onepoint/shared/models/profile-application';
import {ApplicationTypeEnum} from '@onepoint/shared/models/enums';
import { Dictionary } from 'app/shared/models/dictionary';

@Component({
  selector: 'app-profile-applications',
  templateUrl: './profile-applications.component.html',
  styleUrls: ['./profile-applications.component.scss']
})
export class ProfileApplicationsComponent implements OnInit {

  profileApplications: ProfileApplication[] = [];
  oldProfileApplicationsJson: string;
  hasChanged: boolean;
  legacyCodes: Dictionary[] = [
      new Dictionary('ABS', 'ABS - Alm. Brand'),
      new Dictionary('GFS', 'GFS - GF-FORSIKRING'),
      new Dictionary('NE', 'NE - IF Europæiske Norge'),
      new Dictionary('JB', 'JB - Jernbanernes Forsikring Norway'),
      new Dictionary('DAP', 'DAP - Aros Forsikring'),
      new Dictionary('SPA', 'SPA - Sparebank 1 Skadeforsikring'),
      new Dictionary('TER', 'TER - Eika Gruppen')
    ];
  isGroupProfile: boolean;

  @Input() set profileId(profileId: string) {
    this.profileService.isGroupProfile(profileId).subscribe(result => {
      this.isGroupProfile = result;
    });
    this.profileService.getApplicationsByProfile(profileId).subscribe(
      next => {
        this.profileApplications = next;
        this.oldProfileApplicationsJson = JSON.stringify(this.profileApplications);
      }
    );
  }
  constructor(private profileService: ProfileService) { }

  get type() {
    return ApplicationTypeEnum;
  }

  editAsCaseEventsLegacyCode(applicationType: ApplicationTypeEnum): boolean {
    return ApplicationTypeEnum[applicationType].valueOf() === ApplicationTypeEnum.CASE_EVENTS.valueOf();
  }

  isValid(): boolean {
      if (!this.isCaseEventsValid()) {
          return false;
      }
      return true;
  }

  isCaseEventsValid(): boolean {
      const caseEventApplicationSetting = this.profileApplications.filter(pa => ApplicationTypeEnum[pa.type].valueOf() === ApplicationTypeEnum.CASE_EVENTS.valueOf());
      if (caseEventApplicationSetting[0] && caseEventApplicationSetting[0].included && caseEventApplicationSetting[0].stillLegacy && (caseEventApplicationSetting[0].legacyCode === null)) {
          return false;
      }
      return true;
  }

  ngOnInit(): void {
  }

  checkBoxIncludedChange(profileApplication: ProfileApplication) {
    if (profileApplication && !profileApplication.included) {
        profileApplication.stillLegacy = false;
        profileApplication.legacyCode = null;
    }
    this.hasChanged = JSON.stringify(this.profileApplications) !== this.oldProfileApplicationsJson;
  }

  checkBoxLegacyChange(profileApplication: ProfileApplication) {
    if (!(profileApplication.stillLegacy)) {
        profileApplication.legacyCode = null;
    }
    this.hasChanged = JSON.stringify(this.profileApplications) !== this.oldProfileApplicationsJson;
  }

  validationMessage(): string {
      if (!this.isCaseEventsValid()) {
          return 'Case events legacy codes must be selected before save';
      }
      return 'Save changes';

  }

  validDateChangeValidFrom(profileApplication: ProfileApplication) {
    if (profileApplication.validFrom) {
      profileApplication.validFrom.setHours(12, 0, 0, 0);
    }
    this.hasChanged = JSON.stringify(this.profileApplications) !== this.oldProfileApplicationsJson;
  }

  validDateChangeValidTo(profileApplication: ProfileApplication) {
    if (profileApplication.validTo) {
      profileApplication.validTo.setHours(12, 0, 0, 0);
    }
    this.hasChanged = JSON.stringify(this.profileApplications) !== this.oldProfileApplicationsJson;
  }

  saveChanges() {
    this.profileService.updateApplication(this.profileApplications).subscribe(result => {
      this.hasChanged = false;
      this.profileApplications = result;
      this.oldProfileApplicationsJson = JSON.stringify(this.profileApplications);
    });
  }
}
