export enum DiagnosesMenuType {
  BUSINESS_KEY = 'businessKey',
  NAME = 'name',
  ICD10_CODE = 'icd10Code',
  ICD10_NAME = 'idc10Name',
  UI_SORT_INDEX = 'uiSortIndex',
  ACTIVATE_ON = 'activateOn',
  DEACTIVATION_ON = 'deactivateOn',
  AVAILABLE_TRANSLATION = 'availableTranslation'
}
