<div class="page-container">
  <div class="page-header justify-content-between no-gutters">
    <div class="col-4 my-auto counts-container">
      <div class="count-container">
        <span>Sos services</span> <span>{{total}}</span>
      </div>
    </div>
    <div class="search-container my-auto">
      <app-input withImage="true" [(value)]="searchText" (valueChange)="textValueChange()"></app-input>
    </div>
    <button class="primary-button my-auto" (click)="addNew()">
      Add SOS services
    </button>
  </div>

  <app-new-sos-services #newSosServicesComponent>
  </app-new-sos-services>

  <div class="sos-services-container">
    <div class="sos-services-table">
      <app-sos-services-table #sosServicesTable></app-sos-services-table>
    </div>
  </div>
</div>
