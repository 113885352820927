<ng-template #content let-modal>
  <div class="modal-container">
    <div class="debtor-modal-header">
      <div class="debtor-title" *ngIf="debtor.id == null">Add New Debtor</div>
      <div class="debtor-title" *ngIf="debtor.id != null">Edit Debtor</div>
      <div class="debtor-close"><img (click)="close()" src="assets/icons/Close.svg" alt="Close" /></div>
    </div>

    <div class="debtor-modal-body">
      <div class="row">
        <div class="col">
          <div class="input-title-name">Debtor Name<span class="star">*</span></div>
          <div class="debtor-input">
            <app-input [(value)]="debtor.name" (valueChange)="calculateAllowSubmit()"></app-input>
          </div>
          <div class="input-title-name">Debtor number<span class="star">*</span></div>
          <div class="debtor-input">
            <app-input [pattern]="'[0-9]*'" [type]="'number'" [(value)]="debtor.number" (valueChange)="calculateAllowSubmit()"></app-input>
          </div>
          <div class="input-title-name">ERP Client<span class="star">*</span></div>
          <div class="debtor-input">
            <app-drop-down [items]="erpClients" [(selectedItemId)]="debtor.erpClient.id" (selectedItemIdChange)="calculateAllowSubmit()">
            </app-drop-down>
          </div>
          <div class="input-title-name">Billing Currency<span class="star">*</span></div>
          <div class="debtor-input">
            <app-drop-down
              [items]="billingCurrencies"
              [(selectedItemId)]="debtor.billingCurrency.id"
              (selectedItemIdChange)="calculateAllowSubmit()"
            >
            </app-drop-down>
          </div>
          <div class="input-title-name">Internal Remarks</div>
          <div class="debtor-input">
            <textarea name="internalRemarks" rows="4" [(ngModel)]="debtor.internalRemarks" (change)="calculateAllowSubmit()" [maxLength]="1000"></textarea>
          </div>
        </div>
        <div class="col">
          <div class="input-title-name">Payment terms<span class="star">*</span></div>
          <div class="debtor-input">
            <app-drop-down
              [items]="paymentTerms"
              [(selectedItemId)]="debtor.paymentTerm.id"
              (selectedItemIdChange)="calculateAllowSubmit()"
            >
            </app-drop-down>
          </div>
          <div class="input-title-name">Invoice frequency<span class="star">*</span></div>
          <div class="debtor-input">
            <app-drop-down
              [items]="invoiceFrequencies"
              [(selectedItemId)]="debtor.invoiceFrequency.id"
              (selectedItemIdChange)="calculateAllowSubmit()"
            >
            </app-drop-down>
          </div>
          <div class="input-title-name">Max. number of invoices per day<span class="star">*</span></div>
          <div class="debtor-input">
            <app-input [pattern]="'[0-9]*'" [(value)]="debtor.maxInvoicesPerDay" (valueChange)="calculateAllowSubmit()"></app-input>
          </div>
          <div class="input-title-name">Claims cost partial invoicing limit<span class="star">*</span></div>
          <div class="debtor-input">
            <app-input
              [pattern]="'[0-9]*'"
              [(value)]="debtor.claimsCostPartialInvoicingLimit"
              (valueChange)="calculateAllowSubmit()"
            ></app-input>
          </div>
          <div class="input-title-name">SOS Services on separate invoice (Not Recovery)</div>
          <div class="debtor-input">
            <input
              class="businessAreaCheckbox"
              type="checkbox"
              (change)="calculateAllowSubmit()"
              [(ngModel)]="debtor.sosServicesSeparateInvoice"
            />
          </div>
          <div class="input-title-name">Customer reference required for invoicing</div>
          <div class="debtor-input">
            <input
              class="businessAreaCheckbox"
              type="checkbox"
              (change)="calculateAllowSubmit()"
              [(ngModel)]="debtor.customerReferenceRequired"
            />
          </div>
          <div class="input-title-name">Invoicing suspended</div>
          <div class="debtor-input">
            <input class="businessAreaCheckbox" type="checkbox" (change)="calculateAllowSubmit()" [(ngModel)]="debtor.invoicingSuspended" />
          </div>
        </div>
      </div>
    </div>
    <div class="masterlist-modal-footer  d-flex justify-content-between">
      <div class="debtor-title" *ngIf="debtor.id == null">
        <button class="primary-button" (click)="addNewDebtor()" [ngClass]="!allowSubmit && 'button-disabled'" [disabled]="!allowSubmit">
          Add
        </button>
      </div>
      <div class="debtor-title" *ngIf="debtor.id != null">
        <button class="primary-button" (click)="updateDebtor()" [ngClass]="!allowSubmit && 'button-disabled'" [disabled]="!allowSubmit">
          Save changes
        </button>
      </div>
      <div *ngIf="errorMessage" class="error-message" [class.error-message-wide]="!debtor.id">{{ errorMessage }}</div>
      <app-last-changed class="float-end last-changed" [masterListItem]="debtor"></app-last-changed>
    </div>
  </div>
</ng-template>
