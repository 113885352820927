import { KamAdapter } from './../../../../shared/models/kam';
import { UpdateService } from 'app/core/services/update.service';
import { FilteringSortingMenuType } from './filtering-sorting-menu/filtering-sorting-menu-type';
import { CustomerTypeEnum } from './../../../../shared/enums/customerType.enum';
import { CustomersService } from './../../../../core/services/customers.service';
import { ApplicationRef, Component, OnInit } from '@angular/core';
import { CustomerListView } from 'app/shared/models/customer-list-view';
import { Router } from '@angular/router';
import { CustomersTableRow } from './customers-table-row';
import { CustomersSummaryUpdateService } from '../customers-summary/customers-summary-update.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-customers-table',
  templateUrl: './customers-table.component.html',
  styleUrls: ['./customers-table.component.scss']
})
export class CustomersTableComponent implements OnInit {
  rows: CustomersTableRow[];
  filteredRows: CustomersTableRow[];
  showMenu: boolean[] = [];
  error: any;
  
  constructor(
    private customersService: CustomersService,
    private router: Router,
    private applicationRef: ApplicationRef,
    private customerUpdateService: CustomersSummaryUpdateService,
    private updateService: UpdateService,
    private kamAdapter: KamAdapter
  ) {}

  ngOnInit() {
    this.loadTree();
    this.updateService.updateCommonCustomerListWhenCommonCustomerIsDeleted.subscribe( value => {
      if (value === true) {
          this.loadTree();
      }
    });
  }

  private loadTree() {
    this.error = '';
    forkJoin([this.customersService.getAllCommonCustomerListView(),
             this.customersService.getAllBrandListView(),
             this.customersService.getAllProfileListView()]).subscribe(
      ([customers, brands, profiles]) => {
        this.rows = customers
        .concat(brands)
        .concat(profiles)
        .map(item => new CustomersTableRow(this.kamAdapter, item));
      this.normalizeTable(this.rows);
      this.filterTable();
      },
      error => {
        this.error = error;
        console.log(error);
      }
    )
  }

  public searchTextValueChange(criteria: string) {
    if (criteria === null || criteria === '') {
      this.ngOnInit();
      this.collapseAll();
      return;
    }

    this.error = '';
    forkJoin([this.customersService.getAllCommonCustomerFromSearchListView(criteria),
      this.customersService.getAllBrandFromSearchListView(criteria),
      this.customersService.getProfileFromSearchListView(criteria)]).subscribe(
        ([customers, brands, profiles]) => {
        this.rows = customers
        .concat(brands)
        .concat(profiles)
        .map(item => new CustomersTableRow(this.kamAdapter, item));
          this.normalizeTable(this.rows);
          this.filterTable();
          this.expandAll();
        },
        error => {
          this.error = error;
          console.log(error);
        }
    )
  }

  get CustomerTypeEnum() {
    return CustomerTypeEnum;
  }

  get FilteringSortingMenuType() {
    return FilteringSortingMenuType;
  }

  filterTable() {
    this.filteredRows = this.rows;
    this.customerUpdateService.change(this.filteredRows);
  }

  clearAllFiltering() {
    this.filterTable();
    this.applicationRef.tick();
  }

  navigate(customerListView: CustomerListView) {
    if (customerListView.type === CustomerTypeEnum.commonCustomer) {
      this.router.navigate(['/customers/common-customer', customerListView.id]);
    }
    if (customerListView.type === CustomerTypeEnum.brand) {
      this.router.navigate(['/customers/brand', customerListView.id]);
    }
    if (customerListView.type === CustomerTypeEnum.profile) {
      this.router.navigate(['/customers/profile', customerListView.id]);
    }
  }

  expandAll() {
    this.filteredRows
      .filter(item => !item.isCommonCustomer())
      .forEach(a => {
        a.expanded = true;
        a.visible = true;
      });
  }

  collapseAll() {
    this.filteredRows
      .filter(item => !item.isCommonCustomer())
      .forEach(a => {
        a.expanded = false;
        a.visible = a.type === CustomerTypeEnum.commonCustomer;
      });
  }

  hideCollapseIcon(row: CustomersTableRow): boolean {
    return !row.expanded || row.isProfile();
  }

  hideExpandIcon(row: CustomersTableRow): boolean {
    return row.expanded || row.isProfile();
  }

  expandCollapse(row: CustomersTableRow, event: Event) {
    event.stopPropagation();
    row.expanded = !row.expanded;
    if (row.isCommonCustomer() || row.isBrand()) {
      this.expandCollapseChildren(row);
    }
    this.applicationRef.tick();
  }

  private expandCollapseChildren(row: CustomersTableRow) {
    this.filteredRows.filter(item => item.parentId === row.id)
      .forEach(item => {
        item.visible = row.expanded;
        if (!row.expanded) {
          item.expanded = row.expanded;
        }
        if (item.isBrand()) {
          this.expandCollapseChildren(item);
        }
      });
  }

  normalizeTable(tempTable: CustomersTableRow[]) {
    const commonCustomersList = tempTable.filter(row => row.isCommonCustomer());
    const brandsList = tempTable.filter(row => row.isBrand());
    const profilesList = tempTable.filter(row => row.isProfile());
    commonCustomersList.forEach(commonCustomer => {
      brandsList.map(brand => {
        if (brand.parentId === commonCustomer.id) {
          tempTable.splice(tempTable.indexOf(brand), 1);
          tempTable.splice(tempTable.indexOf(commonCustomer) + 1, 0, brand);
        }
      });
    });
    brandsList.forEach(brand => {
      profilesList.map(profile => {
        if (profile.parentId === brand.id) {
          tempTable.splice(tempTable.indexOf(profile), 1);
          tempTable.splice(tempTable.indexOf(brand) + 1, 0, profile);
        }
      });
    });
  }

  finishedAndFilteringSorting() {
    this.normalizeTable(this.filteredRows);
    this.normalizeTable(this.filteredRows);
    // done twice to sort sub items
    this.showFilteringSortingMenu(FilteringSortingMenuType.empty);
    this.customerUpdateService.change(this.filteredRows);
  }

  showFilteringSortingMenu(filteringSortingMenuType: FilteringSortingMenuType) {
    for (let i = 0; i < this.showMenu.length; i++) {
      if (i !== filteringSortingMenuType) {
        this.showMenu[i] = false;
      }
    }
    this.showMenu[filteringSortingMenuType] = !this.showMenu[
      filteringSortingMenuType
    ];
  }
}
