import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CommonCustomer} from '../../../../../shared/models/common-customer';
import {CommonCustomerService} from '../../../../../core/services/common-customer.service';
import {Router} from '@angular/router';
import { RoutesName } from 'app/app-routes-name';
import {OnepointModalService, Size} from '../../../../../core/services/onepoint.modal.service';

@Component({
  selector: 'app-new-common-customer',
  templateUrl: './new-common-customer.component.html',
  styleUrls: ['./new-common-customer.component.scss']
})
export class NewCommonCustomerComponent implements OnInit {
  @ViewChild('content', { static: true }) template: TemplateRef<any>;
  commonCustomer: CommonCustomer;
  isCommonCustomerValid = false;

  constructor(
    private commonCustomerService: CommonCustomerService,
    private router: Router,
    private modalService: OnepointModalService
  ) {
    this.commonCustomer = new CommonCustomer();
  }

  ngOnInit() {
  }

  public open() {
    this.modalService.open(this.template, Size.NORMAL);
  }

  close() {
    this.modalService.dismissAll();
  }

  handleChange() {
    this.isCommonCustomerValid = Boolean(
      this.commonCustomer.name &&
      this.commonCustomer.keyAccountManagerUserId != null &&
      this.commonCustomer.groupSegment.id != null &&
      this.commonCustomer.travelSegment.id != null &&
      this.commonCustomer.roadsideSegment.id != null &&
      this.commonCustomer.healthSegment.id != null &&
      this.commonCustomer.sosOwner != null &&
      this.commonCustomer.subBusinessRelation.id != null);
  }

  addNewCommonCustomer() {
    this.commonCustomerService.saveCommonCustomer(this.commonCustomer).subscribe(
      result => {
        this.commonCustomer = result;
        this.close();
        this.router.navigate([RoutesName.customers + '/' + RoutesName.commonCustomers, this.commonCustomer.id]);
      }, error => {
        console.error(error);
      }
    );
  }
}
