import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';

export class TeamRelation {
  id: string;
  adtCode: string;
  businessArea: string;
  team: string;
  profileId: number;
  start: Date;
  end: Date;
  groupProfileTeamRelationId: string

  public constructor(init?: Partial<TeamRelation>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class TeamRelationAdapter implements Adapter<TeamRelation> {
  adapt(item: any): TeamRelation {
    return new TeamRelation({
      id: item.id,
      adtCode: item.adtCode,
      businessArea: item.businessArea,
      profileId: item.profileId,
      team: item.team,
      start: item.start,
      end: item.end,
      groupProfileTeamRelationId: item.groupProfileTeamRelationId
    });
  }
}
