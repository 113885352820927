import { Dictionary } from './dictionary';

export class DictionaryWithType extends Dictionary {
  type: string;

  public constructor(id: string, name: string, type: string) {
    super(id, name);

    this.type = type;
  }
}
