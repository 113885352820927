export enum CommitmentTypeMenuType {
  BUSINESS_KEY = "businessKey",
  NAME = "name",
  ACTIVATE_ON = 'activateOn',
  DEACTIVATION_ON = 'deactivateOn',
  UI_SORT_INDEX= 'uiSortIndex',
  SUPPLIER_INVOICE = 'supplierInvoice',
  REFUND = 'refund',
  GOP = 'gop',
  SOS_CLAIMS = 'sosClaims',
  HANDLING_AREA_COST_CONTROL = 'handlingAreaCostControl',
  HANDLING_AREA_CLAIMS = 'handlingAreaClaims',
  EXCLUDE_FROM_EUS = 'excludeFromEUS',
  RECOVERY = 'recovery'
}
