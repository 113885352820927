import {Injectable} from '@angular/core';
import {Adapter} from './../interfaces/adapter';
import {Sla, SlaAdapter} from "./sla";

export class SlaAgreement {
  id: number;
  deadlineTime: number;
  requirement: number;
  enabled: boolean;
  contractDescription: string
  sla: Sla;

  public constructor(init?: Partial<SlaAgreement>) {
    Object.assign(this, init);
  }
}


@Injectable({
  providedIn: 'root'
})
export class SlaAgreementAdapter implements Adapter<SlaAgreement> {
  adapt(item: any): SlaAgreement {
    const slaAdapter = new SlaAdapter();
    return new SlaAgreement({
      id: item.id,
      deadlineTime: item.deadlineTime,
      requirement: item.requirement,
      enabled: item.enabled,
      contractDescription: item.contractDescription,
      sla: slaAdapter.adapt(item.sla)
    });
  }
}
