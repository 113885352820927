import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterListService } from 'app/core/services/masterlist.service';
import { OnepointModalService, Size } from 'app/core/services/onepoint.modal.service';
import { MasterListOverviewModel } from 'app/shared/models/masterlist-overview-model';
import { MasterListUploadInfo } from 'app/shared/models/masterlist-upload-info';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import { MASTER_LISTS } from '../common/common';
import { MasterListUploadModalComponent } from '../upload/upload-modal/masterlist-upload-modal.component';
import { MasterListsOverviewMenuType } from './overview-menu/masterlist-overview-menu-type';

@Component({
  selector: 'app-masterlist-overview',
  templateUrl: './masterlist-overview.component.html',
  styleUrls: ['./masterlist-overview.component.scss'],
})
export class MasterListsOverviewComponent implements OnInit {
  @ViewChild('MasterListUploadModal')
  private masterListUploadModalTemplate: TemplateRef<any>;

  masterList: MasterListOverviewModel[] = MASTER_LISTS;

  masterListUploadInfo: MasterListUploadInfo;
  showMenu = new Map<MasterListsOverviewMenuType, boolean>();
  searchText: string;
  shownSection = 'overview';

  constructor(
    private masterListService: MasterListService,
    private onepointModalService: OnepointModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.masterListService
      .getUploadedTranslationsInfo(MasterListUploadModalComponent.APPLICATION_NAME)
      .subscribe(response => (this.masterListUploadInfo = response));
  }

  showFilteringSortingMenu(filteringSortingMenuType: MasterListsOverviewMenuType) {
    this.showMenu.forEach((value, key) => {
      if (key !== filteringSortingMenuType) {
        this.showMenu[key] = false;
      }
    });

    this.showMenu[filteringSortingMenuType] = !this.showMenu[filteringSortingMenuType];
  }

  navigate(item: MasterListOverviewModel): void {
    if (item.enabled) {
      this.router.navigate([item.id], { relativeTo: this.activatedRoute });
    }
  }

  searchTextChanged(): void {}

  upload() {
    this.onepointModalService.openWithCustomWindowClass(this.masterListUploadModalTemplate, Size.LARGE, 'master-list-modal-container');
  }

  get MasterListsOverviewMenuType() {
    return MasterListsOverviewMenuType;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
