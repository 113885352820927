import { CustomerListView } from 'app/shared/models/customer-list-view';
import { CustomerTypeEnum } from './../enums/customerType.enum';
import { Adapter } from './../interfaces/adapter';
import { Injectable } from '@angular/core';
import { Profile } from './profile';
import { KamService } from 'app/core/services/kam.service';
import { Kam } from './kam';

export class Brand {
  id: string;
  commonCustomerId: string;
  name: string;
  iconBlob: string;
  keyAccountManagerUserId: string;
  keyAccountManagerFirstName: string;
  keyAccountManagerSurname: string;
  keyAccountManagerEmail: string;
  keyAccountManagerPhone: string;
  profiles: Profile[];
  active: boolean;
  deletable: boolean;
  remarks: string;

  public constructor(init?: Partial<Brand>) {
    Object.assign(this, init);
  }

  updateKamInfo(kam: Kam) {
    this.keyAccountManagerUserId = kam.userId;
    this.keyAccountManagerFirstName = kam.firstName;
    this.keyAccountManagerSurname = kam.surname;
    this.keyAccountManagerEmail = kam.email;
    this.keyAccountManagerPhone = kam.phoneNumber;
  }
}

@Injectable({
  providedIn: 'root',
})
export class BrandAdapter implements Adapter<Brand> {
  constructor(private kamService: KamService) {}
  adapt(item: any): Brand {
    const brand = new Brand({
      id: item.id,
      commonCustomerId: item.commonCustomerId,
      name: item.name,
      iconBlob: item.iconBlob,
      profiles: item.profiles,
      active: item.active,
      deletable: item.deletable,
      keyAccountManagerUserId: item.keyAccountManagerUserId,
      keyAccountManagerFirstName: item.keyAccountManagerFirstName,
      keyAccountManagerSurname: item.keyAccountManagerSurname,
      keyAccountManagerEmail: item.keyAccountManagerEmail,
      keyAccountManagerPhone: item.keyAccountManagerPhone,
      remarks: item.remarks,
    });
    return brand;
  }

  adaptToCommonCustomerListView(item: any): CustomerListView {
    const customerListView = new CustomerListView({
      id: item.id,
      name: item.name,
      type: CustomerTypeEnum.brand,
      market: item.markets,
      groupSegment: item.groupSegment,
      healthSegment: item.healthSegment,
      travelSegment: item.travelSegment,
      roadsideSegment: item.roadsideSegment,
      subBusinessRelation: item.subBusinessRelation,
      businessAreaCount:
        (item.isTravel ? 1 : 0) +
        (item.isRoadside ? 1 : 0) +
        (item.isHealth ? 1 : 0),
      isTravel: item.travel,
      isRoadside: item.roadside,
      isHealth: item.health,
      nextNegotationDate:
        item.nextNegotationDate === ''
          ? null
          : new Date(item.nextNegotationDate),
      iconBlob: item.iconBlob,
      numberOfProfiles: item.profiles ? item.profiles.length : 0,
      sosOwner: item.sosOwner,
      parentId: item.commonCustomerId,
      integrationType: item.integrationType,
      children: item.profiles,
      active: item.active,
      keyAccountManagerUserId: item.keyAccountManagerUserId,
      keyAccountManagerSurname: item.keyAccountManagerSurname,
      keyAccountManagerFirstName: item.keyAccountManagerFirstName,
      keyAccountManagerEmail: item.keyAccountManagerEmail,
      keyAccountManagerPhone: item.keyAccountManagerPhone,
      deletable: item.deletable,
      remarks: item.remarks,
    });
    return customerListView;
  }
}
