<div class="profile-teams-container">
  <div class="profile-teams-container-body justify-content-between">
    <div class="business-area my-auto">
      <app-drop-down
        [items]="businessAreas"
        [name]="'Business Area'"
        [(selectedItemId)]="currentBusinessArea"
        (saveModel)="getTeamRelationsFromBusinessArea(currentBusinessArea)"
        [disabled]="newPeriodExists || !!customerListViewOriginal.groupProfileId"
      ></app-drop-down>
    </div>
    <button
      class="primary-button my-auto"
      (click)="addNewPeriod()"
      [disabled]="newPeriodExists || !endDateIsSet || isFieldEdited || !!customerListViewOriginal.groupProfileId"
      [ngClass]="(newPeriodExists || !endDateIsSet || isFieldEdited || !!customerListViewOriginal.groupProfileId) && 'button-disabled'"
    >
      Add new period
    </button>
  </div>

  <table class="primary-table">
    <thead>
      <tr>
        <td
          class="filter-column"
          sortColumn
          [sortKey]="'team'"
          [data]="teamRelations"
        >
          Team
        </td>
        <td
          class="filter-column"
          sortColumn
          [sortKey]="'start'"
          [data]="teamRelations"
        >
          Start date
        </td>
        <td
          class="filter-column"
          sortColumn
          [sortKey]="'end'"
          [data]="teamRelations"
        >
          End date
        </td>
        <td></td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let teamRelation of teamRelations; let i = index">
        <td class="td-fixed-width" [ngClass]="{ even: i % 2 == 1 }">
          <app-drop-down
            [items]="teams"
            [placeholder]="'Choose team'"
            [(selectedItemId)]="teamRelation.adtCode"
            (saveModel)="validate(i)"
            [disabled]="!!customerListViewOriginal.groupProfileId"
          ></app-drop-down>
        </td>
        <td class="td-fixed-width" [ngClass]="{ even: i % 2 == 1 }">
          <app-input-calendar
            [withImage]="true"
            *ngIf="i == teamRelations.length - 1"
            [minDate]="default"
            [maxDate]="teamRelation.end === null ? '' : teamRelation.end"
            [(dateTimeRange)]="teamRelation.start"
            [selectMode]="'single'"
            (saveModel)="validate(i)"
            [disabled]="!!customerListViewOriginal.groupProfileId"
          >
          </app-input-calendar>
          <app-input-calendar
            [withImage]="true"
            *ngIf="i < teamRelations.length - 1"
            [minDate]="teamRelations[i + 1].end"
            [maxDate]="teamRelation.end === null ? '' : teamRelation.end"
            [(dateTimeRange)]="teamRelation.start"
            [selectMode]="'single'"
            (saveModel)="validate(i)"
            [disabled]="!!customerListViewOriginal.groupProfileId"
          >
          </app-input-calendar>
        </td>
        <td class="td-fixed-width" [ngClass]="{ even: i % 2 == 1 }">
          <app-input-calendar
            [withImage]="true"
            [minDate]="teamRelation.start"
            [(dateTimeRange)]="teamRelation.end"
            [selectMode]="'single'"
            (saveModel)="validate(i)"
            [disabled]="!!customerListViewOriginal.groupProfileId"
          >
          </app-input-calendar>
        </td>
        <td [ngClass]="{ even: i % 2 == 1 }">
          <button
            class="btn"
            *ngIf="teamRelation.id === null"
            (click)="removeNewPeriod()"
            [disabled]="!!customerListViewOriginal.groupProfileId"
          >
            <img alt="Delete icon" src="assets/icons/garbage-red.svg" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="footer">
    <button
      class="primary-button my-auto"
      (click)="submit()"
      [ngClass]="!validated && 'button-disabled'"
      [disabled]="!validated"
    >
      Save changes
    </button>
  </div>
</div>
