import { BusinessAreaEnum } from '@onepoint/shared/models/enums';
import { ContractService } from '@onepoint/core/services/contract.service';
import { Contract } from '@onepoint/shared/models/contract';
import { Dictionary } from 'app/shared/models/dictionary';
import { DictionaryService } from '@onepoint/core/services/dictionary.service';
import { CommonCustomer } from '@onepoint/shared/models/common-customer';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef
} from '@angular/core';
import {PriceAgreement, PriceAgreementCategory} from '@onepoint/shared/models/price-agreement';
import { formatDate } from '@angular/common';
import { OnepointModalService, Size } from '@onepoint/core/services/onepoint.modal.service';
import { AppliedProfileService } from '@onepoint/core/services/applied-profile.service';
import * as _ from 'lodash';
import {SettingsService} from "@onepoint/core/services/settings.service";
import {IndexService} from "@onepoint/core/services/index.service";
import {PriceAgreementService} from "@onepoint/core/services/price-agreement.service";
import {CustomerSlaAgreement} from "@onepoint/shared/models/customer-sla-agreement";

@Component({
  selector: 'app-common-customer-contracts',
  templateUrl: './common-customer-contracts.component.html',
  styleUrls: ['./common-customer-contracts.component.scss']
})
export class CommonCustomerContractsComponent implements OnInit {
  @ViewChild('content') template: TemplateRef<any>;
  @Input()
  get commonCustomer(): CommonCustomer {
    return this._commonCustomer;
  }
  set commonCustomer(commonCustomer: CommonCustomer) {
    this._commonCustomer = commonCustomer;
    if (this.commonCustomer) {
      this.contractService
        .getContractsForCommonCustomer(commonCustomer.id)
        .subscribe(
          result => {
            this.contracts = result.sort((a, b) =>
              a.commencementDate > b.commencementDate ? 1 : -1
            );
            this.filterContracts();
          },
          error => console.log(error)
        );
    }
  }

  private _commonCustomer: CommonCustomer;
  contracts: Contract[];
  filteredContracts: Contract[];
  currentContract: Contract;
  appliedProfileLowerBound: Date;
  appliedProfileUpperBound: Date;
  fetchingBounds = false;
  savedContract: Contract;
  businessAreas: Dictionary[];
  addingMode: boolean;
  filterOptions: Dictionary[] = [];
  selectedFilter: FilterEnum;
  priceAgreements: Map<string, PriceAgreement[]> = new Map();
  customerSlaAgreements: Map<string, CustomerSlaAgreement[]> = new Map();

  constructor(
    private dictionaryService: DictionaryService,
    private modalService: OnepointModalService,
    private appliedProfileService: AppliedProfileService,
    private contractService: ContractService,
    private settingsService: SettingsService,
    private indexService: IndexService,
    private priceAgreementService: PriceAgreementService
  ) {
    this.currentContract = new Contract();
    this.currentContract.businessArea = BusinessAreaEnum.Empty;
    this.filterOptions.push(
      new Dictionary(FilterEnum.active, FilterEnum.active)
    );
    this.filterOptions.push(
      new Dictionary(FilterEnum.expired, FilterEnum.expired)
    );
    this.selectedFilter = FilterEnum.active;
  }

  get BusinessAreaEnum() {
    return BusinessAreaEnum;
  }

  get isAddingNewContractAllowed() {
    return (
      this.currentContract.businessArea &&
      this.contractService.isAddingNewContractAllowed(
        this.contracts,
        this.currentContract.businessArea
      )
    );
  }


  filterContracts() {
    const todayShort = new Date(formatDate(new Date(), 'yyyy/MM/dd', 'en'));
    if (this.selectedFilter === FilterEnum.active) {
      this.filteredContracts = this.contracts.filter(
        a =>
          a.terminationDate == null ||
          (a.terminationDate && a.terminationDate >= todayShort)
      );
    } else {
      this.filteredContracts = this.contracts.filter(
        a => a.terminationDate && a.terminationDate < todayShort
      );
    }
  }

  ngOnInit() {
    this.businessAreas = this.dictionaryService.getBusinessAreas();
  }
  get contractFormValid(): boolean {
    return (
      this.currentContract.linkToDatabase != null &&
      this.currentContract.linkToDatabase !== '' &&
      this.currentContract.commencementDate != null &&
      this.currentContract.title != null &&
      this.currentContract.title !== '' &&
      this.currentContract.businessArea != null &&
      !this.currentContract.overlaps(this.contracts) &&
      !this.fetchingBounds &&
      (this.appliedProfileLowerBound == null || this.appliedProfileLowerBound >= this.currentContract.commencementDate) &&
      (this.appliedProfileUpperBound == null || this.currentContract.terminationDate == null || this.appliedProfileUpperBound <= this.currentContract.terminationDate)
    );
  }

  get contractChanged(): boolean {
    return JSON.stringify(this.currentContract) !== JSON.stringify(this.savedContract);
  }

  get saveButtonEnabled(): boolean {
    return this.contractFormValid && this.contractChanged;
  }

  checkCommencementDate(commencementDate: any) {
    if (!commencementDate || commencementDate > new Date(this.currentContract.nextNegotiationDate)) {
      this.currentContract.commencementDate = null;
    } else {
      this.currentContract.commencementDate = commencementDate;
    }
  }

  checkTerminationDate(terminationDate: any) {
    if (!terminationDate) {
      this.currentContract.terminationDate = null;
    }
    if (terminationDate < new Date(this.currentContract.commencementDate)) {
      this.currentContract.terminationDate = null;
    } else {
      this.currentContract.terminationDate = terminationDate;
    }
  }

  checkNextNegotiationDate(nextNegotiationDate: any) {
    if (!nextNegotiationDate) {
      this.currentContract.nextNegotiationDate = null;
    }
    if (nextNegotiationDate < new Date(this.currentContract.commencementDate)) {
      this.currentContract.nextNegotiationDate = null;
    } else {
      this.currentContract.nextNegotiationDate = nextNegotiationDate;
    }
  }

  addNewContractButtonClick() {
    if (this.isAddingNewContractAllowed) {
      this.appliedProfileLowerBound = null;
      this.appliedProfileUpperBound = null;
      this.currentContract = new Contract({
        businessArea: this.currentContract.businessArea
      });
      this.addingMode = true;
      this.currentContract.commonCustomer = this.commonCustomer.id;
      this.openContractModalDialog();
    }
  }

  editNewContractButtonClick(contract: Contract) {
    this.addingMode = false;
    this.setAppliedProfilesBounds(contract.id);
    this.currentContract = _.cloneDeep(contract);
    this.savedContract = _.cloneDeep(this.currentContract);
    this.openContractModalDialog();
  }

  openContractModalDialog() {
    this.modalService.open(this.template, Size.LARGE);
  }

  close() {
    this.modalService.dismissAll();
  }

  saveContract() {
    if (this.addingMode) {
      this.contractService.createContract(this.currentContract).subscribe(
        result => {
          this.contracts.push(result);
          this.modalService.dismissAll();
          this.filterContracts();
        },
        error => console.log(error)
      );
    } else {
      this.contractService.updateContract(this.currentContract).subscribe(
        result => {
          this.contracts.splice(
            this.contracts.findIndex(a => a.id === result.id),
            1,
            result
          );
          this.modalService.dismissAll();
          this.filterContracts();
        },
        error => console.log(error)
      );
    }
  }

  saveContractRunPriceIndex() {
    this.contractService.updateContract(this.currentContract).subscribe(
      result => {
        this.contracts.splice(
          this.contracts.findIndex(a => a.id === result.id),
          1,
          result
        );
        this.priceAgreementService.getPriceAgreements(this.currentContract.id).subscribe(
          result => {
            let indexesUsed = [];
            let maxYear = 0;
            result.forEach(pa => {
              pa.categories.forEach(pac => maxYear = Math.max(maxYear, this.findMaxYear(pac)));
            });
            if (!!this.currentContract.terminationDate) {
              maxYear = Math.min(this.currentContract.terminationDate.getFullYear(), maxYear);
            }
            this.indexService.updateIndexes(indexesUsed, maxYear.toString()).subscribe(
              result => {
                this.modalService.dismissAll();
                this.filterContracts();
              },
              error => {
              }
            );
          },
          error => console.error(error)
        );
      },
      error => console.log(error)
    );
  }

  private findMaxYear(category: PriceAgreementCategory): number {
    let maxYear = 0;
    category.services.forEach(service => {
      if (service.years.length > 0) {
        maxYear = Math.max(maxYear, service.years[service.years.length - 1]);
      } else {
        maxYear = Math.max(maxYear, this.currentContract.commencementDate.getFullYear());
      }
    });
    return maxYear;
  }

  private setAppliedProfilesBounds(contractId: string) {
    this.fetchingBounds = true;
    this.appliedProfileService.getAppliedProfiles(contractId).subscribe(
      result => {
        if (result.length){
          this.appliedProfileLowerBound = new Date(Math.min.apply(null, result.map(ap => ap.commencement)));
          this.appliedProfileLowerBound.setHours(0, 0, 0, 0);
        } else {
          this.appliedProfileLowerBound = null;
        }
        if (result.find(ap => ap.termination == null)) {
          this.appliedProfileUpperBound = null;
        } else {
          this.appliedProfileUpperBound = new Date(Math.max.apply(null, result.map(ap => ap.termination)));
          this.appliedProfileUpperBound.setHours(0, 0, 0, 0);
        }
        this.fetchingBounds = false;
      }
    );
  }
}

export enum FilterEnum {
  active = 'Active & future contracts',
  expired = 'Expired contracts'
}
