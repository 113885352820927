import {Injectable} from "@angular/core";
import {Adapter} from "@onepoint/shared/interfaces/adapter";
import * as moment from 'moment';

export class ErpCreditor {
  id: number;
  erpClient = { id: null, name: null };
  erpId: number;
  erpLastUpdated: moment.Moment;
  erpActive: boolean;
  erpExpired: moment.Moment;
  name: string;
  alias: string;
  vatRegNo: string;
  address: string;
  addressType: number;
  addressTypeDesc: string;
  postCode: string;
  place: string;
  countryCode: string;
  emails: string;
  group: number;
  groupDesc: string;
  segment: string;
  segmentDesc: string;
  paymentTerms: string;
  paymentTermsDesc: string;
  payTransfer: string;
  payTransferDesc: string;
  payCurrency: string;
  massPayment: boolean;
  bankAccount: string;
  currency: string;
  iban: string;
  swift: string;
  onBlackList: boolean;
  displayName: string;
  public constructor(init?: Partial<ErpCreditor>) {
    Object.assign(this, init);
  }

}

@Injectable({
  providedIn: 'root'
})
export class ErpCreditorAdapter implements Adapter<ErpCreditor> {
  adapt(item: any): ErpCreditor {
    return new ErpCreditor({
      id: item.id,
      erpClient: item.erpClient,
      erpId: item.erpId,
      erpLastUpdated: item.erpLastUpdated == null ? null : moment(item.erpLastUpdated),
      erpActive: item.erpActive,
      erpExpired: item.erpExpired,
      name: item.name,
      alias: item.alias,
      vatRegNo: item.vatRegNo,
      address: item.address,
      addressType: item.addressType,
      addressTypeDesc: item.addressTypeDesc,
      postCode: item.postCode,
      place: item.place,
      countryCode: item.countryCode,
      emails: item.emails,
      group: item.group,
      groupDesc: item.groupDesc,
      segment: item.segment,
      segmentDesc: item.segmentDesc,
      paymentTerms: item.paymentTerms,
      paymentTermsDesc: item.paymentTermsDesc,
      payTransfer: item.payTransfer,
      payTransferDesc: item.payTransferDesc,
      payCurrency: item.payCurrency,
      massPayment: item.massPayment,
      bankAccount: item.bankAccount,
      currency: item.currency,
      iban: item.iban,
      swift: item.swift,
      displayName: item.erpId + " - " + item.name + " ("+ item.currency + ")"
    });
  }
}
