<div class="customers-summary-container justify-content-between no-gutters">
  <div class="col-4 my-auto counts-container">
    <div class="count-container">
      <span>Common Customers</span> <span>{{totalNumberOfCommonCustomers}}</span>
    </div>
    <div class="count-container">
      <span>Brands</span> <span>{{totalNumberOfBrands}}</span>
    </div>
    <div class="count-container">
      <span>Profiles</span> <span>{{totalNumberOfProfiles}}</span>
    </div>
  </div>
  <div class="my-auto search-container">
    <app-input withImage="true" [(value)]="searchText" (focusOut)="textValueChange()"></app-input>
  </div>
  <button class="primary-button my-auto" (click)="addNewCommonCustomer()">Add new Common Customer</button>

</div>

<app-new-common-customer #newCommonCustomerComponent>
</app-new-common-customer>
