<div class="service-category row" [class.not-included]="!categoryIncluded()">
  <div class="category-heading col-12 text-center">{{ categoryName }}</div>
  <div class="col-12 d-flex justify-content-center">
    <div class="status" *ngIf="categoryIncluded()">Included</div>
    <div class="status" *ngIf="!categoryIncluded()">Not included</div>
    <app-input-switch
      *ngIf="!suspended"
      [id]="'category-' + categoryId"
      [value]="categoryIncluded()"
      (valueChange)="priceAgreementCategory.included = $event.valueOf()"
    >
    </app-input-switch>
  </div>
</div>
<div *ngIf="priceAgreementCategory">
  <div class="d-flex category-container">
    <div class="service-container">
      <div class="service-name-headers">Service type</div>
      <div class="service-list" *ngFor="let service of services">
        <app-services-general
          [sosServices]="service"
          [categoryId]="categoryId"
          [priceAgreementService]="getService(service.id)"
          [categoryIncluded]="categoryIncluded()"
          [suspended]="suspended"
          [editable]="editable"
          [manualIndex]="manualIndex"
          [options]="options"
          [showType]="showType"
          (dataChange)="recalculateValid()"
        >
        </app-services-general>
      </div>
    </div>
    <div #priceContainerDiv class="price-container" [scrollLeft]=scrollLeft>
      <div class="d-flex price-years">
        <div class="price-headers" *ngFor="let year of getYears()">Price {{ year }}</div>
      </div>
      <div class="price-list" *ngFor="let service of services">
        <app-services-prices
          [sosServices]="service"
          [categoryId]="categoryId"
          [priceAgreementService]="getService(service.id)"
          [categoryIncluded]="categoryIncluded()"
          [suspended]="suspended"
          [editable]="editable"
          [manualIndex]="manualIndex"
          [options]="options"
          [showType]="showType"
          (dataChange)="recalculateValid()"
        >
        </app-services-prices>
      </div>
    </div>
  </div>
</div>
