import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Event, NavigationStart, Router } from '@angular/router';
import { SupplierProfileService } from 'app/core/services/supplier-profile.service';
import { AutoUnsubscribe } from 'app/shared/decorators/auto-unsubscribe';
import { SupplierProfile } from 'app/shared/models/supplier-profile';
import { Subscription } from 'rxjs';
import { PermissionEnum } from '@onepoint/shared/models/permission.enum';
import {SimpleErpCreditor} from "@onepoint/shared/models/simple-erp-creditor";
import {ErpDataService} from "@onepoint/core/services/erp-data.service";

@Component({
  selector: 'app-suppliers-page',
  templateUrl: './suppliers-page.component.html',
  styleUrls: ['./suppliers-page.component.scss'],
})
@AutoUnsubscribe
export class SuppliersPageComponent implements OnInit {
  subcription: Subscription;
  shownSection: string;
  supplierProfile: SupplierProfile;
  allErpCreditors: SimpleErpCreditor[];
  constructor(private route: ActivatedRoute, private erpDataService: ErpDataService, private supplierProfileService: SupplierProfileService) {}

  ngOnInit(): void {
    this.shownSection = 'overview';
    this.erpDataService.getAllSimpleCreditors().subscribe(erpCreditors => this.allErpCreditors = erpCreditors);
    this.subcription = this.route.params.subscribe(params => {
      this.supplierProfileService.get(params.id).subscribe(
        result => (this.supplierProfile = result),
        error => console.error(error)
      );
    });
  }

  onSupplierProfileUpdate(changed: SupplierProfile) {
    this.supplierProfile = changed;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
