import {Component, OnInit, TemplateRef, ViewChild, Output, EventEmitter, Input} from '@angular/core';
import {OnepointModalService, Size} from "@onepoint/core/services/onepoint.modal.service";
import {Dictionary} from "@onepoint/shared/models/dictionary";
import {SlaCalculatedEvent} from "@onepoint/shared/models/sla-calculated-event";
import {SlaCalculatedEventService} from "@onepoint/core/services/sla-calculated-event.service";

@Component({
  selector: 'app-add-sla-calculated-event',
  templateUrl: './add-sla-calculated-event.component.html',
  styleUrls: ['./add-sla-calculated-event.component.scss']
})
export class AddSlaCalculatedEventComponent{
  @Output() addSlaCalculatedEvent = new EventEmitter();
  @ViewChild('content')
  private template: TemplateRef<any>;
  slaCalculatedEvent: SlaCalculatedEvent;
  slaCalculatedEventTypes: string[]
  slaCalculatedEventTypeDictionary: Dictionary[]
  valid: boolean;

  constructor(private modalService: OnepointModalService, private slaCalculatedEventService: SlaCalculatedEventService) {
  }

  openNew(slaCalculatedEventTypes: string[]) {
    this.slaCalculatedEvent = new SlaCalculatedEvent();
    this.slaCalculatedEventTypes = slaCalculatedEventTypes;
    this.createDictionary(slaCalculatedEventTypes);
    this.modalService.open(this.template, Size.NORMAL);
  }

  openExisting(slaCalculatedEvent: SlaCalculatedEvent, slaCalculatedEventTypes: string[]) {
    this.slaCalculatedEventTypes = slaCalculatedEventTypes;
    this.createDictionary(slaCalculatedEventTypes);
    this.slaCalculatedEventService.getSlaCalculatedEvent(slaCalculatedEvent.id).subscribe(
      result=>{
        this.slaCalculatedEvent = result;
        this.modalService.open(this.template, Size.NORMAL);
      }
    )
  }

  createDictionary(slaCalculatedEventTypes: string[]){
    this.slaCalculatedEventTypeDictionary = slaCalculatedEventTypes.map(type => new Dictionary(type, type));
  }

  validate() {
    this.valid = false;
    if(this.slaCalculatedEvent.name !== null && this.slaCalculatedEvent.name !== undefined && this.slaCalculatedEvent.name.length > 0
      && this.slaCalculatedEvent.calculatedEventType !== undefined && this.slaCalculatedEvent.calculatedEventType !== null){
      this.valid = true;
    }
  }

  close() {
    this.modalService.dismissAll();
  }

  save() {
    this.slaCalculatedEventService.saveSlaCalculatedEvent(this.slaCalculatedEvent).subscribe(
      result => {
        this.addSlaCalculatedEvent.emit();
        this.modalService.dismissAll();
      }
    )
  }

}
