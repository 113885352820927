<div class="page-container">
    <div class="page-header header">
        <button class="primary-button button" (click)="createActivity()">Create new activity</button>
    </div>
  <table class="primary-table sos-services-table">
    <thead>
      <tr>
        <td class="unsortable-column">
          Name
        </td>
        <td class="unsortable-column">
          Type
        </td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let activity of activities" (click)="editActivity(activity)">
        <td>
          {{activity.name}}
        </td>
        <td>
          {{activity.type}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<app-edit-dcf-activity #editDcfActivityComponent (updateCallback)="editUpdated()">
</app-edit-dcf-activity>
