import {Component, OnInit, TemplateRef, ViewChild, Output, EventEmitter, Input} from '@angular/core';
import {OnepointModalService, Size} from "@onepoint/core/services/onepoint.modal.service";
import {Sla} from "@onepoint/shared/models/sla";
import {SlaService} from "@onepoint/core/services/sla.service";
import {SlaCategory} from "@onepoint/shared/models/sla-category";
import {Dictionary} from "@onepoint/shared/models/dictionary";
import {SlaEvent} from "@onepoint/shared/models/sla-event";
import {SlaRule} from "@onepoint/shared/models/sla-rule";
import {SlaRuleService} from "@onepoint/core/services/sla-rule.service";
import {validate} from "codelyzer/walkerFactory/walkerFn";

@Component({
  selector: 'app-add-sla',
  templateUrl: './add-sla.component.html',
  styleUrls: ['./add-sla.component.scss']
})
export class AddSlaComponent{
  @Output() addSla = new EventEmitter();
  @ViewChild('content')
  private template: TemplateRef<any>;
  sla: Sla;
  slaEvents: SlaEvent[];
  slaRules: SlaRule[];
  slaCategories: SlaCategory[];
  slaCategoryDictionary: Dictionary[];
  valid: boolean;
  validationMessage: string;

  constructor(private modalService: OnepointModalService, private slaService: SlaService, private slaRuleService : SlaRuleService) {
  }

  openNew(slaCategories: SlaCategory[]) {
    this.reload();
    this.sla = new Sla();
    this.sla.category = new SlaCategory();
    this.slaCategories = slaCategories;
    this.createDictionary(slaCategories);
    this.modalService.open(this.template, Size.NORMAL);
    this.valid = false;
  }

  openExisting(sla: Sla, slaCategories: SlaCategory[]) {
    this.slaCategories = slaCategories;
    this.createDictionary(slaCategories);
    this.slaService.getSla(sla.id).subscribe(
      result=>{
        this.sla = result;
        this.modalService.open(this.template, Size.NORMAL);
        this.reload();
        this.validate();
      }
    )
  }

  reload(){
    this.slaService.getSlaEvents().subscribe(result =>{
      this.slaEvents = result;
    });
    this.slaRuleService.getSlaRules().subscribe(result =>{
      this.slaRules = result;
    });
  }

  createDictionary(slaCategories: SlaCategory[]){
    this.slaCategoryDictionary = slaCategories.map(category => new Dictionary(category.id.toString(), category.name));
  }

  categoryChanged(event: any){
    this.sla.category = this.slaCategories.find(category => category.id.toString() === event);
    this.validate();
  }

  standardChanged(event: any){
    this.sla.standard = event.valueOf()
    this.validate();
  }

  close() {
    this.modalService.dismissAll();
  }

  exceptionSelected(){
    var rules = [];
    this.sla.rules.forEach(rule =>{
      rules.push(this.slaRules.find(ruleBackend => ruleBackend.id === rule.id));
    });
    this.sla.rules = rules;
    this.validate();
  }

  validate() {
    this.valid = true;

    if(this.sla.rules?.filter(rule => rule.type === 'BUSINESS_DAYS_ONLY').length > 0
      && (this.sla.rules?.filter(rule => rule.type === 'GRACE_PERIOD').length > 0
        || this.sla.rules?.filter(rule => rule.type === 'GRACE_PERIOD_LOCAL').length > 0)){
      this.valid = false;
      this.validationMessage = 'Can´t use both grace period and business day exceptions';
    }
    if(this.sla.rules?.filter(rule => rule.type === 'GRACE_PERIOD').length > 0
      && this.sla.rules?.filter(rule => rule.type === 'GRACE_PERIOD_LOCAL').length > 0){
      this.valid = false;
      this.validationMessage = 'Can´t use both grace periods exceptions';
    }
    if(this.sla.name === undefined || this.sla.name === null || this.sla.name.length === 0){
      this.valid = false;
      this.validationMessage = 'Name is missing';
    }
    if(this.sla.requirement === undefined || this.sla.requirement.toString() === ""){
      this.valid = false;
      this.validationMessage = 'Requirement is empty';
    }
    if(this.sla.deadlineTime === undefined || this.sla.deadlineTime.toString() === ""){
      this.valid = false;
      this.validationMessage = 'Deadline is empty';
    }
    if(this.sla.startEvents === undefined || this.sla.startEvents.length === 0){
      this.valid = false;
      this.validationMessage = 'Missing start event';
    }
    if(this.sla.endEvents === undefined || this.sla.endEvents.length === 0){
      this.valid = false;
      this.validationMessage = 'Missing end event';
    }
    if(this.sla.category.name === undefined){
      this.valid = false;
      this.validationMessage = 'Missing name';
    }

  }

  save() {
    this.slaService.saveCustomerSlaAgreement(this.sla).subscribe(
      result => {
        this.addSla.emit();
        this.modalService.dismissAll();
      }
    )
  }

}
