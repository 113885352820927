import {Component, OnInit} from '@angular/core';
import {ErpCreditor} from "@onepoint/shared/models/erp-creditor";
import {ErpCreditorPageRequest, ErpDataService} from "@onepoint/core/services/erp-data.service";
import {CreditorListPageDto} from "@onepoint/shared/models/creditor-list-page-dto";
import {Dictionary} from "@onepoint/shared/models/dictionary";
@Component({
  selector: 'app-creditor-list',
  templateUrl: './creditor-list.component.html',
  styleUrls: ['./creditor-list.component.scss']
})
export class CreditorListComponent implements OnInit {
  sortKey: string = 'erpId';
  erpIdSearch: string = '';
  pageIndex: number = 0;
  nameSearch: string = '';
  selectedStatus: string = 'all';
  statusTypes: Dictionary[] = [
    { id: 'all', name: 'All' },
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
  ];
  creditors: ErpCreditor[] = [];
  pageSize: number = 100;
  totalElements: number = 0;

  constructor(private erpDataService: ErpDataService) {
  }

  ngOnInit() {
    this.reload();
  }

  pagingUpdate($event){
    this.pageSize = $event.pageSize;
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.reload();
  }
  sort(sortColumn: string){
    this.sortKey = sortColumn;
    this.reload();
  }
  reload(){
    let erpId = null;
    if(this.erpIdSearch.length != 0){
      erpId = +this.erpIdSearch;
    }
    const request = new ErpCreditorPageRequest(this.pageIndex,this.pageSize,erpId,this.nameSearch,this.selectedStatus, this.sortKey)
    console.log(request);
    this.erpDataService.getCreditorPage(request).subscribe((page: CreditorListPageDto) => {
      this.creditors = page.erpCreditors;
      this.totalElements = page.totalCreditors;
    });
  }

}
