export enum PermissionHideTypeEnum {
    DISABLE,
    HIDE,
    REMOVE,
  }

  export enum PermissionEnum {
    NONE = '',
    AC_STATUS_UPDATE = 'AC Status - Update',
    CASE_BASIC_CREATE = 'Case Basic - Create',
    CASE_BASIC_UPDATE = 'Case Basic - Update',
    CASE_BASIC_DELETE = 'Case Basic - Delete',
    CASE_BASIC_READ = 'Case Basic - Read',
    CASE_SUMMARY_FULL_DETAILS_READ = 'Case Summary full details - Read',
    CASE_SUMMARY_READ = 'Case Summary - Read',
    COMMUNICATION_CREATE = 'Communication - Create',
    COMMUNICATION_UPDATE = 'Communication - Update',
    COMMUNICATION_DELETE = 'Communication - Delete',
    COMMUNICATION_READ = 'Communication - Read',
    CUSTOMER_AND_SOS_SERVICE_CREATE = 'Customer and SOS Service - Create',
    CUSTOMER_AND_SOS_SERVICE_UPDATE = 'Customer and SOS Service - Update',
    CUSTOMER_AND_SOS_SERVICE_DELETE = 'Customer and SOS Service - Delete',
    CUSTOMER_AND_SOS_SERVICE_READ = 'Customer and SOS Service - Read',
    DOCUMENT_CREATE = 'Document - Create',
    DOCUMENT_UPDATE = 'Document - Update',
    DOCUMENT_DELETE = 'Document - Delete',
    DOCUMENT_READ = 'Document - Read',
    NOTE_CREATE = 'Note - Create',
    NOTE_UPDATE = 'Note - Update',
    NOTE_DELETE = 'Note - Delete',
    NOTE_READ = 'Note - Read',
    SALES_ORDER_CREATE = 'Sales Order - Create',
    SALES_ORDER_UPDATE = 'Sales Order - Update',
    SALES_ORDER_DELETE = 'Sales Order - Delete',
    SALES_ORDER_READ = 'Sales Order - Read',
    SEARCH_READ = 'Search - Read',
    PLANS_CREATE = 'Plans - Create',
    PLANS_UPDATE = 'Plans - Update',
    PLANS_DELETE = 'Plans - Delete',
    PLANS_READ = 'Plans - Read',
    STAKEHOLDER_CREATE = 'Stakeholder - Create',
    STAKEHOLDER_UPDATE = 'Stakeholder - Update',
    STAKEHOLDER_DELETE = 'Stakeholder - Delete',
    STAKEHOLDER_READ = 'Stakeholder - Read',
    SUPPLIER_INVOICE_CREATE = 'Supplier Invoice - Create',
    SUPPLIER_INVOICE_UPDATE = 'Supplier Invoice - Update',
    SUPPLIER_INVOICE_DELETE = 'Supplier Invoice - Delete',
    SUPPLIER_INVOICE_READ = 'Supplier Invoice - Read',
    TASK_CREATE = 'Task - Create',
    TASK_UPDATE = 'Task - Update',
    TASK_DELETE = 'Task - Delete',
    TASK_READ = 'Task - Read',
    TASK_SUPPLIER_INVOICE_READ = 'Task Supplier Invoice - Read',
    TASK_SUPPLIER_INVOICE_UPDATE = 'Task Supplier Invoice - Update',
    MEDICAL_CREATE = 'Medical - Create',
    MEDICAL_UPDATE = 'Medical - Update',
    MEDICAL_DELETE = 'Medical - Delete',
    MEDICAL_READ = 'Medical - Read',
    MEDICAL_ESCORT_SUPPLIER_INVOICE_CREATE = 'Medical escort supplier Invoice - Create',
    MEDICAL_ESCORT_SUPPLIER_INVOICE_UPDATE = 'Medical escort supplier Invoice - Update',
    MEDICAL_ESCORT_SUPPLIER_INVOICE_DELETE = 'Medical escort supplier Invoice - Delete',
    MEDICAL_ESCORT_SUPPLIER_INVOICE_READ = 'Medical escort supplier Invoice - Read',
    TEMPLATES_AND_BUSINESS_RULES_READ = 'Templates and business rules - Read',
    TEMPLATES_AND_BUSINESS_RULES_CREATE = 'Templates and business rules - Create',
    TEMPLATES_AND_BUSINESS_RULES_UPDATE = 'Templates and business rules - Update',
    NOTIFICATION_CREATE = 'Notification - Create',
    NOTIFICATION_UPDATE = 'Notification - Update',
    NOTIFICATION_DELETE = 'Notification - Delete',
    NOTIFICATION_READ = 'Notification - Read',
    REFUND_CREATE = 'Refund - Create',
    REFUND_UPDATE = 'Refund - Update',
    REFUND_DELETE = 'Refund - Delete',
    REFUND_READ = 'Refund - Read',
    CUSTOMER_PRICE_AGREEMENT_CREATE = 'Customer price agreement - Create',
    CUSTOMER_PRICE_AGREEMENT_UPDATE = 'Customer price agreement - Update',
    CUSTOMER_PRICE_AGREEMENT_DELETE = 'Customer price agreement - Delete',
    CUSTOMER_PRICE_AGREEMENT_READ = 'Customer price agreement - Read',
    CUSTOMER_COVERAGES_LIMITS_RELATION_CREATE = 'Customer coverages and limits and relations - Create',
    CUSTOMER_COVERAGES_LIMITS_RELATION_UPDATE = 'Customer coverages and limits and relations - Update',
    CUSTOMER_COVERAGES_LIMITS_RELATION_DELETE = 'Customer coverages and limits and relations - Delete',
    CUSTOMER_COVERAGES_LIMITS_RELATION_READ = 'Customer coverages and limits and relations - Read',

    SUPPLIER_CREATE = 'Supplier - Create',
    SUPPLIER_UPDATE = 'Supplier - Update',
    SUPPLIER_DELETE = 'Supplier - Delete',
    SUPPLIER_READ = 'Supplier - Read'
  }
