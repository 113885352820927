import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SosServices } from 'app/shared/models/sos-services';
import { SosServicesService } from 'app/core/services/sos-services.service';
import { RoutesName } from 'app/app-routes-name';
import { SosServicesValidationService } from 'app/core/services/sos-services-validation.service';
import { BreadcrumbsMenuUpdateService } from 'app/shared/components/breadcrumbs-menu/breadcrumbs-menu-update-service';

@Component({
  selector: 'app-sos-services-settings',
  templateUrl: './sos-services-settings.component.html',
  styleUrls: ['./sos-services-settings.component.scss'],
})
export class SosServicesSettingsComponent implements OnInit {
  @ViewChild('content', { static: true }) template: TemplateRef<any>;
  @Input() sosServicesOriginal: SosServices;
  @Output() sosServicesChanged: EventEmitter<SosServices> = new EventEmitter<SosServices>();

  canSave = false;
  deletable = false;
  private sosServicesOriginalString: string;
  sosServices: SosServices;

  constructor(
    private sosServicesService: SosServicesService,
    private sosServicesValidationService: SosServicesValidationService,
    private router: Router,
    private breadcrumbsMenuUpdateService: BreadcrumbsMenuUpdateService
  ) {}

  ngOnInit() {
    this.sosServicesOriginalString = JSON.stringify(this.sosServicesOriginal);
    this.sosServices = new SosServices(this.sosServicesOriginal);
    this.sosServicesService.usedOnPriceAgreement(this.sosServices.id).subscribe(result => {
      this.deletable = !JSON.parse(result);
    });
  }

  onEdit() {
    this.sosServicesService.update(this.sosServices).subscribe(
      result => {
        this.sosServicesOriginalString = JSON.stringify(result);
        this.sosServices = new SosServices(result);
        this.canSave = false;
        this.sosServicesChanged.emit(result);
        this.breadcrumbsMenuUpdateService.updatedSosService(result);
      },
      error => {
        console.error(error);
      }
    );
  }

  handleChanges(sosServices: SosServices) {
    this.canSave = JSON.stringify(sosServices) !== this.sosServicesOriginalString;
    if (
      !(
        this.sosServicesValidationService.isValid(sosServices) &&
        this.sosServicesValidationService.isSosServiceIdUnique(sosServices, SosServicesService.allSosServices)
      )
    ) {
      this.canSave = false;
    }
  }

  delete() {
    this.sosServicesService.delete(this.sosServicesOriginal.id).subscribe(
      result => {
        alert(result);
        this.router.navigate([RoutesName.sosServices]);
      },
      error => {
        console.error(error);
      }
    );
  }
}
