<div class="menu">
  <ul class="nav justify-content-center">
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'sla'}" (click)="shownSection = 'sla'">
        <div>SLA</div>
      </div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'sla-rule'}" (click)="shownSection = 'sla-rule'">
        <div>SLA exception</div>
      </div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'sla-calculated-event'}" (click)="shownSection = 'sla-calculated-event'">
        <div>SLA calculated events</div>
      </div>
    </li>
  </ul>
</div>
<app-sla *ngIf="shownSection === 'sla'"></app-sla>
<app-sla-rule *ngIf="shownSection === 'sla-rule'"></app-sla-rule>
<app-sla-calculated-event *ngIf="shownSection === 'sla-calculated-event'"></app-sla-calculated-event>
