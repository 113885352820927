import {Component, OnInit} from '@angular/core';
import {Dictionary} from '@onepoint/shared/models/dictionary';
import {finalize, map} from 'rxjs/operators';
import {CaseProcessProfile} from '@onepoint/shared/models/case-process-profile';
import {CaseProcessService} from '@onepoint/core/services/case-process.service';
import {
  CustomerProfileCaseProcessesOverviewSortingMenuType
} from './customer-profile-case-processes-overview-sorting-menu/customer-profile-case-processes-overview-sorting-menu-type';
import {Router} from '@angular/router';
import {HelperService} from '@onepoint/core/services/helper.service';
import {CaseProcess} from '@onepoint/shared/models/case-process';

@Component({
  selector: 'app-customer-profile-case-processes-overview',
  templateUrl: './customer-profile-case-processes-overview.component.html',
  styleUrls: ['./customer-profile-case-processes-overview.component.scss']
})
export class CustomerProfileCaseProcessesOverviewComponent implements OnInit {

  caseProcessProfiles: CaseProcessProfile[];

  checkboxShowActive = false;
  loadInProgress = false;
  saveInProgress = false;
  selectedCaseProcessType: string;
  caseProcessTypes: Dictionary[];
  checkboxAllIncluded = false;
  checkboxEditAllValidFrom = false;
  checkboxEditAllValidTo = false;
  formChanged = false;
  caseProcessTypeDescription: string;
  oldCaseProcessTypeDescription: string;
  showMenu = new Map<CustomerProfileCaseProcessesOverviewSortingMenuType, boolean>();
  filteredCaseProcessProfiles: CaseProcessProfile[];

  get isSaveButtonEnabled(): boolean {
    return this.formChanged || this.oldCaseProcessTypeDescription !== this.caseProcessTypeDescription;
  }

  get CustomerProfileCaseProcessesOverviewSortingMenuType() {
    return CustomerProfileCaseProcessesOverviewSortingMenuType;
  }

  constructor(private caseProcessService: CaseProcessService,
              private helperService: HelperService,
              private router: Router) { }

  ngOnInit(): void {
    this.caseProcessService.getCaseProcessTypes()
      .pipe(
        map((items: CaseProcess[]) => items.map(item => new Dictionary(item.caseProcessType, item.caseProcessName)))
      )
      .subscribe(
        result => this.caseProcessTypes = result
      );
  }

  caseProcessTypeSelected() {
    this.checkboxAllIncluded = false;
    this.checkboxEditAllValidFrom = false;
    this.checkboxEditAllValidTo = false;
    this.formChanged = false;
    this.loadCaseProcessProfiles(this.selectedCaseProcessType);
  }

  private loadCaseProcessProfiles(caseProcessType: string): void {
    this.loadInProgress = true;
    this.caseProcessProfiles = [];
    this.caseProcessService.getCaseProcessProfilesByCaseProcessType(caseProcessType)
      .pipe(finalize(() => this.loadInProgress = false))
      .subscribe(result => {
        this.caseProcessProfiles = result;
        this.caseProcessService.getDescriptionForCaseProcessType(caseProcessType).subscribe(
          description => {
            this.caseProcessTypeDescription = description;
            this.oldCaseProcessTypeDescription = this.caseProcessTypeDescription;
          }
        );
        this.checkboxAllIncluded = this.isAllSelected(CustomerProfileCaseProcessesOverviewSortingMenuType.INCLUDED);
        this.filterCaseProcesses();
        this.sortCaseProcesses();
      });
  }

  private isAllSelected(property: string): boolean {
    return !this.caseProcessProfiles.find(item => !item[property]);
  }

  saveChanges() {
    this.saveInProgress = true;
    if (this.caseProcessTypeDescription !== this.oldCaseProcessTypeDescription) {
      this.caseProcessService.updateCaseProcessDescription(this.selectedCaseProcessType, this.caseProcessTypeDescription)
        .subscribe(() => {
          this.saveChangedCaseProcesses();
          this.oldCaseProcessTypeDescription = this.caseProcessTypeDescription;
        });
    } else {
      this.saveChangedCaseProcesses();
    }
  }

  private saveChangedCaseProcesses() {
    const changedCaseProcessProfile = this.filteredCaseProcessProfiles.filter(item => item.changed);
    if (changedCaseProcessProfile.length > 0) {
      this.caseProcessService.updateCaseProcessProfiles(changedCaseProcessProfile)
        .pipe(finalize(() => this.saveInProgress = false))
        .subscribe(() => {
          this.formChanged = false;
          this.caseProcessProfiles.forEach(item => item.changed = false);
          this.loadCaseProcessProfiles(this.selectedCaseProcessType);
        });
    } else {
      this.saveInProgress = false;
    }
  }

  checkBoxShowActiveChange() {
    this.filterCaseProcesses();
  }

  private filterCaseProcesses(): void {
    if ( this.checkboxShowActive ) {
      this.filteredCaseProcessProfiles = this.caseProcessProfiles.filter(profile => profile.included);
    }
    else {
      this.filteredCaseProcessProfiles = this.caseProcessProfiles;
    }
  }

  private sortCaseProcesses() {
    this.helperService.sortArray(
      this.filteredCaseProcessProfiles,
      CustomerProfileCaseProcessesOverviewSortingMenuType.PROFILE_NAME,
      true
    );
  }

  showFilteringSortingMenu(filteringSortingMenuType: CustomerProfileCaseProcessesOverviewSortingMenuType) {
    this.showMenu.forEach((value, key) => {
      if (key !== filteringSortingMenuType) {
        this.showMenu[key] = false;
      }
    });
    this.showMenu[filteringSortingMenuType] = !this.showMenu[filteringSortingMenuType];
  }

  checkBoxIncludedChange(caseProcessProfile: CaseProcessProfile) {
    this.checkboxAllIncluded = this.isAllSelected(CustomerProfileCaseProcessesOverviewSortingMenuType.INCLUDED);
    this.formChanged = true;
    caseProcessProfile.changed = true;
  }

  dateChangeValidFrom(caseProcessProfile: CaseProcessProfile) {
    this.formChanged = true;
    caseProcessProfile.changed = true;

    if (caseProcessProfile.validFrom) {
      caseProcessProfile.validFrom.setHours(12, 0, 0 , 0);
    }

    if (this.checkboxEditAllValidFrom) {
      this.caseProcessProfiles.forEach(item => {
        item.validFrom = caseProcessProfile.validFrom;
        item.changed = true;
      });
    }
  }

  dateChangeValidTo(caseProcessProfile: CaseProcessProfile) {
    this.formChanged = true;
    caseProcessProfile.changed = true;

    if (caseProcessProfile.validTo) {
      caseProcessProfile.validTo.setHours(12, 0, 0, 0);
    }

    if (this.checkboxEditAllValidFrom) {
      this.caseProcessProfiles.forEach(item => {
        item.validTo = caseProcessProfile.validTo;
        item.changed = true;
      });
    }
  }

  checkBoxAllIncludedChange(isChecked: boolean) {
    this.caseProcessProfiles.forEach(item => {
      item.included = isChecked;
      item.changed = true;
    });
    this.formChanged = true;
  }

  checkBoxEditAllValidFromChange(isChecked: boolean) {
    this.checkboxEditAllValidFrom = isChecked;
  }

  checkBoxEditAllValidToChange(isChecked: boolean) {
    this.checkboxEditAllValidTo = isChecked;
  }

  gotoProfile(profileId: number) {
    if (profileId) {
      this.router.navigate(['/customers/profile/' + profileId]);
    }
  }

  gotoCommonCustomer(commonCustomerId: number) {
    if (commonCustomerId) {
      this.router.navigate(['/customers/common-customer/' + commonCustomerId]);
    }
  }

  gotoBrand(brandId: number) {
    if (brandId) {
      this.router.navigate(['/customers/brand/' + brandId]);
    }
  }
}
