export enum CaseTypeMenuType {
  CASE_TYPE_CODE = 'caseTypeCode',
  CASE_TYPE_NAME = 'caseTypeName',
  CASE_TYPE_ENUM_VALUE = 'caseTypeEnumValue',
  UI_SORT_INDEX = 'uiSortIndex',
  DESCRIPTION = 'description',
  ACTIVATE_ON = 'activateOn',
  DEACTIVATION_ON = 'deactivateOn',
  ORDER = 'order',
  CASE_TYPE_RESET_DISABLED = 'caseTypeResetDisabled'
}
