import { Brand } from 'app/shared/models/brand';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CustomerListView } from '../../../../shared/models/customer-list-view';

@Component({
  selector: 'app-common-customer-overview',
  templateUrl: './common-customer-overview.component.html',
  styleUrls: ['./common-customer-overview.component.scss'],
})
export class CommonCustomerOverviewComponent implements OnInit {
  @Input() commonCustomer: CustomerListView;
  @Input() brands: CustomerListView[];
  @Output() refreshBrands = new EventEmitter<Brand>();

  constructor() {}

  ngOnInit() {}

  refreshBrandsEvent(brand: Brand) {
    this.refreshBrands.emit(brand);
    this.brands.sort((a, b) => a.market.localeCompare(b.market, 'da-DK') || a.name.localeCompare(b.name, 'da-DK'));
  }
}
