<div class="common-content-container">
  <div class="common-content-container-title">Product specific debtor</div>
  <div class="common-content-container-body">
    <div class="overruleDebtorRow">
      <div class="checkbox-container">
        <div class="overruleCheckboxWrapper">
          Overrule customer profile debtor?
          <input class="overruleDebtorCheckbox" type="checkbox" (change)="dataChanged()" [disabled]="isChildProduct()" [(ngModel)]="productDebtorOverrule.overruled"/>
        </div>
      </div>
    </div>
    <div class="dropdown-debtors">
      Product specific debtor
      <app-drop-down [items]="debtors" [(selectedItemId)]="productDebtorOverrule.debtorId" (selectedItemIdChange)="dataChanged()" [disabled]="!productDebtorOverrule.overruled || isChildProduct()"></app-drop-down>
    </div>
    <div class="debtor-remarks-input">
      Internal remarks
      <textarea name="remarks" rows="4" [(ngModel)]="productDebtorOverrule.remarks" (ngModelChange)="dataChanged()" [disabled]="isChildProduct()" [maxLength]="1000"></textarea>
    </div>
    <div class="debtor-overrule-save">
      <button class="primary-button" (click)="save()" [ngClass]="(isChildProduct() || !this.saveActive) && 'button-disabled'" [disabled]="isChildProduct() || !this.saveActive">Save</button>
    </div>
  </div>
</div>
