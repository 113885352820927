import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DictionaryService} from '../../../../core/services/dictionary.service';
import {HelperService} from '../../../../core/services/helper.service';
import { CustomerProductRelationOverview } from 'app/shared/models/customer-product-relation-overview';
import { CustomerProfileRelationOverviewFilterSortEnum } from './customer-profile-relations-overview-sorting-menu-enum';
import { CustomerProfileRelationOverviewService } from './customer-profile-relation-overview.service';
import { CustomerProductRelationTypeEnum } from 'app/shared/enums/policy/customer-product-relation-type.enum';

@Component({
  selector: 'app-customer-profile-relations-overview-sorting-menu',
  templateUrl: './customer-profile-relations-overview-sorting-menu.component.html',
  styleUrls: ['./customer-profile-relations-overview-sorting-menu.component.scss']
})
export class CustomerProfileRelationsOverviewSortingMenuComponent implements OnInit {
  @Input() customerProductRelationOverviewList: CustomerProductRelationOverview[];
  @Input() originalCustomerProductRelationOverviewList: CustomerProductRelationOverview[];
  @Input() customerProfileRelationOverviewFilterSortType: CustomerProfileRelationOverviewFilterSortEnum;

  @Output() modelChanged = new EventEmitter();
  @Output() customerProductRelationOverviewChange = new EventEmitter();
  @Output() clearAllFilters = new EventEmitter();


  creditCardTypes = new Map<string, string>([
    [CustomerProductRelationTypeEnum.BUSINESS_TRAVEL, 'Business travel'],
    [CustomerProductRelationTypeEnum.CREDIT_CARD, 'Credit card'],
    [CustomerProductRelationTypeEnum.EXPATRIATE_INSURANCE, 'Expatriate insurance'],
    [CustomerProductRelationTypeEnum.GROUP_INSURANCE, 'Group']
  ]);


  sortByAscending: boolean;
  items: CheckBoxModel[];
  sortingKey: string;

  constructor(private dictionaryService: DictionaryService,
              private helperService: HelperService,
              private customerProfileRelationOverviewService: CustomerProfileRelationOverviewService) { }

  ngOnInit() {
    this.populateItems();
  }

  public uncheckItems() {
    if (this.items) {
      this.items.forEach(a => (a.check = false));
    }
  }

  clearPopulateItems(CustomerProductRelations: CustomerProductRelationOverview[]) {
    this.customerProfileRelationOverviewService.filteringStep.forEach(a =>
      this.customerProfileRelationOverviewService.removeStep(a.filteringSortingMenuType)
    );
    this.customerProductRelationOverviewList = CustomerProductRelations;
    this.populateItems();
  }

  updatePopulateItems(CustomerProductRelations: CustomerProductRelationOverview[]) {
    this.customerProductRelationOverviewList = CustomerProductRelations;
    this.populateItems();
  }

  populateItems() {
    this.items = [];
    switch (this.customerProfileRelationOverviewFilterSortType) {
      case CustomerProfileRelationOverviewFilterSortEnum.type:
        const itemSelectedType = this.customerProfileRelationOverviewService.filteringStep.some(
          a =>  a.filteringSortingMenuType === this.customerProfileRelationOverviewFilterSortType
        );

        this.customerProductRelationOverviewList.forEach(a => {
          if (!this.items.find(f => f.id === a.type)) {
            this.items.push(new CheckBoxModel(a.type, this.creditCardTypes.get(a.type), itemSelectedType));
          }
        });
        this.sortingKey = 'type';
        break;
      case CustomerProfileRelationOverviewFilterSortEnum.companyPolicyHolder:
        const itemSelectedCompanyPolicyHolder = this.customerProfileRelationOverviewService.filteringStep.some(
          a => a.filteringSortingMenuType === this.customerProfileRelationOverviewFilterSortType
        );
        this.customerProductRelationOverviewList.forEach(a => {
            if (!this.items.find(f => f.id === a.companyPolicyHolder)) {
              this.items.push(new CheckBoxModel(a.companyPolicyHolder, a.companyPolicyHolder, itemSelectedCompanyPolicyHolder));
            }
        });
        this.sortingKey = 'companyPolicyHolder';
        break;
      case CustomerProfileRelationOverviewFilterSortEnum.bank:
        const itemSelectedBank = this.customerProfileRelationOverviewService.filteringStep.some(
          a => a.filteringSortingMenuType === this.customerProfileRelationOverviewFilterSortType
        );
        this.customerProductRelationOverviewList.forEach(a => {
          if (!this.items.find(f => f.id === a.bank)) {
            this.items.push(new CheckBoxModel(a.bank, a.bank, itemSelectedBank));
          }
        });
        this.sortingKey = 'bank';
        break;
      case CustomerProfileRelationOverviewFilterSortEnum.customerProfile:
        const itemSelectedCustomerProfile = this.customerProfileRelationOverviewService.filteringStep.some(
          a => a.filteringSortingMenuType === this.customerProfileRelationOverviewFilterSortType
        );
        this.customerProductRelationOverviewList.forEach(a => {
          if (!this.items.find(f => f.id === a.customerProfileName)) {
            this.items.push(new CheckBoxModel(a.customerProfileName, a.customerProfileName, itemSelectedCustomerProfile));
          }
        });
        this.sortingKey = 'customerProfileName';
        break;

      case CustomerProfileRelationOverviewFilterSortEnum.bin:
        const itemSelectedBin = this.customerProfileRelationOverviewService.filteringStep.some(
          a => a.filteringSortingMenuType === this.customerProfileRelationOverviewFilterSortType
        );
        this.customerProductRelationOverviewList.forEach(a => {
          if (!this.items.find(f => f.id === a.bin)) {
            this.items.push(new CheckBoxModel(a.bin, a.bin, itemSelectedBin));
          }
        });
        this.sortingKey = 'bin';
      break;

      case CustomerProfileRelationOverviewFilterSortEnum.customerReportingLabel:
        const itemSelectedReportingLabel = this.customerProfileRelationOverviewService.filteringStep.some(
          a => a.filteringSortingMenuType === this.customerProfileRelationOverviewFilterSortType
        );
        this.customerProductRelationOverviewList.forEach(a => {
          if (!this.items.find(f => f.id === a.customerRelationLabel)) {
            this.items.push(new CheckBoxModel(a.customerRelationLabel, a.customerRelationLabel, itemSelectedReportingLabel));
          }
        });
        this.sortingKey = 'customerRelationLabel';
      break;
    }
  }

  get doesUserCheckAnything() {
    return this.items.find(a => a.check) != null;
  }


  sortAscending() {
    this.sortByAscending = true;
    this.helperService.sortArray(
      this.customerProductRelationOverviewList,
      this.sortingKey,
      this.sortByAscending
    );
    this.modelChanged.emit();
  }

  sortDescending() {
    this.sortByAscending = false;
    this.helperService.sortArray(
      this.customerProductRelationOverviewList,
      this.sortingKey,
      this.sortByAscending
    );
    this.modelChanged.emit();
  }

  applyButtonClick() {
    if (!this.doesUserCheckAnything) {
      return;
    }
    this.clearAllFilters.emit();
    const selectedIds = [];
    this.items.filter(a => a.check).forEach(a => selectedIds.push(a.id));
    this.customerProfileRelationOverviewService.removeStep(this.customerProfileRelationOverviewFilterSortType);
    this.customerProfileRelationOverviewService.addStep(
      this.customerProfileRelationOverviewFilterSortType,
      selectedIds,
    );
    this.customerProductRelationOverviewList = this.customerProfileRelationOverviewService.filter(
      this.customerProductRelationOverviewList
    );

    this.customerProductRelationOverviewChange.emit(this.customerProductRelationOverviewList);
    this.modelChanged.emit();
  }

  clearAllButtonClick() {
    this.customerProductRelationOverviewList = JSON.parse(JSON.stringify(this.originalCustomerProductRelationOverviewList));
    this.items.forEach(a => (a.check = false));
    this.customerProfileRelationOverviewService.removeStep(this.customerProfileRelationOverviewFilterSortType);
    this.clearAllFilters.emit();
    this.customerProductRelationOverviewList = this.customerProfileRelationOverviewService.filter(
      this.customerProductRelationOverviewList
    );
    this.populateItems();
    this.customerProductRelationOverviewChange.emit(this.customerProductRelationOverviewList);
  }

  modalWindowClick(event: any) {
    event.stopPropagation();
  }
}

export class CheckBoxModel {
  name: string;
  id: string;
  check: boolean;

  constructor(id: string, name: string, check: boolean) {
    this.check = check;
    this.id = id;
    this.name = name;
  }
}
