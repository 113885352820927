import { MasterListCommitmentTypeModel } from "./masterlist-commitment-type-model";

export class MasterListServiceTypeModel {
  id: string;
  serviceTypeCode: string;
  serviceTypeEnumValue: string;
  serviceTypeName: string;
  description: string;
  economyApplicable: boolean;
  uiSortIndex: number;
  activateOn: Date;
  deactivateOn: Date;
  active: boolean;
  commitmentTypes: MasterListCommitmentTypeModel[];
  defaultCommitmentType : MasterListCommitmentTypeModel;

  public constructor(init?: Partial<MasterListServiceTypeModel>) {
    Object.assign(this, init);
  }

}
