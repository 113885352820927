<div class="modal-content">
  <ng-container *ngIf="configuration">
    <div class="modal-container">
      <div class="modal-header">
        <h2>{{configuration.header}}</h2>
      </div>
      <div class="modal-body">
        <div class="message-header">{{ configuration.warning.header }}</div>
        <div class="lane">
          <ul>
            <li *ngFor="let description of configuration.warning.descriptions">
              <div class="message">{{ description.key }} : {{ description.value }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <button class="primary-button float-end" (click)="close(true)">
          {{closeBtnLabel}}
        </button>
      </div>
    </div>

  </ng-container>
</div>
