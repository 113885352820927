import { Injectable } from '@angular/core';
import {ProductsPolicyRelationSortingTypeEnum} from './product-policy-relation-sorting-type-enum';
import { CustomerProductRelation } from 'app/shared/models/customers/product/policy/customer-product-relation';

@Injectable({
  providedIn: 'root'
})
export class CustomerProductRelationFilterService {
  filteringStep: FilteringStep[] = [];
  constructor() { }

  public addStep(
    croductsPolicyRelationSortingTypeEnum: ProductsPolicyRelationSortingTypeEnum,
    selectedIds: string[],
  ) {
    this.filteringStep.push(
      new FilteringStep(croductsPolicyRelationSortingTypeEnum, selectedIds)
    );
  }

  public removeStep(productsPolicyRelationSortingTypeEnum: ProductsPolicyRelationSortingTypeEnum) {
    const itemToDelete = this.filteringStep.find(
      a => a.filteringSortingMenuType === productsPolicyRelationSortingTypeEnum
    );
    if (itemToDelete) {
      this.filteringStep.splice(this.filteringStep.indexOf(itemToDelete));
    }
  }

  public filter(customerProductRelationList: CustomerProductRelation[]): CustomerProductRelation[] {
    let tempCustomerProductRelationsList: Set<CustomerProductRelation>;

    this.filteringStep.forEach(step => {
      tempCustomerProductRelationsList = new Set();
      switch (step.filteringSortingMenuType) {
        case ProductsPolicyRelationSortingTypeEnum.companyPolicyHolder:
          step.selectedIds.forEach(element => {
            customerProductRelationList
              .filter(a => a.companyPolicyHolder === element)
              .forEach(a => tempCustomerProductRelationsList.add(a));
          });
          break;
        case ProductsPolicyRelationSortingTypeEnum.bank:
          step.selectedIds.forEach(element => {
            customerProductRelationList
              .filter(a => a.bank === element)
              .forEach(a => tempCustomerProductRelationsList.add(a));
          });
          break;
        case ProductsPolicyRelationSortingTypeEnum.BIN:
          step.selectedIds.forEach(element => {
            customerProductRelationList
              .filter(a => a.bin.toString() === element)
              .forEach(a => tempCustomerProductRelationsList.add(a));
          });
          break;

          case ProductsPolicyRelationSortingTypeEnum.customerRelationLabel:
          step.selectedIds.forEach(element => {
            customerProductRelationList
              .filter(a => a.customerRelationLabel.name === element)
              .forEach(a => tempCustomerProductRelationsList.add(a));
          });
          break;
      }
      customerProductRelationList = Array.from(tempCustomerProductRelationsList);
    });
    return customerProductRelationList;
  }
}

export class FilteringStep {
  filteringSortingMenuType: ProductsPolicyRelationSortingTypeEnum;
  selectedIds: string[];
  constructor(
    filteringSortingMenuType: ProductsPolicyRelationSortingTypeEnum,
    selectedIds: string[]
  ) {
    this.filteringSortingMenuType = filteringSortingMenuType;
    this.selectedIds = selectedIds;
  }
}
