import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {OnepointModalService, Size} from '@onepoint/core/services/onepoint.modal.service';
import {CustomerProductRelationTypeEnum} from '@onepoint/shared/enums/policy/customer-product-relation-type.enum';
import {CustomerProductRelation} from '@onepoint/shared/models/customers/product/policy/customer-product-relation';
import {DictionaryService} from '@onepoint/core/services/dictionary.service';
import {Dictionary} from 'app/shared/models/dictionary';
import {PolicyLookupMethodService} from '@onepoint/core/services/policy-lookup-method.service';
import {CustomerRelationLabel} from 'app/shared/models/customer-relation-label';
import {SplitDateTypeEnum} from '@onepoint/shared/enums/policy/split-date.enum';
import * as moment from 'moment';
import {CustomerRelationLabelStatusEnum} from '@onepoint/shared/enums/customer-relation-label-status.enum';

@Component({
  selector: 'app-product-policy-relations-modal',
  templateUrl: './product-policy-relations-modal.component.html',
  styleUrls: ['./product-policy-relations-modal.component.scss']
})
export class ProductPolicyRelationsModalComponent implements OnInit {
  @Output() addProductRelation = new EventEmitter<CustomerProductRelation>();
  @Output() closeProductRelation = new EventEmitter<CustomerProductRelation>();
  @ViewChild('content')
  private template: TemplateRef<any>;
  customerProductRelation: CustomerProductRelation;
  existingCustomerProductRelationBeforeEdit: CustomerProductRelation;
  splitDatesTypesDictionary: Dictionary[];
  customerRelationLabel: CustomerRelationLabel[] = [];
  dropDownCustomerRelationLabelsList: Dictionary[] = [];
  selectedCustomerRelationLabel: number;
  existingRelation: boolean;

  @Input() set minDate(value) {
    if (value) {
      this._minDate = value;
      this._minDate.setDate(this._minDate.getDate() - 1)
    }
  }
  get minDate() {
    return this._minDate;
  }
  private _minDate: Date;


  @Input() set maxDate(value) {
    if (value) {
      this._maxDate = value;
      this._maxDate.setDate(this._maxDate.getDate() + 1)
    }
  }
  get maxDate() {
    return this._maxDate;
  }
  private _maxDate: Date;

  @Input() set customerProductRelations(value) {
    if (value) {
      this._CustomerProductRelations = value;
    }
  }
  get customerProductRelations() {
    return this._CustomerProductRelations;
  }
  private _CustomerProductRelations: CustomerProductRelation[];


  constructor(private modalService: OnepointModalService,
              private dictionaryService: DictionaryService,
              private policyLookupMethodService: PolicyLookupMethodService) {
    this.splitDatesTypesDictionary = this.dictionaryService.getSplitDatesTypes();
  }

  ngOnInit() {
    this.policyLookupMethodService.getCustomerRelationLabels().subscribe(
      result => {
        this.customerRelationLabel = result;
        this.dropDownCustomerRelationLabelsList.push(new Dictionary(null, ''));
        this.customerRelationLabel.forEach(customerRelationLabel => {
          if (customerRelationLabel.status !== CustomerRelationLabelStatusEnum.DEACTIVATED ) {
            const customerRelationLabelTest = customerRelationLabel.name +
              (customerRelationLabel.remarks ? ' - ' + customerRelationLabel.remarks : '');
            this.dropDownCustomerRelationLabelsList.push(new Dictionary(customerRelationLabel.id.toString(), customerRelationLabelTest));
          }
        });
      });
  }

  clearFieldsInForm() {
    // Only clear greyed out fields. Other fields should be carried over, when relation type is changed.
    if (this.customerProductRelation.customerProductRelationType === CustomerProductRelationTypeEnum.CREDIT_CARD) {
      this.customerProductRelation.companyPolicyHolder = '';
    } else {
      this.customerProductRelation.bin = '';
      this.customerProductRelation.bank = '';
    }
  }

  openExisting(value){
    this.existingRelation = true;
    this.modalService.open(this.template, Size.NORMAL);
    this.customerProductRelation = JSON.parse(JSON.stringify(this.customerProductRelations.find(customerProductRelation => ( customerProductRelation.id === value ))));
    this.existingCustomerProductRelationBeforeEdit = JSON.parse(JSON.stringify(this.customerProductRelation));
    if (this.customerProductRelation.customerRelationLabel) {
      this.selectedCustomerRelationLabel = this.customerProductRelation.customerRelationLabel.id;
    }
  }

  open(){
    this.existingRelation = false;
    this.modalService.open(this.template, Size.NORMAL);
    this.customerProductRelation = new CustomerProductRelation;
    this.customerProductRelation.splitDateType = SplitDateTypeEnum.INCIDENT_DATE;
    this.selectedCustomerRelationLabel = null;
  }

  close() {
    this.customerProductRelation = new CustomerProductRelation;
    this.customerProductRelation.splitDateType = undefined;
    this.selectedCustomerRelationLabel = null;
    this.modalService.dismissAll();
    this.closeProductRelation.emit();
  }

  changeRelationLabel() {
    this.customerProductRelation.customerRelationLabel = this.customerRelationLabel.find(label => label.id = this.selectedCustomerRelationLabel);
  }

  get CustomerProductRelationTypeEnum() {
    return CustomerProductRelationTypeEnum;
  }

  saveProductRelation() {
    this.addProductRelation.emit(this.customerProductRelation);
    this.customerProductRelation = new CustomerProductRelation;
    this.modalService.dismissAll();
  }

  existingWithNoChanges() {
      return (JSON.stringify(this.existingCustomerProductRelationBeforeEdit) === JSON.stringify(this.customerProductRelation));
  }

  checkForSameValuesInNewCustomerProductRelation() {
    let returnValue;


    this.customerProductRelations.forEach(existing => {
      const isBankAndBinTheSame = ((existing.bank === this.customerProductRelation.bank) &&
                                     (existing.bin === this.customerProductRelation.bin));

      const isRelationTheSame = (existing.id === this.customerProductRelation.id);

      const existingRelationWithNoDateChanges = (isRelationTheSame &&
                                                  (moment(existing.validFrom).isSame(moment(this.customerProductRelation.validFrom))) &&
                                                  (moment(existing.validTo).isSame(moment(this.customerProductRelation.validTo))));

      if (!existingRelationWithNoDateChanges) {
        if (existing.validTo) {
          if (this.customerProductRelation.validFrom) {
                if (moment(existing.validFrom).isSameOrBefore(moment(this.customerProductRelation.validFrom)) &&
                moment(this.customerProductRelation.validFrom).isSameOrBefore(moment(existing.validTo)) &&
                isBankAndBinTheSame) {
                  returnValue = false;
                }
          }
          if (this.customerProductRelation.validTo) {
                if (moment(existing.validFrom).isSameOrBefore(moment(this.customerProductRelation.validTo)) &&
                    moment(this.customerProductRelation.validTo).isSameOrBefore(moment(existing.validTo)) &&
                    isBankAndBinTheSame) {
                      returnValue = false;
                    }
          }

          if (moment(this.customerProductRelation.validFrom).isBefore(moment(existing.validFrom)) &&
              moment(existing.validTo).isBefore(moment(this.customerProductRelation.validTo)) &&
              isBankAndBinTheSame) {
                returnValue = false;
              }

        } else if (this.customerProductRelation.validFrom &&
                   !existing.validTo &&
                   isBankAndBinTheSame &&
                   !isRelationTheSame) {
                      returnValue = false;
                  }
      }
      else {
        return false;
      }
    });
    if (returnValue === undefined) {
      returnValue = true;
    }
    return returnValue;
  }

  formNotFilledOut() {
    switch(this.customerProductRelation.customerProductRelationType) {
      case CustomerProductRelationTypeEnum.EXPATRIATE_INSURANCE:
      case CustomerProductRelationTypeEnum.GROUP_INSURANCE:
      case CustomerProductRelationTypeEnum.BUSINESS_TRAVEL: {
          return !(this.customerProductRelation.companyPolicyHolder &&
            this.customerProductRelation.policyNo &&
            this.customerProductRelation.splitDateType &&
            this.customerProductRelation.validFrom);
      }
      case CustomerProductRelationTypeEnum.CREDIT_CARD: {
        return !(this.customerProductRelation.bank &&
          this.customerProductRelation.validFrom &&
          this.checkForSameValuesInNewCustomerProductRelation());
      }
      default: {
        return true;
      }
    }
  }
}
