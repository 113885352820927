import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-erp-data-page',
  templateUrl: './erp-data.component.html',
  styleUrls: ['./erp-data.component.scss']
})
export class ErpDataComponent implements OnInit {

  shownSection: string;

  ngOnInit() {
    this.shownSection = 'creditor';
  }
}
