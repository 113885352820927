import { Router } from '@angular/router';
import { BrandService } from '../../../../../../core/services/brand.service';
import { CustomerListView } from 'app/shared/models/customer-list-view';
import { Brand } from '../../../../../../shared/models/brand';
import { Kam } from '../../../../../../shared/models/kam';
import {
  Component,
  TemplateRef,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Dictionary } from 'app/shared/models/dictionary';
import { RoutesName } from 'app/app-routes-name';
import {OnepointModalService, Size} from '../../../../../../core/services/onepoint.modal.service';

@Component({
  selector: 'app-common-customer-new-brand',
  templateUrl: './common-customer-new-brand.component.html',
  styleUrls: ['./common-customer-new-brand.component.scss']
})
export class CommonCustomerNewBrandComponent {
  @ViewChild('content')
  private template: TemplateRef<any>;
  @Input() commonCustomerListView: CustomerListView;
  @Output() refreshBrands = new EventEmitter<Brand>();
  kams: Kam[];
  brand: Brand;
  isBrandValid: boolean;
  brandKAM: Dictionary[];
  public controls: UntypedFormControl;
  showInvalidIconMsg = false;

  constructor(
    private modalService: OnepointModalService,
    private brandService: BrandService,
    private router: Router
  ) {
    this.brand = new Brand();
  }

  get iconBlob() {
    return this.brand.iconBlob;
  }

  set iconBlob(iconBlob) {
    this.brand.iconBlob = iconBlob;
    this.showInvalidIconMsg = false;
    this.formChanged();
  }

  public open() {
    this.modalService.open(this.template, Size.NORMAL);
  }

  close() {
    this.modalService.dismissAll();
  }

  brandNameChanges(name: string) {
    this.brand.name = name;
    this.formChanged();
  }

  formChanged() {
    if (
      this.brand.name &&
      this.brand.keyAccountManagerUserId &&
      this.brand.iconBlob &&
      this.brand.name !== ''
    ) {
      this.isBrandValid = true;
    } else {
      this.isBrandValid = false;
    }
  }

  addNewBrand() {
    this.brand.commonCustomerId = this.commonCustomerListView.id;
    this.brandService.addBrand(this.brand).subscribe(
      result => {
        if ((result as any).errorCode === 'INVALID_ICON') {
          this.showInvalidIconMsg = true;
          return;
        }
        this.modalService.dismissAll();
        this.brand = result;
        this.refreshBrands.emit(this.brand);
        this.router.navigate([RoutesName.customers + '/' + RoutesName.brand, this.brand.id]);
      },
      error => {

        console.log(error);
      }
    );
  }
}

