import { Dictionary } from './../../models/dictionary';
import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropDownComponent {
  @Input() name: string;
  @Input() standardDropdownIcon: boolean;
  @Input() items: Dictionary[];
  @Input() selectedItemId = '';
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Output() selectedItemIdChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();
  @Input() placeholder: string;
  @Input() allowBlank = false;
  @Input() titleClass: string;
  oldSelectedItemId: string;

  onChange() {
    this.selectedItemIdChange.emit(this.selectedItemId);
  }

  onSaveModel() {
    if (this.oldSelectedItemId !== this.selectedItemId) {
      this.saveModel.emit();
    }
  }

  onSaveState() {
    this.oldSelectedItemId = this.selectedItemId;
  }
}
