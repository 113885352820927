import {Component, Input, OnInit} from '@angular/core';
import {DropDownComponent} from '../../../../../../shared/components/drop-down/drop-down.component';
import {AppliedProfile} from '../../../../../../shared/models/applied-profile';

@Component({
  selector: 'app-profile-drop-down',
  templateUrl: './profile-drop-down.component.html',
  styleUrls: ['./profile-drop-down.component.scss']
})
export class ProfileDropDownComponent extends DropDownComponent {
  @Input() appliedProfiles: AppliedProfile[];

  isDisabled(id: string): boolean {
    return this.appliedProfiles
      .filter(p => p.profileId === String(id))
      .filter(p => p.termination == null)
      .length > 0;
  }
}
