import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesName } from 'app/app-routes-name';
import { OnepointModalService, Size } from 'app/core/services/onepoint.modal.service';
import { SupplierProfileService } from 'app/core/services/supplier-profile.service';
import { SupplierProfile } from 'app/shared/models/supplier-profile';
import { Subscription } from 'rxjs';
import {ErpDataService} from "@onepoint/core/services/erp-data.service";
import {SimpleErpCreditor} from "@onepoint/shared/models/simple-erp-creditor";
import { EditSupplierProfileComponent } from '../edit-supplier-profile/edit-supplier-profile.component';

@Component({
  selector: 'app-new-supplier-profile',
  templateUrl: './new-supplier-profile.component.html',
  styleUrls: ['./new-supplier-profile.component.scss'],
})
export class NewSupplierProfileComponent implements OnInit {
  @ViewChild('content', { static: true }) template: TemplateRef<any>;
  allErpCreditors: SimpleErpCreditor[];
  supplierProfile: SupplierProfile;
  isValid = false;
  allSupplierProfiles: SupplierProfile[];
  subscription: Subscription;
  condition = false;

  constructor(private erpDataService: ErpDataService, private supplierProfileService: SupplierProfileService, private router: Router, private modalService: OnepointModalService) {}

  ngOnInit(): void {
    this.erpDataService.getAllSimpleCreditors().subscribe(erpCreditors => this.allErpCreditors = erpCreditors);
    this.clear();
  }

  clear() {
    this.supplierProfile = new SupplierProfile();
  }

  public open() {
    this.clear();
    this.modalService.openWithCustomWindowClass(this.template, Size.LARGE, 'modal-container');
    this.subscription = this.supplierProfileService.getAllSupplierProfiles().subscribe(result => (this.allSupplierProfiles = result));
  }

  handleChange(editSupplierComponent: EditSupplierProfileComponent) {
    this.isValid =
      this.supplierProfile.supplierProfileCode != null &&
      this.supplierProfile.supplierProfileCode.startsWith('SP') &&
      this.supplierProfile.supplierProfileName != null &&
      this.supplierProfile.providerId != null &&
      this.supplierProfile.startDate != null &&
      (this.supplierProfile.endDate == null || this.supplierProfile.endDate > this.supplierProfile.startDate) &&
      this.allSupplierProfiles.findIndex(s => s.supplierProfileCode === this.supplierProfile.supplierProfileCode) < 0 &&
      editSupplierComponent.validate();
  }

  close() {
    this.subscription.unsubscribe();
    this.subscription = null;
    this.modalService.dismissAll();
  }

  addNewSupplierProfile() {
    this.supplierProfileService.save(this.supplierProfile).subscribe(
      result => {
        this.supplierProfile = result;
        this.close();
        this.router.navigate([RoutesName.suppliers + '/', this.supplierProfile.id]);
      },
      error => {
        console.error(error);
      }
    );
  }
}
