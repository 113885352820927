import { Product } from './product';
import { CustomerTypeEnum } from './../enums/customerType.enum';
import { CustomerListView } from 'app/shared/models/customer-list-view';
import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';
import { Kam } from './kam';
import { KamService } from 'app/core/services/kam.service';
import {Regres} from "@onepoint/shared/models/regres";

export class Profile {
  id: string;
  code: string;
  name: string;
  market: string;
  businessAreasTravel = false;
  caseNumberPreFixTravel: string;
  businessAreasRoadside = false;
  caseNumberPreFixRoadside: string;
  businessAreasHealth = false;
  caseNumberPreFixHealth: string;
  brandId: string;
  products: Product[];
  active: boolean;
  keyAccountManagerUserId: string;
  keyAccountManagerFirstName: string;
  keyAccountManagerSurname: string;
  keyAccountManagerEmail: string;
  keyAccountManagerPhone: string;
  responsibleKamTravelUserId: string;
  responsibleKamTravelFirstName: string;
  responsibleKamTravelSurname: string;
  responsibleKamTravelEmail: string;
  responsibleKamTravelPhone: string;
  travelcareCaseInformation: string;
  travelcareInvoicing: string;
  responsibleKamRoadsideUserId: string;
  responsibleKamRoadsideFirstName: string;
  responsibleKamRoadsideSurname: string;
  responsibleKamRoadsideEmail: string;
  responsibleKamRoadsidePhone: string;
  mobilityCaseInformation: string;
  mobilityInvoicing: string;
  responsibleKamHealthUserId: string;
  responsibleKamHealthFirstName: string;
  responsibleKamHealthSurname: string;
  responsibleKamHealthEmail: string;
  responsibleKamHealthPhone: string;
  healthcareCaseInformation: string;
  healthcareInvoicing: string;
  contactInfo: string;
  remarks: string;
  groupProfileId: string;
  groupMemberIdForMapping: string;
  regres: Regres;

  public constructor(init?: Partial<Profile>) {
    Object.assign(this, init);
  }

  public isGroupProfile(): boolean {
    return !this.groupProfileId;
  }

  public updateKamInfo(kam: Kam) {
    this.keyAccountManagerUserId = kam.userId;
    this.keyAccountManagerFirstName = kam.firstName;
    this.keyAccountManagerSurname = kam.surname;
    this.keyAccountManagerEmail = kam.email;
    this.keyAccountManagerPhone = kam.phoneNumber;
  }

  public updateTravelcareKamInfo(kam: Kam) {
    this.responsibleKamTravelUserId = kam.userId;
    this.responsibleKamTravelFirstName = kam.firstName;
    this.responsibleKamTravelSurname = kam.surname;
    this.responsibleKamTravelEmail = kam.email;
    this.responsibleKamTravelPhone = kam.phoneNumber;
  }

  public updateMobilityKamInfo(kam: Kam) {
    this.responsibleKamRoadsideUserId = kam.userId;
    this.responsibleKamRoadsideFirstName = kam.firstName;
    this.responsibleKamRoadsideSurname = kam.surname;
    this.responsibleKamRoadsideEmail = kam.email;
    this.responsibleKamRoadsidePhone = kam.phoneNumber;
  }

  public updateHealthcareKamInfo(kam: Kam) {
    this.responsibleKamHealthUserId = kam.userId;
    this.responsibleKamHealthFirstName = kam.firstName;
    this.responsibleKamHealthSurname = kam.surname;
    this.responsibleKamHealthEmail = kam.email;
    this.responsibleKamHealthPhone = kam.phoneNumber;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProfileAdapter implements Adapter<Profile> {
  constructor(private kamService: KamService) {}
  adapt(item: any): Profile {
    const profile = new Profile({
      id: item.id,
      code: item.code,
      name: item.name,
      market: item.market,
      businessAreasTravel: item.businessAreasTravel,
      caseNumberPreFixTravel: item.caseNumberPreFixTravel,
      businessAreasRoadside: item.businessAreasRoadside,
      caseNumberPreFixRoadside: item.caseNumberPreFixRoadside,
      businessAreasHealth: item.businessAreasHealth,
      caseNumberPreFixHealth: item.caseNumberPreFixHealth,
      brandId: item.brandId,
      products: item.products,
      active: item.active,
      keyAccountManagerUserId: item.keyAccountManagerUserId,
      keyAccountManagerFirstName: item.keyAccountManagerFirstName,
      keyAccountManagerSurname: item.keyAccountManagerSurname,
      keyAccountManagerEmail: item.keyAccountManagerEmail,
      keyAccountManagerPhone: item.keyAccountManagerPhone,
      responsibleKamTravelUserId: item.responsibleKamTravelUserId,
      responsibleKamTravelFirstName: item.responsibleKamTravelFirstName,
      responsibleKamTravelSurname: item.responsibleKamTravelSurname,
      responsibleKamTravelEmail: item.responsibleKamTravelEmail,
      responsibleKamTravelPhone: item.responsibleKamTravelPhone,
      travelcareCaseInformation: item.travelcareCaseInformation,
      travelcareInvoicing: item.travelcareInvoicing,
      responsibleKamRoadsideUserId: item.responsibleKamRoadsideUserId,
      responsibleKamRoadsideFirstName: item.responsibleKamRoadsideFirstName,
      responsibleKamRoadsideSurname: item.responsibleKamRoadsideSurname,
      responsibleKamRoadsideEmail: item.responsibleKamRoadsideEmail,
      responsibleKamRoadsidePhone: item.responsibleKamRoadsidePhone,
      mobilityCaseInformation: item.mobilityCaseInformation,
      mobilityInvoicing: item.mobilityInvoicing,
      responsibleKamHealthUserId: item.responsibleKamHealthUserId,
      responsibleKamHealthFirstName: item.responsibleKamHealthFirstName,
      responsibleKamHealthSurname: item.responsibleKamHealthSurname,
      responsibleKamHealthEmail: item.responsibleKamHealthEmail,
      responsibleKamHealthPhone: item.responsibleKamHealthPhone,
      healthcareCaseInformation: item.healthcareCaseInformation,
      healthcareInvoicing: item.healthcareInvoicing,
      contactInfo: item.contactInfo,
      remarks: item.remarks,
      groupProfileId: item.groupProfileId,
      groupMemberIdForMapping: item.groupMemberIdForMapping,
      regres: item.regres
    });
    return profile;
  }
  adaptToCommonCustomerListView(item: any): CustomerListView {
    const customerListView = new CustomerListView({
      id: item.id,
      code: item.code,
      name: item.name,
      type: CustomerTypeEnum.profile,
      market: item.market,
      segment: item.segment,
      groupSegment: item.groupSegment,
      travelSegment: item.travelSegment,
      healthSegment: item.healthSegment,
      roadsideSegment: item.roadsideSegment,
      subBusinessRelation: item.subBusinessRelation,
      businessAreaCount:
        (item.travelDeptorId === '' ? 0 : 1) + (item.roadsideDeptorId === '' ? 0 : 1) + (item.healthDeptorId === '' ? 0 : 1),
      isTravel: item.businessAreasTravel,
      isRoadside: item.businessAreasRoadside,
      isHealth: item.businessAreasHealth,
      nextNegotationDate: !item.nextNegotationDate || item.nextNegotationDate === '' ? null : new Date(item.nextNegotationDate),
      sosOwner: item.sosOwner,
      parentId: item.brandId,
      integrationType: item.integrationType,
      caseNumberPreFixTravel: item.caseNumberPreFixTravel,
      caseNumberPreFixRoadside: item.caseNumberPreFixRoadside,
      caseNumberPreFixHealth: item.caseNumberPreFixHealth,
      children: item.products,
      active: item.active,
      keyAccountManagerUserId: item.keyAccountManagerUserId,
      keyAccountManagerFirstName: item.keyAccountManagerFirstName,
      keyAccountManagerSurname: item.keyAccountManagerSurname,
      keyAccountManagerEmail: item.keyAccountManagerEmail,
      keyAccountManagerPhone: item.keyAccountManagerPhone,
      responsibleKamTravelUserId: item.responsibleKamTravelUserId,
      responsibleKamTravelFirstName: item.responsibleKamTravelFirstName,
      responsibleKamTravelSurname: item.responsibleKamTravelSurname,
      responsibleKamTravelEmail: item.responsibleKamTravelEmail,
      responsibleKamTravelPhone: item.responsibleKamTravelPhone,
      travelcareCaseInformation: item.travelcareCaseInformation,
      travelcareInvoicing: item.travelcareInvoicing,
      responsibleKamRoadsideUserId: item.responsibleKamRoadsideUserId,
      responsibleKamRoadsideFirstName: item.responsibleKamRoadsideFirstName,
      responsibleKamRoadsideSurname: item.responsibleKamRoadsideSurname,
      responsibleKamRoadsideEmail: item.responsibleKamRoadsideEmail,
      responsibleKamRoadsidePhone: item.responsibleKamRoadsidePhone,
      mobilityCaseInformation: item.mobilityCaseInformation,
      mobilityInvoicing: item.mobilityInvoicing,
      responsibleKamHealthUserId: item.responsibleKamHealthUserId,
      responsibleKamHealthFirstName: item.responsibleKamHealthFirstName,
      responsibleKamHealthSurname: item.responsibleKamHealthSurname,
      responsibleKamHealthEmail: item.responsibleKamHealthEmail,
      responsibleKamHealthPhone: item.responsibleKamHealthPhone,
      healthcareCaseInformation: item.healthcareCaseInformation,
      healthcareInvoicing: item.healthcareInvoicing,
      contactInfo: item.contactInfo,
      remarks: item.remarks,
      groupProfileId: item.groupProfileId
    });
    return customerListView;
  }
}
