<div class="menu-container" *ngIf="showBreadcrumbs">
  <div class="menu-item" *ngIf="customersMenuItem">
    <a class="menu-item-link" [routerLink]="['/' + RoutesName.customers]" routerLinkActive="menu-item-link-active">{{
      customersMenuItem.name
    }}</a>
  </div>
  <div class="menu-item" *ngIf="commonCustomerMenuItem">
    <img src="/assets/icons/breadcrumb-arrow.svg" class="triangle-separator" />
  </div>
  <div class="menu-item" *ngIf="commonCustomerMenuItem">
    <a
      class="menu-item-link"
      [routerLink]="['/' + RoutesName.customers + '/' + RoutesName.commonCustomers, commonCustomerMenuItem.id]"
      routerLinkActive="menu-item-link-active"
    >
      <div>{{ commonCustomerMenuItem.name }}</div>
      <div class="link-description">Common Customer</div>
    </a>
  </div>
  <div class="menu-item" *ngIf="brandMenuItem">
    <img src="/assets/icons/breadcrumb-arrow.svg" class="triangle-separator" />
  </div>
  <div class="menu-item" *ngIf="brandMenuItem">
    <a
      class="menu-item-link"
      [routerLink]="['/' + RoutesName.customers + '/' + RoutesName.brand, brandMenuItem.id]"
      routerLinkActive="menu-item-link-active"
    >
      <div>{{ brandMenuItem.name }}</div>
      <div class="link-description">Brand</div>
    </a>
  </div>
  <div class="menu-item" *ngIf="profileMenuItem">
    <img src="/assets/icons/breadcrumb-arrow.svg" class="triangle-separator" />
  </div>
  <div class="menu-item" *ngIf="profileMenuItem">
    <a
      class="menu-item-link"
      [routerLink]="['/' + RoutesName.customers + '/' + RoutesName.profile, profileMenuItem.id]"
      routerLinkActive="menu-item-link-active"
    >
      <div>{{ profileMenuItem.name }}</div>
      <div class="link-description">Profile</div>
    </a>
  </div>
  <div class="menu-item" *ngIf="productMenuItem">
    <img src="/assets/icons/breadcrumb-arrow.svg" class="triangle-separator" />
  </div>
  <div class="menu-item" *ngIf="productMenuItem">
    <a
      class="menu-item-link"
      [routerLink]="['/' + RoutesName.customers + '/' + RoutesName.product, productMenuItem.id]"
      routerLinkActive="menu-item-link-active"
    >
      <div>{{ productMenuItem.name }}</div>
      <div class="link-description">Product</div>
    </a>
  </div>
  <div class="menu-item" *ngIf="sosServicesMenuItem">
    <a class="menu-item-link" [routerLink]="['/' + RoutesName.sosServices]" routerLinkActive="menu-item-link-active">{{
      sosServicesMenuItem.name
    }}</a>
  </div>
  <div class="menu-item" *ngIf="sosServiceMenuItem">
    <div class="menu-item">
      <img src="/assets/icons/breadcrumb-arrow.svg" class="triangle-separator" />
    </div>
  </div>
  <div class="menu-item" *ngIf="sosServiceMenuItem">
    <a class="menu-item-link" [routerLink]="['/' + RoutesName.sosServices, sosServiceMenuItem.id]" routerLinkActive="menu-item-link-active">
      <div>{{ sosServiceMenuItem.name }}</div>
      <div class="link-description">Sos services</div>
    </a>
  </div>
  <div class="menu-item" *ngIf="masterListsMenuItem">
    <a class="menu-item-link" [routerLink]="['/' + RoutesName.masterLists]" routerLinkActive="menu-item-link-active">{{
      masterListsMenuItem.name
    }}</a>
  </div>
  <div class="menu-item" *ngIf="masterListMenuItem">
    <div class="menu-item">
      <img src="/assets/icons/breadcrumb-arrow.svg" class="triangle-separator" />
    </div>
  </div>
  <div class="menu-item" *ngIf="masterListMenuItem">
    <a class="menu-item-link" [routerLink]="['/' + RoutesName.masterLists, masterListMenuItem.id]" routerLinkActive="menu-item-link-active">
      <div>{{ masterListMenuItem.name }}</div>
      <div class="link-description">Master lists</div>
    </a>
  </div>
  <div class="menu-item" *ngIf="masterListCausesLevel1MenuItem">
    <div class="menu-item">
      <img src="/assets/icons/breadcrumb-arrow.svg" class="triangle-separator" />
    </div>
  </div>
  <div class="menu-item" *ngIf="masterListCausesLevel1MenuItem">
    <a
      class="menu-item-link"
      [routerLink]="['/' + RoutesName.masterLists, masterListMenuItem.id, masterListCausesLevel1MenuItem.id]"
      [state]="{ level1: masterListCausesLevel1MenuItem.name }"
      routerLinkActive="menu-item-link-active"
    >
      <div>{{ masterListCausesLevel1MenuItem.name }}</div>
      <div class="link-description">Causes level1</div>
    </a>
  </div>
  <div class="menu-item" *ngIf="masterListCausesLevel2MenuItem">
    <div class="menu-item">
      <img src="/assets/icons/breadcrumb-arrow.svg" class="triangle-separator" />
    </div>
  </div>
  <div class="menu-item" *ngIf="masterListCausesLevel2MenuItem">
    <a
      class="menu-item-link"
      [routerLink]="[
        '/' + RoutesName.masterLists,
        masterListMenuItem.id,
        masterListCausesLevel1MenuItem.id,
        masterListCausesLevel2MenuItem.id
      ]"
      [state]="{ level1: masterListCausesLevel1MenuItem.name, level2: masterListCausesLevel2MenuItem.name }"
      routerLinkActive="menu-item-link-active"
    >
      <div>{{ masterListCausesLevel2MenuItem.name }}</div>
      <div class="link-description">Causes level2</div>
    </a>
  </div>
  <div class="menu-item" *ngIf="supplierProfilesMenuItem">
    <a class="menu-item-link" [routerLink]="['/' + RoutesName.suppliers]" routerLinkActive="menu-item-link-active">{{
      supplierProfilesMenuItem.name
    }}</a>
  </div>
  <div class="menu-item" *ngIf="supplierProfileMenuItem">
    <div class="menu-item">
      <img src="/assets/icons/breadcrumb-arrow.svg" class="triangle-separator" />
    </div>
  </div>
  <div class="menu-item" *ngIf="supplierProfileMenuItem">
    <a
      class="menu-item-link"
      [routerLink]="['/' + RoutesName.suppliers, supplierProfileMenuItem.id]"
      routerLinkActive="menu-item-link-active"
    >
      <div>{{ supplierProfileMenuItem.name }}</div>
      <div class="link-description">Supplier profile</div>
    </a>
  </div>
  <div class="menu-item" *ngIf="digitalCaseflowMenuItem">
    <a class="menu-item-link" [routerLink]="['/' + RoutesName.dcf]" routerLinkActive="menu-item-link-active">{{
      digitalCaseflowMenuItem.name
      }}</a>
  </div>
  <div class="menu-item" *ngIf="digitalCaseflowMenuConfigurationItem">
    <div class="menu-item">
      <img src="/assets/icons/breadcrumb-arrow.svg" class="triangle-separator" />
    </div>
  </div>
  <div class="menu-item" *ngIf="digitalCaseflowMenuConfigurationItem">
    <a
      class="menu-item-link"
      [routerLink]="['/' + RoutesName.dcf + '/' + RoutesName.dcfCustomerConfig, digitalCaseflowMenuConfigurationItem.id]"
      routerLinkActive="menu-item-link-active"
    >
      <div>{{ digitalCaseflowMenuConfigurationItem.name }}</div>
      <div class="link-description">Customer configuration</div>
    </a>
  </div>
</div>
