<div class="service-type-modal-container">
  <div class="closing-x" (click)="closeModal()">
    <img src="/assets/icons/Close.svg" />
  </div>
  <div class="header">
    <span *ngIf="isNew">Create new Commitment Type</span>
    <span *ngIf="!isNew">Edit Commitment Type</span>
  </div>
  <div class="row body-container" *ngIf="masterListType">
    <div class="col-3">
      <div class="input-name">
        Activate on
        <span *ngIf="!isReadonly" class="required">*</span>
      </div>
      <app-input-calendar
        [withImage]="true"
        [selectMode]="'single'"
        [disabled]="isReadonly"
        [(dateTimeRange)]="masterListType.activateOn"
        [maxDate]="masterListType.deactivateOn"
        (saveModel)="activateOnChanged(masterListType)"
      >
      </app-input-calendar>
      <div class="input-name">Deactivate after</div>
      <app-input-calendar
        [withImage]="true"
        [selectMode]="'single'"
        [disabled]="isReadonly"
        [(dateTimeRange)]="masterListType.deactivateOn"
        [minDate]="masterListType.activateOn"
        (saveModel)="deactivateOnChanged(masterListType)"
      >
      </app-input-calendar>
      <div class="label" style="font-size: 14px;
      color: #787878;" [ngClass]="'input-name'">Based on case creation time</div>
      <div class="padding-top">
        <app-input [name]="'Sort in UI'" [(value)]="masterListType.uiSortIndex" (valueChange)="modelChanged()"
          [disabled]="isReadonly"></app-input>
      </div>
      <div class="padding-top">
        <app-drop-down-multiselect name="Service type" [required]="false" [dropdownItemList]="serviceTypesDictionary"
          [(selectedItems)]="selectedServiceTypes" (selectedItemsChange)="selectedServiceTypeChanged($event)">
        </app-drop-down-multiselect>
      </div>
      <div class="padding-top">
        <app-drop-down name="Service category"
                       [(selectedItemId)]="masterListType.servicesCategory"
                       [items]="sosServicesCategoryList"
                       (selectedItemIdChange)="modelChanged()"
                       [required]="false"
                       titleClass="name-large"
                       [disabled]="isReadonly">
        </app-drop-down>
      </div>
    </div>
    <div class="col-9">
      <div class="row">
        <div class="col-6">
          <div>
            <app-input
              [name]="'Business key'"
              [(value)]="masterListType.businessKey"
              (valueChange)="modelChanged()"
              [disabled]="isReadonly || masterListType.id"
              [required]="!isReadonly"
            ></app-input>
          </div>
          <div class="padding-top">
            <app-input
              [name]="'Name'"
              [(value)]="masterListType.name"
              (valueChange)="modelChanged()"
              [disabled]="isReadonly"
              [required]="!isReadonly"
            ></app-input>
          </div>
          <div class="padding-top">
            <div class="field-title">Description</div>
            <textarea [(ngModel)]="masterListType.description" (ngModelChange)="modelChanged()" [disabled]="isReadonly"></textarea>
          </div>
          <div class="padding-top">
            <div class="field-title">Hover text</div>
            <textarea class="hover-text" [(ngModel)]="masterListType.hoverText" (ngModelChange)="modelChanged()" [disabled]="isReadonly"></textarea>
          </div>
        </div>
        <div class="col-6">

          <div class="padding-top">
            <div class="field-title">Supplier Invoice</div>
            <input
              type="checkbox"
              name="alwaysDirectToTeam"
              [(ngModel)]="masterListType.supplierInvoice"
              class="sos-services-checkbox"
              [disabled]="readonly"
              (change)="modelChanged()"
            />
          </div>
          <div class="padding-top">
            <div class="field-title">Refund</div>
            <input
              type="checkbox"
              name="teamBeforeProfile"
              [(ngModel)]="masterListType.refund"
              class="sos-services-checkbox"
              [disabled]="readonly"
              (change)="modelChanged()"
            />
          </div>
          <div class="padding-top">
            <div class="field-title">Handling area Cost Control</div>

            <input
              type="checkbox"
              name="alwaysDirectToTeam"
              [(ngModel)]="masterListType.handlingAreaCostControl"
              class="sos-services-checkbox"
              [disabled]="readonly"
              (change)="modelChanged()"
            />
          </div>
          <div class="padding-top">
            <div class="field-title">Handling Area Claims</div>
            <input
              type="checkbox"
              name="teamBeforeProfile"
              [(ngModel)]="masterListType.handlingAreaClaims"
              class="sos-services-checkbox"
              [disabled]="readonly"
              (change)="modelChanged()"
            />
          </div>

          <div class="padding-top">
            <div class="field-title">Exclude from EUS</div>
            <input
              type="checkbox"
              name="teamBeforeProfile"
              [(ngModel)]="masterListType.excludeFromEUS"
              class="sos-services-checkbox"
              [disabled]="readonly"
              (change)="modelChanged()"
            />
          </div>
          <div class="padding-top">
            <div class="field-title">GOP</div>
            <input
              type="checkbox"
              name="teamBeforeProfile"
              [(ngModel)]="masterListType.gop"
              class="sos-services-checkbox"
              [disabled]="readonly"
              (change)="modelChanged()"
            />
          </div>
          <div class="padding-top">
            <div class="field-title">SOS claims</div>
            <input
              type="checkbox"
              name="teamBeforeProfile"
              [(ngModel)]="masterListType.sosClaims"
              class="sos-services-checkbox"
              [disabled]="readonly"
              (change)="modelChanged()"
            />
          </div>
          <div class="padding-top">
            <div class="field-title">Recovery</div>
            <input
              type="checkbox"
              name="recovery"
              [(ngModel)]="masterListType.recovery"
              [disabled]="readonly"
              (change)="modelChanged()"
            />
          </div>
        </div>
      </div>
      <div class="row body-container" *ngIf="error">
        <div class="col-12 error">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
  <div class="masterlist-modal-footer d-flex justify-content-between">
    <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE" class="">
      <button class="primary-button save-button" [class.button-disabled]="!isSaveButtonEnabled" (click)="save()">Save</button>
    </div>
    <app-last-changed [masterListItem]="masterListType" class="last-changed float-end"> </app-last-changed>
  </div>
</div>
