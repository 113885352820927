<app-environment-ribbon></app-environment-ribbon>
<div class="layout-container">
  <div class="left-menu">
    <app-left-menu></app-left-menu>
  </div>
  <div class="content">
    <div class="top-bar">
      <app-top-bar></app-top-bar>
      <app-sign-out></app-sign-out>
    </div>
    <div class="router-outlet">
        <router-outlet></router-outlet>
    </div>
  </div>
  <app-reload-footer></app-reload-footer>
</div>
