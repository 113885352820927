<div class="page-container">
    <div class="page-header header">
        <button class="primary-button button" (click)="createFlow()">Create new flow</button>
    </div>
  <table class="primary-table sos-services-table">
    <thead>
      <tr>
        <td class="unsortable-column">
          Name
        </td>
        <td class="unsortable-column">
          Customer
        </td>
        <td class="unsortable-column">
          Channel
        </td>
        <td class="unsortable-column">
          Start types
        </td>
        <td class="unsortable-column">
          Active
        </td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let customerConfiguration of customerConfigurations" (click)="navigate(customerConfiguration)">
        <td>
          {{customerConfiguration.flow.name}}
        </td>
        <td>
          {{getCustomerProfileName(customerConfiguration.customerProfileId)}}
        </td>
        <td>
          {{customerConfiguration.channel}}
        </td>
        <td>
          {{customerConfiguration.flow.startTypes.join(', ')}}
        </td>
        <td>
          {{customerConfiguration.enabled ? 'Activated' : 'Disabled'}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<app-create-customer-config #createCustomerConfigComponent (updateCallback)="navigate($event)">
</app-create-customer-config>
