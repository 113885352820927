import {Component, OnInit, ViewChild} from '@angular/core';
import {AddSlaCalculatedEventComponent} from "./add-sla-calculated-event/add-sla-calculated-event.component";
import {SlaCalculatedEvent} from "@onepoint/shared/models/sla-calculated-event";
import {SlaCalculatedEventService} from "@onepoint/core/services/sla-calculated-event.service";

@Component({
  selector: 'app-sla-calculated-event',
  templateUrl: './sla-calculated-event.component.html',
  styleUrls: ['./sla-calculated-event.component.scss']
})
export class SlaCalculatedEventComponent implements OnInit {
  @ViewChild('modal') modal: AddSlaCalculatedEventComponent;
  slaCalculatedEvents: SlaCalculatedEvent[];
  slaCalculatedEventTypes: string[];
  constructor( private slaCalculatedEventService : SlaCalculatedEventService) {
  }

  ngOnInit() {
    this.reload()
  }

  reload(){
    this.slaCalculatedEventService.getSlaCalculatedEventTypes().subscribe( result =>{
      this.slaCalculatedEventTypes = result;
    });

    this.slaCalculatedEventService.getSlaCalculatedEvents().subscribe(result =>{
      this.slaCalculatedEvents = result;
    });
  }

  addNewSlaCalculatedEvent(){
    this.modal.openNew(this.slaCalculatedEventTypes);
  }

  showExistingSlaCalculatedEvent(eventId: number){
    this.modal.openExisting(this.slaCalculatedEvents.find(event => event.id === eventId), this.slaCalculatedEventTypes);
  }
}
