<ng-template #content let-modal>
  <div class="onepoint-modal-container">
    <div class="onepoint-modal-header">
      <div class="onepoint-modal-header-title">
        <div *ngIf="slaRule.id == null">
          Add new SLA exception<br/>
        </div>
        <div *ngIf="slaRule.id != null">
          Update SLA exception<br/>
        </div>
      </div>
      <div class="onepoint-modal-header-close" (click)="close();"><img src="assets/icons/Close.svg"></div>
    </div>
    <div class="onepoint-modal-body p-3">
      <div class="input-title-name" (focusout)="isValid()">
        SLA exception name
      </div>
      <app-input [(value)]="slaRule.name" (focusOut)="isValid()"></app-input>
      <div class="input-title-name">
        Type
      </div>
      <app-drop-down class="dropdown" [items]="ruleTypes" [(selectedItemId)]="slaRule.type" (selectedItemIdChange)="isValid()"></app-drop-down>

      <div *ngIf="slaRule.type === 'START_EVENT_CALCULATED_FROM_CASE'">
        <div class="input-title-name">
          Calculation Type
        </div>
        <app-drop-down class="dropdown" [items]="calculatedTypes" [(selectedItemId)]="slaRule.calculatedEventType" (selectedItemIdChange)="isValid()"></app-drop-down>
      </div>

      <div *ngIf="slaRule.type === 'IS_CORRECT_CASE_TYPE'">
        <div class="input-title-name">
          Case Type
        </div>
        <app-drop-down class="dropdown" [items]="caseTypes" [(selectedItemId)]="slaRule.slaCaseType" (selectedItemIdChange)="isValid()"></app-drop-down>
      </div>

      <div *ngIf="slaRule.type === 'GRACE_PERIOD' || slaRule.type === 'GRACE_PERIOD_LOCAL'">
        <div class="input-title-name">
          Grace period start
        </div>
        <app-input [(value)]="slaRule.gracePeriodStart"
                   id="gracePeriodStart" type="time" (focusout)="isValid()"></app-input>

        <div class="input-title-name">
          Grace period end
        </div>
        <app-input [(value)]="slaRule.gracePeriodEnd" id="gracePeriodEnd" type="time" (focusout)="isValid()"></app-input>

      </div>
      <div class="onepoint-modal-footer">
        <button
          class="primary-button save-btn"
          (click)="save()"
          [ngClass]="!valid && 'button-disabled'"
          [disabled]="!valid"
        >
          <div *ngIf="slaRule.id == null">
            Add<br/>
          </div>
          <div *ngIf="slaRule.id != null">
            Save<br/>
          </div>
        </button>
      </div>
    </div>
  </div>
</ng-template>
