export enum CoveragePeriodUnitEnum {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS'
}

export enum LimitPeriodUnitEnum {
  DAYS= 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS'
}
