<div>
  <div class="document-box d-flex align-items-center" *ngIf="product">
    <div *ngIf="product.termsDocumentId" class="document-icon" (click)="openTermsDocument()">
      <div class="document-image-label label-terms">TERMS</div>
      <img class="document-image" src="assets/icons/terms.svg" alt="Link to terms document">
    </div>
    <div *ngIf="!product.termsDocumentId" class="document-no-image"></div>

    <div class="me-2" style="min-width: 0">
      <div *ngIf="product.termsName" class="document-name">{{ product.termsName }}</div>
      <div *ngIf="!product.termsName" class="document-name">No terms configured</div>
    </div>

    <div class="d-flex ms-auto">
      <span *permission='PermissionEnum.CUSTOMER_COVERAGES_LIMITS_RELATION_DELETE'>
        <button *ngIf="product.termsDocumentId && !product.groupProfileProductId" class="primary-button-outline-red" (click)="clearTerms()">Delete</button>
      </span>
      <span *permission='PermissionEnum.CUSTOMER_COVERAGES_LIMITS_RELATION_UPDATE'>
        <button *ngIf="!product.groupProfileProductId" class="primary-button-outline ms-2" [class.button-disabled]="isChildProduct(product)"
              (click)="editTerms()">Edit
        </button>
      </span>
    </div>
  </div>
  <app-edit-terms [product]="product" (updated)="getProduct()" #editTermsComponent></app-edit-terms>
</div>

<div>
  <div class="document-box d-flex align-items-center" *ngIf="product">
      <div *ngIf="product.guidelineUri" class="document-icon" (click)="openGuidelineDocument(product.guidelineUri)">
        <div class="document-image-label label-guide">GUIDE</div>
        <img class="document-image" src="assets/icons/guide.svg" alt="Link to terms document">
      </div>
      <div *ngIf="!product.guidelineUri" class="document-no-image"></div>

      <div class="me-2" style="min-width: 0">
        <div *ngIf="product.guidelineName" class="document-name">{{ product.guidelineName }}</div>
        <div *ngIf="!product.guidelineName" class="document-name">No guide configured</div>
      </div>

      <div class="d-flex ms-auto">
        <span *permission='PermissionEnum.CUSTOMER_COVERAGES_LIMITS_RELATION_DELETE'>
          <button *ngIf="product.guidelineUri && !product.groupProfileProductId" class="primary-button-outline-red" (click)="clearGuidelines()">Delete</button>
        </span>
        <span *permission='PermissionEnum.CUSTOMER_COVERAGES_LIMITS_RELATION_UPDATE'>
          <button *ngIf="!product.groupProfileProductId" class="primary-button-outline ms-2" [class.button-disabled]="isChildProduct(product)"
                (click)="editGuide()">Edit
          </button>
        </span>
      </div>
  </div>
  <app-edit-guide [product]="product" (updated)="getProduct()" (openGuidelineFile)="openGuidelineDocument($event)" #editGuideComponent></app-edit-guide>
</div>
