import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { PriceAgreementCategoryService } from 'app/shared/models/price-agreement';
import { SosServices } from 'app/shared/models/sos-services';
import { SosServicesCategoryEnum } from 'app/shared/models/enums';

@Component({
  selector: 'app-services-general',
  templateUrl: './services-general.component.html',
  styleUrls: ['./services-general.component.scss'],
})
export class ServicesGeneralComponent {
  @Output() dataChange = new EventEmitter();
  @Input() categoryId;
  @Input() priceAgreementService: PriceAgreementCategoryService;
  @Input() sosServices: SosServices;
  @Input() categoryIncluded: boolean;
  @Input() suspended: boolean;
  @Input() editable: boolean;
  @Input() manualIndex: boolean;
  @Input() options: any;
  @Input() showType: string;

  SosServicesCategoryEnum = SosServicesCategoryEnum;
  percentageOptions = { prefix: '', allowNegative: false, suffix: ' %', max: 100 };

  priceInputDisabled(): boolean {
    return (!this.editable && !this.manualIndex) || this.suspendedOrFixedPrice();
  }

  suspendedOrFixedPrice(): boolean {
    const categoryEnum = SosServicesCategoryEnum[this.categoryId];
    return (
      this.suspended ||
      categoryEnum == SosServicesCategoryEnum.ADDITIONAL_MEDICAL_SERVICES ||
      categoryEnum == SosServicesCategoryEnum.ADDITIONAL_MEDICAL_SERVICES_ESCORT
    );
  }

  setIncluded(included: boolean) {
    this.priceAgreementService.included = included;
    this.dataChange.emit();
  }
}
