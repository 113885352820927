import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { OnepointModalService, Size } from 'app/core/services/onepoint.modal.service';
import { Dictionary } from '@onepoint/shared/models/dictionary';
import { MasterListIncidentEventModel } from '@onepoint/shared/models/masterlist-incident-event-model';
import { MasterListService } from 'app/core/services/masterlist.service';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import * as _ from 'lodash';
import { IncidentEventMenuType } from './incident-event-menu/masterlist-incident-event-menu-type';
import { EventCategory } from '@onepoint/shared/models/enums';
import { HelperService } from '@onepoint/core/services/helper.service';

@Component({
  selector: 'app-masterlist-incident-events',
  templateUrl: './masterlist-incident-events.component.html',
  styleUrls: ['./masterlist-incident-events.component.scss']
})
export class MasterlistIncidentEventsComponent implements OnInit {
  @ViewChild('MasterListModal')
  private masterListModalTemplate: TemplateRef<any>;

  masterList: MasterListIncidentEventModel[];
  masterListFiltered: MasterListIncidentEventModel[];
  masterListType: MasterListIncidentEventModel;
  showMenu = new Map<IncidentEventMenuType, boolean>();

  statusTypes: Dictionary[] = [
    { id: 'all', name: 'All' },
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
  ];
  selectedStatus = 'all';

  constructor(private masterListService: MasterListService,
              private onepointModalService: OnepointModalService,
              private helperService: HelperService) {}

  ngOnInit() {
    this.loadMasterList();
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  get IncidentEventMenuType() {
    return IncidentEventMenuType;
  }

  addNew(): void {
    this.masterListType = new MasterListIncidentEventModel(({category: 'OTHER_EVENT'}));
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.LARGE, 'reduction-modal-container');
  }

  edit(item: MasterListIncidentEventModel) {
    this.masterListType = _.clone(item);
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.LARGE, 'reduction-modal-container');
  }

  getEnumValue(eventCategory: EventCategory) {
    return Object.values(EventCategory)[Object.keys(EventCategory).indexOf(eventCategory)];
  }

  private loadMasterList() {
    this.masterListService.getIncidentEventList().subscribe(result => {
      this.masterList = result;
      this.masterListFiltered = result;
      this.helperService.sortArray(this.masterListFiltered, IncidentEventMenuType.DATE_OF_EVENT, false, true);
    });
  }

  selectedStatusTypeChanged(status: string) {
    this.masterListFiltered = this.filterByStatus(status);
  }

  private filterByStatus(status: string): MasterListIncidentEventModel[] {
    if (status === 'all') {
      return this.masterList;
    }
    return this.masterList.filter(item => (status === 'active' ? item.active : !item.active));
  }




  showFilteringSortingMenu(filteringSortingMenuType: IncidentEventMenuType) {
    this.showMenu.forEach((value, key) => {
      if (key !== filteringSortingMenuType) {
        this.showMenu[key] = false;
      }
    });

    this.showMenu[filteringSortingMenuType] = !this.showMenu[filteringSortingMenuType];
  }

  reload() {
    this.loadMasterList();
  }
}
