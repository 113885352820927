<div class="document-template-table-container" *ngIf="filteredTemplates">
  <table class="document-template-table">
    <thead>
      <tr (clickOutside)="closeFilteringSortingMenu()">
        <td (click)="showFilteringSortingMenu(FilteringSortingMenuType.documentType)" class="filter-column">
          <div [ngStyle]="!showFilteringMenu[FilteringSortingMenuType.documentType] && {'display':'none'}">
            <app-template-filtering-sorting-menu (modelChanged)="finishedAndFilteringSorting()"
                                                 [filteringSortingMenuType]="FilteringSortingMenuType.documentType" (clearAllFilters)="clearAllFiltering()"
                                                 [(documentTemplateList)]="filteredTemplates"
                                                 (documentTemplateListChange)="onListChange($event)"></app-template-filtering-sorting-menu>
          </div>
          Category
        </td>
        <td (click)="showFilteringSortingMenu(FilteringSortingMenuType.receiverType)" class="filter-column">
          <div [ngStyle]="!showFilteringMenu[FilteringSortingMenuType.receiverType] && {'display':'none'}">
            <app-template-filtering-sorting-menu (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="FilteringSortingMenuType.receiverType" (clearAllFilters)="clearAllFiltering()"
              [(documentTemplateList)]="filteredTemplates"
              (documentTemplateListChange)="onListChange($event)"></app-template-filtering-sorting-menu>
          </div>
          Receiver
        </td>
        <td (click)="showFilteringSortingMenu(FilteringSortingMenuType.serviceType)" class="filter-column">
          <div [ngStyle]="!showFilteringMenu[FilteringSortingMenuType.serviceType] && {'display':'none'}">
            <app-template-filtering-sorting-menu (modelChanged)="finishedAndFilteringSorting()"
                                                 [filteringSortingMenuType]="FilteringSortingMenuType.serviceType" (clearAllFilters)="clearAllFiltering()"
                                                 [(documentTemplateList)]="filteredTemplates"
                                                 (documentTemplateListChange)="onListChange($event)"></app-template-filtering-sorting-menu>
          </div>
          Service type
        </td>
        <td (click)="showFilteringSortingMenu(FilteringSortingMenuType.templateType)" class="filter-column">
          <div [ngStyle]="!showFilteringMenu[FilteringSortingMenuType.templateType] && {'display':'none'}">
            <app-template-filtering-sorting-menu (modelChanged)="finishedAndFilteringSorting()"
                                                 [filteringSortingMenuType]="FilteringSortingMenuType.templateType" (clearAllFilters)="clearAllFiltering()"
                                                 [documentTemplateList]="filteredTemplates"
                                                 (documentTemplateListChange)="onListChange($event)"></app-template-filtering-sorting-menu>
          </div>
          Template Type
        </td>
        <td (click)="showFilteringSortingMenu(FilteringSortingMenuType.tags)" class="filter-column">
          <div [ngStyle]="!showFilteringMenu[FilteringSortingMenuType.tags] && {'display':'none'}">
            <app-template-filtering-sorting-menu (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="FilteringSortingMenuType.tags" (clearAllFilters)="clearAllFiltering()"
              [(documentTemplateList)]="filteredTemplates"
              (documentTemplateListChange)="onListChange($event)"></app-template-filtering-sorting-menu>
          </div>
          Tags
        </td>
        <td (click)="showFilteringSortingMenu(FilteringSortingMenuType.displayName)" class="filter-column">
          <div [ngStyle]="!showFilteringMenu[FilteringSortingMenuType.displayName] && {'display':'none'}">
            <app-template-filtering-sorting-menu (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="FilteringSortingMenuType.displayName" (clearAllFilters)="clearAllFiltering()"
              [(documentTemplateList)]="filteredTemplates"
              (documentTemplateListChange)="onListChange($event)"></app-template-filtering-sorting-menu>
          </div>
          Display name
        </td>
        <td (click)="showFilteringSortingMenu(FilteringSortingMenuType.fileName)" class="filter-column">
          <div [ngStyle]="!showFilteringMenu[FilteringSortingMenuType.fileName] && {'display':'none'}">
            <app-template-filtering-sorting-menu (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="FilteringSortingMenuType.fileName" (clearAllFilters)="clearAllFiltering()"
              [(documentTemplateList)]="filteredTemplates"
              (documentTemplateListChange)="onListChange($event)"></app-template-filtering-sorting-menu>
          </div>
          File name
        </td>
        <td (click)="showFilteringSortingMenu(FilteringSortingMenuType.language)" class="filter-column align-right">
          <div [ngStyle]="!showFilteringMenu[FilteringSortingMenuType.language] && {'display':'none'}">
            <app-template-filtering-sorting-menu (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="FilteringSortingMenuType.language" (clearAllFilters)="clearAllFiltering()"
              [(documentTemplateList)]="filteredTemplates"
              (documentTemplateListChange)="onListChange($event)"></app-template-filtering-sorting-menu>
          </div>
          Language
        </td>
        <td (click)="showFilteringSortingMenu(FilteringSortingMenuType.subject)" class="filter-column align-right">
          <div [ngStyle]="!showFilteringMenu[FilteringSortingMenuType.subject] && {'display':'none'}">
            <app-template-filtering-sorting-menu (modelChanged)="finishedAndFilteringSorting()"
                                                 [filteringSortingMenuType]="FilteringSortingMenuType.subject" (clearAllFilters)="clearAllFiltering()"
                                                 [(documentTemplateList)]="filteredTemplates"
                                                 (documentTemplateListChange)="onListChange($event)"></app-template-filtering-sorting-menu>
          </div>
          Subject
        </td>
      </tr>
    </thead>
    <tbody>
      <ng-template ngFor let-template [ngForOf]="filteredTemplates">
        <tr>
          <td>{{template.documentType}}</td>
          <td>{{template.receiverType}}</td>
          <td>{{template.serviceType}}</td>
          <td>{{template.templateType}}</td>
          <td>
            <ng-template ngFor let-tag [ngForOf]="template.tags">
              {{tag}}<br/>
            </ng-template>
          </td>
          <td>{{template.name}}</td>
          <td>{{template.path}}{{template.fileName}}</td>
          <td>{{template.languageCode}}</td>
          <td>{{template.subject}}</td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>
