import {ApplicationRef, Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Product} from '../../../../../shared/models/product';
import {Subscription} from 'rxjs';
import {ProductService} from '../../../../../core/services/product.service';
import {ProductsFilteringSortingMenuTypeEnum} from '../../../product-page/product-filtering-sorting-menu/products-filtering-sorting-menu-type-enum';
import {ProductUpdateService} from '../../../../../core/services/product-update.service';
import {DictionaryService} from '../../../../../core/services/dictionary.service';
import {BusinessAreaEnum} from '../../../../../shared/models/enums';
import {Router} from '@angular/router';
import {RoutesName} from '../../../../../app-routes-name';
import {ProfileProductsAddComponent} from '../../../product-page/profile-products-add/profile-products-add.component';
import {AutoUnsubscribe} from '../../../../../shared/decorators/auto-unsubscribe';
import {InsuranceTypeEnum} from '../../../../../shared/enums/insurance-type-enum';
import {ProductStatusEnum} from '../../../../../shared/enums/product-status-enum';
import {Dictionary} from '../../../../../shared/models/dictionary';
import {ProductDebtorOverrule} from "../../../../../shared/models/product-debtor-overrule";
import { PermissionEnum } from '@onepoint/shared/models/permission.enum';

@Component({
  selector: 'app-profile-products',
  templateUrl: './profile-products.component.html',
  styleUrls: ['./profile-products.component.scss'],
  encapsulation: ViewEncapsulation.None
})

@AutoUnsubscribe
export class ProfileProductsComponent implements OnInit {
  @Input() set profileId(profileId: string) {
    this._profileId = profileId;
    this.loadProducts();
  }

  get profileId() {
    return this._profileId;
  }

  private _profileId: string;

  @Input() set groupProfileId(groupProfileId: string) {
    this._groupProfileId = groupProfileId;
  }

  get groupProfileId() {
    return this._groupProfileId;
  }

  private _groupProfileId: string;

  @ViewChild('profileProductsAddComponent') profileProductsAddComponent: ProfileProductsAddComponent;

  productsList: Product[];
  filteredProductsList: Product[];
  searchResultProductsList: Product[];
  productDebtorOverruleList: ProductDebtorOverrule[];
  subscription: Subscription;

  numberOfProducts: number;
  statusFilterValue: ProductStatusEnum;
  searchText: string;

  showAddMenu: boolean;
  showFilterMenu: boolean[] = [];
  showActionMenu: boolean;
  actionMenuIndex: number;
  copyCoveragesAndLimitsInProgress: boolean;
  copyFromProductSelected: boolean;
  copyFromProduct: Product;
  productsWithCoverages: number[] = [];

  constructor(private productService: ProductService,
              private dictionaryService: DictionaryService,
              private productUpdateService: ProductUpdateService,
              private router: Router,
              private applicationRef: ApplicationRef) {
  }

  ngOnInit(): void {
    this.statusFilterValue = ProductStatusEnum.ACTIVE;
  }

  private loadProducts() {
    this.subscription = this.productService.getProfileProducts(this.profileId)
      .subscribe(result => {
        this.productsList = result;
        this.numberOfProducts = this.computeNumberOfProducts();
        this.productService.getProductDebtorOverruleList(+this.profileId)
          .subscribe(value => {
            this.productDebtorOverruleList = value;
            this.productService.getTopAncestorProducts(+this.profileId)
              .subscribe(ancestorMap => {
                this.productsList.forEach(product => {
                  const productDebtorOverrule = this.productDebtorOverruleList?.find(value => value.productId === ancestorMap[product.id]);
                  product.debtorOverruled = productDebtorOverrule ? productDebtorOverrule.overruled : false;
              })
              this.filterTable();
            })
          })
      });
  }

  filterTable() {
    this.filterActive();
    this.searchTable(this.searchText);
    this.normalizeTable(this.searchResultProductsList);
    this.productUpdateService.changeMessage(this.searchResultProductsList);
  }

  clearAllFiltering() {
    this.filterTable();
    this.applicationRef.tick();
    this.showFilteringSortingMenu(ProductsFilteringSortingMenuTypeEnum.empty);
  }

  finishedAndFilteringSorting() {
    this.showFilteringSortingMenu(ProductsFilteringSortingMenuTypeEnum.empty);
    this.searchTable(this.searchText);
    this.normalizeTable(this.searchResultProductsList);
    this.productUpdateService.changeMessage(this.searchResultProductsList);
  }

  closeFilteringSortingMenu() {
    for (let i = 0; i < this.showFilterMenu.length; i++) {
      if (this.showFilterMenu[i]) {
        this.showFilterMenu[i] = false;
      }
    }
  }

  showFilteringSortingMenu(filteringSortingType: ProductsFilteringSortingMenuTypeEnum) {
    for (let i = 0; i < this.showFilterMenu.length; i++) {
      if (i !== filteringSortingType.valueOf()) {
        this.showFilterMenu[i] = false;
      }
    }
    this.showFilterMenu[filteringSortingType] = !this.showFilterMenu[filteringSortingType];
  }

  searchTable(searchText: string) {
    if (this.searchText != null && this.searchText.length > 0) {
      searchText = searchText.toLowerCase();
      this.searchResultProductsList = this.filteredProductsList.filter(product => {
        return product.name.toLowerCase().includes(searchText) ||
          product.insuranceType.includes(this.getInsuranceTypeFromSearch(searchText)) ||
          (product.insuranceLevels != null && product.insuranceLevels.filter(level => level.toLowerCase().includes(searchText)).length) ||
          product.version.toLowerCase().includes(searchText) ||
          product.sosProductId.toLowerCase().includes(searchText) ||
          (product.customerProductIds.filter(productId => productId.toLowerCase().includes(searchText)).length) ||
          product.businessArea.toLowerCase().includes(searchText);
      });
    } else {
      this.searchResultProductsList = this.filteredProductsList;
    }
    this.normalizeTable(this.searchResultProductsList);
  }

  showAddActionMenu() {
    this.showAddMenu = true;
  }

  clickActionMenu(productId: number) {
    this.showActionMenu = true;
    this.actionMenuIndex = productId;
  }

  hideActionMenu() {
    this.showActionMenu = null;
  }

  addNew(businessArea: BusinessAreaEnum) {
    this.profileProductsAddComponent.openCreateNew(businessArea);
  }

  addNewLevel(parent: Product) {
    this.profileProductsAddComponent.openCreateChild(parent);
  }

  edit(productId: number) {
    this.router.navigate([RoutesName.customers + '/' + RoutesName.product, productId.toString()]);
  }

  refreshProducts(product: Product) {
    this.productsList.push(product);
    this.numberOfProducts = this.computeNumberOfProducts();
    this.filterTable();
  }

  normalizeTable(products: Product[]) {
    const normalized: Product[] = [];
    products.filter(parent => !parent.parentId).forEach(parent => this.normalizeChildren(normalized, products, parent));
    this.searchResultProductsList = normalized;
  }

  normalizeChildren(normalized: Product[], products: Product[], parent: Product): Product[] {
    normalized.push(parent);
    products.filter(child => child.parentId === parent.id).forEach(child => this.normalizeChildren(normalized, products, child));
    return normalized;
  }

  getInsuranceTypeFromSearch(searchText: string): InsuranceTypeEnum {
    if ('regular travel insurance'.includes(searchText)) {
      return InsuranceTypeEnum.regular;
    }
    if ('credit card insurance'.includes(searchText)) {
      return InsuranceTypeEnum.card;
    }
    return null;
  }

  get productStatusList() {
    return Object.values(ProductStatusEnum);
  }

  get productsFilteringSortingMenuType() {
    return ProductsFilteringSortingMenuTypeEnum;
  }

  get businessAreas(): Dictionary[] {
    return Object.keys(BusinessAreaEnum).filter(item => BusinessAreaEnum[item] !== BusinessAreaEnum.Empty)
      .map(item => new Dictionary(item, BusinessAreaEnum[item]));
  }

  filterActive() {
    if (this.statusFilterValue === ProductStatusEnum.ACTIVE) {
      this.filteredProductsList = this.productsList.filter(
        p => p.isActive()
      );
    } else {
      this.filteredProductsList = this.productsList.filter(
        p => !p.isActive()
      );
    }
  }

  filterChanged(value) {
    this.statusFilterValue = value;
    this.filterTable();
  }

  computeNumberOfProducts(): number {
    return this.productsList.filter(product => !product.parentId && product.isActive()).length;
  }

  get BusinessAreaEnum() {
    return BusinessAreaEnum;
  }

  beginCopyCoveragesAndLimits() {
    this.copyCoveragesAndLimitsInProgress = true;
    this.getAllProductsWithCoverages();
  }

  cancelCopyCoveragesAndLimits() {
    this.copyCoveragesAndLimitsInProgress = false;
    this.copyFromProductSelected = false;
    this.copyFromProduct = null;
    this.productsList.forEach(product => product.copyCoverages = false);
  }

  executeCopyCoveragesAndLimits() {
    let copyToProducts = this.productsList.filter(product => product.copyCoverages).map(produduct => produduct.id);
    this.productService.copyCoveragesAndLimits(this.copyFromProduct.id, copyToProducts).subscribe(
      result => {
        this.copyCoveragesAndLimitsInProgress = false;
        this.copyFromProductSelected = false;
        this.copyFromProduct = null;
        this.productsList.forEach(product => product.copyCoverages = false);
        this.getAllProductsWithCoverages();
      },
      error => console.error(error)
    );
  }

  selectCopyFromProduct(product: Product) {
    this.copyFromProductSelected = !this.copyFromProductSelected;
    if (this.copyFromProductSelected) {
      this.copyFromProduct = product;
      this.getAllProductsWithCoverages();
    }
  }

  private getAllProductsWithCoverages() {
    this.productService.getAllProductsWithCoverages(this.profileId).subscribe(products => {
      this.productsWithCoverages = [];
      products.forEach(product => this.productsWithCoverages.push(product.id));
    });
  }

  coveragesAndLimitsExist(product: Product) {
    return this.productsWithCoverages.includes(product.id);
  }

  anySelectedCopyToProduct(): boolean {
    return this.productsList.filter(product => product.copyCoverages).length > 0;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
