<div class="container">
  <div class="circle">
    <div class="num">{{ numberOfAppliedProfiles }}</div>
    <div class="text">Applied</div>
    <div class="text">profiles</div>
  </div>
  <div class="apply-profiles-button-container">
    <button class="primary-button" (click)="openApply()"
            [disabled]="!(debtors.length > 1 && profiles.length > 1 && priceAgreementOptions.length > 1)">Apply profiles
    </button>
  </div>
</div>

<ng-template #modal let-modal>
  <div class="header">
    <div class="apply-profiles-title">Apply profiles</div>
    <div class="apply-profiles-close"><img (click)="close()" src="../../../../../../assets/icons/Close.svg" alt="Close">
    </div>
  </div>
  <div class="content">
    <div class="top-line clearfix">
      <div class="new-table-row float-end">
        <button class="primary-button" (click)="addAppliedProfile()">Apply new profile and debtor</button>
      </div>
    </div>
    <div class="apply-profile-table">
      <table>
        <thead class="headers">
        <tr>
          <td class="profile">Profile</td>
          <td class="price-agreement">Price Agreement</td>
          <td class="customer-sla-agreement">Customer SLA Agreement</td>
          <td class="debtor">Debtor</td>
          <td class="pre-departure-debtor">Debtor pre-departure</td>
          <td class="commencement">Commencement</td>
          <td class="termination">Termination</td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let profile of appliedProfiles">
          <td>
            <app-profile-drop-down [items]="profiles" [(selectedItemId)]="profile.profileId"
                                   [appliedProfiles]="appliedProfiles"
                                   (selectedItemIdChange)="recalculateCanSave()"></app-profile-drop-down>
          </td>
          <td>
            <app-drop-down [items]="priceAgreementOptions" [(selectedItemId)]="profile.priceAgreementId"
                           (selectedItemIdChange)="recalculateCanSave()"></app-drop-down>
          </td>
          <td>
            <app-drop-down [items]="customerSlaAgreementOptions" [(selectedItemId)]="profile.customerSlaAgreementId"
                           (selectedItemIdChange)="recalculateCanSave()"></app-drop-down>
          </td>
          <td>
            <app-drop-down [items]="debtors" [(selectedItemId)]="profile.debtorId"
                           (selectedItemIdChange)="recalculateCanSave()"></app-drop-down>
          </td>
          <td>
            <app-drop-down [items]="profile.debtorId ? getSortDebitorList(profile.debtorId) : []" [disabled]="!profile.debtorId" [(selectedItemId)]="profile.preDepartureDebtorId"
                           (selectedItemIdChange)="recalculateCanSave()"></app-drop-down>
          </td>
          <td>
            <app-input-calendar [withImage]="true" [required]="false" (saveModel)="recalculateCanSave()"
                                [minDate]="getMinimumCommencementDate()"
                                [maxDate]="getMaximumCommencementDate(profile.termination)"
                                [selectMode]="'single'" [(dateTimeRange)]="profile.commencement"></app-input-calendar>
          </td>
          <td>
            <app-input-calendar [withImage]="true" [required]="false" (saveModel)="recalculateCanSave()"
                                [minDate]="getMinimumTerminationDate(profile.commencement)"
                                [maxDate]="getMaximumTerminationDate()"
                                [selectMode]="'single'" [(dateTimeRange)]="profile.termination"></app-input-calendar>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="footer">
    <button class="primary-button" (click)="saveApplyProfiles()"
            [ngClass]="!canSave && 'button-disabled'" [disabled]="!canSave">Save
    </button>
    {{ profile }}
    <app-last-changed [masterListItem]="lastUpdate" class="float-end">
    </app-last-changed>

  </div>
</ng-template>
