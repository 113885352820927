<div class="approval-limit-modal-container">
  <div class="closing-x" (click)="closeModal()">
    <img src="/assets/icons/Close.svg" />
  </div>
  <div class="header">
    <span>Edit approval limit</span>
  </div>
  <div class="row body-container" *ngIf="masterListItem">
    <div class="col-3">
      <div class="input-name">
        Activate on
        <span *ngIf="!isReadonly" class="required">*</span>
      </div>
      <app-input-calendar
        [withImage]="true"
        [selectMode]="'single'"
        [disabled]="isReadonly"
        [(dateTimeRange)]="masterListItem.activateOn"
        [maxDate]="masterListItem.deactivateOn"
        (saveModel)="activateOnChanged(masterListItem)"
      >
      </app-input-calendar>
      <div class="input-name">Deactivate after</div>
      <app-input-calendar
        [withImage]="true"
        [selectMode]="'single'"
        [disabled]="isReadonly"
        [(dateTimeRange)]="masterListItem.deactivateOn"
        [minDate]="minDeactivationDate"
        (saveModel)="deactivateOnChanged(masterListItem)"
      >
      </app-input-calendar>
      <div class="label" style="font-size: 14px;
      color: #787878;" [ngClass]="'input-name'">Based on case creation time</div>
    </div>
    <div class="col-8">
      <div class="row">
        <div class="col-9">
          <div>
            <app-input
              [name]="'Permission'"
              [(value)]="masterListItem.permission"
              [disabled]="true"
            ></app-input>
          </div>
          <div class="padding-top">
            <app-input
              [name]="'Limit in DKK'"
              [(value)]="masterListItem.limitInDKK"
              (valueChange)="modelChanged()"
              [disabled]="isReadonly"
              [required]="!isReadonly"
            ></app-input>
          </div>
          <div class="padding-top">
            <div class="field-title">Description</div>
            <textarea [(ngModel)]="masterListItem.description"
                      (ngModelChange)="modelChanged()"
                      [disabled]="isReadonly"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="row body-container" *ngIf="error">
      <div class="col-12 error">
        {{ error }}
      </div>
    </div>
  </div>
  <div class="masterlist-modal-footer d-flex justify-content-between">
    <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE" class="">
      <button class="primary-button save-button" [class.button-disabled]="!isSaveButtonEnabled" (click)="save()">Save</button>
    </div>
    <app-last-changed [masterListItem]="masterListItem" class="last-changed float-end"> </app-last-changed>
  </div>

</div>
