import { Profile } from './../../../../../shared/models/profile';
import { BrandNewProfileComponent } from './Brand-new-profile/brand-new-profile/brand-new-profile.component';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerListView } from 'app/shared/models/customer-list-view';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { RoutesName } from 'app/app-routes-name';
import { SettingsService } from 'app/core/services/settings.service';
import { ProfileService } from "../../../../../core/services/profile.service";

@Component({
  selector: 'app-brand-profiles',
  templateUrl: './brand-profiles.component.html',
  styleUrls: ['./brand-profiles.component.scss']
})
export class BrandProfilesComponent implements OnInit {
  @ViewChild('brandNewProfileComponent')
  brandNewProfileComponent: BrandNewProfileComponent;
  @Input() customerListView: CustomerListView;
  @Input() numberOfProfiles: number;
  @Input() profiles: CustomerListView[];
  @Output() refreshProfiles = new EventEmitter<Profile>();
  baseURL: string;
  showActionMenu: boolean;
  actionMenuIndex: number;

  constructor(private router: Router,
              private settingService: SettingsService,
              private profileService: ProfileService,
  ) {
    this.baseURL = this.settingService.getBffEndpoint();
  }

  ngOnInit() {
  }


  profileClick(profile: CustomerListView) {
    this.router.navigate([
      RoutesName.customers + '/' + RoutesName.profile,
      profile.id
    ]);
  }

  addNewProfile() {
    this.brandNewProfileComponent.openAddNewProfile();
  }

  refreshProfilesEvent(profile: Profile) {
    this.refreshProfiles.emit(profile);
    this.profiles.sort((a, b) => a.name.localeCompare(b.name, 'da-DK'));
  }

  clickActionMenu(profileId: number) {
    this.showActionMenu = true;
    this.actionMenuIndex = profileId;
  }

  hideActionMenu() {
    this.showActionMenu = null;
  }

  addNewGroupMember(parentId: string) {
    this.profileService.getProfile(parentId).subscribe(
      profile => {
        this.brandNewProfileComponent.openAddNewMemberProfile(profile);
      });
  }

  groupMembersExist(): boolean {
    return this.profiles.filter(profile => !!profile.groupProfileId).length > 0;
  }
}
