import { Kam, KamAdapter } from './kam';
import { BusinessAreaEnum } from './enums';
import { BusinessArea } from './customers/business-area';

export class CustomerListView {
  id: string;
  code: string;
  type: string;
  name: string;
  market: string;
  groupSegment: { id: string; name: string };
  travelSegment: { id: string; name: string };
  roadsideSegment: { id: string; name: string };
  healthSegment: { id: string; name: string };
  segment: string;
  subBusinessRelation: { id: string; name: string };
  businessAreaCount: number;
  isTravel: boolean;
  isRoadside: boolean;
  isHealth: boolean;
  nextNegotationDate: Date;
  sosOwner: boolean;
  iconBlob: string;
  numberOfProfiles: number;
  parentId: string;
  integrationType: string;
  caseNumberPreFixTravel: string;
  caseNumberPreFixRoadside: string;
  caseNumberPreFixHealth: string;
  children: any[];
  active: boolean;
  deletable: boolean;
  keyAccountManagerUserId: string;
  keyAccountManagerFirstName: string;
  keyAccountManagerSurname: string;
  keyAccountManagerEmail: string;
  keyAccountManagerPhone: string;
  responsibleKamTravelUserId: string;
  responsibleKamTravelFirstName: string;
  responsibleKamTravelSurname: string;
  responsibleKamTravelEmail: string;
  responsibleKamTravelPhone: string;
  travelcareCaseInformation: string;
  travelcareInvoicing: string;
  responsibleKamRoadsideUserId: string;
  responsibleKamRoadsideFirstName: string;
  responsibleKamRoadsideSurname: string;
  responsibleKamRoadsideEmail: string;
  responsibleKamRoadsidePhone: string;
  mobilityCaseInformation: string;
  mobilityInvoicing: string;
  responsibleKamHealthUserId: string;
  responsibleKamHealthFirstName: string;
  responsibleKamHealthSurname: string;
  responsibleKamHealthEmail: string;
  responsibleKamHealthPhone: string;
  healthcareCaseInformation: string;
  healthcareInvoicing: string;
  contactInfo: string;
  remarks: string;
  groupProfileId: string;

  public constructor(init?: Partial<CustomerListView>) {
    Object.assign(this, init);
  }

  public getProfileKams(kamAdapter: KamAdapter): Kam[] {
    const result: Kam[] = [];
    let travelKam: Kam = null;
    if (this.responsibleKamTravelUserId) {
      travelKam = kamAdapter.adapt({
        userId: this.responsibleKamTravelUserId,
        firstName: this.responsibleKamTravelFirstName,
        surname: this.responsibleKamTravelSurname,
        areas: [BusinessAreaEnum.TRAVELCARE],
        userWorkEmail: this.responsibleKamTravelEmail,
        userWorkPhone: this.responsibleKamTravelPhone,
      });
      result.push(travelKam);
    }

    let mobilityKam: Kam = null;
    if (this.responsibleKamRoadsideUserId) {
      if (travelKam && travelKam.userId === this.responsibleKamRoadsideUserId) {
        travelKam.areas.push(BusinessAreaEnum.MOBILITY);
      } else {
        mobilityKam = kamAdapter.adapt({
          userId: this.responsibleKamRoadsideUserId,
          firstName: this.responsibleKamRoadsideFirstName,
          surname: this.responsibleKamRoadsideSurname,
          userWorkEmail: this.responsibleKamRoadsideEmail,
          userWorkPhone: this.responsibleKamRoadsidePhone,
          areas: [BusinessAreaEnum.MOBILITY],
        });
        result.push(mobilityKam);
      }
    }

    let healthKam = null;
    if (this.responsibleKamHealthUserId) {
      if (travelKam && travelKam.userId === this.responsibleKamHealthUserId) {
        travelKam.areas.push(BusinessAreaEnum.HEALTHCARE);
      } else if (mobilityKam && mobilityKam.userId === this.responsibleKamHealthUserId) {
        mobilityKam.areas.push(BusinessAreaEnum.HEALTHCARE);
      } else {
        healthKam = kamAdapter.adapt({
          userId: this.responsibleKamHealthUserId,
          firstName: this.responsibleKamHealthFirstName,
          surname: this.responsibleKamHealthSurname,
          areas: [BusinessAreaEnum.HEALTHCARE],
          userWorkEmail: this.responsibleKamHealthEmail,
          userWorkPhone: this.responsibleKamHealthPhone,
        });
        result.push(healthKam);
      }
    }
    return result;
  }

  public getBusinessAreas(): BusinessArea[] {
    return [
      new BusinessArea({
        displayName: 'Travelcare',
        segment: this.travelSegment,
        type: BusinessAreaEnum.TRAVELCARE,
        active: this.isTravel,
      }),
      new BusinessArea({
        displayName: 'Mobility',
        segment: this.roadsideSegment,
        type: BusinessAreaEnum.MOBILITY,
        active: this.isRoadside,
      }),
      new BusinessArea({
        displayName: 'Healthcare',
        segment: this.healthSegment,
        type: BusinessAreaEnum.HEALTHCARE,
        active: this.isHealth,
      }),
    ];
  }
}
