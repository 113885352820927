export enum SanctionCountryMenuType {
  SANCTION_COUNTRY_CODE = 'code',
  SANCTION_COUNTRY_NAME = 'name',
  SANCTION_ALPHA_COUNTRY_CODE = 'alphaCountryCode',
  LIMIT_SERVICE_TYPES = 'limitServiceTypes',
  INFO_TASK = 'infoTask',
  SCREENING = 'screening',
  UI_SORT_INDEX = 'uiSortIndex',
  ACTIVATE_ON = 'activateOn',
  DEACTIVATION_ON = 'deactivateOn'
}
