import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Dictionary } from '@onepoint/shared/models/dictionary';
import { MasterListService } from '@onepoint/core/services/masterlist.service';
import { OnepointModalService, Size } from '@onepoint/core/services/onepoint.modal.service';
import { RefundTypeMenuType } from '@onepoint/master-lists/refund-type/refund-type-menu-type';
import * as _ from 'lodash';
import { MasterListRefundTypeModel } from '@onepoint/shared/models/masterlist-refund-type-model';
import { PermissionEnum } from '@onepoint/shared/models/permission.enum';

@Component({
  selector: 'app-masterlist-refund-type',
  templateUrl: './masterlist-refund-type.component.html',
  styleUrls: ['./masterlist-refund-type.component.scss']
})
export class MasterlistRefundTypeComponent implements OnInit {
  @ViewChild('MasterListModal')
  private masterListModalTemplate: TemplateRef<any>;

  masterList: MasterListRefundTypeModel[];
  masterListFiltered: MasterListRefundTypeModel[];
  masterListType: MasterListRefundTypeModel;
  showMenu = new Map<RefundTypeMenuType, boolean>();
  serviceTypesMap = new Map<string, string>();
  statusTypes: Dictionary[] = [
    { id: 'all', name: 'All' },
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
  ];
  selectedStatus = 'all';

  get RefundTypeMenuType() {
    return RefundTypeMenuType;
  }

  constructor(private masterListService: MasterListService, private onepointModalService: OnepointModalService) {}

  ngOnInit(): void {
    this.loadMasterList();
  }

  selectedStatusTypeChanged(status: string) {
    this.masterListFiltered = this.filterByStatus(status);
  }

  private loadMasterList() {
    this.masterListService.getRefundTypeList().subscribe(result => {
      this.masterList = result;
      this.masterListFiltered = result;
    });
  }

  private filterByStatus(status: string): MasterListRefundTypeModel[] {
    if (status === 'all') {
      return this.masterList;
    }
    const now = Date.now();
    this.masterList.forEach(rt =>
      rt.active = rt.activateOn.getTime() < now && (rt.deactivateOn == null || rt.deactivateOn.getTime() > now)
    );

    return this.masterList.filter(item => (status === 'active' ? item.active : !item.active));
  }

  showFilteringSortingMenu(filteringSortingMenuType: RefundTypeMenuType) {
    this.showMenu.forEach((value, key) => {
      if (key !== filteringSortingMenuType) {
        this.showMenu[key] = false;
      }
    });

    this.showMenu[filteringSortingMenuType] = !this.showMenu[filteringSortingMenuType];
  }

  addNew(): void {
    this.masterListType = new MasterListRefundTypeModel();
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.LARGE, 'refund-type-modal-container');
  }

  edit(item: MasterListRefundTypeModel) {
    this.masterListType = _.clone(item);
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.NORMAL, 'refund-type-modal-container');
  }

  reload() {
    this.loadMasterList();
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

}
