import { Kam } from 'app/shared/models/kam';
import { KamAdapter } from '../../shared/models/kam';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { BaseService } from './base.service';
import { Observable, empty } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class KamService extends BaseService {
  constructor(private kamAdapter: KamAdapter, private http: HttpClient, private settingsService: SettingsService) {
    super(settingsService);
  }

  public getUserListByADTCode(adtCode: string): Observable<Kam[]> {
    return this.http
      .get(this.baseURL + 'users/by-adtcode-list/' + adtCode)
      .pipe(map((data: any[]) => data.map(item => this.kamAdapter.adapt(item))));
  }
}
