<ng-template #content let-modal>
  <div class="onepoint-modal-container">
    <div class="onepoint-modal-header">
      <div class="onepoint-modal-header-title">Edit Terms</div>
      <div class="onepoint-modal-header-close">
        <img (click)="close()" src="assets/icons/Close.svg" alt="Close" />
      </div>
    </div>
    <div class="onepoint-modal-body">
      <div class="input-title-name">File<span class="star">*</span></div>
      <input disabled class="filename-text" type="text" [value]="filename">
      <input type="file" accept=".pdf" id="upload" (change)="fileChanged($event)">
      <label class="btn primary-button upload-btn" for="upload">{{ buttonLabel }}</label>
      <div class="input-title-name">Terms name<span class="star">*</span></div>
      <div class="terms-name-input">
        <app-input [(value)]="termsName" (valueChange)="evaluateAbleToSave()"></app-input>
      </div>
    </div>
    <div class="onepoint-modal-footer">
      <button
        class="primary-button save-btn"
        (click)="save()"
        [ngClass]="!ableToSave && 'button-disabled'"
        [disabled]="!ableToSave"
      >
        Save
      </button>
    </div>
  </div>
</ng-template>
