import { UpdateService } from 'app/core/services/update.service';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CustomerListView } from '../../../../shared/models/customer-list-view';
import { CommonCustomerService } from '../../../../core/services/common-customer.service';
import { CommonCustomer, CommonCustomerAdapter } from '../../../../shared/models/common-customer';
import { CommonCustomerChangeService } from '../../../../core/services/common-customer-change';
import { Router } from '@angular/router';
import { BreadcrumbsMenuUpdateService } from 'app/shared/components/breadcrumbs-menu/breadcrumbs-menu-update-service';

@Component({
  selector: 'app-common-customer-settings',
  templateUrl: './common-customer-settings.component.html',
  styleUrls: ['./common-customer-settings.component.scss'],
})
export class CommonCustomerSettingsComponent implements OnInit {
  @ViewChild('content', { static: true }) template: TemplateRef<any>;
  @Input() commonCustomerOriginal: CustomerListView;
  changesMade = false;
  private commonCustomerOriginalString: string;
  commonCustomer: CommonCustomer;

  constructor(
    private commonCustomersService: CommonCustomerService,
    private commonCustomerChange: CommonCustomerChangeService,
    private breadcrumbsMenuUpdateService: BreadcrumbsMenuUpdateService,
    private router: Router,
    private customerAdapter: CommonCustomerAdapter,
    private updateService: UpdateService
  ) {}

  ngOnInit() {
    this.commonCustomerOriginalString = JSON.stringify(this.commonCustomerOriginal);
    this.commonCustomer = this.customerAdapter.adapt(JSON.parse(this.commonCustomerOriginalString));
  }

  onEditCommonCustomer() {
    this.commonCustomersService.updateCommonCustomer(this.commonCustomer).subscribe(
      result => {
        this.commonCustomerOriginalString = JSON.stringify(result);
        this.commonCustomerOriginal = this.customerAdapter.adaptToCommonCustomerListView(result);
        this.changesMade = false;
        this.commonCustomerChange.changedListView(this.commonCustomerOriginal);
        this.breadcrumbsMenuUpdateService.updatedCommonCustomer(result);
      },
      error => {
        console.error(error);
      }
    );
  }

  handleChanges(commonCustomer: CommonCustomer) {
    this.changesMade = JSON.stringify(commonCustomer) !== this.commonCustomerOriginalString;
  }

  deleteCommonCustomer() {
    this.commonCustomersService.deleteCommonCustomer(this.commonCustomerOriginal.id).subscribe(result => {
      this.updateService.deletedCommonCustomer();
      this.router.navigate(['.']);
    });
  }
}
