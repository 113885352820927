import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';
import {SlaAgreementCategory, SlaAgreementCategoryAdapter} from "@onepoint/shared/models/sla-agreement-category";

export class CustomerSlaAgreement {
  id: number;
  customerContractId: number;
  contractName: string
  name: string;
  enabled: boolean;
  startDate: Date;
  endDate: Date;
  slaAgreementCategories: SlaAgreementCategory[]

  public constructor(init?: Partial<CustomerSlaAgreement>) {
    Object.assign(this, init);
  }
}

@Injectable({
    providedIn: 'root'
  })
  export class CustomerSlaAgreementAdapter implements Adapter<CustomerSlaAgreement> {
    adapt(item: any): CustomerSlaAgreement {
      const slaAgreementCatalogAdapter = new SlaAgreementCategoryAdapter();
      return new CustomerSlaAgreement({
        id: item.id,
        customerContractId: item.customerContractId,
        name: item.name,
        enabled: item.enabled,
        contractName: item.contractName,
        startDate: item.startDate,
        endDate: item.endDate,
        slaAgreementCategories : item.slaAgreementCategories.map(slaAgreementCatalogAdapter.adapt)
      });
    }
  }
