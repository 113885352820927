<ng-template #content let-modal>
  <div class="onepoint-modal-container">
    <div class="onepoint-modal-header">
      <div class="onepoint-modal-header-title">{{ title }}</div>
      <div class="onepoint-modal-header-close">
        <img (click)="close()" src="assets/icons/Close.svg" alt="Close" />
      </div>
    </div>
    <div class="onepoint-modal-body">
        <div class="message">{{ message }}</div>
      </div>
    <div class="onepoint-modal-footer">
      <button class="primary-button float-end" (click)="close()">
        {{closeBtnLabel}}
      </button>
    </div>
  </div>
</ng-template>
