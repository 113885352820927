<ng-template #content let-modal>
  <div class="add-product-container">
    <div class="add-product-container-head">
      <div class="add-product-title">{{windowTitle}}</div>
      <div class="add-product-close"><img (click)="close()" src="assets/icons/Close.svg" alt="Close"></div>
    </div>
    <div class="add-product-container-notification" *ngIf="getViolations().length">
      The following product(s) violates the validation rules due to same Name, Customer Id and/or Level:
      <div class="violating" *ngFor="let violating of uniqueViolations">
        <div class="dot"></div>
        <div class="product-info">
          Name: {{violating.name}}; Version: {{violating.version}}
          <span *ngIf="!!violating.customerProductIds.length">; Customer Product Ids: {{violating.customerProductIds.join(', ')}}</span>
          <span *ngIf="!!violating.insuranceLevels.length">; Insurance Levels: {{violating.insuranceLevels.join(', ')}}</span>
        </div>
      </div>
      <div class="disclaimer" *ngIf="!product.parentId && !!product.insuranceLevels">If you wish to create a new
        Insurance/Card level use product table action menu
      </div>
    </div>
    <div class="add-product-container-body">
      <app-product-settings-form [product]="product" [newVersion]="newVersion"></app-product-settings-form>
    </div>
    <div class="add-product-container-footer">
      <button class="primary-button my-auto" [disabled]="!isValid()" (click)="saveProduct()">
        <span>Add</span>
      </button>
    </div>
  </div>
</ng-template>
