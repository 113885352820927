import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomersTableComponent } from './customers-table/customers-table.component';
import { CustomerListView } from 'app/shared/models/customer-list-view';

@Component({
  selector: 'app-customers-page',
  templateUrl: './customers-page.component.html',
  styleUrls: ['./customers-page.component.scss']
})
export class CustomersPageComponent implements OnInit {
  @ViewChild('customersTable') customersTable: CustomersTableComponent;

  constructor() {}

  ngOnInit() {}

  searchTextValueChange(searchText: string) {
    this.customersTable.searchTextValueChange(searchText);
  }
}
