import {Limit, LimitAdapter} from './limit';
import {Injectable} from '@angular/core';
import {Adapter} from '../interfaces/adapter';
import { CoverageTypeEnum  } from './enums';

export class Coverage {
  id: number;
  coverageTermsRef: string;
  productId: number;
  coverageName: string;
  coverageNameAlias: string;
  limits: Limit[];
  coverageType: CoverageTypeEnum;
  coverageSortOrder: number;
  groupProfileCoverageId: string;
  coverageIdForMapping: string;

  public constructor(init?: Partial<Coverage>) {
    Object.assign(this, init);
  }

}

@Injectable({
  providedIn: 'root'
})
export class CoverageAdapter implements Adapter<Coverage> {
  adapt(item: any): Coverage {
    const limitAdapter = new LimitAdapter();
    return new Coverage({
      id: item.id,
      coverageTermsRef: item.coverageTermsRef,
      productId: item.productId,
      coverageName: item.coverageName,
      coverageNameAlias: item.coverageNameAlias,
      limits:  item.limits.map(limitAdapter.adapt),
      coverageType: item.coverageType,
      coverageSortOrder: item.coverageSortOrder,
      groupProfileCoverageId: item.groupProfileCoverageId,
      coverageIdForMapping: item.coverageIdForMapping
    });
  }

}
