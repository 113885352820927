import { map } from 'rxjs/operators';
import { HelperService } from './helper.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { BaseService } from './base.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { PermissionEnum } from 'app/shared/models/permission.enum';

@Injectable({
  providedIn: 'root',
})
export class PermissionService extends BaseService {
  private userPermissions: PermissionEnum[] = [];
  private isInTestMode: boolean;
  private isReadOnlyMode: boolean;
  public permissionsReadyNotification = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private settingsService: SettingsService, private helperService: HelperService) {
    super(settingsService);
    this.getUserPermissions().subscribe(
      result => {
        this.userPermissions = result;
        this.isReadOnlyMode = this.hasUserOnlyReadPermissions();
        this.permissionsReadyNotification.next(true);
      },
      error => console.log(error)
    );
  }

  private getUserPermissions(): Observable<PermissionEnum[]> {
    return this.http
      .get<PermissionEnum[]>(this.baseURL + 'permissions')
      .pipe(map((data: any[]) => data.map(item => this.parsePermissionEnum(item))));
  }

  private parsePermissionEnum(item: string): PermissionEnum {
    let result = this.helperService.getEnumKeyByEnumValue(PermissionEnum, item);
    if (result == null) {
      return PermissionEnum.NONE;
    } else {
      return item as PermissionEnum;
    }
  }

  private hasUserOnlyReadPermissions(): boolean {
    return this.userPermissions.filter((p: PermissionEnum) => {
      switch(p) {
        case PermissionEnum.CASE_BASIC_READ:
        case PermissionEnum.CASE_SUMMARY_READ:
        case PermissionEnum.CASE_SUMMARY_FULL_DETAILS_READ:
        case PermissionEnum.COMMUNICATION_READ:
        case PermissionEnum.CUSTOMER_AND_SOS_SERVICE_READ:
        case PermissionEnum.DOCUMENT_READ:
        case PermissionEnum.NOTE_READ:
        case PermissionEnum.SALES_ORDER_READ:
        case PermissionEnum.SEARCH_READ:
        case PermissionEnum.PLANS_READ:
        case PermissionEnum.STAKEHOLDER_READ:
        case PermissionEnum.SUPPLIER_INVOICE_READ:
        case PermissionEnum.TASK_READ:
        case PermissionEnum.TASK_SUPPLIER_INVOICE_READ:
        case PermissionEnum.MEDICAL_READ:
        case PermissionEnum.MEDICAL_ESCORT_SUPPLIER_INVOICE_READ:
        case PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_READ:
        case PermissionEnum.NONE:
          return false;
        
        default:
          return true;
      }
    }).length === 0;
  }

  public checkUserPermission(permission: PermissionEnum): boolean {
    return this.userPermissions.some(a => a === permission);
  }

  public get isReadOnly(): boolean {
    return this.isReadOnlyMode;
  }
}
