import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Debtor } from '../../../../shared/models/debtor';
import { EditDebtorComponent } from './edit-debtor/edit-debtor.component';
import { DebtorService } from '../../../../core/services/debtor.service';

@Component({
  selector: 'app-common-customer-debtors',
  templateUrl: './common-customer-debtors.component.html',
  styleUrls: ['./common-customer-debtors.component.scss'],
})
export class CommonCustomerDebtorsComponent implements OnInit {
  @ViewChild('modal') modal: EditDebtorComponent;
  @Input() commonCustomerId: number;
  debtors: Array<Debtor> = [];

  constructor(private debtorService: DebtorService) {}

  ngOnInit() {
    this.reloadDebtors();
  }

  addNewDebtor() {
    this.modal.openNew();
  }

  editDebtor(debtor: Debtor) {
    this.modal.open(debtor);
  }

  reloadDebtors() {
    this.debtorService.getDebtors(this.commonCustomerId).subscribe(
      result => {
        this.debtors = result;
      },
      error => {
        console.error(error);
      }
    );
  }
}
