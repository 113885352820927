export enum SupplierMenuType {
  supplierProfileCode = 0,
  supplierProfileName = 1,
  providerId = 2,
  creditorNumber = 3,
  creditorName = 4,
  creditorCurrency = 5,
  networkManagerId = 6,
  startDate = 7,
  endDate = 8,
  type = 9,
  partner = 10,
  empty = 11,
}
