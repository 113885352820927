import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customers',
  template: '<router-outlet></router-outlet>'
})
export class CustomersComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
