import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { Product, ProductAdapter } from '../../shared/models/product';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BusinessAreaEnum } from '../../shared/models/enums';
import { ProductDebtorOverrule, ProductDebtorOverruleAdapter } from "../../shared/models/product-debtor-overrule";

@Injectable({
  providedIn: 'root',
})
export class ProductService extends BaseService {
  constructor(private http: HttpClient,
              private settingsService: SettingsService,
              private productAdapter: ProductAdapter,
              private productDebtorOverruleAdapter: ProductDebtorOverruleAdapter) {
    super(settingsService);
  }

  public getProfileProducts(profileId: string): Observable<Product[]> {
    const profileProductsTarget = this.baseURL + 'products/profile/' + profileId;
    return this.http.get(profileProductsTarget).pipe(map((data: any[]) => data.map(item => this.productAdapter.adapt(item))));
  }

  public getProductDebtorOverruleList(profileId: number): Observable<ProductDebtorOverrule[]> {
    return this.http.get(this.baseURL + 'debtor-overrule/profile/' + profileId).pipe(map((data: any[]) => data.map(item => this.productDebtorOverruleAdapter.adapt(item))));
  }

  public getTopAncestorProducts(profileId: number): Observable<any> {
    return this.http.get(this.baseURL + 'debtor-overrule/ancestor/' + profileId);
  }

  public getProductDebtorOverrule(productId: number): Observable<ProductDebtorOverrule> {
    return this.http.get(this.baseURL + 'debtor-overrule/product/' + productId).pipe(map(item => this.productDebtorOverruleAdapter.adapt(item)));
  }

  public updateProductDebtorOverrule(productDebtorOverrule: ProductDebtorOverrule): Observable<ProductDebtorOverrule> {
    return this.http
      .post(this.baseURL + 'debtor-overrule', productDebtorOverrule, { headers: this.jsonHeaders })
      .pipe(map(item => this.productDebtorOverruleAdapter.adapt(item)));
  }

  public getProduct(productId: string): Observable<Product> {
    return this.http.get(this.baseURL + 'products/' + productId).pipe(map(item => this.productAdapter.adapt(item)));
  }

  public getProductVersions(productId: string): Observable<Product[]> {
    return this.http
      .get(this.baseURL + 'products/' + productId + '/versions')
      .pipe(map((data: any[]) => data.map(item => this.productAdapter.adapt(item))));
  }

  public createNewProduct(profileId: string, product: Product): Observable<Product> {
    const data = {};
    Object.assign(data, product);
    (data as {
      businessArea: string;
    }).businessArea = this.getBusinessAreaCode(product.businessArea);
    return this.http
      .post(this.baseURL + 'products/profile/' + profileId, data, { headers: this.jsonHeaders })
      .pipe(map(item => this.productAdapter.adapt(item)));
  }

  public createChildProduct(parentId: number, product: Product): Observable<Product> {
    const data = {};
    Object.assign(data, product);
    (data as {
      businessArea: string;
    }).businessArea = this.getBusinessAreaCode(product.businessArea);
    return this.http
      .post(this.baseURL + 'products/' + parentId + '/child', data, { headers: this.jsonHeaders })
      .pipe(map(item => this.productAdapter.adapt(item)));
  }

  public updateProduct(product: Product): Observable<Product> {
    const data = {};
    Object.assign(data, product);
    (data as {
      businessArea: string;
    }).businessArea = this.getBusinessAreaCode(product.businessArea);
    return this.http
      .patch(this.baseURL + 'products', data, { headers: this.jsonHeaders })
      .pipe(map(item => this.productAdapter.adapt(item)));
  }

  private getBusinessAreaCode(businessArea: BusinessAreaEnum): string {
    for (const key in BusinessAreaEnum) {
      if (businessArea === BusinessAreaEnum[key]) {
        return key;
      }
    }
    return null;
  }

  public deleteProduct(productId: number): Observable<any> {
    return this.http.delete(this.baseURL + 'products/' + productId, { headers: this.jsonHeaders });
  }

  public addTerms(file: string | ArrayBuffer, termsName: string, filename: string, productId: number): Observable<any> {
    const formData = new FormData();
    formData.append('file', new Blob([file], { type: 'application/pdf' }));
    formData.append('termsName', encodeURI(termsName));
    formData.append('filename', encodeURI(filename));
    return this.http.post(this.baseURL + 'terms/' + productId, formData, {});
  }

  public getTermsDocument(documentId: string): Observable<any> {
    return this.http.get(this.baseURL + 'document-service/product/terms/' + documentId, { responseType: 'arraybuffer' }).pipe(
      map((res: any) => {
        return new Blob([res], {
          type: 'application/pdf',
        });
      })
    );
  }

  public clearTerms(productId: number): Observable<Product> {
    return this.http.delete(this.baseURL + 'terms/' + productId)
      .pipe(map(result => this.productAdapter.adapt(result)));
  }

  public getAllProductsWithCoverages(profileId: string): Observable<Product[]> {
    return this.http
      .get(this.baseURL + 'products/profile/' + profileId + '/has-coverages')
      .pipe(map((data: any[]) => data.map(item => this.productAdapter.adapt(item))));
  }

  copyCoveragesAndLimits(productId: number, copyToProducts: number[]): Observable<any> {
    return this.http.post(this.baseURL + 'products/' + productId + '/copy-coverages-limits' , copyToProducts, {
      headers: this.jsonHeaders
    });
  }
}
