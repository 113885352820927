import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasterListService } from 'app/core/services/masterlist.service';
import { OnepointModalService } from 'app/core/services/onepoint.modal.service';
import { PermissionService } from 'app/core/services/permission.service';
import { SosServicesService } from 'app/core/services/sos-services.service';
import { Dictionary } from 'app/shared/models/dictionary';
import { DictionaryWithType } from 'app/shared/models/dictionary-with-type';
import { MasterListCaseTypeModel } from 'app/shared/models/masterlist-case-type-model';
import { MasterListServiceTypeModel } from 'app/shared/models/masterlist-service-type-model';
import { PermissionEnum } from 'app/shared/models/permission.enum';

@Component({
  selector: 'app-masterlist-case-type-modal',
  templateUrl: './masterlist-case-type-modal.component.html',
  styleUrls: ['./masterlist-case-type-modal.component.scss'],
})
export class MasterlistCaseTypeModalComponent implements OnInit {
  @Input()
  masterListType: MasterListCaseTypeModel;

  @Output()
  refreshTable = new EventEmitter();

  vatValues: Dictionary[] = [
    { id: 'true', name: 'Yes' },
    { id: 'false', name: 'No' },
  ];
  minDeactivationDate: Date;
  selectedServiceTypeId: string;
  selectedVatValue: string;
  error: string;
  canUpdate: boolean;
  isModelChanged: boolean;
  selectedServiceTypes: Dictionary[];
  selectedCaseTypes: Dictionary[];
  serviceTypes: MasterListServiceTypeModel[];
  serviceTypesDictionary: Dictionary[];
  caseTypes: MasterListCaseTypeModel[];
  caseTypesDictionary: Dictionary[];

  constructor(
    private masterListService: MasterListService,
    private onepointModalService: OnepointModalService,
    private sosServicesService: SosServicesService,
    private permissionService: PermissionService,
  ) {}

  ngOnInit(): void {
    this.minDeactivationDate = new Date(Date.now() - 86400000);
    this.permissionService.permissionsReadyNotification.subscribe(
      result => (this.canUpdate = this.permissionService.checkUserPermission(PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE))
    );
    if (this.masterListType.activateOn > this.minDeactivationDate) {
      this.minDeactivationDate = this.masterListType.activateOn;
    }
    this.selectedServiceTypes = [];
    this.masterListType.serviceTypes?.forEach(type => this.selectedServiceTypes.push(new Dictionary(type.id, type.serviceTypeName)));
    this.sosServicesService.getServiceTypeModalList().subscribe(response => {
      this.serviceTypesDictionary = [];
      this.serviceTypes = response;
      this.serviceTypes.filter(type => type.active).forEach(type => this.serviceTypesDictionary.push(new Dictionary(type.id, type.serviceTypeName)));
    });

    this.selectedCaseTypes = [];
    this.masterListType.allowableCaseTypes?.forEach(type => this.selectedCaseTypes.push(new Dictionary(type.id, type.caseTypeName)));
    this.masterListService.getCaseTypeList().subscribe(response => {
      this.caseTypesDictionary = [];
      this.caseTypes = response;
      this.caseTypes.filter(type => type.active).filter(type => type.caseTypeCode !== "CT99").filter(type => type.caseTypeCode !== this.masterListType.caseTypeCode).forEach(type => this.caseTypesDictionary.push(new Dictionary(type.id, type.caseTypeName)));
    });

  }

  closeModal() {
    this.onepointModalService.dismissAll();
  }

  get isSaveButtonEnabled(): boolean {
    return (
      this.isModelChanged &&
      this.masterListType &&
      (this.masterListType.serviceTypes && this.masterListType.serviceTypes.length > 0) &&
      !!this.masterListType.activateOn &&
      !!this.masterListType.caseTypeCode &&
      !!this.masterListType.caseTypeName
    );
  }

  save() {
    this.masterListService.updateCaseType(this.masterListType).subscribe(
      response => {
        this.masterListType = response;
        this.refreshTable.emit();
        this.closeModal();
      },
      err => (this.error = err.error ? err.error.message : err.message)
    );
  }

  activateOnChanged(item: MasterListServiceTypeModel) {
    this.modelChanged();
    item.activateOn.setHours(12, 0);
  }
  
  selectedServiceTypeChanged(): void {
    this.modelChanged();
    this.masterListType.serviceTypes = [];
    this.selectedServiceTypes.forEach(dictionary =>
      this.masterListType.serviceTypes.push(this.serviceTypes.find(type => type.id === dictionary.id))
    );
  }

  selectedCaseTypeChanged(): void {
    this.modelChanged();
    this.masterListType.allowableCaseTypes = [];
    this.selectedCaseTypes.forEach(dictionary =>
      this.masterListType.allowableCaseTypes.push(this.caseTypes.find(type => type.id === dictionary.id))
    );
  }

  deactivateOnChanged(item: MasterListServiceTypeModel) {
    this.modelChanged();
    item.deactivateOn.setHours(12, 0);
  }

  modelChanged(): void {
    this.isModelChanged = true;
  }

  get isReadonly(): boolean {
    return !this.canUpdate;
  }

  get isNew(): boolean {
    return this.masterListType.id == null;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
