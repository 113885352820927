import { SettingsLoaderService } from '../core/services/settings.loader.service';
import { KeycloakService } from 'keycloak-angular';
import { SettingsService } from '../core/services/settings.service';
import { initializeAppSettings } from './app-init-settings';
import { initializeAppSSO } from './app-init-sso';

export function initializeApp(keycloakService: KeycloakService, settingsService: SettingsService, settingsLoaderService: SettingsLoaderService): () => Promise<any> {
  return async (): Promise<any> => {
    await initializeAppSettings(settingsLoaderService);
    return await initializeAppSSO(keycloakService, settingsService);
  };
}

