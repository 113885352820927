import {Component, OnInit, ViewChild} from '@angular/core';
import {Sla} from "@onepoint/shared/models/sla";
import {AddSlaComponent} from "./add-sla/add-sla.component";
import {SlaService} from "@onepoint/core/services/sla.service";
import {MasterListService} from "@onepoint/core/services/masterlist.service";
import {SlaCategory} from "@onepoint/shared/models/sla-category";
import {Dictionary} from "@onepoint/shared/models/dictionary";
import {SlaEvent} from "@onepoint/shared/models/sla-event";

@Component({
  selector: 'app-sla',
  templateUrl: './sla.component.html',
  styleUrls: ['./sla.component.scss']
})
export class SlaComponent implements OnInit {
  @ViewChild('modal') modal: AddSlaComponent;
  slas: Sla[];
  filteredSlas: Sla[];
  slaCategories: SlaCategory[];
  currentFilter: string = 'all';
  slaCategoryDictionary: Dictionary[];
  constructor( private slaService : SlaService, private masterListService: MasterListService) {
  }

  ngOnInit() {
    this.reload()
    this.loadSlaCategoryList();
  }

  applyFilter(id: any) {
    if (this.slas != null) {
      this.currentFilter = id;
      this.filteredSlas = this.slas.filter(sla => sla.category.id.toString() === id || id === 'all');
    }
  }

  concatEventNames(slaEvents: SlaEvent[]){
    return slaEvents.map(x => x.name).join(", ");
  }

  loadSlaCategoryList(){
    this.masterListService.getSlaCategoryList().subscribe(result =>{
      this.slaCategories = result;
      this.slaCategoryDictionary = result.map(a => new Dictionary(a.id.toString(), a.name));
      this.slaCategoryDictionary.unshift(new Dictionary('all', 'ALL'));
    });
  }

  reload(){
    this.slaService.getSlas().subscribe(result =>{
      this.slas = result;
      this.applyFilter(this.currentFilter);
    });
  }

  addNewSla(){
    this.modal.openNew(this.slaCategories);
  }

  showExistingSla(slaId: number){
    this.modal.openExisting(this.slas.find(sla => sla.id === slaId), this.slaCategories);
  }
}
