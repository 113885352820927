import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dictionary } from '@onepoint/shared/models/dictionary';
import { MasterListService } from '@onepoint/core/services/masterlist.service';
import { OnepointModalService } from '@onepoint/core/services/onepoint.modal.service';
import { SosServicesService } from '@onepoint/core/services/sos-services.service';
import { PermissionService } from '@onepoint/core/services/permission.service';
import { MasterListRefundTypeModel } from '@onepoint/shared/models/masterlist-refund-type-model';
import { PermissionEnum } from '@onepoint/shared/models/permission.enum';

@Component({
  selector: 'app-refund-type-modal',
  templateUrl: './refund-type-modal.component.html',
  styleUrls: ['./refund-type-modal.component.scss']
})
export class RefundTypeModalComponent implements OnInit {
  @Input()
  masterListType: MasterListRefundTypeModel;

  @Output()
  refreshTable = new EventEmitter();

  vatValues: Dictionary[] = [
    { id: 'true', name: 'Yes' },
    { id: 'false', name: 'No' },
  ];
  selectedVatValue: string;
  error: string;
  canUpdate: boolean;
  isModelChanged: boolean;
  constructor(
    private masterListService: MasterListService,
    private onepointModalService: OnepointModalService,
    private sosServicesService: SosServicesService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.permissionService.permissionsReadyNotification.subscribe(
      ignore => (this.canUpdate = this.permissionService.checkUserPermission(PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE))
    );
  }

  closeModal() {
    this.onepointModalService.dismissAll();
  }

  get isSaveButtonEnabled(): boolean {
    return (
      this.isModelChanged &&
      this.masterListType &&
      !!this.masterListType.activateOn &&
      !!this.masterListType.businessKey &&
      !!this.masterListType.refundTypeName
    );
  }

  save() {
    this.masterListService.updateRefundType(this.masterListType).subscribe(
      response => {
        this.masterListType = response;
        this.refreshTable.emit();
        this.closeModal();
      },
      err => (this.error = err.error ? err.error.message : err.message)
    );
  }

  activateOnChanged(item: MasterListRefundTypeModel) {
    this.modelChanged();
    item.activateOn.setHours(12, 0);
  }

  deactivateOnChanged(item: MasterListRefundTypeModel) {
    this.modelChanged();
    item.deactivateOn.setHours(12, 0);
  }

  modelChanged(): void {
    this.isModelChanged = true;
  }

  get isReadonly(): boolean {
    return !this.canUpdate;
  }

  get isNew(): boolean {
    return this.masterListType.id == null;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
