<div class="profile-contact-info">
  <div class="contact-info-container">
    <div class="contact-info-header"><label for="contact-info">Contact and info for policy verification</label></div>
    <div>
      <textarea maxlength="3000" id="contact-info" class="contact-info" [(ngModel)]="textContent" (keyup)="textChanged = true" [disabled]="!!profile.groupProfileId"></textarea>
    </div>
    <button class="primary-button save-button" (click)="saveContactInfo()"
            [ngClass]="{ 'button-disabled': !textChanged }" [disabled]="!textChanged || !!profile.groupProfileId">Save changes</button>
  </div>
</div>
