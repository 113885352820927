import { CustomerProfileRelationOverviewFilterSortEnum } from './customer-profile-relations-overview-sorting-menu-enum';
import {Injectable} from '@angular/core';
import { CustomerProductRelationOverview } from 'app/shared/models/customer-product-relation-overview';

@Injectable({
  providedIn: 'root'
})
export class CustomerProfileRelationOverviewService {

  filteringStep: FilteringStep[] = [];
  constructor() {}

  public addStep(
    customerProfileRelationOverviewFilterSortEnum: CustomerProfileRelationOverviewFilterSortEnum,
    selectedIds: string[],
  ) {
    this.filteringStep.push(
      new FilteringStep(customerProfileRelationOverviewFilterSortEnum, selectedIds)
    );
  }

  public removeStep(customerProfileRelationOverviewFilterSortEnum: CustomerProfileRelationOverviewFilterSortEnum) {
    const itemToDelete = this.filteringStep.find(
      a => a.filteringSortingMenuType === customerProfileRelationOverviewFilterSortEnum
    );
    if (itemToDelete) {
      this.filteringStep.splice(this.filteringStep.indexOf(itemToDelete));
    }
  }

  public filter(customerProductRelationOverview: CustomerProductRelationOverview[]): CustomerProductRelationOverview[] {
    let tempCustomerProductRelationOverview: Set<CustomerProductRelationOverview>;

    this.filteringStep.forEach(step => {
      tempCustomerProductRelationOverview = new Set();
      switch (step.filteringSortingMenuType) {
        case CustomerProfileRelationOverviewFilterSortEnum.type:
          step.selectedIds.forEach(element => {
            customerProductRelationOverview
              .filter(a => a.type === element)
              .forEach(a => tempCustomerProductRelationOverview.add(a));
          });
          break;
        case CustomerProfileRelationOverviewFilterSortEnum.companyPolicyHolder:
          step.selectedIds.forEach(element => {
            customerProductRelationOverview
              .filter(a => a.companyPolicyHolder.includes(element))
              .forEach(a => tempCustomerProductRelationOverview.add(a));
          });
          break;
        case CustomerProfileRelationOverviewFilterSortEnum.bank:
          step.selectedIds.forEach(element => {
            customerProductRelationOverview
              .filter(a => a.bank === element)
              .forEach(a => tempCustomerProductRelationOverview.add(a));
          });
          break;
        case CustomerProfileRelationOverviewFilterSortEnum.bin:
          step.selectedIds.forEach(element => {
            customerProductRelationOverview
              .filter(a => a.bin === element)
              .forEach(a => tempCustomerProductRelationOverview.add(a));
          });
          break;
        case CustomerProfileRelationOverviewFilterSortEnum.customerProfile:
          step.selectedIds.forEach(element => {
            customerProductRelationOverview
              .filter(a => a.customerProfileName.includes(element))
              .forEach(a => tempCustomerProductRelationOverview.add(a));
          });
          break;
        case CustomerProfileRelationOverviewFilterSortEnum.customerReportingLabel:
          step.selectedIds.forEach(element => {
            customerProductRelationOverview
              .filter(a => a.customerRelationLabel === element)
              .forEach(a => tempCustomerProductRelationOverview.add(a));
          });
          break;
      }
      customerProductRelationOverview.splice(0, customerProductRelationOverview.length);
      Array.from(tempCustomerProductRelationOverview).forEach(element => {
        customerProductRelationOverview.push(element);
      });
    });
    return customerProductRelationOverview;
  }
}

export class FilteringStep {
  filteringSortingMenuType: CustomerProfileRelationOverviewFilterSortEnum;
  selectedIds: string[];
  constructor(
    filteringSortingMenuType: CustomerProfileRelationOverviewFilterSortEnum,
    selectedIds: string[],
  ) {
    this.filteringSortingMenuType = filteringSortingMenuType;
    this.selectedIds = selectedIds;
  }
}
