import { CustomersService } from './../../../../core/services/customers.service';
import {Component, OnInit, Output, EventEmitter, ViewChild, Input} from '@angular/core';
import {NewCommonCustomerComponent} from './new-common-customer/new-common-customer.component';
import { CustomerTypeEnum } from 'app/shared/enums/customerType.enum';
import { CustomersSummaryUpdateService } from './customers-summary-update.service';

@Component({
  selector: 'app-customers-summary',
  templateUrl: './customers-summary.component.html',
  styleUrls: ['./customers-summary.component.scss']
})
export class CustomersSummaryComponent implements OnInit {
  @ViewChild('newCommonCustomerComponent')
    newCommonCustomerComponent: NewCommonCustomerComponent;
  @Output() onSearchTextValueChange = new EventEmitter<string>();

  totalNumberOfCommonCustomers: number;
  totalNumberOfBrands: number;
  totalNumberOfProfiles: number;
  searchText: string;

  constructor(
    private customersService: CustomersService,
    private customerUpdateService: CustomersSummaryUpdateService) {}

  ngOnInit() {
    this.customerUpdateService.currentCustomerList.subscribe(data => {
      if (data !== null) {
        this.totalNumberOfCommonCustomers = data.totalNumberOfCommonCustomers;
        this.totalNumberOfBrands = data.totalNumberOfBrands;
        this.totalNumberOfProfiles = data.totalNumberOfProfiles;
      }
    });
  }

  get CustomerTypeEnum() {
    return CustomerTypeEnum;
  }

  textValueChange() {
    this.onSearchTextValueChange.emit(this.searchText);
  }

  addNewCommonCustomer() {
    this.newCommonCustomerComponent.open();
  }
}
