import { Component, Input } from '@angular/core';
import { Limit } from '../../../../../../shared/models/limit';
import { LimitTypeEnum, LimitFromTypeEnum, LimitPersonTypeEnum, LimitQuantityTypeEnum } from '../../../../../../shared/models/enums';
import { LimitPeriodUnitEnum } from '../../../../../../shared/enums/coverage-period-unit-enum';
import { CoverageService } from '../../../../../../core/services/coverage-service';

@Component({
  selector: 'app-limits-field',
  templateUrl: './limits-field.component.html',
  styleUrls: ['./limits-field.component.scss']
})
export class LimitsFieldComponent {
  @Input() limits: Limit[];

  coverageLimitTypes(value: LimitTypeEnum) {
    return CoverageService.coverageLimitTypes.get(value);
  }

  coverageLimitPeriodTypes(value: LimitPeriodUnitEnum) {
    return CoverageService.coverageLimitPeriodTypes.get(value);
  }

  coverageLimitFromTypes(value: LimitFromTypeEnum) {
    return CoverageService.coverageLimitFromTypes.get(value);
  }

  coverageLimitPersonTypes(value: LimitPersonTypeEnum) {
    return CoverageService.coverageLimitPersonTypes.get(value);
  }

  coverageLimitTreatmentsTypes(value: LimitQuantityTypeEnum) {
    return CoverageService.coverageLimitTreatmentsTypes.get(value);
  }

  get getLimitTypeEnum() {
    return LimitTypeEnum;
  }

  constructor() { }

  ngOnInit() { }
}
