import {Injectable} from "@angular/core";
import {Adapter} from "@onepoint/shared/interfaces/adapter";
import {ErpCreditor, ErpCreditorAdapter} from "@onepoint/shared/models/erp-creditor";

export class CreditorListPageDto {
  erpCreditors: ErpCreditor[];
  currentPage: number;
  totalCreditors: number;
  public constructor(init?: Partial<CreditorListPageDto>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CreditorPageDtoAdapter implements Adapter<CreditorListPageDto> {
  adapt(item: any): CreditorListPageDto {
    const erpCreditorAdapter = new ErpCreditorAdapter();
    return new CreditorListPageDto({
      erpCreditors: item.erpCreditors.map(erpCreditorAdapter.adapt),
      totalCreditors: item.totalCreditors,
      currentPage: item.currentPage
    });
  }
}
