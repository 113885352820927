import { Dictionary } from './dictionary';

export class Debtor extends Dictionary {
  number: number;
  erpClient = { id: null, name: null };
  billingCurrency = { id: null, name: null };
  commonCustomerId: number;
  paymentTerm = { id: null, name: null };
  invoiceFrequency = { id: null, name: null };
  sosServicesSeparateInvoice: boolean;
  maxInvoicesPerDay: number;
  claimsCostPartialInvoicingLimit: number;
  customerReferenceRequired: boolean;
  invoicingSuspended: boolean;
  internalRemarks: string;

  constructor(id: string, name: string, commonCustomerId: number) {
    super(id, name);
    this.commonCustomerId = commonCustomerId;
    this.paymentTerm = { id: null, name: null };
    this.invoiceFrequency = { id: null, name: null };
    this.sosServicesSeparateInvoice = false;
    this.maxInvoicesPerDay = null;
    this.claimsCostPartialInvoicingLimit = null;
    this.customerReferenceRequired = false;
    this.invoicingSuspended = false;
  }
}
