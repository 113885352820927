import {Injectable} from '@angular/core';
import {Adapter} from './../../interfaces/adapter';
import {ActivityInput, ActivityInputAdapter} from "@onepoint/shared/models/digital-caseflow/activity-input";

export class Activity {
  id: number;
  revision: number;
  name: string;
  destination: string;
  type: string;
  input: ActivityInput;

  public constructor(init?: Partial<Activity>) {
    Object.assign(this, init);
  }
}


@Injectable({
  providedIn: 'root'
})
export class ActivityAdapter implements Adapter<Activity> {
  adapt(item: any): Activity {
    const activityInputAdapter = new ActivityInputAdapter();
    return new Activity({
      id: item.id,
      revision: item.revision,
      name: item.name,
      destination: item.destination,
      type: item.type,
      input: activityInputAdapter.adapt(item.input)
    });
  }
}
