import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasterListService } from 'app/core/services/masterlist.service';
import { OnepointModalService } from 'app/core/services/onepoint.modal.service';
import { PermissionService } from 'app/core/services/permission.service';
import { SosServicesService } from 'app/core/services/sos-services.service';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import { MasterlistDiagnosisModel } from '@onepoint/shared/models/masterlist-diagnosis-model';
import { MasterlistLanguages } from '@onepoint/master-lists/masterlist-languages';
import { MasterListQuestionnaireModel } from '@onepoint/shared/models/masterlist-questionnaire-model';
import { FLOWS } from '@onepoint/master-lists/common/common';
import { Dictionary } from '@onepoint/shared/models/dictionary';

@Component({
  selector: 'app-masterlist-questionnaire-modal',
  templateUrl: './masterlist-questionnaire-modal.component.html',
  styleUrls: ['./masterlist-questionnaire-modal.component.scss'],
})
export class MasterlistQuestionnaireModalComponent implements OnInit {
  @Input()
  masterListType: MasterListQuestionnaireModel;

  @Output()
  refreshTable = new EventEmitter();

  minDeactivationDate: Date;
  selectedQuestionnaire: any;
  error: string;
  canUpdate: boolean;
  isModelChanged: boolean;
  languages = MasterlistLanguages;

  flowTypes = FLOWS.map(flow => new Dictionary(flow, flow));

  constructor(
    private masterListService: MasterListService,
    private onepointModalService: OnepointModalService,
    private sosServicesService: SosServicesService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.minDeactivationDate = new Date(Date.now() - 86400000);
    this.permissionService.permissionsReadyNotification.subscribe(
      result => (this.canUpdate = this.permissionService.checkUserPermission(PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE))
    );
    if (this.masterListType.activateOn > this.minDeactivationDate) {
      this.minDeactivationDate = this.masterListType.activateOn;
    }
  }

  closeModal() {
    this.onepointModalService.dismissAll();
  }

  get isSaveButtonEnabled(): boolean {
    return (
      this.isModelChanged &&
      this.masterListType &&
      !!this.masterListType.activateOn &&
      !!this.masterListType.questionCode &&
      !!this.masterListType.questionName
    );
  }

  save() {
    if ( this.isNew ) {
      this.masterListService.addQuestionnaire(this.masterListType).subscribe(
        response => {
          this.masterListType = response;
          this.refreshTable.emit();
          this.closeModal();
        },
        err => (this.error = err.error ? err.error.message : err.message)
      );
    } else {
      this.masterListService.updateQuestionnaire(this.masterListType).subscribe(
        response => {
          this.masterListType = response;
          this.refreshTable.emit();
          this.closeModal();
        },
        err => (this.error = err.error ? err.error.message : err.message)
      );
    }
  }

  activateOnChanged(item: MasterListQuestionnaireModel) {
    this.modelChanged();
    item.activateOn.setHours(12, 0);
  }

  deactivateOnChanged(item: MasterListQuestionnaireModel) {
    this.modelChanged();
    item.deactivateOn.setHours(12, 0);
  }

  selectedFlowValueChanged(value: string): void {
    this.modelChanged();
    this.masterListType.flow = value;
  }

  modelChanged(): void {
    this.isModelChanged = true;
  }

  keepOrder = (a, b) => {
    return a;
  }

  get isReadonly(): boolean {
    return !this.canUpdate;
  }

  get isNew(): boolean {
    return this.masterListType.id == null;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
