import { Component, Input } from '@angular/core';
import { PolicyLookupMethodTypeEnum } from '../../../../shared/enums/policy/policy-lookup-method-type.enum';
import {
  RegisterServerPolicyLookupMethodExtension
} from '../../../../shared/models/customers/product/policy/method/register-server-policy-lookup-method-extension';
import { PolicyLookupMethod } from '../../../../shared/models/customers/product/policy/policy-lookup-method';
import { PolicyLookupMethodService } from '../../../../core/services/policy-lookup-method.service';
import { Product } from '../../../../shared/models/product';
import { Dictionary } from '../../../../shared/models/dictionary';
import { ProfileService } from '../../../../core/services/profile.service';

@Component({
  selector: 'app-product-lookup-integration',
  templateUrl: './product-policy-integration.component.html',
  styleUrls: ['./product-policy-integration.component.scss']
})

export class ProductPolicyIntegrationComponent {
  types: Dictionary[];
  policyLookupMethod: PolicyLookupMethod;
  originalPolicyLookupMethod: PolicyLookupMethod;
  contactInfo: string;
  private _product: Product;


  @Input() set product(product: Product) {
    this._product = product;
    if (product) {
      this.policyLookupMethodService.getPolicyLookupMethod(product.id).subscribe(
        result => {
          this.initPolicyLookupMethod(product.id, result);
        },
        error => console.error(error)
      );
      this.profileService.getProfile('' + product.profileId).subscribe(
        result => this.contactInfo = result.contactInfo,
        error => console.error(error)
      );
    }
  }

  get product(): Product {
    return this._product;
  }

  get type(): PolicyLookupMethodTypeEnum {
    return this.policyLookupMethod ? this.policyLookupMethod.type : undefined;
  }

  set type(value: PolicyLookupMethodTypeEnum) {
    this.policyLookupMethod.type = value;
  }

  constructor(private policyLookupMethodService: PolicyLookupMethodService, private profileService: ProfileService) {
    this.types = Object.keys(PolicyLookupMethodTypeEnum)
      .map(key => new Dictionary(PolicyLookupMethodTypeEnum[key], PolicyLookupMethodTypeEnum[key]));
  }

  public typeChanged() {
    if ([PolicyLookupMethodTypeEnum.REGISTER_SERVER, PolicyLookupMethodTypeEnum.OTHER_INTEGRATION, PolicyLookupMethodTypeEnum.NO_INTEGRATION].includes(this.policyLookupMethod.type)) {
      this.policyLookupMethod.extension = new RegisterServerPolicyLookupMethodExtension();
    }
  }



  public isValid(): boolean {
    return this.policyLookupMethod && this.policyLookupMethod.isValid() && this.updated();
  }

  private updated(): boolean {
    return JSON.stringify(this.policyLookupMethod) !== JSON.stringify(this.originalPolicyLookupMethod);
  }

  public save() {
    this.policyLookupMethodService.save(this.policyLookupMethod).subscribe(
      result => {
        this.initPolicyLookupMethod(this.policyLookupMethod.productId, result);
      },
      error => console.error(error)
    );
  }

  get PolicyLookupMethodTypeEnum() {
    return PolicyLookupMethodTypeEnum;
  }

  private initPolicyLookupMethod(productId: number, result: PolicyLookupMethod) {
    if (result === null) {
      this.policyLookupMethod = new PolicyLookupMethod({ productId});
      this.originalPolicyLookupMethod = new PolicyLookupMethod({ productId});
    } else {
      this.policyLookupMethod = result;
      this.originalPolicyLookupMethod = new PolicyLookupMethod(result);
      if (result.extension) {
        this.originalPolicyLookupMethod.extension = result.extension.clone();
      }
    }
  }
}
