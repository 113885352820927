import { ProfileAdapter } from '../../shared/models/profile';
import { BrandAdapter } from '../../shared/models/brand';
import { CustomerListView } from '../../shared/models/customer-list-view';
import { CommonCustomerAdapter } from '../../shared/models/common-customer';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class CustomersService extends BaseService {
  constructor(
    private commonCustomerAdapter: CommonCustomerAdapter,
    private brandAdapter: BrandAdapter,
    private profileAdapter: ProfileAdapter,
    private http: HttpClient,
    private settingsService: SettingsService
  ) {
    super(settingsService);
  }

  public getAllCommonCustomerListView(): Observable<CustomerListView[]> {
    return this.http
      .get(this.baseURL + 'common-customers?includeProfiles=false')
      .pipe(
        map((data: any[]) =>
          data.map(item =>
            this.commonCustomerAdapter.adaptToCommonCustomerListView(item)
          )
        )
      );
  }

  public getAllCommonCustomerFromSearchListView(criteria: string): Observable<CustomerListView[]> {
    return this.http
      .get(this.baseURL + 'common-customers/search/' + criteria)
      .pipe(
        map((data: any[]) =>
          data.map(item =>
            this.commonCustomerAdapter.adaptToCommonCustomerListView(item)
          )
        )
      );
  }

  public getBrandListView(
    commonCustomerId: string
  ): Observable<CustomerListView[]> {
    return this.http
      .get(this.baseURL + 'brand?commonCustomerId=' + commonCustomerId)
      .pipe(
        map((data: any[]) =>
          data.map(item =>
            this.brandAdapter.adaptToCommonCustomerListView(item)
          )
        )
      );
  }

  public getAllBrandListView(): Observable<CustomerListView[]> {
    return this.http
      .get(this.baseURL + 'brand?includeProfiles=false')
      .pipe(
        map((data: any[]) =>
          data.map(item =>
            this.brandAdapter.adaptToCommonCustomerListView(item)
          )
        )
      );
  }

  public getAllBrandFromSearchListView(criteria: string): Observable<CustomerListView[]> {
    return this.http
      .get(this.baseURL + 'brand/search/' + criteria)
      .pipe(
        map((data: any[]) =>
          data.map(item =>
            this.brandAdapter.adaptToCommonCustomerListView(item)
          )
        )
      );
  }

  public getProfileListView(brandId: string): Observable<CustomerListView[]> {
    return this.http
      .get(this.baseURL + 'profile?brandId=' + brandId)
      .pipe(
        map((data: any[]) =>
          data.map(item =>
            this.profileAdapter.adaptToCommonCustomerListView(item)
          )
        )
      );
  }

  public getProfileFromSearchListView(criteria: string): Observable<CustomerListView[]> {
    return this.http
      .get(this.baseURL + 'profile/search/' + criteria)
      .pipe(
        map((data: any[]) =>
          data.map(item =>
            this.profileAdapter.adaptToCommonCustomerListView(item)
          )
        )
      );
  }

  public getAllProfileListView(): Observable<CustomerListView[]> {
    return this.http
      .get(this.baseURL + 'profile?includeProducts=false')
      .pipe(
        map((data: any[]) =>
          data.map(item =>
            this.profileAdapter.adaptToCommonCustomerListView(item)
          )
        )
      );
  }

  public getCommonCustomerCount(): Observable<any> {
    return this.http.get(this.baseURL + 'common-customers/count');
  }

  public getBrandCount(): Observable<any> {
    return this.http.get(this.baseURL + 'brand/count');
  }

  public getProfileCount(): Observable<any> {
    return this.http.get(this.baseURL + 'profile/count');
  }
}
