import {Component, ViewChild} from '@angular/core';
import {DigitalCaseflowService} from "@onepoint/core/services/digital-caseflow.service";
import {EditDcfActivityComponent} from "./edit-activity/edit-dcf-activity.component";
import {Activity} from "@onepoint/shared/models/digital-caseflow/activity";
import {ActivityInput} from "@onepoint/shared/models/digital-caseflow/activity-input";

@Component({
  selector: 'app-dcf-activities',
  templateUrl: './dcf-activities.component.html',
  styleUrls: ['./dcf-activities.component.scss']
})
export class DcfActivitiesComponent{
  activities: Activity[];
  @ViewChild('editDcfActivityComponent')
  editDcfActivityComponent: EditDcfActivityComponent;
  constructor( private digitalCaseflowService: DigitalCaseflowService) {
    this.editUpdated();
  }

  createActivity(){
    const act = new Activity();
    act.input = new ActivityInput();
    this.editDcfActivityComponent.deletePossible = false;
    this.editDcfActivityComponent.open(act);
  }

  editActivity(activity: Activity){
    this.editDcfActivityComponent.open(activity);
  }

  editUpdated() {
    this.digitalCaseflowService.getAllActivities().subscribe(data => {
      this.activities = data;
    });
  }

}
