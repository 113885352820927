import { Component, Input } from '@angular/core';
import { Profile } from '../../../../shared/models/profile';
import { ProfileService } from '../../../../core/services/profile.service';

@Component({
  selector: 'app-profile-contact-info',
  templateUrl: './profile-contact-info.component.html',
  styleUrls: ['./profile-contact-info.component.scss']
})
export class ProfileContactInfoComponent {
  @Input() set profileId(profileId: string) {
    if (profileId != null) {
      this.profileService.getProfile(profileId).subscribe(
        result => {
          this.profile = result;
          this.textContent = result.contactInfo;
        },
        error => console.error(error)
      );
    }
  }
  profile = new Profile();
  textContent: string;
  textChanged = false;

  constructor(private profileService: ProfileService) {}

  saveContactInfo() {
    this.profile.contactInfo = this.textContent;
    this.profileService.updateProfile(this.profile).subscribe(
      result => {
        this.profile = result;
        this.textContent = result.contactInfo;
        this.textChanged = false;
      },
      error => console.error(error)
    );
  }
}
