import {Injectable} from "@angular/core";

export class ProductDebtorOverrule {
  id: number;
  overruled: boolean;
  productId: number;
  debtorId: string;
  debtorNumber: string;
  debtorCurrency: string;
  remarks: string;

  public constructor(init?: Partial<ProductDebtorOverrule>) {
    if (init != null) {
      Object.assign(this, init);
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProductDebtorOverruleAdapter {
  adapt(item: any): ProductDebtorOverrule {
    if (item === null) {
      return null;
    }
    return new ProductDebtorOverrule({
      id: item.id,
      overruled: item.overruled,
      productId: item.productId,
      debtorId: item.debtorId,
      debtorNumber: item.debtorNumber,
      debtorCurrency: item.debtorCurrency,
      remarks: item.remarks
    });
  }
}
