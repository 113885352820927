<div class="edit-sos-services">
  <app-edit-sos-services
    [sosServices]="sosServices"
    (changes)="handleChanges($event)"
    [readonly]="false"
  ></app-edit-sos-services>
  <button
    class="primary-button"
    (click)="onEdit()"
    [ngClass]="{ 'button-disabled': !canSave }"
    [disabled]="!canSave"
  >
    Save changes
  </button>
</div>

<div class="delete-container justify-content-between">
  <div class="text my-auto">
    <div class="black-text">
      Delete SOS Services
    </div>
    <div class="gray-text" *ngIf="!deletable">
      The SOS Service is used on a price agreement and can not be deleted.
    </div>
  </div>
  <button
    class="primary-button delete-button my-auto"
    (click)="delete()"
    [class.button-disabled]="!deletable"
  >
    Delete
  </button>
</div>
