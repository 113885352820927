<div class="page-container">
  <div class="page-header">
    <div class="my-auto counts-container">
      <div class="count-container">
        <span>Channel Types</span> <span>{{ masterListFiltered?.length }}</span>
      </div>
    </div>
    <div class="status-container my-auto">
      <app-drop-down [(selectedItemId)]="selectedStatus" [items]="statusTypes" (selectedItemIdChange)="selectedStatusTypeChanged($event)">
      </app-drop-down>
    </div>
    <div class="button-container my-auto">
      <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_CREATE">
        <button class="primary-button" (click)="addNew()">
          Add Channel type
        </button>
      </div>
    </div>
  </div>
  <div class="primary-table page-content-container">
    <table class="master-lists-table">
      <thead>
        <tr>
          <td (click)="showFilteringSortingMenu(ChannelTypeMenuType.CHANNEL_TYPE_CODE)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Business key</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[ChannelTypeMenuType.CHANNEL_TYPE_CODE] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ChannelTypeMenuType.CHANNEL_TYPE_CODE"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ChannelTypeMenuType.CHANNEL_TYPE_NAME)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Channel Type name</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[ChannelTypeMenuType.CHANNEL_TYPE_NAME] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ChannelTypeMenuType.CHANNEL_TYPE_NAME"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ChannelTypeMenuType.CHANNEL_TYPE_ENUM_VALUE)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Channel Type Enum value</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[ChannelTypeMenuType.CHANNEL_TYPE_ENUM_VALUE] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ChannelTypeMenuType.CHANNEL_TYPE_ENUM_VALUE"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ChannelTypeMenuType.UI_SORT_INDEX)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Sort in UI</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[ChannelTypeMenuType.UI_SORT_INDEX] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ChannelTypeMenuType.UI_SORT_INDEX"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ChannelTypeMenuType.DESCRIPTION)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Description</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[ChannelTypeMenuType.DESCRIPTION] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ChannelTypeMenuType.DESCRIPTION"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ChannelTypeMenuType.ACTIVATE_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Activation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[ChannelTypeMenuType.ACTIVATE_ON] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ChannelTypeMenuType.ACTIVATE_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(ChannelTypeMenuType.DEACTIVATION_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Deactivation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[ChannelTypeMenuType.DEACTIVATION_ON] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="ChannelTypeMenuType.DEACTIVATION_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of masterListFiltered" (click)="edit(item)">
          <td>
            <div class="first-column-container">
              <div class="first-column-content">
                {{ item.channelTypeCode }}
              </div>
            </div>
          </td>
          <td>
            {{ item.channelTypeName }}
          </td>
          <td>
            {{ item.channelTypeEnumValue }}
          </td>
          <td>
            {{ item.uiSortIndex }}
          </td>
          <td>
            {{ item.description }}
          </td>
          <td>
            {{ item.activateOn | date: 'dd MMM yyyy' }}
          </td>
          <td>
            {{ item.deactivateOn | date: 'dd MMM yyyy' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-template #MasterListModal>
    <app-masterlist-channel-type-modal [masterListType]="masterListType" (refreshTable)="reload()"> </app-masterlist-channel-type-modal>
  </ng-template>
</div>
