import { Component, OnInit } from '@angular/core';
import { MasterListService } from 'app/core/services/masterlist.service';
import { OnepointModalService } from 'app/core/services/onepoint.modal.service';
import { MasterListUploadInfo } from 'app/shared/models/masterlist-upload-info';
import { MasterListUploadStatistics } from 'app/shared/models/masterlist-upload-statistics';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import { finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'app-masterlist-upload-modal',
  templateUrl: './masterlist-upload-modal.component.html',
  styleUrls: ['./masterlist-upload-modal.component.scss'],
})
export class MasterListUploadModalComponent implements OnInit {
  static readonly APPLICATION_NAME = 'secca';

  fileToUpload: File;
  uploadStatistics: MasterListUploadStatistics;
  error: string;
  masterListUploadInfo: MasterListUploadInfo;
  uploading = false;

  constructor(private masterListService: MasterListService,
              private onepointModalService: OnepointModalService) {}

  ngOnInit() {
    this.uploading = false;
    this.fetchUploadInfo();
  }

  closeModal() {
    this.onepointModalService.dismissAll();
  }

  isUploadButtonEnabled(): boolean {
    return !!this.fileToUpload && !this.uploading;
  }

  handleFileInput(files: FileList) {
    if ( files && files.length != 0 ) {
      this.uploading = false;
      this.uploadStatistics = null;
      this.error = null;
      this.fileToUpload = files.item(0);
    }
  }

  humanFileSize(size): string {
    const i = (size == 0) ? 0 : Math.floor( Math.log(size) / Math.log(1024) );
    return ( size / Math.pow(1024, i) ).toFixed(2) + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
  };

  upload() {
    this.uploading = true;
    this.uploadStatistics = null;
    this.error = null;
    this.masterListService.uploadTranslations(this.fileToUpload, MasterListUploadModalComponent.APPLICATION_NAME).pipe(
      finalize(() => {
        this.uploading = false;
      })
    )
    .subscribe(
      response => {
        this.uploadStatistics = response;
        this.fetchUploadInfo();
      },
      err => {
        this.error = err.error ? err.error.message : err.message;
      }
    )
  }

  private fetchUploadInfo(): void {
    this.masterListService.getUploadedTranslationsInfo(MasterListUploadModalComponent.APPLICATION_NAME).subscribe(
      response => this.masterListUploadInfo = response
    )
  }
}
