import { TemplatesListUpdateService } from './../templates-list-update-service';
import { OnInit, Component, Input, ApplicationRef, QueryList, ViewChildren } from '@angular/core';
import { DocumentTemplate } from 'app/shared/models/templates/document.template';
import { TemplateFilteringSortingMenuType } from './template-filtering-sorting-menu/template-filtering-sorting-menu-type';
import { TemplateService } from 'app/core/services/templates/template.service';
import { TemplateFilteringSortingMenuComponent } from './template-filtering-sorting-menu/template-filtering-sorting-menu.component';

@Component({
  selector: 'app-document-templates-table',
  templateUrl: './document-templates-table.component.html',
  styleUrls: ['./document-templates-table.component.scss']
})
export class DocumentTemplatesTableComponent implements OnInit {
  @ViewChildren(TemplateFilteringSortingMenuComponent) filteringComponents: QueryList<TemplateFilteringSortingMenuComponent>;
  templates: DocumentTemplate[];
  filteredTemplates: DocumentTemplate[];
  showFilteringMenu: boolean[] = [];

  constructor(
    private templateService: TemplateService,
    private applicationRef: ApplicationRef,
    private updateService: TemplatesListUpdateService
  ) {
  }

  ngOnInit() {
    this.loadTemplates();
  }

  private loadTemplates() {
    this.templateService.getAllTemplates().subscribe(templates => {
      this.templates = templates;
      this.filteredTemplates = templates;
      this.filteringComponents.forEach(component => component.populateItems(templates));
      this.updateService.change(this.filteredTemplates);
    },
      error => console.log(error));
  }

  reload() {
    this.loadTemplates();
  }

  get FilteringSortingMenuType() {
    return TemplateFilteringSortingMenuType;
  }

  finishedAndFilteringSorting() {
    this.showFilteringSortingMenu(TemplateFilteringSortingMenuType.empty);
  }

  clearAllFiltering() {
    this.filteredTemplates = this.templates;
    this.updateService.change(this.filteredTemplates);
    this.applicationRef.tick();
    this.showFilteringSortingMenu(TemplateFilteringSortingMenuType.empty);
  }

  onListChange(newList: DocumentTemplate[]) {
    this.filteredTemplates = newList;
    this.updateService.change(this.filteredTemplates);
  }

  showFilteringSortingMenu(filteringSortingMenuType: TemplateFilteringSortingMenuType) {
    for (let i = 0; i < this.showFilteringMenu.length; i++) {
      if (i !== filteringSortingMenuType) {
        this.showFilteringMenu[i] = false;
      }
    }
    this.showFilteringMenu[filteringSortingMenuType] = !this.showFilteringMenu[
      filteringSortingMenuType
    ];
  }

  closeFilteringSortingMenu() {
    for (let i = 0; i < this.showFilteringMenu.length; i++) {
      if (this.showFilteringMenu[i]) {
        this.showFilteringMenu[i] = false;
      }
    }
  }

}
