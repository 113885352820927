import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Product } from '../../../../../../shared/models/product';
import { ProductService } from '../../../../../../core/services/product.service';


@Component({
  selector: 'app-edit-terms',
  templateUrl: './edit-terms.component.html',
  styleUrls: ['./edit-terms.component.scss'],
})
export class EditTermsComponent {
  @Input() product: Product;
  @Output() updated = new EventEmitter<void>();
  @ViewChild('content', { static: true }) template: TemplateRef<any>;
  productId: number = null;
  termsName = '';
  filename = '';
  file: File;
  ableToSave = false;
  buttonLabel: string;

  constructor(private modalService: NgbModal, private productService: ProductService) {}

  open() {
    this.ableToSave = false;
    if (this.product != null) {
      this.productId = this.product.id;
      this.termsName = this.product.termsName;
      this.filename = this.product.termsDocumentName;
      this.buttonLabel = this.termsName != null ? 'Edit' : 'Select';
    }
    this.modalService.open(this.template, { windowClass: 'modal-container' });
  }

  fileChanged(event) {
    if (event.target.value.endsWith('.pdf')) {
      this.buttonLabel = 'Edit';
      this.file = event.target.files[0];
      const idx = event.target.value.lastIndexOf('\\');
      this.filename = event.target.value.substring(idx + 1);
      this.evaluateAbleToSave();
    }
  }

  evaluateAbleToSave() {
    this.ableToSave = this.termsName != null && this.termsName.length > 0 && !!this.filename;
  }

  save() {
    if (this.file) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(this.file);
      let fileContent: string | ArrayBuffer;
      reader.onload = evt => {
        fileContent = (evt.target as FileReader).result;
        this.productService.addTerms(fileContent, this.termsName, this.filename, this.productId).subscribe(
          result => {
            this.close();
            this.updated.emit();
          },
          error => console.error(error)
        );
      };
    } else {
      const product = this.product;
      product.termsName = this.termsName;
      this.productService.updateProduct(product).subscribe(
        result => {
          this.close();
          this.updated.emit();
        },
        error => console.error(error)
      );
    }
  }

  close() {
    this.modalService.dismissAll();
  }
}
