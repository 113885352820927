import { Component, OnInit, ViewChild } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ConfirmModalComponent } from '../modal/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss']
})
// @AutoUnsubscribe
export class SignOutComponent implements OnInit {
  @ViewChild('signOutWarningConfirmModal') signOutWarningConfirmModal: ConfirmModalComponent;

  constructor(private keycloakService: KeycloakService) { }

  ngOnInit(): void { }

  signOutWarning(): void {
    this.signOutWarningConfirmModal.open('Warning', 'Do you want to sign out?');
  }

  signOut(): void {
    this.keycloakService.logout('https://www.sos.eu/da/');
  }
}
