<div class="page-container">
  <div class="page-header">
    <div class="my-auto counts-container">
      <div class="count-container">
        <span>Refund Types</span> <span>{{ masterListFiltered?.length }}</span>
      </div>
    </div>
    <div class="status-container my-auto">
      <app-drop-down [(selectedItemId)]="selectedStatus" [items]="statusTypes" (selectedItemIdChange)="selectedStatusTypeChanged($event)">
      </app-drop-down>
    </div>
    <div class="button-container my-auto">
      <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_CREATE">
        <button class="primary-button" (click)="addNew()">
          Add Refund Type
        </button>
      </div>
    </div>
  </div>
  <div class="primary-table page-content-container">
    <table class="master-lists-table">
      <thead>
      <tr>
        <td class="filter-column unsortable-column"></td>
        <td class="filter-column unsortable-column"></td>
        <td class="filter-column unsortable-column"></td>
        <td class="filter-column unsortable-column"></td>
        <td class="filter-column unsortable-column"></td>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of masterListFiltered" (click)="edit(item)">
        <td>
          <div class="first-column-container">
            <div class="first-column-content">
              {{ item.businessKey }}
            </div>
          </div>
        </td>
        <td>
          {{ item.refundTypeName }}
        </td>
        <td>
          {{ item.uiSort }}
        </td>
        <td>
          {{ item.activateOn | date: 'dd MMM yyyy' }}
        </td>
        <td>
          {{ item.deactivateOn | date: 'dd MMM yyyy' }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <ng-template #MasterListModal>
    <app-refund-type-modal [masterListType]="masterListType" (refreshTable)="reload()"> </app-refund-type-modal>
  </ng-template>
</div>
