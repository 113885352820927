import { SosServices } from './../../models/sos-services';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Product } from 'app/shared/models/product';
import { CommonCustomer } from 'app/shared/models/common-customer';
import { Profile } from 'app/shared/models/profile';
import { Brand } from 'app/shared/models/brand';
import { MasterListOverviewModel } from 'app/shared/models/masterlist-overview-model';
import { SupplierProfile } from 'app/shared/models/supplier-profile';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsMenuUpdateService {
  updateBrand: BehaviorSubject<Brand> = new BehaviorSubject(null);
  updateProfile: BehaviorSubject<Profile> = new BehaviorSubject(null);
  updateProduct: BehaviorSubject<Product> = new BehaviorSubject(null);
  updateCommonCustomer: BehaviorSubject<CommonCustomer> = new BehaviorSubject(null);
  updateSosService: BehaviorSubject<SosServices> = new BehaviorSubject(null);
  updateMasterListService: BehaviorSubject<MasterListOverviewModel> = new BehaviorSubject(null);
  updateSupplierProfile: BehaviorSubject<SupplierProfile> = new BehaviorSubject(null);

  updatedProfile(profile: Profile) {
    this.updateProfile.next(profile);
  }
  updatedBrand(brand: Brand) {
    this.updateBrand.next(brand);
  }
  updatedProduct(product: Product) {
    this.updateProduct.next(product);
  }
  updatedCommonCustomer(commonCustomer: CommonCustomer) {
    this.updateCommonCustomer.next(commonCustomer);
  }
  updatedSosService(sosService: SosServices) {
    this.updateSosService.next(sosService);
  }
  updatedMasterList(masterList: MasterListOverviewModel) {
    this.updateMasterListService.next(masterList);
  }
  updatedSupplierProfile(supplierProfile: SupplierProfile) {
    this.updateSupplierProfile.next(supplierProfile);
  }
}
