<div class="menu-container">
  <div class="sorting-ascending" (click)="sortAscending()">
    <div class="two-row-letters">
      <div>A</div>
      <div class="two-row-letters-second-row">Z</div>
    </div>
    <div class="sort-arrow"><img src="assets/icons/Down.svg" /></div>
    <div class="sort-type">Ascending</div>
  </div>
  <div class="small-separator"></div>
  <div class="sorting-descending" (click)="sortDescending()">
    <div class="two-row-letters">
      <div>Z</div>
      <div class="two-row-letters-second-row">A</div>
    </div>
    <div class="sort-arrow"><img src="assets/icons/Up.svg" /></div>
    <div class="sort-type">Descending</div>
  </div>
</div>
