export enum ProductsFilteringSortingMenuTypeEnum {
  name = 0,
  insuranceLevel = 1,
  sosProductId = 2,
  customerProductId = 3,
  productVersion = 4,
  businessArea = 5,
  validFrom = 6,
  validTo = 7,
  deactivationDate = 8,
  empty = 9,
  debtor = 10,
  policyLookupMethodType,
  policyLookupMethodCode
}
