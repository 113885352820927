
<div class="page-container">
  <div class="page-header">
      <div class="my-auto counts-container">
        <div class="count-container">
          <span>SECCA AD teams</span> <span>{{masterListFiltered?.length}}</span>
        </div>
      </div>
      <div class="status-container my-auto">
        <app-drop-down [(selectedItemId)]="selectedStatus"
                       [items]="statusTypes"
                       (selectedItemIdChange)="selectedStatusTypeChanged($event)">
        </app-drop-down>
      </div>
      <div class="button-container my-auto">
        <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_CREATE">
          <button class="primary-button" (click)="addNew()">
            Add new team
          </button>
        </div>
      </div>
  </div>
  <div class="primary-table page-content-container">
    <table class="master-lists-table">
      <thead>
        <tr>
          <td (click)="showFilteringSortingMenu(AdTeamsMenuType.ADT_CODE)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Business key</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(AdTeamsMenuType.ADT_CODE) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="AdTeamsMenuType.ADT_CODE"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(AdTeamsMenuType.TEAM_NAME)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Team name</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(AdTeamsMenuType.TEAM_NAME) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="AdTeamsMenuType.TEAM_NAME"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(AdTeamsMenuType.TEAM_ID)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Team ID</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(AdTeamsMenuType.TEAM_ID) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="AdTeamsMenuType.TEAM_ID"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(AdTeamsMenuType.UI_SORT_INDEX)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Sort in UI</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(AdTeamsMenuType.UI_SORT_INDEX) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="AdTeamsMenuType.UI_SORT_INDEX"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(AdTeamsMenuType.TEAM_NAME_ALT)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Alt. team name</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(AdTeamsMenuType.TEAM_NAME_ALT) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="AdTeamsMenuType.TEAM_NAME_ALT"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(AdTeamsMenuType.TEAM_TYPE)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Team type</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(AdTeamsMenuType.TEAM_TYPE) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="AdTeamsMenuType.TEAM_TYPE"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(AdTeamsMenuType.SUB_TEAM_IDS)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Mindkey ID</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(AdTeamsMenuType.SUB_TEAM_IDS) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="AdTeamsMenuType.SUB_TEAM_IDS"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(AdTeamsMenuType.DEPARTMENT_NAME)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Department</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(AdTeamsMenuType.DEPARTMENT_NAME) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="AdTeamsMenuType.DEPARTMENT_NAME"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(AdTeamsMenuType.CISCO_TEAM_ID)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Cisco ID</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(AdTeamsMenuType.CISCO_TEAM_ID) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="AdTeamsMenuType.CISCO_TEAM_ID"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(AdTeamsMenuType.ACTIVATE_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Activation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(AdTeamsMenuType.ACTIVATE_ON) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="AdTeamsMenuType.ACTIVATE_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(AdTeamsMenuType.DEACTIVATION_ON)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Deactivation date</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!isShowMenu(AdTeamsMenuType.DEACTIVATION_ON) && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="AdTeamsMenuType.DEACTIVATION_ON"
                [masterList]="masterListFiltered"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of masterListFiltered" (click)="edit(item)">
          <td>
            <div class="first-column-container">
              <div class="first-column-content">
                {{ item.adTCode }}
              </div>
            </div>
          </td>
          <td>
            {{ item.teamName }}
          </td>
          <td>
            {{ item.teamId }}
          </td>
          <td>
            {{ item.uiSortIndex }}
          </td>
          <td>
            {{ item.teamNameAlt }}
          </td>
          <td>
            {{ item.teamType }}
          </td>
          <td>
            {{ item.subTeamIds }}
          </td>
          <td>
            {{ item.departmentName }}
          </td>
          <td>
            {{ item.ciscoTeamId }}
          </td>
          <td>
            {{ item.activateOn | date: 'dd MMM yyyy'}}
          </td>
          <td>
            {{ item.deactivateOn | date: 'dd MMM yyyy'}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-template #MasterListModal>
    <app-masterlist-ad-teams-modal [masterListItem]="masterListItem" (refreshTable)="reload()">
    </app-masterlist-ad-teams-modal>
  </ng-template>
</div>
