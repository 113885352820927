import { Component, Input } from '@angular/core';
import { Product } from '../../../../shared/models/product';
import { Profile } from '../../../../shared/models/profile';
import { DictionaryService } from '../../../../core/services/dictionary.service';
import { DictionaryHelper } from '../../../../shared/helpers/dictionary-helper';
import { BusinessAreaEnum } from '../../../../shared/models/enums';
import { CoveragePeriodTypeEnum } from '../../../../shared/enums/coverage-period-type-enum';
import { InsuranceTypeEnum } from '../../../../shared/enums/insurance-type-enum';
import { ProfileCountryListsService } from '../../../../core/services/profile-country-lists.service';
import { Kam, KamAdapter } from 'app/shared/models/kam';
import { ProfileService } from 'app/core/services/profile.service';

@Component({
  selector: 'app-product-overview',
  templateUrl: './product-overview.component.html',
  styleUrls: ['./product-overview.component.scss'],
})
export class ProductOverviewComponent {
  @Input() set product(value) {
    if (value) {
      this._product = value;
      this.profileService.getProfile('' + this._product.profileId).subscribe(
        result => {
          this.kam = this.getKam(result);
        },
        error => console.error(error)
      );
      this.initCountryLists();
    }
  }
  get product() {
    return this._product;
  }
  private _product: Product;
  kam: Kam;

  purposesOfTravel: Map<string, string>;
  geographicalCoverageAreas: Map<number, string>;
  coveragePeriodTypes = new Map<string, string>([
    [CoveragePeriodTypeEnum.annual, 'Annual'],
    [CoveragePeriodTypeEnum.single, 'Single Trip'],
  ]);
  insuranceTypes = new Map<string, string>([
    [InsuranceTypeEnum.regular, 'Regular'],
    [InsuranceTypeEnum.card, 'Card'],
  ]);
  personGroups = new Map<string, string>([
    ['HOUSEHOLD', 'Household'],
    ['POLICY_HOLDER', 'Policy Holder'],
    ['OTHER', 'Other'],
  ]);

  constructor(
    private profileService: ProfileService,
    private dictionaryService: DictionaryService,
    private countryListService: ProfileCountryListsService,
    private kamAdapter: KamAdapter
  ) {
    this.dictionaryService.getAllPurposeOfTravels().subscribe(
      result => (this.purposesOfTravel = DictionaryHelper.convertToMap(result)),
      error => console.error(error)
    );
  }

  private getKam(profile: Profile): Kam {
    if (this.product.businessArea === BusinessAreaEnum.TRAVELCARE) {
      return this.kamAdapter.adapt({
        id: profile.responsibleKamTravelUserId,
        firstName: profile.responsibleKamTravelFirstName,
        surname: profile.responsibleKamTravelSurname,
        areas: [BusinessAreaEnum.TRAVELCARE],
      });
    } else if (this.product.businessArea === BusinessAreaEnum.MOBILITY) {
      return this.kamAdapter.adapt({
        id: profile.responsibleKamRoadsideUserId,
        firstName: profile.responsibleKamRoadsideFirstName,
        surname: profile.responsibleKamRoadsideSurname,
        areas: [BusinessAreaEnum.MOBILITY],
      });
    } else if (this.product.businessArea === BusinessAreaEnum.HEALTHCARE) {
      return this.kamAdapter.adapt({
        id: profile.responsibleKamHealthUserId,
        firstName: profile.responsibleKamHealthFirstName,
        surname: profile.responsibleKamHealthSurname,
        areas: [BusinessAreaEnum.HEALTHCARE],
      });
    }
  }

  initCountryLists() {
    this.countryListService.getAll(this.product.profileId).subscribe(
      result => {
        const areaMap = new Map<number, string>();
        result.forEach(cl => areaMap.set(cl.id, cl.name));
        this.geographicalCoverageAreas = areaMap;
      },
      error => console.error(error)
    );
  }
}
