import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MasterListService } from 'app/core/services/masterlist.service';
import { Dictionary } from 'app/shared/models/dictionary';
import { MasterListReductionModel } from 'app/shared/models/masterlist-reduction-model';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import * as _ from 'lodash';
import { OnepointModalService, Size } from 'app/core/services/onepoint.modal.service';
import { ReductionMenuType } from './reduction-menu/masterlist-reduction-menu-type';

@Component({
  selector: 'app-masterlist-reduction-type',
  templateUrl: './masterlist-reduction.component.html',
  styleUrls: ['./masterlist-reduction.component.scss'],
})
export class MasterlistReductionComponent implements OnInit {
  @ViewChild('MasterListModal')
  private masterListModalTemplate: TemplateRef<any>;

  masterList: MasterListReductionModel[];
  masterListFiltered: MasterListReductionModel[];
  masterListType: MasterListReductionModel;
  showMenu = new Map<ReductionMenuType, boolean>();
  serviceTypesMap = new Map<string, string>();
  statusTypes: Dictionary[] = [
    { id: 'all', name: 'All' },
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
  ];
  selectedStatus = 'all';

  
  get PermissionEnum() {
    return PermissionEnum;
  }
  
  get ReductionMenuType() {
    return ReductionMenuType;
  }

  constructor(private masterListService: MasterListService, private onepointModalService: OnepointModalService) {}

  ngOnInit(): void {
    this.loadMasterList();
  }

  addNew(): void {
    this.masterListType = new MasterListReductionModel();
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.LARGE, 'reduction-modal-container');
  }

  selectedStatusTypeChanged(status: string) {
    this.masterListFiltered = this.filterByStatus(status);
  }

  private loadMasterList() {
    this.masterListService.getReductionList().subscribe(result => {
      this.masterList = result;
      this.masterListFiltered = result;
    });
  }

  private filterByStatus(status: string): MasterListReductionModel[] {
    if (status === 'all') {
      return this.masterList;
    }

    return this.masterList.filter(item => (status === 'active' ? item.active : !item.active));
  }

  showFilteringSortingMenu(filteringSortingMenuType: ReductionMenuType) {
    this.showMenu.forEach((value, key) => {
      if (key !== filteringSortingMenuType) {
        this.showMenu[key] = false;
      }
    });

    this.showMenu[filteringSortingMenuType] = !this.showMenu[filteringSortingMenuType];
  }

  edit(item: MasterListReductionModel) {
    this.masterListType = _.clone(item);
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.LARGE, 'reduction-modal-container');
  }

  reload() {
    this.loadMasterList();
  }
}
