import { BusinessAreaEnum } from 'app/shared/models/enums';
import { FilteringSortingMenuType } from './filtering-sorting-menu-type';
import { Injectable } from '@angular/core';
import { CustomersTableRow } from '../customers-table-row';

@Injectable({
  providedIn: 'root',
})
export class FilteringService {
  filteringStep: FilteringStep[] = [];
  constructor() {}

  public addStep(filteringSortingMenuType: FilteringSortingMenuType, selectedIds: string[], dateFrom: Date = null, dateTo: Date = null) {
    this.filteringStep.push(new FilteringStep(filteringSortingMenuType, selectedIds, dateFrom, dateTo));
  }

  public removeStep(filteringSortingMenuType: FilteringSortingMenuType) {
    let itemToDelete = this.filteringStep.find(a => a.filteringSortingMenuType === filteringSortingMenuType);
    if (itemToDelete) {
      this.filteringStep.splice(this.filteringStep.indexOf(itemToDelete));
    }
  }

  public filter(rows: CustomersTableRow[]): CustomersTableRow[] {
    let tempRows: Set<CustomersTableRow>;

    this.filteringStep.forEach(step => {
      tempRows = new Set();
      switch (step.filteringSortingMenuType) {
        case FilteringSortingMenuType.customer:
          step.selectedIds.forEach(element => {
            rows.filter(a => a.name === element).forEach(a => tempRows.add(a));
          });
          break;
        case FilteringSortingMenuType.market:
          step.selectedIds.forEach(element => {
            rows.filter(a => a.market.indexOf(element) >= 0).forEach(a => tempRows.add(a));
          });
          break;
        case FilteringSortingMenuType.segment:
          step.selectedIds.forEach(element => {
            rows.filter(a => a.segment === element).forEach(a => tempRows.add(a));
          });
          break;
        case FilteringSortingMenuType.subBusinessRelation:
          step.selectedIds.forEach(element => {
            rows.filter(a => a.subBusinessRelation.name.indexOf(element) >= 0).forEach(a => tempRows.add(a));
          });
          break;
        case FilteringSortingMenuType.integrationType:
          step.selectedIds.forEach(element => {
            rows.filter(a => a.integrationType.indexOf(element) >= 0).forEach(a => tempRows.add(a));
          });
          break;
        case FilteringSortingMenuType.businessArea:
          step.selectedIds.forEach(element => {
            rows
              .filter(
                a =>
                  (a.isHealth && element === BusinessAreaEnum.HEALTHCARE) ||
                  (a.isRoadside && element === BusinessAreaEnum.MOBILITY) ||
                  (a.isTravel && element === BusinessAreaEnum.TRAVELCARE)
              )
              .forEach(a => tempRows.add(a));
          });
          break;
        case FilteringSortingMenuType.nextNegotiation:
          rows
            .filter(element => step.dateFrom <= element.nextNegotationDate && step.dateTo >= element.nextNegotationDate)
            .forEach(a => tempRows.add(a));
          break;
      }

      rows = Array.from(tempRows);
    });
    return rows;
  }
}

export class FilteringStep {
  filteringSortingMenuType: FilteringSortingMenuType;
  selectedIds: string[];
  dateFrom: Date;
  dateTo: Date;
  constructor(filteringSortingMenuType: FilteringSortingMenuType, selectedIds: string[], dateFrom: Date, dateTo: Date) {
    this.filteringSortingMenuType = filteringSortingMenuType;
    this.selectedIds = selectedIds;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
  }
}
