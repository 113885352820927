import {Component, OnInit, TemplateRef, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { ProfileCountryListsService } from 'app/core/services/profile-country-lists.service';
import { ProfileCountryListDetail } from 'app/shared/models/profile-country-list-detail';
import { ProfileCountryList } from 'app/shared/models/profile-country-list';
import { Dictionary } from 'app/shared/models/dictionary';
import { DualListboxItem } from 'app/shared/components/dual-listbox/dual-listbox.item';
import { CountryService } from 'app/core/services/country.service';
import { Country } from 'app/shared/models/country';
import { OnepointModalService, Size } from '../../../../../core/services/onepoint.modal.service';

@Component({
  selector: 'app-profile-country-list',
  templateUrl: './profile-country-list.component.html',
  styleUrls: ['./profile-country-list.component.scss']
})
export class ProfileCountryListComponent implements OnInit {

  @Input() profileId: number;
  @ViewChild('content', { static: true }) template: TemplateRef<any>;
  @Output() changed = new EventEmitter<ProfileCountryListDetail>();

  countryList: ProfileCountryListDetail;
  continentsOptions: Dictionary[] = [{id: '', name: 'Worldwide'}];
  continentCode = '';
  searchQuery;

  constructor(
    private countryListsService: ProfileCountryListsService,
    private countryService: CountryService,
    private modalService: OnepointModalService
  ) {
    this.countryService.getAllContinents().subscribe(data => {
      this.continentsOptions = this.continentsOptions.concat(
        data.map(item => {
          return {id: item.code, name: item.name};
        })
      );
    });
    this.countryList = new ProfileCountryListDetail();
  }

  countries: Array<DualListboxItem> = [];
  left: Array<DualListboxItem> = [];
  right: Array<DualListboxItem> = [];

  ngOnInit() {
    this.clear();
  }

  selectContinent() {
    this.left = this.countries.filter(item => !this.right.find(selected => selected === item));
    if (!this.continentCode) {
      return;
    }
    this.left = this.left.filter(item => item.object.continentCode === this.continentCode);
  }

  save() {
    if (this.countryList.id) {
      this.update();
    } else {
      this.create();
    }
  }

  update() {
    this.countryList.countries = this.right.map(item => item.object);
    this.countryListsService.update(this.countryList).subscribe(
      result => {
        this.modalService.dismissAll();
        this.countryList = result;
        this.changed.emit(this.countryList);
      },
      error => {
        console.log(error);
      }
    );
  }
  create() {
    this.countryList.countries = this.right.map(item => item.object);
    this.countryListsService.save(this.countryList).subscribe(
      result => {
        this.close();
        this.countryList = result;
        this.changed.emit(this.countryList);
      },
      error => {
        console.log(error);
      }
    );
  }

  clear() {
    this.countryList = new ProfileCountryListDetail( {
      name: null,
      profileId: this.profileId
    });
    this.left = [];
    this.right = [];
    this.continentCode = '';
    this.searchQuery = '';
  }

  public openNewListModal() {
    this.clear();
    this.countryService.getAllCountries().subscribe(countries => {
      this.countries = countries.map(this.createDualListboxItem);
      this.left = this.countries;
      this.modalService.openWithCustomWindowClass(this.template, Size.LARGE, 'modal-container onepoint-wide-modal');
    });
  }

  public openEditListModal(data: ProfileCountryList) {
    this.clear();
    this.countryListsService.get(data.id).subscribe(list => {
      this.countryList = list;
      this.right = list.countries.map(this.createDualListboxItem);
      const countryIds = list.countries.map(item => {
        return item.id;
      });
      this.countryService.getAllCountries().subscribe(countries => {
        this.countries = countries.map(this.createDualListboxItem);
        this.left = this.countries.filter(item => !countryIds.includes(item.id));
        this.modalService.openWithCustomWindowClass(this.template, Size.LARGE, 'modal-container onepoint-wide-modal');
      });
    }, error => console.log(error));
  }

  private createDualListboxItem(item: Country): DualListboxItem {
    return new DualListboxItem(item.id, item.countryName, item);
  }

  close() {
    this.modalService.dismissAll();
  }

  get isValid() {
    return !this.isEmpty(this.countryList.name);
  }

  get numberOfCoveredCountries() {
    return this.right.length;
  }

  isEmpty(obj) {
    if (obj && (obj instanceof Date)) {
      return false;
    }
    if (typeof obj === 'number') {
      return false;
    }
    return !obj || !Object.values(obj).some(x => x !== void 0);
  }
}
