import {Component, ViewChild} from '@angular/core';
import {DigitalCaseflowService} from "@onepoint/core/services/digital-caseflow.service";
import {EditDcfValidationComponent} from "./edit-validation/edit-dcf-validation.component";
import {Activity} from "@onepoint/shared/models/digital-caseflow/activity";
import {ActivityInput} from "@onepoint/shared/models/digital-caseflow/activity-input";

@Component({
  selector: 'app-dcf-validations',
  templateUrl: './dcf-validations.component.html',
  styleUrls: ['./dcf-validations.component.scss']
})
export class DcfValidationsComponent {
  validations: Activity[];
  @ViewChild('editDcfValidationComponent')
  editDcfValidationComponent: EditDcfValidationComponent;
  constructor( private digitalCaseflowService : DigitalCaseflowService) {
    this.editUpdated();
  }

  createValidation(){
    let act = new Activity();
    act.type = "VALIDATION";
    act.input = new ActivityInput();
    this.editDcfValidationComponent.open(act);
  }

  editValidation(activity: Activity){
    this.editDcfValidationComponent.open(activity);
  }

  editUpdated() {
    this.digitalCaseflowService.getAllValidations().subscribe(data => {
      this.validations = data;
    });
  }

}
