import { Dictionary } from './dictionary';
import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class PriceAgreement {
  id: string;
  contractId: string;
  name: string;
  currency: Dictionary = { id: null, name: '' };
  indexId: number;
  firstRegulation: string;
  suspended: boolean;
  categories?: PriceAgreementCategory[];
  servicesNotOnPriceAgreementExist = false;

  public constructor(init?: Partial<PriceAgreement>) {
    Object.assign(this, init);
  }

  trim() {
    this.categories.forEach(c => c.trim());
  }

  getCategory(key: string): PriceAgreementCategory {
    return this.categories.find(c => c.categoryId === key);
  }
}

@Injectable({
  providedIn: 'root',
})
export class PriceAgreementAdapter implements Adapter<PriceAgreement> {
  adapt(item: any): PriceAgreement {
    return new PriceAgreement({
      id: item.id,
      contractId: item.contractId,
      name: item.name,
      currency: item.currency || { id: null, name: '' },
      indexId: item.indexId,
      firstRegulation: item.firstRegulation,
      suspended: item.suspended,
      categories: item.categories.map(c => this.adaptCategory(c)),
      servicesNotOnPriceAgreementExist: item.servicesNotOnPriceAgreementExist
    });
  }

  adaptCategory(item: any): PriceAgreementCategory {
    return new PriceAgreementCategory({
      id: item.id,
      categoryId: item.categoryId,
      included: item.included,
      services: item.services.map(s => this.adaptService(s)),
      currency: item.currency,
    });
  }

  adaptService(item: any): PriceAgreementCategoryService {
    return new PriceAgreementCategoryService({
      id: item.id,
      serviceId: item.serviceId,
      included: item.included,
      prices: item.prices,
      shares: item.shares,
      years: item.years
    });
  }
}

export class PriceAgreementCategory {
  id: number;
  categoryId: string;
  included: boolean;
  services: PriceAgreementCategoryService[];
  currency: string;

  constructor(init?: Partial<PriceAgreementCategory>) {
    Object.assign(this, init);
  }

  trim() {
    if (!this.included) {
      this.services = null;
    } else {
      this.services.forEach(s => s.trim());
    }
  }
}

export class PriceAgreementCategoryService {
  id: string;
  serviceId: number;
  included: boolean;
  prices: number[];
  shares: number[];
  years: number[];

  constructor(init?: Partial<PriceAgreementCategoryService>) {
    Object.assign(this, init);
  }

  trim() {
    if (!this.included) {
      this.prices = null;
      this.shares = null;
      this.years = null;
    }
  }
}
