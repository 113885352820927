<div class="edit-supplier-container">
  <div class="edit-supplier-header">Supplier Profile settings</div>
  <div class="container">
    <div *ngIf="!!supplierProfile" class="row edit-supplier-details">
      <div class="col-sm">
        <div class="edit-supplier-input">
          <app-input name="Supplier profile code" trim="true" [required]="true" [disabled]="readonly || supplierProfile.id"
            [(value)]="supplierProfile.supplierProfileCode" (valueChange)="dataChanged()"></app-input>
        </div>
        <div class="edit-supplier-input">
          <app-input name="Supplier profile name" trim="true" [required]="true" [disabled]="readonly"
            [(value)]="supplierProfile.supplierProfileName" (valueChange)="dataChanged()"></app-input>
        </div>
        <div class="edit-supplier-input">
          <app-input name="Provider ID" trim="true" [required]="true" [disabled]="readonly" [(value)]="supplierProfile.providerId"
            (valueChange)="dataChanged()" [pattern]="'[0-9]*'"></app-input>
        </div>
        <div class="edit-supplier-input">
          <app-input name="Creditor number" trim="true" [disabled]="readonly" [(value)]="supplierProfile.creditorNumber"
            (valueChange)="dataChanged()"></app-input>
        </div>
        <div class="edit-supplier-input">
          <app-input name="Creditor name" trim="true" [disabled]="readonly" [(value)]="supplierProfile.creditorName"
            (valueChange)="dataChanged()"></app-input>
        </div>
        <div class="edit-supplier-input">
          <app-input name="Creditor currency" trim="true" [disabled]="readonly" [(value)]="supplierProfile.creditorCurrency"
            (valueChange)="dataChanged()"></app-input>
        </div>
        <div class="edit-supplier-input">
          <app-drop-down-multiselect [(selectedItems)]="primaryErpCreditors"
                                     (selectedItemsChange)="primaryErpCreditorChanged($event)"
                                     [singleValue]="true"
                                     [dropdownItemList]="allErpCreditors"
                                     [name]="'Primary creditor'"
                                     [textField]="'displayName'"
                                     [disabled]="readonly"
                                     required="true">
          </app-drop-down-multiselect>
        </div>
        <div class="edit-supplier-input">
          <app-drop-down-multiselect [(selectedItems)]="supplierProfile.secondaryErpCreditors"
                                     [dropdownItemList]="allErpCreditors"
                                     [name]="'Secondary creditors'"
                                     [textField]="'displayName'"
                                     (selectedItemsChange)="dataChanged()"
                                     [disabled]="readonly">
          </app-drop-down-multiselect>
        </div>
      </div>
      <div class="col-sm">
        <div class="edit-supplier-input">
          <app-kam-drop-down name="Network manager" [required]='true' [(selectedKamId)]="supplierProfile.networkManagerId"
            [(selectedKamFirstName)]="supplierProfile.networkManagerFirstname"
            [(selectedKamSurname)]="supplierProfile.networkManagerSurname"
            [(selectedKamEmail)]="supplierProfile.networkManagerEmail"
            [(selectedKamPhone)]="supplierProfile.networkManagerPhone" (selectedKamIdChange)="dataChanged()"
            [disabled]="readonly" [adtCode]="'ADT18'"></app-kam-drop-down>
        </div>
        <div class="edit-supplier-input">
          <app-input-calendar [required]="true" [withImage]="true" [selectMode]="'single'" [disabled]="readonly"
            name="Start date" [(dateTimeRange)]="supplierProfile.startDate" (dateTimeRangeChange)="dataChanged()">
          </app-input-calendar>
        </div>
        <div class="edit-supplier-input">
          <app-input-calendar [required]="false" [withImage]="true" [selectMode]="'single'"
            [minDate]="supplierProfile.startDate" [disabled]="readonly" name="End date"
            [(dateTimeRange)]="supplierProfile.endDate" (dateTimeRangeChange)="dataChanged()"></app-input-calendar>
        </div>
        <div class="edit-supplier-input">
          <app-drop-down name="Type" [required]='true' [disabled]="readonly" [items]="supplierTypes"
            [(selectedItemId)]="supplierProfile.type" (selectedItemIdChange)="dataChanged()"></app-drop-down>
        </div>
        <div class="input">
          <span class="input-name">Automated approval</span>
          <div>
            <div class="form-check form-check-inline">
              <input class="me-2" type="radio" [(ngModel)]='supplierProfile.automatedApproval'
                     name="automatedApproval" id="automatedApprovalTrue" [value]="true" [disabled]="readonly"
                     (ngModelChange)="dataChanged()">
              <label class="form-check-label" for="automatedApprovalTrue">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="me-2" type="radio" [(ngModel)]='supplierProfile.automatedApproval'
                     name="automatedApproval" id="automatedApprovalFalse" [value]="false" [disabled]="readonly"
                     (ngModelChange)="dataChanged()">
              <label class="form-check-label" for="automatedApprovalFalse">No</label>
            </div>
          </div>
        </div>
        <div class="edit-supplier-input" *ngIf="supplierProfile.automatedApproval">
          <div class="input-name">Approval score</div>
          <input currencyMask required [(ngModel)]="supplierProfile.approvalScore"
                 [disabled]="readonly" (ngModelChange)="dataChanged()" class="input-component"
                 [options]="{ nullable: true, prefix: '', thousands: '.', decimal: ',', precision: 2, max: 100 }" />
        </div>
        <div class="edit-supplier-input" *ngIf="supplierProfile.automatedApproval">
          <div class="input-name">Approval limit factor</div>
          <input currencyMask required [(ngModel)]="supplierProfile.approvalLimitFactor"
                 [disabled]="readonly" (ngModelChange)="dataChanged()" class="input-component"
                 [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2, max: 100 }" />
        </div>
      </div>
      <div class="col-lg-auto">
        <div>
          <div class="d-flex" *ngFor="let partner of supplierProfilePartners; index as idx" >
            <div class="partner me-2">
              <app-drop-down
                name="Partner"
                [disabled]="readonly"
                [items]="partners"
                [(selectedItemId)]="partner.supplierProfilePartnerId"
                (selectedItemIdChange)="supplierProfilePartnerDataChanged()">
              </app-drop-down>
            </div>
            <div class="calendar me-2">
              <app-input-calendar [required]="true" [withImage]="true" [selectMode]="'single'" [disabled]="readonly"
                name="Start date" [(dateTimeRange)]="partner.startDate" (dateTimeRangeChange)="supplierProfilePartnerDataChanged()">
              </app-input-calendar>
            </div>
            <div class="calendar me-2">
              <app-input-calendar [required]="false" [withImage]="true" [selectMode]="'single'"
              [minDate]="partner.startDate" [disabled]="readonly" name="End date"
              [(dateTimeRange)]="partner.endDate" (dateTimeRangeChange)="supplierProfilePartnerDataChanged()"></app-input-calendar>
            </div>
            <div class="delete me-2">
              <button (click)="deleteSupplierProfilePartner(idx)" class="btn" [disabled]="isSupplierPartnerProfileEmpty(partner) || readonly">
                <img  src="assets/icons/garbage-red.svg" alt="Delete supplier profile partner"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
