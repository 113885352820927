import { KamAdapter } from 'app/shared/models/kam';
import { CustomerTypeEnum } from 'app/shared/enums/customerType.enum';
import { CustomerListView } from 'app/shared/models/customer-list-view';

export class CustomersTableRow {
  id: string;
  parentId: string;
  visible: boolean;
  active: boolean;
  type: string;
  name: string;
  expanded: boolean;
  market: string;
  kam: string;
  segment: string;
  subBusinessRelation: {id: string, name: string};
  integrationType: string;
  isTravel: boolean;
  isRoadside: boolean;
  isHealth: boolean;
  nextNegotationDate: Date;

  constructor(kamAdapter: KamAdapter, customerListView: CustomerListView) {
    this.id = customerListView.id;
    this.parentId = customerListView.parentId;
    this.expanded = false;
    this.visible = customerListView.type === CustomerTypeEnum.commonCustomer;
    this.active = customerListView.active;
    this.type = customerListView.type;
    this.name = customerListView.name;
    this.market = customerListView.market;
    this.kam = this.getKamFullName(kamAdapter, customerListView);
    this.segment = customerListView.segment;
    this.subBusinessRelation = customerListView.subBusinessRelation;
    this.integrationType = customerListView.integrationType;
    this.isTravel = customerListView.isTravel;
    this.isRoadside = customerListView.isRoadside;
    this.isHealth = customerListView.isHealth;
    this.nextNegotationDate = customerListView.nextNegotationDate;
  }

  isBrand(): boolean {
    return this.type === CustomerTypeEnum.brand;
  }
  isProfile(): boolean {
    return this.type === CustomerTypeEnum.profile;
  }
  isCommonCustomer(): boolean {
    return this.type === CustomerTypeEnum.commonCustomer;
  }

  private getKamFullName(kamAdapter: KamAdapter, customerListView: CustomerListView): string {
    if (this.type === CustomerTypeEnum.commonCustomer || this.type === CustomerTypeEnum.brand) {
      return kamAdapter.adapt({
        id: customerListView.keyAccountManagerUserId,
        firstName: customerListView.keyAccountManagerFirstName,
        surname: customerListView.keyAccountManagerSurname
      }).getFullName();
    } else if (this.type === CustomerTypeEnum.profile) {
      const kams = customerListView.getProfileKams(kamAdapter);
      if (kams.length === 1) {
        return kams[0].getFullName();
      } else {
        return 'See Profile';
      }
    }
  }
}
