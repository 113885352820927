import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from 'app/core/services/helper.service';
import { KamService } from 'app/core/services/kam.service';
import { SupplierProfileService } from 'app/core/services/supplier-profile.service';
import { AutoUnsubscribe } from 'app/shared/decorators/auto-unsubscribe';
import { Dictionary } from 'app/shared/models/dictionary';
import { SupplierProfile } from 'app/shared/models/supplier-profile';
import { forkJoin, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { FilteringService } from './supplier-filtering-service';
import { SupplierMenuType } from './supplier-menu-type';

@Component({
  selector: 'app-supplier-filtering-sorting-menu',
  templateUrl: './supplier-filtering-sorting-menu.component.html',
  styleUrls: ['./supplier-filtering-sorting-menu.component.scss'],
})
@AutoUnsubscribe
export class SupplierFilteringSortingMenuComponent implements OnInit {
  @Input() supplierProfileListView: SupplierProfile[];
  @Input() filteringSortingMenuType: SupplierMenuType;
  @Output() modelChanged = new EventEmitter();
  @Output() supplierProfileListViewChange = new EventEmitter();
  @Output() clearAllFilters = new EventEmitter();
  sortByAscending: boolean;
  dateFilter: boolean;
  showFilter: boolean;
  sortingKey: string;
  fromDate: any;
  toDate: any;
  items = [];
  supplierProfileSubscription: Subscription;

  constructor(
    private supplierProfileService: SupplierProfileService,
    private kamService: KamService,
    private filteringService: FilteringService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.populateItems();
  }

  sortAscending() {
    this.sortByAscending = true;
    this.helperService.sortArray(this.supplierProfileListView, this.sortingKey, this.sortByAscending);
    this.modelChanged.emit();
  }

  sortDescending() {
    this.sortByAscending = false;
    this.helperService.sortArray(this.supplierProfileListView, this.sortingKey, this.sortByAscending);
    this.modelChanged.emit();
  }

  populateItems() {
    switch (this.filteringSortingMenuType) {
      case SupplierMenuType.supplierProfileCode:
        this.sortingKey = 'supplierProfileCode';
        this.showFilter = false;
        break;
      case SupplierMenuType.supplierProfileName:
        this.sortingKey = 'supplierProfileName';
        this.showFilter = false;
        break;
      case SupplierMenuType.providerId:
        this.sortingKey = 'providerId';
        this.showFilter = false;
        break;
      case SupplierMenuType.creditorNumber:
        this.sortingKey = 'creditorNumber';
        this.showFilter = false;
        break;
      case SupplierMenuType.creditorName:
        this.sortingKey = 'creditorName';
        this.showFilter = false;
        break;
      case SupplierMenuType.creditorCurrency:
        this.sortingKey = 'creditorCurrency';
        this.showFilter = false;
        break;
      case SupplierMenuType.networkManagerId:
        this.sortingKey = 'networkManagerId';
        this.showFilter = true;
        this.items = [];
        this.kamService
          .getUserListByADTCode('ADT18')
          .subscribe(result => result.forEach(kam => this.items.push({ id: kam.userId, name: kam.getFullName(), check: false })));
        break;
      case SupplierMenuType.startDate:
        this.dateFilter = true;
        let year = new Date().getFullYear();
        let month = new Date().getMonth() + 1;
        let day = new Date().getDate();
        this.fromDate = new NgbDate(year, month, day);
        this.toDate = new NgbDate(year + 1, month, day);
        this.sortingKey = 'startDate';
        this.showFilter = true;
        break;
      case SupplierMenuType.endDate:
        {
          this.dateFilter = true;
          let year = new Date().getFullYear();
          let month = new Date().getMonth() + 1;
          let day = new Date().getDate();
          this.fromDate = new NgbDate(year, month, day);
          this.toDate = new NgbDate(year + 1, month, day);
          this.sortingKey = 'endDate';
          this.showFilter = true;
        }
        break;
      case SupplierMenuType.type:
        {
          this.sortingKey = 'type';
          this.showFilter = true;
          this.items = [];
          let typesSubscr: Subscription = null;
          typesSubscr = this.supplierProfileService.getSupoTypeList().subscribe(result => {
            if (result) {
              result.forEach(supoType => this.items.push({ id: supoType.id.toString(), name: supoType.name, check: false }));
              typesSubscr?.unsubscribe();
            }
          });
        }
        break;
      case SupplierMenuType.partner:
        {
          this.sortingKey = 'partner';
          this.showFilter = true;
          this.items = [];
          let typesSubscr: Subscription = null;
          typesSubscr = this.supplierProfileService.getSupoTypeList().subscribe(supoTypes => {
            if (supoTypes) {
              const supplierTypeId = supoTypes.find(st => st.type === 'PARTNER')?.id;
              this.supplierProfileSubscription = this.supplierProfileService.getAllSupplierProfiles().subscribe(supplierProfiles => {
                this.items = [];
                if (supplierProfiles) {
                  supplierProfiles
                    .filter(p => p.type === supplierTypeId)
                    .forEach(p => this.items.push({ id: p.id.toString(), name: p.supplierProfileName, check: false }));
                }
              });
              typesSubscr?.unsubscribe();
            }
          });
        }
        break;
    }
  }

  get hasUserCheckedAnything() {
    return this.dateFilter || this.items?.filter(item => item.check).length > 0;
  }

  applyButtonClick() {
    if (!this.hasUserCheckedAnything) {
      return;
    }
    this.clearAllFilters.emit();
    let selectedIds = [];
    this.items.filter(a => a.check).forEach(a => selectedIds.push(a.id));
    if (selectedIds.length > 0 || this.dateFilter) {
      this.filteringService.removeStep(this.filteringSortingMenuType);
      this.filteringService.addStep(
        this.filteringSortingMenuType,
        selectedIds,
        this.fromDate ? new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day) : new Date(),
        this.toDate ? new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day) : new Date()
      );
    } else {
      this.clearAllButtonClick();
    }
    this.supplierProfileListView = this.filteringService.filter(this.supplierProfileListView);
    this.supplierProfileService.changedSupplierProfileCount(this.supplierProfileListView);
    this.supplierProfileListViewChange.emit(this.supplierProfileListView);
    this.modelChanged.emit();
  }

  clearAllButtonClick() {
    if (!this.hasUserCheckedAnything) {
      return;
    }
    this.items.forEach(a => (a.check = false));
    this.filteringService.removeStep(this.filteringSortingMenuType);
    this.clearAllFilters.emit();
    this.supplierProfileListView = this.filteringService.filter(this.supplierProfileListView);
    this.supplierProfileService.changedSupplierProfileCount(this.supplierProfileListView);
    this.supplierProfileListViewChange.emit(this.supplierProfileListView);
  }
  modalWindowClick(event: any) {
    event.stopPropagation();
  }

  isLast() {
    return this.filteringSortingMenuType && this.filteringSortingMenuType === SupplierMenuType.partner;
  }
}
