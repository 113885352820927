import { BusinessAreaEnum } from './enums';
import { Adapter } from '../interfaces/adapter';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

export class Contract {
  id: string;
  commonCustomer: string;
  title: string;
  linkToDatabase: string;
  businessArea: BusinessAreaEnum;
  commencementDate: Date;
  terminationDate: Date;
  nextNegotiationDate: Date;

  public constructor(init?: Partial<Contract>) {
    Object.assign(this, init);
  }

  public overlaps(contracts: Contract[]): boolean {
    return contracts.filter(c => this.overlapsSingle(c)).length > 0;
  }

  private overlapsSingle(contract: Contract): boolean {
    if (this.businessArea !== contract.businessArea || this.id === contract.id) {
      return false;
    }
    return (this.commencementDate <= contract.commencementDate && this.terminationDate == null) ||
      (contract.commencementDate <= this.commencementDate && contract.terminationDate == null) ||
      (this.commencementDate <= contract.terminationDate && this.terminationDate >= contract.commencementDate) ||
      (contract.commencementDate <= this.commencementDate && contract.terminationDate >= this.commencementDate);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ContractAdapter implements Adapter<Contract> {
  adapt(item: any): Contract {
    const contract = new Contract({
      id: item.id,
      commonCustomer: item.commonCustomer,
      title: item.title,
      linkToDatabase: item.linkToDatabase,
      businessArea: item.businessArea,
      commencementDate:
        item.commencementDate === '' ? null : new Date(item.commencementDate),
      terminationDate:
        item.terminationDate === '' || item.terminationDate == null
          ? null
          : new Date(item.terminationDate),
      nextNegotiationDate:
        item.nextNegotiationDate === '' || item.nextNegotiationDate == null
          ? null
          : new Date(item.nextNegotiationDate)
    });
    if (contract.commencementDate) {
      contract.commencementDate.setHours(0, 0, 0, 0);
    }
    if (contract.nextNegotiationDate) {
      contract.nextNegotiationDate.setHours(0, 0, 0, 0);
    }
    if (contract.terminationDate) {
      contract.terminationDate.setHours(0, 0, 0, 0);
    }
    return contract;
  }

  adaptForServiceCall(contract: Contract) {
    return {
      id: contract.id,
      commonCustomer: contract.commonCustomer,
      title: contract.title,
      linkToDatabase: contract.linkToDatabase,
      businessArea: contract.businessArea,
      commencementDate: this.adaptDate(contract.commencementDate),
      terminationDate: this.adaptDate(contract.terminationDate),
      nextNegotiationDate: this.adaptDate(contract.nextNegotiationDate)
    };
  }

  adaptDate(date: Date): string {
    return date == null ? null : moment(date).format('YYYY-MM-DD');
  }
}
