export enum ReasonForCancellationMenuType {
  REASON_FOR_CANCELLATION_CODE = 'reasonForCancellationCode',
  REASON_FOR_CANCELLATION_NAME = 'reasonForCancellationName',
  UI_SORT_INDEX = 'uiSortIndex',
  MODAL_TYPE = 'serviceType',
  DESCRIPTION = 'description',
  ACTIVATE_ON = 'activateOn',
  DEACTIVATION_ON = 'deactivateOn',
  AVAILABLE_TRANSLATION = 'availableTranslation'
}
