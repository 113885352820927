<div class="page-container">
    <div class="page-header header">
        <button class="primary-button button" (click)="addNewSlaCalculatedEvent()">Add new event</button>
    </div>
    <div class="page-content-container">
        <table class="primary-table sos-services-table">
            <thead>
              <tr>
                <td sortColumn [data]="slaCalculatedEvents" >
                  Name
                </td>
                <td sortColumn [data]="slaCalculatedEvents" >
                  Event
                </td>
              </tr>
            </thead>
            <tbody class="theBody">
              <ng-container *ngFor="let slaCalculatedEvent of slaCalculatedEvents; index as i">
                <tr (click)="showExistingSlaCalculatedEvent(slaCalculatedEvent.id)">
                  <td>
                    {{ slaCalculatedEvent.name }}
                  </td>
                  <td>
                    {{ slaCalculatedEvent.calculatedEventType }}
                  </td>
              </ng-container>
            </tbody>
          </table>
    </div>
</div>

<app-add-sla-calculated-event #modal (addSlaCalculatedEvent)="reload()"></app-add-sla-calculated-event>
