import {Injectable} from '@angular/core';
import {Adapter} from './../../interfaces/adapter';
import {Activity, ActivityAdapter} from "@onepoint/shared/models/digital-caseflow/activity";

export class Step {
  id: number;
  revision: number;
  activity: Activity;
  executionOrder: number

  public constructor(init?: Partial<Step>) {
    Object.assign(this, init);
  }
}


@Injectable({
  providedIn: 'root'
})
export class StepAdapter implements Adapter<Step> {
  adapt(item: any): Step {
    const activityAdapter = new ActivityAdapter();
    return new Step({
      id: item.id,
      revision: item.revision,
      executionOrder: item.executionOrder,
      activity: activityAdapter.adapt(item.activity)
    });
  }
}
