<div class="left-menu-container">
  <div class="left-tabs">
    <img class="logo" src="assets/icons/sos-logo.png" />
  </div>
  <div class="left-tabs">
    <div *permission='PermissionEnum.CUSTOMER_AND_SOS_SERVICE_READ; hideType:PermissionHideTypeEnum.REMOVE'
      class="left-tabs-item"
      [ngClass]="{ 'left-tabs-item-selected': isTabSelected(RoutesName.customers) }"
      (click)="customersClick()"
      [routerLink]="['/customers']"
    >
      <div class="left-tabs-label">Customers</div>
    </div>
    <div *permission='PermissionEnum.CUSTOMER_AND_SOS_SERVICE_READ; hideType:PermissionHideTypeEnum.REMOVE'
      class="left-tabs-item"
      [ngClass]="{ 'left-tabs-item-selected': isTabSelected(RoutesName.customerProfileRelations) }"
      (click)="customerProfileRelationsClick()"
      [routerLink]="['/customer-profile-relations']"
    >
      <div class="left-tabs-label">Customer profile relations</div>
    </div>
    <div *permission='PermissionEnum.CUSTOMER_AND_SOS_SERVICE_READ; hideType:PermissionHideTypeEnum.REMOVE'
      class="left-tabs-item"
      [ngClass]="{ 'left-tabs-item-selected': isTabSelected(RoutesName.customerProfileApplications) }"
      (click)="customerProfileApplicationsClick()"
      [routerLink]="['/customer-profile-applications']"
    >
      <div class="left-tabs-label">Customer profile applications</div>
    </div>
    <div *permission='PermissionEnum.CUSTOMER_AND_SOS_SERVICE_READ; hideType:PermissionHideTypeEnum.REMOVE'
      class="left-tabs-item"
      [ngClass]="{ 'left-tabs-item-selected': isTabSelected(RoutesName.customerProfileCaseProcesses) }"
      (click)="customerProfileCaseProcessesClick()"
      [routerLink]="['/customer-profile-case-processes']"
    >
      <div class="left-tabs-label">Customer profile case processes</div>
    </div>
    <div *permission='PermissionEnum.CUSTOMER_AND_SOS_SERVICE_READ; hideType:PermissionHideTypeEnum.REMOVE'
      class="left-tabs-item"
      [ngClass]="{ 'left-tabs-item-selected': isTabSelected(RoutesName.sosServices) }"
      (click)="servicesClick()"
      [routerLink]="['/sos-services']"
    >
      <div class="left-tabs-label">SOS services</div>
    </div>
    <div *permission='PermissionEnum.CUSTOMER_AND_SOS_SERVICE_READ; hideType:PermissionHideTypeEnum.REMOVE'
      class="left-tabs-item"
      [ngClass]="{ 'left-tabs-item-selected': isTabSelected(RoutesName.indexes) }"
      (click)="indexesClick()"
      [routerLink]="['/indexes']"
    >
      <div class="left-tabs-label">Indexes</div>
    </div>
    <div *permission='PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_READ; hideType:PermissionHideTypeEnum.REMOVE'
      class="left-tabs-item"
      [ngClass]="{ 'left-tabs-item-selected': isTabSelected(RoutesName.templates) }"
      (click)="templatesClick()"
      [routerLink]="['/templates']"
    >
      <div class="left-tabs-label">Templates</div>
    </div>
    <div *permission='PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_READ; hideType:PermissionHideTypeEnum.REMOVE'
      class="left-tabs-item"
      [ngClass]="{ 'left-tabs-item-selected': isTabSelected(RoutesName.businessRules) }"
      (click)="BusinessRulesClick()"
      [routerLink]="['/business-rules']"
    >
      <div class="left-tabs-label">Business Rules</div>
    </div>
    <div *permission='PermissionEnum.SUPPLIER_READ; hideType:PermissionHideTypeEnum.REMOVE'
      class="left-tabs-item"
      [ngClass]="{ 'left-tabs-item-selected': isTabSelected(RoutesName.suppliers) }"
      (click)="suppliersClick()"
      [routerLink]="['/suppliers']"
    >
      <div class="left-tabs-label">Suppliers</div>
    </div>

    <div *permission='PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_READ; hideType:PermissionHideTypeEnum.REMOVE'
      class="left-tabs-item"
      [ngClass]="{ 'left-tabs-item-selected': isTabSelected(RoutesName.masterLists) }"
      (click)="masterlistsClick()"
      [routerLink]="['/master-lists']"
    >
      <div class="left-tabs-label">Master lists</div>
    </div>

    <div *permission='PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_READ; hideType:PermissionHideTypeEnum.REMOVE'
      class="left-tabs-item"
      [ngClass]="{ 'left-tabs-item-selected': isTabSelected(RoutesName.slaConfiguration) }"
      (click)="slaConfigurationClick()"
      [routerLink]="['/sla-configuration']"
    >
      <div class="left-tabs-label">SOS SLA</div>
    </div>
    <div *permission='PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_READ; hideType:PermissionHideTypeEnum.REMOVE'
      class="left-tabs-item"
      [ngClass]="{ 'left-tabs-item-selected': isTabSelected(RoutesName.dcf) }"
      (click)="digitalCaseflowClick()"
      [routerLink]="['/digital-caseflow']"
    >
      <div class="left-tabs-label">DCF</div>
    </div>
    <div *permission='PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_READ; hideType:PermissionHideTypeEnum.REMOVE'
      class="left-tabs-item"
      [ngClass]="{ 'left-tabs-item-selected': isTabSelected(RoutesName.erpData) }"
      (click)="erpDataClick()"
      [routerLink]="['/erp-data']"
    >
      <div class="left-tabs-label">ERP Data</div>
    </div>

  </div>
</div>
