<div class="menu">
  <ul class="nav justify-content-center">
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'overview'}" (click)="shownSection = 'overview'">
        <div>Overview</div>
      </div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'settings'}" (click)="shownSection = 'settings'">
        <div>Settings</div>
      </div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'countryLists'}" (click)="shownSection = 'countryLists'">
        <div>Country lists</div>
      </div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'teams'}" (click)="shownSection = 'teams'">
        <div>Teams</div>
      </div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'contactinfo'}" (click)="shownSection = 'contactinfo'">
        <div>Contact &amp; Info</div>
      </div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'applications'}" (click)="shownSection = 'applications'">
        <div>Applications</div>
      </div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'caseProcesses'}" (click)="shownSection = 'caseProcesses'">
        <div>Case processes</div>
      </div>
    </li>
  </ul>
</div>
<app-profile-overview *ngIf="shownSection === 'overview'" [profile]="profile" [products]='products'
(refreshProfile)="refreshProductsEvent($event)"></app-profile-overview>
<app-profile-settings *ngIf="shownSection === 'settings'" [customerListViewOriginal]="profile"></app-profile-settings>
<app-profile-country-lists *ngIf="shownSection === 'countryLists'" [profileId]="+profile.id"></app-profile-country-lists>
<app-profile-teams *ngIf="shownSection === 'teams'" [customerListViewOriginal]="profile">
</app-profile-teams>
<app-profile-contact-info *ngIf="shownSection === 'contactinfo'" [profileId]="profile.id"></app-profile-contact-info>
<app-profile-applications *ngIf="shownSection === 'applications'" [profileId]="profile.id"></app-profile-applications>
<app-profile-case-processes *ngIf="shownSection === 'caseProcesses'" [profileId]="+profile.id"></app-profile-case-processes>
