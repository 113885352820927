import { BusinessAreaEnum } from 'app/shared/models/enums';
import { DictionaryService } from './../../../../core/services/dictionary.service';
import { ProfileService } from './../../../../core/services/profile.service';
import { TeamRelation } from './../../../../shared/models/team-relation';
import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { CustomerListView } from 'app/shared/models/customer-list-view';
import { Dictionary } from 'app/shared/models/dictionary';

@Component({
  selector: 'app-profile-teams',
  templateUrl: './profile-teams.component.html',
  styleUrls: ['./profile-teams.component.scss'],
})
export class ProfileTeamsComponent implements OnInit {
  @Input() customerListViewOriginal: CustomerListView;
  temps: string[];
  newTeamRelation: TeamRelation;
  teamRelations: TeamRelation[];
  teamRelationsEdited: TeamRelation[] = [];
  businessAreas: Dictionary[];
  teams: Dictionary[];
  default = new Date('1970');
  businessAreasDisabled: Dictionary[];
  submitted = false;
  validated = false;
  edit = false;
  newPeriod: TeamRelation;
  newPeriodExists = false;
  currentBusinessArea = 'TRAVELCARE';
  endDateIsSet = true;
  isFieldEdited = false;

  constructor(private profileService: ProfileService, private dictionaryService: DictionaryService) {}

  ngOnInit() {
    this.profileService.getProfileTeamRelations(this.customerListViewOriginal.id, this.currentBusinessArea).subscribe(
      result => {
        this.teamRelations = result;
        this.checkEndDate();
      },
      error => {
        console.log(error);
      }
    );
    this.businessAreas = this.dictionaryService.getBusinessAreas();
    this.businessAreasDisabled = this.businessAreas;
    this.dictionaryService.getTeamsByDepartments().subscribe(result => {
      this.teams = result;
    });
  }

  checkEndDate() {
    if (this.teamRelations[0].end === null) {
      this.endDateIsSet = false;
      return;
    }
  }

  validate(idx: number) {
    this.checkEndDate();
    this.checkIfEdited();
    if (this.teamRelations[idx].businessArea && this.teamRelations[idx].adtCode && this.teamRelations[idx].start) {
      this.validated = true;
      if (!this.newPeriodExists) {
        this.teamRelationsEdited[idx] = this.teamRelations[idx];
      }
    }
  }

  checkIfEdited() {
    if (!this.newPeriod) {
      this.isFieldEdited = true;
    }
  }

  addNewPeriod() {
    this.newPeriod = new TeamRelation();
    this.newPeriodExists = true;
    this.newPeriod.profileId = +this.customerListViewOriginal.id;
    this.newPeriod.businessArea = this.currentBusinessArea;
    this.newPeriod.id = null;
    this.teamRelations.unshift(this.newPeriod);
  }

  removeNewPeriod() {
    this.newPeriodExists = false;
    this.newPeriod = undefined;
    this.teamRelations = this.teamRelations.slice(1);
    this.validated = false;
  }

  switchBusinessArea(businessArea: string) {
    this.currentBusinessArea = businessArea;
    this.profileService.getProfileTeamRelations(this.customerListViewOriginal.id, this.currentBusinessArea).subscribe(
      result => {
        this.teamRelations = result;
      },
      error => {
        console.log(error);
      }
    );
  }

  submit() {
    if (!this.newPeriod) {
      this.submitEdit();
    } else {
      this.submitNew();
    }
  }

  getTeamRelationsFromBusinessArea(businessArea) {
    this.profileService.getProfileTeamRelations(this.customerListViewOriginal.id, businessArea).subscribe(
      result => {
        this.teamRelations = result;
      },
      error => {
        console.log(error);
      }
    );
  }

  submitNew() {
    this.profileService.createTeamRelation(this.newPeriod).subscribe(
      createResult => {
        this.profileService.getProfileTeamRelations(this.customerListViewOriginal.id, this.currentBusinessArea).subscribe(
          result => {
            this.teamRelations = result;
            this.clearNewPeriod();
            this.isFieldEdited = false;
            this.checkEndDate();
          },
          error => {
            console.log(error);
          }
        );
      },
      error => {
        console.log(error);
      }
    );
  }

  submitEdit() {
    this.profileService.updateTeamRelations(this.teamRelationsEdited).subscribe(createResult => {
      this.profileService.getProfileTeamRelations(this.customerListViewOriginal.id, this.currentBusinessArea).subscribe(
        result => {
          this.teamRelations = result;
          this.clearNewPeriod();
          this.teamRelationsEdited = [];
          if (this.teamRelations[0].end) {
            this.endDateIsSet = true;
            this.isFieldEdited = false;
          }
        },
        error => {
          console.log(error);
        }
      );
    });
  }

  private clearNewPeriod() {
    this.validated = false;
    this.newPeriod = null;
    this.newPeriodExists = false;
  }
}
