<div class="customers-toolbar-spinner" *ngIf="!filteredRows && !error">
    <br>
    <img src="./assets/icons/waitForSearchresult.gif">
</div>

<div class="customers-table-container" *ngIf="filteredRows">
  <table class="primary-table common-customers-table">
    <thead>
      <tr>
        <td (click)="showFilteringSortingMenu(FilteringSortingMenuType.customer)" class="filter-column">
          <div [ngStyle]="!showMenu[FilteringSortingMenuType.customer] && {'display':'none'}">
            <app-filtering-sorting-menu (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="FilteringSortingMenuType.customer" (clearAllFilters)="clearAllFiltering()"
              [(rows)]="filteredRows" [setDefault]="true"></app-filtering-sorting-menu>
          </div>
          Customer
        </td>
        <td (click)="showFilteringSortingMenu(FilteringSortingMenuType.kam)" class="filter-column">
          <div [ngStyle]="!showMenu[FilteringSortingMenuType.kam] && {'display':'none'}">
            <app-filtering-sorting-menu (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="FilteringSortingMenuType.kam" (clearAllFilters)="clearAllFiltering()"
              [(rows)]="filteredRows"></app-filtering-sorting-menu>
          </div>
          KAM
        </td>
        <td (click)="showFilteringSortingMenu(FilteringSortingMenuType.market)" class="filter-column">
          <div [ngStyle]="!showMenu[FilteringSortingMenuType.market] && {'display':'none'}">
            <app-filtering-sorting-menu (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="FilteringSortingMenuType.market" (clearAllFilters)="clearAllFiltering()"
              [(rows)]="filteredRows"></app-filtering-sorting-menu>
          </div>
          Market
        </td>
        <td (click)="showFilteringSortingMenu(FilteringSortingMenuType.segment)" class="filter-column">
          <div [ngStyle]="!showMenu[FilteringSortingMenuType.segment] && {'display':'none'}">
            <app-filtering-sorting-menu (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="FilteringSortingMenuType.segment" (clearAllFilters)="clearAllFiltering()"
              [(rows)]="filteredRows"></app-filtering-sorting-menu>
          </div>
          Segment
        </td>
        <td (click)="showFilteringSortingMenu(FilteringSortingMenuType.subBusinessRelation)" class="filter-column">
          <div [ngStyle]="!showMenu[FilteringSortingMenuType.subBusinessRelation] && {'display':'none'}">
            <app-filtering-sorting-menu (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="FilteringSortingMenuType.subBusinessRelation"
              (clearAllFilters)="clearAllFiltering()" [(rows)]="filteredRows">
            </app-filtering-sorting-menu>
          </div>
          Sub-business relation
        </td>
        <td (click)="showFilteringSortingMenu(FilteringSortingMenuType.integrationType)" class="filter-column">
          <div [ngStyle]="!showMenu[FilteringSortingMenuType.integrationType] && {'display':'none'}">
            <app-filtering-sorting-menu (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="FilteringSortingMenuType.integrationType"
              (clearAllFilters)="clearAllFiltering()" [(rows)]="filteredRows">
            </app-filtering-sorting-menu>
          </div>
          Integration type
        </td>
        <td (click)="showFilteringSortingMenu(FilteringSortingMenuType.businessArea)" class="filter-column">
          <div [ngStyle]="!showMenu[FilteringSortingMenuType.businessArea] && {'display':'none'}">
            <app-filtering-sorting-menu (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="FilteringSortingMenuType.businessArea" (clearAllFilters)="clearAllFiltering()"
              [(rows)]="filteredRows">
            </app-filtering-sorting-menu>
          </div>
          <div [ngStyle]="!showMenu[FilteringSortingMenuType.nextNegotiation] && {'display':'none'}">
            <app-filtering-sorting-menu (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="FilteringSortingMenuType.nextNegotiation"
              (clearAllFilters)="clearAllFiltering()" [(rows)]="filteredRows">
            </app-filtering-sorting-menu>
          </div>
          Business area
        </td>
        <td (click)="showFilteringSortingMenu(FilteringSortingMenuType.nextNegotiation)">Next
          negotation</td>

      </tr>
    </thead>
    <tbody>
      <ng-template ngFor let-row [ngForOf]="filteredRows">
        <tr *ngIf="row.visible" (click)="navigate(row)">
          <td [ngClass]="{'intended-column': row.isBrand() || row.isProfile() }">
            <div class="first-column-container">
              <div *ngIf="row.isBrand()" class="brand-intend"><div class="border-hiding"></div></div>
              <div *ngIf="row.isProfile()" class="profile-intend"><div class="border-hiding"></div></div>
              <div class="first-column-content" [ngClass]="{'first-column-content-profile': row.isProfile()}">
                <div [ngClass]="{'common-customer': row.isCommonCustomer()}"></div>
                <div [ngClass]="{'brand': row.isBrand()}"></div>
                <div [ngClass]="{'profile': row.isProfile()}"></div>
                <img (click)="expandCollapse(row, $event)" class="expand-expaned-icon"
                  src="assets/icons/Expand.svg" [ngClass]="{'hidden-icon' : hideExpandIcon(row)}" alt="Expand">
                <img (click)="expandCollapse(row, $event)" class="expand-expaned-icon"
                  src="assets/icons/Expanded.svg" [ngClass]="{'hidden-icon' : hideCollapseIcon(row)}" alt="Collapse">
                {{row.name}}
                <span class="row-type-description" *ngIf="row.isCommonCustomer()">Common Customer</span>
                <span class="row-type-description" *ngIf="row.isBrand()">Brand</span>
                <span class="row-type-description" *ngIf="row.isProfile()">Profile</span>
                <div class="border-bottom-brand" *ngIf="row.isBrand()"></div>
                <div class="border-top-brand" *ngIf="row.isBrand()"></div>
                <div class="border-bottom-profile" *ngIf="row.isProfile()"></div>
                <div class="border-top-common-customer" *ngIf="row.isCommonCustomer()"></div>
              </div>
            </div>
          </td>
          <td>{{row.kam}}</td>
          <td>{{row.market}}</td>
          <td>{{row.segment}}</td>
          <td>{{row.subBusinessRelation.name}}</td>
          <td>{{row.integrationType}}</td>
          <td>
            <img *ngIf="row.isTravel" src="assets/icons/travel-enabled.png">
            <img *ngIf="!row.isTravel" src="assets/icons/travel-disabled.png">
            <img *ngIf="row.isRoadside" src="assets/icons/roadside-enabled.png">
            <img *ngIf="!row.isRoadside" src="assets/icons/roadside-disabled.png">
            <img *ngIf="row.isHealth" src="assets/icons/health-enabled.png">
            <img *ngIf="!row.isHealth" src="assets/icons/health-disabled.png">
          </td>
          <td>{{row.nextNegotationDate | date :'dd. MMM yyyy'}}</td>

        </tr>
      </ng-template>
    </tbody>
  </table>
</div>
