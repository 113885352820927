import {Injectable} from '@angular/core';
import {Adapter} from './../interfaces/adapter';

export class SlaRule {
  id: number;
  name: string;
  type: string;
  calculatedEventType: string;
  slaCaseType: string;
  event: string;
  gracePeriodStart: Date;
  gracePeriodEnd: Date;

  public constructor(init?: Partial<SlaRule>) {
    Object.assign(this, init);
  }
}


@Injectable({
  providedIn: 'root'
})
export class SlaRuleAdapter implements Adapter<SlaRule> {
  adapt(item: any): SlaRule {
    return new SlaRule({
      id: item.id,
      name: item.name,
      type: item.type,
      slaCaseType: item.slaCaseType,
      calculatedEventType: item.calculatedEventType,
      gracePeriodStart: item.gracePeriodStart,
      gracePeriodEnd: item.gracePeriodEnd
    });
  }
}
