<ng-template #content let-modal>
  <div class="onepoint-modal-container">
    <div class="onepoint-modal-header">
      <div class="onepoint-modal-header-title">{{validation.id != null ? 'Edit validation':'Create validation'}}</div>
      <div class="onepoint-modal-header-close"><img (click)="close()" src="assets/icons/Close.svg" alt="Close"></div>
    </div>

    <div class="onepoint-modal-body p-3">
      <div class="top-space">
        <app-input [name]="'Name'" [(value)]="validation.name" (valueChange)="validate()"></app-input>
      </div>
      <div class="top-space">
        <div class="input-title-name">
          Validation Type
        </div>
        <app-drop-down class="dropdown" [items]="validationTypes"
                       [(selectedItemId)]="validation.input.validationType"
                       [disabled]="validation.id != null"
                       (selectedItemIdChange)="validationTypeChanged()"></app-drop-down>
      </div>
      <div *ngIf="validation.input.validationType !== undefined">
        <div class="top-space">
          <app-input [name]="'Validation type description'" [(value)]="description" [disabled]="true"></app-input>
        </div>
      </div>
      <div class="top-space" *ngIf="shouldShow('LENGTH')">
        <app-input [name]="'Length'" [type]="'number'" [(value)]="validation.input.length"
                   (valueChange)="validate()"></app-input>
      </div>
      <div class="top-space" *ngIf="shouldShow('STAKEHOLDER_TYPE')">
        <div class="input-title-name">
          Stakeholder Type
        </div>
        <app-drop-down class="dropdown" [items]="stakeholderTypes"
                       [(selectedItemId)]="validation.input.stakeholderType"
                       (selectedItemIdChange)="validate()"></app-drop-down>
      </div>
      <div class="top-space" *ngIf="shouldShow('COUNTRIES')">
          <app-drop-down-multiselect [(selectedItems)]="selectedCountries"

                                     (selectedItemsChange)="validation.input.countries = getStringArray(selectedCountries); validate()"
                                     [singleValue]="false"
                                     [dropdownItemList]="countries"
                                     [name]="'Countries'"
                                     [textField]="'name'">
          </app-drop-down-multiselect>
      </div>
      <div class="top-space" *ngIf="shouldShow('CASE_TYPES')">
          <app-drop-down-multiselect [(selectedItems)]="selectedCaseTypes"

                                     (selectedItemsChange)="validation.input.caseTypes = getStringArray(selectedCaseTypes); validate()"
                                     [singleValue]="false"
                                     [dropdownItemList]="caseTypes"
                                     [name]="'Case types'"
                                     [textField]="'name'">
          </app-drop-down-multiselect>
      </div>
      <div class="top-space" *ngIf="shouldShow('COMMITMENT_TYPES')">
          <app-drop-down-multiselect [(selectedItems)]="selectedCommitmentTypes"

                                     (selectedItemsChange)="validation.input.commitmentTypes = getStringArray(selectedCommitmentTypes); validate()"
                                     [singleValue]="false"
                                     [dropdownItemList]="commitmentTypes"
                                     [name]="'Commitment types'"
                                     [textField]="'name'">
          </app-drop-down-multiselect>
      </div>
      <div class="top-space" *ngIf="shouldShow('CAUSE_LEVEL_ONES')">
          <app-drop-down-multiselect [(selectedItems)]="selectedCauseLevelOnes"
                                     (selectedItemsChange)="validation.input.causeLevels = getStringArray(selectedCauseLevelOnes); validate()"
                                     [singleValue]="false"
                                     [dropdownItemList]="causeLevelOnes"
                                     [name]="'Cause level 1'"
                                     [textField]="'name'">
          </app-drop-down-multiselect>
      </div>
      <div class="top-space" *ngIf="shouldShow('CAUSE_LEVEL_THREES')">
          <app-drop-down-multiselect [(selectedItems)]="selectedCauseLevelThrees"
                                     (selectedItemsChange)="validation.input.causeLevels = getStringArray(selectedCauseLevelThrees); validate()"
                                     [singleValue]="false"
                                     [dropdownItemList]="causeLevelThrees"
                                     [name]="'Cause level 3'"
                                     [textField]="'name'">
          </app-drop-down-multiselect>
      </div>
      <div class="top-space" *ngIf="shouldShow('PURPOSES_OF_TRAVEL')">
        <app-drop-down-multiselect [(selectedItems)]="selectedPurposesOfTravel"
                                   (selectedItemsChange)="validation.input.purposesOfTravel = getStringArray(selectedPurposesOfTravel); validate()"
                                   [singleValue]="false"
                                   [dropdownItemList]="purposesOfTravel"
                                   [name]="'Purposes of travel'"
                                   [textField]="'name'">
        </app-drop-down-multiselect>
      </div>
      <div class="top-space" *ngIf="shouldShow('SANCTION_CHECK_EXPORT_TYPE')">
        <div class="input-title-name">
          Stakeholder Type
        </div>
        <app-drop-down class="dropdown" [items]="sanctionStakeholderTypes"
                       [(selectedItemId)]="validation.input.sanctionCheckType"
                       (selectedItemIdChange)="validate()"></app-drop-down>
      </div>
      <div class='top-space' *ngIf="shouldShow('SPECIFIC_EMAIL_ADDRESSES')">
        <div class='input-title-name'>
          Specific sender
        </div>
        <app-chip-input class='dropdown' [placeholder]="placeholderSender"
                        (chipsChange)='senderEmails($event)'
                        [chips]='emailList'
                        [validatorFn]='validateSenderEmail'>

        </app-chip-input>
      </div>
      <div class='top-space' *ngIf="shouldShow('EMAIL_ADDRESS_DOMAINS')">
        <div class='input-title-name'>
          Email domain
        </div>
        <app-chip-input class='dropdown' [placeholder]='placeholderDomain'
                        (chipsChange)='senderDomains($event)'
                        [chips]='domainList'
                        [validatorFn]='validateDomain'>
        ></app-chip-input>
      </div>
      <div class='top-space' *ngIf="shouldShow('VALIDATION_NEGATION')">
        <div class='input-title-name'>
          Negate validation
        </div>
        <app-input-switch [id]="'negation'"
                          [value]='validation.input.validationNegation'
                          (valueChange)='setNegation($event.valueOf())'>
        </app-input-switch>
      </div>
      <div class='top-space' *ngIf="shouldShow('SANCTION_ACTION_TYPE')">
        <div class='input-title-name'>
          Sanction action type
        </div>
        <app-drop-down class="dropdown" [items]="sanctionActionTypes"
                       [(selectedItemId)]="validation.input.sanctionActionType"
                       (selectedItemIdChange)="validate()"></app-drop-down>
      </div>
    </div>
    <div class='onepoint-modal-footer'>
      <button class='delete-button' *ngIf='deletePossible'
              (click)='delete()'>Delete
      </button>

      <button class='primary-button' (click)='save()' [ngClass]="!updateNeeded && 'button-disabled'"
              [disabled]='!updateNeeded'>Save
      </button>
    </div>
  </div>
</ng-template>
