import { Component, Input } from '@angular/core';
import { RegisterServerLookupTypeEnum } from '../../../../../shared/enums/policy/register-server-lookup-type.enum';
import { RegisterServerPolicyLookupMethodExtension } from '../../../../../shared/models/customers/product/policy/method/register-server-policy-lookup-method-extension';
import { PolicyLookupMethodExtension } from '../../../../../shared/models/customers/product/policy/policy-lookup-method-extension';
import { Dictionary } from '../../../../../shared/models/dictionary';
import { PolicyLookupMethodTypeEnum } from '@onepoint/shared/enums/policy/policy-lookup-method-type.enum';
import { PolicyLookupMethodService } from '@onepoint/core/services/policy-lookup-method.service';
import { PolicyLookupMethod } from '@onepoint/shared/models/customers/product/policy/policy-lookup-method';

@Component({
  selector: 'app-register-server-integration',
  templateUrl: './register-server-integration.component.html',
  styleUrls: ['./register-server-integration.component.scss']
})

export class RegisterServerIntegrationComponent {

  data: RegisterServerPolicyLookupMethodExtension;
  types: Dictionary[];
  selectedPolicyMethod: PolicyLookupMethod;

  get type(): RegisterServerLookupTypeEnum {
    return this.data?.type ? RegisterServerLookupTypeEnum[this.data.type] : RegisterServerLookupTypeEnum.NONE;
  }

  set type(value: RegisterServerLookupTypeEnum) {
    this.data.type = this.getRegisterServerLookupTypeCode(value);
    if (this.isNoneTypeSelected()) {
      this.data.code = null;
    }
  }

  @Input() set extension(data: PolicyLookupMethodExtension) {
    this.data = data as RegisterServerPolicyLookupMethodExtension;
  }

  @Input() set policyLookupMethodType(enumType){
    if (enumType === PolicyLookupMethodTypeEnum.NO_INTEGRATION){
      this.data.type = this.getRegisterServerLookupTypeCode(RegisterServerLookupTypeEnum.NONE);
    }
}

  @Input() disabled: boolean;
  get RegisterServerLookupTypeEnum() {
    return RegisterServerLookupTypeEnum;
  }

  constructor(private policyLookupMethodService: PolicyLookupMethodService) {
    this.types = Object.keys(RegisterServerLookupTypeEnum).map(key => new Dictionary(RegisterServerLookupTypeEnum[key], RegisterServerLookupTypeEnum[key]));
  }

  isNoneTypeSelected() {
    return RegisterServerLookupTypeEnum[this.data.type] === RegisterServerLookupTypeEnum.NONE;
  }

  private getRegisterServerLookupTypeCode(type: RegisterServerLookupTypeEnum): string {
    for (const key in RegisterServerLookupTypeEnum) {
      if (type === RegisterServerLookupTypeEnum[key]) {
        return key;
      }
    }
    return null;
  }
}
