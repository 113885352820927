<div class="menu">
  <ul class="nav justify-content-center">
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'overview'}" (click)="shownSection = 'overview'">
        <div>Overview</div>
      </div>
    </li>
    <li class="nav-item">
      <div class="nav-link" [ngClass]="{'active': shownSection === 'settings'}" (click)="shownSection = 'settings'">
        <div>Settings</div>
      </div>
    </li>
  </ul>
</div>
<app-sos-services-overview *ngIf="shownSection === 'overview'" [sosServices]="sosServices"></app-sos-services-overview>
<app-sos-services-settings *ngIf="shownSection === 'settings'" [sosServicesOriginal]="sosServices"
                           (sosServicesChanged)="onSosServiceUpdate($event)"></app-sos-services-settings>

