import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ServerWarningModalConfiguration} from '../../../models/server-warning-modal-configuration';

@Component({
  selector: 'app-warning-global-modal',
  templateUrl: './server-warning-modal.component.html',
  styleUrls: ['./server-warning-modal.component.scss']
})
export class ServerWarningModalComponent{
  @Input() configuration: ServerWarningModalConfiguration;
  @Input() closeBtnLabel = 'Close';
  @Output() closed = new EventEmitter<any>();

  close() {
    this.closed.emit();
  }


}
