export class MasterListAdTeamsModel {
  id: string;
  adTCode: string;
  teamId: string;
  teamName: string;
  teamNameAlt: string;
  ciscoTeamId: string;
  teamType: string
  subTeamIds: string;
  adDepartmentsId: number;
  departmentId: string;
  departmentName: string;
  defaultLanguage: string;
  defaultChannel: string;
  uiSortIndex: number;
  activateOn: Date;
  deactivateOn: Date;
  active: boolean;
  applications: string[];

  public constructor(init?: Partial<MasterListAdTeamsModel>) {
    Object.assign(this, init);
  }
}
