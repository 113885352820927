import * as _ from 'lodash';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Product, ProductAdapter } from '../../../../shared/models/product';
import {ProductService} from '../../../../core/services/product.service';
import {ConfirmModalComponent} from '../../../../shared/components/modal/confirm-modal/confirm-modal.component';
import {Router} from '@angular/router';
import {RoutesName} from '../../../../app-routes-name';
import {BreadcrumbsMenuUpdateService} from 'app/shared/components/breadcrumbs-menu/breadcrumbs-menu-update-service';

@Component({
  selector: 'app-product-settings',
  templateUrl: './product-settings-page.component.html',
  styleUrls: ['./product-settings-page.component.scss']
})

export class ProductSettingsPageComponent {
  @Output() changed = new EventEmitter<Product>();

  @Input() set product(value) {
    this.profileId = value.profileId;
    this.persistedProduct = value;
    this._product = _.cloneDeep(value) as Product;
    this.deleteEnabled = false;
    this.getProducts();
  }

  get product() {
    return this._product;
  }

  persistedProduct: Product;
  _product: Product;
  profileId: number;
  @ViewChild('deleteConfirmModal')
  deleteConfirmModal: ConfirmModalComponent;
  products: Product[] = [];

  uniqueViolations: Product[] = [];
  mandatoryMet: boolean;
  deleteEnabled = false;

  constructor(
    private productService: ProductService,
    private productAdapter: ProductAdapter,
    private router: Router,
    private breadcrumbsMenuUpdateService: BreadcrumbsMenuUpdateService) {
  }

  saveProduct() {
    this.productService.updateProduct(this.product).subscribe(
      result => {
        this._product = _.cloneDeep(result);
        this.persistedProduct = result;
        this.changed.emit(result);
        this.breadcrumbsMenuUpdateService.updatedProduct(this.product);
      },
      error => console.error(error)
    );
  }

  showDeleteModal() {
    this.deleteConfirmModal.open('Delete product?');
  }

  deleteProduct() {
    this.productService.deleteProduct(this.product.id).subscribe(
      ignore => this.router.navigate([RoutesName.customers + '/' + RoutesName.profile, this.profileId.toString()]),
      error => console.error(error)
    );
  }

  getProducts() {
    this.productService.getProfileProducts('' + this.product.profileId).subscribe(
      result => {
        this.products = result;
        this.isValid();
        this.deleteEnabled = !this.isParent();
      },
      error => console.error(error)
    );
  }

  productUpdated(): boolean {
    return JSON.stringify(this.product) !== JSON.stringify(this.persistedProduct);
  }

  isValid(): boolean {
    if (this.productUpdated()) {
      this.mandatoryMet = this.product.mandatoryMet();
      return this.mandatoryMet && this.uniqueViolations.length < 1;
    }
    return false;
  }

  getViolations(): Product[] {
    this.uniqueViolations = this.product.getUniqueViolations(this.products);
    return this.uniqueViolations;
  }

  private isParent(): boolean {
    return this.products.filter( p => p.parentId === this.product.id).length > 0;
  }
}
