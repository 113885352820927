<ng-template #content let-modal>
  <div class="onepoint-modal-container">
    <div class="onepoint-modal-header">
      <div class="onepoint-modal-header-title">Select covered countries</div>
      <div class="onepoint-modal-header-close">
        <img (click)="close()" src="assets/icons/Close.svg" alt="Close" />
      </div>
    </div>
    <div class="onepoint-modal-body">
      <div class="search-header">
        <label class="continent-dropdown-label">Continents</label>
        <app-drop-down 
          class="continent-dropdown"
          [items]="continentsOptions"
          [(selectedItemId)]="continentCode"
          (selectedItemIdChange)="selectContinent()"></app-drop-down>  
        <app-input
          withImage="true"
          class="country-search-input"
          [placeholder]="'Search'"
          [(value)]="searchQuery"></app-input>
      </div>
      <app-dual-listbox 
        [searchQuery]="searchQuery"
        leftLabel="Not covered"
        rightLabel="Covered"
        [(leftItems)]="left"
        [(rightItems)]="right"
        ></app-dual-listbox>
    </div>
    <div class="onepoint-modal-footer">
      <div class="name-input">
        <label class="list-name-label">List name<span class="required">*</span></label>
        <app-input class="list-name-input" [placeholder]="'Add name'" [(value)]="countryList.name"></app-input>
      </div>
      <button
        class="primary-button save-btn"
        (click)="save()"
        [ngClass]="!isValid && 'button-disabled'"
        [disabled]="!isValid"
      >
        Save
      </button>
      <span class="covered-countries-number">Total covered countries: ({{ numberOfCoveredCountries }})</span>
    </div>
  </div>
</ng-template>
