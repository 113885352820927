import * as _ from 'lodash';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PriceAgreementCategoryService} from "@onepoint/shared/models/price-agreement";
import {SosServices} from "@onepoint/shared/models/sos-services";
import {SosServicesCategoryEnum} from "@onepoint/shared/models/enums";

@Component({
  selector: 'app-services-prices',
  templateUrl: './services-prices.component.html',
  styleUrls: ['./services-prices.component.scss']
})
export class ServicesPricesComponent implements OnInit {
  @Output() dataChange = new EventEmitter();
  @Input() categoryId;
  @Input() sosServices: SosServices;
  @Input() categoryIncluded: boolean;
  @Input() suspended: boolean;
  @Input() editable: boolean;
  @Input() manualIndex: boolean;
  @Input() options: any;
  @Input() showType: string;
  @Input() set priceAgreementService(priceAgreementService: PriceAgreementCategoryService) {
    this._priceAgreementService = priceAgreementService;
    this.prices = _.clone(this._priceAgreementService.prices);
    this.shares = _.clone(this._priceAgreementService.shares);
  }

  get priceAgreementService() {
    return this._priceAgreementService;
  }

  private _priceAgreementService: PriceAgreementCategoryService;
  prices: number[];
  shares: number[];
  sosServicesCategoryEnum = SosServicesCategoryEnum;
  percentageOptions = { prefix: '', allowNegative: false, suffix: ' %', max: 100 };


  updateShares(event: any, idx: number) {
    this.priceAgreementService.shares[idx] = this.shares[idx];
    this.dataChange.emit();
  }

  updatePrices(event: any, idx: number) {
    this.priceAgreementService.prices[idx] = this.prices[idx];
    this.dataChange.emit();
  }

  constructor() { }

  ngOnInit(): void {
  }

  sharesDisabled(idx: number): boolean {
    return (!this.editable && !this.manualIndex && idx !== 0) || this.suspended;
  }

  priceInputDisabled(idx: number): boolean {
    return (!this.editable && !this.manualIndex && idx !== 0) || this.suspendedOrFixedPrice();
  }

  suspendedOrFixedPrice(): boolean {
    const categoryEnum = SosServicesCategoryEnum[this.categoryId];
    return (
      this.suspended ||
      categoryEnum == SosServicesCategoryEnum.ADDITIONAL_MEDICAL_SERVICES ||
      categoryEnum == SosServicesCategoryEnum.ADDITIONAL_MEDICAL_SERVICES_ESCORT
    );
  }
}
