import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MasterListService } from 'app/core/services/masterlist.service';
import { OnepointModalService } from 'app/core/services/onepoint.modal.service';
import { SosServicesService } from 'app/core/services/sos-services.service';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import { PermissionService } from 'app/core/services/permission.service';
import { MasterListCausesLevel1Model, MasterListCausesLevel3Model } from 'app/shared/models/masterlist-cause-model';
import { Dictionary } from 'app/shared/models/dictionary';

@Component({
  selector: 'app-masterlist-causes-level3-modal',
  templateUrl: './masterlist-causes-level3-modal.component.html',
  styleUrls: ['./masterlist-causes-level3-modal.component.scss'],
})
export class MasterListCausesLevel3ModalComponent implements OnInit {
  @Input()
  masterListItem: MasterListCausesLevel3Model;

  @Input()
  level2Code: string;

  @Output()
  refreshTable = new EventEmitter();

  businessAreas: Dictionary[] = [{id:'Travel', name:'Travel'}];

  selectedBusinessAreaId: string;
  error: string;
  canUpdate: boolean;
  isModelChanged: boolean; 

  constructor(private masterListService: MasterListService,
              private onepointModalService: OnepointModalService,
              private sosServicesService: SosServicesService,
              private permissionService: PermissionService) {}

  ngOnInit() {
    this.permissionService.permissionsReadyNotification.subscribe(
      result =>
        this.canUpdate = this.permissionService.checkUserPermission(PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE)
    )
  }

  closeModal() {
    this.onepointModalService.dismissAll();
  }

  get isSaveButtonEnabled(): boolean {    
    return this.isModelChanged &&
           this.masterListItem &&
           !!this.masterListItem.activateOn &&
           !!this.masterListItem.businessArea && 
           !!this.masterListItem.level3Code &&
           !!this.masterListItem.level3;
  }

  save() {
    if ( this.isNew ) {
      this.masterListService.addCausesLevel3(this.level2Code, this.masterListItem).subscribe(
        response => {
          this.masterListItem = response;
          this.refreshTable.emit();
          this.closeModal();
        },
        err => this.error = err.error ? err.error.message : err.message
      )  
    }
    else {
      this.masterListService.updateCausesLevel3(this.masterListItem).subscribe(
        response => {
          this.masterListItem = response;
          this.refreshTable.emit();
          this.closeModal();
        },
        err => this.error = err.error ? err.error.message : err.message
      )
    }
  }

  activateOnChanged(item: MasterListCausesLevel3Model) {
    this.modelChanged();
    item.activateOn.setHours(12, 0);
  }

  deactivateOnChanged(item: MasterListCausesLevel3Model) {
    this.modelChanged();
    item.deactivateOn.setHours(12, 0);
  }

  modelChanged(): void {
    this.isModelChanged = true;
  }

  get isReadonly(): boolean {
    return !this.canUpdate;
  }

  get isNew(): boolean {
    return this.masterListItem.id == null;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
