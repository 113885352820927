import {Component, OnInit} from '@angular/core';
import {CustomerConfiguration} from "@onepoint/shared/models/digital-caseflow/customer-configuration";
import {OnepointModalService} from "@onepoint/core/services/onepoint.modal.service";
import {Dictionary} from "@onepoint/shared/models/dictionary";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {DigitalCaseflowService} from "@onepoint/core/services/digital-caseflow.service";
import {ActivatedRoute} from "@angular/router";
import {ProfileService} from "@onepoint/core/services/profile.service";
import {Activity} from "@onepoint/shared/models/digital-caseflow/activity";
import {Step} from "@onepoint/shared/models/digital-caseflow/step";
import {resumeAnimation} from "../animation";

@Component({
  selector: 'app-digital-caseflow-customer-configuration-page',
  templateUrl: './digital-caseflow-customer-configuration-page.component.html',
  styleUrls: ['./digital-caseflow-customer-configuration-page.component.scss'],
  animations: resumeAnimation
})
export class DigitalCaseflowCustomerConfigurationPageComponent implements OnInit {

  customerConfig: CustomerConfiguration = new CustomerConfiguration();
  customerProfiles: Dictionary[] = [];
  allChannels: Dictionary[] = [];
  startTypes: string[] = [];
  validations: Activity[] = [];
  selectedValidations: Activity[] = [];
  activities: Activity[];
  selectedActivities: Activity[] = [];
  selectedCustomerProfiles: Dictionary[];
  name: string;
  updateNeeded: boolean = false;

  constructor(private digitalCaseflowService: DigitalCaseflowService,
              private profileService: ProfileService,
              private modalService: OnepointModalService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.digitalCaseflowService.getAllChannels().subscribe(data => {
      this.allChannels = data.map(activityType => new Dictionary(activityType, activityType));
    });

    this.route.params.subscribe(params => {
      this.digitalCaseflowService.getCustomerConfigurationById(params.id).subscribe(data => {
        this.customerConfig = data;
        this.name = this.customerConfig.flow.name;

        this.selectedActivities = this.customerConfig.flow.steps.map(step => step.activity);
        this.selectedValidations = this.customerConfig.flow.validationSteps.map(step => step.activity);

        this.digitalCaseflowService.getStartingTypes().subscribe(data => {
          this.startTypes = data.filter(startType => !this.customerConfig.flow.startTypes.includes(startType));
        });

        this.digitalCaseflowService.getAllValidations().subscribe(data => {
          this.validations = data.filter(validation => !this.selectedValidations.map(selectedValidation => selectedValidation.id).includes(validation.id));
        });

        this.digitalCaseflowService.getAllActivities().subscribe(data => {
          this.activities = data.filter(activity => !this.selectedActivities.map(selectedActivity => selectedActivity.id).includes(activity.id));
        });
        this.profileService.getCustomerProfiles().subscribe(data => {
          this.customerProfiles = data.map(customerProfile => new Dictionary(""+customerProfile.profileId, customerProfile.profileName)).sort((a, b) => a.name.localeCompare(b.name));
          this.customerProfiles.unshift(new Dictionary("0", "All customers"));
          this.selectedCustomerProfiles = this.customerProfiles.filter(customerProfile => this.customerConfig.customerProfileId+"" == customerProfile.id);
        });
      });
    });
  }

  update() {
    let index = 0;
    //Activities
    this.customerConfig.flow.steps = this.customerConfig.flow.steps.filter(step => this.selectedActivities.map(activity => activity.id).includes(step.activity.id));
    this.selectedActivities.forEach(activity => {
      let currentStep = this.customerConfig.flow.steps.find(step => activity.id == step.activity.id);
      if (currentStep) {
        currentStep.executionOrder = index++*10;
      } else{
        let newStep = new Step();
        newStep.activity = activity;
        newStep.executionOrder = index++*10;
        this.customerConfig.flow.steps.push(newStep);
      }
    });
    //Validations
    this.customerConfig.flow.validationSteps = this.customerConfig.flow.validationSteps.filter(step => this.selectedValidations.map(activity => activity.id).includes(step.activity.id));
    this.selectedValidations.forEach(activity => {
      let currentStep = this.customerConfig.flow.validationSteps.find(step => activity.id == step.activity.id);
      if (currentStep) {
        currentStep.executionOrder = index++*10;
      } else{
        let newStep = new Step();
        newStep.activity = activity;
        newStep.executionOrder = index++*10;
        this.customerConfig.flow.validationSteps.push(newStep);
      }
    });

    this.customerConfig.flow.name = this.name
    this.digitalCaseflowService.updateCustomerConfiguration(this.customerConfig).subscribe(data => {
      this.customerConfig = data;
    });
  }

  save(){
    if(this.updateNeeded) {
      this.update();
      this.updateNeeded = false;
    }
  }

  validate() {
    this.updateNeeded = true;
  }

  setIncluded(included: boolean) {
    this.customerConfig.enabled = included;
    this.validate();
  }

  setHidden(hidden: boolean) {
    this.customerConfig.hiddenInSummary = hidden;
    this.validate();
  }

  drop(event: CdkDragDrop<Activity[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.validate();
  }

  dropStartType(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.validate();
  }
  customerProfileChanged(item: any) {
    if(item.length > 0){
      this.customerConfig.customerProfileId = item[0].id;
    }else{
      this.customerConfig.customerProfileId = null;
    }
    this.validate();
  }
}
