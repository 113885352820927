import {DocumentTemplatesTableComponent} from './document-templates-table/document-templates-table.component';
import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {DocumentTemplate} from 'app/shared/models/templates/document.template';
import {TemplateService} from 'app/core/services/templates/template.service';
import {TemplateTypes} from 'app/shared/models/templates/template.types.enum';
import {TemplatesListUpdateService} from './templates-list-update-service';
import { interval, Subscription } from 'rxjs';
import { TemplateRefreshingStateEnum } from 'app/shared/models/enums';
@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit, OnDestroy {
  @ViewChild('templateTable') templateTable: DocumentTemplatesTableComponent;
  currentTemplateList: DocumentTemplate[];

  mySubscription: Subscription
  isTemplatesRefreshing: TemplateRefreshingStateEnum = TemplateRefreshingStateEnum.DONE;
  isTemplatesRefreshingOldValue: TemplateRefreshingStateEnum = TemplateRefreshingStateEnum.DONE;

  constructor(private templateService: TemplateService, private updateService: TemplatesListUpdateService) {
    this.mySubscription = interval(10000).subscribe((x =>{
      this.isTemlpatesBeingRefreshed()
    }));
   }
   ngOnDestroy() {
    this.mySubscription.unsubscribe();
  }

  ngOnInit() {
    this.updateService.currentTemplateList.subscribe(
      updatedList => this.currentTemplateList = updatedList
    );
    this.isTemlpatesBeingRefreshed();
  }

  getNumberOfDocuments(): number {
    return this.getNumberOfType('DOCUMENT');
  }

  getNumberOfLogos(): number {
    return this.getNumberOfType('LOGO');
  }

  getNumberOfSignatures(): number {
    return this.getNumberOfType('SIGNATURE');
  }

  getNumberOfEmailsAndFaxes(): number {
    return this.getNumberOfType('EMAIL_FAX');
  }

  getNumberOfSms(): number {
    return this.getNumberOfType('SMS');
  }

  getTemplateRefreshingStateEnum() {
    return TemplateRefreshingStateEnum;
  }

  private getNumberOfType(documentType: string): number {
    return this.currentTemplateList ? this.currentTemplateList.filter(template => template.documentType === documentType).length : undefined;
  }

  isTemlpatesBeingRefreshed() {
    this.templateService.isTemlpatesBeingRefreshed().subscribe(
      result => {
        this.isTemplatesRefreshing = TemplateRefreshingStateEnum[result] ;
        if (this.isTemplatesRefreshing !== this.isTemplatesRefreshingOldValue) {
          this.templateTable.reload();
        }
        this.isTemplatesRefreshingOldValue = this.isTemplatesRefreshing;
      }
    );
  }

  refresh() {
    this.isTemplatesRefreshing = TemplateRefreshingStateEnum.IN_PROGRESS;
    this.isTemplatesRefreshingOldValue = TemplateRefreshingStateEnum.IN_PROGRESS;
    this.templateService.refresh().subscribe(result => { }
     );
  }
}
