export enum CustomerProfileApplicationsOverviewMenuType {
  PROFILE_CODE = 'code',
  PROFILE_NAME = 'profileName',
  COMMON_CUSTOMER = 'commonCustomerName',
  BRAND_NAME = 'brandName',
  INCLUDED = 'included',
  VALID_FROM = 'validFrom',
  VALID_TO = 'validTo',
  STILL_LEGACY = "stillLegacy"
}
