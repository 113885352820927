<div *ngIf="profile" class="profile-summary-container">
  <div class="profile-summary-content">
    <div class="general-info-column">
      <div class="title">
        Group segment
      </div>
      <div class="value">
        {{ profile.groupSegment?.name }}
      </div>
      <div class="title">
        SOS owner
      </div>
      <div class="value">{{ profile.sosOwner | YesNo }}</div>
      <div class="title">
        Sub-business relation
      </div>
      <div>{{ profile.subBusinessRelation?.name }}</div>
    </div>
    <div class="markets-column">
      <app-market-summary [markets]="markets"></app-market-summary>
    </div>
    <div class="business-areas-column">
      <app-business-area-summary [areas]="areas"></app-business-area-summary>
    </div>
    <div class="KAM-column" *ngFor="let kam of kams">
      <app-kam-info-view [kam]="kam"></app-kam-info-view>
    </div>
  </div>
  <app-profile-products [profileId]="profile.id" [groupProfileId]="profile.groupProfileId"> </app-profile-products>
</div>
