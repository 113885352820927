import { Component, Input, OnInit } from '@angular/core';
import { SosServices } from 'app/shared/models/sos-services';
import { SosServicesService } from 'app/core/services/sos-services.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sos-services-page',
  templateUrl: './sos-services-page.component.html',
  styleUrls: ['./sos-services-page.component.scss']
})
export class SosServicesPageComponent implements OnInit {
  @Input() sosServices: SosServices;
  shownSection: string;
  sub: any;


  constructor(private sosServicessService: SosServicesService,
    private route: ActivatedRoute) { }


  ngOnInit() {
    this.shownSection="overview";

    this.sub = this.route.params.subscribe(params => {
      this.sosServicessService
        .get(params.id)
        .subscribe(
          result => {
            this.sosServices = result;
          },
          error => console.log(error)
        );
    });

  }

  onSosServiceUpdate(sosService: SosServices) {
    this.sosServices = new SosServices(sosService);
  }

}
