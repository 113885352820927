import { ApplicationRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesName } from 'app/app-routes-name';
import { HelperService } from 'app/core/services/helper.service';
import { SupplierProfileService } from 'app/core/services/supplier-profile.service';
import { AutoUnsubscribe } from 'app/shared/decorators/auto-unsubscribe';
import { SupplierProfile } from 'app/shared/models/supplier-profile';
import { Subscription } from 'rxjs';
import { SupplierMenuType } from './supplier-filtering-sorting-menu/supplier-menu-type';
import * as moment from "moment-timezone";

@Component({
  selector: 'app-suppliers-table',
  templateUrl: './suppliers-table.component.html',
  styleUrls: ['./suppliers-table.component.scss'],
})
@AutoUnsubscribe
export class SuppliersTableComponent implements OnInit {
  filteredSupplierListView: SupplierProfile[];
  supplierProfiles: SupplierProfile[];
  showMenu: boolean[] = new Array(SupplierMenuType.empty);
  supoTypeSubscription: Subscription;

  get SupplierMenuType() {
    return SupplierMenuType;
  }

  constructor(
    private supplierProfileService: SupplierProfileService,
    private router: Router,
    private applicationRef: ApplicationRef,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.supplierProfileService.getAll().subscribe(result => {
      this.supplierProfileService.setAllSupplierProfiles(result);
      this.supplierProfiles = this.initSort(result);
      this.filteredSupplierListView = this.supplierProfiles;
      this.supplierProfileService.changedSupplierProfileCount(this.filteredSupplierListView);
      if (this.supplierProfiles) {
        this.supoTypeSubscription = this.supplierProfileService.getSupoTypeList().subscribe(types => {
          if (types) {
            this.supplierProfiles.forEach(sp => (sp.typeName = types.find(t => t.id === sp.type)?.name));
            this.supoTypeSubscription?.unsubscribe();
          }
        });
      }
    });
  }

  initSort(supplierProfiles: SupplierProfile[]): SupplierProfile[] {
    return this.helperService.sortArray(supplierProfiles, 'supplierProfileCode', true);
  }

  public searchTextValueChange(searchText: string) {
    searchText = searchText.toLowerCase();
    this.filteredSupplierListView = this.supplierProfiles.filter(item => item.supplierProfileName.toLowerCase().search(searchText) >= 0);
    this.supplierProfiles.forEach(item => {
      const networkManagerName =
        (item.networkManagerFirstname ? item.networkManagerFirstname + ' ' : '') +
        (item.networkManagerSurname ? item.networkManagerSurname : '');
      if (
        item.supplierProfileCode.toLowerCase().search(searchText) >= 0 ||
        item.providerId?.toString().search(searchText) >= 0 ||
        (item.creditorNumber !== null && String(item.creditorNumber).search(searchText) >= 0) ||
        item.creditorName?.toLowerCase().search(searchText) >= 0 ||
        item.creditorCurrency?.toLowerCase().search(searchText) >= 0 ||
        networkManagerName.toLowerCase().search(searchText) >= 0 ||
        item.typeName?.toLowerCase().search(searchText) >= 0 ||
        this.getPartnerName(item)?.toLowerCase().search(searchText) >= 0
      ) {
        if (this.filteredSupplierListView.find(x => x.id === item.id) == null) {
          this.filteredSupplierListView.push(item);
        }
      }
    });
    this.supplierProfileService.changedSupplierProfileCount(this.filteredSupplierListView);
  }

  filterTable() {
    this.filteredSupplierListView = this.supplierProfiles;
  }
  clearAllFiltering() {
    this.filterTable();
    this.applicationRef.tick();
    this.showFilteringSortingMenu(SupplierMenuType.empty);
  }

  navigate(supplierProfile: SupplierProfile) {
    this.router.navigate(['/supplier-profile/', supplierProfile.id]);
  }

  finishedAndFilteringSorting() {
    this.showFilteringSortingMenu(SupplierMenuType.empty);
  }

  showFilteringSortingMenu(filterItem: SupplierMenuType) {
    for (let i = 0; i < this.showMenu.length; i++) {
      if (i !== filterItem) {
        this.showMenu[i] = false;
      }
    }
    this.showMenu[filterItem] = !this.showMenu[filterItem];
  }

  navigateSupplierProfile(supplierProfile: SupplierProfile) {
    this.router.navigate([RoutesName.suppliers + '/', supplierProfile.id]);
  }

  getPartnerName(supplier: SupplierProfile): string {
    if (supplier?.supplierProfilePartners?.length > 0) {
      let startOfToday = moment.utc().startOf('day').tz("Europe/Copenhagen").toDate();
      return supplier.supplierProfilePartners.filter(partner => partner.startDate <= startOfToday && (partner.endDate == null || partner.endDate >= startOfToday)).map(partner => this.supplierProfiles.find(p => p.id === partner.supplierProfilePartnerId)?.supplierProfileName).join(', ');
    }
    return null;
  }
}
