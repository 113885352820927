<ng-template #content let-modal>
  <div class="modal-container">
    <div class="customer-sla-agreement-modal-header">
      <div class="customer-sla-agreement-title" *ngIf="customerSlaAgreement.id == null">Add New SLA Agreement</div>
      <div class="customer-sla-agreement-title" *ngIf="customerSlaAgreement.id != null">SLA Agreement</div>
      <div class="customer-sla-agreement-close"><img (click)="close()" src="assets/icons/Close.svg" alt="Close"/></div>
    </div>

    <div class="customer-sla-agreement-modal-body">
      <div class="customer-sla-agreement-properties">
        <div class="row">
          <div class="input-property col-3">
            <div class="title">Title</div>
            <app-input
              [(value)]="customerSlaAgreement.name"
              (valueChange)="allowSubmit()"
            ></app-input>
          </div>
          <div class="input-property col-3">
            <div class="title">Contract name</div>
            <app-input
              [(value)]="customerSlaAgreement.contractName"
              (valueChange)="allowSubmit()"
            ></app-input>
          </div>
        </div>
      </div>
      <div class="sla-category-list col-12 " *ngFor="let slaAgreementCategory of customerSlaAgreement.slaAgreementCategories">
        <div class="sla-category-header">
          <div class="sla-category-name text-center">{{ slaAgreementCategory.name }}</div>
          <div class="d-flex justify-content-center">
            <div class="status">Included</div>
            <app-input-switch [id]="'customer-sla-agreement-enabled' + slaAgreementCategory.name"
                              [value]="slaAgreementCategory.enabled"
                              (valueChange)="slaAgreementCategory.enabled = $event.valueOf()">
            </app-input-switch>
          </div>
        </div>

        <div class="sla-headers row" [class.sla-not-included]="!slaAgreementCategory.enabled">
          <div class="col-3">SLA name</div>
          <div class="col-2">Requirement</div>
          <div class="col-2">Deadline</div>
          <div class="col-5">Description</div>
        </div>

        <div class="sla-list" *ngFor="let slaAgreement of slaAgreementCategory.slaAgreements">
          <div class="row sla-row">
            <div class="col-3 name" >
              {{ slaAgreement.sla.name }}
              <div class="slider float-end">
                <app-input-switch
                  *ngIf="slaAgreementCategory.enabled"
                  [id]="'slaAgreement-' + slaAgreement.sla.id"
                  [value]="slaAgreement.enabled"
                  (valueChange)="slaAgreement.enabled = $event.valueOf()"
                >
                </app-input-switch>
              </div>
            </div>
            <div class="col-2">
              <input *ngIf="slaAgreement.enabled && slaAgreementCategory.enabled" [(ngModel)]="slaAgreement.requirement"/>
            </div>
            <div class="col-2">
              <input *ngIf="slaAgreement.enabled && slaAgreementCategory.enabled" [(ngModel)]="slaAgreement.deadlineTime"/>
            </div>
            <div class="col-5">
              <input class="description-input" *ngIf="slaAgreement.enabled && slaAgreementCategory.enabled" [(ngModel)]="slaAgreement.contractDescription"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="customer-sla-agreement-modal-footer">
      <div class="customer-sla-agreement-title">
        <button
          class="save-button primary-button"
          (click)="saveCustomerSlaAgreement()"
          [ngClass]="!saveEnabled && 'button-disabled'"
          [disabled]="!saveEnabled"
        >
          Save changes
        </button>
      </div>
    </div>
  </div>
</ng-template>
