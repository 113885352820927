import { Component, Input, ViewChild } from '@angular/core';
import { EditTermsComponent } from './edit-terms/edit-terms.component';
import { Product } from '../../../../../shared/models/product';
import { ProductService } from '../../../../../core/services/product.service';
import { EditGuideComponent } from './edit-guide/edit-guide.component';
import { PermissionEnum } from '@onepoint/shared/models/permission.enum';

@Component({
  selector: 'app-product-documents',
  templateUrl: './product-documents.component.html',
  styleUrls: ['./product-documents.component.scss']
})
export class ProductDocumentsComponent {
  @Input() set productId(id: number) {
    this._productId = id;
    this.getProduct();
  }
  get productId() {
    return this._productId;
  }


  product: Product;

  @ViewChild('editTermsComponent')
  editTermsComponent: EditTermsComponent;
  @ViewChild('editGuideComponent', {static: false})
  editGuideComponent: EditGuideComponent;

  private _productId: number;
  private guidelineMsWordUriPrefix = 'ms-word:ofv|u|';

  constructor(private productService: ProductService) { }

  getProduct() {
    this.productService.getProduct(this.productId.toString()).subscribe(
      result => this.product = result,
      error => console.error(error)
    );
  }

  openTermsDocument() {
    this.productService.getTermsDocument(this.product.termsDocumentId).subscribe(result => {
      const url = window.URL.createObjectURL(result);
      window.open(url);
    }, error => console.log(error));
  }

  openGuidelineDocument(uri) {
    window.open(this.guidelineMsWordUriPrefix + uri);
  }

  editTerms() {
    this.editTermsComponent.open();
  }

  editGuide() {
    this.editGuideComponent.open();
  }

  isChildProduct(product: Product) {
    return product.parentId != null;
  }

  clearGuidelines() {
    this.product.guidelineUri = null;
    this.product.guidelineName = null;
    this.productService.updateProduct(this.product).subscribe(result => {

    });
  }

  clearTerms() {
    this.productService.clearTerms(this.productId).subscribe(result => {
      this.product = result;
    });
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
