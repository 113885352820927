import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ProfileCountryList } from 'app/shared/models/profile-country-list';

@Component({
  selector: 'app-profile-country-lists-table',
  templateUrl: './profile-country-lists-table.component.html',
  styleUrls: ['./profile-country-lists-table.component.scss']
})
export class ProfileCountryListsTableComponent {
  @Input() countryLists: ProfileCountryList[] = [];
  @Output() editOnClick = new EventEmitter();
  @Output() deleteOnClick = new EventEmitter();

  editClicked(row: ProfileCountryList) {
    this.editOnClick.emit(row);
  }
  deleteClicked(row: ProfileCountryList) {
    this.deleteOnClick.emit(row);
  }
}
