import { ActionInput } from '@onepoint/shared/models/action-input';
import { RuleMapping } from './../../shared/models/rule-mapping';
import { RuleAction } from './../../shared/models/rule-action';
import { Observable } from 'rxjs';
import { RuleEvent } from './../../shared/models/rule-event';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { Rule } from 'app/shared/models/rule';

@Injectable({
  providedIn: 'root',
})
export class RuleEngineService extends BaseService {
  constructor(private http: HttpClient, private settingsService: SettingsService) {
    super(settingsService);
  }

  public getAllRuleEvents(): Observable<RuleEvent[]> {
    return this.http.get<RuleEvent[]>(this.baseURL + 'rule/rule-events');
  }

  public getAllRuleActions(): Observable<RuleAction[]> {
    return this.http.get<RuleAction[]>(this.baseURL + 'rule/rule-actions');
  }

  public getAllRuleActionInputs(): Observable<ActionInput[]> {
    return this.http.get<ActionInput[]>(this.baseURL + 'rule/rule-outputs');
  }

  public getAllRules(): Observable<Rule[]> {
    return this.http.get<Rule[]>(this.baseURL + 'rule/rules');
  }

  public getAllRuleTypes(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + 'rule/types');
  }

  public getAllRuleMappings(): Observable<RuleMapping[]> {
    return this.http.get<RuleMapping[]>(this.baseURL + 'rule');
  }

  public addRuleMapping(ruleMapping: RuleMapping): Observable<RuleMapping> {
    return this.http.post<RuleMapping>(this.baseURL + 'rule', ruleMapping, { headers: this.jsonHeaders });
  }

  public updateRuleMapping(ruleMapping: RuleMapping): Observable<RuleMapping> {
    return this.http.put<RuleMapping>(this.baseURL + 'rule', ruleMapping, { headers: this.jsonHeaders });
  }

  public updateActionInput(actionInput: ActionInput): Observable<ActionInput> {
    return this.http.put<ActionInput>(this.baseURL + 'rule/update-action-input', actionInput, { headers: this.jsonHeaders });
  }

  public getRuleMapping(ruleMappingId: number): Observable<RuleMapping> {
    return this.http.get<RuleMapping>(this.baseURL + 'rule/id/' + ruleMappingId, { headers: this.jsonHeaders });
  }
}
