import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ProfileCountryList } from 'app/shared/models/profile-country-list';
import { ProfileCountryListsService } from 'app/core/services/profile-country-lists.service';
import { HelperService } from 'app/core/services/helper.service';
import { ProfileCountryListComponent } from './profile-country-list/profile-country-list.component';
import { WarningModalComponent } from 'app/shared/components/modal/warning-modal/warning-modal.component';
import { ConfirmModalComponent } from 'app/shared/components/modal/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-profile-country-lists',
  templateUrl: './profile-country-lists.component.html',
  styleUrls: ['./profile-country-lists.component.scss']
})
export class ProfileCountryListsComponent implements OnInit {
  @Input() profileId: number;
  @ViewChild('profileCountryList')
  profileCountryListComponent: ProfileCountryListComponent;
  countryLists: ProfileCountryList[] = [];
  @ViewChild('cannotDeleteWarningModal')
  cannotDeleteWarningModal: WarningModalComponent;
  @ViewChild('deleteConfirmModal')
  deleteConfirmModal: ConfirmModalComponent;

  constructor(
    private countryListsService: ProfileCountryListsService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.loadAll();
  }

  loadAll() {
    this.countryListsService.getAll(this.profileId).subscribe(countryLists => {
      this.countryLists = this.initialSort(countryLists);
     }, error => console.log(error));
  }

  initialSort(countryLists: ProfileCountryList[]) {
    return this.helperService.sortArray(countryLists, 'name', true);
  }

  get numberOfCountryLists() {
    return this.countryLists.length;
  }
  addNewCountrylist() {
    this.profileCountryListComponent.openNewListModal();
  }

  showEditModal(data: ProfileCountryList) {
    this.profileCountryListComponent.openEditListModal(data);
  }

  showDeleteModal(data: ProfileCountryList) {
    this.deleteConfirmModal.open(`Delete country list ${data.name}?`, `Delete country list ${data.name}?`, data);
  }

  deleteList(data: ProfileCountryList) {
    this.countryListsService.delete(data.id).subscribe(result => {
      if (result.deleted) {
        this.handleChange();
        return;
      }
      let errorMsg = `This country list is used in ${result.namesOfProductsUsingGivenCountryList[0]} and cannot be deleted`;
      if (result.namesOfProductsUsingGivenCountryList.length > 1) {
        errorMsg = `This country list is used in ${result.namesOfProductsUsingGivenCountryList[0]} `
         + `and in ${result.namesOfProductsUsingGivenCountryList.length - 1} others and cannot be deleted`;
      }
      this.cannotDeleteWarningModal.open(`Cannot delete country list`, errorMsg);
    });
  }

  handleChange() {
    this.loadAll();
  }
}
