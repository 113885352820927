<div class="page-content-container" *ngIf="filteredSosServicesListView">
  <table class="primary-table sos-services-table">
    <thead>
      <tr>
        <td (click)="showFilteringSortingMenu(SosServicesMenuType.name)" class="filter-column">
          <div [ngStyle]="!showMenu[SosServicesMenuType.name] && { display: 'none' }">
            <app-sos-services-menu
              (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="SosServicesMenuType.name"
              (clearAllFilters)="clearAllFiltering()"
              [(sosServicesListView)]="filteredSosServicesListView"
            ></app-sos-services-menu>
          </div>
          SOS service name
        </td>
        <td (click)="showFilteringSortingMenu(SosServicesMenuType.sosServiceId)" class="filter-column">
          <div [ngStyle]="!showMenu[SosServicesMenuType.sosServiceId] && { display: 'none' }">
            <app-sos-services-menu
              (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="SosServicesMenuType.sosServiceId"
              (clearAllFilters)="clearAllFiltering()"
              [(sosServicesListView)]="filteredSosServicesListView"
            ></app-sos-services-menu>
          </div>
          SOS service ID
        </td>
        <td (click)="showFilteringSortingMenu(SosServicesMenuType.category)" class="filter-column">
          <div [ngStyle]="!showMenu[SosServicesMenuType.category] && { display: 'none' }">
            <app-sos-services-menu
              (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="SosServicesMenuType.category"
              (clearAllFilters)="clearAllFiltering()"
              [(sosServicesListView)]="filteredSosServicesListView"
            ></app-sos-services-menu>
          </div>
          Service category
        </td>
        <td (click)="showFilteringSortingMenu(SosServicesMenuType.validStart)" class="filter-column">
          <div [ngStyle]="!showMenu[SosServicesMenuType.validStart] && { display: 'none' }">
            <app-sos-services-menu
              (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="SosServicesMenuType.validStart"
              (clearAllFilters)="clearAllFiltering()"
              [(sosServicesListView)]="filteredSosServicesListView"
            ></app-sos-services-menu>
          </div>
          Start date
        </td>
        <td (click)="showFilteringSortingMenu(SosServicesMenuType.validEnd)" class="filter-column">
          <div [ngStyle]="!showMenu[SosServicesMenuType.validEnd] && { display: 'none' }">
            <app-sos-services-menu
              (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="SosServicesMenuType.validEnd"
              (clearAllFilters)="clearAllFiltering()"
              [(sosServicesListView)]="filteredSosServicesListView"
            ></app-sos-services-menu>
          </div>
          End date
        </td>
        <td (click)="showFilteringSortingMenu(SosServicesMenuType.feeType)" class="filter-column">
          <div [ngStyle]="!showMenu[SosServicesMenuType.feeType] && { display: 'none' }">
            <app-sos-services-menu
              (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="SosServicesMenuType.feeType"
              (clearAllFilters)="clearAllFiltering()"
              [(sosServicesListView)]="filteredSosServicesListView"
            >
            </app-sos-services-menu>
          </div>
          Fee type
        </td>
        <td (click)="showFilteringSortingMenu(SosServicesMenuType.feePriority)" class="filter-column">
          <div [ngStyle]="!showMenu[SosServicesMenuType.feePriority] && { display: 'none' }">
            <app-sos-services-menu
              (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="SosServicesMenuType.feePriority"
              (clearAllFilters)="clearAllFiltering()"
              [(sosServicesListView)]="filteredSosServicesListView"
            >
            </app-sos-services-menu>
          </div>
          Priority
        </td>
        <td (click)="showFilteringSortingMenu(SosServicesMenuType.includedInStandard)" class="filter-column">
          <div
            [ngStyle]="
              !showMenu[SosServicesMenuType.includedInStandard] && {
                display: 'none'
              }
            "
          >
            <app-sos-services-menu
              (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="SosServicesMenuType.includedInStandard"
              (clearAllFilters)="clearAllFiltering()"
              [(sosServicesListView)]="filteredSosServicesListView"
            >
            </app-sos-services-menu>
          </div>
          Included in<br />standard
        </td>
        <td (click)="showFilteringSortingMenu(SosServicesMenuType.vat)" class="filter-column">
          <div [ngStyle]="!showMenu[SosServicesMenuType.vat] && { display: 'none' }">
            <app-sos-services-menu
              (modelChanged)="finishedAndFilteringSorting()"
              [filteringSortingMenuType]="SosServicesMenuType.vat"
              (clearAllFilters)="clearAllFiltering()"
              [(sosServicesListView)]="filteredSosServicesListView"
            >
            </app-sos-services-menu>
          </div>
          VAT ERP Client
        </td>
        <td (click)="showFilteringSortingMenu(SosServicesMenuType.targetPrice)" class="filter-column">
          <div [ngStyle]="!showMenu[SosServicesMenuType.targetPrice] && { display: 'none' }">
            <app-sos-services-menu
              (modelChanged)="finishedAndFilteringSorting()"
              class="filter-column-last"
              [filteringSortingMenuType]="SosServicesMenuType.targetPrice"
              (clearAllFilters)="clearAllFiltering()"
              [(sosServicesListView)]="filteredSosServicesListView"
            >
            </app-sos-services-menu>
          </div>
          Target price
        </td>
      </tr>
    </thead>
    <tbody>
      <ng-template ngFor let-sosServices [ngForOf]="filteredSosServicesListView">
        <tr (click)="navigate(sosServices)">
          <td>
            <div class="first-column-container">
              <div class="first-column-content">
                {{ sosServices.name }}
              </div>
            </div>
          </td>
          <td>{{ sosServices.sosServiceId }}</td>
          <td>
            {{ sosServices.sosServicesCategoryEnum }}
          </td>
          <td>{{ sosServices.validStart | date: 'dd MMM yyyy' }}</td>
          <td>{{ sosServices.validEnd | date: 'dd  MMM yyyy' }}</td>
          <ng-template [ngIf]="sosServices.extension?.feeType != null && sosServices.extension.feeType !== sosServices.feeType" [ngIfElse]="normalFee">
            <td>{{ 'Primary case fee; Secondary case fee' }}</td>
          </ng-template>
          <ng-template #normalFee>
            <td>{{ sosServices.feeTypeEnum }}</td>
          </ng-template>
          <td class="align-center">{{ sosServices.feePriority }}</td>
          <td class="align-center">
            <input type="checkbox" [checked]="sosServices.includedInStandard" disabled="true" />
          </td>
          <td class="align-center">{{ sosServices.vat }}</td>
          <td>
            <div *ngIf="sosServices.sosServicesCategoryEnum !== SosServicesCategoryEnum.COMMUNICATION_COST">
              <span class="currency">{{
                sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.SHARE_OF_COST_CONTROL
                  ? '%'
                  : sosServices.targetPrice == null
                  ? ''
                  : sosServices.targetPrice.currency
              }}</span>
              <span class="amount"
                >{{
                  sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.SHARE_OF_COST_CONTROL
                    ? sosServices.targetShare
                    : ((sosServices.targetPrice == null ? '' : sosServices.targetPrice.amount) | number: '.2')
                }}
              </span>
            </div>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>
