export enum CausesLevel1MenuType {
  LEVEL1_CODE = 'level1Code',
  LEVEL1 = 'level1',
  UI_SORT_INDEX = 'uiSortIndex',
  ACTIVATE_ON = 'activateOn',
  DEACTIVATION_ON = 'deactivateOn'
}

export enum CausesLevel2MenuType {
  LEVEL2_CODE = 'level2Code',
  LEVEL2 = 'level2',
  UI_SORT_INDEX = 'uiSortIndex',
  ACTIVATE_ON = 'activateOn',
  DEACTIVATION_ON = 'deactivateOn'
}

export enum CausesLevel3MenuType {
  LEVEL3_CODE = 'level3Code',
  LEVEL3 = 'level3',
  UI_SORT_INDEX = 'uiSortIndex',
  ACTIVATE_ON = 'activateOn',
  DEACTIVATION_ON = 'deactivateOn'
}
