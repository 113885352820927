
import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { DocumentTemplate, DocumentTemplateAdapter } from 'app/shared/models/templates/document.template';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../helper.service';
import { SettingsService } from '../settings.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TemplateRefreshingStateEnum } from 'app/shared/models/enums';


@Injectable({
  providedIn: 'root'
})
export class TemplateService extends BaseService {

  constructor(
    private adapter: DocumentTemplateAdapter,
    private http: HttpClient,
    private helperService: HelperService,
    private settingsService: SettingsService
  ) {
    super(settingsService);
  }

  public getAllTemplates(): Observable<DocumentTemplate[]> {
    return this.http
      .get(this.baseURL + 'document-templates')
      .pipe(
        map((data: any[]) => data.map(item => this.adapter.adapt(item)))
      );
  }

  public refresh(): Observable<string> {
    return this.http
      .get(this.baseURL + 'document-templates/refresh')
      .pipe(
        map((data: any) => data)
      );
  }

  public isTemlpatesBeingRefreshed(): Observable<TemplateRefreshingStateEnum> {
    return this.http.get<TemplateRefreshingStateEnum>(this.baseURL + 'document-templates/isTemplatesRefresing')
    .pipe(
      map((data: any) => data)
    );
  }
}

