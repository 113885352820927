<div class="country-lists-table-container" *ngIf="countryLists">
  <table class="primary-table profile-country-lists-table">
    <thead>
      <tr>
        <td class="name-column filter-column" sortColumn [sortKey]="'name'" [data]="countryLists">
          List name
        </td>
        <td class="number-countries-column filter-column" sortColumn [sortKey]="'numberOfCountries'" [data]="countryLists">
          Included countries
        </td>
        <td class="action-column"></td>
      </tr>
    </thead>
    <tbody>
      <ng-template ngFor let-countryList [ngForOf]="countryLists">
        <tr [ngClass]="{ 'inherited': !!countryList.groupProfileCountryListId }">
          <td class="name-column">
            <div class="first-column-content">
              {{ countryList.name }}
            </div>
          </td>
          <td class="number-countries-column">{{ countryList.numberOfCountries }}</td>
          <td class="action-column">
            <button *ngIf="!countryList.groupProfileCountryListId" class="primary-button-outline" (click)="editClicked(countryList)">Edit</button>
            <button *ngIf="!countryList.groupProfileCountryListId" class="btn" (click)="deleteClicked(countryList)"><img src="assets/icons/garbage-red.svg" alt="Delete icon"></button>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>
