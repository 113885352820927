<div class="drop-down-list">
  <span class="name" *ngIf="name !== undefined">{{name}}</span>
  <ng-select
    [items]="items"
    bindLabel="{{bindLabel}}"
    bindValue="{{bindValue}}"
    [clearable]="false"
    [(ngModel)]="selectedItem"
    [selectOnTab]="true"
    [searchFn]="searchFunction"
    [ngClass]="'custom'">
  </ng-select>
</div>
