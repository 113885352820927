import { HelperService } from './helper.service';
import { Brand, BrandAdapter } from './../../shared/models/brand';
import { CustomerListView } from 'app/shared/models/customer-list-view';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { ProfileAdapter } from 'app/shared/models/profile';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class BrandService extends BaseService {
  constructor(
    private profileAdapter: ProfileAdapter,
    private brandAdapter: BrandAdapter,
    private http: HttpClient,
    private helperService: HelperService,
    private settingsService: SettingsService
  ) {
    super(settingsService);
  }

  public getBrandListView(id: string): Observable<CustomerListView> {
    return this.http
      .get(this.baseURL + 'brand/' + id)
      .pipe(map(item => this.brandAdapter.adaptToCommonCustomerListView(item)));
  }

  public getBrand(id: string): Observable<Brand> {
    return this.http
      .get(this.baseURL + 'brand/' + id)
      .pipe(map(item => this.brandAdapter.adapt(item)));
  }

  public getProfiles(brandId: string): Observable<CustomerListView[]> {
    return this.http
      .get(this.baseURL + 'profile?brandId=' + brandId)
      .pipe(
        map((data: any[]) =>
          data.map(item =>
            this.profileAdapter.adaptToCommonCustomerListView(item)
          )
        )
      );
  }

  public addBrand(brand: Brand): Observable<Brand> {
    return this.http.post<Brand>(this.baseURL + 'brand', brand, {
      headers: this.jsonHeaders
    });
  }

  public getAllBrands(): Observable<Brand[]> {
    return this.http
      .get(this.baseURL + 'brand?includeProducts=false')
      .pipe(
        map((data: any[]) => data.map(item => this.brandAdapter.adapt(item)))
      );
  }

  public updateBrand(brand: Brand): Observable<Brand> {
    return this.http.put<Brand>(this.baseURL + 'brand/update', brand, {
      headers: this.jsonHeaders
    }).pipe(map(result => this.brandAdapter.adapt(result)));
  }
  public deleteBrand(id: string): Observable<any> {
    return this.http.delete<any>(this.baseURL + 'brand/delete/' + id, {
      headers: this.jsonHeaders
    });
  }
}
