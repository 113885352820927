<ng-template #content let-modal>
  <div class="modal-container">
    <div class="new-profile-modal-header">
      <div *ngIf="profile.isGroupProfile()" class="new-profile-title">Add new Profile</div>
      <div *ngIf="!profile.isGroupProfile()" class="new-profile-title">Add new group member</div>
      <div class="new-profile-close" (click)="close()">
        <img src="assets/icons/Close.svg" />
      </div>
    </div>

    <div class="new-profile-modal-body">
      <div class="profile-setup">Profile setup</div>
      <div class="input-name">Profile name <span class="required">*</span></div>
      <div class="profile-name-input">
        <app-input (valueChange)="nameChanges($event)" (value)="(profile.name)"></app-input>
      </div>
      <div class="input-name">Customer Profile Code <span class="required">*</span></div>
      <div class="profile-code-input">
        <app-input (valueChange)="onCodeChange($event)" (value)="(profile.code)"></app-input>
        <div *ngIf="profile.code && !isProfileCodeUnique && profileCodeViolations.length > 0" class="notification">
          Profile code {{profile.code}} is already used by profile {{profileCodeViolations.join('')}}
        </div>
      </div>
      <div *ngIf="!profile.isGroupProfile()" class="input-name">Group member ID for mapping</div>
      <div *ngIf="!profile.isGroupProfile()" class="profile-code-input">
        <app-input (valueChange)="onGroupMemberIdForMappingChange($event)" (value)="profile.groupMemberIdForMapping"></app-input>
        <div *ngIf="profile.groupMemberIdForMapping && !isGroupMemberIdForMappingValid && groupMemberIdForMappingViolations.length > 0" class="notification">
          Group member ID for mapping {{profile.groupMemberIdForMapping}} is already used
        </div>
      </div>
      <div class="input-name">Market <span class="required">*</span></div>
      <div class="dropdown-market">
        <app-drop-down-with-auto-complete-countries
          [autoCompleteTypeEnum]="AutoCompleteTypeEnum.Countries"
          [withInput]="false"
          [(selectedItemId)]="profile.market"
          (selectedItemIdChange)="formChanged()"
          [showCode]="false"
          [disabled]="hasGroupProfile(profile)"
        >
        </app-drop-down-with-auto-complete-countries>
      </div>

      <div class="businessAreaRow d-flex">
        <div class="checkbox-container">
          <div class="input-name">Business Area <span class="required">*</span></div>
          <div class="businessAreaCheckboxWrapper">
            <input
              class="businessAreaCheckbox"
              type="checkbox"
              (change)="(!profile.businessAreasTravel); checkBoxChange()"
              [(ngModel)]="profile.businessAreasTravel"
              [disabled]="hasGroupProfile(profile)"
            />Travel
          </div>
        </div>
        <div class="kam-container">
          <div class="input-name">Responsible KAM <span class="required">*</span></div>
          <div class="dropdown">
            <app-kam-drop-down
              [(selectedKamId)]="profile.responsibleKamTravelUserId"
              [(selectedKamFirstName)]="profile.responsibleKamTravelFirstName"
              [(selectedKamSurname)]="profile.responsibleKamTravelSurname"
              [(selectedKamEmail)]="profile.responsibleKamTravelEmail"
              [(selectedKamPhone)]="profile.responsibleKamTravelPhone"
              (selectedKamIdChange)="formChanged()"
              [disabled]="!profile.businessAreasTravel"
            >
            </app-kam-drop-down>
          </div>
        </div>
        <div class="case-number-pre-fix-container">
          <div class="input-name">Case no prefix <span class="required">*</span></div>
          <div class="case-number-pre-fix">
            <app-input
              (valueChange)="prefixCheck(BusinessAreaEnum.TRAVELCARE, $event)"
              [(value)]="profile.caseNumberPreFixTravel"
              [disabled]="!profile.businessAreasTravel || hasGroupProfile(profile)"
            ></app-input>
          </div>
        </div>
        <div class="profile-integrationtype-container">
          <div class="input-name">Case Information<span class="required">*</span></div>
          <select
            class="input-component"
            [(ngModel)]="profile.travelcareCaseInformation"
            (ngModelChange)="formChanged()"
            [disabled]="!profile.businessAreasTravel"
          >
            <option *ngFor="let item of CaseInformationEnumKeys" [value]="item">{{ CaseInformationEnum[item] }}</option>
          </select>
        </div>
        <div class="profile-integrationtype-container">
          <div class="input-name">Invoicing<span class="required">*</span></div>
          <select
            class="input-component"
            [(ngModel)]="profile.travelcareInvoicing"
            (ngModelChange)="formChanged()"
            [disabled]="!profile.businessAreasTravel"
          >
            <option *ngFor="let item of InvoicingEnumKeys" [value]="item">{{ InvoicingEnum[item] }}</option>
          </select>
        </div>
      </div>

      <div class="businessAreaRow d-flex">
        <div class="checkbox-container">
          <div class="businessAreaCheckboxWrapper">
            <input
              class="businessAreaCheckbox"
              type="checkbox"
              (change)="(!profile.businessAreasRoadside); checkBoxChange()"
              [(ngModel)]="profile.businessAreasRoadside"
              [disabled]="hasGroupProfile(profile)"
            />Roadside
          </div>
        </div>
        <div class="kam-container">
          <div class="dropdown">
            <app-kam-drop-down
              [(selectedKamId)]="profile.responsibleKamRoadsideUserId"
              [(selectedKamFirstName)]="profile.responsibleKamRoadsideFirstName"
              [(selectedKamSurname)]="profile.responsibleKamRoadsideSurname"
              [(selectedKamEmail)]="profile.responsibleKamRoadsideEmail"
              [(selectedKamPhone)]="profile.responsibleKamRoadsidePhone"
              (selectedKamIdChange)="formChanged()"
              [disabled]="!profile.businessAreasRoadside"
            >
            </app-kam-drop-down>
          </div>
        </div>
        <div class="case-number-pre-fix-container">
          <div class="case-number-pre-fix">
            <app-input
              (valueChange)="prefixCheck(BusinessAreaEnum.MOBILITY, $event)"
              [(value)]="profile.caseNumberPreFixRoadside"
              [disabled]="!profile.businessAreasRoadside || hasGroupProfile(profile)"
            ></app-input>
          </div>
        </div>
        <div class="profile-integrationtype-container">
          <select
            class="input-component"
            [(ngModel)]="profile.mobilityCaseInformation"
            (ngModelChange)="formChanged()"
            [disabled]="!profile.businessAreasRoadside"
          >
            <option *ngFor="let item of CaseInformationEnumKeys" [value]="item">{{ CaseInformationEnum[item] }}</option>
          </select>
        </div>
        <div class="profile-integrationtype-container">
          <select
            class="input-component"
            [(ngModel)]="profile.mobilityInvoicing"
            (ngModelChange)="formChanged()"
            [disabled]="!profile.businessAreasRoadside"
          >
            <option *ngFor="let item of InvoicingEnumKeys" [value]="item">{{ InvoicingEnum[item] }}</option>
          </select>
        </div>
      </div>

      <div class="businessAreaRow d-flex">
        <div class="checkbox-container">
          <div class="businessAreaCheckboxWrapper">
            <input
              class="businessAreaCheckbox"
              type="checkbox"
              (change)="(!profile.businessAreasHealth); checkBoxChange()"
              [(ngModel)]="profile.businessAreasHealth"
              [disabled]="hasGroupProfile(profile)"
            />Health
          </div>
        </div>
        <div class="kam-container">
          <div class="dropdown">
            <app-kam-drop-down
              [(selectedKamId)]="profile.responsibleKamHealthUserId"
              [(selectedKamFirstName)]="profile.responsibleKamTravelFirstName"
              [(selectedKamSurname)]="profile.responsibleKamTravelSurname"
              [(selectedKamEmail)]="profile.responsibleKamHealthEmail"
              [(selectedKamPhone)]="profile.responsibleKamHealthPhone"
              (selectedKamIdChange)="formChanged()"
              [disabled]="!profile.businessAreasHealth"
            >
            </app-kam-drop-down>
          </div>
        </div>
        <div class="case-number-pre-fix-container">
          <div class="case-number-pre-fix">
            <app-input
              (valueChange)="prefixCheck(BusinessAreaEnum.HEALTHCARE, $event)"
              [(value)]="profile.caseNumberPreFixHealth"
              [disabled]="!profile.businessAreasHealth || hasGroupProfile(profile)"
            ></app-input>
          </div>
        </div>
        <div class="profile-integrationtype-container">
          <select
            class="input-component"
            [(ngModel)]="profile.healthcareCaseInformation"
            (ngModelChange)="formChanged()"
            [disabled]="!profile.businessAreasHealth"
          >
            <option *ngFor="let item of CaseInformationEnumKeys" [value]="item">{{ CaseInformationEnum[item] }}</option>
          </select>
        </div>
        <div class="profile-integrationtype-container">
          <select
            class="input-component"
            [(ngModel)]="profile.healthcareInvoicing"
            (ngModelChange)="formChanged()"
            [disabled]="!profile.businessAreasHealth"
          >
            <option *ngFor="let item of InvoicingEnumKeys" [value]="item">{{ InvoicingEnum[item] }}</option>
          </select>
        </div>
      </div>

      <div class="input-name">Internal remarks</div>
      <div class="profile-remarks-input">
        <textarea name="remarks" rows="4" [(ngModel)]="profile.remarks" (ngModelChange)="formChanged()" [maxLength]="1000"></textarea>
      </div>
    </div>

    <div class="new-profile-modal-footer">
      <button class="primary-button" (click)="addNewProfile()" [ngClass]="!isProfileValid && 'button-disabled'">Add</button>
    </div>
  </div>
</ng-template>
