import { RuleMappingModalComponent } from './modules/rule-engine/rule-mapping-modal/rule-mapping-modal.component';
import { RuleEngineTableComponent } from './modules/rule-engine/rule-engine-table/rule-engine-table.component';
import { RuleEnginePageComponent } from './modules/rule-engine/rule-engine-page.component';
import { TemplatesComponent } from './modules/templates/templates.component';
import { AddNewIndexComponent } from './modules/indexes/add-new-index/add-new-index.component';
import { ProfileMenuComponent } from './modules/customers/profile-page/profile-menu/profile-menu.component';
import { YesNoPipe } from './shared/pipes/yes-no.pipe';
import { InputComponent } from './shared/components/input/input.component';
import { DropDownComponent } from './shared/components/drop-down/drop-down.component';
import { SortDirective } from './shared/directives/sort.directive';
import { TrimDirective } from './shared/directives/trim.directive';
import { TopBarComponent } from './shared/components/top-bar/top-bar.component';
import { LeftMenuComponent } from './shared/components/left-menu/left-menu.component';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AccessGuard, AppRoutingModule } from './app-routing.module';
import { LayoutComponent } from './shared/layout/layout/layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgClickOutsideDirective  } from 'ng-click-outside2';
import { CustomersPageComponent } from './modules/customers/customers-page/customers-page.component';
import { CustomersSummaryComponent } from './modules/customers/customers-page/customers-summary/customers-summary.component';
import { CustomersTableComponent } from './modules/customers/customers-page/customers-table/customers-table.component';
import { CommonCustomerPageComponent } from './modules/customers/common-customer-page/common-customer-page.component';
import { CommonCustomerOverviewComponent } from './modules/customers/common-customer-page/common-customer-overview/common-customer-overview.component';
import { CommonCustomerContractsComponent } from './modules/customers/common-customer-page/common-customer-contracts/common-customer-contracts.component';
import { CommonCustomerSettingsComponent } from './modules/customers/common-customer-page/common-customer-settings/common-customer-settings.component';
import { CommonCustomerSummaryComponent } from './modules/customers/common-customer-page/common-customer-overview/common-customer-summary/common-customer-summary.component';
import { CommonCustomerBrandsComponent } from './modules/customers/common-customer-page/common-customer-overview/common-customer-brands/common-customer-brands.component';
import { BrandPageComponent } from './modules/customers/brand-page/brand-page.component';
import { BrandOverviewComponent } from './modules/customers/brand-page/brand-overview/brand-overview.component';
import { BrandSettingsComponent } from './modules/customers/brand-page/brand-settings/brand-settings.component';
import { BrandSummaryComponent } from './modules/customers/brand-page/brand-overview/brand-summary/brand-summary.component';
import { BrandProfilesComponent } from './modules/customers/brand-page/brand-overview/brand-profiles/brand-profiles.component';
import { ProfilePageComponent } from './modules/customers/profile-page/profile-page.component';
import { ProfileOverviewComponent } from './modules/customers/profile-page/profile-overview/profile-overview.component';
import { ProfileSettingsComponent } from './modules/customers/profile-page/profile-settings/profile-settings.component';
import { ProfileContractsComponent } from './modules/customers/profile-page/profile-contracts/profile-contracts.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonCustomerNewBrandComponent } from './modules/customers/common-customer-page/common-customer-overview/common-customer-brands/common-customer-new-brand/common-customer-new-brand.component';
import { SettingsLoaderService } from './core/services/settings.loader.service';
import { initializeApp } from './shared/app-init';
import { NewCommonCustomerComponent } from './modules/customers/customers-page/customers-summary/new-common-customer/new-common-customer.component';
import { IndexesPageComponent } from './modules/indexes/indexes-page.component';
import { SosServicesPageComponent } from './modules/sos-services/sos-services-page.component';
import { BreadcrumbsMenuComponent } from './shared/components/breadcrumbs-menu/breadcrumbs-menu.component';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { CustomersComponent } from './modules/customers/customers.component';
import { CommonCustomerMenuComponent } from './modules/customers/common-customer-page/common-customer-menu/common-customer-menu.component';
import { EditCommonCustomerComponent } from './modules/customers/customers-page/customers-summary/edit-common-customer/edit-common-customer.component';
import { FilteringSortingMenuComponent } from './modules/customers/customers-page/customers-table/filtering-sorting-menu/filtering-sorting-menu.component';
import { BrandMenuComponent } from './modules/customers/brand-page/brand-menu/brand-menu.component';
import { InputCalendarComponent } from './shared/components/input-calendar/input-calendar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrandNewProfileComponent } from './modules/customers/brand-page/brand-overview/brand-profiles/Brand-new-profile/brand-new-profile/brand-new-profile.component';
import { DropDownWithAutoCompleteCountriesComponent } from './shared/components/drop-down-with-auto-complete-countries/drop-down-with-auto-complete-countries.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonCustomerDebtorsComponent } from './modules/customers/common-customer-page/common-customer-debtors/common-customer-debtors.component';
import { EditDebtorComponent } from './modules/customers/common-customer-page/common-customer-debtors/edit-debtor/edit-debtor.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { SettingsService } from './core/services/settings.service';
import { SosServicesTableComponent } from './modules/sos-services/sos-services-table/sos-services-table.component';
import { SosServicesSummaryComponent } from './modules/sos-services/sos-services-summary/sos-services-summary.component';
import { NewSosServicesComponent } from './modules/sos-services/sos-services-summary/new-sos-services/new-sos-services.component';
import { EditSosServicesComponent } from './modules/sos-services/sos-services-summary/edit-sos-services/edit-sos-services.component';
import { SosServicesMenuComponent } from './modules/sos-services/sos-services-table/sos-services-menu/sos-services-menu.component';
import { NgxCurrencyDirective } from "ngx-currency";
import { InputMoneyComponent } from './shared/components/input-money/input-money.component';
import { MoneyCurrencyPipe } from './shared/components/input-money/input-money-currency-pipe';
import { DropDownMultiselectComponent } from './shared/components/drop-down-multiselect/drop-down-multiselect.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SosServicesSettingsComponent } from './modules/sos-services/sos-services-settings/sos-services-settings.component';
import { SosServicesOverviewComponent } from './modules/sos-services/sos-settings-overview/sos-services-overview.component';
import { SosServicesComponent } from './modules/sos-services/sos-services.component';
import { ProfileTeamsComponent } from './modules/customers/profile-page/profile-teams/profile-teams.component';
import { PriceAgreementsComponent } from './modules/customers/common-customer-page/common-customer-contracts/price-agreements/price-agreements.component';
import { ViewEditAgreementComponent } from './modules/customers/common-customer-page/common-customer-contracts/price-agreements/view-edit-agreement/view-edit-agreement.component';
import { InputSwitchComponent } from './shared/components/input-switch/input-switch.component';
import { AgreementCategoryComponent } from './modules/customers/common-customer-page/common-customer-contracts/price-agreements/view-edit-agreement/agreement-category/agreement-category.component';
import localeDa from '@angular/common/locales/da';
import { ContractProfilesComponent } from './modules/customers/common-customer-page/common-customer-contracts/contract-profiles/contract-profiles.component';
import { ProfileDropDownComponent } from './modules/customers/common-customer-page/common-customer-contracts/contract-profiles/profile-drop-down/profile-drop-down.component';
import { KamDropDownComponent } from './shared/components/kam-drop-down/kam-drop-down.component';
import { ProfileCountryListsComponent } from './modules/customers/profile-page/profile-country-lists/profile-country-lists.component';
import { ProfileCountryListsTableComponent } from './modules/customers/profile-page/profile-country-lists/profile-country-lists-table/profile-country-lists-table.component';
import { ProfileCountryListComponent } from './modules/customers/profile-page/profile-country-lists/profile-country-list/profile-country-list.component';
import { WarningModalComponent } from './shared/components/modal/warning-modal/warning-modal.component';
import { ConfirmModalComponent } from './shared/components/modal/confirm-modal/confirm-modal.component';
import { DualListboxComponent } from './shared/components/dual-listbox/dual-listbox.component';
import { CheckboxComponent } from './shared/components/checkbox/checkbox.component';
import { ProfileProductsComponent } from './modules/customers/profile-page/profile-overview/profile-products/profile-products.component';
import { ProductsFilteringSortingMenuComponent } from './modules/customers/product-page/product-filtering-sorting-menu/products-filtering-sorting-menu.component';
import { ProductMenuComponent } from './modules/customers/product-page/product-menu/product-menu.component';
import { ProductPageComponent } from './modules/customers/product-page/product-page.component';
import { ProductSettingsPageComponent } from './modules/customers/product-page/product-settings/product-settings-page.component';
import { ProfileProductsAddComponent } from './modules/customers/product-page/profile-products-add/profile-products-add.component';
import { ProductSettingsFormComponent } from './modules/customers/product-page/product-form/product-settings-form.component';
import { ProductOverviewComponent } from './modules/customers/product-page/product-overview/product-overview.component';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { ProductTermsComponent } from './modules/customers/product-page/product-terms/product-terms.component';
import { CoveragesLimitsListComponent } from './modules/customers/product-page/product-terms/coverages-limits/coverages-limits-list.component';
import { LimitsFieldComponent } from './modules/customers/product-page/product-terms/coverages-limits/limits-field/limits-field.component';
import { CoverageLimitsModalComponent } from './modules/customers/product-page/product-terms/coverages-limits/coverage-limits-modal/coverage-limits-modal.component';
import { EditTermsComponent } from './modules/customers/product-page/product-terms/product-documents/edit-terms/edit-terms.component';
import { DocumentTemplatesTableComponent } from './modules/templates/document-templates-table/document-templates-table.component';
import { UploadBrandIconComponent } from './modules/customers/brand-page/upload-brand-icon/upload-brand-icon.component';
import { KamInfoViewComponent } from './shared/components/kam-info-view/kam-info-view.component';
import { BusinessAreaSummaryComponent } from './shared/components/customers/business-area-summary/business-area-summary.component';
import { MarketSummaryComponent } from './shared/components/customers/market-summary/market-summary.component';
import { ProductPolicyIntegrationComponent } from './modules/customers/product-page/product-policy-integration/product-policy-integration.component';
import { RegisterServerIntegrationComponent } from './modules/customers/product-page/product-policy-integration/register-server-integration/register-server-integration.component';
import { ProfileContactInfoComponent } from './modules/customers/profile-page/profile-contact-info/profile-contact-info.component';
import { ServicesGeneralComponent } from './modules/customers/common-customer-page/common-customer-contracts/price-agreements/view-edit-agreement/services-general/services-general.component';
import { TemplateFilteringSortingMenuComponent } from './modules/templates/document-templates-table/template-filtering-sorting-menu/template-filtering-sorting-menu.component';
import { EditGuideComponent } from './modules/customers/product-page/product-terms/product-documents/edit-guide/edit-guide.component';
import { ProductDocumentsComponent } from './modules/customers/product-page/product-terms/product-documents/product-documents.component';
import { EnvironmentRibbonComponent } from './shared/components/environment-ribbon/environment-ribbon.component';
import { SignOutComponent } from './shared/components/sign-out/sign-out.component';
import { ErrorHttpInterceptorProvider } from './shared/interceptors/error.http.interceptor';
import { GlobalErrorHandler } from './core/services/error-handler.service';
import { ServerWarningModalComponent } from './shared/components/modal/warning-server-modal/server-warning-modal.component';
import { ProfileApplicationsComponent } from './modules/customers/profile-page/profile-applications/profile-applications.component';
import { ReloadFooterComponent } from './shared/components/reload-footer/reload-footer.component';
import { MasterListsComponent } from './modules/master-lists/master-lists.component';
import { MasterListsOverviewComponent } from './modules/master-lists/overview/masterlist-overview.component';
import { MasterListServiceItemComponent } from './modules/master-lists/service-item/masterlist-service-item.component';
import { MasterListServiceItemModalComponent } from './modules/master-lists/service-item/service-item-modal/masterlist-service-item-modal.component';
import { MasterListUploadModalComponent } from './modules/master-lists/upload/upload-modal/masterlist-upload-modal.component';
import { PermissionDirective } from './shared/directives/permission.directive';
import { MomentPipe } from './shared/pipes/moment-pipe';
import { MasterListCausesLevel1Component } from './modules/master-lists/causes/causes-level1/masterlist-causes-level1.component';
import { MasterListCausesLevel2Component } from './modules/master-lists/causes/causes-level2/masterlist-causes-level2.component';
import { MasterListCausesLevel3Component } from './modules/master-lists/causes/causes-level3/masterlist-causes-level3.component';
import { MasterListCausesLevel1ModalComponent } from './modules/master-lists/causes/causes-level1/causes-level1-modal/masterlist-causes-level1-modal.component';
import { MasterListCausesLevel2ModalComponent } from './modules/master-lists/causes/causes-level2/causes-level2-modal/masterlist-causes-level2-modal.component';
import { MasterListCausesLevel3ModalComponent } from './modules/master-lists/causes/causes-level3/causes-level3-modal/masterlist-causes-level3-modal.component';
import { ProductPolicyRelationsComponent } from './modules/customers/product-page/product-policy-relations/product-policy-relations.component';
import { ProductPolicyRelationsModalComponent } from './modules/customers/product-page/product-policy-relations/product-policy-relations-modal/product-policy-relations-modal.component';
import { CustomerProfileRelationsComponent } from './modules/customer-profile-relations/customer-profile-relations.component';
import { CustomerProfileRelationComponent } from './modules/customer-profile-relations/customer-profile-relation/customer-profile-relation.component';
import { AddCustomerProfileLabelComponent } from './modules/customer-profile-relations/add-customer-profile-label/add-customer-profile-label.component';
import { ProductPolicyRelationsSortingMenuComponent } from './modules/customers/product-page/product-policy-relations/product-policy-relations-sorting-menu/product-policy-relations-sorting-menu.component';
import { CustomerProfileRelationsOverviewComponent } from './modules/customer-profile-relations/customer-profile-relations-overview/customer-profile-relations-overview.component';
import { CustomerProfileRelationsOverviewSortingMenuComponent } from './modules/customer-profile-relations/customer-profile-relations-overview/customer-profile-relations-overview-sorting-menu/customer-profile-relations-overview-sorting-menu.component';
import { MasterListMenuComponent } from './modules/master-lists/menu/masterlist-menu.component';
import { MasterListApplicationsComponent } from './modules/master-lists/applications/masterlist-applications.component';
import { SuppliersPageComponent } from './modules/suppliers/suppliers-page/suppliers-page.component';
import { SuppliersTableComponent } from './modules/suppliers/suppliers-table/suppliers-table.component';
import { SuppliersComponent } from './modules/suppliers/suppliers.component';
import { SupplierFilteringSortingMenuComponent } from './modules/suppliers/suppliers-table/supplier-filtering-sorting-menu/supplier-filtering-sorting-menu.component';
import { SupplierOverviewComponent } from './modules/suppliers/supplier-overview/supplier-overview.component';
import { SupplierSettingsComponent } from './modules/suppliers/supplier-settings/supplier-settings.component';
import { SuppliersAllComponent } from './modules/suppliers/suppliers-all/suppliers-all.component';
import { NewSupplierProfileComponent } from './modules/suppliers/suppliers-all/new-supplier-profile/new-supplier-profile.component';
import { EditSupplierProfileComponent } from './modules/suppliers/suppliers-all/edit-supplier-profile/edit-supplier-profile.component';
import { MasterlistServiceTypeComponent } from './modules/master-lists/service-type/masterlist-service-type.component';
import { MasterlistServiceTypeModalComponent } from './modules/master-lists/service-type/service-type-modal/masterlist-service-type-modal.component';
import { ProductDebtorComponent } from './modules/customers/product-page/product-debtor/product-debtor.component';
import { SlaAgreementsComponent } from './modules/customers/common-customer-page/common-customer-contracts/sla-agreement/sla-agreements.component';
import {SlaConfigurationPageComponent} from "./modules/sla-configuration/sla-configuration-page.component";
import {SlaComponent} from "./modules/sla-configuration/sla/sla.component";
import {SlaRuleComponent} from "./modules/sla-configuration/sla-rule/sla-rule.component";
import {ViewEditCustomerSlaAgreementComponent} from "./modules/customers/common-customer-page/common-customer-contracts/sla-agreement/view-edit-customer-sla-agreement/view-edit-customer-sla-agreement.component";
import {AddSlaComponent} from "./modules/sla-configuration/sla/add-sla/add-sla.component";
import {AddSlaRuleComponent} from "./modules/sla-configuration/sla-rule/add-sla-rule/add-sla-rule.component";
import { CustomerProfileApplicationsComponent } from './modules/customer-profile-applications/customer-profile-applications.component';
import { CustomerProfileApplicationsOverviewComponent } from './modules/customer-profile-applications/customer-profile-applications-overview/customer-profile-applications-overview.component';
import { CustomerProfileApplicationsOverviewMenuComponent } from './modules/customer-profile-applications/customer-profile-applications-overview/customer-profile-applications-overview-sorting-menu/customer-profile-applications-overview-menu.component';
import { ServicesPricesComponent } from './modules/customers/common-customer-page/common-customer-contracts/price-agreements/view-edit-agreement/services-prices/services-prices.component';
import {SlaCalculatedEventComponent} from "@onepoint/sla-configuration/sla-calculated-events/sla-calculated-event.component";
import {AddSlaCalculatedEventComponent} from '@onepoint/sla-configuration/sla-calculated-events/add-sla-calculated-event/add-sla-calculated-event.component';
import { MasterListAdTeamsComponent } from '@onepoint/master-lists/ad-teams/masterlist-ad-teams.component';
import { MasterListAdTeamsModalComponent } from '@onepoint/master-lists/ad-teams/ad-teams-modal/masterlist-ad-teams-modal.component';
import { MasterlistCaseTypeComponent } from './modules/master-lists/case-type/masterlist-case-type.component';
import { MasterlistCaseTypeModalComponent } from './modules/master-lists/case-type/case-type-modal/masterlist-case-type-modal.component';
import { MasterlistRefundTypeComponent } from './modules/master-lists/refund-type/masterlist-refund-type.component';
import { RefundTypeModalComponent } from './modules/master-lists/refund-type/refund-type-modal/refund-type-modal.component';
import { MasterlistReductionComponent } from './modules/master-lists/reductions/masterlist-reduction.component';
import { MasterlistReductionModalComponent } from '@onepoint/master-lists/reductions/reduction-modal/masterlist-reduction-modal.component'
import { MasterlistSortingMenuComponent} from '@onepoint/master-lists/sorting-menu/masterlist-sorting-menu.component'
import { MasterlistSanctionCountryModalComponent } from '@onepoint/master-lists/sanction-countries/sanction-country-modal/masterlist-sanction-country-modal.component';
import { MasterlistSanctionCountriesComponent } from '@onepoint/master-lists/sanction-countries/masterlist-sanction-countries.component';
import { LastChangedComponent } from './shared/components/last-changed/last-changed.component';
import { MasterlistDedicatedEmailsComponent } from '@onepoint/master-lists/dedicated-emails/masterlist-dedicated-emails.component';
import { MasterlistDedicatedEmailModalComponent } from '@onepoint/master-lists/dedicated-emails/dedicated-email-modal/masterlist-dedicated-email-modal.component';
import { GenericDropDownComponent } from './shared/components/generic-drop-down/generic-drop-down.component';
import { DropDownListComponent } from './shared/components/drop-down-list/drop-down-list.component';
import { ProfileCaseProcessesComponent } from './modules/customers/profile-page/profile-case-processes/profile-case-processes.component';
import { CustomerProfileCaseProcessesComponent } from './modules/customer-profile-case-processes/customer-profile-case-processes.component';
import { CustomerProfileCaseProcessesOverviewComponent } from './modules/customer-profile-case-processes/customer-profile-case-processes-overview/customer-profile-case-processes-overview.component';
import { CustomerProfileCaseProcessesOverviewSortingMenuComponent } from './modules/customer-profile-case-processes/customer-profile-case-processes-overview/customer-profile-case-processes-overview-sorting-menu/customer-profile-case-processes-overview-sorting-menu.component';
import { MasterlistCommitmentTypeModalComponent } from '@onepoint/master-lists/commitment-types/commitment-type-modal/masterlist-commitment-type-modal.component';
import { MasterlistCommitmentTypesComponent } from '@onepoint/master-lists/commitment-types/masterlist-commitment-types.component';
import { MasterlistIncidentEventsComponent } from './modules/master-lists/incident-events/masterlist-incident-events.component';
import { IncidentEventModalComponent } from './modules/master-lists/incident-events/incident-event-modal/masterlist-incident-event-modal.component';
import { MasterlistApprovalLimitsComponent } from '@onepoint/master-lists/approval-limits/masterlist-approval-limits.component';
import { ApprovalLimitModalComponent } from './modules/master-lists/approval-limits/approval-limit-modal/approval-limit-modal.component';
import { MasterlistDiagnosesComponent } from '@onepoint/master-lists/diagnoses/masterlist-diagnoses.component';
import { MasterlistDiagnosisModalComponent } from '@onepoint/master-lists/diagnoses/diagnosis-modal/masterlist-diagnosis-modal.component';
import { SortColumnComponent } from '@onepoint/shared/components/sort-column/sort-column.component';
import { MasterlistQuestionnaireComponent } from '@onepoint/master-lists/questionaire/masterlist-questionnaire.component';
import { MasterlistQuestionnaireModalComponent } from '@onepoint/master-lists/questionaire/questionnaire-modal/masterlist-questionnaire-modal.component';
import { PolicyLookupMethodPipePipe } from '@onepoint/shared/pipes/policy-method.pipe';
import {DigitalCaseflowPageComponent} from "./modules/digital-caseflow/digital-caseflow-page.component";
import { MasterListReasonForCancellationComponent } from '@onepoint/master-lists/reason-for-cancellation/masterlist-reason-for-cancellation.component';
import { MasterListReasonForCancellationModalComponent } from '@onepoint/master-lists/reason-for-cancellation/reason-for-cancellation-modal/masterlist-reason-for-cancellation-modal.component';
import { MasterlistChannelTypeComponent } from '@onepoint/master-lists/channel-type/masterlist-channel-type.component';
import { MasterlistChannelTypeModalComponent } from '@onepoint/master-lists/channel-type/channel-type-modal/masterlist-channel-type-modal.component';
import { MasterlistContactRoleComponent } from './modules/master-lists/contact-role/masterlist-contact-role.component';
import { ConstactRoleModalComponent } from './modules/master-lists/contact-role/constact-role-modal/constact-role-modal.component';
import {ErpDataComponent} from "./modules/erp-data/erp-data.component";
import {CreditorListComponent} from "./modules/erp-data/creditors/creditor-list.component";
import {MatPaginatorModule} from "@angular/material/paginator";
import {DcfFlowsComponent} from "./modules/digital-caseflow/flows/dcf-flows.component";
import {DcfActivitiesComponent} from "./modules/digital-caseflow/activities/dcf-activities.component";
import {CreateCustomerConfigComponent} from "./modules/digital-caseflow/customer-configuration/create-customer-config/create-customer-config.component";
import {
  EditDcfActivityComponent
} from "./modules/digital-caseflow/activities/edit-activity/edit-dcf-activity.component";
import {CdkDrag, CdkDropList} from "@angular/cdk/drag-drop";
import {
  DigitalCaseflowCustomerConfigurationPageComponent
} from "./modules/digital-caseflow/customer-configuration/digital-caseflow-customer-configuration-page.component";
import {DigitalCaseflowComponent} from "./modules/digital-caseflow/digital-caseflow.component";
import {CdkAccordionModule} from "@angular/cdk/accordion";
import {DcfValidationsComponent} from "./modules/digital-caseflow/validations/dcf-validations.component";
import {
  EditDcfValidationComponent
} from "./modules/digital-caseflow/validations/edit-validation/edit-dcf-validation.component";
import { ChipInputComponent } from './shared/components/chip-input/chip-input.component';


registerLocaleData(localeDa);

export const ngxMaskOptions: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    LayoutComponent,
    ServerWarningModalComponent,
    LeftMenuComponent,
    TopBarComponent,
    CustomersPageComponent,
    IndexesPageComponent,
    SosServicesPageComponent,
    TemplatesComponent,
    DocumentTemplatesTableComponent,
    CustomersSummaryComponent,
    CustomersTableComponent,
    CommonCustomerPageComponent,
    CommonCustomerOverviewComponent,
    CommonCustomerContractsComponent,
    CommonCustomerSettingsComponent,
    CommonCustomerSummaryComponent,
    CommonCustomerBrandsComponent,
    BrandPageComponent,
    BrandOverviewComponent,
    BrandSettingsComponent,
    BrandSummaryComponent,
    BrandProfilesComponent,
    UploadBrandIconComponent,
    ProfilePageComponent,
    ProfileOverviewComponent,
    ProfileSettingsComponent,
    ProfileContractsComponent,
    ProfileProductsComponent,
    ProfileProductsAddComponent,
    ProductsFilteringSortingMenuComponent,
    ProductPageComponent,
    ProductMenuComponent,
    SortDirective,
    TrimDirective,
    DropDownComponent,
    InputComponent,
    YesNoPipe,
    NewCommonCustomerComponent,
    CommonCustomerNewBrandComponent,
    BreadcrumbsMenuComponent,
    CustomersComponent,
    CommonCustomerMenuComponent,
    EditCommonCustomerComponent,
    FilteringSortingMenuComponent,
    SosServicesMenuComponent,
    SosServicesTableComponent,
    SosServicesSummaryComponent,
    SosServicesPageComponent,
    TemplateFilteringSortingMenuComponent,
    NewSosServicesComponent,
    EditSosServicesComponent,
    BrandMenuComponent,
    InputCalendarComponent,
    BrandNewProfileComponent,
    ProfileMenuComponent,
    DropDownWithAutoCompleteCountriesComponent,
    CommonCustomerDebtorsComponent,
    EditDebtorComponent,
    InputMoneyComponent,
    MoneyCurrencyPipe,
    DropDownMultiselectComponent,
    SosServicesSettingsComponent,
    SosServicesOverviewComponent,
    SosServicesComponent,
    ProfileCountryListsComponent,
    ProfileCountryListsTableComponent,
    ProfileCountryListComponent,
    ProfileTeamsComponent,
    SosServicesComponent,
    EditDebtorComponent,
    PriceAgreementsComponent,
    ViewEditAgreementComponent,
    InputSwitchComponent,
    AgreementCategoryComponent,
    AddNewIndexComponent,
    AgreementCategoryComponent,
    ContractProfilesComponent,
    ProfileDropDownComponent,
    KamDropDownComponent,
    KamInfoViewComponent,
    MarketSummaryComponent,
    BusinessAreaSummaryComponent,
    WarningModalComponent,
    ConfirmModalComponent,
    DualListboxComponent,
    CheckboxComponent,
    ProductSettingsPageComponent,
    ProductSettingsFormComponent,
    ProductOverviewComponent,
    ProductTermsComponent,
    ProductPolicyIntegrationComponent,
    RegisterServerIntegrationComponent,
    CoveragesLimitsListComponent,
    LimitsFieldComponent,
    CoverageLimitsModalComponent,
    ProductDocumentsComponent,
    EditTermsComponent,
    ProfileContactInfoComponent,
    ServicesGeneralComponent,
    RuleEnginePageComponent,
    RuleEngineTableComponent,
    RuleMappingModalComponent,
    EditGuideComponent,
    EnvironmentRibbonComponent,
    SignOutComponent,
    ProfileApplicationsComponent,
    ReloadFooterComponent,
    MasterListsComponent,
    MasterListsOverviewComponent,
    MasterListServiceItemComponent,
    MasterListServiceItemModalComponent,
    MasterListUploadModalComponent,
    PermissionDirective,
    MomentPipe,
    MasterListCausesLevel1Component,
    MasterListCausesLevel2Component,
    MasterListCausesLevel3Component,
    MasterListCausesLevel1ModalComponent,
    MasterListCausesLevel2ModalComponent,
    MasterListCausesLevel3ModalComponent,
    ProductPolicyRelationsComponent,
    ProductPolicyRelationsModalComponent,
    CustomerProfileRelationsComponent,
    CustomerProfileRelationComponent,
    AddCustomerProfileLabelComponent,
    DigitalCaseflowComponent,
    DigitalCaseflowPageComponent,
    DigitalCaseflowCustomerConfigurationPageComponent,
    DcfFlowsComponent,
    CreateCustomerConfigComponent,
    DcfActivitiesComponent,
    EditDcfActivityComponent,
    DcfValidationsComponent,
    EditDcfValidationComponent,
    SlaConfigurationPageComponent,
    ErpDataComponent,
    CreditorListComponent,
    SlaComponent,
    SlaRuleComponent,
    SlaCalculatedEventComponent,
    SlaAgreementsComponent,
    AddSlaComponent,
    AddSlaCalculatedEventComponent,
    AddSlaRuleComponent,
    ViewEditCustomerSlaAgreementComponent,
    ProductPolicyRelationsSortingMenuComponent,
    CustomerProfileRelationsOverviewComponent,
    CustomerProfileRelationsOverviewSortingMenuComponent,
    MasterListMenuComponent,
    MasterListApplicationsComponent,
    SuppliersPageComponent,
    SuppliersTableComponent,
    SuppliersComponent,
    SupplierFilteringSortingMenuComponent,
    SupplierOverviewComponent,
    SupplierSettingsComponent,
    SuppliersAllComponent,
    NewSupplierProfileComponent,
    EditSupplierProfileComponent,
    MasterlistServiceTypeComponent,
    MasterlistServiceTypeModalComponent,
    ProductDebtorComponent,
    CustomerProfileApplicationsComponent,
    CustomerProfileApplicationsOverviewComponent,
    CustomerProfileApplicationsOverviewMenuComponent,
    ServicesPricesComponent,
    MasterListAdTeamsComponent,
    MasterListAdTeamsModalComponent,
    MasterlistCaseTypeComponent,
    MasterListApplicationsComponent,
    MasterlistCaseTypeComponent,
    MasterlistCaseTypeModalComponent,
    MasterlistRefundTypeComponent,
    RefundTypeModalComponent,
    MasterlistReductionComponent,
    MasterlistReductionModalComponent,
    MasterlistSanctionCountryModalComponent,
    MasterlistSanctionCountriesComponent,
    MasterlistSortingMenuComponent,
    LastChangedComponent,
    MasterlistDedicatedEmailsComponent,
    MasterlistDedicatedEmailModalComponent,
    GenericDropDownComponent,
    DropDownListComponent,
    GenericDropDownComponent,
    ProfileCaseProcessesComponent,
    CustomerProfileCaseProcessesComponent,
    CustomerProfileCaseProcessesOverviewComponent,
    CustomerProfileCaseProcessesOverviewSortingMenuComponent,
    MasterlistCommitmentTypesComponent,
    MasterlistCommitmentTypeModalComponent,
    MasterlistIncidentEventsComponent,
    IncidentEventModalComponent,
    MasterlistApprovalLimitsComponent,
    ApprovalLimitModalComponent,
    MasterlistDiagnosesComponent,
    MasterlistDiagnosisModalComponent,
    SortColumnComponent,
    MasterlistQuestionnaireComponent,
    MasterlistQuestionnaireModalComponent,
    PolicyLookupMethodPipePipe,
    MasterListReasonForCancellationComponent,
    MasterListReasonForCancellationModalComponent,
    MasterlistChannelTypeComponent,
    MasterlistChannelTypeModalComponent,
    MasterlistContactRoleComponent,
    ConstactRoleModalComponent,
    ChipInputComponent
],
  imports: [
    FormsModule,
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    NgSelectModule,
    KeycloakAngularModule,
    NgxCurrencyDirective,
    NgMultiSelectDropDownModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatPaginatorModule,
    CdkDropList,
    CdkDrag,
    CdkAccordionModule,
    NgClickOutsideDirective,
    NgxWebstorageModule.forRoot(),

  ],

  providers: [
    ErrorHttpInterceptorProvider,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [KeycloakService, SettingsService, SettingsLoaderService],
    },
    AccessGuard,
    provideNgxMask()
  ],
  bootstrap: [LayoutComponent],
    exports: [MatDatepickerModule, MatNativeDateModule, ConfirmModalComponent],
})
export class AppModule {}
