<div class="page-container">  
  <div class="page-header justify-content-between no-gutters">
    <div class="col-4 my-auto counts-container">
      <div class="count-container">
        <span>Master lists</span> <span>{{masterList?.length}}</span>
      </div>
    </div>
    <div class="my-auto">
      <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_CREATE">
        <button class="primary-button" (click)="upload()">
          Upload translations
        </button>
        <div *ngIf="masterListUploadInfo" class="upload-info-container">
          <div class="upload-info">Last upload: {{masterListUploadInfo?.lastUploaded | momentFormat: 'DD/MM-YYYY HH:mm' : 'local'}}</div>
        </div>
      </div>
    </div>
  </div>
    
  <div class="page-content-container">
    <table class="primary-table master-lists-table">
      <thead>
        <tr>
          <td (click)="showFilteringSortingMenu(MasterListsOverviewMenuType.NAME)" class="filter-column">
            <div [ngStyle]="!showMenu[MasterListsOverviewMenuType.NAME] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="MasterListsOverviewMenuType.NAME"
                [masterList]="masterList"
              ></app-masterlist-sorting-menu>
            </div>
            List name
          </td>
          <td (click)="showFilteringSortingMenu(MasterListsOverviewMenuType.STATUS)" class="filter-column">
            <div [ngStyle]="!showMenu[MasterListsOverviewMenuType.STATUS] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="MasterListsOverviewMenuType.STATUS"
                [masterList]="masterList"
              ></app-masterlist-sorting-menu>
            </div>
            Status
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of masterList" (click)="navigate(item)" [ngClass]="{'dimmed': !item.enabled}">
          <td>
            <div class="first-column-container">
              <div class="first-column-content">
                {{ item.name }}
              </div>
            </div>
          </td>
          <td>
            {{ item.status }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <ng-template #MasterListUploadModal>
    <app-masterlist-upload-modal></app-masterlist-upload-modal>
  </ng-template>
</div>