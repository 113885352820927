import { Injectable } from '@angular/core';
import { SosServices } from 'app/shared/models/sos-services';
import { SosServicesCategoryEnum } from 'app/shared/models/enums';

@Injectable({
  providedIn: 'root',
})
export class SosServicesValidationService {
  constructor() {}

  isValid(sosServices: SosServices) {
    if (
      this.isEmpty(sosServices.sosServiceId) ||
      this.isEmpty(sosServices.name) ||
      this.isEmpty(sosServices.sosServicesCategory) ||
      this.isEmpty(sosServices.validStart) ||
      this.isEmpty(sosServices.feeType) ||
      this.isEmpty(sosServices.feePriority) ||
      this.isEmpty(sosServices.caseType) ||
      this.isEmpty(sosServices.causesLevel1) ||
      this.isEmpty(sosServices.causesLevel2) ||
      this.isEmpty(sosServices.description)
    ) {
      return false;
    }

    if (sosServices.sosServicesCategoryEnum !== SosServicesCategoryEnum.BANK_FEE && sosServices.sosServicesCategoryEnum !== SosServicesCategoryEnum.SHARE_OF_RECOVERY &&
      (this.isEmpty(sosServices.commitmentType) ||
        this.isEmpty(sosServices.sosServicesType))) {
      return false;
    }

    if (parseInt(sosServices.targetPriceYear, 10) < 2010 || parseInt(sosServices.targetPriceYear, 10) > 2199) {
      return false;
    }
    if (sosServices.extensionType === 'AND' &&
      this.isEmpty(sosServices.extension.sosServicesType) &&
      this.isEmpty(sosServices.extension.commitmentType) &&
      this.isEmpty(sosServices.extension.sosServicesItem)
    ) {
      return false;
    }
    if (sosServices.extensionType === 'OR') {
      if (this.isEmpty(sosServices.extension.feeType) ||
        this.isEmpty(sosServices.extension.caseType) ||
        this.isEmpty(sosServices.extension.causesLevel1) ||
        this.isEmpty(sosServices.extension.causesLevel2) ||
        this.isEmpty(sosServices.extension.commitmentType) ||
        (this.isEmpty(sosServices.extension.refundTypes) && sosServices.sosServicesCategory === SosServicesCategoryEnum.CLAIMS_TRAVEL)
      ) {
        return false;
      }
    }
    if (sosServices.extensionType === 'OR' && sosServices.sosServicesCategoryEnum !== SosServicesCategoryEnum.BANK_FEE && sosServices.sosServicesCategoryEnum !== SosServicesCategoryEnum.SHARE_OF_RECOVERY &&
      (this.isEmpty(sosServices.sosServicesType))) {
      return false;
    }

    if (sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.SHARE_OF_COST_CONTROL ||
      sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.SHARE_OF_RECOVERY
    ) {
      return (
        !this.isEmpty(sosServices.targetShare) &&
        !this.isEmpty(sosServices.targetMaxCap) &&
        sosServices.targetShare >= 0 &&
        sosServices.targetShare <= 100
      );
    } else if (sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.COMMUNICATION_COST) {
      let valid =
        !this.isEmpty(sosServices.communicationCostCurrency) &&
        !this.isEmpty(sosServices.communicationCostMinimum) &&
        !this.isEmpty(sosServices.communicationCostMaximum) &&
        !this.isEmpty(sosServices.communicationCostDefault) &&
        sosServices.communicationCosts &&
        sosServices.communicationCosts.length > 0;
      for (const commCost of sosServices.communicationCosts) {
        valid =
          valid &&
          commCost.continentCode &&
          commCost.continentCode !== '' &&
          commCost.phoneAmount > 0 &&
          commCost.smsAmount > 0 &&
          commCost.medicalAmount > 0;
      }
      return valid;
    } else if (sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.BANK_FEE) {
      let valid =
        !this.isEmpty(sosServices.communicationCostCurrency) &&
        sosServices.bankFees &&
        sosServices.bankFees.length > 0;
      for (const fee of sosServices.bankFees) {
        valid =
          valid &&
          fee.feeAmount !== null;
      }
      return valid;
    } else if (sosServices.sosServicesCategoryEnum === SosServicesCategoryEnum.ADDITIONAL_MEDICAL_SERVICES_ESCORT) {
      return !this.isEmpty(sosServices.medicalServiceOptions);
    }
    return !this.isEmpty(sosServices.targetPrice.amount) && !this.isEmpty(sosServices.targetPriceYear);
  }

  isSosServiceIdUnique(sosServices: SosServices, allSosServices: SosServices[]) {
    return allSosServices.filter(other => other.sosServiceId === sosServices.sosServiceId && other.id !== sosServices.id).length < 1;
  }

  isEmpty(obj) {
    if (obj && obj instanceof Date) {
      return false;
    }
    if (typeof obj === 'number') {
      return false;
    }
    if (Array.isArray(obj)) {
      return !obj.length;
    }
    return !obj || !Object.values(obj).some(x => x !== void 0);
  }
}
