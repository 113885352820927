import { ApplicationRef, Component, OnInit, ViewChild, Input, ViewChildren, QueryList } from '@angular/core';
import { CustomerProductRelationTypeEnum } from '../../../../shared/enums/policy/customer-product-relation-type.enum';
import { CustomerProductRelationFilterTypeEnum } from '../../../../shared/enums/policy/customer-product-relation-filter.type.enum';
import { SplitDateTypeEnum } from '../../../../shared/enums/policy/split-date.enum';
import { CustomerProductRelation } from '../../../../shared/models/customers/product/policy/customer-product-relation';
import { ProductPolicyRelationsModalComponent } from './product-policy-relations-modal/product-policy-relations-modal.component';
import { ConfirmModalComponent } from '../../../../shared/components/modal/confirm-modal/confirm-modal.component';
import { Product } from '../../../../shared/models/product';
import { PolicyLookupMethodService } from '../../../../core/services/policy-lookup-method.service';
import { Dictionary } from 'app/shared/models/dictionary';
import { DictionaryService } from '../../../../core/services/dictionary.service';
import * as moment from 'moment';
import { ProductsPolicyRelationSortingTypeEnum } from './product-policy-relations-sorting-menu/product-policy-relation-sorting-type-enum';
import { ProductPolicyRelationsSortingMenuComponent } from './product-policy-relations-sorting-menu/product-policy-relations-sorting-menu.component';
import {CustomerProductRelationUpdateService } from '../../../../core/services/customer-product-relation-update.service';
import {Subscription} from 'rxjs';
import { PermissionEnum } from '@onepoint/shared/models/permission.enum';
import { PermissionService } from '@onepoint/core/services/permission.service';


@Component({
  selector: 'app-product-policy-relations',
  templateUrl: './product-policy-relations.component.html',
  styleUrls: ['./product-policy-relations.component.scss']
})
export class ProductPolicyRelationsComponent implements OnInit {
  @ViewChildren(ProductPolicyRelationsSortingMenuComponent) filteringComponents: QueryList<ProductPolicyRelationsSortingMenuComponent>;
  @ViewChild('productPolicyRelationsModalComponent')
  productPolicyRelationsModalComponent: ProductPolicyRelationsModalComponent;

  @ViewChild('deleteConfirmModal')
  deleteConfirmModal: ConfirmModalComponent;

  splitDateTypes = new Map<string, string>([
    [SplitDateTypeEnum.INCIDENT_DATE, 'Incident date'],
    [SplitDateTypeEnum.REPORT_DATE, 'Report date']
  ]);

  searchText: string;

  @Input() product: Product;

  @Input() set productId(value) {
    if (value) {
      this._productId = value;
      this.getCustomerProductRelations();
    }
  }
  get productId() {
    return this._productId;
  }
  private _productId: number;
  displayDelete: boolean = false;
  productRelationID: number;
  selectedProductRelation: number;
  dropDownCustomerProductRelationFilterList: Dictionary[] = [];
  selectedFilterType: CustomerProductRelationFilterTypeEnum;
  showFilterMenu: boolean[] = [];
  subscription: Subscription;


  customerProductRelationsList: CustomerProductRelation[];
  filteredCustomerProductRelationsList: CustomerProductRelation[];
  searchResultcustomerProductRelationsList: CustomerProductRelation[];


  constructor( private policyLookupMethodService: PolicyLookupMethodService,
                private dictionaryService: DictionaryService,
                private customerProductRelationUpdateService: CustomerProductRelationUpdateService,
                private applicationRef: ApplicationRef,
                private permissionService: PermissionService) { }

  ngOnInit() {
    this.dropDownCustomerProductRelationFilterList = this.dictionaryService.getCustomerProductRelationFilterTypesForSearch();
    this.selectedFilterType = CustomerProductRelationFilterTypeEnum.ACTIVE_FUTURE;
   }

  open(){
    this.productPolicyRelationsModalComponent.open();
  }


  get PolicyRelationTypeEnum() {
    return CustomerProductRelationTypeEnum;
  }

  get ProductsPolicyRelationSortingTypeEnum() {
    return ProductsPolicyRelationSortingTypeEnum;
  }

  getCustomerProductRelations() {
    this.subscription = this.policyLookupMethodService.getCustomerProductRelationsByProductId(this.productId)
    .subscribe(result => {
        this.customerProductRelationsList = result;
        this.filterTable();
        this.customerProductRelationUpdateService.changeMessage(this.searchResultcustomerProductRelationsList);
      }
    );
  }

  filterTable() {
    this.filterActive();
    this.searchTable(this.searchText);
    this.customerProductRelationUpdateService.changeMessage(this.searchResultcustomerProductRelationsList);
  }

  finishedAndFilteringSorting() {
    this.showFilteringSortingMenu(ProductsPolicyRelationSortingTypeEnum.empty);
    this.searchTable(this.searchText);
    this.customerProductRelationUpdateService.changeMessage(this.searchResultcustomerProductRelationsList);
  }

  clearAllFiltering() {
    this.filterTable();
    this.applicationRef.tick();
    this.showFilteringSortingMenu(ProductsPolicyRelationSortingTypeEnum.empty);
  }

  showFilteringSortingMenu(filteringSortingType: ProductsPolicyRelationSortingTypeEnum) {
    for (let i = 0; i < this.showFilterMenu.length; i++) {
      if (i !== filteringSortingType.valueOf()) {
        this.showFilterMenu[i] = false;
      }
    }
    this.showFilterMenu[filteringSortingType] = !this.showFilterMenu[filteringSortingType];
  }

  setSelect(value: number) {
    this.selectedProductRelation = value;
  }

  getSelected() {
    return this.selectedProductRelation;
  }

  closeProductRelation() {
    this.productRelationID = undefined;
    this.selectedProductRelation = undefined;
  }

  addProductRelation(customerProductRelation: CustomerProductRelation) {
    if (!customerProductRelation.id) {
      customerProductRelation.productId = this.product.id;
      customerProductRelation.profileId = this.product.profileId;
      this.policyLookupMethodService.createCustomerProductRelations(customerProductRelation).subscribe(
        result => {
          this.customerProductRelationsList.push(result);
          this.productRelationID = undefined;
          this.filterTable();
          this.filteringComponents.forEach(component => component.updatePopulateItems(this.customerProductRelationsList));
          this.customerProductRelationUpdateService.changeMessage(this.searchResultcustomerProductRelationsList);
        }
      );
    } else {
      this.policyLookupMethodService.updateCustomerProductRelations(customerProductRelation).subscribe(
        result => {
          const updatedIndex = this.customerProductRelationsList.findIndex(customerProductRelationToRelpace =>  customerProductRelationToRelpace.id === result.id);
          this.customerProductRelationsList[updatedIndex] = result;
          this.productRelationID = undefined;
          this.filterTable();
          this.filteringComponents.forEach(component => component.updatePopulateItems(this.customerProductRelationsList));
          this.customerProductRelationUpdateService.changeMessage(this.searchResultcustomerProductRelationsList);
        }
      );
    }
  }

  showDeleteModal(productRelationID: number, productRelationName: string) {
    this.productRelationID = productRelationID;
    this.deleteConfirmModal.open('Delete product relation?',
      'You are about to delete a product relation for ' + productRelationName + '. Do you wish to proceed?');
  }

  editCustomerProductRelation(productRelationID: number) {
    this.productRelationID = productRelationID;
    this.productPolicyRelationsModalComponent.openExisting(productRelationID);
  }

  deleteProductRelation() {
    const customerProductRelationToDelete = this.customerProductRelationsList.find(customerProductRelation =>  customerProductRelation.id === this.productRelationID);
    const deleteIndex = this.customerProductRelationsList.findIndex(customerProductRelationToRelpace =>  customerProductRelationToRelpace.id === this.productRelationID);
    this.policyLookupMethodService.deleteCustomerProductRelations(customerProductRelationToDelete).subscribe(
      result => {
        this.customerProductRelationsList.splice(deleteIndex, 1);
        this.filterTable();
        this.customerProductRelationUpdateService.changeMessage(this.searchResultcustomerProductRelationsList);
      }
    );
  }

  searchTable(searchText: string) {
    if (this.searchText != null && this.searchText.length > 0) {
      searchText = searchText.toLowerCase();
      this.searchResultcustomerProductRelationsList = this.filteredCustomerProductRelationsList.filter(productRelation => {
        return  ( productRelation?.companyPolicyHolder?.toLowerCase().includes(searchText)) ||
                ( productRelation?.policyNo?.toLowerCase().includes(searchText)) ||
                ( productRelation?.bank?.toLowerCase().includes(searchText)) ||
                ( productRelation?.bin?.toString().includes(searchText)) ||
                ( productRelation?.customerRelationLabel?.name?.toLowerCase()?.includes(searchText));
      });
    }
    else {
       this.searchResultcustomerProductRelationsList = this.filteredCustomerProductRelationsList;
    }
  }

  filterActive() {
    let today = moment().format('YYYY-MM-DD');
    switch(this.selectedFilterType) {
      case CustomerProductRelationFilterTypeEnum.ACTIVE_FUTURE: {
        this.filteredCustomerProductRelationsList = this.customerProductRelationsList.filter(customerProductRelation => {
          return  (moment(customerProductRelation.validTo).isSameOrAfter(today) || !customerProductRelation.validTo);
        });
        break;
      }

      case CustomerProductRelationFilterTypeEnum.PAST: {
        this.filteredCustomerProductRelationsList = this.customerProductRelationsList.filter(customerProductRelation => {
          return (moment(customerProductRelation.validTo).isBefore(today));
        });
        break;
      }

      case CustomerProductRelationFilterTypeEnum.ALL: {
        this.filteredCustomerProductRelationsList = this.customerProductRelationsList;
        break;
      }
    }
  }

  closeFilteringSortingMenu() {
    for (let i = 0; i < this.showFilterMenu.length; i++) {
      if (this.showFilterMenu[i]) {
        this.showFilterMenu[i] = false;
      }
    }
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
  editable() {
    return this.permissionService.checkUserPermission(PermissionEnum.CUSTOMER_COVERAGES_LIMITS_RELATION_UPDATE) ||
      this.permissionService.checkUserPermission(PermissionEnum.CUSTOMER_COVERAGES_LIMITS_RELATION_DELETE);
  }
}
