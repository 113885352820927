export class MasterlistContactRoleModel {
  id: string;
  businessKey: string;
  contactRoleName: string;
  description: string;
  uiSort: string;
  activateOn: Date;
  deactivateOn: Date;
  active: boolean;

  autoImportFromSearch: boolean;
  excludeFromMedicalAssessment: boolean;
  excludeFromServiceOrder: boolean;
  gopPrimary: boolean;
  gopSecondary: boolean;

  modifiedOn: Date;
  modifiedBy: string;

  public constructor(init?: Partial<MasterlistContactRoleModel>) {
    Object.assign(this, init);
  }
}
