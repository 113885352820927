import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';

export class Country {
    id: number;
    url: string;
    code: string;
    countryName: string;
    continentCode: string;

    public constructor(init?: Partial<Country>) {
        Object.assign(this, init);
      }
}

@Injectable({
    providedIn: 'root'
  })
  export class CountryAdapter implements Adapter<Country> {
    adapt(item: any): Country {
      return new Country({
        id: item.id,
        code: item.alpha2Code,
        countryName: item.name,
        continentCode: item.continentCode,
        url: `/assets/flag-icons/${item.alpha2Code}.svg`.toLocaleLowerCase()
      });
    }
  }
