import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomerProductRelation } from 'app/shared/models/customers/product/policy/customer-product-relation';

@Injectable({
  providedIn: 'root'
})
export class CustomerProductRelationUpdateService {

  private customerProductRelationList = new BehaviorSubject(null);
  currentCustomerProductRelationList = this.customerProductRelationList.asObservable();

  changeMessage(newList: CustomerProductRelation[]) {
    this.customerProductRelationList.next(newList);
  }
}
