<div class="header clearfix">
  <div class="title">Price agreements ({{ priceAgreements.length }})</div>
  <button *permission="PermissionEnum.CUSTOMER_PRICE_AGREEMENT_CREATE" class="primary-button new-agreement" (click)="modal.openNew()">Add new price agreement</button>
</div>
<div class="agreement" *ngFor="let agreement of priceAgreements">
  <div class="agreement-name">{{ agreement.name }} <span class="suspended" *ngIf="agreement.suspended">Suspended</span></div>
  <div class="agreement-buttons">
    <button *permission="PermissionEnum.CUSTOMER_PRICE_AGREEMENT_CREATE" class="primary-button copy" (click)="modal.openCopy(agreement.id)">Create copy</button>
    <button *permission="PermissionEnum.CUSTOMER_PRICE_AGREEMENT_READ" class="primary-button open" (click)="modal.openExisting(agreement.id)">Open</button>
  </div>
</div>

<app-view-edit-price-agreement #modal (reloadPriceAgreements)="loadPriceAgreements()" [firstPriceYear]="commencementYear" [contract]="contract">
</app-view-edit-price-agreement>
