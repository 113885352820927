import {Injectable} from '@angular/core';
import {Adapter} from '@onepoint/shared/interfaces/adapter';

export class CaseProcess {
  id: number;
  caseProcessType: string;
  caseProcessName: string;
  description: string;

  public constructor(init?: Partial<CaseProcess>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CaseProcessAdapter implements Adapter<CaseProcess> {
  constructor() {
  }

  adapt(item: any): CaseProcess {
    return new CaseProcess({
      id: item.id,
      caseProcessType: item.caseProcessType,
      caseProcessName: item.caseProcessName,
      description: item.description
    });
  }
}
