<ng-template #content let-modal>
  <div class="modal-container">
    <div class="coverage-limits-modal-header">
      <div *ngIf="!isEdit" class="coverage-limits-title">Add coverage &amp; limit</div>
      <div *ngIf="isEdit" class="coverage-limits-title">Edit coverage &amp; limit</div>
      <div class="coverage-limits-close" (click)="close();"> <img src="assets/icons/Close.svg" alt="Close"></div>
    </div>
    <div class="coverage-limits-modal-body">
      <div class="input-title-name ">Coverage type<span class="star">*</span></div>
      <div class="dropdown-big">
        <select class="input-component coverage-period-value-container-unit"
          (change)="validateCoverageAndLimitsValueBeforeSave()" [(ngModel)]="coverage.coverageType">
          <option *ngFor="let item of coverageTypeDictionary" [value]="item.id">{{item.name}}</option>
        </select>
      </div>
      <div class="input-title-name ">Coverage ref. in terms<span class="star">*</span></div>
      <div class="coverage-limits-name-input">
        <app-input [(value)]="coverage.coverageTermsRef" (keyup)="validateCoverageAndLimitsValueBeforeSave()"></app-input>
      </div>
      <div class="input-title-name ">Coverage Name<span class="star">*</span></div>
      <div class="coverage-limits-name-input">
        <app-input [(value)]="coverage.coverageName" (keyup)="validateCoverageAndLimitsValueBeforeSave()">
        </app-input>
      </div>
      <div class="input-title-name ">Coverage Name Alias</div>
      <div class="coverage-limits-name-input">
        <app-input [(value)]="coverage.coverageNameAlias"></app-input>
      </div>
      <div class="input-title-name ">Coverage ID for Mapping</div>
      <div class="coverage-limits-name-input">
        <app-input [(value)]="coverage.coverageIdForMapping" (keyup)="validateCoverageAndLimitsValueBeforeSave()"></app-input>
      </div>
      <div class="input-title-name ">Sort in UI</div>
      <div class="coverage-limits-name-input">
        <app-input [(value)]="coverage.coverageSortOrder" (change)="validateCoverageAndLimitsValueBeforeSave()" [type]="'number'" ></app-input>
      </div>
      <div *ngFor="let limit of limits; let i = index">
        <div class="coverage mt-3 mb-3 p-3" [ngClass]="{ 'coverage-first-coverage': i === 0 }">
          <div class="coverage-title" *ngIf="i===0">Primary limit</div>
          <div class="input-title-name ">Description</div>
          <div class="coverage-limits-name-input">
            <app-input [(value)]="limit.description" (keyup)="validateCoverageAndLimitsValueBeforeSave()"></app-input>
          </div>
          <div class="input-title-name ">Limit type<span *ngIf="i !== 0 || limits.length > 1" class="star">*</span></div>
          <div class="dropdown-big">
            <select class="input-component coverage-period-value-container-unit" [(ngModel)]="limit.limitType"
              (change)="validateCoverageAndLimitsValueBeforeSave()">
              <option *ngFor="let item of limitUnitsTypesDictionary" [value]="item.id">{{item.name}}</option>
            </select>
          </div>
          <div *ngIf="limit.limitType != null" class="input-title-name ">Limit unit<span *ngIf="i !== 0" class="star">*</span></div>
          <div class="dropdown-small">
            <select *ngIf="getLimitUnitsTypesEnum[limit.limitType] === getLimitUnitsTypesEnum.DURATION"
              class="input-component coverage-period-value-container-unit" [(ngModel)]="limit.limitUnit"
              (change)="validateCoverageAndLimitsValueBeforeSave()">
              <option *ngFor="let item of LimitDurationUnits | keyvalue" [value]="LimitDurationUnits[item.key]">
                {{item.key | titlecase}}</option>
            </select>
            <select *ngIf="getLimitUnitsTypesEnum[limit.limitType] === getLimitUnitsTypesEnum.AMOUNT"
              class="input-component coverage-period-value-container-unit" [(ngModel)]="limit.limitUnit"
              (change)="validateCoverageAndLimitsValueBeforeSave()">
              <option *ngFor="let item of limitUnitsCurrenciesDictionary" [value]="item.id">{{item.name}}</option>
            </select>
            <select *ngIf="getLimitUnitsTypesEnum[limit.limitType] === getLimitUnitsTypesEnum.NUMBER_OF_PERSONS"
              class="input-component coverage-period-value-container-unit" [(ngModel)]="limit.limitUnit"
              (change)="validateCoverageAndLimitsValueBeforeSave()">
              <option *ngFor="let item of limitUnitsPersonsDictionary" [value]="item.id">{{item.name}}</option>
            </select>
            <select *ngIf="getLimitUnitsTypesEnum[limit.limitType] === getLimitUnitsTypesEnum.QUANTITY"
              class="input-component coverage-period-value-container-unit" [(ngModel)]="limit.limitUnit"
              (change)="validateCoverageAndLimitsValueBeforeSave()">
              <option *ngFor="let item of limitUnitsTreatmentsDictionary" [value]="item.id">{{item.name}}</option>
            </select>
          </div>
          <div *ngIf="getLimitUnitsTypesEnum[limit.limitType] === getLimitUnitsTypesEnum.DURATION">
            <div class="input-title-name">From<span *ngIf="i !== 0" class="star">*</span></div>
            <div class="maximum-limit-value-input">
              <select class="input-component coverage-period-value-container-unit" [(ngModel)]="limit.limitValue"
                (change)="validateCoverageAndLimitsValueBeforeSave()">
                <option *ngFor="let item of limitFromTypeDictionary" [value]="item.id">{{item.name}}</option>
              </select>
            </div>
          </div>
          <div class="input-title-name ">Maximum limit value</div>
          <div class="maximum-limit-value-input">
            <app-input [(value)]="limit.limitMaxValue" [type]="'number'" (valueChange)="validateCoverageAndLimitsValueBeforeSave()">
            </app-input>
          </div>
          <div *ngIf="i!==0" class="deleteIcon"><button (click)="deleteLimit(i)" class="btn flex-fill"><img
                src="assets/icons/garbage-red.svg" alt="Delete limits"></button> </div>
        </div>
      </div>
      <div class="add-button" (click)="addNewLimit()">Add more <img src="assets/icons/add-new.svg" alt="Add new"></div>
      <div class="coverage-limits-modal-footer">
        <button [disabled]="disabledSaveButton()" class="primary-button" (click)="saveCoverage()">Save</button>
      </div>
    </div>
  </div>
</ng-template>
