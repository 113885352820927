import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router, Event } from '@angular/router';
import { AutoUnsubscribe } from '@onepoint/shared/decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { RoutesName, RoutesNameUtils } from 'app/app-routes-name';
import { PermissionEnum, PermissionHideTypeEnum } from 'app/shared/models/permission.enum';
import { PermissionService } from 'app/core/services/permission.service';
import {SettingsService} from '@onepoint/core/services/settings.service';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
@AutoUnsubscribe
export class LeftMenuComponent implements OnInit {
  subcription: Subscription;
  selectedMenu: RoutesName;
  get RoutesName() {
    return RoutesName;
  }

  constructor(private router: Router, private permissionService: PermissionService, private settingsService: SettingsService) {}

  ngOnInit() {
    this.subcription = this.router.events.subscribe(
      (event: Event) => {
        if (event instanceof NavigationStart) {
          this.selectedMenu = RoutesNameUtils.getMainRouteFromUrl(event.url);
        }
      },
      error => console.log(error)
    );
  }

  isTabSelected(menuName: RoutesName): boolean {
    return menuName === this.selectedMenu;
  }

  customersClick() {
    this.selectedMenu = RoutesName.customers;
  }
  servicesClick() {
    this.selectedMenu = RoutesName.sosServices;
  }
  indexesClick() {
    this.selectedMenu = RoutesName.indexes;
  }
  templatesClick() {
    this.selectedMenu = RoutesName.templates;
  }
  BusinessRulesClick() {
    this.selectedMenu = RoutesName.businessRules;
  }
  suppliersClick() {
    this.selectedMenu = RoutesName.suppliers;
  }
  masterlistsClick() {
    this.selectedMenu = RoutesName.masterLists;
  }
  slaConfigurationClick() {
    this.selectedMenu = RoutesName.slaConfiguration;
  }
  digitalCaseflowClick() {
    this.selectedMenu = RoutesName.dcf;
  }
  erpDataClick() {
    this.selectedMenu = RoutesName.erpData;
  }
  get PermissionHideTypeEnum() {
    return PermissionHideTypeEnum;
  }
  get PermissionEnum() {
    return PermissionEnum;
  }
  customerProfileRelationsClick() {
    this.selectedMenu = RoutesName.customerProfileRelations;
  }
  customerProfileApplicationsClick() {
    this.selectedMenu = RoutesName.customerProfileApplications;
  }
  customerProfileCaseProcessesClick() {
    this.selectedMenu = RoutesName.customerProfileCaseProcesses;
  }
}
