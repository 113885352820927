<div class="digital-caseflow-customer-configuration">
  <div class="body-header">
    <div class="body-header-title-bar">
      <div class="body-header-title">Flow settings</div>
      <button class="primary-button save-btn"
              [disabled]="!updateNeeded"
              [ngClass]="!updateNeeded && 'button-disabled'"
              (click)="save()">Save
      </button>
    </div>
    <div class="body-header-bar">
      <div class="bar-item">
        <app-input [name]="'Name'" [(value)]="name" (focusOut)="validate()"></app-input>
      </div>
      <div class="bar-item">
        <app-drop-down-multiselect [(selectedItems)]="selectedCustomerProfiles"
                                   (selectedItemsChange)="customerProfileChanged($event)"
                                   [singleValue]="true"
                                   [dropdownItemList]="customerProfiles"
                                   [name]="'Customer profile'"
                                   [textField]="'name'"
                                   [disabled]="false">
        </app-drop-down-multiselect>
      </div>
      <div class="bar-item">
        <div class="item-title">
          Channel type
        </div>
        <app-drop-down class="dropdown" [items]="allChannels"
                       [(selectedItemId)]="customerConfig.channel"
                       (selectedItemIdChange)="validate()"></app-drop-down>
      </div>
      <div class="bar-item">
        <div class="item-title">
          Hide in Case Summary
        </div>
        <div class="slider">
          <app-input-switch [id]="'hiddenInSummary'"
                            [value]="customerConfig.hiddenInSummary"
                            (valueChange)="setHidden($event.valueOf())">
          </app-input-switch>
        </div>
      </div>
      <div class="last-bar-item">
        <div class="item-title">
          Active
        </div>
        <div class="slider">
          <app-input-switch [id]="'enabled'"
                            [value]="customerConfig.enabled"
                            (valueChange)="setIncluded($event.valueOf())">
          </app-input-switch>
        </div>
      </div>
    </div>
  </div>
  <div class="body-container container-fluid">
    <cdk-accordion class="dcf-accordion" [multi]="true">
      <cdk-accordion-item
        #accordionStart="cdkAccordionItem"
        class="dcf-accordion-item"
        role="button"
        tabindex="0"
        [attr.id]="'accordion-header-start'"
        [attr.aria-expanded]="accordionStart.expanded"
        [attr.aria-controls]="'accordion-body-start'">
        <div class="dcf-accordion-item-header" [ngClass]="{'dcf-accordion-item-header-open' : accordionStart.expanded}" (click)="accordionStart.toggle()">
          <div class="dcf-accordion-item-header-icon">
            <img src='/assets/icons/ArrowDown.svg' [@accordianState]='accordionStart.expanded ? "open" : "close"' alt='drop down' />
          </div>
          <div class="dcf-accordion-item-header-text">
            {{ 'Starting events' }}
          </div>
        </div>
        <div
          class="dcf-accordion-item-body"
          role="region"
          [style.display]="accordionStart.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-start'"
          [attr.aria-labelledby]="'accordion-header-start'">
          <div class="row">
            <div class="col-md builder column">
              <div class="column-header-title">Drag activities from here</div>
              <div class="column-header-description" *ngIf="startTypes.length == 0">No more activities</div>
              <div
                cdkDropList
                #possibleStartList="cdkDropList"
                [cdkDropListData]="startTypes"
                [cdkDropListConnectedTo]="[selectedStartList]"
                class="dragdrop-list"
                (cdkDropListDropped)="dropStartType($event)">
                <div class="dragdrop-box" *ngFor="let item of startTypes" cdkDrag>
                  <img class="dragdrop-box-icon" src='/assets/icons/dragger.svg'/>
                  <div class="dragdrop-box-text">
                    {{item}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md builder column">
              <div class="column-header-title">Starting activities</div>
              <div class="column-header-description" *ngIf="customerConfig.flow?.startTypes?.length == 0">Drag activities select what activities start a flow
              </div>
              <div
                cdkDropList
                #selectedStartList="cdkDropList"
                [cdkDropListData]="customerConfig.flow?.startTypes"
                [cdkDropListConnectedTo]="[possibleStartList]"
                class="dragdrop-list"
                (cdkDropListDropped)="dropStartType($event)">
                <div class="dragdrop-box" *ngFor="let item of customerConfig.flow.startTypes" cdkDrag>
                  <img class="dragdrop-box-icon" src='/assets/icons/dragger.svg'/>
                  <div class="dragdrop-box-text">
                    {{item}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md column last-column">
              <div class="column-header-title">Description</div>
              <div class="column-header-details">The configured below flow will start if any event on this list is happening in secca.</div>
              <div class="column-header-details">A configuration for specific customer configuration and channel configuration is needed</div>
              </div>
          </div>
        </div>
      </cdk-accordion-item>
      <cdk-accordion-item
        #accordionValidations="cdkAccordionItem"
        class="dcf-accordion-item"
        role="button"
        tabindex="0"
        [attr.id]="'accordion-header-validation'"
        [attr.aria-expanded]="accordionValidations.expanded"
        [attr.aria-controls]="'accordion-body-validation'">
        <div class="dcf-accordion-item-header" [ngClass]="{'dcf-accordion-item-header-open' : accordionValidations.expanded}" (click)="accordionValidations.toggle()">
          <div class="dcf-accordion-item-header-icon">
            <img src='/assets/icons/ArrowDown.svg' [@accordianState]='accordionValidations.expanded ? "open" : "close"' alt='drop down' />
          </div>
          <div class="dcf-accordion-item-header-text">
            {{ 'Validations' }}
          </div>
        </div>
        <div
          class="dcf-accordion-item-body"
          role="region"
          [style.display]="accordionValidations.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-validation'"
          [attr.aria-labelledby]="'accordion-header-validation'">
          <div class="row">
            <div class="col-md builder column">
              <div class="column-header-title">Drag validations from here</div>
              <div class="column-header-description" *ngIf="validations.length == 0">No more activities</div>
              <div
                cdkDropList
                #possibleValidationList="cdkDropList"
                [cdkDropListData]="validations"
                [cdkDropListConnectedTo]="[selectedValidationList]"
                class="dragdrop-list"
                (cdkDropListDropped)="drop($event)">
                <div class="dragdrop-box" *ngFor="let item of validations" cdkDrag>
                  <img class="dragdrop-box-icon" src='/assets/icons/dragger.svg'/>
                  <div class="dragdrop-box-text">
                    {{item.name}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md builder column">
              <div class="column-header-title">Validation activities</div>
              <div class="column-header-description" *ngIf="selectedValidations.length == 0">Drag validation to select what being checked before flow starts
              </div>
              <div
                cdkDropList
                #selectedValidationList="cdkDropList"
                [cdkDropListData]="selectedValidations"
                [cdkDropListConnectedTo]="[possibleValidationList]"
                class="dragdrop-list"
                (cdkDropListDropped)="drop($event)">
                <div class="dragdrop-box" *ngFor="let item of selectedValidations" cdkDrag>
                  <img class="dragdrop-box-icon" src='/assets/icons/dragger.svg'/>
                  <div class="dragdrop-box-text">
                    {{item.name}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md column last-column">
              <div class="column-header-title">Description</div>
              <div class="column-header-details">The configured below flow will start if any event on this list is happening in secca.</div>
              <div class="column-header-details">A configuration for specific customer configuration and channel configuration is needed</div>
              </div>
          </div>
        </div>
      </cdk-accordion-item>
      <cdk-accordion-item
        #accordionFlow="cdkAccordionItem"
        class="dcf-accordion-item"
        role="button"
        tabindex="0"
        [attr.id]="'accordion-header-flow'"
        [attr.aria-expanded]="accordionFlow.expanded"
        [attr.aria-controls]="'accordion-body-flow'">
        <div class="dcf-accordion-item-header" [ngClass]="{'dcf-accordion-item-header-open' : accordionFlow.expanded}" (click)="accordionFlow.toggle()">
          <div class="dcf-accordion-item-header-icon">
            <img src='/assets/icons/ArrowDown.svg' [@accordianState]='accordionFlow.expanded ? "open" : "close"' alt='drop down' />
          </div>
          <div class="dcf-accordion-item-header-text">
            {{ 'Flow' }}
          </div>
        </div>
        <div
          class="dcf-accordion-item-body"
          role="region"
          [style.display]="accordionFlow.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-flow'"
          [attr.aria-labelledby]="'accordion-header-flow'">
          <div class="row">
            <div class="col-md builder column">
              <div class="column-header-title">Drag activities from here</div>
              <div class="column-header-description" *ngIf="activities?.length == 0">No more activities</div>
              <div
                cdkDropList
                #possibleList="cdkDropList"
                [cdkDropListData]="activities"
                [cdkDropListConnectedTo]="[selectedList]"
                class="dragdrop-list"
                (cdkDropListDropped)="drop($event)">
                <div class="dragdrop-box" *ngFor="let item of activities" cdkDrag>
                  <img class="dragdrop-box-icon" src='/assets/icons/dragger.svg'/>
                  <div class="dragdrop-box-text">
                    {{item.name}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md builder column">
              <div class="column-header-title">Flow builder</div>
              <div class="column-header-description" *ngIf="selectedActivities.length == 0">Drag activities to build flow here
              </div>
              <div
                cdkDropList
                #selectedList="cdkDropList"
                [cdkDropListData]="selectedActivities"
                [cdkDropListConnectedTo]="[possibleList]"
                class="dragdrop-list"
                (cdkDropListDropped)="drop($event)">
                <div class="dragdrop-box" *ngFor="let item of selectedActivities" cdkDrag>
                  <img class="dragdrop-box-icon" src='/assets/icons/dragger.svg'/>
                  <div class="dragdrop-box-text">
                    {{item.name}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md builder column last-column">
              Work in progress
            </div>
          </div>
        </div>
      </cdk-accordion-item>
    </cdk-accordion>
  </div>
</div>
