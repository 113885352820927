import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import {Dictionary, DropdownAdapter} from 'app/shared/models/dictionary';
import { MasterListServiceItemModel } from 'app/shared/models/masterlist-service-item-model';
import { Observable } from 'rxjs';
import { MasterListUploadStatistics } from 'app/shared/models/masterlist-upload-statistics';
import { MasterListUploadInfo, MasterListUploadInfoAdapter } from 'app/shared/models/masterlist-upload-info';
import { map } from 'rxjs/operators';
import {
  MasterListCausesLevel1Model,
  MasterListCausesLevel2Model,
  MasterListCausesLevel3Model,
} from 'app/shared/models/masterlist-cause-model';
import { MasterListApplicationType } from 'app/shared/models/masterlist-applicationtype';
import { MasterListApplicationModel } from 'app/shared/models/masterlist-application-model';
import { MasterListServiceTypeModel } from 'app/shared/models/masterlist-service-type-model';
import {SlaCategory} from '@onepoint/shared/models/sla-category';
import { MasterListAdTeamsModel } from '@onepoint/shared/models/masterlist-ad-teams-model';
import { MasterListCaseTypeModel } from 'app/shared/models/masterlist-case-type-model';
import {
  MasterListRefundTypeModeAdapter,
  MasterListRefundTypeModel
} from '@onepoint/shared/models/masterlist-refund-type-model';
import { MasterListReductionModel } from '@onepoint/shared/models/masterlist-reduction-model';
import { MasterListSanctionCountryModel } from '@onepoint/shared/models/masterlist-sanction-country-model';
import { MasterListDedicatedEmailModel } from '@onepoint/shared/models/masterlist-dedicated-email-model';
import { MasterListCommitmentTypeModel } from '@onepoint/shared/models/masterlist-commitment-type-model';
import { MasterListIncidentEventModel } from '@onepoint/shared/models/masterlist-incident-event-model';
import {
  MasterListApprovalLimitsModel,
  MasterListApprovalPermissionLimitsModel
} from '@onepoint/shared/models/masterlist-approval-limits-model';
import { MasterlistDiagnosisModel } from '@onepoint/shared/models/masterlist-diagnosis-model';
import { MasterListQuestionnaireModel } from '@onepoint/shared/models/masterlist-questionnaire-model';
import { MasterListReasonForCancellationModel } from '@onepoint/shared/models/masterlist-reason-for-cancellation-model';
import { MasterListChannelTypeModel } from '@onepoint/shared/models/masterlist-channel-type-model';
import { MasterlistContactRoleModel} from '@onepoint/shared/models/masterlist-contact-role-model';

@Injectable({
  providedIn: 'root',
})
export class MasterListService extends BaseService {

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private dropdownAdapter: DropdownAdapter,
    private refundTypeAdapter: MasterListRefundTypeModeAdapter,
    private masterListUploadInfoAdapter: MasterListUploadInfoAdapter
  ) {
    super(settingsService);
  }

  getServiceItemList(): Observable<MasterListServiceItemModel[]> {
    return this.http.get<MasterListServiceItemModel[]>(this.baseURL + 'masterlist/service-item-list');
  }

  addServiceItem(item: MasterListServiceItemModel): Observable<MasterListServiceItemModel> {
    return this.http.post<MasterListServiceItemModel>(this.baseURL + 'masterlist/service-item-list/add', item);
  }

  updateServiceItem(item: MasterListServiceItemModel): Observable<MasterListServiceItemModel> {
    return this.http.post<MasterListServiceItemModel>(this.baseURL + 'masterlist/service-item-list/update', item);
  }

  uploadTranslations(file: File, applicationName: string): Observable<MasterListUploadStatistics> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('application', applicationName);

    return this.http.post<MasterListUploadStatistics>(this.baseURL + 'masterlist/translations/upload', formData);
  }

  getUploadedTranslationsInfo(applicationName: string): Observable<MasterListUploadInfo> {
    return this.http
      .get<MasterListUploadInfo>(this.baseURL + `masterlist/translations/upload-info/${applicationName}`)
      .pipe(map(response => this.masterListUploadInfoAdapter.adapt(response)));
  }

  getCausesLevel1(): Observable<MasterListCausesLevel1Model[]> {
    return this.http.get<MasterListCausesLevel1Model[]>(this.baseURL + 'masterlist/causes/level1/all');
  }

  getSlaCategoryList(): Observable<SlaCategory[]> {
    return this.http.get<SlaCategory[]>(this.baseURL + 'masterlist/slacategories');
  }

  addCausesLevel1(item: MasterListCausesLevel1Model): Observable<MasterListCausesLevel1Model> {
    return this.http.post<MasterListCausesLevel1Model>(this.baseURL + 'masterlist/causes/level1/add', item);
  }

  updateCausesLevel1(item: MasterListCausesLevel1Model): Observable<MasterListCausesLevel1Model> {
    return this.http.post<MasterListCausesLevel1Model>(this.baseURL + 'masterlist/causes/level1/update', item);
  }

  addCausesLevel2(level1CauseCode: string, item: MasterListCausesLevel2Model): Observable<MasterListCausesLevel2Model> {
    return this.http.post<MasterListCausesLevel2Model>(this.baseURL + `masterlist/causes/level2/add/${level1CauseCode}`, item);
  }

  updateCausesLevel2(item: MasterListCausesLevel2Model): Observable<MasterListCausesLevel2Model> {
    return this.http.post<MasterListCausesLevel2Model>(this.baseURL + 'masterlist/causes/level2/update', item);
  }

  addCausesLevel3(level2CauseCode: string, item: MasterListCausesLevel3Model): Observable<MasterListCausesLevel3Model> {
    return this.http.post<MasterListCausesLevel3Model>(this.baseURL + `masterlist/causes/level3/add/${level2CauseCode}`, item);
  }

  updateCausesLevel3(item: MasterListCausesLevel3Model): Observable<MasterListCausesLevel3Model> {
    return this.http.post<MasterListCausesLevel3Model>(this.baseURL + 'masterlist/causes/level3/update', item);
  }

  getServiceTypeList(): Observable<MasterListServiceTypeModel[]> {
    return this.http.get<MasterListServiceTypeModel[]>(this.baseURL + 'masterlist/service-type-list');
  }

  getCaseTypeList(): Observable<MasterListCaseTypeModel[]> {
    return this.http.get<MasterListCaseTypeModel[]>(this.baseURL + 'masterlist/case-type-list');
  }

  getReductionList(): Observable<MasterListReductionModel[]> {
    return this.http.get<MasterListReductionModel[]>(this.baseURL + 'masterlist/reductions');
  }

  getIncidentEventList(): Observable<MasterListIncidentEventModel[]> {
    return this.http.get<MasterListIncidentEventModel[]>(this.baseURL + 'masterlist/incident-events');
  }

  updateIncidentEvent(type: MasterListIncidentEventModel): Observable<MasterListIncidentEventModel> {
    return this.http.put<MasterListIncidentEventModel>(this.baseURL + 'masterlist/incident-events/update', type);
  }

  addIncidentEvent(item: MasterListIncidentEventModel): Observable<MasterListIncidentEventModel> {
    return this.http.post<MasterListIncidentEventModel>(this.baseURL + 'masterlist/incident-events/add', item);
  }

  getSanctionCountryList(): Observable<MasterListSanctionCountryModel[]> {
    return this.http.get<MasterListSanctionCountryModel[]>(this.baseURL + 'masterlist/sanctioncountries/all');
  }

  addSanctionCountry(sanctionCountry: MasterListSanctionCountryModel): Observable<MasterListSanctionCountryModel> {
    return this.http.post<MasterListSanctionCountryModel>(this.baseURL + 'masterlist/sanctioncountries/add', sanctionCountry);
  }

  updateSanctionCountry(sanctionCountry: MasterListSanctionCountryModel): Observable<MasterListSanctionCountryModel> {
    return this.http.put<MasterListSanctionCountryModel>(this.baseURL + 'masterlist/sanctioncountries/update', sanctionCountry);
  }

  updateCaseType(type: MasterListCaseTypeModel): Observable<MasterListCaseTypeModel> {
    return this.http.put<MasterListCaseTypeModel>(this.baseURL + 'masterlist/case-type-list/update', type);
  }

  getRefundTypeList(): Observable<MasterListRefundTypeModel[]> {
    return this.http.get<MasterListRefundTypeModel[]>(this.baseURL + 'masterlist/refund-type-list')
      .pipe(
        map((data: any[]) =>
          data.map(item => this.refundTypeAdapter.adapt(item))
        )
      );
  }

  updateRefundType(type: MasterListRefundTypeModel): Observable<MasterListRefundTypeModel> {
    return this.http.put<MasterListRefundTypeModel>(this.baseURL + 'masterlist/refund-type-list/update', type);
  }
  updateReduction(type: MasterListReductionModel): Observable<MasterListReductionModel> {
    return this.http.put<MasterListReductionModel>(this.baseURL + 'masterlist/reductions/update', type);
  }

  addReduction(item: MasterListReductionModel): Observable<MasterListReductionModel> {
    return this.http.post<MasterListReductionModel>(this.baseURL + 'masterlist/reductions/add', item);
  }


  getApplicationTypes(): Observable<MasterListApplicationType[]> {
    return this.http.get<MasterListApplicationType[]>(this.baseURL + 'masterlist/applications');
  }

  getApplicationsByMasterlist(masterlistType: string): Observable<MasterListApplicationModel[]> {
    return this.http.get<MasterListApplicationModel[]>(this.baseURL + `masterlist/${masterlistType}/applications`);
  }

  updateApplications(masterlistType: string, masterListApplications: MasterListApplicationModel[]) {
    return this.http.post<void>(this.baseURL + `masterlist/${masterlistType}/applications`, masterListApplications);
  }

  updateServiceType(type: MasterListServiceTypeModel): Observable<MasterListServiceTypeModel> {
    return this.http.put<MasterListServiceTypeModel>(this.baseURL + 'masterlist/service-type-list/update', type);
  }

  getAdTeams(): Observable<MasterListAdTeamsModel[]> {
    return this.http.get<MasterListAdTeamsModel[]>(this.baseURL + 'masterlist/adteams');
  }

  createAdTeams(model: MasterListAdTeamsModel): Observable<MasterListAdTeamsModel> {
    return this.http.post<MasterListAdTeamsModel>(this.baseURL + 'masterlist/adteams', model);
  }

  updateAdTeams(model: MasterListAdTeamsModel): Observable<MasterListAdTeamsModel> {
    return this.http.put<MasterListAdTeamsModel>(this.baseURL + `masterlist/adteams/${model.adTCode}`, model);
  }

  getAllDedicatedEmails(): Observable<MasterListDedicatedEmailModel[]> {
    return this.http.get<MasterListDedicatedEmailModel[]>(this.baseURL + `masterlist/dedicatedemails/`);
  }

  updateDedicatedEmail(model: MasterListDedicatedEmailModel) {
    return this.http.put<MasterListDedicatedEmailModel>(this.baseURL + `masterlist/dedicatedemails/update`, model);
  }

  addDedicatedEmail(model: MasterListDedicatedEmailModel) {
    return this.http.post<MasterListDedicatedEmailModel>(this.baseURL + `masterlist/dedicatedemails/add`, model);
  }

  addCommitmentType(model: MasterListCommitmentTypeModel) {
    return this.http.post<MasterListCommitmentTypeModel>(this.baseURL + `masterlist/commitmenttypes/add`, model);
  }

  updateCommitmentType(model: MasterListCommitmentTypeModel) {
   return this.http.put<MasterListCommitmentTypeModel>(this.baseURL + `masterlist/commitmenttypes/update`, model);
  }

  getAllCommitmentTypes() {
    return this.http.get<MasterListCommitmentTypeModel[]>(this.baseURL + `masterlist/commitmenttypes`);
  }

  public getBusinessKeyValuesWithoutGopByServiceType(serviceOrder: string): Observable<Dictionary[]> {
    return this.http
      .get(this.baseURL + 'masterlist/commitmenttypes/getBusinessKeyValuesWithoutGopByServiceType/' + serviceOrder)
      .pipe(map((data: any[]) => data.map(item => new Dictionary(item.businessKey, item.name))));
  }

  getApprovalLimits(): Observable<MasterListApprovalLimitsModel[]> {
    return this.http.get<MasterListApprovalLimitsModel[]>(this.baseURL + 'masterlist/approval-limits');
  }

  updateApprovalPermissionLimit(model: MasterListApprovalPermissionLimitsModel) {
    return this.http.put<MasterListApprovalPermissionLimitsModel>(this.baseURL + `masterlist/approval-limits/permission-limit-update`, model);
  }

  getDiagnoses(): Observable<MasterlistDiagnosisModel[]> {
    return this.http.get<MasterlistDiagnosisModel[]>(this.baseURL + 'masterlist/diagnosis');
  }

  updateDiagnosis(model: MasterlistDiagnosisModel) {
    return this.http.put<MasterlistDiagnosisModel>(this.baseURL + `masterlist/diagnosis/update`, model);
  }

  addDiagnosis(model: MasterlistDiagnosisModel) {
    return this.http.post<MasterlistDiagnosisModel>(this.baseURL + `masterlist/diagnosis/add`, model);
  }

  getIcd10CodeList() {
    return this.http.get<any>(this.baseURL + `icd10/`);
  }

  getQuestionnaireList(): Observable<MasterListQuestionnaireModel[]> {
    return this.http.get<MasterListQuestionnaireModel[]>(this.baseURL + 'masterlist/questionnaire');
  }

  updateQuestionnaire(model: MasterListQuestionnaireModel) {
    return this.http.put<MasterListQuestionnaireModel>(this.baseURL + `masterlist/questionnaire/update`, model);
  }

  addQuestionnaire(model: MasterListQuestionnaireModel) {
    return this.http.post<MasterListQuestionnaireModel>(this.baseURL + `masterlist/questionnaire/add`, model);
  }

  getReasonForCancellationList(): Observable<MasterListReasonForCancellationModel[]> {
    return this.http.get<MasterListReasonForCancellationModel[]>(this.baseURL + 'masterlist/reason-for-cancellation');
  }

  addReasonForCancellation(item: MasterListReasonForCancellationModel): Observable<MasterListReasonForCancellationModel> {
    return this.http.post<MasterListReasonForCancellationModel>(this.baseURL + 'masterlist/reason-for-cancellation/add', item);
  }

  updateReasonForCancellation(item: MasterListReasonForCancellationModel): Observable<MasterListReasonForCancellationModel> {
    return this.http.post<MasterListReasonForCancellationModel>(this.baseURL + 'masterlist/reason-for-cancellation/update', item);
  }

  
  getChannelTypeList(): Observable<MasterListChannelTypeModel[]> {
    return this.http.get<MasterListChannelTypeModel[]>(this.baseURL + 'masterlist/channel-type-list');
  }

  addChannelType(item: MasterListChannelTypeModel): Observable<MasterListChannelTypeModel> {
    return this.http.post<MasterListChannelTypeModel>(this.baseURL + 'masterlist/channel-type-list/add', item);
  }

  updateChannelType(type: MasterListChannelTypeModel): Observable<MasterListChannelTypeModel> {
    return this.http.put<MasterListChannelTypeModel>(this.baseURL + 'masterlist/channel-type-list/update', type);
  }
  getContactRoleList(): Observable<MasterlistContactRoleModel[]> {
    return this.http.get<MasterlistContactRoleModel[]>(this.baseURL + 'masterlist/contact-role-list');
  }
  updateContactRole(model: MasterlistContactRoleModel) {
    return this.http.put<MasterlistContactRoleModel>(this.baseURL + `masterlist/contact-role-list/update`, model);
  }
  createContactRole(model: MasterlistContactRoleModel) {
    return this.http.post<MasterlistContactRoleModel>(this.baseURL + `masterlist/contact-role-list/create`, model);
  }

}
