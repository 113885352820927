import { Country } from './../../models/country';
import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AutoCompleteTypeEnum } from './auto-complete-type-enum';
import { CountryService } from 'app/core/services/country.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { map } from 'rxjs/operators';
import { AutoUnsubscribe } from 'app/shared/decorators/auto-unsubscribe';

@Component({
  selector: 'app-drop-down-with-auto-complete-countries',
  templateUrl: './drop-down-with-auto-complete-countries.component.html',
  styleUrls: ['./drop-down-with-auto-complete-countries.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@AutoUnsubscribe
export class DropDownWithAutoCompleteCountriesComponent implements OnInit {
  @ViewChild('select') select: NgSelectComponent;
  @Input() autoCompleteTypeEnum: AutoCompleteTypeEnum;
  @Input() name: string;
  @Input() set selectedItemId(code) {
    if (!this.currentlySelectedItemId && code) {
      this.items = this.countryService.getCountryByAlpha2Code(code)
        .pipe(
          map((data: any) => [data])
        );
    }
    this.currentlySelectedItemId = code;
  }
  get selectedItemId() {
    return this.currentlySelectedItemId;
  }
  @Output() selectedItemIdChange = new EventEmitter();
  @Output() saveModel = new EventEmitter();
  @Input() disabled: boolean;
  @Input() showCode: boolean;
  @Input() withInput: boolean;
  @Input() value: string;
  @Output() valueChange = new EventEmitter();
  @Input() showEye: boolean;
  @Input() blockEmail: boolean;
  @Input() validate: boolean;
  @Input() validationType: string;
  @Input() validationTypeToInput: string;
  @Input() countryName: string;
  @Output() showNationalId = new EventEmitter();
  oldValue: string;
  subscription: Subscription;
  items: Observable<Country[]>;

  currentlySelectedItemId: any;
  oldSelectedItemId: any;
  input = new Subject<string | null>();

  constructor(
    private countryService: CountryService,
  ) {
    this.input.subscribe(newTerm => {
      this.search(newTerm);
    });
  }

  public setFocus() {
    this.select.focus();
  }

  ngOnInit() {}

  onValueChange() {
    this.valueChange.emit(this.value);
  }

  onSaveStateInput() {
    this.oldValue = this.value;
  }

  showNationalIdClick() {
    this.showNationalId.emit();
  }

  onSaveModelInput() {
    if (this.oldValue !== this.value) {
      this.onSaveModel();
    }
  }

  onChange() {
    this.selectedItemIdChange.emit(this.currentlySelectedItemId);
  }

  onSaveModel() {
    if ((this.oldValue !== this.value) || (this.oldSelectedItemId !== this.currentlySelectedItemId)) {
      this.saveModel.emit();
    }
  }

  onSaveState() {
    this.oldSelectedItemId = this.currentlySelectedItemId;
  }

  private search(term: string | null) {
    if (term) {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      switch (this.autoCompleteTypeEnum) {
        case AutoCompleteTypeEnum.Countries:
          this.subscription = this.countryService
            .searchCountries(term)
            .pipe(debounceTime(100))
            .pipe(result => (this.items = result))
            .subscribe();
          break;
        default:
          console.error('Action is not defined');
          break;
      }
    }
  }
}
