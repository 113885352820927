import { Adapter } from './../interfaces/adapter';
import { Injectable } from '@angular/core';
import { SplitDateTypeEnum } from '../enums/policy/split-date.enum';
import { CustomerProductRelationTypeEnum } from '../enums/policy/customer-product-relation-type.enum';
export class CustomerProductRelationOverview {
  id: string;
  type: CustomerProductRelationTypeEnum;
  customerProductName: string;
  customerProductVersion: string;
  customerProductId: string;
  insuranceCardLevel: string;
  companyPolicyHolder: string;
  policyNo: string;
  bank: string;
  bin: string;
  splitDateType: SplitDateTypeEnum;
  customerProfileName: string;
  remarks: string;
  customerRelationLabel: string;
  validFrom: Date;
  validTo: Date;


  public constructor(init?: Partial<CustomerProductRelationOverview>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CustomerProductRelationOverviewAdapter {
  adapt(item: any): CustomerProductRelationOverview {
    return new CustomerProductRelationOverview({
      id: item.id,
      type: item.type,
      customerProductName: item.customerProductName,
      customerProductVersion: item.customerProductVersion,
      insuranceCardLevel: item.insuranceCardLevel,
      companyPolicyHolder: item.companyPolicyHolder,
      policyNo: item.policyNo,
      bank: item.bank,
      bin: item.bin,
      splitDateType: item.splitDateType,
      customerProfileName: item.customerProfileName,
      remarks: item.remarks,
      customerRelationLabel: item.customerRelationLabel,
      validFrom: item.validFrom,
      validTo: item.validTo,
      customerProductId: item.customerProductId,
    });
  }
}
