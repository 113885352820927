import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Dictionary } from '@onepoint/shared/models/dictionary';
import {
  MasterListApprovalLimitsModel,
  MasterListApprovalPermissionLimitsModel
} from '@onepoint/shared/models/masterlist-approval-limits-model';
import { ApprovalPermissionLimitsMenuType } from '@onepoint/master-lists/approval-limits/approval-permission-limits-menu-type';
import { OnepointModalService, Size } from '@onepoint/core/services/onepoint.modal.service';
import { MasterListService } from '@onepoint/core/services/masterlist.service';
import * as _ from 'lodash';
import { PermissionEnum } from '@onepoint/shared/models/permission.enum';

@Component({
  selector: 'app-approval-limits',
  templateUrl: './masterlist-approval-limits.component.html',
  styleUrls: ['./masterlist-approval-limits.component.scss']
})
export class MasterlistApprovalLimitsComponent implements OnInit {
  @ViewChild('MasterListModal')
  private masterListModalTemplate: TemplateRef<any>;

  masterListForLimitType: MasterListApprovalLimitsModel[];
  masterList: MasterListApprovalPermissionLimitsModel[];
  masterListFiltered: MasterListApprovalPermissionLimitsModel[];
  masterListType: MasterListApprovalPermissionLimitsModel;
  showMenu = new Map<ApprovalPermissionLimitsMenuType, boolean>();
  serviceTypesMap = new Map<string, string>();
  limitTypes: Dictionary[];
  selectedLimitType: string;
  statusTypes: Dictionary[] = [
    { id: 'all', name: 'All' },
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
  ];
  selectedStatus = 'all';

  approvalLimitTypes = new Map<string, string>([
    ['REFUND', 'Refunds'],
    ['SUPPLIER_INVOICE', 'Supplier invoices'],
  ]);


  get ApprovalPermissionLimitsMenuType() {
    return ApprovalPermissionLimitsMenuType;
  }

  constructor(private masterListService: MasterListService, private onepointModalService: OnepointModalService) {
  }

  ngOnInit(): void {
    this.loadMasterList();
  }

  selectedStatusTypeChanged(status: string) {
    this.masterListFiltered = this.filterByStatus(status);
  }

  selectedLimitTypeChanged(selectedLimitType: string) {
    this.selectedLimitType = selectedLimitType;
    this.masterList = this.masterListForLimitType.find(item => item.limitType === this.selectedLimitType).approvalPermissionLimits;
    this.masterListFiltered = this.filterByStatus(this.selectedStatus);
  }

  private loadMasterList() {
    this.masterListService.getApprovalLimits().subscribe(result => {
      this.masterListForLimitType = result;
      this.limitTypes = result.map(item => {
        const limitTypeName = this.approvalLimitTypes.get(item.limitType);
        return new Dictionary(String(item.limitType), limitTypeName !== null ? limitTypeName : item.limitType);
      });
      if (this.selectedLimitType === undefined || this.selectedLimitType === null) {
        this.selectedLimitType = this.limitTypes[0].id;
      }
      this.masterList = result.find(item => item.limitType === this.selectedLimitType).approvalPermissionLimits;
      this.masterListFiltered = this.masterList;
    });
  }

  private filterByStatus(status: string): MasterListApprovalPermissionLimitsModel[] {
    if (status === 'all') {
      return this.masterList;
    }
    return this.masterList.filter(item => (status === 'active' ? item.active : !item.active));
  }

  showFilteringSortingMenu(filteringSortingMenuType: ApprovalPermissionLimitsMenuType) {
    this.showMenu.forEach((value, key) => {
      if (key !== filteringSortingMenuType) {
        this.showMenu[key] = false;
      }
    });
    this.showMenu[filteringSortingMenuType] = !this.showMenu[filteringSortingMenuType];
  }

  edit(item: MasterListApprovalPermissionLimitsModel) {
    this.masterListType = _.clone(item);
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.NORMAL, 'approval-limits-modal-container');
  }

  reload() {
    this.loadMasterList();
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
