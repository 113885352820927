import { RuleEnginePageComponent } from './modules/rule-engine/rule-engine-page.component';
import { ProfilePageComponent } from './modules/customers/profile-page/profile-page.component';
import { BrandPageComponent } from './modules/customers/brand-page/brand-page.component';
import { CommonCustomerPageComponent } from './modules/customers/common-customer-page/common-customer-page.component';
import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { CustomersPageComponent } from './modules/customers/customers-page/customers-page.component';
import { IndexesPageComponent } from './modules/indexes/indexes-page.component';
import { SosServicesPageComponent } from './modules/sos-services/sos-services-page.component';
import { CustomersComponent } from './modules/customers/customers.component';
import { SosServicesSummaryComponent } from './modules/sos-services/sos-services-summary/sos-services-summary.component';
import { RoutesName } from './app-routes-name';
import { SosServicesComponent } from './modules/sos-services/sos-services.component';
import { ProductPageComponent } from './modules/customers/product-page/product-page.component';
import { TemplatesComponent } from './modules/templates/templates.component';
import { MasterListsComponent } from './modules/master-lists/master-lists.component';
import { MasterListServiceItemComponent } from './modules/master-lists/service-item/masterlist-service-item.component';
import { MasterListCausesLevel1Component } from './modules/master-lists/causes/causes-level1/masterlist-causes-level1.component';
import { MasterListCausesLevel2Component } from './modules/master-lists/causes/causes-level2/masterlist-causes-level2.component';
import { MasterListCausesLevel3Component } from './modules/master-lists/causes/causes-level3/masterlist-causes-level3.component';
import { CustomerProfileRelationsComponent } from './modules/customer-profile-relations/customer-profile-relations.component';
import { MasterListMenuComponent } from './modules/master-lists/menu/masterlist-menu.component';
import { SuppliersPageComponent } from './modules/suppliers/suppliers-page/suppliers-page.component';
import { SuppliersComponent } from './modules/suppliers/suppliers.component';
import { SuppliersAllComponent } from './modules/suppliers/suppliers-all/suppliers-all.component';
import { MasterlistServiceTypeComponent } from './modules/master-lists/service-type/masterlist-service-type.component';
import { SlaConfigurationPageComponent } from './modules/sla-configuration/sla-configuration-page.component';
import { CustomerProfileApplicationsComponent } from './modules/customer-profile-applications/customer-profile-applications.component';
import { MasterListAdTeamsComponent } from '@onepoint/master-lists/ad-teams/masterlist-ad-teams.component';
import { MasterlistCaseTypeComponent } from './modules/master-lists/case-type/masterlist-case-type.component';
import { MasterlistRefundTypeComponent } from '@onepoint/master-lists/refund-type/masterlist-refund-type.component';
import { MasterlistReductionComponent } from '@onepoint/master-lists/reductions/masterlist-reduction.component';
import { MasterlistSanctionCountriesComponent } from '@onepoint/master-lists/sanction-countries/masterlist-sanction-countries.component';
import { MasterlistDedicatedEmailsComponent } from '@onepoint/master-lists/dedicated-emails/masterlist-dedicated-emails.component';
import { CustomerProfileCaseProcessesComponent } from './modules/customer-profile-case-processes/customer-profile-case-processes.component';
import { MasterlistCommitmentTypesComponent } from '@onepoint/master-lists/commitment-types/masterlist-commitment-types.component';
import { PermissionService } from '@onepoint/core/services/permission.service';
import { PermissionEnum } from '@onepoint/shared/models/permission.enum';
import { MasterlistIncidentEventsComponent } from '@onepoint/master-lists/incident-events/masterlist-incident-events.component';
import { MasterlistApprovalLimitsComponent } from '@onepoint/master-lists/approval-limits/masterlist-approval-limits.component';
import { MasterlistDiagnosesComponent } from '@onepoint/master-lists/diagnoses/masterlist-diagnoses.component';
import { MasterlistQuestionnaireComponent } from '@onepoint/master-lists/questionaire/masterlist-questionnaire.component';
import {DigitalCaseflowPageComponent} from "./modules/digital-caseflow/digital-caseflow-page.component";
import { MasterListReasonForCancellationComponent } from '@onepoint/master-lists/reason-for-cancellation/masterlist-reason-for-cancellation.component';
import { MasterlistChannelTypeComponent } from '@onepoint/master-lists/channel-type/masterlist-channel-type.component';
import { MasterlistContactRoleComponent } from '@onepoint/master-lists/contact-role/masterlist-contact-role.component';
import {ErpDataComponent} from "./modules/erp-data/erp-data.component";
import {
  DigitalCaseflowCustomerConfigurationPageComponent
} from "./modules/digital-caseflow/customer-configuration/digital-caseflow-customer-configuration-page.component";
import {DigitalCaseflowComponent} from "./modules/digital-caseflow/digital-caseflow.component";

@Injectable()
export class AccessGuard  {
  constructor(private router: Router, private permissionService: PermissionService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAccess(state);
  }

  private checkAccess(state: RouterStateSnapshot) {
    if (this.permissionService.checkUserPermission(PermissionEnum.CUSTOMER_AND_SOS_SERVICE_READ)) {
      this.router.navigate([RoutesName.commonCustomers]);
    } else {
      this.router.navigate([RoutesName.suppliers]);
    }
    return true;
  }
}



const routes: Routes = [
  {
    path: RoutesName.sosServices,
    component: SosServicesComponent,
    children: [
      { path: '', component: SosServicesSummaryComponent },
      {
        path: ':id',
        component: SosServicesPageComponent,
      },
    ],
  },
  { path: RoutesName.templates, component: TemplatesComponent, children: [{ path: '', component: TemplatesComponent }] },
  { path: RoutesName.businessRules, component: RuleEnginePageComponent },
  { path: RoutesName.indexes, component: IndexesPageComponent },
  {
    path: RoutesName.masterLists,
    component: MasterListsComponent,
    children: [
      { path: '', component: MasterListMenuComponent },
      { path: 'AD_TEAMS', component: MasterListAdTeamsComponent },
      { path: 'SERVICE_ITEM', component: MasterListServiceItemComponent },
      { path: 'SERVICE_TYPE', component: MasterlistServiceTypeComponent },
      { path: 'CASE_TYPE', component: MasterlistCaseTypeComponent },
      { path: 'REDUCTIONS', component: MasterlistReductionComponent },
      { path: 'SANCTION_COUNTRIES', component: MasterlistSanctionCountriesComponent },
      { path: 'CAUSE_LIST', component: MasterListCausesLevel1Component },
      { path: 'CAUSE_LIST/:level1Code', component: MasterListCausesLevel2Component },
      { path: 'CAUSE_LIST/:level1Code/:level2Code', component: MasterListCausesLevel3Component },
      { path: 'REFUND_TYPE', component: MasterlistRefundTypeComponent },
      { path: 'DEDICATED_EMAILS', component: MasterlistDedicatedEmailsComponent },
      { path: 'COMMITMENT_TYPES', component: MasterlistCommitmentTypesComponent },
      { path: 'INCIDENT_EVENT', component: MasterlistIncidentEventsComponent },
      { path: 'APPROVAL_LIMITS', component: MasterlistApprovalLimitsComponent },
      { path: 'DIAGNOSES', component: MasterlistDiagnosesComponent },
      { path: 'QUESTIONNAIRE', component: MasterlistQuestionnaireComponent },
      { path: 'REASON_FOR_CANCELLATION', component: MasterListReasonForCancellationComponent },
      { path: 'CHANNEL_TYPE', component: MasterlistChannelTypeComponent },
      { path: 'CONTACT_ROLE', component: MasterlistContactRoleComponent },
    ],
  },
  {
    path: RoutesName.customers,
    component: CustomersComponent,
    children: [
      { path: '', component: CustomersPageComponent },
      {
        path: RoutesName.commonCustomers + '/:id',
        component: CommonCustomerPageComponent,
      },
      {
        path: RoutesName.brand + '/:id',
        component: BrandPageComponent,
      },
      { path: RoutesName.profile + '/:id', component: ProfilePageComponent },
      { path: RoutesName.product + '/:id', component: ProductPageComponent },
    ],
  },
  { path: RoutesName.customerProfileRelations, component: CustomerProfileRelationsComponent },
  { path: RoutesName.customerProfileApplications, component: CustomerProfileApplicationsComponent },
  { path: RoutesName.customerProfileCaseProcesses, component: CustomerProfileCaseProcessesComponent },
  {
    path: RoutesName.suppliers,
    component: SuppliersComponent,
    children: [
      { path: '', component: SuppliersAllComponent },
      { path: ':id', component: SuppliersPageComponent },
    ],
  },
  {
    path: RoutesName.slaConfiguration,
    component: SlaConfigurationPageComponent,
  },
  {
    path: RoutesName.erpData,
    component: ErpDataComponent,
  },
  {
    path: RoutesName.dcf,
    component: DigitalCaseflowComponent,
    children: [
      { path: '', component: DigitalCaseflowPageComponent },
      { path: RoutesName.dcfCustomerConfig + '/:id', component: DigitalCaseflowCustomerConfigurationPageComponent }
    ]
  },
  { path: '**', children: [], pathMatch: 'full', canActivate: [AccessGuard] },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
