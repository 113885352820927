import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({ selector: '[appTrim]' })
export class TrimDirective {
  @Input() appTrim = false;

  constructor(private ngControl: NgControl) {
    this.trimValueAccessor(ngControl.valueAccessor);
  }

  trimValueAccessor(valueAccessor: ControlValueAccessor) {
    const original = valueAccessor.registerOnChange;

    valueAccessor.registerOnChange = (fn: (_: unknown) => void) => {
      return original.call(valueAccessor, (value: unknown) => {
        return fn(this.appTrim && typeof value === 'string' ? value.trim() : value);
      });
    };
  }
}


