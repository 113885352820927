import {Component, Input, OnInit} from '@angular/core';
import {Product} from '../../../../shared/models/product';
import {Dictionary} from '../../../../shared/models/dictionary';
import {DictionaryService} from '../../../../core/services/dictionary.service';
import {InsuranceTypeEnum} from '../../../../shared/enums/insurance-type-enum';
import {PersonGroupEnum} from '../../../../shared/enums/person-group-enum';
import {CoveragePeriodTypeEnum} from '../../../../shared/enums/coverage-period-type-enum';
import {ProfileCountryListsService} from '../../../../core/services/profile-country-lists.service';
import {CoveragePeriodUnitEnum} from '../../../../shared/enums/coverage-period-unit-enum';
import {SelectedByEnum} from '../../../../shared/enums/selected-by-enum';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {APP_DATE_FORMATS, AppDateAdapter} from '../../../../shared/components/input-calendar/format-datepicker';
import {PurposeOfTravelTypeEnum} from '../../../../shared/models/enums';
import {map} from 'rxjs/operators';
import {ProfileService} from "../../../../core/services/profile.service";

@Component({
  selector: 'app-product-settings-form',
  templateUrl: './product-settings-form.component.html',
  styleUrls: ['./product-settings-form.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})

export class ProductSettingsFormComponent implements OnInit {
  _product: Product;
  @Input() set product(product: Product) {
    this._product = product;
    if (product) {
      this.profileService.isGroupProfile(String(product.profileId)).subscribe(result => {
        this.isGroupProfile = result;
      })
    }
  }
  get product() {
    return this._product;
  }
  @Input() newVersion = false;

  primaryPurposeOfTravelDictionary: Dictionary[] = [];
  secondaryPurposeOfTravelDictionary: Dictionary[] = [];
  geographicalCoverageAreaDictionary: Dictionary[] = [];
  primaryTravelPurpose: Dictionary[] = [];
  secondaryTravelPurpose: Dictionary[] = [];
  selectedGeographicalCoverageAreas: Dictionary[] = [];
  isGroupProfile: boolean;

  constructor(private dictionaryService: DictionaryService,
              private countryListService: ProfileCountryListsService,
              private profileService: ProfileService) {
  }

  private loadPurposesOfTravel() {
    this.dictionaryService.getAllPurposeOfTravels().subscribe(data => {
      this.primaryPurposeOfTravelDictionary = data;
      this.primaryPurposeOfTravelDictionary.sort((a, b) => a.name.localeCompare(b.name));
      this.secondaryPurposeOfTravelDictionary = this.primaryPurposeOfTravelDictionary.slice();
      this.secondaryPurposeOfTravelDictionary.shift();
    }, error => {throw error}, () => this.initiateProduct());
  }

  private initiateProduct() {
    if (this.product.primaryPurposeOfTravelCode) {
      this.primaryTravelPurpose = [this.primaryPurposeOfTravelDictionary.find(i => i.id === this.product.primaryPurposeOfTravelCode)];
    }
    if (this.product.secondaryPurposesOfTravelCodes) {
      this.secondaryTravelPurpose =
        this.product.secondaryPurposesOfTravelCodes.map(pCode =>
          this.secondaryPurposeOfTravelDictionary.find(i => i.id === pCode));
    }
  }

  public ngOnInit(): void {
    this.loadCountryLists();
    this.loadPurposesOfTravel();
  }

  public levelInputLabel(): string {
    if (this.product.insuranceType === this.InsuranceType.regular) {
      return 'Insurance level for mapping';
    }
    if (this.product.insuranceType === this.InsuranceType.card) {
      return 'Card level for mapping';
    }
    return 'Invalid';
  }



  loadCountryLists() {
    this.countryListService.getAll(this.product.profileId)
      .pipe(map(data => data.map(item => new Dictionary(item.id.toString(), item.name))))
      .subscribe(
        result => {
          this.geographicalCoverageAreaDictionary = result;
          if (this.product.coverageGeographicAreaIds) {
            this.selectedGeographicalCoverageAreas = this.mapCountryListsToDictionary(this.product.coverageGeographicAreaIds);
          }
        },
        error => console.error(error)
      );
  }

  get PersonGroupEnumKeys() {
    return Object.keys(PersonGroupEnum);
  }

  get PersonGroupEnum() {
    return PersonGroupEnum;
  }

  get SelectedByEnumKeys() {
    return Object.keys(SelectedByEnum);
  }

  get SelectedByEnum() {
    return SelectedByEnum;
  }

  get InsuranceType() {
    return InsuranceTypeEnum;
  }

  get CoveragePeriodType() {
    return CoveragePeriodTypeEnum;
  }

  get CoveragePeriodUnits() {
    return CoveragePeriodUnitEnum;
  }

  validToChanged() {
    this.setPurposeOfTravels();
    if (!!this.product.validTo) {
      this.product.deactivationDate = new Date(this.product.validTo.getFullYear() + 3,
        this.product.validTo.getMonth(),
        this.product.validTo.getDate());
    }
  }

  private mapToTravelPurposeEnum(travelPurpose: string): Dictionary {
    return new Dictionary(travelPurpose, PurposeOfTravelTypeEnum[travelPurpose]);
  }

  setPurposeOfTravels() {
    if (this.primaryTravelPurpose[0] !== undefined) {
      this.product.primaryPurposeOfTravelCode = this.primaryTravelPurpose[0].id;
    }
    if (this.isPrimaryPurposeOfTravelEqualToAllPurpose()) {
      this.secondaryTravelPurpose = [];
    }
    // here we remove the selected primary travel purpose from selected secondary purposes
    this.secondaryTravelPurpose = this.secondaryTravelPurpose.filter(val => val.id !== this.product.primaryPurposeOfTravelCode);
    this.product.secondaryPurposesOfTravelCodes = this.secondaryTravelPurpose.map(purpose => purpose.id);
  }

  isPrimaryPurposeOfTravelEqualToAllPurpose() {
    return this.product.primaryPurposeOfTravelCode === 'POT01';
  }

  setCountryList(selectedGeographicCoverageAreas: Dictionary[]) {
    this.product.coverageGeographicAreaIds = selectedGeographicCoverageAreas.map(val => +val.id);
    this.validToChanged();
  }

  mapCountryListsToDictionary(countryListsIds: number[]): Dictionary[] {
    let mappedCountryLists = [];
    if (this.geographicalCoverageAreaDictionary) {
      countryListsIds.forEach(item => {
        mappedCountryLists = mappedCountryLists.concat(this.geographicalCoverageAreaDictionary.filter(cl => cl.id === item.toString()));
      });
    }
    return mappedCountryLists;
  }

  addNewProductId() {
    this.product.customerProductIds.push('');
  }

  deleteProductId(idx: number) {
    this.product.customerProductIds.splice(idx, 1);
  }

  addNewLevel() {
    if (this.product.insuranceLevels == null) {
      this.product.insuranceLevels = [];
    }
    this.product.insuranceLevels.push('');
  }

  deleteLevel(idx: number) {
    this.product.insuranceLevels.splice(idx, 1);
  }

  trackByFn(index) {
    return index;
  }
}
