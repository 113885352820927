<div class="causes-modal-container">
  <div class="closing-x" (click)="closeModal()">
    <img src="/assets/icons/Close.svg">
  </div>
  <div class="header">
    <span *ngIf="isNew">Create new level-2 cause</span>
    <span *ngIf="!isNew">Edit level-2 cause</span>
  </div>
  <div class="row body-container" *ngIf="masterListItem">
    <div class="col-3">
      <div class="input-name">
        Activate on
        <span *ngIf="!isReadonly" class="required">*</span>
      </div>
      <app-input-calendar [withImage]="true" [selectMode]="'single'" [disabled]="isReadonly"
                          [(dateTimeRange)]="masterListItem.activateOn" [maxDate]="masterListItem.deactivateOn"
                          (saveModel)="activateOnChanged(masterListItem)">
      </app-input-calendar>
      <div class="input-name">
        Deactivate on
      </div>
      <app-input-calendar [withImage]="true" [selectMode]="'single'" [disabled]="isReadonly"
                          [(dateTimeRange)]="masterListItem.deactivateOn" [minDate]="masterListItem.activateOn"
                          (saveModel)="deactivateOnChanged(masterListItem)">
      </app-input-calendar>
    </div>
    <div class="col-9">
      <div class="row">
        <div class="col-6">
          <div>
            <app-drop-down [name]="'Business area'" [(selectedItemId)]="masterListItem.businessArea" (saveModel)="modelChanged()"
              [items]="businessAreas" [disabled]="isReadonly" [required]="!isReadonly">
            </app-drop-down>
          </div>
          <div>
            <app-input [name]="'Business key'" [(value)]="masterListItem.level2Code" (valueChange)="modelChanged()" [disabled]="isReadonly || masterListItem.id" [required]="!isReadonly"></app-input>
          </div>
          <div class="padding-top">
            <app-input [name]="'Name'" [(value)]="masterListItem.level2" (valueChange)="modelChanged()" [disabled]="isReadonly" [required]="!isReadonly"></app-input>
          </div>
          <div class="padding-top">
            <app-input [name]="'Sort in UI'" [(value)]="masterListItem.uiSortIndex" (valueChange)="modelChanged()" [disabled]="isReadonly"></app-input>
          </div>

        </div>
      </div>
    </div>
    <div class="row body-container" *ngIf="error">
      <div class="col-12 error">
        {{error}}
      </div>
    </div>
  </div>
  <div class="masterlist-modal-footer d-flex justify-content-between">
    <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE" class="">
      <button class="primary-button save-button" [class.button-disabled]="!isSaveButtonEnabled" (click)="save()">Save</button>
    </div>
    <app-last-changed [masterListItem]="masterListItem" class="last-changed float-end"> </app-last-changed>
  </div>
</div>
