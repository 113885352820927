import * as moment from 'moment';
export class MasterListSanctionCountryModel {
  id: string;
  code: string;
  name: string;
  alphaCountryCode: string;
  limitServiceTypes: boolean;
  infoTask: boolean;
  screening: boolean;
  activateOn: Date;
  deactivateOn: Date;
  active: boolean;
  modifiedOn: Date;
  modifiedBy: string;

  public constructor(init?: Partial<MasterListSanctionCountryModel>) {
    Object.assign(this, init);
  }
}
