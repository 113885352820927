import { CountryAdapter } from 'app/shared/models/country';
import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { Country } from './country';

export class ProfileCountryListDetail {
  id: number;
  name: string;
  profileId: number;
  countries: Array<Country> = [];

  get numberOfCountries(): number {
    return this.countries.length;
  }

  public constructor(init?: Partial<ProfileCountryListDetail>) {
    Object.assign(this, init);
  }

}
@Injectable({
  providedIn: 'root'
})
export class ProfileCountryListDetailAdapter implements Adapter<ProfileCountryListDetail> {
  constructor() {}
  adapt(item: any): ProfileCountryListDetail {
    const countryAdapter = new CountryAdapter();
    const result = new ProfileCountryListDetail({
      id: item.id,
      name: item.name,
      profileId: item.profileId,
      countries: item.countries.map(countryAdapter.adapt)
    });
    return result;
  }
}
