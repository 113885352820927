import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from './settings.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppliedProfile, AppliedProfileAdapter} from '../../shared/models/applied-profile';

@Injectable({
  providedIn: 'root'
})
export class AppliedProfileService extends BaseService {
  constructor(private settingsService: SettingsService,
              private http: HttpClient,
              private appliedProfileAdapter: AppliedProfileAdapter) {
    super(settingsService);
  }

  public getAppliedProfiles(contractId: string): Observable<AppliedProfile[]> {
    return this.http.get(this.baseURL + 'contracts/' + contractId + '/applied-profiles', {
      headers: this.jsonHeaders
    }).pipe(
      map((data: any[]) =>
        data.map(item => this.appliedProfileAdapter.adapt(item))
      )
    );
  }

  public getAppliedProfile(contractId: string, id: string): Observable<AppliedProfile> {
    return this.http.get(this.baseURL + 'contracts/' + contractId + '/applied-profiles/' + id, {
      headers: this.jsonHeaders
    }).pipe(
      map(item => this.appliedProfileAdapter.adapt(item))
    );
  }

  public saveAppliedProfile(contractId: string, appliedProfile: AppliedProfile): Observable<AppliedProfile> {
    return this.http.post<AppliedProfile>(this.baseURL + 'contracts/' + contractId + '/applied-profiles',
      this.appliedProfileAdapter.adaptForServiceCall(appliedProfile), { headers: this.jsonHeaders }
      ).pipe(
        map(item => this.appliedProfileAdapter.adapt(item))
    );
  }

  public deleteAppliedProfile(contractId: string, appliedProfileId: string): Observable<any> {
    return this.http.delete(this.baseURL + 'contracts/' + contractId + '/applied-profiles/' + appliedProfileId,
      {headers: this.jsonHeaders});
  }
}
