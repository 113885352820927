import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from 'app/shared/models/dictionary';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { SettingsService } from './settings.service';

@Injectable({
    providedIn: 'root',
})
export class CausesService  extends BaseService {

    constructor(private http: HttpClient,
                private settingsService: SettingsService) {
        super(settingsService);
    }

  private static mapCausesLevel1(causeList: any[]): Dictionary[] {
      return causeList.map(item => new Dictionary(item.id, item.level1));
  }

  private static mapCausesLevel2(causeList: any[]): Map<string, Dictionary[]> {
      const causes = new Map<string, Dictionary[]>();
      causeList.forEach(item =>
        causes.set(item.id, item.level2Causes.map(
          cause => new Dictionary(cause.id, cause.level2)
        ))
      );
      return causes;
  }

  public getCauses(): Observable<[Dictionary[], Map<string, Dictionary[]>]> {
    return this.http.get(this.baseURL + 'masterlist/causes/level1/all').pipe(
      map((items: any[]) => [CausesService.mapCausesLevel1(items), CausesService.mapCausesLevel2(items)])
    );
  }
}
