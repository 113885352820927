export enum LimitTypeEnum {
  REASONABLE = 'REASONABLE',
  AMOUNT = 'AMOUNT',
  NUMBER_OF_PERSONS = 'NUMBER_OF_PERSONS',
  DURATION = 'DURATION',
  QUANTITY = 'QUANTITY',
  AGE = 'AGE',
}

export enum LimitDurationUnitEnum {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
}

export enum LimitFromTypeEnum {
  INCIDENT_DATE = 'INCIDENT_DATE',
  FIRST_MEDICAL_VISIT = 'FIRST_MEDICAL_VISIT',
}

export enum LimitPersonTypeEnum {
  PERSON = 'PERSON',
}

export enum LimitQuantityTypeEnum {
  TREATMENTS = 'TREATMENTS',
}

export enum CoverageTypeEnum {
  BASIC = 'BASIC',
  SUPPLEMENT = 'SUPPLEMENT',
}

export enum BusinessAreaEnum {
  Empty = '',
  TRAVELCARE = 'Travelcare',
  MOBILITY = 'Mobility',
  HEALTHCARE = 'Healthcare',
}

export enum PurposeOfTravelTypeEnum {
  ALL_PURPOSES = 'All purposes',
  LEISURE = 'Holiday / Leisure',
  BUSINESS = 'Business',
  EXPATRIATION = 'Expatriation',
  STUDYING = 'Studying',
  MEDICAL_TREATMENT = 'Medical treatment',
  SPORT = 'Sport: Extreme, Professional, Competition, etc.',
  TRAINEE = 'Trainee / Volunteer',
  AU_PAIR = 'Au Pair',
  EXPEDITION = 'Expedition',
}

export enum SosServicesCategoryEnum {
  MEDICAL_PRE_ASSESSMENT = 'Medical pre-assessment',
  PRE_DEPARTURE_TRAVEL_SERVICE = 'Pre-departure travel service',
  MEDICAL_FLIGHT_AUTHORIZATION = 'Medical flight authorisation',
  ALARM_CENTER = 'Alarm center',
  BANK_FEE = 'Bank fee',
  BATCH_BILLING = 'Batch billing',
  COST_CONTROL = 'Cost control',
  SHARE_OF_COST_CONTROL = 'Share of cost control',
  SHARE_OF_RECOVERY = 'Share of recovery',
  CLAIMS_TRAVEL = 'Claims',
  OTHER = 'Other services',
  COMMUNICATION_COST = 'Communication cost',
  ADDITIONAL_MEDICAL_SERVICES = 'Additional medical services',
  ADDITIONAL_MEDICAL_SERVICES_ESCORT = 'Additional medical services - escort',
}

export enum FeeTypeEnum {
  PRIMARY = 'Primary case fee',
  SECONDARY = 'Secondary case fee',
}

export enum CaseInformationEnum {
  CASE_EVENT_PUSH = 'Case event - Legacy push',
  CASE_EVENT_PULL = 'Case event - Legacy pull',
  CASE_EVENT_SECCA = 'Case event - SECCA',
  ROBOTICS = 'Robotics',
  NO_INTEGRATION = 'No integration',
}

export enum InvoicingEnum {
  CUSTOMER_PORTAL_NO_EMAIL = 'Customer portal (no email)',
  EMAIL = 'Email',
  ELECTRONIC_INVOICE = 'Electronic invoice',
  MAIL_PRINT = 'Mail (Print)',
  CASE_EVENT_PULL = 'Case event - Legacy pull',
}

export enum CommunicationCostTypeEnum {
  OUTGOING_PHONE = 'Phone minutes',
  OUTGOING_SMS = 'Outgoing SMS',
  MEDICAL_VOICE = 'Medical voice',
}

export enum MedicalServiceOptionsEnum {
  HOURLY = 'Hourly',
  FIRST_DAY = 'First day',
  FOLLOWING_DAYS = 'Following days',
  MEDICINE = 'Medicine',
  EQUIPMENT = 'Equipment',
}

export enum PriceIndexEnum {
  DANISH_SALARY_INDEX = 'Danish salary index',
  DANISH_CONSUMER_PRICE_INDEX = 'Danish consumer price index',
}

export enum ApplicationTypeEnum {
  CREATE_CASE = 'Create Case',
  ONLINE_CLAIMS = 'Online Claims',
  CLAIMS_BUSINESS = 'Claims Business',
  MPA = 'MPA',
  FOLLOW_MY_CASE = 'Follow-my case',
  SUPPLIER_PORTAL = 'Supplier portal',
  VIDEO_DOCTOR = 'Video doctor',
  CASE_EVENTS = 'Case events',
  SECCA = 'SECCA',
  HELP_ME_CREATE_CASE = 'Help me APP - New case',
  CREATE_CASE_EXPAT_BTI = 'Create Case Expat BTI',
  FIND_CARE = 'Find Care',
}

export enum TemplateRefreshingStateEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export enum EventCategory {
  ATTACKS_INCL_TERROR = 'Attacks incl terror',
  BANKRUPTCY = 'Bankruptcy',
  EPIDEMIC_PANDEMIC_OUTBREAK = 'Epidemic/pandemic outbreak',
  GOVERNMENTAL_INCIDENT = 'Governmental incident',
  NATURAL_DISASTER = 'Natural disaster',
  SPORT_EVENT = 'Sports event',
  STRIKE = 'Strike',
  TRANSPORT_INCIDENT = 'Transport incident',
  WAR_CIVIL_UNREST = 'War/civil unrest',
  OTHER_EVENT = 'Other event',
}









