import { ProfileService } from 'app/core/services/profile.service';
import { CommonCustomerService } from './../../../core/services/common-customer.service';
import { BrandService } from './../../../core/services/brand.service';
import { Router, Event, NavigationStart, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'app/shared/decorators/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { SosServicesService } from 'app/core/services/sos-services.service';
import { RoutesName, RoutesNameUtils } from 'app/app-routes-name';
import { ProductService } from '../../../core/services/product.service';
import { BreadcrumbsMenuUpdateService } from './breadcrumbs-menu-update-service';
import { map } from 'rxjs/operators';
import { MASTER_LISTS } from 'app/modules/master-lists/common/common';
import { SupplierProfileService } from 'app/core/services/supplier-profile.service';
import {DigitalCaseflowService} from "@onepoint/core/services/digital-caseflow.service";

class MenuItem {
  id: string;
  name: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

@Component({
  selector: 'app-breadcrumbs-menu',
  templateUrl: './breadcrumbs-menu.component.html',
  styleUrls: ['./breadcrumbs-menu.component.scss'],
})
@AutoUnsubscribe
export class BreadcrumbsMenuComponent implements OnInit {
  subcription: Subscription;

  commonCustomerMenuItem: MenuItem;
  brandMenuItem: MenuItem;
  profileMenuItem: MenuItem;
  productMenuItem: MenuItem;
  customersMenuItem: MenuItem;
  sosServicesMenuItem: MenuItem;
  sosServiceMenuItem: MenuItem;
  masterListsMenuItem: MenuItem;
  masterListMenuItem: MenuItem;
  masterListCausesLevel1MenuItem: MenuItem;
  masterListCausesLevel2MenuItem: MenuItem;
  supplierProfilesMenuItem: MenuItem;
  supplierProfileMenuItem: MenuItem;
  digitalCaseflowMenuItem: MenuItem;
  digitalCaseflowMenuConfigurationItem: MenuItem;

  showBreadcrumbs: boolean;

  constructor(
    private router: Router,
    private brandService: BrandService,
    private commonCustomerService: CommonCustomerService,
    private profileService: ProfileService,
    private productService: ProductService,
    private sosServicesService: SosServicesService,
    private supplierProfileService: SupplierProfileService,
    private breadcrumbsMenuUpdateService: BreadcrumbsMenuUpdateService,
    private digitalCaseflowService: DigitalCaseflowService
  ) {}

  ngOnInit() {
    this.subcription = this.router.events.subscribe(
      (event: Event) => {
        if (event instanceof NavigationStart) {
          this.handleNavigationStartEvent(event);
        }
      },
      error => console.log(error)
    );

    this.breadcrumbsMenuUpdateService.updateBrand.subscribe(brand => {
      if (brand !== null) {
        this.brandMenuItem.name = brand.name;
      }
    });

    this.breadcrumbsMenuUpdateService.updateProfile.subscribe(profile => {
      if (profile !== null) {
        this.profileMenuItem.name = profile.name;
      }
    });

    this.breadcrumbsMenuUpdateService.updateProduct.subscribe(product => {
      if (product !== null) {
        this.productMenuItem.name = product.name;
      }
    });

    this.breadcrumbsMenuUpdateService.updateCommonCustomer.subscribe(commonCustomer => {
      if (commonCustomer !== null) {
        this.commonCustomerMenuItem.name = commonCustomer.name;
      }
    });

    this.breadcrumbsMenuUpdateService.updateSosService.subscribe(sosService => {
      if (sosService !== null) {
        this.sosServiceMenuItem.name = sosService.name;
      }
    });

    this.breadcrumbsMenuUpdateService.updateMasterListService.subscribe(masterList => {
      if (masterList !== null) {
        this.masterListMenuItem.name = masterList.name;
      }
    });

    this.breadcrumbsMenuUpdateService.updateSupplierProfile.subscribe(supplierProfile => {
      if (supplierProfile !== null) {
        this.supplierProfileMenuItem.name = supplierProfile.supplierProfileName;
      }
    });
  }

  private handleNavigationStartEvent(event: NavigationStart) {
    const mainRoute = RoutesNameUtils.getMainRouteFromUrl(event.url);
    this.clearAllDigitalCaseflowMenuItems();
    if (mainRoute === RoutesName.customers) {
      this.showBreadcrumbs = true;
      this.clearAllSosServicesBreadcrumbItems();
      this.clearAllMasterListsBreadcrumbItems();
      this.clearAllSuppliersBreadcrumpItems();
      this.updateCustomersBreadcrumbs(event.url);
    } else if (mainRoute === RoutesName.dcf) {
      this.showBreadcrumbs = true;
      this.clearAllCustomersBreadcrumbItems();
      this.clearAllSosServicesBreadcrumbItems();
      this.clearAllMasterListsBreadcrumbItems();
      this.clearAllSuppliersBreadcrumpItems();
      this.updateDigitalCaseflowBreadcrumbs(event.url);
    } else if (mainRoute === RoutesName.sosServices) {
      this.showBreadcrumbs = true;
      this.clearAllCustomersBreadcrumbItems();
      this.clearAllMasterListsBreadcrumbItems();
      this.clearAllSuppliersBreadcrumpItems();
      this.updateSosServicesBreadcrumbs(event.url);
    } else if (mainRoute === RoutesName.masterLists) {
      this.showBreadcrumbs = true;
      this.clearAllSosServicesBreadcrumbItems();
      this.clearAllCustomersBreadcrumbItems();
      this.clearAllSuppliersBreadcrumpItems();
      this.updateMasterListBreadcrumbs(event.url);
    } else if (mainRoute === RoutesName.suppliers) {
      this.showBreadcrumbs = true;
      this.clearAllSosServicesBreadcrumbItems();
      this.clearAllCustomersBreadcrumbItems();
      this.clearAllMasterListsBreadcrumbItems();
      this.updateSupplierListBreadcrumbs(event.url);
    } else {
      this.showBreadcrumbs = false;
    }
  }
  updateCustomersBreadcrumbs(url: string) {
    this.customersMenuItem = new MenuItem('', 'All');
    let id = this.getIdFromUrl(url);
    const childRoute: RoutesName = this.getChildRouteFromUrl(url);
    if (childRoute === null) {
      this.clearCustomersChildrenBreadcrumbItems();
      return;
    }
    if (childRoute === RoutesName.commonCustomers) {
      this.createCommonCustomerMenuItem(id);
      this.brandMenuItem = null;
      this.profileMenuItem = null;
      this.productMenuItem = null;
    } else if (childRoute === RoutesName.brand) {
      this.profileMenuItem = null;
      this.productMenuItem = null;
      this.createBrandMenuItem(id);
    } else if (childRoute === RoutesName.profile) {
      this.productMenuItem = null;
      this.createProfileMenuItem(id);
    } else if (childRoute === RoutesName.product) {
      this.createProductMenuItem(id);
    }
  }
  updateDigitalCaseflowBreadcrumbs(url: string) {
    this.digitalCaseflowMenuItem = new MenuItem('', 'All');
    const id = this.getIdFromUrl(url);
    if (id !== null) {
      this.digitalCaseflowService.getCustomerConfigurationById(id).subscribe(digitalCaseflow => {
        if (digitalCaseflow !== null) {
          this.digitalCaseflowMenuConfigurationItem = new MenuItem(digitalCaseflow.id+"",digitalCaseflow.flow.name);
        }
      })
    }
  }

  updateSosServicesBreadcrumbs(url: string) {
    this.sosServicesMenuItem = new MenuItem('', 'All');
    const sosServiceId = this.getIdFromUrl(url);
    if (sosServiceId !== null) {
      this.createSosServiceMenuItem(sosServiceId);
    } else {
      this.sosServiceMenuItem = null;
    }
  }

  updateMasterListBreadcrumbs(url: string) {
    const state = this.router.getCurrentNavigation().extras.state;

    this.masterListsMenuItem = new MenuItem('', 'All');
    const [masterListName, level1, level2] = url.split('/').slice(2);

    this.createMasterListMenuItem(masterListName);
    this.createMasterListCausesMenuItem(level1, level2, state);
  }

  updateSupplierListBreadcrumbs(url: string) {
    this.supplierProfilesMenuItem = new MenuItem('', 'All');
    const supplierProfileId = this.getIdFromUrl(url);
    if (supplierProfileId !== null) {
      this.createSupplierListMenuItem(supplierProfileId);
    } else {
      this.supplierProfileMenuItem = null;
    }
  }

  private clearAllCustomersBreadcrumbItems() {
    this.customersMenuItem = null;
    this.clearCustomersChildrenBreadcrumbItems();
  }

  private clearCustomersChildrenBreadcrumbItems() {
    this.commonCustomerMenuItem = null;
    this.brandMenuItem = null;
    this.profileMenuItem = null;
    this.productMenuItem = null;
    this.masterListMenuItem = null;
    this.supplierProfileMenuItem = null;
    this.digitalCaseflowMenuConfigurationItem = null;
    this.digitalCaseflowMenuItem = null;
  }

  private clearAllSosServicesBreadcrumbItems() {
    this.sosServicesMenuItem = null;
    this.sosServiceMenuItem = null;
  }

  private clearAllMasterListsBreadcrumbItems() {
    this.masterListsMenuItem = null;
    this.masterListMenuItem = null;
    this.masterListCausesLevel1MenuItem = null;
    this.masterListCausesLevel2MenuItem = null;
  }

  private clearAllSuppliersBreadcrumpItems() {
    this.supplierProfilesMenuItem = null;
    this.supplierProfileMenuItem = null;
  }
  private clearAllDigitalCaseflowMenuItems() {
    this.digitalCaseflowMenuItem = null;
    this.digitalCaseflowMenuConfigurationItem = null;
  }

  get RoutesName() {
    return RoutesName;
  }

  private createCommonCustomerMenuItem(id: number) {
    this.commonCustomerService.getCommonCustomer(id.toString()).subscribe(
      commonCustomer => {
        this.commonCustomerMenuItem = new MenuItem(id.toString(), commonCustomer.name);
      },
      error => console.log(error)
    );
  }

  private createBrandMenuItem(id: number) {
    this.brandService.getBrand(id.toString()).subscribe(
      brand => {
        this.brandMenuItem = new MenuItem(brand.id, brand.name);
        this.createCommonCustomerMenuItem(+brand.commonCustomerId);
      },
      error => console.log(error)
    );
  }

  private createProfileMenuItem(id: number) {
    this.profileService.getProfile(id.toString()).subscribe(
      profile => {
        this.profileMenuItem = new MenuItem(profile.id, profile.name);
        this.createBrandMenuItem(+profile.brandId);
      },
      error => console.log(error)
    );
  }

  private createProductMenuItem(id: number) {
    this.productService.getProduct(id.toString()).subscribe(
      product => {
        this.productMenuItem = new MenuItem(product.id.toString(), product.name);
        this.createProfileMenuItem(product.profileId);
      },
      error => console.log(error)
    );
  }

  private createSosServiceMenuItem(id: number) {
    this.sosServiceMenuItem = new MenuItem(id.toString(), '');
    this.sosServicesService.get(id.toString()).subscribe(
      sosServices => {
        this.sosServiceMenuItem = sosServices === null ? new MenuItem('', '') : new MenuItem(sosServices.id, sosServices.name);
      },
      error => console.log(error)
    );
  }

  private createMasterListMenuItem(masterListType: string) {
    this.masterListMenuItem = null;
    if (masterListType) {
      const masterListDisplayName = MASTER_LISTS.find(m => m.id === masterListType)?.name;
      this.masterListMenuItem = new MenuItem(masterListType, masterListDisplayName);
    }
  }

  private createSupplierListMenuItem(id: number) {
    this.supplierProfileMenuItem = new MenuItem(id.toString(), '');
    this.supplierProfileService.get(id.toString()).subscribe(
      supplierProfile => {
        this.supplierProfileMenuItem =
          supplierProfile === null
            ? new MenuItem('', '')
            : new MenuItem(supplierProfile.id.toString(), supplierProfile.supplierProfileName);
      },
      error => console.log(error)
    );
  }

  private createMasterListCausesMenuItem(level1: string, level2: string, state: any) {
    this.masterListCausesLevel1MenuItem = null;
    this.masterListCausesLevel2MenuItem = null;

    if (level1) {
      this.masterListCausesLevel1MenuItem = new MenuItem(level1, state && state.level1 ? state.level1 : level1);
    }

    if (level2) {
      this.masterListCausesLevel2MenuItem = new MenuItem(level2, state && state.level2 ? state.level2 : level2);
    }
  }

  private getChildRouteFromUrl(url: string): RoutesName {
    const route = url.split('/')[url.split('/').length - 2];
    if (route === '') {
      return null;
    }
    return route as RoutesName;
  }

  private getIdFromUrl(url: string): number {
    const id = url.split('/')[url.split('/').length - 1];
    if (!isNaN(+id)) {
      return +id;
    }
    return null;
  }
}
