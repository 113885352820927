<div class="common-customer-overview-container">
  <div class="common-customer-summary">
    <app-common-customer-summary [commonCustomer]="commonCustomer" [numberOfBrands]="brands.length">
    </app-common-customer-summary>
  </div>
  <div class="common-customer-brands">
    <app-common-customer-brands [commonCustomer]="commonCustomer" [numberOfBrands]="brands.length"
                                (refreshBrands)="refreshBrandsEvent($event)"
                                [brands]="brands">
    </app-common-customer-brands>
  </div>
</div>
