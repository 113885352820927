<ng-template #content let-modal>
  <div class="onepoint-modal-container">
    <div class="onepoint-modal-header">
      <div class="onepoint-modal-header-title">Create new customer flow</div>
      <div class="onepoint-modal-header-close"><img (click)="close()" src="../../../../../assets/icons/Close.svg" alt="Close"></div>
    </div>

    <div class="onepoint-modal-body">
        <app-input [name]="'Name'" [(value)]="name" (focusOut)="validate()"></app-input>
    </div>

    <div class="onepoint-modal-footer">
      <button class="primary-button" (click)="save()" [ngClass]="!updateNeeded && 'button-disabled'"
              [disabled]="!updateNeeded">Save
      </button>
    </div>
  </div>
</ng-template>
