import {ProductsFilteringSortingMenuTypeEnum} from './products-filtering-sorting-menu-type-enum';

import {Injectable} from '@angular/core';
import {Product} from '../../../../shared/models/product';
import {formatDate} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ProductsFilteringService {
  filteringStep: FilteringStep[] = [];
  constructor() {}

  public addStep(
    productsFilteringSortingMenuType: ProductsFilteringSortingMenuTypeEnum,
    selectedIds: string[],
    dateFrom: Date = null,
    dateTo: Date = null
  ) {
    this.filteringStep.push(
      new FilteringStep(productsFilteringSortingMenuType, selectedIds, dateFrom, dateTo)
    );
  }

  public removeStep(productsFilteringSortingMenuType: ProductsFilteringSortingMenuTypeEnum) {
    const itemToDelete = this.filteringStep.find(
      a => a.filteringSortingMenuType === productsFilteringSortingMenuType
    );
    if (itemToDelete) {
      this.filteringStep.splice(this.filteringStep.indexOf(itemToDelete));
    }
  }

  public filter(productsList: Product[]): Product[] {
    let tempProductsList: Set<Product>;

    this.filteringStep.forEach(step => {
      tempProductsList = new Set();
      switch (step.filteringSortingMenuType) {
        case ProductsFilteringSortingMenuTypeEnum.name:
          // tslint:disable-next-line:no-shadowed-variable
          step.selectedIds.forEach(element => {
            productsList
              .filter(a => a.name === element)
              .forEach(a => tempProductsList.add(a));
          });
          break;
        case ProductsFilteringSortingMenuTypeEnum.insuranceLevel:
          step.selectedIds.forEach(element => {
            productsList
              .filter(a => a.insuranceLevels.includes(element))
              .forEach(a => tempProductsList.add(a));
          });
          break;
        case ProductsFilteringSortingMenuTypeEnum.productVersion:
          step.selectedIds.forEach(element => {
            productsList
              .filter(a => a.version === element)
              .forEach(a => tempProductsList.add(a));
          });
          break;
        case ProductsFilteringSortingMenuTypeEnum.sosProductId:
          step.selectedIds.forEach(element => {
            productsList
              .filter(a => a.sosProductId === element)
              .forEach(a => tempProductsList.add(a));
          });
          break;
        case ProductsFilteringSortingMenuTypeEnum.customerProductId:
          step.selectedIds.forEach(element => {
            productsList
              .filter(a => a.customerProductIds.includes(element))
              .forEach(a => tempProductsList.add(a));
          });
          break;
        case ProductsFilteringSortingMenuTypeEnum.businessArea:
          step.selectedIds.forEach(element => {
            productsList
              .filter(a => a.businessArea === element)
              .forEach(a => tempProductsList.add(a));
          });
          break;
        case ProductsFilteringSortingMenuTypeEnum.debtor:
          step.selectedIds.forEach(element => {
            productsList
              .filter(a => element === (a.debtorOverruled ? 'Other' : 'Main'))
              .forEach(a => tempProductsList.add(a));
          });
          break;
        case ProductsFilteringSortingMenuTypeEnum.validFrom:
          step.selectedIds.forEach(element => {
            productsList
              .filter(a => formatDate(a.validFrom, 'dd MMM yyyy', 'en-en') === element)
              .forEach(a => tempProductsList.add(a));
          });
          break;
        case ProductsFilteringSortingMenuTypeEnum.validTo:
          step.selectedIds.forEach(element => {
            productsList
              .filter(a => formatDate(a.validTo, 'dd MMM yyyy', 'en-en') === element)
              .forEach(a => tempProductsList.add(a));
          });
          break;
        case ProductsFilteringSortingMenuTypeEnum.deactivationDate:
          step.selectedIds.forEach(element => {
            productsList
              .filter(a => formatDate(a.deactivationDate, 'dd MMM yyyy', 'en-en') === element)
              .forEach(a => tempProductsList.add(a));
          });
          break;
      }
      productsList = Array.from(tempProductsList);
    });
    return productsList;
  }
}

export class FilteringStep {
  filteringSortingMenuType: ProductsFilteringSortingMenuTypeEnum;
  selectedIds: string[];
  dateFrom: Date;
  dateTo: Date;
  constructor(
    filteringSortingMenuType: ProductsFilteringSortingMenuTypeEnum,
    selectedIds: string[],
    dateFrom: Date,
    dateTo: Date
  ) {
    this.filteringSortingMenuType = filteringSortingMenuType;
    this.selectedIds = selectedIds;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
  }
}
