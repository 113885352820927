<div class="upload-brand-icon-container">
  <div class="icon-container">
    <div class="icon">
      <img [src]="iconUrl" *ngIf="!iconBase64" />
      <img [src]="iconBase64" *ngIf="iconBase64" />
    </div>
    <div class="icon-remarks">
      <p class="" [class.upload-brand-icon-error-message]="showInvalidIconMsg">
        Logo must be a square, in PNG, GIF, or ICO format and file size less
        than 150kB
      </p>
      <p>Recommended dimensions are 200 x 200 pixels.</p>
      <div class="upload-button-container">
        <button class="primary-button-outline" (click)="fileInput.click()">
          {{ uploadBtnLabel }}
        </button>
      </div>
    </div>
  </div>
  <input
    #fileInput
    type="file"
    accept="image/png,image/gif,image/ico"
    (change)="changeListener($event)"
    style="display: none;"
  />
</div>
