import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-drop-down-list',
  templateUrl: './drop-down-list.component.html',
  styleUrls: ['./drop-down-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropDownListComponent<T> implements OnInit {
  @Input() name?: string = undefined;
  @Input() items: T[] | null;
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() searchFunction: (term: string, item: T) => boolean;


  // two-way binding for selectedItem
  private selectedItemValue: T;

  @Output() selectedItemChange = new EventEmitter<T>();
  get selectedItem(): T {
    return this.selectedItemValue;
  }

  @Input()
  set selectedItem(newItem: T) {
    if (this.selectedItemValue !== newItem) {
      this.selectedItemValue = newItem;
      this.selectedItemChange.emit(this.selectedItemValue);
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  public onSearch(term: string, item: any): boolean {
    const token = term.toLowerCase();
    const profile = item['profileName'].toLowerCase();
    console.log(this.bindLabel);
    console.log('Does ' + profile + ' start with ' + token + ' = ' + profile.startsWith(token));
    return profile.startsWith(token);
  }
}
