import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasterListAdTeamsModel } from '@onepoint/shared/models/masterlist-ad-teams-model';
import { MasterListDedicatedEmailModel } from '@onepoint/shared/models/masterlist-dedicated-email-model';
import { MasterListService } from 'app/core/services/masterlist.service';
import { OnepointModalService } from 'app/core/services/onepoint.modal.service';
import { PermissionService } from 'app/core/services/permission.service';
import { Dictionary } from 'app/shared/models/dictionary';
import { DictionaryWithType } from 'app/shared/models/dictionary-with-type';
import { MasterListServiceTypeModel } from 'app/shared/models/masterlist-service-type-model';
import { PermissionEnum } from 'app/shared/models/permission.enum';

@Component({
  selector: 'app-masterlist-dedicated-email-modal',
  templateUrl: './masterlist-dedicated-email-modal.component.html',
  styleUrls: ['./masterlist-dedicated-email-modal.component.scss'],
})
export class MasterlistDedicatedEmailModalComponent implements OnInit {
  @Input()
  masterListType: MasterListDedicatedEmailModel;

  @Output()
  refreshTable = new EventEmitter();

  serviceTypes: DictionaryWithType[];
  vatValues: Dictionary[] = [
    { id: 'true', name: 'Yes' },
    { id: 'false', name: 'No' },
  ];
  minDeactivationDate: Date;
  selectedServiceTypeId: string;
  selectedVatValue: string;
  error: string;
  canUpdate: boolean;
  isModelChanged: boolean;
  teams : MasterListAdTeamsModel[];
  
  constructor(
    private masterListService: MasterListService,
    private onepointModalService: OnepointModalService,
    private permissionService: PermissionService,
    
  ) {}

  ngOnInit(): void {
    this.minDeactivationDate = new Date(Date.now() - 86400000);
    this.permissionService.permissionsReadyNotification.subscribe(
      result => (this.canUpdate = this.permissionService.checkUserPermission(PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE))
    );
    if (this.masterListType.activateOn > this.minDeactivationDate) {
      this.minDeactivationDate = this.masterListType.activateOn;
    }
    this.masterListService.getAdTeams().subscribe(teams => {
      this.teams = teams.filter(team => team.departmentId !== 'OTHER');
    });
  }

  closeModal() {
    this.onepointModalService.dismissAll();
  }

  get isSaveButtonEnabled(): boolean {
    return (
      this.isModelChanged &&
      this.masterListType &&
      !!this.masterListType.activateOn &&
      !!this.masterListType.businessKey &&
      !!this.masterListType.sendTo
    );
  }

  save() {
    if ( this.isNew ) {
      this.masterListService.addDedicatedEmail(this.masterListType).subscribe(
        response => {
          this.masterListType = response;
          this.refreshTable.emit();
          this.closeModal();
        },
        err => this.error = err.error ? err.error.message : err.message
      )
    } else {
      this.masterListService.updateDedicatedEmail(this.masterListType).subscribe(
        response => {
          this.masterListType = response;
          this.refreshTable.emit();
          this.closeModal();
        },
        err => (this.error = err.error ? err.error.message : err.message)
      );
    }
  }

  activateOnChanged(item: MasterListServiceTypeModel) {
    this.modelChanged();
    item.activateOn.setHours(12, 0);
  }

  deactivateOnChanged(item: MasterListServiceTypeModel) {
    this.modelChanged();
    item.deactivateOn.setHours(12, 0);
  }

  modelChanged(): void {
    this.isModelChanged = true;
  }
  
  teamChanged(team) : void {
    this.masterListType.adTeam = team;
    this.modelChanged();
  }

  get isReadonly(): boolean {
    return !this.canUpdate;
  }

  get isNew(): boolean {
    return this.masterListType.id == null;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
