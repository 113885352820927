<div class="common-content-container-title">
  Common Customer Settings
</div>
<div class="common-content-container-body">
  <div class="input-name">Common Customer name<span class="required">*</span></div>
  <div class="common-customer-input">
    <app-input [(value)]="commonCustomer.name" (valueChange)="formChanged()"></app-input>
  </div>
  <div class="input-name">Responsible KAM<span class="required">*</span></div>
  <div class="common-customer-input">
    <app-kam-drop-down
      [(selectedKamId)]="commonCustomer.keyAccountManagerUserId"
      (selectedKamChange)="this.commonCustomer.updateKamInfo($event); formChanged()"
    >
    </app-kam-drop-down>
  </div>
  <div class="input-name">Group segment<span class="required">*</span></div>
  <div class="common-customer-input">
    <app-drop-down
      [items]="segments"
      [(selectedItemId)]="commonCustomer.groupSegment.id"
      (selectedItemIdChange)="formChanged()"
    ></app-drop-down>
  </div>
  <div class="input-name">Travel Segment<span class="required">*</span></div>
  <div class="common-customer-input">
    <app-drop-down
      [items]="segments"
      [(selectedItemId)]="commonCustomer.travelSegment.id"
      (selectedItemIdChange)="formChanged()"
    ></app-drop-down>
  </div>
  <div class="input-name">Roadside Segment<span class="required">*</span></div>
  <div class="common-customer-input">
    <app-drop-down
      [items]="segments"
      [(selectedItemId)]="commonCustomer.roadsideSegment.id"
      (selectedItemIdChange)="formChanged()"
    ></app-drop-down>
  </div>
  <div class="input-name">Health Segment<span class="required">*</span></div>
  <div class="common-customer-input">
    <app-drop-down
      [items]="segments"
      [(selectedItemId)]="commonCustomer.healthSegment.id"
      (selectedItemIdChange)="formChanged()"
    ></app-drop-down>
  </div>
  <div class="input-name">SOS owner<span class="required">*</span></div>
  <div class="common-customer-input">
    <app-drop-down
      [items]="sosOwnerOptions"
      [(selectedItemId)]="commonCustomer.sosOwner"
      (selectedItemIdChange)="formChanged()"
    ></app-drop-down>
  </div>
  <div class="input-name">Sub-business relation<span class="required">*</span></div>
  <div class="common-customer-input">
    <app-drop-down
      [items]="subBusinessRelations"
      [(selectedItemId)]="commonCustomer.subBusinessRelation.id"
      (selectedItemIdChange)="formChanged()"
    ></app-drop-down>
  </div>
  <div class="input-name">Internal remarks</div>
  <div class="common-customer-input">
    <textarea name="remarks" rows="4" [(ngModel)]="commonCustomer.remarks" (change)="formChanged()" [maxLength]="1000"></textarea>
  </div>
</div>
