import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MasterListService } from 'app/core/services/masterlist.service';
import { OnepointModalService, Size } from 'app/core/services/onepoint.modal.service';
import { MasterListServiceItemModel } from 'app/shared/models/masterlist-service-item-model';
import { ServiceItemMenuType } from './service-item-menu/masterlist-service-item-menu-type';
import * as _ from 'lodash';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import { Dictionary } from 'app/shared/models/dictionary';
import { SosServicesService } from 'app/core/services/sos-services.service';
import {MasterListServiceTypeModel} from "../../../shared/models/masterlist-service-type-model";

@Component({
  selector: 'app-masterlist-service-item',
  templateUrl: './masterlist-service-item.component.html',
  styleUrls: ['./masterlist-service-item.component.scss'],
})
export class MasterListServiceItemComponent implements OnInit {
  @ViewChild('MasterListModal')
  private masterListModalTemplate: TemplateRef<any>;

  masterList: MasterListServiceItemModel[];
  masterListFiltered: MasterListServiceItemModel[];
  masterListItem: MasterListServiceItemModel;
  showMenu = new Map<ServiceItemMenuType, boolean>();
  serviceTypesMap = new Map<string, string>();
  statusTypes: Dictionary[] = [
    { id: 'all', name: 'All' },
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
  ];
  selectedStatus = 'all';

  constructor(
    private masterListService: MasterListService,
    private onepointModalService: OnepointModalService,
    private sosServicesService: SosServicesService
  ) {}

  ngOnInit(): void {
    this.sosServicesService.getSosServicesTypeList().subscribe(response => {
      response.forEach(item => this.serviceTypesMap.set(item.type, item.name));
    });

    this.loadMasterList();
  }

  showFilteringSortingMenu(filteringSortingMenuType: ServiceItemMenuType) {
    this.showMenu.forEach((value, key) => {
      if (key !== filteringSortingMenuType) {
        this.showMenu.set(key, false);
      }
    });

    this.showMenu.set(filteringSortingMenuType, !this.showMenu.get(filteringSortingMenuType));
  }

  isShowMenu(filteringSortingMenuType: ServiceItemMenuType) {
    return this.showMenu.get(filteringSortingMenuType);
  }

  addNew(): void {
    this.masterListItem = new MasterListServiceItemModel();
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.LARGE, 'service-item-modal-container');
  }

  edit(item: MasterListServiceItemModel): void {
    this.masterListItem = _.clone(item);
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.LARGE, 'service-item-modal-container');
  }

  selectedStatusTypeChanged(status: string): void {
    this.masterListFiltered = this.filterByStatus(status);
  }

  reload(): void {
    this.loadMasterList();
  }

  getServiceTypesLabel(serviceTypes: MasterListServiceTypeModel[]) {
    const typeNames = serviceTypes.map(type => type.serviceTypeName);
    return typeNames.join(", ");
  }

  get ServiceItemMenuType() {
    return ServiceItemMenuType;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  private loadMasterList() {
    this.masterListService.getServiceItemList().subscribe(response => {
      this.masterList = response;
      this.masterListFiltered = this.filterByStatus(this.selectedStatus);
    });
  }

  private filterByStatus(status: string): MasterListServiceItemModel[] {
    if (status === 'all') {
      return this.masterList;
    }
    return this.masterList.filter(item => (status === 'active' ? item.active : !item.active));
  }
}
