<div class="common-content-container">
  <app-edit-common-customer [commonCustomer]="commonCustomer"
                            (changes)="handleChanges($event)"></app-edit-common-customer>
  <div class="save-button-container">
    <button class="primary-button edit-common-customer-button" (click)="onEditCommonCustomer()"
            [ngClass]="{'button-disabled': !changesMade}" [disabled]="!changesMade">Save changes</button>
  </div>
</div>

<div class="common-delete-container justify-content-between" *ngIf="commonCustomer">
  <div class="text">
    <div class="black-text">
      Delete Common Customer
    </div>
    <div class="gray-text" *ngIf="!commonCustomer.deletable">
        This Common Customer can not deleted,because it contains one or more Brand and Profiles.
        To delete this Common Customer, please delete all Brands and Profiles within it first.
    </div>
  </div>
  <button class="primary-button my-auto" (click)="deleteCommonCustomer()" [ngClass]="!commonCustomer.deletable && 'button-disabled'">Delete Common Customer</button>
</div>
