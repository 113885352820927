import { Injectable } from '@angular/core';
import { CoveragePeriodTypeEnum } from '../enums/coverage-period-type-enum';
import { InsuranceTypeEnum } from '../enums/insurance-type-enum';
import { CoveragePeriodUnitEnum } from '../enums/coverage-period-unit-enum';
import { BusinessAreaEnum } from './enums';
import { PolicyLookupMethodTypeEnum } from '@onepoint/shared/enums/policy/policy-lookup-method-type.enum';

export class Product {
  id: number;
  name: string;
  insuranceType: InsuranceTypeEnum;
  sosInsuranceLevel: string;
  insuranceLevels: string[];
  termsSelectedBy: string;
  sosProductId: string;
  customerProductIds = [''];
  version: string;
  businessArea: BusinessAreaEnum;
  percentagePaidByCard: number;
  personGroup: string;
  coveragePeriodType: CoveragePeriodTypeEnum;
  coveragePeriodUnit: CoveragePeriodUnitEnum;
  coveragePeriodValue: number;
  primaryPurposeOfTravelCode: string;
  secondaryPurposesOfTravelCodes: string[];
  coverageGeographicAreaIds: number[];
  parentId: number;
  profileId: number;
  termsName: string;
  termsDocumentName: string;
  termsDocumentId: string;
  guidelineName: string;
  guidelineUri: string;

  validFrom: Date;
  validTo: Date;
  deactivationDate: Date;
  debtorOverruled: boolean;
  copyCoverages: boolean;
  groupProfileProductId: string;
  groupProfileOnly: boolean;
  policyLookupMethodType: PolicyLookupMethodTypeEnum;
  policyLookupMethodCode: string;

  public static rootAncestorId(product: Product, products: Product[]): number {
    if (product.parentId == null) {
      return product.id;
    } else {
      const parent = products.filter(p => p.id === product.parentId)[0];
      return this.rootAncestorId(parent, products);
    }
  }

  public constructor(init?: Partial<Product>) {
    if (init != null) {
      Object.assign(this, init);
    }
  }

  public isActive() {
    const now = new Date();
    return this.deactivationDate > now || this.deactivationDate == null;
  }

  public isValid(products: Product[]): boolean {
    const uniqueViolations = this.getUniqueViolations(products);
    return this.mandatoryMet() && this.validFrom && uniqueViolations.length < 1;
  }

  public mandatoryMet(): boolean {
    return !!this.insuranceType
      && !!this.name
      && !!this.version
      && !!this.termsSelectedBy
      && !!this.sosProductId
      && !!this.customerProductIds && this.customerProductIds.length > 0
      && !!this.primaryPurposeOfTravelCode
      && !!this.coverageGeographicAreaIds
      && this.coverageGeographicAreaIds.length > 0
      && !!this.validFrom
      && (this.coveragePeriodType !== CoveragePeriodTypeEnum.annual || (!!this.coveragePeriodUnit && !!this.coveragePeriodValue))
      && (!(!!this.parentId && !this.sosInsuranceLevel))
      && !!this.personGroup
      && !!this.primaryPurposeOfTravelCode;
  }

  public getUniqueViolations(products: Product[]): Product[] {
    if (products != null && this.validFrom) {
      const rootId = Product.rootAncestorId(this, products);
      return this.getOverlappingProducts(products)
        .filter(product => !this.ifCurrentProduct(product))
        .filter(product => this.doesViolate(product, Product.rootAncestorId(product, products) === rootId));
    }
    return [];
  }

  private doesViolate(product: Product, family: boolean): boolean {
    return this.ifNamesSameAndNotFamily(product, family) || (this.ifProductIdsOverlap(product) && !family)
      || (this.ifProductIdsOverlap(product) && this.ifLevelsOverlap(product));
  }

  private ifCurrentProduct(product: Product): boolean {
    return product.id === this.id;
  }

  private ifProductIdsOverlap(product: Product): boolean {
    if (product.customerProductIds == null || this.customerProductIds == null) {
      return false;
    }
    const intersection = product.customerProductIds.filter(elem => this.customerProductIds.includes(elem));
    return intersection.length > 0;
  }

  private ifNamesSameAndNotFamily(product: Product, family: boolean): boolean {
    return product.name === this.name && !family;
  }

  private ifLevelsOverlap(product: Product): boolean {
    if (product.insuranceLevels == null || this.insuranceLevels == null) {
      return false;
    }
    const intersection = product.insuranceLevels.filter(elem => this.insuranceLevels.includes(elem));
    return intersection.length > 0;
  }

  private getOverlappingProducts(products: Product[]): Product[] {
    return products.filter(product => this.overlaps(product));
  }

  public overlaps(product: Product): boolean {
    const productValidFromBetween = this.validFrom <= product.validFrom
      && (this.validTo == null || this.validTo >= product.validFrom);
    const thisValidFromBetween = product.validFrom <= this.validFrom
      && (product.validTo == null || product.validTo >= this.validFrom);
    return this.profileId === product.profileId && (productValidFromBetween || thisValidFromBetween);
  }

  public productProfileIsMember(): boolean {
    return !!this.groupProfileProductId;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProductAdapter {
  adapt(item: any): Product {
    return new Product({
      id: item.id,
      name: item.name,
      insuranceType: item.insuranceType,
      sosInsuranceLevel: item.sosInsuranceLevel,
      insuranceLevels: item.insuranceLevels == null ? null : Object.assign([], item.insuranceLevels),
      termsSelectedBy: item.termsSelectedBy,
      sosProductId: item.sosProductId,
      customerProductIds: Object.assign([], item.customerProductIds),
      version: item.version,
      businessArea: BusinessAreaEnum[item.businessArea as keyof typeof BusinessAreaEnum],
      percentagePaidByCard: item.percentagePaidByCard,
      personGroup: item.personGroup,
      coveragePeriodType: item.coveragePeriodType,
      coveragePeriodUnit: item.coveragePeriodUnit,
      coveragePeriodValue: item.coveragePeriodValue,
      primaryPurposeOfTravelCode: item.primaryPurposeOfTravelCode,
      secondaryPurposesOfTravelCodes: item.secondaryPurposesOfTravelCodes == null ? null
        : Object.assign([], item.secondaryPurposesOfTravelCodes),
      coverageGeographicAreaIds: item.coverageGeographicAreaIds,
      parentId: item.parentId,
      profileId: item.profileId,
      termsName: item.termsName,
      termsDocumentName: item.termsDocumentName,
      termsDocumentId: item.termsDocumentId,
      validFrom: item.validFrom !== null ? new Date(item.validFrom) : null,
      validTo: item.validTo !== null ? new Date(item.validTo) : null,
      deactivationDate: item.deactivationDate !== null ? new Date(item.deactivationDate) : null,
      guidelineName: item.guidelineName,
      guidelineUri: item.guidelineUri,
      debtorOverruled: null,
      copyCoverages: false,
      groupProfileProductId: item.groupProfileProductId,
      groupProfileOnly: item.groupProfileOnly,
      policyLookupMethodType: item.policyLookupMethodType,
      policyLookupMethodCode: item.policyLookupMethodCode
    });
  }
}
