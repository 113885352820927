export class ApplicationSettings {
  bffEndpoint: string;
  keyCloakUlr: string;
  ssoDisabled: boolean;
  isProduction: boolean;
  isTest: boolean;
  productGuidelineLocation: string;
  constructor() {
    this.bffEndpoint = '';
  }
}
