import { Directive, ElementRef, OnInit, Input, Output, EventEmitter, Renderer2 } from '@angular/core';

@Directive({ selector: '[sortColumn]' })
export class SortDirective implements OnInit {
  @Input() data: any[];
  @Input('sortKey') key: any;
  @Output() finishedSorting: EventEmitter<any> = new EventEmitter();
  private toggleSort: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.listen(this.el.nativeElement, 'click', event => {
    let parentNode = this.el.nativeElement.parentNode;
    let children = parentNode.children;
    if (this.data && this.key) {
        let sortedData: any = this.sortArray();
        if (this.finishedSorting) {
            this.finishedSorting.emit();
        }
    }
    this.toggleSort = !this.toggleSort;
});
  }


  sortArray(): Array<any> {
    const tempArray: Array<any> = this.data;

    const orderingAscDesc: number = this.toggleSort ? 1 : -1;

    tempArray.sort((a, b) => {

      // First numeric and date
      if (!isNaN(a[this.key])) {
        return a[this.key] < b[this.key] ? orderingAscDesc : -orderingAscDesc;
      }

      // Then strings
      let str1: string = a[this.key] != null ? a[this.key].toString().toLowerCase() : "";
      let str2: string = b[this.key] != null ? b[this.key].toString().toLowerCase() : "";

      if (this.toggleSort) {
        return str1 < str2 ? -1 : 1;
      } else {
        return str2 < str1 ? -1 : 1;
      }
    });
    return tempArray;
  }
}
