export enum TemplateTypes {
    GOP = 'GOP',
    VOB = 'VOB',
    CONSENT = 'Consent',
    DIGITAL_CONSENT = 'Digital Consent',
    REFERRAL = 'Referral',
    AFY_BOOKING = 'AFY booking',
    CASE_INFO = 'Case info',
    SIGNATURE = 'Signature',
    COVER_EMAIL = 'Cover email',
    INSURANCE_VALIDATION = 'Insurance validation',
    LOGO_HEADER = 'Logo header',
    CLAIM_LETTER = 'Claim letter',
    APPLICATION = 'Application',
    PRE_ASSESSMENT = "Pre-assessment",
    RECOVERY_POA_REQUEST = "Recovery POA request",
    RECOVERY_APPLICATION = "Recovery Application"
  
}
