<ng-template #content let-modal>
  <div class="onepoint-modal-container">
    <div class="onepoint-modal-header">
      <div class="onepoint-modal-header-title" *ngIf="activity.id != null">Edit Activity</div>
      <div class="onepoint-modal-header-title" *ngIf="activity.id == null">Create Activity</div>
      <div class="onepoint-modal-header-close"><img (click)="close()" src="assets/icons/Close.svg" alt="Close"></div>
    </div>

    <div class="onepoint-modal-body p-3">
      <div class="top-space">
        <app-input [name]="'Name'" [(value)]="activity.name" (valueChange)="validate()"></app-input>
      </div>
      <div class="top-space">
        <div class="input-title-name">
          Type
        </div>
        <app-drop-down class="dropdown" [items]="allActivityTypes"
                       [(selectedItemId)]="activity.type"
                       [disabled]="activity.id != null"
                       (selectedItemIdChange)="typeChanged()"></app-drop-down>
      </div>
      <div class="top-space" *ngIf="shouldShow('MANDATORY')">
        <div class="input-title-name">
          Mandatory
        </div>
        <app-input-switch [id]="'mandatory'"
                          [value]="activity.input.mandatory"
                          (valueChange)="setIncluded($event.valueOf())">
        </app-input-switch>
      </div>
      <div class="top-space" *ngIf="shouldShow('LENGTH')">
        <app-input [name]="'Length'" [type]="'number'" [(value)]="activity.input.length"
                   (valueChange)="validate()"></app-input>
      </div>
      <div class="top-space" *ngIf="shouldShow('SERVICE_ORDER_CREATION_TYPE')">
        <div class="input-title-name">
          Service Order Creation Type
        </div>
        <app-drop-down class="dropdown" [items]="serviceOrderCreationTypes"
                       [(selectedItemId)]="activity.input.serviceOrderCreationType"
                       (selectedItemIdChange)="validate()"></app-drop-down>
      </div>
      <div class="top-space" *ngIf="shouldShow('STAKEHOLDER_TYPE')">
        <div class="input-title-name">
          Stakeholder Type
        </div>
        <app-drop-down class="dropdown" [items]="stakeholderTypes"
                       [(selectedItemId)]="activity.input.stakeholderType"
                       (selectedItemIdChange)="validate()"></app-drop-down>
      </div>
      <div class="top-space" *ngIf="shouldShow('TEMPLATE_NAME')">
        <app-input [name]="'Template name'" [(value)]="activity.input.templateName" (valueChange)="validate()"></app-input>
      </div>
      <div class="top-space" *ngIf="shouldShow('SERVICE_TYPE')">
        <div class="input-title-name">
          Service Order Type
        </div>
        <app-drop-down class="dropdown" [items]="serviceTypes"
                       [(selectedItemId)]="activity.input.serviceType"
                       (selectedItemIdChange)="serviceTypeChanged()"></app-drop-down>
      </div>
      <div class="top-space" *ngIf="shouldShow('COMMITMENT_TYPE')">
        <div class="input-title-name">
          Commitment Type
        </div>
        <app-drop-down class="dropdown" [items]="commitmentTypes"
                       [(selectedItemId)]="activity.input.commitmentType"
                       (selectedItemIdChange)="validate()"></app-drop-down>
      </div>
      <div class="top-space" *ngIf="shouldShow('HANDLING_AREA_TYPE')">
        <div class="input-title-name">
          Handling area type
        </div>
        <app-drop-down class="dropdown" [items]="handlingAreaTypes"
                       [(selectedItemId)]="activity.input.handlingAreaType"
                       (selectedItemIdChange)="validate()"></app-drop-down>
      </div>
      <div class="top-space" *ngIf="shouldShow('HANDLING_AREA_STATUS')">
        <div class="input-title-name">
          Handling area status
        </div>
        <app-drop-down class="dropdown" [items]="handlingAreaStatus"
                       [(selectedItemId)]="activity.input.handlingAreaStatus"
                       (selectedItemIdChange)="validate()"></app-drop-down>
      </div>
      <div class="top-space" *ngIf="shouldShow('SANCTION_CHECK_EXPORT_TYPE')">
        <div class="input-title-name">
          Sanction check type
        </div>
        <app-drop-down class="dropdown" [items]="sanctionCheckTypes"
                       [(selectedItemId)]="activity.input.sanctionCheckType"
                       (selectedItemIdChange)="validate()"></app-drop-down>
      </div>
      <div class="top-space" *ngIf="shouldShow('TASK_ASSIGNEE')">
        <div class="input-title-name">
          Assignee
        </div>
        <app-drop-down class="dropdown" [items]="taskAssigneeTypes"
                       [(selectedItemId)]="activity.input.taskAssigneeType"
                       (selectedItemIdChange)="taskAssigneeChanged()"></app-drop-down>
      </div>
      <div class="top-space" *ngIf="shouldShow('TASK_ASSIGNEE') && activity.input.taskAssigneeType === 'FIXED_TEAM'">
        <div class="input-title-name">
          Fixed team
        </div>
        <app-drop-down class="dropdown" [items]="adTeams"
                       [(selectedItemId)]="activity.input.taskFixedTeam"
                       (selectedItemIdChange)="validate()"></app-drop-down>
      </div>
      <div class="top-space" *ngIf="shouldShow('TASK_TITLE')">
        <app-input [name]="'Task title'" [(value)]="activity.input.taskTitle" (valueChange)="validate()" maxTextLength="1000"></app-input>
      </div>
      <div class="top-space" *ngIf="shouldShow('TASK_BODY_TEXT')">
        <div class="input-title-name">
          Body text
        </div>
        <textarea [(ngModel)]="activity.input.taskBodyText" rows="4" maxlength="8000" class="input-component input-textarea" (change)="validate()"></textarea>
      </div>
      <div class="top-space" *ngIf="shouldShow('TASK_DEFAULT_CHANNEL')">
        <div class="input-title-name">
          Default channel
        </div>
        <app-drop-down class="dropdown" [items]="channelTypes"
                       [(selectedItemId)]="activity.input.taskDefaultChannel"
                       (selectedItemIdChange)="validate()"></app-drop-down>

      </div>
      <div class="top-space" *ngIf="shouldShow('PURPOSE_OF_TRAVEL')">
        <div class="input-title-name">
          Purpose of travel
        </div>
        <app-drop-down class="dropdown" [items]="purposesOfTravel"
                       [(selectedItemId)]="activity.input.purposeOfTravel"
                       (selectedItemIdChange)="validate()"></app-drop-down>
      </div>
    </div>
    <div class="onepoint-modal-footer">
      <button class="delete-button" *ngIf="deletePossible"
              (click)="delete()">Delete activity
      </button>

      <button class="primary-button" (click)="save()" [ngClass]="!updateNeeded && 'button-disabled'"
              [disabled]="!updateNeeded">Save
      </button>
    </div>
  </div>
</ng-template>
