<div class="supplier-settings-container">
  <app-edit-supplier-profile #editSupplierComponent
                             [(supplierProfile)]="supplierProfile" 
                             [allErpCreditors]="allErpCreditors"
                             (supplierProfileChanged)="handleChange(editSupplierComponent)">
  </app-edit-supplier-profile>
  <button class="primary-button" (click)="onEdit()" [ngClass]="{ 'button-disabled': !canSave }" [disabled]="!canSave">Save changes</button>
  <div *ngIf="!!editSupplierComponent?.validationError" class="validation-error">
    <span>{{editSupplierComponent.validationError}}</span>
  </div>
</div>
