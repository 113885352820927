export enum AdTeamsMenuType {
  ADT_CODE = 'adTCode',
  TEAM_ID = 'teamId',
  TEAM_NAME = 'teamName',
  TEAM_NAME_ALT = 'teamNameAlt',
  CISCO_TEAM_ID = 'ciscoTeamId',
  TEAM_TYPE = 'teamType',
  SUB_TEAM_IDS = 'subTeamIds',
  DEPARTMENT_ID = 'departmentId',
  DEPARTMENT_NAME = 'departmentName',
  DEFAULT_LANGUAGE = 'defaultLanguage',
  UI_SORT_INDEX = 'uiSortIndex',
  ACTIVATE_ON = 'activateOn',
  DEACTIVATION_ON = 'deactivateOn'
}
