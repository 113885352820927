import { Component, OnInit, TemplateRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { CoverageService } from '../../../../../../core/services/coverage-service';
import { DictionaryService } from '../../../../../../core/services/dictionary.service';
import { Limit } from '../../../../../../shared/models/limit';
import { Coverage } from '../../../../../../shared/models/coverage';
import { Dictionary } from 'app/shared/models/dictionary';
import { LimitTypeEnum, CoverageTypeEnum, LimitDurationUnitEnum, } from '../../../../../../shared/models/enums';
import { OnepointModalService, Size } from '../../../../../../core/services/onepoint.modal.service';

@Component({
  selector: 'app-coverage-limits-modal',
  templateUrl: './coverage-limits-modal.component.html',
  styleUrls: ['./coverage-limits-modal.component.scss']
})
export class CoverageLimitsModalComponent implements OnInit {
  @Output() refreshCoverages = new EventEmitter<Coverage>();
  @ViewChild('content')

  private template: TemplateRef<any>;

  constructor(private modalService: OnepointModalService,
              private coverageService: CoverageService,
              private dictionaryService: DictionaryService) {
    this.limitUnitsTypesDictionary = this.dictionaryService.getCoverageLimitType();
    this.limitUnitsPersonsDictionary = this.dictionaryService.getPersonLimitType();
    this.limitUnitsTreatmentsDictionary = this.dictionaryService.getTreatmentsLimitType();
    this.coverageTypeDictionary = this.dictionaryService.getCoverageType();
    this.limitFromTypeDictionary = this.dictionaryService.getLimitFromType();
  }

  limits: Limit[];
  newLimit: Limit;
  coverage: Coverage;
  limitUnitsTypesDictionary: Dictionary[];
  limitUnitsCurrenciesDictionary: Dictionary[];
  limitUnitsPersonsDictionary: Dictionary[];
  limitUnitsTreatmentsDictionary: Dictionary[];
  limitFromTypeDictionary: Dictionary[];
  coverageTypeDictionary: Dictionary[];
  coverageValidatedBeforeSave = false;
  limitValidatedBeforeSave = false;
  coverageNameValidatedBeforeSave = true;
  listOfCoverageNames: string[] = [];

  ngOnInit() {
    this.dictionaryService.getBillingCurrencies().subscribe(
      result => (this.limitUnitsCurrenciesDictionary = result),
      error => console.error(error)
    );
    this.disabledSaveButton();
  }

  public open(coverage: Coverage, listOfCoverageNames: string[]) {
    this.resetModal(listOfCoverageNames);
    this.coverage = JSON.parse(JSON.stringify(coverage));
    this.limits = (this.coverage.limits != null && this.coverage.limits.length) ? this.coverage.limits : [new Limit()];
    this.modalService.open(this.template, Size.NORMAL);
  }

  close() {
    this.modalService.dismissAll();
  }

  public createNewCoverageAndOpen(productId: number, listOfCoverageNames: string[]) {
    this.resetModal(listOfCoverageNames);
    const coverage: Coverage = this.createNewCoverage(productId);
    this.open(coverage, listOfCoverageNames);
  }

  private resetModal(listOfCoverageNames: string[]) {
    this.coverageValidatedBeforeSave = false;
    this.limitValidatedBeforeSave = false;
    this.listOfCoverageNames = listOfCoverageNames;
  }

  saveCoverage() {
    if (this.limits.length && !this.limits[0].limitType) {
      this.coverage.limits = null;
    } else {
      this.coverage.limits = this.limits;
    }
    if (this.isEdit) {
      this.coverageService.updateCoverage(this.coverage).subscribe(
        result => {
          this.modalService.dismissAll();
          this.refreshCoverages.emit(result);
        },
        error => console.error(error)
      );
    } else {
      this.coverageService.createCoverage(this.coverage).subscribe(
        result => {
          this.modalService.dismissAll();
          this.refreshCoverages.emit(result);
        },
        error => console.error(error)
      );
    }
  }

  get isEdit() {
    return this.coverage.id !== null;
  }

  get getLimitUnitsTypesEnum() {
    return LimitTypeEnum;
  }

  get LimitDurationUnits() {
    return LimitDurationUnitEnum;
  }

  deleteLimit(index) {
    this.limits.splice(index, 1);
    this.validateCoverageAndLimitsValueBeforeSave();
  }

  createNewCoverage(productId: number): Coverage {
    const newCoverage = new Coverage();
    newCoverage.id = null;
    newCoverage.coverageTermsRef = '';
    newCoverage.coverageName = '';
    newCoverage.coverageType = CoverageTypeEnum.BASIC;
    newCoverage.productId = productId;
    newCoverage.limits = [];
    newCoverage.limits.push(this.createNewLimit());
    return newCoverage;
  }

  addNewLimit() {
    this.limits.push(this.createNewLimit());
    this.validateCoverageAndLimitsValueBeforeSave();
  }

  createNewLimit() {
    this.newLimit = new Limit();
    this.newLimit.id = null;
    this.newLimit.limitType = null;
    this.newLimit.coverageID = null;
    this.newLimit.limitUnit = '';
    this.newLimit.limitValue = '';
    return this.newLimit;
  }

  validateCoverageAndLimitsValueBeforeSave() {
    this.coverageValidatedBeforeSave = this.coverage.coverageTermsRef !== '' && this.coverage.coverageName !== '';
    this.coverageNameValidatedBeforeSave = this.listOfCoverageNames.includes(this.coverage.coverageName);
    this.limitValidatedBeforeSave = true;
    if (this.limits.length > 1) {
      this.limitValidatedBeforeSave = this.limits[0].limitType != null;
    }
    for (const limit of this.limits.slice(1)) {
      let result;
      if (limit.limitType === LimitTypeEnum.DURATION) {
        result = limit.limitUnit !== '' && limit.limitValue !== '';
      } else {
        result = limit.limitUnit !== '';
      }
      if (!result) {
        this.limitValidatedBeforeSave = false;
        break;
      }
    }
  }

  disabledSaveButton() {
    let returnValue: boolean;
    returnValue = !(this.coverageValidatedBeforeSave &&
      this.limitValidatedBeforeSave &&
      !this.coverageNameValidatedBeforeSave);
    return returnValue;
  }
}
