import { PolicyLookupMethodTypeEnum } from '../../../../enums/policy/policy-lookup-method-type.enum';
import { PolicyLookupMethodExtension } from './policy-lookup-method-extension';
import { Injectable } from '@angular/core';
import { RegisterServerPolicyLookupMethodExtension } from './method/register-server-policy-lookup-method-extension';

export class PolicyLookupMethod {
  id: number;
  productId: number;
  type: PolicyLookupMethodTypeEnum = undefined;
  extension: PolicyLookupMethodExtension;

  public constructor(init?: Partial<PolicyLookupMethod>) {
    Object.assign(this, init);
  }

  public isValid(): boolean {
    return this.type !== undefined && this.extension && this.extension.isValid();
  }
}

@Injectable({
  providedIn: 'root'
})
export class PolicyLookupMethodAdapter {
  adapt(item: any): PolicyLookupMethod {
    if (item === null) {
      return null;
    }
    return new PolicyLookupMethod({
      id: item.id,
      productId: item.productId,
      type: this.getType(item.type),
      extension: this.adaptExtension(this.getType(item.type), item.extension)
    });
  }

  private getType(type: any): PolicyLookupMethodTypeEnum {
    return PolicyLookupMethodTypeEnum[type as keyof typeof PolicyLookupMethodTypeEnum];
  }

  private adaptExtension(type: PolicyLookupMethodTypeEnum, extension: any): PolicyLookupMethodExtension {
    if ([PolicyLookupMethodTypeEnum.REGISTER_SERVER, PolicyLookupMethodTypeEnum.OTHER_INTEGRATION, PolicyLookupMethodTypeEnum.NO_INTEGRATION].includes(type)) {
      return new RegisterServerPolicyLookupMethodExtension(extension);
    }
  }
}
