import { Directive, Input, ElementRef, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from 'app/core/services/permission.service';
import { PermissionEnum, PermissionHideTypeEnum } from '../models/permission.enum';

@Directive({
  selector: '[permission]',
})
export class PermissionDirective implements OnInit {
  @Input() permissionHideType: PermissionHideTypeEnum = PermissionHideTypeEnum.HIDE;
  @Input() permission: PermissionEnum = PermissionEnum.NONE;
  @Input() permissionForTestingOnly: boolean;
  embeddedView;

  private el: any;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private elementRef: ElementRef,
    private permissionService: PermissionService
  ) {

    this.initailizeEmbeddedView();
  }

  ngOnInit(): void {
    this.el = this.elementRef.nativeElement.previousElementSibling;

    if (this.permission !== PermissionEnum.NONE && this.el != null) {
      console.log('permissionHide', this.permissionHideType);
      this.el.classList.add('permission-hide');
    }
    this.permissionService.permissionsReadyNotification.subscribe((result) => {
      if (result && this.permission !== PermissionEnum.NONE) {
        if (!this.permissionService.checkUserPermission(this.permission)) {
          if (this.isPermissionSetOnNgTemplate()) {
            this.viewContainer.remove();
          } else {
            console.log('this.permissionHideType', this.permissionHideType);
            switch (this.permissionHideType) {
              case PermissionHideTypeEnum.DISABLE:
                this.el.classList.remove('permission-hide');
                this.el.classList.remove('permission-remove');
                this.el.classList.add('permission-disable');
                // To avoid getting to the disabled element by tab key the focus is moved to the next sibling element
                this.el.insertAdjacentHTML('afterend', "<span tabindex='0' style='height:0px;width:0px;' onfocus='this.blur()'/>");
                this.el.setAttribute('onfocus', 'this.nextSibling.focus({preventScroll:true})');
                this.el.setAttribute('tabindex', '0');
                break;
              case PermissionHideTypeEnum.HIDE:
                this.el.classList.remove('permission-remove');
                this.el.classList.add('permission-hide');
                break;
              case PermissionHideTypeEnum.REMOVE:
                this.el.classList.remove('permission-hide');
                this.el.classList.add('permission-remove');
                break;
              default:
                break;
            }
          }
        } else {
          if (this.el != null) {
            this.el.classList.remove('permission-hide');
          }
        }
      }
    });
  }

  initailizeEmbeddedView() {
    this.embeddedView = this.viewContainer.createEmbeddedView(this.templateRef);
  }

  isPermissionSetOnNgTemplate(): boolean {
    return this.el == null;
  }
}
