<div class="page-content-container">
  <table class="primary-table suppliers-table">
    <thead>
      <tr>
        <td class="filter-column" (click)="showFilteringSortingMenu(SupplierMenuType.supplierProfileCode)">
          <div [ngStyle]="!showMenu[SupplierMenuType.supplierProfileCode] && { display: 'none' }">
            <app-supplier-filtering-sorting-menu
              [(supplierProfileListView)]="filteredSupplierListView"
              (modelChanged)="finishedAndFilteringSorting()"
              (clearAllFilters)="clearAllFiltering()"
              [filteringSortingMenuType]="SupplierMenuType.supplierProfileCode"
            ></app-supplier-filtering-sorting-menu>
          </div>
          Code
        </td>
        <td class="filter-column" (click)="showFilteringSortingMenu(SupplierMenuType.supplierProfileName)">
          <div [ngStyle]="!showMenu[SupplierMenuType.supplierProfileName] && { display: 'none' }">
            <app-supplier-filtering-sorting-menu
              [(supplierProfileListView)]="filteredSupplierListView"
              (modelChanged)="finishedAndFilteringSorting()"
              (clearAllFilters)="clearAllFiltering()"
              [filteringSortingMenuType]="SupplierMenuType.supplierProfileName"
            ></app-supplier-filtering-sorting-menu>
          </div>
          Supplier profile name
        </td>
        <td class="filter-column" (click)="showFilteringSortingMenu(SupplierMenuType.providerId)">
          <div [ngStyle]="!showMenu[SupplierMenuType.providerId] && { display: 'none' }">
            <app-supplier-filtering-sorting-menu
              [(supplierProfileListView)]="filteredSupplierListView"
              (modelChanged)="finishedAndFilteringSorting()"
              (clearAllFilters)="clearAllFiltering()"
              [filteringSortingMenuType]="SupplierMenuType.providerId"
            ></app-supplier-filtering-sorting-menu>
          </div>
          Provider ID
        </td>
        <td class="filter-column" (click)="showFilteringSortingMenu(SupplierMenuType.creditorNumber)">
          <div [ngStyle]="!showMenu[SupplierMenuType.creditorNumber] && { display: 'none' }">
            <app-supplier-filtering-sorting-menu
              [(supplierProfileListView)]="filteredSupplierListView"
              (modelChanged)="finishedAndFilteringSorting()"
              (clearAllFilters)="clearAllFiltering()"
              [filteringSortingMenuType]="SupplierMenuType.creditorNumber"
            ></app-supplier-filtering-sorting-menu>
          </div>
          Creditor number
        </td>
        <td class="filter-column" (click)="showFilteringSortingMenu(SupplierMenuType.creditorName)">
          <div [ngStyle]="!showMenu[SupplierMenuType.creditorName] && { display: 'none' }">
            <app-supplier-filtering-sorting-menu
              [(supplierProfileListView)]="filteredSupplierListView"
              (modelChanged)="finishedAndFilteringSorting()"
              (clearAllFilters)="clearAllFiltering()"
              [filteringSortingMenuType]="SupplierMenuType.creditorName"
            ></app-supplier-filtering-sorting-menu>
          </div>
          Creditor name
        </td>
        <td class="filter-column" (click)="showFilteringSortingMenu(SupplierMenuType.creditorCurrency)">
          <div [ngStyle]="!showMenu[SupplierMenuType.creditorCurrency] && { display: 'none' }">
            <app-supplier-filtering-sorting-menu
              [(supplierProfileListView)]="filteredSupplierListView"
              (modelChanged)="finishedAndFilteringSorting()"
              (clearAllFilters)="clearAllFiltering()"
              [filteringSortingMenuType]="SupplierMenuType.creditorCurrency"
            ></app-supplier-filtering-sorting-menu>
          </div>
          Creditor currency
        </td>
        <td class="filter-column" (click)="showFilteringSortingMenu(SupplierMenuType.networkManagerId)">
          <div [ngStyle]="!showMenu[SupplierMenuType.networkManagerId] && { display: 'none' }">
            <app-supplier-filtering-sorting-menu
              [(supplierProfileListView)]="filteredSupplierListView"
              (modelChanged)="finishedAndFilteringSorting()"
              (clearAllFilters)="clearAllFiltering()"
              [filteringSortingMenuType]="SupplierMenuType.networkManagerId"
            ></app-supplier-filtering-sorting-menu>
          </div>
          Network manager
        </td>
        <td class="filter-column" (click)="showFilteringSortingMenu(SupplierMenuType.startDate)">
          <div [ngStyle]="!showMenu[SupplierMenuType.startDate] && { display: 'none' }">
            <app-supplier-filtering-sorting-menu
              [(supplierProfileListView)]="filteredSupplierListView"
              (modelChanged)="finishedAndFilteringSorting()"
              (clearAllFilters)="clearAllFiltering()"
              [filteringSortingMenuType]="SupplierMenuType.startDate"
            ></app-supplier-filtering-sorting-menu>
          </div>
          Start date
        </td>
        <td class="filter-column" (click)="showFilteringSortingMenu(SupplierMenuType.endDate)">
          <div [ngStyle]="!showMenu[SupplierMenuType.endDate] && { display: 'none' }">
            <app-supplier-filtering-sorting-menu
              [(supplierProfileListView)]="filteredSupplierListView"
              (modelChanged)="finishedAndFilteringSorting()"
              (clearAllFilters)="clearAllFiltering()"
              [filteringSortingMenuType]="SupplierMenuType.endDate"
            ></app-supplier-filtering-sorting-menu>
          </div>
          End date
        </td>
        <td class="filter-column" (click)="showFilteringSortingMenu(SupplierMenuType.type)">
          <div [ngStyle]="!showMenu[SupplierMenuType.type] && { display: 'none' }">
            <app-supplier-filtering-sorting-menu
              [(supplierProfileListView)]="filteredSupplierListView"
              (modelChanged)="finishedAndFilteringSorting()"
              (clearAllFilters)="clearAllFiltering()"
              [filteringSortingMenuType]="SupplierMenuType.type"
            ></app-supplier-filtering-sorting-menu>
          </div>
          Type
        </td>
        <td class="filter-column" (click)="showFilteringSortingMenu(SupplierMenuType.partner)">
          <div [ngStyle]="!showMenu[SupplierMenuType.partner] && { display: 'none' }">
            <app-supplier-filtering-sorting-menu
              [(supplierProfileListView)]="filteredSupplierListView"
              (modelChanged)="finishedAndFilteringSorting()"
              (clearAllFilters)="clearAllFiltering()"
              [filteringSortingMenuType]="SupplierMenuType.partner"
            ></app-supplier-filtering-sorting-menu>
          </div>
          Current partner
        </td>
      </tr>
    </thead>
    <tbody>
      <ng-template ngFor let-supplier [ngForOf]="filteredSupplierListView">
        <tr (click)="navigateSupplierProfile(supplier)">
          <td>
            <div class="first-column-container">
              <div class="first-column-content">{{ supplier.supplierProfileCode }}</div>
            </div>
          </td>
          <td>{{ supplier.supplierProfileName }}</td>
          <td>{{ supplier.providerId }}</td>
          <td>{{ supplier.creditorNumber }}</td>
          <td>{{ supplier.creditorName }}</td>
          <td>{{ supplier.creditorCurrency }}</td>
          <td>
            {{
              (supplier.networkManagerFirstname ? supplier.networkManagerFirstname + ' ' : '') +
                (supplier.networkManagerSurname ? supplier.networkManagerSurname : '')
            }}
          </td>
          <td>{{ supplier.startDate | date: 'dd MMM yyyy' }}</td>
          <td>{{ supplier.endDate | date: 'dd MMM yyyy' }}</td>
          <td>{{ supplier.typeName }}</td>
          <td>{{ getPartnerName(supplier) }}</td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>
