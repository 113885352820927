import {Warning} from "./warning";

export class ServerWarningModalConfiguration {
  header: string;
  warning: Warning;

  constructor(init?: Partial<ServerWarningModalConfiguration>) {
    Object.assign(this, init);
  }
}
