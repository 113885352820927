import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasterListService } from 'app/core/services/masterlist.service';
import { OnepointModalService } from 'app/core/services/onepoint.modal.service';
import { PermissionService } from 'app/core/services/permission.service';
import { SosServicesService } from 'app/core/services/sos-services.service';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import { MasterlistDiagnosisModel } from '@onepoint/shared/models/masterlist-diagnosis-model';
import { MasterlistLanguages } from '@onepoint/master-lists/masterlist-languages';

@Component({
  selector: 'app-masterlist-diagnosis-modal',
  templateUrl: './masterlist-diagnosis-modal.component.html',
  styleUrls: ['./masterlist-diagnosis-modal.component.scss'],
})
export class MasterlistDiagnosisModalComponent implements OnInit {
  @Input()
  masterListType: MasterlistDiagnosisModel;

  @Output()
  refreshTable = new EventEmitter();

  diagnosis: any[];

  minDeactivationDate: Date;
  selectedDiagnosis: any;
  error: string;
  canUpdate: boolean;
  isModelChanged: boolean;
  languages = MasterlistLanguages;

  constructor(
    private masterListService: MasterListService,
    private onepointModalService: OnepointModalService,
    private sosServicesService: SosServicesService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.minDeactivationDate = new Date(Date.now() - 86400000);
    this.permissionService.permissionsReadyNotification.subscribe(
      result => (this.canUpdate = this.permissionService.checkUserPermission(PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE))
    );
    if (this.masterListType.activateOn > this.minDeactivationDate) {
      this.minDeactivationDate = this.masterListType.activateOn;
    }
    this.loadIcdList();
  }

  loadIcdList() {
    this.masterListService.getIcd10CodeList().subscribe(icdList => {
      this.diagnosis = icdList;
    });
  }
  closeModal() {
    this.onepointModalService.dismissAll();
  }

  get isSaveButtonEnabled(): boolean {
    return (
      this.isModelChanged &&
      this.masterListType &&
      !!this.masterListType.activateOn &&
      !!this.masterListType.businessKey &&
      !!this.masterListType.name &&
      !!this.masterListType.icd10Code
    );
  }

  save() {

    this.masterListType.icd10Name = this.diagnosis.find(x => x.icd10Code === this.masterListType.icd10Code).shortName;
    if ( this.isNew ) {
      this.masterListService.addDiagnosis(this.masterListType).subscribe(
        response => {
          this.masterListType = response;
          this.refreshTable.emit();
          this.closeModal();
        },
        err => (this.error = err.error ? err.error.message : err.message)
      );
    } else {
      this.masterListService.updateDiagnosis(this.masterListType).subscribe(
        response => {
          this.masterListType = response;
          this.refreshTable.emit();
          this.closeModal();
        },
        err => (this.error = err.error ? err.error.message : err.message)
      );
    }
  }

  activateOnChanged(item: MasterlistDiagnosisModel) {
    this.modelChanged();
    item.activateOn.setHours(12, 0);
  }

  deactivateOnChanged(item: MasterlistDiagnosisModel) {
    this.modelChanged();
    item.deactivateOn.setHours(12, 0);
  }

  modelChanged(): void {
    this.isModelChanged = true;
  }

  get isReadonly(): boolean {
    return !this.canUpdate;
  }

  get isNew(): boolean {
    return this.masterListType.id == null;
  }

  keepOrder = (a, b) => {
    return a;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
