import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomerListView } from 'app/shared/models/customer-list-view';
import { CustomersSummaryUpdate } from './customers-summary-update-data';
import { CustomerTypeEnum } from 'app/shared/enums/customerType.enum';
import { CustomersTableRow } from '../customers-table/customers-table-row';


@Injectable({
  providedIn: 'root'
})
export class CustomersSummaryUpdateService {
  private customerList = new BehaviorSubject(null);
  currentCustomerList = this.customerList.asObservable();

  change(list: CustomersTableRow[]) {
    const data: CustomersSummaryUpdate = new CustomersSummaryUpdate();
    list.filter(c => c.active).forEach(obj => {
      if (obj.type === CustomerTypeEnum.commonCustomer) {
        data.totalNumberOfCommonCustomers++;
      }
      if (obj.type === CustomerTypeEnum.brand) {
        data.totalNumberOfBrands++;
      }
      if (obj.type === CustomerTypeEnum.profile) {
        data.totalNumberOfProfiles++;
      }
    });
    this.customerList.next(data);
  }

}
