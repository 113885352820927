<ng-template #content let-modal>
  <div class="onepoint-modal-container">
    <div class="onepoint-modal-header">
      <div class="onepoint-modal-header-title">Edit Guide</div>
      <div class="onepoint-modal-header-close">
        <img (click)="close()" src="assets/icons/Close.svg" alt="Close"/>
      </div>
    </div>
    <div class="onepoint-modal-body">
      <div class="input-title-name">File path<span class="star">*</span></div>
      <div class="filepath-input">
        <app-input type="text" id="filepath" [(value)]="filePath" (valueChange)="validatePath()"></app-input>
      </div>
      <span *ngIf="!validPath" class="filepath-info">File path must point to <em>{{extension}}</em> file</span>
      <div class="input-title-name">Guide name<span class="star">*</span></div>
      <div class="terms-name-input">
        <app-input [(value)]="name" (valueChange)="evaluateAbleToSave()"></app-input>
      </div>
      <div class="onepoint-modal-footer">
        <button
          class="primary-button save-btn"
          (click)="save()"
          [ngClass]="!ableToSave && 'button-disabled'"
          [disabled]="!ableToSave"
        >
          Save
        </button>
        <button
          class="preview-btn primary-button"
          (click)="openFile()"
          [ngClass]="!validPath && 'button-disabled'"
          [disabled]="!validPath"
        >
          Preview guide
        </button>
      </div>
    </div>
  </div>
</ng-template>
