import { Adapter } from './../interfaces/adapter';
import { Injectable } from '@angular/core';
export class CustomerProfile {
  commonCustomerName: string;
  brandName: string;
  brandId: number;
  profileName: string;
  profileId: number;
  profileCode: string;
  teamName: string;

  public constructor(init?: Partial<CustomerProfile>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CustomerProfileAdapter implements Adapter<CustomerProfile> {
  adapt(item: any): CustomerProfile {
    return new CustomerProfile({
      commonCustomerName: item.commonCustomerName,
      brandName: item.brandName,
      brandId: item.brandId,
      profileName: item.profileName,
      profileId: item.profileId,
      profileCode: item.profileCode,
      teamName: item.teamName,
    });
  }
}
