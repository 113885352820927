<div class="menu-container" [ngStyle]="{ left: isLast() ? '-150px' : '0px' }" (click)="modalWindowClick($event)">
  <div class="sorting-ascending" (click)="sortAscending()">
    <div class="two-row-letters">
      <div>A</div>
      <div class="two-row-letters-second-row">Z</div>
    </div>
    <div class="sort-arrow"><img src="assets/icons/Down.svg" /></div>
    <div class="sort-type">Ascending</div>
  </div>
  <div class="small-separator"></div>
  <div class="sorting-descending" (click)="sortDescending()">
    <div class="two-row-letters">
      <div>Z</div>
      <div class="two-row-letters-second-row">A</div>
    </div>
    <div class="sort-arrow"><img src="assets/icons/Up.svg" /></div>
    <div class="sort-type">Descending</div>
  </div>
  <div *ngIf="showFilter">
    <hr />
    <div class="item-container">
      <ng-template ngFor let-item [ngForOf]="items">
        <div class="checkbox-item">
          <input type="checkbox" name="checkbox" [(ngModel)]="item.check" [id]="item.id" value="item.id" class="checkbox-input" />
          <label [for]="item.id" class="checkbox-label">{{ item.name }}</label>
        </div>
      </ng-template>
    </div>
    <div *ngIf="dateFilter" class="dates-container">
      <div>From:</div>

      <input
        class="dates-container-input"
        placeholder="dd-mm-yyyy"
        name="dp"
        [(ngModel)]="fromDate"
        ngbDatepicker
        #fromDateControl="ngbDatepicker"
      />
      <img class="dates-container-image" (click)="fromDateControl.toggle()" src="/assets/icons/calendar.png" />
      <div class="small-separator"></div>
      <div>To:</div>
      <input
        class="dates-container-input"
        placeholder="dd-mm-yyyy"
        name="dp"
        [(ngModel)]="toDate"
        ngbDatepicker
        #toDateControl="ngbDatepicker"
      /><img class="dates-container-image" (click)="toDateControl.toggle()" src="/assets/icons/calendar.png" />
    </div>
    <hr />
    <button class="button-apply" [ngClass]="{ 'button-apply-active': hasUserCheckedAnything }" (click)="applyButtonClick()">Apply</button>
    <button class="button-clear-all" [ngClass]="{ 'button-clear-all-active': hasUserCheckedAnything }" (click)="clearAllButtonClick()">
      Clear all
    </button>
  </div>
</div>
