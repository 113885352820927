import { Injectable } from '@angular/core';
import { Adapter } from './../interfaces/adapter';
import { MasterListAdTeamsModel } from './masterlist-ad-teams-model';

export class Dictionary {
  id: string;
  name: string;

  public constructor(id: string, name: string) {
    this.id = id;
    this.name = name;

  }
}

export class GenericDictionary {
  value: any;
  name: string;

  public constructor(value: any, name: string) {
    this.value = value;
    this.name = name;

  }
}

export class AdTeamsDictionary {
  id: string;
  name: string;

  public constructor(id: string, name: string) {
    this.id = id;
    this.name = name;

  }
}


@Injectable({
  providedIn: 'root'
})
export class DropdownAdapter implements Adapter<Dictionary> {
  adapt(item: string): Dictionary {
    return new Dictionary(item, item);
  }
}

@Injectable({
  providedIn: 'root'
})
export class TeamDropdownAdapter  {
  adapt(item: any): AdTeamsDictionary {
    return new AdTeamsDictionary(item, item.teamName);
  }
}

@Injectable({
  providedIn: 'root'
})
export class TeamAdtCodeDropdownAdapter  {
  adapt(item: any): GenericDictionary {
    return new GenericDictionary(item, this.label(item.teamName, item.adTCode) );
  }
  label(teamName: string, adtCode: string): string {
    return teamName + ' - ' + adtCode;
  } 
}


@Injectable({
  providedIn: 'root'
})
export class PurposeOfTravelDropdownAdapter implements Adapter<Dictionary> {
  adapt(item: any): Dictionary {
    return new Dictionary(item.purposeOfTravelCode, item.purposeOfTravelName);
  }
}
