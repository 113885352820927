
<div class="page-container">
  <div class="page-header">
      <div class="my-auto counts-container">
        <div>Master lists</div>
      </div>
      <div class="status-container my-auto">        
        <app-drop-down [(selectedItemId)]="selectedMasterlist"
                       [items]="masterLists"
                       (selectedItemIdChange)="selectedMasterlistChanged($event)">
        </app-drop-down>
      </div>  
  </div>
  <div class="primary-table page-content-container">
    <table class="master-lists-table">
      <thead>
        <tr>
          <td (click)="showFilteringSortingMenu(MasterlistApplicationsMenuType.APPLICATIONS_BUSINESS_CODE)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Business key</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[MasterlistApplicationsMenuType.APPLICATIONS_BUSINESS_CODE] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="MasterlistApplicationsMenuType.APPLICATIONS_BUSINESS_CODE"
                [masterList]="masterListApplication"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td (click)="showFilteringSortingMenu(MasterlistApplicationsMenuType.APPLICATIONS_BUSINESS_CODE_NAME)" class="filter-column unsortable-column">
            <div class="sortable">
              <span>Business key name</span>
              <span class="icon"></span>
            </div>
            <div [ngStyle]="!showMenu[MasterlistApplicationsMenuType.APPLICATIONS_BUSINESS_CODE_NAME] && { display: 'none' }">
              <app-masterlist-sorting-menu
                [sortingKey]="MasterlistApplicationsMenuType.APPLICATIONS_BUSINESS_CODE_NAME"
                [masterList]="masterListApplication"
              ></app-masterlist-sorting-menu>
            </div>
          </td>
          <td *ngFor="let applicationType of applicationTypes" class="unsortable-column">
            <div class="checkbox-column">
              <div>
                <app-checkbox [id]="'selectall-' + applicationType.type" [(value)]="allSelected[applicationType.type]" 
                              (valueChange)="selectAllCheckboxValueChanged(applicationType.type, $event)"
                              [disabled]="!(masterListApplication && masterListApplication.length > 0) || isReadonly"></app-checkbox>
              </div>
              <div class="name-heading">{{ applicationType.name }}</div>
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of masterListApplicationFiltered">
          <td>
            <div class="first-column-container">
              <div class="first-column-content">
                {{ item.businessCode }}
              </div>
            </div>
          </td>
          <td>
            {{ item.businessCodeName }}
          </td>
          <td *ngFor="let applicationType of applicationTypes">
            <div class="checkbox-column">
              <div>
                <app-checkbox [id]="'select-' + item.id + '-' + applicationType.type" [(value)]="selectedApplications[item.id][applicationType.type]" (valueChange)="checkboxValueChanged(applicationType.type, $event)" [disabled]="isReadonly"></app-checkbox>
              </div>
            </div>
          </td>
        </tr>        
      </tbody>
    </table>
    <div *ngIf="selectedMasterlist" class="button-row" >
      <button class="primary-button" (click)="updateApplications()" [ngClass]="{ 'button-disabled': !isSaveButtonEnabled }" [disabled]="!isSaveButtonEnabled">
        <img *ngIf="saveInProgress" src="./assets/icons/waitForSearchresult.gif" alt="Save changes">
        <span *ngIf="!saveInProgress">Save changes</span>
      </button>
    </div>  
  </div>
</div>
