import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DualListboxItem } from './dual-listbox.item';


@Component({
  selector: 'app-dual-listbox',
  templateUrl: './dual-listbox.component.html',
  styleUrls: ['./dual-listbox.component.scss']
})
export class DualListboxComponent {

  @Input() set leftItems(items: Array<DualListboxItem>) {
    this.left = items;
    this.leftFiltered = this.left;
    this.selectedAllLeft = false;
    this.executeSearch();
  }
  @Output()
  leftItemsChange = new EventEmitter<Array<DualListboxItem>>();

  @Input() set rightItems(items: Array<DualListboxItem>) {
    this.right = items;
  }
  @Output()
  rightItemsChange = new EventEmitter<Array<DualListboxItem>>();

  right: Array<DualListboxItem> = [];
  left: Array<DualListboxItem> = [];
  leftFiltered: Array<DualListboxItem> = [];
  searchQueryValue = '';
  selectedAllLeft = false;

  @Input() height = '200px';
  @Input() leftLabel = 'Left';
  @Input() rightLabel = 'Right';
  @Input()
  set searchQuery(query: string) {
    this.searchQueryValue = query;
    this.executeSearch();
  }

  private executeSearch() {
    if (!this.searchQueryValue) {
      this.leftFiltered = this.left;
      return;
    }
    this.leftFiltered = this.left.filter(item => item.name.toLowerCase().indexOf(this.searchQueryValue.toLowerCase()) !== -1);
  }

  get leftColumnLabel() {
    return `${this.leftLabel} (${this.left.length})`;
  }
  get rightColumnLabel() {
    return `${this.rightLabel} (${this.right.length})`;
  }

  get addButtonDisabled() {
    return this.leftFiltered.filter(item => item.selected).length === 0;
  }

  get removeButtonDisabled() {
    return this.right.filter(item => item.selected).length === 0;
  }

  add() {
    const added = this.removeFromLeft();
    this.right = this.right.concat(added);
    this.rightItemsChange.emit(this.right);
    this.leftItemsChange.emit(this.left);
  }

  private removeFromLeft(): Array<DualListboxItem> {
    const toBeAdded = this.leftFiltered.filter(item => item.selected);
    this.left = this.left.filter(item => !toBeAdded.includes(item));
    this.leftFiltered = this.leftFiltered.filter(item => !item.selected);
    toBeAdded.forEach(item => item.selected = false);
    return toBeAdded;
  }

  remove() {
    const removed = this.removeFromRight();
    this.left = this.left.concat(removed);
    this.rightItemsChange.emit(this.right);
    this.leftItemsChange.emit(this.left);
  }

  private removeFromRight(): Array<DualListboxItem> {
    const toBeRemoved = this.right.filter(item => item.selected);
    this.right = this.right.filter(item => !item.selected);
    toBeRemoved.forEach(item => item.selected = false);
    return toBeRemoved;
  }

  selectAllLeft(selectAll: boolean) {
    this.selectedAllLeft = selectAll;
    this.leftFiltered.forEach(item => item.selected = false);
    this.leftFiltered.forEach(item => item.selected = selectAll);
  }

  selectAllRight(selectAll: boolean) {
    this.right.forEach(item => item.selected = selectAll);
  }
}
