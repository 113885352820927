import { DocumentTemplate } from './../../shared/models/templates/document.template';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class TemplatesListUpdateService {
    private filteredTemplateList = new BehaviorSubject(null);
    currentTemplateList = this.filteredTemplateList.asObservable();

    change(newData: DocumentTemplate[]) {
        this.filteredTemplateList.next(newData);
    }
}
