import {Observable} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {Injector, NgZone} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {ServerWarningModalConfiguration} from "../models/server-warning-modal-configuration";
import {ServerWarningModalComponent} from "../components/modal/warning-server-modal/server-warning-modal.component";

export class ServerWarningHandlerHelper {

  private serverWarningModal: NgbModal;
  private ngbModalRef: NgbModalRef;
  private modalConfiguration: ServerWarningModalConfiguration;
  private isWarningModalOpen = false;

  constructor(errorMessages$: Observable<HttpErrorResponse>,
              private injector: Injector,
              private zone: NgZone) {
    errorMessages$.subscribe(error => {
      if (!this.getWarningErrorModel()) {
        return;
      }

      this.zone.runGuarded(() => {
        //check if modal is opened already
        if (error instanceof HttpErrorResponse && error.status === 403) {
          return;
        }

        if ( !this.isWarningModalOpen ) {
          this.openServerWarningModal();
          this.modalConfiguration = new ServerWarningModalConfiguration();
          this.modalConfiguration.header = 'Warning';
          this.modalConfiguration.warning = JSON.parse(error.error.message);

          this.ngbModalRef.componentInstance.configuration = this.modalConfiguration;

          this.ngbModalRef.componentInstance.closed.subscribe(
            () => {
              this.ngbModalRef.close();
            }
          );

        }
      });
    });
  }

  getWarningErrorModel(): NgbModal {
    if (!this.serverWarningModal) {
     this.serverWarningModal = this.injector.get(NgbModal);
    }
    return this.serverWarningModal;
  }

  private openServerWarningModal(): void {
    this.ngbModalRef = this.serverWarningModal.open(ServerWarningModalComponent, {
      windowClass: 'top-most-window-class',
    });
    this.isWarningModalOpen = true;

    this.ngbModalRef.result.then(
      () => {
        this.isWarningModalOpen = false;
      },
      () => {
        this.isWarningModalOpen = false;
    });
  }
}
