<div class="page-container">
  <div class="page-header">
    <div class="my-auto counts-container">
      <div class="count-container">
        <span>Diagnoses</span> <span>{{ masterListFiltered?.length }}</span>
      </div>
    </div>
    <div class="status-container my-auto">
      <app-drop-down [(selectedItemId)]="selectedStatus" [items]="statusTypes" (selectedItemIdChange)="selectedStatusTypeChanged($event)">
      </app-drop-down>
    </div>
    <div class="button-container my-auto">
      <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_CREATE">
        <button class="primary-button" (click)="addNew()">
          Add Diagnosis
        </button>
      </div>
    </div>
  </div>

  <div class="primary-table page-content-container">
    <table class="master-lists-table">
      <thead>
        <tr>
          <td class="filter-column unsortable-column">
            <app-sort-column [list]="masterListFiltered" [sortingKey]="DiagnosisMenuType.BUSINESS_KEY" [menuGroup]="menuGroup">Business key</app-sort-column>
          </td>
          <td class="filter-column unsortable-column">
            <app-sort-column [list]="masterListFiltered" [sortingKey]="DiagnosisMenuType.NAME" [menuGroup]="menuGroup">Name</app-sort-column>
          </td>
          <td class="filter-column unsortable-column">
            <app-sort-column [list]="masterListFiltered" [sortingKey]="DiagnosisMenuType.ICD10_CODE" [menuGroup]="menuGroup">ICD10 Code & Name</app-sort-column>
          </td>
          <td class="filter-column unsortable-column">
            <app-sort-column [list]="masterListFiltered" [sortingKey]="DiagnosisMenuType.UI_SORT_INDEX" [menuGroup]="menuGroup">Sort in UI</app-sort-column>
          </td>
          <td *ngFor="let lang of languages | keyvalue: keepOrder" class="language-header filter-column unsortable-column">
            <app-sort-column [list]="masterListFiltered" [sortingKey]="lang" [sortingFunction]="translationSort(lang.value)" [menuGroup]="menuGroup">{{lang.key}}</app-sort-column>
          </td>
          <td class="filter-column unsortable-column">
            <app-sort-column [list]="masterListFiltered" [sortingKey]="DiagnosisMenuType.ACTIVATE_ON" [menuGroup]="menuGroup">Activation date</app-sort-column>
          </td>
          <td class="filter-column unsortable-column">
            <app-sort-column [list]="masterListFiltered" [sortingKey]="DiagnosisMenuType.DEACTIVATION_ON" [menuGroup]="menuGroup">Deactivation date</app-sort-column>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of masterListFiltered" (click)="edit(item)">
          <td>
            <div class="first-column-container">
              <div class="first-column-content">
                {{ item.businessKey }}
              </div>
            </div>
          </td>
          <td>
            {{ item.name }}
          </td>
          <td>
            {{ item.icd10Name }}
          </td>
          <td>
            {{ item.uiSortIndex }}
          </td>
          <td *ngFor="let lang of languages | keyvalue: keepOrder">
            <div class="language-item">
              <img *ngIf="hasDuplicateTranslation(item, lang.value)" class="warning" src="assets/icons/Warning.svg" container="body"
                  [ngbPopover]="'Same as English translation'" triggers="hover" placement="top">
              <img class="ms-1" src="assets/icons/success.svg" alt="exists" *ngIf="!!item.translationsAvailable[lang.value]">
              <div class='ms-1 no-col-on' *ngIf="!item.translationsAvailable[lang.value]"></div>
            </div>
          </td>
          <td>
            {{ item.activateOn | date: 'dd MMM yyyy' }}
          </td>
          <td>
            {{ item.deactivateOn | date: 'dd MMM yyyy' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-template #MasterListModal>
    <app-masterlist-diagnosis-modal [masterListType]="masterListType" (refreshTable)="reload()"> </app-masterlist-diagnosis-modal>
  </ng-template>
</div>
