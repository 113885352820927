import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';


@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  @Output() focusOut = new EventEmitter();
  @Input() placeholder: string;
  @Input() name: string;
  @Input() withImage: boolean;
  @Input() recommended: boolean = false;
  @Input() value: string;
  @Input() required: boolean;
  @Input() withLink: boolean;
  @Input() maxTextLength: number = 100000;
  @Input() pattern = '.*';
  @Input() type = 'text';
  @Input() disabled: boolean;
  @Input() trim = false;

  constructor() {
    if (this.placeholder === undefined) {
      this.placeholder = '';
    }
  }
  ngOnInit() {}

  onChangeValue() {
    this.valueChange.emit(this.value);
  }

  onFocusOut() {
    this.focusOut.emit(this.value);
  }
}
