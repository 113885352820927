import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MasterListService } from "app/core/services/masterlist.service";
import { OnepointModalService, Size } from "app/core/services/onepoint.modal.service";
import { MasterListServiceItemModel } from "app/shared/models/masterlist-service-item-model";
import * as _ from "lodash";
import { PermissionEnum } from "app/shared/models/permission.enum";
import { Dictionary } from "app/shared/models/dictionary";
import { MasterListCausesLevel1Model, MasterListCausesLevel2Model } from "app/shared/models/masterlist-cause-model";
import { CausesLevel2MenuType } from "../causes-menu/masterlist-causes-menu-type";
import { ActivatedRoute, Router } from "@angular/router";
import { AutoUnsubscribe } from "app/shared/decorators/auto-unsubscribe";
import { Subscription } from "rxjs";

@Component({
    selector: 'app-masterlist-causes-level2',
    templateUrl: './masterlist-causes-level2.component.html',
    styleUrls: ['./masterlist-causes-level2.component.scss'],
})
@AutoUnsubscribe
export class MasterListCausesLevel2Component implements OnInit {
  @ViewChild('MasterListModal')
  private masterListModalTemplate: TemplateRef<any>;

  level1Item: MasterListCausesLevel1Model; 

  masterList: MasterListCausesLevel2Model[];
  masterListFiltered: MasterListCausesLevel2Model[];
  masterListItem: MasterListCausesLevel2Model;
  showMenu = new Map<CausesLevel2MenuType, boolean>();
  statusTypes: Dictionary[] = [{id:'all', name:'All'}, {id:'active', name:'Active'}, {id:'inactive', name:'Inactive'}];
  selectedStatus = 'all';
  level1Code: string;
  subscr: Subscription;

  constructor(private masterListService: MasterListService,
              private onepointModalService: OnepointModalService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.subscr = this.activatedRoute.params.subscribe(params => {
      this.level1Code = params.level1Code;
    });

    this.loadMasterList();
  }
  
  showFilteringSortingMenu(filteringSortingMenuType: CausesLevel2MenuType) {
    this.showMenu.forEach((value, key) => {
      if (key !== filteringSortingMenuType) {
        this.showMenu.set(key, false);
      }
    });

    this.showMenu.set(filteringSortingMenuType, !this.showMenu.get(filteringSortingMenuType));
  }

  isShowMenu(filteringSortingMenuType: CausesLevel2MenuType) {
    return this.showMenu.get(filteringSortingMenuType);
  }

  addNew(): void {    
    this.masterListItem = new MasterListCausesLevel2Model({businessArea: 'Travel'});
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.NORMAL, 'causes-modal-container');
  }

  edit(item: MasterListServiceItemModel): void {
    this.masterListItem = _.clone(item);
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.NORMAL, 'causes-modal-container');
  }

  showLevel3(level2Item: MasterListCausesLevel2Model) {
    this.router.navigate([level2Item.level2Code], {state: {level1: this.level1Item.level1, level2: level2Item.level2} , relativeTo: this.activatedRoute});
  }

  selectedStatusTypeChanged(status: string): void {
    this.masterListFiltered = this.filterByStatus(status);
  }

  reload(): void {
    this.loadMasterList();
  }

  get CausesMenuType() {
    return CausesLevel2MenuType;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  private loadMasterList() {
    this.masterListService.getCausesLevel1().subscribe(
      response => {
        this.level1Item = response.find(level1 => level1.level1Code === this.level1Code);
        this.masterList = this.level1Item.level2Causes;
        this.masterListFiltered = this.filterByStatus(this.selectedStatus);
      }
    )
  }

  private filterByStatus(status: string): MasterListCausesLevel2Model[] {
    if ( status === 'all' ) {
      return this.masterList;
    }

    return this.masterList.filter(item => status === 'active' ? item.active : !item.active);
  }
}
