<div (click)='toggleMenu()' class='sortable'>
  <span><ng-content></ng-content></span>
  <span class="icon"></span>
</div>

<div class="menu-container" [class.d-none]="!isShowMenu" >
  <div class="sorting-ascending" (click)="sort(true)">
    <div class="two-row-letters">
      <div>A</div>
      <div class="two-row-letters-second-row">Z</div>
    </div>
    <div class="sort-arrow"><img src='../../../../assets/icons/Down.svg' /></div>
    <div class="sort-type">Ascending</div>
  </div>
  <div class="small-separator"></div>
  <div class="sorting-descending" (click)="sort(false)">
    <div class="two-row-letters">
      <div>Z</div>
      <div class="two-row-letters-second-row">A</div>
    </div>
    <div class="sort-arrow"><img src='../../../../assets/icons/Up.svg' /></div>
    <div class="sort-type">Descending</div>
  </div>
</div>
