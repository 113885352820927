import { BusinessAreaEnum } from '../enums';

export class BusinessArea {
  displayName = '';
  segment: {id: string, name: string};
  type: BusinessAreaEnum;
  active = false;

  public constructor(init?: Partial<BusinessArea>) {
    Object.assign(this, init);
  }
}
