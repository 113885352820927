export enum ContactRoleMenuType {
  BUSINESS_KEY = 'businessKey',
  DESCRIPTION = 'description',
  UI_SORT = 'uiSort',
  ACTIVATE_ON = 'activateOn',
  DEACTIVATION_ON = 'deactivateOn',
  CONTACT_ROLE_NAME = 'contactRoleName',
  AUTO_IMPORT_FROM_SEARCH = 'autoImportFromSearch',
  EXCLUDE_FROM_MEDICAL_ASSESSMENT = 'excludeFromMedicalAssessment',
  EXCLUDE_FROM_SERVICE_ORDER = 'excludeFromServiceOrder',
}
