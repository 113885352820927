import {Injectable} from '@angular/core';
import {Adapter} from './../interfaces/adapter';
import {SlaAgreement, SlaAgreementAdapter} from "@onepoint/shared/models/sla-agreement";

export class SlaAgreementCategory {
  id: number;
  name: string;
  enabled: boolean;
  slaAgreements: SlaAgreement[]

  public constructor(init?: Partial<SlaAgreementCategory>) {
    Object.assign(this, init);
  }
}


@Injectable({
  providedIn: 'root'
})
export class SlaAgreementCategoryAdapter implements Adapter<SlaAgreementCategory> {
  adapt(item: any): SlaAgreementCategory {
    const slaAgreementAdapter = new SlaAgreementAdapter();
    return new SlaAgreementCategory({
      id: item.id,
      name: item.name,
      enabled: item.enabled,
      slaAgreements: item.slaAgreements.map(slaAgreementAdapter.adapt)
    });
  }
}
