import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DictionaryWithType } from '@onepoint/shared/models/dictionary-with-type';
import { MasterListService } from '@onepoint/core/services/masterlist.service';
import { OnepointModalService } from '@onepoint/core/services/onepoint.modal.service';
import { PermissionService } from '@onepoint/core/services/permission.service';
import { MasterListApprovalPermissionLimitsModel } from '@onepoint/shared/models/masterlist-approval-limits-model';
import { PermissionEnum } from '@onepoint/shared/models/permission.enum';

@Component({
  selector: 'app-approval-limit-modal',
  templateUrl: './approval-limit-modal.component.html',
  styleUrls: ['./approval-limit-modal.component.scss']
})
export class ApprovalLimitModalComponent implements OnInit {

  @Input()
  masterListItem: MasterListApprovalPermissionLimitsModel;

  @Output()
  refreshTable = new EventEmitter();

  serviceTypes: DictionaryWithType[];
  minDeactivationDate: Date;
  error: string;
  canUpdate: boolean;
  isModelChanged: boolean;

  constructor(
    private masterListService: MasterListService,
    private onepointModalService: OnepointModalService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.permissionService.permissionsReadyNotification.subscribe(
      result => (this.canUpdate = this.permissionService.checkUserPermission(PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE))
    );
    if (this.masterListItem.activateOn) {
      this.minDeactivationDate = this.masterListItem.activateOn;
    }
  }

  closeModal() {
    this.onepointModalService.dismissAll();
  }

  get isSaveButtonEnabled(): boolean {
    return (
      this.isModelChanged &&
      this.masterListItem &&
      !!this.masterListItem.activateOn &&
      !!this.masterListItem.limitInDKK
    );
  }

  save() {
    this.masterListService.updateApprovalPermissionLimit(this.masterListItem).subscribe(
      response => {
        this.masterListItem = response;
        this.refreshTable.emit();
        this.closeModal();
      },
      err => (this.error = err.error ? err.error.message : err.message)
    );
  }

  activateOnChanged(item: MasterListApprovalPermissionLimitsModel) {
    this.modelChanged();
    item.activateOn.setHours(12, 0);
    if (this.masterListItem.activateOn) {
      this.minDeactivationDate = this.masterListItem.activateOn;
    }
  }

  deactivateOnChanged(item: MasterListApprovalPermissionLimitsModel) {
    this.modelChanged();
    item.deactivateOn.setHours(12, 0);
  }

  modelChanged(): void {
    this.isModelChanged = true;
  }

  get isReadonly(): boolean {
    return !this.canUpdate;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }
}
