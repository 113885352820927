import { BusinessAreaEnum, CaseInformationEnum, InvoicingEnum } from 'app/shared/models/enums';
import { CountryService } from 'app/core/services/country.service';
import { ProfileService } from 'app/core/services/profile.service';
import { Component, Input, OnInit } from '@angular/core';
import { CustomerListView } from 'app/shared/models/customer-list-view';
import { Router } from '@angular/router';
import { CustomersService } from 'app/core/services/customers.service';
import { UntypedFormControl } from '@angular/forms';
import { Dictionary } from 'app/shared/models/dictionary';
import { Kam } from 'app/shared/models/kam';
import { Profile, ProfileAdapter } from 'app/shared/models/profile';
import { AutoCompleteTypeEnum } from 'app/shared/components/drop-down-with-auto-complete-countries/auto-complete-type-enum';
import { RoutesName } from 'app/app-routes-name';
import { UpdateService } from 'app/core/services/update.service';
import { ProductService } from '../../../../core/services/product.service';
import { Product } from '../../../../shared/models/product';
import { BreadcrumbsMenuUpdateService } from 'app/shared/components/breadcrumbs-menu/breadcrumbs-menu-update-service';
import { DictionaryService } from 'app/core/services/dictionary.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss'],
})
export class ProfileSettingsComponent implements OnInit {
  @Input() customerListViewOriginal: CustomerListView;
  showFavicon = false;
  kams: Kam[];
  profile: Profile = new Profile();
  profileOld: Profile = new Profile();
  isProfileValid: boolean;
  isCodeValid: boolean;
  isGroupMemberIdForMappingValid: boolean;
  profileCodeViolations: string[];
  groupMemberIdForMappingViolations: string[];
  changedCode = new Subject<string>();
  changedGroupMemberIdForMapping = new Subject<string>();
  isApplied: boolean;
  profileProducts: Product[];
  profileKAM: Dictionary[];
  marketList: Dictionary[];

  countryName: string;
  public controls: UntypedFormControl;

  constructor(
    private customersService: CustomersService,
    private profileService: ProfileService,
    private productService: ProductService,
    private router: Router,
    private countryService: CountryService,
    private updateService: UpdateService,
    private breadcrumbsMenuUpdateService: BreadcrumbsMenuUpdateService,
    private profileAdapter: ProfileAdapter
  ) {}

  ngOnInit() {
    this.marketList = [
      { id: 'Denmark', name: 'Denmark' },
      { id: 'Sweden', name: 'Sweden' },
    ];

    this.profileService.getProfile(this.customerListViewOriginal.id).subscribe(result => {
      this.profile = result;
      this.profileOld = this.profileAdapter.adapt(this.profile);

      this.isCodeValid = true;
      this.isGroupMemberIdForMappingValid = true;

      this.countryService.searchCountries(this.profile.market).subscribe(
        result => {
          const foundCountry = result.find(x => x.code === this.profile.market);
          if (foundCountry) {
            this.countryName = foundCountry.countryName;
          } else {
            this.countryName = '';
          }
        },
        error => console.log(error)
      );

      this.profileService.getProfileActiveContracts(this.profile.id).subscribe(
        result => (result.length > 0 ? (this.isApplied = true) : (this.isApplied = false)),
        error => console.error(error)
      );
      this.productService.getProfileProducts(this.profile.id).subscribe(
        result => (this.profileProducts = result),
        error => console.log(error)
      );

      this.setupChangeHandlers();
      this.formChanged();
    })
  }

  get AutoCompleteTypeEnum() {
    return AutoCompleteTypeEnum;
  }

  get BusinessAreaEnum() {
    return BusinessAreaEnum;
  }

  showHideFavicon() {
    this.showFavicon = !this.showFavicon;
  }

  nameChanges(name: string) {
    this.profile.name = name;
    this.formChanged();
  }

  onCodeChange(code: string) {
    this.changedCode.next(code);
    this.profileCodeViolations = [];
  }

  onGroupMemberIdForMappingChange(memberId: string) {
    this.changedGroupMemberIdForMapping.next(memberId);
    this.groupMemberIdForMappingViolations = [];
  }

  private setupChangeHandlers() {
    this.changedCode.pipe(debounceTime(500)).subscribe(value => this.handleCodeChange(value));
    this.changedGroupMemberIdForMapping.pipe(debounceTime(500)).subscribe(value => this.handleGroupMemberIdForMappingChange(value));
  }

  private handleCodeChange(code: string) {
    this.profile.code = code;
    if (this.profileOld.code !== this.profile.code) {
      this.validateCode(this.profile.code);
    } else {
      this.isCodeValid = true;
      this.formChanged();
    }
  }

  private handleGroupMemberIdForMappingChange(memberId: string) {
    this.profile.groupMemberIdForMapping = memberId;
    if (this.profileOld.groupMemberIdForMapping !== this.profile.groupMemberIdForMapping) {
      this.validateGroupMemberIdForMapping(this.profile.groupMemberIdForMapping);
    } else {
      this.isGroupMemberIdForMappingValid = true;
      this.formChanged();
    }
  }

  private validateCode(code: string) {
    if (code && code.startsWith('CP')) {
      this.profileService.getProfileNamesByCode(code).subscribe(value => {
        this.profileCodeViolations = value;
        this.isCodeValid = this.profileCodeViolations.length < 1;
        this.formChanged();
      });
    } else {
      this.isCodeValid = false;
      this.formChanged();
    }
  }

  private validateGroupMemberIdForMapping(memberId: string) {
    if (memberId) {
      this.profileService.getProfileNamesByGroupMemberIdForMapping(this.profile.groupProfileId, memberId).subscribe(value => {
        this.groupMemberIdForMappingViolations = value;
        this.isGroupMemberIdForMappingValid = this.groupMemberIdForMappingViolations.length < 1;
        this.formChanged();
      });
    } else {
      this.isGroupMemberIdForMappingValid = true;
      this.formChanged();
    }
  }

  get CaseInformationEnumKeys() {
    return Object.keys(CaseInformationEnum);
  }

  get CaseInformationEnum() {
    return CaseInformationEnum;
  }

  get InvoicingEnumKeys() {
    return Object.keys(InvoicingEnum);
  }

  get InvoicingEnum() {
    return InvoicingEnum;
  }

  prefixCheck(type: BusinessAreaEnum, input: string) {
    if (type === BusinessAreaEnum.TRAVELCARE) {
      this.profile.caseNumberPreFixTravel = input;
    }
    if (type === BusinessAreaEnum.MOBILITY) {
      this.profile.caseNumberPreFixRoadside = input;
    }
    if (type === BusinessAreaEnum.HEALTHCARE) {
      this.profile.caseNumberPreFixHealth = input;
    }
    this.formChanged();
  }

  prefixValidation(input: string) {
    const regexp = RegExp('^[A-Z]{4}$');
    return regexp.test(input);
  }

  checkBoxChange() {
    if (!this.profile.businessAreasTravel) {
      this.profile.caseNumberPreFixTravel = null;
      this.profile.responsibleKamTravelUserId = null;
      this.profile.responsibleKamTravelFirstName = null;
      this.profile.responsibleKamTravelSurname = null;
      this.profile.travelcareCaseInformation = null;
      this.profile.travelcareInvoicing = null;
    }
    if (!this.profile.businessAreasRoadside) {
      this.profile.caseNumberPreFixRoadside = null;
      this.profile.responsibleKamRoadsideUserId = null;
      this.profile.responsibleKamRoadsideFirstName = null;
      this.profile.responsibleKamRoadsideSurname = null;
      this.profile.mobilityCaseInformation = null;
      this.profile.mobilityInvoicing = null;
    }
    if (!this.profile.businessAreasHealth) {
      this.profile.caseNumberPreFixHealth = null;
      this.profile.responsibleKamHealthUserId = null;
      this.profile.responsibleKamHealthFirstName = null;
      this.profile.responsibleKamHealthSurname = null;
      this.profile.healthcareCaseInformation = null;
      this.profile.healthcareInvoicing = null;
    }
    this.formChanged();
  }

  formChanged() {
    if (
      this.profile.name &&
      this.profile.code &&
      this.isCodeValid &&
      this.isGroupMemberIdForMappingValid &&
      this.profile.market &&
      this.profile.name !== '' &&
      (this.profile.businessAreasTravel || this.profile.businessAreasRoadside || this.profile.businessAreasHealth) &&
      this.isProfileChanged() &&
      this.isBusinessAreaChanged()
    ) {
      this.isProfileValid = true;
    } else {
      this.isProfileValid = false;
    }
  }

  private isBusinessAreaChanged() {
    return (
      ((this.isTravelcareChanged() && this.prefixValidation(this.profile.caseNumberPreFixTravel)) || this.isTravelcareNotChanged()) &&
      ((this.isMobilityChanged() && this.prefixValidation(this.profile.caseNumberPreFixRoadside)) || this.isMobilityNotChanged()) &&
      ((this.isHealthcareChanged() && this.prefixValidation(this.profile.caseNumberPreFixHealth)) || this.isHealthcareNotChanged())
    );
  }

  private isTravelcareChanged() {
    return (
      this.profile.businessAreasTravel &&
      !!this.profile.responsibleKamTravelUserId &&
      !!this.profile.caseNumberPreFixTravel &&
      !!this.profile.travelcareCaseInformation &&
      !!this.profile.travelcareInvoicing
    );
  }

  private isTravelcareNotChanged() {
    return (
      !this.profile.businessAreasTravel &&
      !this.profile.responsibleKamTravelUserId &&
      !this.profile.caseNumberPreFixTravel &&
      !this.profile.travelcareCaseInformation &&
      !this.profile.travelcareInvoicing
    );
  }

  private isMobilityChanged(): boolean {
    return (
      this.profile.businessAreasRoadside &&
      !!this.profile.responsibleKamRoadsideUserId &&
      !!this.profile.caseNumberPreFixRoadside &&
      !!this.profile.mobilityCaseInformation &&
      !!this.profile.mobilityInvoicing
    );
  }

  private isMobilityNotChanged() {
    return (
      !this.profile.businessAreasRoadside &&
      !this.profile.responsibleKamRoadsideUserId &&
      !this.profile.caseNumberPreFixRoadside &&
      !this.profile.mobilityCaseInformation &&
      !this.profile.mobilityInvoicing
    );
  }

  private isHealthcareChanged() {
    return (
      this.profile.businessAreasHealth &&
      !!this.profile.responsibleKamHealthUserId &&
      !!this.profile.caseNumberPreFixHealth &&
      !!this.profile.healthcareCaseInformation &&
      !!this.profile.healthcareInvoicing
    );
  }

  private isHealthcareNotChanged() {
    return (
      !this.profile.businessAreasHealth &&
      !this.profile.responsibleKamHealthUserId &&
      !this.profile.caseNumberPreFixHealth &&
      !this.profile.healthcareCaseInformation &&
      !this.profile.healthcareInvoicing
    );
  }

  private isProfileChanged() {
    return (
      this.profile.name !== this.profileOld.name ||
      this.profile.code !== this.profileOld.code ||
      this.profile.groupMemberIdForMapping !== this.profileOld.groupMemberIdForMapping ||
      this.profile.market !== this.profileOld.market ||
      this.profile.businessAreasTravel !== this.profileOld.businessAreasTravel ||
      this.profile.responsibleKamTravelUserId !== this.profileOld.responsibleKamTravelUserId ||
      this.profile.caseNumberPreFixTravel !== this.profileOld.caseNumberPreFixTravel ||
      this.profile.travelcareCaseInformation !== this.profileOld.travelcareCaseInformation ||
      this.profile.travelcareInvoicing !== this.profileOld.travelcareInvoicing ||
      this.profile.businessAreasRoadside !== this.profileOld.businessAreasRoadside ||
      this.profile.responsibleKamRoadsideUserId !== this.profileOld.responsibleKamRoadsideUserId ||
      this.profile.caseNumberPreFixRoadside !== this.profileOld.caseNumberPreFixRoadside ||
      this.profile.mobilityCaseInformation !== this.profileOld.mobilityCaseInformation ||
      this.profile.mobilityInvoicing !== this.profileOld.mobilityInvoicing ||
      this.profile.businessAreasHealth !== this.profileOld.businessAreasHealth ||
      this.profile.responsibleKamHealthUserId !== this.profileOld.responsibleKamHealthUserId ||
      this.profile.caseNumberPreFixHealth !== this.profileOld.caseNumberPreFixHealth ||
      this.profile.healthcareCaseInformation !== this.profileOld.healthcareCaseInformation ||
      this.profile.healthcareInvoicing !== this.profileOld.healthcareInvoicing ||
      this.profile.remarks !== this.profileOld.remarks
    );
  }

  addNewProfile() {
    this.profileService.updateProfile(this.profile).subscribe(result => {
      this.profile = result;
      this.profileOld = this.profileAdapter.adapt(this.profile);
      this.updateService.changedProfile();
      this.breadcrumbsMenuUpdateService.updatedProfile(result);
      this.formChanged();
    });
  }

  isDeletable(): boolean {
    return !this.isApplied && (!this.profileProducts || this.profileProducts.length < 1);
  }

  deleteProfile() {
    const navId = this.customerListViewOriginal.parentId;
    this.profileService.deleteProfile(this.profile.id).subscribe(
      result => {
        this.updateService.changedBrand();
        this.router.navigate([RoutesName.customers + '/' + RoutesName.brand, navId]);
      },
      error => console.error(error)
    );
  }

  isGroupProfile(profile: Profile): boolean {
    return !profile.groupProfileId
  }
}
