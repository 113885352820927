<div class="brand-overview-container">
  <div class="brand-summary">
    <app-brand-summary [customerListView]="customerListView" [numberOfProfiles]="numberOfProfiles">
    </app-brand-summary>
  </div>
  <div class="brand-profiles">
    <app-brand-profiles [customerListView]="customerListView" [numberOfProfiles]="numberOfProfiles"
      [profiles]="profiles">
    </app-brand-profiles>
  </div>
</div>
