import {Component, Input, OnInit} from '@angular/core';
import {Product} from "../../../../shared/models/product";
import {DebtorService} from "../../../../core/services/debtor.service";
import {Dictionary} from "../../../../shared/models/dictionary";
import {ProductService} from "../../../../core/services/product.service";
import {ProductDebtorOverrule} from "../../../../shared/models/product-debtor-overrule";

@Component({
  selector: 'app-product-debtor',
  templateUrl: './product-debtor.component.html',
  styleUrls: ['./product-debtor.component.scss']
})
export class ProductDebtorComponent implements OnInit {

  @Input() set product(value) {
    if (value) {
      this._product = value;
      this.productService.getProductDebtorOverrule(this._product.id).subscribe(
        result => {
          this.productDebtorOverrule = result;
          this.originalDebtorId = this.productDebtorOverrule.debtorId;
          this.originalRemarks = this.productDebtorOverrule.remarks;
          this.getDebtors();
          this.saveActive = false;
        },
        error => console.error(error)
      );
    }
  }
  get product() {
    return this._product;
  }
  private _product: Product;
  debtors: Dictionary[] = [];
  saveActive: boolean;
  productDebtorOverrule: ProductDebtorOverrule;
  originalDebtorId: string;
  originalRemarks: string;

  constructor(private debtorService: DebtorService,
              private productService: ProductService) {
    this.productDebtorOverrule = new ProductDebtorOverrule();
  }

  ngOnInit(): void {
  }

  getDebtors() {
    this.debtorService.getAllDebtorsForProfile(this.product.profileId).subscribe(
      result => {
        result.forEach(value => value.name = value.number + ' ' + value.name);
        this.debtors.push(...result);
      },
      error => console.error(error)
    );
  }

  dataChanged() {
    if (this.productDebtorOverrule.overruled) {
      this.saveActive = (
        (!ProductDebtorComponent.isEmpty(this.productDebtorOverrule.debtorId) &&
          (this.productDebtorOverrule.debtorId != this.originalDebtorId || this.productDebtorOverrule.remarks != this.originalRemarks)));
    } else {
      this.productDebtorOverrule.debtorId = null;
      this.saveActive = (
        this.productDebtorOverrule.debtorId != this.originalDebtorId ||
        this.productDebtorOverrule.remarks != this.originalRemarks)
    }
  }

  private static isEmpty(str: string): boolean {
    return (!str || str == '');
  }

  save() {
    this.productService.updateProductDebtorOverrule(this.productDebtorOverrule).subscribe(
      result => {
        this.productDebtorOverrule = result;
        this.originalDebtorId = this.productDebtorOverrule.debtorId;
        this.originalRemarks = this.productDebtorOverrule.remarks
        this.saveActive = false;
      },
      error => console.error(error)
    );
  }

  isChildProduct() {
    return this.product.parentId !== null;
  }
}
