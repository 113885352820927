import { BusinessAreaEnum } from 'app/shared/models/enums';
import { FilteringSortingMenuType } from './filtering-sorting-menu-type';
import { FilteringService } from './filtering-service';
import { HelperService } from './../../../../../core/services/helper.service';
import { DictionaryService } from './../../../../../core/services/dictionary.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { CustomersTableRow } from '../customers-table-row';

@Component({
  selector: 'app-filtering-sorting-menu',
  templateUrl: './filtering-sorting-menu.component.html',
  styleUrls: ['./filtering-sorting-menu.component.scss']
})
export class FilteringSortingMenuComponent implements OnInit {
  @Input() rows: CustomersTableRow[];
  @Input() filteringSortingMenuType: FilteringSortingMenuType;
  @Output() modelChanged = new EventEmitter();
  @Output() rowsChange = new EventEmitter();
  @Output() clearAllFilters = new EventEmitter();
  sortByAscending: boolean;
  dateFilter: boolean;
  sortingKey: string;
  items: CheckBoxModel[];
  @Input() setDefault: boolean;
  fromDate: any;
  toDate: any;

  constructor(
    private dictionaryService: DictionaryService,
    private helperService: HelperService,
    private filteringService: FilteringService
  ) {
  }

  ngOnInit() {
    this.populateItems();
    if (this.setDefault) {
      this.sortAscending();
    }
  }

  public uncheckItems() {
    if (this.items) {
      this.items.forEach(a => (a.check = false));
      this.clearAllFilters.emit();
    }
  }

  sortAscending() {
    this.sortByAscending = true;
    this.helperService.sortArray(this.rows, this.sortingKey, this.sortByAscending);
    this.modelChanged.emit();
  }

  sortDescending() {
    this.sortByAscending = false;
    this.helperService.sortArray(this.rows, this.sortingKey, this.sortByAscending);
    this.modelChanged.emit();
  }

  populateItems() {
    this.items = [];
    switch (this.filteringSortingMenuType) {
      case FilteringSortingMenuType.customer:
        this.rows.map(a => {
          if (!this.items.find(f => f.id === a.name)) {
            this.items.push(new CheckBoxModel(a.name, a.name, false));
          }
        });
        this.sortingKey = 'name';
        break;
      case FilteringSortingMenuType.kam:
        this.rows.map(a => {
          if (a.kam != null && !this.items.find(f => f.id === a.kam)) {
            this.items.push(new CheckBoxModel(a.kam, a.kam, false));
          }
        });
        this.sortingKey = 'kam';
        break;
      case FilteringSortingMenuType.market:
        this.rows.map(a => {
          a.market &&
          a.market.split &&
          a.market.split(',').forEach(marketId => {
            if (!this.items.find(f => f.id === marketId.trim())) {
              this.items.push(new CheckBoxModel(marketId.trim(), this.dictionaryService.getMarketName(marketId.trim()), false));
            }
          });
        });
        this.sortingKey = 'market';
        break;
      case FilteringSortingMenuType.segment:
        this.rows.map(a => {
          if (!this.items.find(f => f.id === a.segment)) {
            this.items.push(new CheckBoxModel(a.segment, a.segment, false));
          }
        });
        this.sortingKey = 'segment';
        break;
      case FilteringSortingMenuType.subBusinessRelation:
        this.rows.map(a => {
          a.subBusinessRelation &&
          a.subBusinessRelation.name &&
          a.subBusinessRelation.name.split &&
          a.subBusinessRelation.name.split('/').forEach(subBusinessRelation => {
            if (!this.items.find(f => f.id === subBusinessRelation.trim())) {
              this.items.push(new CheckBoxModel(subBusinessRelation.trim(), subBusinessRelation.trim(), false));
            }
          });
        });
        this.sortingKey = 'subBusinessRelation';
        break;
      case FilteringSortingMenuType.integrationType:
        this.rows.map(a => {
          if (a.integrationType) {
            a.integrationType &&
            a.integrationType.split &&
            a.integrationType.split(',').forEach(integrationType => this.addSortOption(integrationType));
          }
        });
        this.sortingKey = 'integrationType';
        break;
      case FilteringSortingMenuType.businessArea:
        this.items.push(new CheckBoxModel('TRAVELCARE', BusinessAreaEnum.TRAVELCARE, false));
        this.items.push(new CheckBoxModel('MOBILITY', BusinessAreaEnum.MOBILITY, false));
        this.items.push(new CheckBoxModel('HEALTHCARE', BusinessAreaEnum.HEALTHCARE, false));
        this.sortingKey = 'businessAreaCount';
        break;
      case FilteringSortingMenuType.nextNegotiation:
        this.dateFilter = true;
        const year = new Date().getFullYear();
        const month = new Date().getMonth() + 1;
        const day = new Date().getDay() + 1;
        this.fromDate = new NgbDate(year, month, day);
        this.toDate = new NgbDate(year + 1, month, day);
        this.sortingKey = 'nextNegotationDate';
        break;
    }
  }

  addSortOption(integrationType: string): void {
    if (!this.items.find(f => f.id === integrationType.trim())) {
      this.items.push(new CheckBoxModel(integrationType.trim(), integrationType.trim(), false));
    }
  }

  get doesUserCheckAnything() {
    return !!this.items.find(a => a.check);
  }

  applyButtonClick() {
    if (!this.doesUserCheckAnything) {
      return;
    }
    this.clearAllFilters.emit();
    const selectedIds = [];
    this.items.filter(a => a.check).forEach(a => selectedIds.push(a.id));
    if (selectedIds.length > 0) {
      this.filteringService.removeStep(this.filteringSortingMenuType);
      this.filteringService.addStep(
        this.filteringSortingMenuType,
        selectedIds,
        this.fromDate ? new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day) : new Date(),
        this.toDate ? new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day) : new Date()
      );
    } else {
      this.clearAllButtonClick();
    }
    this.rows = this.filteringService.filter(this.rows);
    this.rowsChange.emit(this.rows);
    this.modelChanged.emit();
  }

  clearAllButtonClick() {
    if (!this.doesUserCheckAnything) {
      return;
    }
    this.items.map(a => (a.check = false));
    this.filteringService.removeStep(this.filteringSortingMenuType);
    this.clearAllFilters.emit();
    this.rows = this.filteringService.filter(this.rows);
    this.rowsChange.emit(this.rows);
  }

  modalWindowClick(event: any) {
    event.stopPropagation();
  }
}

export class CheckBoxModel {
  name: string;
  id: string;
  check: boolean;

  constructor(id: string, name: string, check: boolean) {
    this.check = check;
    this.id = id;
    this.name = name;
  }
}
