<ng-template #content let-modal>
  <div class="modal-container">
    <div class="theHead">
      <div class="new-index-title">Add new Index
        <div class="name">Index settings</div>
      </div>
      <div class="new-index-close"><img (click)="close()" src="assets/icons/Close.svg" alt="Close"></div>
    </div>
    <div class="theBody">
      <div class="name-box">
        <app-input [(value)]="theNewIndexName" [required]="true" (valueChange)="checkIfTheNameIsUnique(theNewIndexName)"
          [name]="'Index Name'"></app-input>
      </div>
    </div>
    <div class="theFooter">
      <button class="primary-button" (click)="addIndexes()" [ngClass]="!theIndexCanbeAdded && 'button-disabled'"
        [disabled]="!theIndexCanbeAdded">Add
      </button>
    </div>
  </div>
</ng-template>
