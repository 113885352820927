export class MasterListChannelTypeModel {
  id: string;
  channelTypeCode: string;
  channelTypeEnumValue: string;
  channelTypeName: string;
  description: string;
  uiSortIndex: number;
  order: number;
  activateOn: Date;
  deactivateOn: Date;
  active: boolean;
  disabledBusinessRules: boolean = false;

  public constructor(init?: Partial<MasterListChannelTypeModel>) {
    Object.assign(this, init);
  }
}
