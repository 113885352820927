<div class="page-container">
  <div class="page-header">
    <div class="year-filter-drop-down row">
      <app-drop-down [items]="dropDownYearList" [(selectedItemId)]="selectedYear"
                     (selectedItemIdChange)="yearDropDownChange()" [name]="'Index year'">
      </app-drop-down>
    </div>
    <div class="new-index-modal-footer row">
      <button class="primary-button add-new-index-button" (click)="addNewIndex()">Add new index</button>
    </div>
  </div>
  <div class="page-content-container">
    <table class="primary-table sos-services-table">
      <thead>
      <tr>
        <td class="Index-name-col" sortColumn [sortKey]="'name'" [data]="indexes">
          Index name
        </td>
        <td class="Index-year-col">
          Index year
        </td>
        <td class="Index-value-col" sortColumn [sortKey]="'indexNumberForSorting'" [data]="indexes">
          Value
        </td>
      </tr>
      </thead>
      <tbody class="theBody">
      <ng-container *ngFor="let theIndex of indexes; index as i">
        <tr>
          <td class="Index-name-col" [ngClass]="{'even' : i % 2 == 1 }">
            <div class="name-box">
              <app-input [(value)]="theIndex.name"
                         (valueChange)="checkIfTheDataCanBeSaved(theIndex.name, theIndex.nameTableId)"></app-input>
            </div>
          </td>
          <td class="Index-year-col" [ngClass]="{'even' : i % 2 == 1 }">
            <div class="year-box">
              {{theIndex.year}}
            </div>
          </td>
          <td class="Index-value-col" [ngClass]="{'even' : i % 2 == 1 }">
            <div class="value-box">
              <app-input [(value)]="theIndex.indexValue" (focusOut)="percentUpdate(theIndex.indexValue, i)"></app-input>
            </div>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
  <div class="index-foot">
    <div class="save-changes-button my-auto">
      <button class="primary-button" (click)="saveIndexes()" *ngIf="buttonType === 'saveAndRun'"
              [ngClass]="!theChangesCanBeSaved && 'button-disabled'" [disabled]="!theChangesCanBeSaved">Save changes
        and run index job
      </button>
      <button class="primary-button" (click)="saveIndexes()" *ngIf="buttonType === 'run'">Run index job
      </button>
      <button class="primary-button" *ngIf="buttonType === 'runingJob'" [ngClass]="'button-disabled'">
        <img src="./assets/icons/waitForSearchresult.gif" alt="Loading">
      </button>
    </div>
    <div class="my-auto" *ngIf="timeStampOfLastIndexJob === null && !warningCaseOfFailingIndexJob"> Index job for
      year {{selectedYear}} has not been executed
    </div>
    <div class="my-auto" *ngIf="timeStampOfLastIndexJob !== null && !warningCaseOfFailingIndexJob"> Index job for
      year {{selectedYear}} was successfully executed
      on {{timeStampOfLastIndexJob | date : "dd MMM yyyy, HH:mm" }} </div>
    <div class="warningtext my-auto" *ngIf="warningCaseOfFailingIndexJob"> WARNING: Index job failed. No
      prices were indexed for year {{selectedYear}}.
    </div>
  </div>
</div>

<app-add-new-index #addNewIndexComponent [indexList]="indexes" (refreshIndex)="refreshIndexEvent($event)"
                   [year]="selectedYear">
</app-add-new-index>
