<div class="service-type-modal-container">
  <div class="closing-x" (click)="closeModal()">
    <img src="/assets/icons/Close.svg" />
  </div>
  <div class="header">
    <span *ngIf="isNew">Create new Service Type</span>
    <span *ngIf="!isNew">Edit Service Type</span>
  </div>
  <div class="row body-container" *ngIf="masterListType">
    <div class="col-3">
      <div class="input-name">
        Activate on
        <span *ngIf="!isReadonly" class="required">*</span>
      </div>
      <app-input-calendar
        [withImage]="true"
        [selectMode]="'single'"
        [disabled]="isReadonly"
        [(dateTimeRange)]="masterListType.activateOn"
        [maxDate]="masterListType.deactivateOn"
        (saveModel)="activateOnChanged(masterListType)"
      >
      </app-input-calendar>
      <div class="input-name">Deactivate after</div>
      <app-input-calendar
        [withImage]="true"
        [selectMode]="'single'"
        [disabled]="isReadonly"
        [(dateTimeRange)]="masterListType.deactivateOn"
        [minDate]="minDeactivationDate"
        (saveModel)="deactivateOnChanged(masterListType)"
      >
      </app-input-calendar>
      <div class="label" style="font-size: 14px;
      color: #787878;" [ngClass]="'input-name'">Based on case creation time</div>
    </div>
    <div class="col-9">
      <div class="row">
        <div class="col-6">
          <div>
            <app-input
              [name]="'Business key'"
              [(value)]="masterListType.serviceTypeCode"
              (valueChange)="modelChanged()"
              [disabled]="isReadonly || masterListType.id"
              [required]="!isReadonly"
            ></app-input>
          </div>
          <div class="padding-top">
            <app-input
              [name]="'Name'"
              [(value)]="masterListType.serviceTypeName"
              (valueChange)="modelChanged()"
              [disabled]="isReadonly"
              [required]="!isReadonly"
            ></app-input>
          </div>
          <div class="padding-top">
            <div class="field-title">Description</div>
            <textarea [(ngModel)]="masterListType.description" (ngModelChange)="modelChanged()" [disabled]="isReadonly"></textarea>
          </div>
          <div class="padding-top">
            <app-input
              [name]="'Sort in UI'"
              [(value)]="masterListType.uiSortIndex"
              (valueChange)="modelChanged()"
              [disabled]="isReadonly"
            ></app-input>
          </div>
        </div>
        <div class="col-6">
          <div>
            <app-input [name]="'Enumerated value'" [(value)]="masterListType.serviceTypeEnumValue" [disabled]="true"></app-input>
          </div>
          <div class="padding-top">
            <div>
              <app-drop-down-multiselect name="Commitment types" [required]="true" [dropdownItemList]="allSelectableCommitmentType"
                [(selectedItems)]="commitmentTypes" (selectedItemsChange)="selectedCommitmentTypesChanged($event)">
              </app-drop-down-multiselect>
            </div>
          </div>
            <div class="padding-top">
            <div>
              <app-drop-down [name]="'Default Commitment Type'" [(selectedItemId)]="selectedDefaultCommitmentTypeId" [items]="defaultCommitmentChoises"
                [required]="false" [disabled]="isReadonly"
                (selectedItemIdChange)="selectedDefaultCommitmentTypeChanged($event)">
              </app-drop-down>
            </div>
          </div>
          <div>
            <div class="padding-top">
              <div class="field-title">Economy</div>
              <div class="sub-text">Include supplier invoice and refund in service order process bar</div>
            </div>
            <div class="checkbox-padding-top">
              <input type="checkbox"
                     [(ngModel)]="masterListType.economyApplicable"
                     (change)="modelChanged()"
                     [disabled]="isReadonly" />
            </div>
          </div>

        </div>
      </div>
      <div class="row body-container" *ngIf="error">
        <div class="col-12 error">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
  <div class="masterlist-modal-footer d-flex justify-content-between">
    <div *permission="PermissionEnum.TEMPLATES_AND_BUSINESS_RULES_UPDATE" class="">
      <button class="primary-button save-button" [class.button-disabled]="!isSaveButtonEnabled" (click)="save()">Save</button>
    </div>
    <app-last-changed [masterListItem]="masterListType" class="last-changed float-end"> </app-last-changed>
  </div>
</div>
