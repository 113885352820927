<div class="upload-modal-container">
  <div class="closing-x" (click)="closeModal()">
    <img src="/assets/icons/Close.svg">
  </div>
  <div class="header">
    <span>Upload translations</span>
  </div>
  <div class="row body-container">
    <div class="col-12">
      <div class="row">
        <div class="col-8">
          <div class="upload-button-container">
            <button class="primary-button-outline" (click)="fileInput.click()">
              Select translation file
            </button>
          </div>
        </div>
        <div class="col-4 text-end">
          <div *ngIf="masterListUploadInfo" class="upload-info-container">
              Last uploaded {{masterListUploadInfo?.lastUploaded | momentFormat: 'DD MMM YYYY HH:mm' : 'local'}} By {{masterListUploadInfo?.uploadedBy}}
          </div>
        </div>
      </div>
      <div *ngIf="fileToUpload">
        <hr>
        <div class="row">
          <div class="col-6">
            <div>
              <div class="input-name">
                Selected file:
              </div>
              <span>
                {{fileToUpload.name}}
              </span>
            </div>
          </div>
          <div class="col-6">
            <div>
              <div class="input-name">
                File size:
              </div>
              <span>
                {{humanFileSize(fileToUpload.size)}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="uploadStatistics">
        <br>
        <hr>
        <div class="row">
          <div class="col-2">
            <div>
              <div class="input-name">
                Status:
              </div>
              <span class="success">
                Success
              </span>
            </div>
          </div>
          <div class="col-2">
            <div>
              <div class="input-name">
                Total rows:
              </div>
              <span class="success">
                {{uploadStatistics.totalRows}}
              </span>
            </div>
          </div>
          <div class="col-2">
            <div>
              <div class="input-name">
                Processed rows:
              </div>
              <span class="success">
                {{uploadStatistics.processedRows}}
              </span>
            </div>
          </div>
          <div class="col-2">
            <div>
              <div class="input-name">
                Skipped rows:
              </div>
              <span class="success">
                {{uploadStatistics.skippedRows}}
              </span>
            </div>
          </div>
          <div class="col-2">
            <div>
              <div class="input-name">
                Processed languages:
              </div>
              <span class="success">
                {{uploadStatistics.processedLanguages}}
              </span>
            </div>
          </div>
          <div class="col-2">
            <div>
              <div class="input-name">
                Invalid entities:
              </div>
              <span class="error">
                {{uploadStatistics.invalidEntities ? uploadStatistics.invalidEntities.length : 0}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="error">
        <br>
        <hr>
        <div class="row">
          <div class="col-6">
            <div>
              <div class="input-name">
                Status:
              </div>
              <span class="error">
                Failed
              </span>
            </div>
          </div>
          <div class="col-6">
            <div>
              <div class="input-name">
                Cause:
              </div>
              <span class="error">
                {{error}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <input #fileInput type="file" accept=".xlsx, .xls, .xlt"
          (change)="handleFileInput($event.target.files)" style="display: none" />
      </div>
    </div>
    <div class="action-container">
      <div class="save-button" *ngIf="!uploadStatistics">
        <button class="primary-button" [ngClass]="{ 'button-disabled': !isUploadButtonEnabled() }"
          (click)="upload()">
          <img *ngIf="uploading" src="./assets/icons/waitForSearchresult.gif" alt="uploading">
          <span *ngIf="!uploading">Upload</span>
        </button>
      </div>
      <div class="save-button" *ngIf="uploadStatistics">
        <button class="primary-button" (click)="closeModal()">
          <span>Close</span>
        </button>
      </div>
    </div>
  </div>
</div>

