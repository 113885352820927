import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import {ServerWarningsEnum} from "../../shared/enums/server-warnings.enum";
import {ServerWarningHandlerHelper} from "../../shared/helpers/server-warning-handler-helper";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  private warningMessages = new ReplaySubject<HttpErrorResponse>();
  private serverWarningHandlerHelper: ServerWarningHandlerHelper;

  constructor(private injector: Injector,
              private zone: NgZone) {
    this.serverWarningHandlerHelper = new ServerWarningHandlerHelper(this.getWarningMessage(), injector, zone);
  }

  public getWarningMessage():Observable<HttpErrorResponse> {
    return this.warningMessages.asObservable();
  }

  private handleHttpErrorResponses(error: HttpErrorResponse){
    if(Object.values(ServerWarningsEnum).includes(error.error?.code)){
      this.warningMessages.next(error);
    }
    //ADD Global error handler if needed;
  }

  handleError(error: Error | HttpErrorResponse) {
    if(error instanceof HttpErrorResponse){
      this.handleHttpErrorResponses(error);
    }
    console.log(error);

  }
}
