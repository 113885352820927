<div class="page-container">
    <div class="page-header header">
        <button class="primary-button button" (click)="createValidation()">Create new validation</button>
    </div>
  <table class="primary-table sos-services-table">
    <thead>
      <tr>
        <td class="unsortable-column">
          Name
        </td>
        <td class="unsortable-column">
          Type
        </td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let validation of validations" (click)="editValidation(validation)">
        <td>
          {{validation.name}}
        </td>
        <td>
          {{validation.input.validationType}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<app-edit-dcf-validation #editDcfValidationComponent (updateCallback)="editUpdated()">
</app-edit-dcf-validation>
