import {Injectable} from '@angular/core';
import {Adapter} from '@onepoint/shared/interfaces/adapter';

export class CaseProcessProfile {
  id: number;
  caseProcessType: string;
  profileId: number;
  profileCode: string;
  profileName: string;
  brandId: number;
  brandName: string;
  commonCustomerId: number;
  commonCustomerName: string;
  included: boolean;
  validFrom: Date;
  validTo: Date;
  changed: boolean;

  public constructor(init?: Partial<CaseProcessProfile>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CaseProcessProfileAdapter implements Adapter<CaseProcessProfile> {
  constructor() {
  }

  adapt(item: any): CaseProcessProfile {
    const caseProcessProfile = new CaseProcessProfile({
      id: item.id,
      caseProcessType: item.caseProcessType,
      profileId: item.profileId,
      profileCode: item.profileCode,
      profileName: item.profileName,
      brandId: item.brandId,
      brandName: item.brandName,
      commonCustomerId: item.commonCustomerId,
      commonCustomerName: item.commonCustomerName,
      included: item.included,
      validFrom: item.validFrom ? new Date(item.validFrom) : null,
      validTo: item.validTo ? new Date(item.validTo) : null,
    });
    if (caseProcessProfile.validFrom != null) {
      caseProcessProfile.validFrom.setHours(12, 0, 0, 0);
    }
    if (caseProcessProfile.validTo != null) {
      caseProcessProfile.validTo.setHours(12, 0, 0, 0);
    }
    return caseProcessProfile;
  }
}
