import {Injectable} from '@angular/core';
import {Adapter} from './../interfaces/adapter';

export class SlaEvent {
  id: number;
  externalId: number;
  name: string;
  type: string;

  public constructor(init?: Partial<SlaEvent>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SlaEventAdapter implements Adapter<SlaEvent> {
  adapt(item: any): SlaEvent {
    return new SlaEvent({
      id: item.id,
      name: item.name,
      type: item.type,
      externalId: item.externalId
    });
  }
}
