import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-input-switch',
  templateUrl: './input-switch.component.html',
  styleUrls: ['./input-switch.component.scss']
})
export class InputSwitchComponent {
  @Output() valueChange = new EventEmitter<boolean>();
  @Input() id: string;
  @Input() value: boolean;

  onChangeValue() {
    this.valueChange.emit(this.value);
  }
}
