import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonCustomer} from '../../../../../shared/models/common-customer';
import {Dictionary} from '../../../../../shared/models/dictionary';
import {DictionaryService} from '../../../../../core/services/dictionary.service';
import { Kam } from 'app/shared/models/kam';

@Component({
  selector: 'app-edit-common-customer',
  templateUrl: './edit-common-customer.component.html',
  styleUrls: ['./edit-common-customer.component.scss']
})
export class EditCommonCustomerComponent implements OnInit {
  @Input() commonCustomer: CommonCustomer;
  @Output() changes = new EventEmitter<CommonCustomer>();
  segments: Dictionary[];
  sosOwnerOptions: Dictionary[];
  subBusinessRelations: Dictionary[];

  constructor(private dictionaryService: DictionaryService) {
    this.sosOwnerOptions = [{id: 'true', name: 'Yes'}, {id: 'false', name: 'No'}];
  }
  ngOnInit() {
    this.dictionaryService.getSegments().subscribe(
      result => (this.segments = result),
      error => console.error(error)
    );
    this.dictionaryService.getSubBusinessRelations().subscribe(
      result => (this.subBusinessRelations = result),
      error => console.error(error)
    );
  }

  formChanged() {
    this.changes.emit(this.commonCustomer);
  }
}
