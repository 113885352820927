import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';
@Component({
  selector: 'app-last-changed',
  templateUrl: './last-changed.component.html',
  styleUrls: ['./last-changed.component.scss']
})
export class LastChangedComponent implements OnInit {

  @Input() masterListItem;
  constructor() { }

  ngOnInit(): void {
  }

  asMoment(dateString: string){
    return moment.utc(dateString)
  }

}
