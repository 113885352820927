import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MasterListService } from 'app/core/services/masterlist.service';
import { Dictionary } from 'app/shared/models/dictionary';
import { MasterListServiceTypeModel } from 'app/shared/models/masterlist-service-type-model';
import { PermissionEnum } from 'app/shared/models/permission.enum';
import { ServiceTypeMenuType } from './service-type-menu/masterlist-service-type-menu-type';
import * as _ from 'lodash';
import { OnepointModalService, Size } from 'app/core/services/onepoint.modal.service';

@Component({
  selector: 'app-masterlist-service-type',
  templateUrl: './masterlist-service-type.component.html',
  styleUrls: ['./masterlist-service-type.component.scss'],
})
export class MasterlistServiceTypeComponent implements OnInit {
  @ViewChild('MasterListModal')
  private masterListModalTemplate: TemplateRef<any>;

  masterList: MasterListServiceTypeModel[];
  masterListFiltered: MasterListServiceTypeModel[];
  masterListType: MasterListServiceTypeModel;
  showMenu = new Map<ServiceTypeMenuType, boolean>();
  serviceTypesMap = new Map<string, string>();
  statusTypes: Dictionary[] = [
    { id: 'all', name: 'All' },
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
  ];
  selectedStatus = 'all';

  get ServiceTypeMenuType() {
    return ServiceTypeMenuType;
  }

  get PermissionEnum() {
    return PermissionEnum;
  }

  constructor(private masterListService: MasterListService, private onepointModalService: OnepointModalService) {}

  ngOnInit(): void {
    this.loadMasterList();
  }

  selectedStatusTypeChanged(status: string) {
    this.masterListFiltered = this.filterByStatus(status);
  }

  private loadMasterList() {
    this.masterListService.getServiceTypeList().subscribe(result => {
      this.masterList = result;
      this.masterListFiltered = result;
    });
  }

  private filterByStatus(status: string): MasterListServiceTypeModel[] {
    if (status === 'all') {
      return this.masterList;
    }

    return this.masterList.filter(item => (status === 'active' ? item.active : !item.active));
  }

  showFilteringSortingMenu(filteringSortingMenuType: ServiceTypeMenuType) {
    this.showMenu.forEach((value, key) => {
      if (key !== filteringSortingMenuType) {
        this.showMenu.set(key, false);
      }
    });

    this.showMenu.set(filteringSortingMenuType, !this.showMenu.get(filteringSortingMenuType));
  }

  isShowMenu(filteringSortingMenuType: ServiceTypeMenuType) {
    return this.showMenu.get(filteringSortingMenuType);
  }

  edit(item: MasterListServiceTypeModel) {
    this.masterListType = _.clone(item);
    this.onepointModalService.openWithCustomWindowClass(this.masterListModalTemplate, Size.LARGE, 'service-type-modal-container');
  }

  reload() {
    this.loadMasterList();
  }
}
