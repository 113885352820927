import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import { SosServices } from 'app/shared/models/sos-services';
import { Adapter } from 'app/shared/interfaces/adapter';
import { IncidentCauseAdapter } from 'app/shared/models/incidentCause';
import { Dictionary, DropdownAdapter } from 'app/shared/models/dictionary';
import { SosServicesCategoryEnum, FeeTypeEnum, PriceIndexEnum, MedicalServiceOptionsEnum } from 'app/shared/models/enums';
import { SosServicesItem, CaseTypeItem } from 'app/shared/models/sos-services-item';
import { CausesLevel1 } from 'app/shared/models/CausesLevel1';
import { DictionaryWithType } from 'app/shared/models/dictionary-with-type';
import { SosServicesType } from 'app/shared/models/sos-services-type';
import {MasterListServiceTypeModel} from 'app/shared/models/masterlist-service-type-model';
import { Cause } from '@onepoint/shared/models/cause';
import {BankFee} from "@onepoint/shared/models/bank-fee";

@Injectable({
  providedIn: 'root',
})
export class SosServicesAdapter implements Adapter<SosServices> {
  adapt(item: any, isExtension= false): SosServices {
    let targetPrice = null;
    let targetMaxCap = null;
    const sosServicesCategoryEnum: SosServicesCategoryEnum =
      SosServicesCategoryEnum[item.sosServicesCategory as keyof typeof SosServicesCategoryEnum];
    const medicalServiceOptionsEnum: MedicalServiceOptionsEnum =
      MedicalServiceOptionsEnum[item.medicalServiceOptions as keyof typeof MedicalServiceOptionsEnum];
    const feeTypeEnum: FeeTypeEnum = FeeTypeEnum[item.feeType as keyof typeof FeeTypeEnum];
    const priceIndexEnum: PriceIndexEnum = PriceIndexEnum[item.defaultPriceIndex as keyof typeof PriceIndexEnum];
    if (item.targetPrice) {
      targetPrice = item.targetPrice;
    } else {
      targetPrice = { amount: 0, currency: 'DKK' };
    }
    if (item.targetMaxCap) {
      targetMaxCap = item.targetMaxCap;
    } else {
      targetMaxCap = { amount: 0, currency: 'DKK' };
    }
    return new SosServices({
      id: item.id,
      sosServiceId: item.sosServiceId,
      name: item.name,
      sosServicesCategory: item.sosServicesCategory,
      sosServicesCategoryEnum,
      validStart: item.validStart,
      validEnd: item.validEnd,
      feeType: item.feeType,
      serviceItemOverride: item.serviceItemOverride,
      feeTypeEnum,
      feePriority: item.feePriority,
      commitmentType: item.commitmentType ? item.commitmentType : [],
      medicalServiceOptions: item.medicalServiceOptions,
      medicalServiceOptionsEnum,
      caseType: item.caseType ? item.caseType.map(elem => new CaseTypeItem(elem.id, elem.caseTypeName)) : [],
      causesLevel1: item.causesLevel1 ? item.causesLevel1.map(elem => new CausesLevel1(elem.id, elem.level1)) : [],
      causesLevel2: item.allCausesLevel2 ? [{id: 'All', name: 'All'}]
        : (item.causesLevel2 ? item.causesLevel2.map(elem => new Cause(elem.id, elem.level2)) : []),
      allCausesLevel2: item.allCausesLevel2,
      refundTypes: item.refundTypes != null ? item.refundTypes.map(elem => new Dictionary(elem.id, elem.name)) : [],
      sosServicesType: item.sosServicesType ? item.sosServicesType.map(elem => new SosServicesType(elem.id, elem.serviceTypeName)) : [],
      includedInStandard: item.includedInStandard,
      multipleDateRange: item.multipleDateRange,
      ignoreServiceItems: item.ignoreServiceItems,
      ignoreAsSuggested: item.ignoreAsSuggested,
      vatErpClient: (item.vatErpClient = item.vatErpClient.map(elem => new Dictionary(elem.id, elem.name))),
      vat: item.vatErpClient.map(elem => elem.name).join(),
      targetPrice,
      targetPriceYear: item.targetPriceYear,
      targetShare: item.targetShare,
      targetMaxCap,
      sosServicesItem: item.sosServicesItem ? item.sosServicesItem.map(elem => new SosServicesItem(elem.id, elem.serviceItemName)) : [],
      refundSosServicesItem: item.refundSosServicesItem
        ? item.refundSosServicesItem.map(elem => new SosServicesItem(elem.id, elem.serviceItemName))
        : [],
      recoverySosServicesItem: item.recoverySosServicesItem
        ? item.recoverySosServicesItem.map(elem => new SosServicesItem(elem.id, elem.serviceItemName))
        : [],
      internalNote: item.internalNote,
      description: item.description,
      communicationCostCurrency: item.communicationCostCurrency,
      communicationCostMinimum: item.communicationCostMinimum,
      communicationCostMaximum: item.communicationCostMaximum,
      communicationCostDefault: item.communicationCostDefault,
      communicationCosts: item.communicationCosts,
      bankFees: item.bankFees,
      priceIndexEnum,
      defaultPriceIndex: item.defaultPriceIndex,
      extensionType: item.extensionType || 'NONE',
      extension: item.extension
        ? this.adapt(item.extension, true)
        : (isExtension ? null : new SosServices()),
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class SosServicesService extends BaseService {
  public static allSosServices: SosServices[] = [];
  updateSosServiceCount = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private sosServicesAdapter: SosServicesAdapter,
    private incidentCauseAdapter: IncidentCauseAdapter,
    private dropdownAdapter: DropdownAdapter
  ) {
    super(settingsService);
  }

  changedSosServiceCount(services: SosServices[]) {
    this.updateSosServiceCount.next(services.length);
  }

  public getAll(): Observable<SosServices[]> {
    return this.http.get(this.baseURL + 'sos-services').pipe(map((data: any[]) => data.map(item => this.sosServicesAdapter.adapt(item))));
  }

  public get(id: string): Observable<SosServices> {
    return this.http
      .get(this.baseURL + 'sos-services/' + id)
      .pipe(map(item => (item === null ? null : this.sosServicesAdapter.adapt(item))));
  }

  public getCount(): Observable<any> {
    return this.http.get(this.baseURL + 'sos-services/');
  }

  public save(sosServices: SosServices): Observable<SosServices> {
    return this.http
      .post<SosServices>(this.baseURL + 'sos-services', sosServices.prepareForSend(), {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.sosServicesAdapter.adapt(item)));
  }

  public update(sosServices: SosServices): Observable<SosServices> {
    return this.http
      .put<SosServices>(this.baseURL + 'sos-services/' + sosServices.id, sosServices.prepareForSend(), {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.sosServicesAdapter.adapt(item)));
  }
  public delete(id: string): Observable<any> {
    return this.http.delete(this.baseURL + 'sos-services/' + id, {
      headers: this.jsonHeaders,
      responseType: 'text',
    });
  }

  public usedOnPriceAgreement(id: string): Observable<any> {
    return this.http.get(this.baseURL + 'sos-services/used-on-price-agreement/' + id, {
      headers: this.jsonHeaders,
      responseType: 'text',
    });
  }

  getSosServicesCategoryList(): Observable<Dictionary[]> {
    return this.http
      .get(this.baseURL + 'sos-services/sos-services-category-list')
      .pipe(map((data: any[]) => data.map(item => new Dictionary(item, SosServicesCategoryEnum[item] || item))));
  }
  getCaseTypeList(): Observable<Dictionary[]> {
    return this.http
      .get(this.baseURL + 'sos-services/case-type-list')
      .pipe(map((data: any[]) => data.map(item => new Dictionary(item.id, item.caseTypeName))));
  }

  getCommitmentTypeList(): Observable<Dictionary[]> {
    return this.http
      .get(this.baseURL + 'sos-services/commitment-type-list')
      .pipe(map((data: any[]) => data.map(item =>  new Dictionary(item.businessKey, item.name))));
  }
  getMedicalServiceOptionsList(): Observable<Dictionary[]> {
    return this.http
      .get(this.baseURL + 'sos-services/medical-service-options-list')
      .pipe(map((data: any[]) => data.map(item => new Dictionary(item, MedicalServiceOptionsEnum[item] || item))));
  }
  getServiceItemList(): Observable<Dictionary[]> {
    return this.http
      .get(this.baseURL + 'masterlist/service-item-list')
      .pipe(map((data: any[]) => data.map(item => new Dictionary(item.id, item.serviceItemName))));
  }

  getSosServicesTypeList(): Observable<DictionaryWithType[]> {
    return this.http
      .get(this.baseURL + 'masterlist/service-type-list')
      .pipe(map((data: any[]) => data.map(item => new DictionaryWithType(item.id, item.serviceTypeName, item.serviceTypeEnumValue))));
  }

  getServiceTypeModalList(): Observable<MasterListServiceTypeModel[]> {
    return this.http
      .get(this.baseURL + 'masterlist/service-type-list')
      .pipe(map((data: any[]) => data.map(item => new MasterListServiceTypeModel(item))));
  }

  getFeeTypeList(): Observable<Dictionary[]> {
    return this.http
      .get(this.baseURL + 'sos-services/fee-type-list')
      .pipe(map((data: any[]) => data.map(item => new Dictionary(item, FeeTypeEnum[item] || item))));
  }

  getDefaultPriceIndex(): Observable<any> {
    return this.http.get(this.baseURL + 'index/default-price-index').pipe(map((data: object) => data));
  }

  getBankFeesPrototype(): Observable<BankFee[]> {
    return this.http.get(this.baseURL + 'sos-services/bankfees').pipe(map((data: any[]) => data.map(item => new BankFee(item))));
  }
}
