import { Component, Input, EventEmitter, Output } from '@angular/core';
import { SettingsService } from 'app/core/services/settings.service';

@Component({
  selector: 'app-upload-brand-icon',
  templateUrl: './upload-brand-icon.component.html',
  styleUrls: ['./upload-brand-icon.component.scss']
})
export class UploadBrandIconComponent {

  @Input() uploadBtnLabel = 'Upload';
  @Input() showInvalidIconMsg = false;
  @Input() iconBlob: string;
  @Output() iconBlobChange = new EventEmitter<string>();
  iconBase64: string;
  showFavicon = false;
  baseURL: string;

  constructor(private settingService: SettingsService) {
    this.baseURL = this.settingService.getBffEndpoint();
  }

  get iconUrl() {
    if (this.iconBlob) {
      return this.baseURL + 'api' + this.iconBlob;
    } else {
      return 'assets/icons/image-container.png';
    }
  }

  showHideFavicon() {
    this.showFavicon = !this.showFavicon;
  }

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = e => {
      this.iconBase64 = myReader.result as string;
      this.iconBlob = this.iconBase64.split(',')[1];
      this.iconBlobChange.emit(this.iconBlob);
    };
    myReader.readAsDataURL(file);
  }
}
