import { Component, EventEmitter, Output, ViewChild, TemplateRef } from '@angular/core';
import { OnepointModalService, Size } from '@onepoint/core/services/onepoint.modal.service';
import { DigitalCaseflowService } from '@onepoint/core/services/digital-caseflow.service';
import { Activity } from '@onepoint/shared/models/digital-caseflow/activity';
import { Dictionary } from '@onepoint/shared/models/dictionary';
import { MasterListService } from '@onepoint/core/services/masterlist.service';
import { CountryService } from '@onepoint/core/services/country.service';
import { DictionaryService } from '@onepoint/core/services/dictionary.service';

@Component({
  selector: 'app-edit-dcf-validation',
  templateUrl: './edit-dcf-validation.component.html',
  styleUrls: ['./edit-dcf-validation.component.scss']
})
export class EditDcfValidationComponent {
  @ViewChild('content')
  private template: TemplateRef<any>;
  @Output() updateCallback = new EventEmitter<any>();
  updateNeeded: boolean;
  deletePossible = false;
  validationTypes: Dictionary[];
  stakeholderTypes: Dictionary[];
  sanctionStakeholderTypes: Dictionary[];
  sanctionActionTypes: Dictionary[] = [];
  commitmentTypes: Dictionary[] = [];
  selectedCommitmentTypes: Dictionary[] = [];
  caseTypes: Dictionary[] = [];
  selectedCaseTypes: Dictionary[] = [];
  countries: Dictionary[] = [];
  selectedCountries: Dictionary[] = [];
  causeLevelOnes: Dictionary[] = [];
  selectedCauseLevelOnes: Dictionary[] = [];
  causeLevelThrees: Dictionary[] = [];
  selectedCauseLevelThrees: Dictionary[] = [];
  purposesOfTravel: Dictionary[] = [];
  selectedPurposesOfTravel: Dictionary[] = [];
  requiredInputTypes: string[];
  validation: Activity;
  description = '';
  emailList: string[] = [];
  domainList: string[] = [];
  placeholderSender: 'xyz@eksample.com';
  placeholderDomain: 'example.com';

  constructor(
    private modalService: OnepointModalService,
    private digitalCaseflowService: DigitalCaseflowService,
    private masterListService: MasterListService,
    private dictionaryService: DictionaryService,
    private countryService: CountryService) {
    this.requiredInputTypes = [];

    digitalCaseflowService.getValidationTypes().subscribe(data => {
      this.validationTypes = data.map(validationType => new Dictionary(validationType, validationType));
      console.log(this.validationTypes);
    });

    masterListService.getAllCommitmentTypes().subscribe(data => {
      this.commitmentTypes = data.map(commitmentType => new Dictionary(commitmentType.businessKey, commitmentType.name));
    });
    masterListService.getCaseTypeList().subscribe(data => {
      this.caseTypes = data.map(caseType => new Dictionary(caseType.caseTypeCode, caseType.caseTypeName));
    });
    masterListService.getCausesLevel1().subscribe(data => {
      this.causeLevelOnes = data.map(cause => new Dictionary(cause.level1Code, '(' + cause.level1Code + ') ' + cause.level1));
      data.forEach(causelevel1 => causelevel1.level2Causes.forEach(causelevel2 => causelevel2.level3Causes.forEach(causelevel3 => {
        let newCause = new Dictionary(causelevel3.level3Code, '(' + causelevel3.level3Code + ') ' + causelevel3.level3);
        if (!this.causeLevelThrees.includes(newCause)) {
          this.causeLevelThrees.push(newCause);
        }
      })));
    });
    countryService.getAllCountries().subscribe(data => {
      this.countries = data.map(country => new Dictionary(country.code, country.countryName));
    });
    digitalCaseflowService.getActivityInputStakeholderTypes().subscribe(data => {
      this.stakeholderTypes = data.map(stakeholderType => new Dictionary(stakeholderType, stakeholderType));
    });
    digitalCaseflowService.getActivityInputSanctionCheckTypes().subscribe(data => {
      this.sanctionStakeholderTypes = data.map(stakeholderType => new Dictionary(stakeholderType, stakeholderType));
    });
    dictionaryService.getAllPurposeOfTravels().subscribe(data => {
      this.purposesOfTravel = data.map(purposeOfTravel => new Dictionary(purposeOfTravel.id, purposeOfTravel.name));
    });
    digitalCaseflowService.getActivityInputSanctionActionTypes().subscribe(data => {
      this.sanctionActionTypes = data.map(stakeholderType => new Dictionary(stakeholderType, stakeholderType));
    });
  }

  public open(validation: Activity) {
    this.validation = validation;
    this.selectedCountries = this.countries.filter(country => validation.input.countries.includes(country.id));
    this.selectedCaseTypes = this.caseTypes.filter(caseType => validation.input.caseTypes.includes(caseType.id));
    this.selectedCommitmentTypes = this.commitmentTypes.filter(commitmentType => validation.input.commitmentTypes.includes(commitmentType.id));
    this.selectedCauseLevelOnes = this.causeLevelOnes.filter(causeLevel => validation.input.causeLevels.includes(causeLevel.id));
    this.selectedCauseLevelThrees = this.causeLevelThrees.filter(causeLevel => validation.input.causeLevels.includes(causeLevel.id));
    this.emailList = [...validation.input.specificEmailAddresses];
    this.domainList = [...validation.input.emailAddressDomains];
    this.selectedPurposesOfTravel = this.purposesOfTravel.filter(purposeOfTravel => validation.input.purposesOfTravel.includes(purposeOfTravel.id));

    if (validation.input.validationType) {
      this.reloadRequiredInput(validation);
    } else {
      this.requiredInputTypes = [];
    }
    if (validation.id) {
      this.digitalCaseflowService.getValidationDescription(validation.input.validationType).subscribe(data => {
        this.description = data[0];
      });
      this.digitalCaseflowService.isValidationDeletionPossible(validation.id).subscribe(data => {
        this.deletePossible = data;
      });
    }
    this.modalService.open(this.template, Size.NORMAL);
  }

  close() {
    this.updateCallback.emit();
    this.modalService.dismissAll();
  }

  save() {
    this.cleanInput();
    console.log(this.validation);
    this.digitalCaseflowService.updateValidation(this.validation).subscribe(
      data => {
        this.updateCallback.emit();
        this.modalService.dismissAll();
      }
    );
  }

  delete() {
    if (this.deletePossible) {
      this.digitalCaseflowService.deleteValidation(this.validation.id).subscribe(
        data => {
          this.updateCallback.emit();
          this.modalService.dismissAll();
        }
      );
    }
  }

  validate() {
    this.updateNeeded = true;
    if (this.validation.name == null || this.validation.name.length === 0 || this.validation.type == null ||
      (this.requiredInputTypes.indexOf('LENGTH') >= 0 && this.validation.input.length == null) ||
      (this.requiredInputTypes.indexOf('STAKEHOLDER_TYPE') >= 0 && this.validation.input.stakeholderType == null) ||
      (this.requiredInputTypes.indexOf('COUNTRIES') >= 0 && this.selectedCountries.length === 0) ||
      (this.requiredInputTypes.indexOf('CASE_TYPES') >= 0 && this.selectedCaseTypes.length === 0) ||
      (this.requiredInputTypes.indexOf('CAUSE_LEVEL_ONES') >= 0 && this.selectedCauseLevelOnes.length === 0) ||
      (this.requiredInputTypes.indexOf('CAUSE_LEVEL_THREES') >= 0 && this.selectedCauseLevelThrees.length === 0) ||
      (this.requiredInputTypes.indexOf('SANCTION_CHECK_EXPORT_TYPE') >= 0 && this.sanctionStakeholderTypes.length === 0) ||
      (this.requiredInputTypes.indexOf('COMMITMENT_TYPES') >= 0 && this.selectedCommitmentTypes.length === 0) ||
      (this.requiredInputTypes.indexOf('SPECIFIC_EMAIL_ADDRESSES') >= 0 && !this.validateSenderEmails(this.emailList)) ||
      (this.requiredInputTypes.indexOf('EMAIL_ADDRESS_DOMAINS') >= 0 && !this.validateDomains(this.domainList)) ||
      (this.requiredInputTypes.indexOf('PURPOSES_OF_TRAVEL') >= 0 && this.selectedPurposesOfTravel.length === 0) ||
      (this.requiredInputTypes.indexOf('SANCTION_ACTION_TYPE') >= 0 && this.validation.input.sanctionActionType == null)
    ) {
      this.updateNeeded = false;
    }
  }

  setNegation(negation: boolean) {
    this.validate();
    this.validation.input.validationNegation = negation;
  }

  shouldShow(type: string) {
    return this.requiredInputTypes && this.requiredInputTypes.indexOf(type) >= 0;
  }

  validationTypeChanged() {
    this.validation.input.causeLevels = [];
    this.selectedCauseLevelThrees = [];
    this.selectedCauseLevelOnes = [];
    this.digitalCaseflowService.getValidationDescription(this.validation.input.validationType).subscribe(data => {
      this.description = data[0];
    });
    this.reloadRequiredInput(this.validation);
  }

  getStringArray(dictionaryArray: Dictionary[]): string[] {
    return dictionaryArray.map(dictionary => dictionary.id);
  }

  private cleanInput() {
    if (!this.requiredInputTypes.includes('LENGTH')) {
      this.validation.input.length = null;
    }
    if (!this.requiredInputTypes.includes('STAKEHOLDER_TYPE')) {
      this.validation.input.stakeholderType = null;
    }
    if (!this.requiredInputTypes.includes('COUNTRIES')) {
      this.validation.input.countries = [];
    }
    if (!this.requiredInputTypes.includes('CASE_TYPES')) {
      this.validation.input.caseTypes = [];
    }
    if (!this.requiredInputTypes.includes('CAUSE_LEVEL_ONES') && !this.requiredInputTypes.includes('CAUSE_LEVEL_THREES')) {
      this.validation.input.causeLevels = [];
    }
    if (!this.requiredInputTypes.includes('COMMITMENT_TYPES')) {
      this.validation.input.commitmentTypes = [];
    }
    if (!this.requiredInputTypes.includes('VALIDATION_NEGATION')) {
      this.validation.input.validationNegation = false;
    }
    if (!this.requiredInputTypes.includes('SANCTION_CHECK_EXPORT_TYPE')) {
      this.validation.input.sanctionCheckType = null;
    }
    if (!this.requiredInputTypes.includes('SPECIFIC_EMAIL_ADDRESSES')) {
      this.validation.input.specificEmailAddresses = [];
    }
    if (!this.requiredInputTypes.includes('EMAIL_ADDRESS_DOMAINS')) {
      this.validation.input.emailAddressDomains = [];
    }
    if (!this.requiredInputTypes.includes('PURPOSES_OF_TRAVEL')){
      this.validation.input.purposesOfTravel = [];
    }
    if (!this.requiredInputTypes.includes('SANCTION_ACTION_TYPE')) {
      this.validation.input.sanctionActionType = null;
    }
    debugger
  }

  private reloadRequiredInput(validation: Activity) {
    this.digitalCaseflowService.getRequiredValidationInput(validation.input.validationType).subscribe(data => {
      this.requiredInputTypes = data;
      this.validate();
    });
  }

  senderEmails($event: string[]) {
    this.validation.input.specificEmailAddresses = [...$event];
    this.validate();
  }

  senderDomains($event: string[]) {
    this.validation.input.emailAddressDomains = [...$event];
    this.validate();
  }

  validateSenderEmail(chip: string): boolean {
    const emailPattern: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return emailPattern.test(chip);
  }

  validateSenderEmails(chips: string[]): boolean {
    return chips.length > 0 && chips.find(s => !this.validateSenderEmail(s)) == null;
  }

  validateDomain(chip: string): boolean {
    const domainPattern: RegExp = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,}$/i;
    return domainPattern.test(chip);
  }

  validateDomains(chips: string[]): boolean {
    return chips.length > 0 && chips.find(s => !this.validateDomain(s)) == null;
  }
}
